import { Button } from "antd";
import Table from "antd/lib/table";
import { ColumnsType } from "antd/lib/table/interface";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DISPLAY_TYPES,
  FIXED_ROW_KEYS,
  FIXED_ROW_KEYS_2,
  MULTIPLE,
  ROW_KEY,
  SINGLE,
  STATUS_PERFORMANCE,
  STATUS_TABLE,
  TABLE_TYPE,
} from "../../constants";
import {
  calculateTableColumnData,
  getShow,
  updateTableDataSource,
} from "../../helper";
import {
  DataType,
  GoalCategoryType,
  HeaderTableDataType,
  UserType,
} from "../../interfaces";
import usePerformanceManagement from "../../redux/hook";
import { createTreeStructure } from "./helper";
import ExpandIcon from "./helper-render/expand-icon";
import { columns, createRenderHeaderTable } from "./helper-render/index";
import { LoadingSSC } from "./spinning";
import styles from "./style.module.scss";
import EmptyDataTable from "./empty";
import { ROLE_PERMISSIONS_FLATTEN } from "../../../../../helpers/permissionConstant";
import { useAppSelector } from "../../../../../store/store";
import { selectAuthUser } from "../../../../auth/redux/authSlice";
import { checkPermissionByRoleId } from "../../../../../helpers/permissionFactory";
function PerformanceTable({
  type,

  userStructure,
  onSentApproveRequest,
}: Readonly<{
  userStructure: HeaderTableDataType[];
  type: string;

  onSentApproveRequest: Function;
}>) {
  const { t } = useTranslation("gperformance", {
    keyPrefix: "performance_filter",
  });
  const [headerConfig, setHeaderConfig] =
    useState<ColumnsType<DataType>>(columns);
  const [disableSentRequest, setDisableSentRequest] = useState(false);
  const {
    toggleMap,
    goalCategory,
    mapProportion,
    renderRowMap,
    statusTable,
    selectedSubject,
    followSubject,
    expandedSubject,
    filterConfiguration,
    displayType,
  } = usePerformanceManagement();
  const { G_PERFORMANCE } = ROLE_PERMISSIONS_FLATTEN;
  const PERMISSION = useAppSelector(selectAuthUser)?.permissions;
  /**
   *
   * @param userStructure UserType Array that contains data of all element should be iterate
   * @returns update Header columns actions via headerConfig
   */
  const createTableColumns = ({
    toggleMap,
    userReference,
  }: {
    toggleMap: { [key: string]: boolean };
    userReference: UserType[];
  }) => {
    // new
    if (userReference.length === 0) return [];
    const { mapChildren, mapEmployee } = createTreeStructure(userReference);
    let mapSelectedSubject: [number, boolean][] = [
      ...selectedSubject,
      ...followSubject,
      ...expandedSubject,
    ].map((item: number) => [item, true]);
    // trong expand chua selected,
    let mapData: Map<number, boolean> = new Map(mapSelectedSubject);
    let listRoot = userStructure.filter(
      (item) =>
        !item?.parent ||
        (selectedSubject.includes(item.id) && item.parent === null) || // khong co parent
        (!mapData.get(item.parent) && !selectedSubject.includes(item.id)) // co parent ko xuat hien trong list selectedSubject
    );
    let result: UserType[] = [];
    listRoot = listRoot.sort((a, b) => {
      if (a.level - b.level === 0) {
        return a.name.localeCompare(b.name);
      } else return a.level - b.level;
    });

    listRoot.forEach((header) => {
      result = result.concat(
        calculateTableColumnData({
          element: header as UserType,
          mapChildren,
          mapEmployee,
          toggleMap,
        })
      );
    });
    return result;
  };
  const [dataSourceBoilerplate, setDataSourceBoilerplate] = useState<
    GoalCategoryType[]
  >([]);
  const mappingDataRow = ({
    rows,
    mapProportion,
  }: {
    rows: any;
    mapProportion: any;
  }) => {
    let tableRow: any[] = [];
    let reverseMap: { [key: number]: { [key: number | string]: any } } = {};
    Object.entries(mapProportion).forEach(
      ([employeeId, value]: [employeeId: number | string, value: any]) => {
        Object.entries(value).forEach(
          ([goalId, proportion]: [
            goalId: string | number,
            proportion: any,
          ]) => {
            if (!reverseMap[Number(goalId)]) {
              reverseMap[Number(goalId)] = {};
            }
            reverseMap[Number(goalId)][employeeId] = proportion;
          }
        );
      }
    );
    userStructure.forEach((employee: HeaderTableDataType) => {
      tableRow = updateTableDataSource({
        dataSet: rows,
        employeeId: employee.id,
        mapProportion,
      });
    });
    tableRow = filterDataValue(tableRow);
    return tableRow;
  };
  const checkValue = (item: any) => {
    const fixFields = FIXED_ROW_KEYS;
    if (item.type === ROW_KEY.TOTAL || item.type === ROW_KEY.STATUS) {
      return item;
    } else {
      const listKey = Object.keys(item)
        .filter((key: any) => !fixFields.includes(String(key)))
        .map((item) => Number(item));
      const res = listKey.some(
        (key) => item[key] !== null && Number(item[key]) >= 0
      );
      if (res === true) return item;
    }
    return false;
  };
  const filterDataValue = (data: any) => {
    if (!data) {
      return data;
    }
    const _data = data.filter(checkValue);
    return _data;
  };
  const TABLE_VIEW: { [key: number]: string } = {
    1: MULTIPLE,
    2: SINGLE,
  };
  const createTableHeaderWithSingleSubject = useCallback(() => {
    const newConfig = createRenderHeaderTable({
      header: userStructure as UserType[],
      headerConfig,
      dataType: type || TABLE_TYPE.USER,
    });
    setHeaderConfig(newConfig);
  }, [userStructure, headerConfig, type]);
  const createTableHeaderWithMultipleSubject = useCallback(() => {
    /// Một kỳ nhiều người
    const dataHeader = createTableColumns({
      toggleMap,
      userReference: userStructure as UserType[],
    });
    const newConfig = createRenderHeaderTable({
      header: dataHeader,
      headerConfig,
      dataType: type || TABLE_TYPE.USER,
    });
    if (newConfig) {
      setHeaderConfig(newConfig);
    }
  }, [toggleMap, userStructure, type]);

  const checkEmptyData = (data: any) => {
    if (!data) return false;
    const fixFields = ["className", "id", "key", "property", "type"];
    const statusArr = data.find((item: any) => item.type === "status");
    if (!statusArr) return false;
    const listKey = Object.keys(statusArr)
      .filter((key: any) => !fixFields.includes(String(key)))
      .map((item) => Number(item));
    const isEmpty = listKey.every((key) => statusArr[key] === undefined);

    return isEmpty;
  };
  const validateData = (data: any) => {
    const fixFields = FIXED_ROW_KEYS_2;
    const summary = data.find((item: any) => item.type === ROW_KEY.TOTAL);
    const statusArr = data.find((item: any) => item.type === ROW_KEY.STATUS);
    if (!summary) {
      return false;
    }

    let result = false;
    const checkListKey = (listKey: number[]) => {
      const isEmpty = listKey.every((key) => statusArr[key] === undefined);
      const hasNewGoal = listKey.some(
        (key) =>
          summary[key] <= 100 && statusArr[key] === STATUS_PERFORMANCE.NEW
      );
      const validateResult = listKey.every(
        (key) =>
          (summary[key] <= 100 && statusArr[key] === STATUS_PERFORMANCE.NEW) ||
          statusArr[key] !== STATUS_PERFORMANCE.NEW
      );
      return !isEmpty && validateResult && hasNewGoal;
    };

    if (displayType === DISPLAY_TYPES.SINGLE_SUBJECT) {
      const listKey = Object.keys(summary)
        .filter((key: any) => !fixFields.includes(String(key)))
        .map((item) => Number(item));
      result = checkListKey(listKey);
    } else if (displayType === DISPLAY_TYPES.MULTIPLE_SUBJECT) {
      result = checkListKey([...selectedSubject, ...followSubject]);
    }
    return result;
  };
  const generateTableHeader = () => {
    if (type === TABLE_TYPE.USER || type === TABLE_TYPE.WORKSPACE) {
      createTableHeaderWithMultipleSubject();
    } else if (type === "date") {
      createTableHeaderWithSingleSubject();
    }
  };
  const [tableEmpty, setTableEmpty] = useState(true);
  useEffect(() => {
    //#1 change data will render the body => this with lead to #2 useEffect
    // calculate data row - change data template row base on render row map
    let showGoalsCategory: GoalCategoryType[] = getShow({
      goals: goalCategory,
      map: renderRowMap,
    });
    if (showGoalsCategory) {
      showGoalsCategory = mappingDataRow({
        rows: showGoalsCategory,
        mapProportion,
      });
      setDataSourceBoilerplate(showGoalsCategory);
    } // template for other\
  }, [renderRowMap, goalCategory, mapProportion]);
  useEffect(() => {
    /// #2 this will validate and allow data can be sent to be approved
    const isEveryUnder100 = validateData(dataSourceBoilerplate);
    const isEmpty = checkEmptyData(dataSourceBoilerplate);
    setTableEmpty(isEmpty);
    setDisableSentRequest(!isEveryUnder100);
    return () => {};
  }, [dataSourceBoilerplate]);
  useEffect(() => {
    //  #3 this will change header of table,
    generateTableHeader();
  }, [toggleMap, userStructure]);
  const [defaultExpandRowKeys, setDefaultExpandRowKeys] = useState<string[]>(
    []
  );
  useEffect(() => {
    const ids = goalCategory.map((item) => item.key);
    setDefaultExpandRowKeys(ids);
  }, [goalCategory, statusTable]);

  function onClickExpandRow(expanded: boolean, record: DataType): void {
    const _key = record.key;
    let _defaultExpandRowKeys = [...defaultExpandRowKeys];
    if (defaultExpandRowKeys.includes(_key) && !expanded) {
      _defaultExpandRowKeys.splice(_defaultExpandRowKeys.indexOf(_key), 1);
    } else {
      _defaultExpandRowKeys.push(_key);
    }
    setDefaultExpandRowKeys(_defaultExpandRowKeys);
  }

  return (
    <div
      className={`${styles["performance-management"]}  ${
        styles["table-type-" + type]
      } ${
        STATUS_TABLE.LOADED
          ? styles[TABLE_VIEW[filterConfiguration.displayType]]
          : ""
      }`}
    >
      <Table
        locale={{ emptyText: <EmptyDataTable /> }}
        columns={headerConfig}
        dataSource={
          tableEmpty && statusTable === STATUS_TABLE.LOADED
            ? []
            : dataSourceBoilerplate
        }
        rowClassName={(record) => record.className}
        scroll={{ x: 500 }}
        loading={{
          spinning: statusTable === STATUS_TABLE.LOADING,
          indicator: <LoadingSSC loading={true} />,
        }}
        expandable={{
          expandedRowKeys: defaultExpandRowKeys,
          defaultExpandedRowKeys: defaultExpandRowKeys,
          defaultExpandAllRows: statusTable === STATUS_TABLE.LOADED,
          onExpand: onClickExpandRow,
          expandIcon: ExpandIcon,
        }}
        pagination={false}
      />
      {!tableEmpty &&
        checkPermissionByRoleId(
          G_PERFORMANCE.PERFORMANCE_MANAGEMENT_SEND_APPROVE,
          PERMISSION
        ) && (
          <div className={styles["approve"]}>
            <Button
              disabled={disableSentRequest}
              className={styles["button"]}
              onClick={() => onSentApproveRequest()}
            >
              {t("sent_approval_request")}
            </Button>
          </div>
        )}
    </div>
  );
}
export default PerformanceTable;
