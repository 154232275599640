import { lazy } from "react";

const MobileDetected: any = lazy(() => import("./index"));

const routes = [
  {
    path: `/mobile-detected`,
    element: MobileDetected,
    index: false,
    private: false,
  },
];

export default routes;
