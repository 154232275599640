import { createAsyncThunk } from "@reduxjs/toolkit";
import { FilterParam } from "../utils/interface";
import KpiDashboardCeoApi from "./api";

export const getListPerformance = createAsyncThunk(
  "KpiDashboardCeo/getListPerformance",
  async (params: FilterParam, { getState }) => {
    try {
      const response = await KpiDashboardCeoApi.listPerformance(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getDetailPerformanceUser = createAsyncThunk(
  "KpiDashboardCeo/getDetailPerformanceUser",
  async ({ id, params }: { id: number; params: any }) => {
    try {
      const response = await KpiDashboardCeoApi.detailPerformanceUser(
        id,
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getDetailPerformanceCeo = createAsyncThunk(
  "KpiDashboardCeo/getDetailPerformanceCeo",
  async (params: any) => {
    try {
      const response = await KpiDashboardCeoApi.detailPerformanceCeo(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getDetailPerformanceWorkspace = createAsyncThunk(
  "KpiDashboardCeo/getDetailPerformanceWorkspace",
  async ({ id, params }: { id: number; params: any }) => {
    try {
      const response = await KpiDashboardCeoApi.detailPerformanceWorkspace(
        id,
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getOverviewPerformance = createAsyncThunk(
  "KpiDashboardCeo/getOverviePerformance",
  async (params: any) => {
    try {
      const response = await KpiDashboardCeoApi.overviewPerformance(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const exportNonCeo = createAsyncThunk(
  "KpiDashboardCeo/exportNonCeo",
  async (params: any) => {
    try {
      const response = await KpiDashboardCeoApi.exportNonCeo(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);
export const exportCEO = createAsyncThunk(
  "KpiDashboardCeo/exportCEO",
  async (params: any) => {
    try {
      const response = await KpiDashboardCeoApi.exportCEO(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);
export const getDataDashboardCEO = createAsyncThunk(
  "KpiDashboardCeo/getDashBoardCEO",
  async (params: FilterParam) => {
    try {
      const response = await KpiDashboardCeoApi.getDashBoardCEO(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);
