import { UserType, DataType } from "../../interfaces";
import { without } from "lodash";
export function createTreeStructure(users: UserType[]) {
  let tree: DataType[] = [];
  const mapEmployee: Map<number, UserType> = new Map();
  const mapChildren: Map<number, Array<number>> = new Map();
  users.forEach((item) => {
    const _employee: UserType = {
      name: item?.name,
      position: item?.position,
      email: item?.email,
      key: String(item?.id),
      id: item?.id,
      parent: null,
      level: item.level,
    };
    if (!mapEmployee.get(item?.id)) {
      mapEmployee.set(item?.id, _employee);
    }
    if (item?.parent) {
      if (!mapChildren.get(item?.parent)) {
        mapChildren.set(item?.parent, []);
      }
      const data = mapChildren.get(item?.parent);
      if (data) {
        mapChildren.set(
          item?.parent,
          without([...data, item?.id], item?.parent)
        );
      }
    }
  });
  const rootElement = users.find((item) => item?.parent === null);
  if (rootElement) {
    tree = buildTree({ id: rootElement?.id, mapChildren, mapEmployee });
  }
  return { tree, mapChildren, mapEmployee };
}
function buildTree({
  id,
  mapChildren,
  mapEmployee,
}: {
  id: string | number;
  mapChildren: any;
  mapEmployee: any;
}) {
  // return object of tree
  const tree = { ...mapEmployee.get(id) };
  if (mapChildren.get(id)?.length > 0) {
    const listChildren = mapChildren.get(id);
    tree.children = listChildren.map((item: any) => {
      return buildTree({ id: item, mapChildren, mapEmployee });
    });
  }
  return tree;
}
