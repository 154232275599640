import { createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import i18n from "../../../../i18n";
import OkrApi from "../../api/okrApi";
import {
  paramsGetSubItemAPIType,
  paramsRequestGetOKRsType,
} from "../../type/type";

const requestGetOKRs = async (params: paramsRequestGetOKRsType) => {
  const response = await OkrApi.getFilterOkr(params);
  return response;
};

export const onGetOKRs = createAsyncThunk(
  "role/ceo/ceo-focus/getOKRs",
  async (params: paramsRequestGetOKRsType) => {
    try {
      const response = await requestGetOKRs(params);
      return response.data;
    } catch (error) {
      toast.error(i18n.t("layout:common.getUserError"));
      throw error;
    }
  }
);

export const onGetSubOKRs = createAsyncThunk(
  "role/ceo/ceo-focus/getSubOKRs",
  async (params: paramsGetSubItemAPIType) => {
    try {
      const response = await OkrApi.getSubOKRs(params);
      return { response, level: params.level, parentUUID: params.uuid };
    } catch (err) {
      return "close-loading";
    }
  }
);

export const onGetKRs = createAsyncThunk(
  "role/ceo/ceo-focus/getKRs",
  async (params: Omit<paramsGetSubItemAPIType, "sub_project_ids">) => {
    try {
      const response = await OkrApi.getKRs(params);
      return { response, level: params.level, parentUUID: params.uuid };
    } catch (err) {
      return "close-loading";
    }
  }
);

export const onGetTasks = createAsyncThunk(
  "role/ceo/ceo-focus/getTasks",
  async (
    params: Omit<paramsGetSubItemAPIType, "sub_project_ids" | "key_result_ids">
  ) => {
    try {
      const response = await OkrApi.getTasks(params);
      return { response, level: params.level, parentUUID: params.uuid };
    } catch (err) {
      return "close-loading";
    }
  }
);
