import { AnyAction, createAsyncThunk } from '@reduxjs/toolkit';
import { DEFAULT_PARAMS, TOAST_TYPE, openNotification } from '../utils';
import { FilterParam } from '../utils/interface';
import { ACTION, MODAL_TYPE } from '../utils/constant';
import KpiDataManagement from './api';

import { clearFormFilter, updateAction, toggleShowModal } from './slice';

export const getList = createAsyncThunk(
  'kpiDataManagment/getList',
  async (params: FilterParam) => {
    try {
      const response = await KpiDataManagement.listFile(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListMore = createAsyncThunk(
  'kpiDataManagment/getListMore',
  async (params: FilterParam) => {
    try {
      const response = await KpiDataManagement.listFile(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListParam = createAsyncThunk(
  'kpiDataManagment/getListParam',
  async (params: any) => {
    try {
      const response = await KpiDataManagement.getListParam(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListParamMore = createAsyncThunk(
  'kpiDataManagment/getListParamMore',
  async (params: any) => {
    try {
      const response = await KpiDataManagement.getListParam(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getConfigsResource = createAsyncThunk(
  'kpiDataManagment/getConfigsResource',
  async () => {
    try {
      const response = await KpiDataManagement.getConfigsResource({});
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const postResource = createAsyncThunk(
  'kpiDataManagment/postResource',
  async ({ payload, message }: any, { dispatch }) => {
    try {
      const response = await KpiDataManagement.postResource(payload);
      dispatch(toggleShowModal(MODAL_TYPE.Add));
      if (!response.data.errors) {
        openNotification({
          type: TOAST_TYPE.Success,
          message: message.success.create
        });
      } else {
        openNotification({
          type: TOAST_TYPE.Error,
          message: message.error.create
        });
      }
      return response;
    } catch (error: any) {
      if (error?.response?.status === 400) {
        openNotification({
          type: TOAST_TYPE.Error,
          message: message.error.readyExistsName
        });
        return;
      }
      openNotification({
        type: TOAST_TYPE.Error,
        message: message.error.create
      });
      throw error;
    }
  }
);

export const uploadData = createAsyncThunk(
  'kpiDataManagment/uploadData',
  async (formData: any, { dispatch }) => {
    try {
      const response = await KpiDataManagement.upload(formData);
      dispatch(clearFormFilter(true));
      dispatch(getList({ ...DEFAULT_PARAMS }));
      dispatch(updateAction({ id: response.data.id, action: ACTION.Upload }));
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const downloadFileData = createAsyncThunk(
  'kpiDataManagment/downloadFileData',
  async ({
    id,
    params,
    message
  }: {
    id: number;
    params: any;
    message: any;
  }) => {
    try {
      const response = await KpiDataManagement.download({ id, params });
      return response;
    } catch (error) {
      openNotification({
        type: TOAST_TYPE.Error,
        message: message.error.download
      });
      throw error;
    }
  }
);
