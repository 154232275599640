import React from "react";

const IconArrowT = () => {
  return (
    <svg
      width="13"
      height="11"
      viewBox="0 0 13 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 5.79431C0 5.50954 0.211615 5.27419 0.486172 5.23695L0.5625 5.23181L11.8125 5.23181C12.1232 5.23181 12.375 5.48365 12.375 5.79431C12.375 6.07908 12.1634 6.31443 11.8888 6.35168L11.8125 6.35681L0.5625 6.35681C0.25184 6.35681 0 6.10497 0 5.79431Z"
        fill="white"
      />
      <path
        d="M6.8785 1.6746C6.65836 1.4554 6.65759 1.09925 6.87679 0.879103C7.07606 0.678973 7.38851 0.660147 7.60907 0.823063L7.67228 0.87739L12.2098 5.39539C12.4105 5.59526 12.4288 5.90884 12.2646 6.1294L12.2098 6.19256L7.67231 10.7113C7.45219 10.9305 7.09604 10.9298 6.87682 10.7097C6.67753 10.5096 6.66003 10.197 6.82387 9.97715L6.87847 9.91417L11.0155 5.79377L6.8785 1.6746Z"
        fill="white"
      />
    </svg>
  );
};

export default IconArrowT;
