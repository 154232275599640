import { buildQueryUrl } from "../../../../helpers/helper";
import { RequestApiPrivate } from "../api/axios";
import { WsCode } from "./wsCode";
import { dataFilesUploadedType } from "../../../../types/types";

const BLOB_TYPE = "blob";

export const Request = {
  async post(wsCode: any, json: any) {
    try {
      const res = await RequestApiPrivate.post(wsCode, json);
      const { data } = res;
      return data;
    } catch (error: any) {
      throw error;
    }
  },
  async postOff(wsCode: string, json: any) {
    try {
      const res = await RequestApiPrivate.post(wsCode, json);
      const { data } = res;
      return data;
    } catch (error: any) {
      throw error;
    }
  },
  async get(wsCode: any) {
    try {
      const res = await RequestApiPrivate.get(wsCode);
      const { data } = res;
      return data;
    } catch (error: any) {
      throw error;
    }
  },
  async getDetail(wsCode: any) {
    try {
      const res = await RequestApiPrivate.get(wsCode);
      const { data } = res;
      return data;
    } catch (error: any) {
      throw error;
    }
  },
  async getApiTimeSheet(wsCode: any) {
    try {
      RequestApiPrivate.defaults.params = [];
      const res = await RequestApiPrivate.get(wsCode);
      const { data } = res;
      return data;
    } catch (error: any) {
      throw error;
    }
  },

  async postFilesData(wsCode: any, dataFilesUploaded: dataFilesUploadedType) {
    try {
      const res = await RequestApiPrivate.post(wsCode, dataFilesUploaded);
      const { data } = res;
      return data;
    } catch (error: any) {
      throw error;
    }
  },

  deleteChecklists: async function (ids: any): Promise<any> {
    try {
      const res = await RequestApiPrivate.delete(`/step-check-list`, {
        data: { ids: ids },
      });
      return res;
    } catch (error) {
      throw error;
    }
  },
  async postFileTimeSheet(wsCode: any, file: any) {
    try {
      const res = await RequestApiPrivate.post(wsCode, file);
      const { data } = res;
      return data;
    } catch (error: any) {
      throw error;
    }
  },

  async put(wsCode: any, json: any) {
    try {
      const res = await RequestApiPrivate.put(wsCode, json);
      const { data } = res;
      return data;
    } catch (error: any) {
      throw error;
    }
  },

  async delete(wsCode: any) {
    try {
      const res = await RequestApiPrivate.delete(wsCode);
      const { data } = res;
      return data;
    } catch (error: any) {
      throw error;
    }
  },
  async getOff(wsCode: any) {
    try {
      const res = await RequestApiPrivate.get(wsCode);
      const { data } = res;
      return data;
    } catch (error: any) {
      throw error;
    }
  },

  async getApi(params: {}, wsCode: any) {
    try {
      if (params) {
        const url = buildQueryUrl(`${wsCode}`, params);
        const res: any = await RequestApiPrivate.get(url);
        if (res && res.data) {
          return res.data;
        }
      }
    } catch (error: any) {
      throw error;
    }
  },
  async getListComment(params: {
    step_id: number;
    ticket_request_id: number;
    limit: number;
    page: number;
  }) {
    try {
      const res: any = await RequestApiPrivate.get("/step-comments", {
        params,
      });
      if (res && res.data) {
        return res?.data?.data;
      }
    } catch (error: any) {
      throw error;
    }
  },

  async getApiFile(wsCode: any) {
    let api = RequestApiPrivate;
    try {
      api.defaults.params = [];
      api.defaults.responseType = "blob";
      const res: any = await api.get(wsCode);
      if (res) {
        if (res.data) {
          return res.data;
        }
      }
    } catch (error: any) {
      throw error;
    } finally {
      delete api.defaults.responseType;
    }
  },

  async getApiDashboard(json: {}, wsCode: any) {
    try {
      if (json) {
        const url = buildQueryUrl(`/${wsCode}`, json);
        const res: any = await RequestApiPrivate.get(url);
        if (res && res.data) {
          return res.data;
        }
      }
    } catch (error: any) {
      throw error;
    }
  },

  async getApiCustom(params: {}, wsCode: any) {
    let api: any = RequestApiPrivate;
    try {
      if (params) {
        api.defaults.params = params;
        api.defaults.responseType = BLOB_TYPE;
        const res: any = await api.get(wsCode);
        if (res) {
          return res;
        }
      }
    } catch (error: any) {
      throw error;
    } finally {
      api.defaults.params = [];
      delete api.defaults.responseType;
    }
  },
  getDataProcess: async (param: {}): Promise<any> => {
    try {
      const result = await Request.getApi(param, WsCode.processAll);
      if (result) {
        return result.data;
      }
    } catch (error: any) {
      throw error;
    }
  },
  async postReport(wsCode: any, payload: any): Promise<any> {
    try {
      const res = await RequestApiPrivate.post(wsCode, payload, {
        responseType: "blob",
      });
      return res;
    } catch (error: any) {
      throw error;
    }
  },
  async deleteMulti(wsCode: any, params: any) {
    let api = RequestApiPrivate;
    try {
      api.defaults.params = params;
      const res = await api.delete(wsCode);
      const { data } = res;
      return data;
    } catch (error: any) {
      throw error;
    } finally {
      api.defaults.params = [];
    }
  },
};
