import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../store/store";
import toast from "react-hot-toast";
import { WorkspaceApi } from "../api/WorkspaceApi";

const fetchWorkspaceInfo = createAsyncThunk(
  "workspace/info",
  async (payload: { dispatch: any; callback?: any }) => {
    const { dispatch, callback } = payload;
    try {
      const { data } = await WorkspaceApi.getAdminWorkspaceInfo();
      return { ...data[0] };
    } catch (error: any) {
      // toast.error(error?.response?.data?.message);
      callback && callback(false);
      return [];
    }
  }
);

export async function getWorkspaceInfo(dispatch: any, callback?: any) {
  await dispatch(
    fetchWorkspaceInfo({
      callback,
      dispatch,
    })
  );
}

const initialState: any = { data: [], status: "loading" };

const workspaceSlice = createSlice({
  name: "workspace",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchWorkspaceInfo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchWorkspaceInfo.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchWorkspaceInfo.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const selectWorkspace = (state: RootState) => state.workspace;
export default workspaceSlice.reducer;
