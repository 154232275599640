import { divisions } from '../redux/slice';
import { nanoid } from '@reduxjs/toolkit';
import { uuid } from '../../../../helpers/helper';
import { KPI_ROLE, STATUS } from './constant';
import { FilterParam } from './interface';
import _ from 'lodash';
import { validateActualTimeHelper } from '../../../okr/target-management/components/ModalAddTimeSheet/helper';

export const convertStatus = (_status: number): boolean => {
  if (_status === STATUS.Active) return true;
  return false;
};

export const removeCondition = (el: any) => {
  const _el = { ...el };
  if (_el.conditions) {
    delete _el.conditions;
  }
  return _el;
};

export const downloadFileParam = (params: FilterParam) => {
  const _params = { ...params } as {
    page?: number;
    status?: number;
    resource_code?: string;
    keyword?: string;
    per_page?: number;
  };
  if (_params.per_page) {
    delete _params.per_page;
  }
  if (_params.page) {
    delete _params.page;
  }
  return _params;
};

export const downloadFile = (response: any): void => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const fileName = getFileNameInResponseHeader(response.headers);
  const link = document.createElement('a');
  link.target = '_blank';
  link.download = fileName;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

const getFileNameInResponseHeader = (headers: any): any => {
  let fileName = '';
  if (!headers) return '';
  if ('content-disposition' in headers) {
    const data = headers['content-disposition']
      .split(';')
      .map((item: string) => item.trim());

    for (let item of data) {
      const disposition = item.split('=');
      if (disposition.length > 0) {
        if (disposition[0] === 'filename') return disposition[1];
      }
    }
  }
  return fileName;
};

export const createKpiDataMapping = (data: any) => {
  const { list, role } = data;
  const _list: any = [];
  const _tracking: any = [];

  if (role === KPI_ROLE.HOD) {
    const results = {
      list: [
        {
          name: list.name,
          key: uuid(),
          total: '-',
          keyList: 'divisions',
          id: list.id,
          formName: JSON.stringify({
            keyList: 'divisions',
            id: list.id,
            positions: '0'
          }),
          disabled: !!list.divisions?.length,
          children: [
            ...list.divisions.map((_item: any, index: number) => ({
              ..._item,
              name: _item.name,
              total: '-',
              key: uuid(),
              keyList: 'divisions',
              formName: JSON.stringify({
                keyList: 'divisions',
                id: _item.id,
                positions: `0_${index}`
              }),
              disabled: !!_item.employees?.length,
              children: [
                ..._item.employees.map((_item: any, _index: number) => ({
                  ..._item,
                  name: _item.username,
                  total: '-',
                  key: uuid(),
                  keyList: 'employees',
                  formName: JSON.stringify({
                    keyList: 'employees',
                    id: _item.id,
                    positions: `0_${index}_${_index}`
                  }),
                  disabled: !!_item.kpi_employees?.length
                }))
              ]
            })),
            ...list.direct_managements.map((_item: any, index: number) => ({
              ..._item,
              name: _item.username,
              total: '-',
              key: uuid(),
              id: _item.user_id,
              keyList: 'direct_managements',
              formName: JSON.stringify({
                keyList: 'direct_managements',
                id: _item.user_id,
                positions: `0_${index}`
              }),
              disabled: !!_item.kpi_directManagements?.length
            }))
          ]
        }
      ],
      tracking: [
        {
          formName: JSON.stringify({
            keyList: 'divisions',
            id: list.id,
            positions: '0'
          })
        },
        ...list.divisions.map((_item: any, index: number) => {
          return {
            formName: JSON.stringify({
              keyList: 'divisions',
              id: _item.id,
              positions: `0_${index}`
            }),
            children: [
              ..._item.employees.map((_item: any, _index: number) => ({
                formName: JSON.stringify({
                  keyList: 'employees',
                  id: _item.id,
                  positions: `0_${index}_${_index}`
                })
              }))
            ]
          };
        }),
        ...list.direct_managements.map((_item: any, index: number) => ({
          formName: JSON.stringify({
            keyList: 'direct_managements',
            id: _item.user_id,
            positions: `0_${list.divisions?.length + index}`
          })
        }))
      ]
    };

    const { tracking } = results;

    const newTracking: any = [];

    tracking.forEach((track) => {
      if (track.children?.length > 0) {
        newTracking.push(track, ...track.children);
      } else {
        newTracking.push(track);
      }
    });

    results.tracking = newTracking;
    return results;
  }

  return { list: _list, tracking: _tracking };
};

export const updateKpiDataMapping = (data: any) => {
  const arrLengthGoals = data.map((e: any) => e.goals.length);

  const indexGoalsMaxLength = arrLengthGoals.findIndex(
    (e: any) => e === Math.max(...arrLengthGoals)
  );

  const maxLengthArrGoals = arrLengthGoals[indexGoalsMaxLength];

  const dataMapping = data.map((_item: any) => {
    const goalIdItemNotInclude = data[indexGoalsMaxLength].goals.filter(
      (_goal: any) => {
        return _item.goals.every((e: any) => _goal.goal_id !== e.goal_id);
      }
    );

    const goalItemNull = goalIdItemNotInclude.map((_goal: any) => ({
      goal_id: _goal.goal_id
    }));
    return {
      ..._item,
      goals: [..._item.goals, ...goalItemNull]
    };
  });

  const results = {
    list: [
      ...dataMapping.map((_item: any, index: number) => {
        let dataIndex: any = {
          name: _item.target_time,
          key: uuid(),
          total: '-',
          keyList: 'goals',
          id: _item.id,
          goals: _item.goals,
          formName: JSON.stringify({
            keyList: 'goals',
            id: _item.id,
            positions: `${index}`
          })
        };
        _item.goals.map((_goal: any) => {
          dataIndex[`${_goal.goal_id}`] = {
            formName: JSON.stringify({
              keyList: 'goals',
              id: _item.id,
              positions: `${index}`,
              goal_id: _goal.goal_id
            }),
            id: _goal.goal_id,
            proportion: _goal.proportion
          };
        });
        return dataIndex;
      })
    ],
    tracking: [
      ...dataMapping.map((_item: any, index: number) => {
        if (!_item?.goals?.length) return;

        return {
          formName: JSON.stringify({
            keyList: 'goals',
            id: _item.id,
            positions: `${index}`
          }),
          ..._item.goals.map((_goal: any) => {
            return {
              formName: JSON.stringify({
                keyList: 'goals',
                id: _item.id,
                positions: `${index}`,
                goal_id: _goal.goal_id
              })
            };
          })
        };
      })
    ]
  };
  return results;
};

export const listMapping = (_list: any) => {
  return _list.map((_val: any, index: number) => {
    if (!_val?.childrens?.length) {
      return { ..._val, children: [] };
    }
    return { ..._val, key: nanoid(), ..._addKey(_val?.childrens) };
  });
};

const _addKey = (_list: any[]): any => {
  if (!_list?.length) return;
  const children = _list.map((_item: any, index: number) => ({
    ..._item,
    index: index + 1,
    key: nanoid(),
    ..._addKey(_item.employees)
  }));

  return { children };
};

export const withIconByRole = (role: any) => {
  if (role === KPI_ROLE.Teamlead || role === KPI_ROLE.HOD) return 0;
  return 46;
};

export const labelByRole = (role: any, key: string) => {
  if (key === 'index') {
    if (role === KPI_ROLE.Teamlead || role === KPI_ROLE.HOD) return key;
    return 'rank';
  }
  if (role === KPI_ROLE.Teamlead) return 'employee';
  if (role === KPI_ROLE.HOD) return 'division';
  return key;
};

export const showTotalKpi = (sum: any) => {
  return !isNaN(sum) || sum === 0 ? sum : '-';
};

export const groupBy = (xs: any[], key: any) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const removeUndefined = (obj: any) => {
  Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);
  return obj;
};
