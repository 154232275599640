import React from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../Layout/Layout";
import LoadingRing from "../LoadingRing/LoadingRing";

function LoadingFallback() {
  const { t } = useTranslation("layout");

  return (
    <Layout>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <LoadingRing size="md" />
        <h4>{t("loading")}</h4>
      </div>
    </Layout>
  );
}

export default LoadingFallback;
