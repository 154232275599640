import { Request } from "../../component/request/Request";
import { WsCode } from "../../component/request/wsCode";

const ProcessManagementApi = {
  //call api department
  getDepartment: async (): Promise<any> => {
    try {
      const result = await Request.getDetail(WsCode.departments);
      if (result) {
        return result.data;
      }
    } catch (error) {
      throw error;
    }
  },
  //call api user
  getUser: async (params: {}): Promise<any> => {
    try {
      const result = await Request.getApi(params, WsCode.users);
      if (result) {
        return result.data;
      }
    } catch (error) {
      throw error;
    }
  },
  //call api group
  getGroup: async (): Promise<any> => {
    try {
      const result = await Request.getDetail(WsCode.groups);
      if (result) {
        return result.data;
      }
    } catch (error) {
      throw error;
    }
  },
  //call api detail user
  getDetailUser: async (id: Number): Promise<any> => {
    try {
      const result = await Request.getDetail(`${WsCode.users}/${id}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      throw error;
    }
  },

  //call api Position
  getPosition: async (): Promise<any> => {
    try {
      const result = await Request.getDetail(WsCode.position);
      if (result) {
        return result.data;
      }
    } catch (error) {
      throw error;
    }
  },
  //call api department in user
  getDepartMentUser: async (id: Number): Promise<any> => {
    try {
      const result = await Request.getDetail(`${WsCode.departments}/${id}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      throw error;
    }
  },
  getCheckList: async (id: Number): Promise<any> => {
    try {
      const result = await Request.getDetail(
        `${WsCode.getCheckList}?step_id=${id}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getProcessStep: async (id: Number): Promise<any> => {
    try {
      const result = await Request.get(
        `${WsCode.processStep}?process_id=${id}`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      throw error;
    }
  },

  //Post group tranfer
  postGroupTranfer: async (params: Object): Promise<any> => {
    try {
      const result = await Request.post(WsCode.groupTranfer, params);
      if (result) {
        return result.data;
      }
    } catch (error) {
      throw error;
    }
  },
  //Post group tranfer
  postStepTranfer: async (params: Object): Promise<any> => {
    try {
      const result = await Request.post(WsCode.postSteptranfer, params);
      if (result) {
        return result.data;
      }
    } catch (error) {
      throw error;
    }
  },
  updateStepTranfer: async (params: Object): Promise<any> => {
    try {
      const result = await Request.put(WsCode.steptranfer, params);
      if (result) {
        return result.data;
      }
    } catch (error) {
      throw error;
    }
  },
  updateStepTransferV2: async (params: Object, id: number): Promise<any> => {
    try {
      const result = await Request.put(`${WsCode.steptranfer}/${id}`, params);
      if (result) {
        return result.data;
      }
    } catch (error) {
      throw error;
    }
  },
  deleteStepTransfer: async (id: number): Promise<any> => {
    try {
      const result = await Request.delete(`${WsCode.steptranfer}/${id}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      throw error;
    }
  },
  getDepartmentDetail: async (id: number): Promise<any> => {
    try {
      const result = await Request.get(WsCode.departments + "/" + id);
      if (result) {
        return result.data;
      }
    } catch (error) {
      throw error;
    }
  },
  getTicketDetail: async (id: number): Promise<any> => {
    try {
      const result = await Request.get(`${WsCode.ticketRequest}/${id}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      throw error;
    }
  },
  updateTicket: async (params: Object, id: number): Promise<any> => {
    try {
      const result = await Request.put(`${WsCode.ticketRequest}/${id}`, params);
      if (result) {
        return result.data;
      }
    } catch (error) {
      throw error;
    }
  },
  createTicket: async (params: Object): Promise<any> => {
    try {
      const result = await Request.post(WsCode.ticketRequest, params);
      if (result) {
        return result.data;
      }
    } catch (error) {
      throw error;
    }
  },
  downloadFile: async (id: Number): Promise<any> => {
    try {
      const result = await Request.getApiFile(WsCode.fileDowload + "/" + id);
      return result ? result : false;
    } catch (error) {
      throw error;
    }
  },
  getUsersOfDepartment: async (params: any): Promise<any> => {
    try {
      const param = `?${new URLSearchParams(params).toString()}`;
      const result = await Request.get(`${WsCode.usersOfDepartment}/${param}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      throw error;
    }
  },
  activeRequestStepHistory: async (payload: any): Promise<any> => {
    try {
      const result = await Request.put(
        "/request-step-history/approve",
        payload
      );
      return result.data;
    } catch (error) {
      throw error;
    }
  },
  updateTicketParent: async (params: Object): Promise<any> => {
    try {
      const result = await Request.post(`${WsCode.parentProcessStep}`, params);
      if (result) {
        return result.data;
      }
    } catch (error) {
      throw error;
    }
  },
  getConfirmTicketDetail: async (id: number): Promise<any> => {
    try {
      const result = await Request.get(`${WsCode.childTicketRequest}/${id}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      throw error;
    }
  },
};

export default ProcessManagementApi;
