const ACCOUNT_SERVICE_ADMIN_URL =
  process.env.REACT_APP_API_ACCOUNT_BASE_URL + "/api/v1/admin";
  const ACCOUNT_SERVICE_ADMIN_URL_V2 =
  process.env.REACT_APP_API_ACCOUNT_BASE_URL + "/api/v2/admin";
const ACCOUNT_SERVICE_URL =
  process.env.REACT_APP_API_ACCOUNT_BASE_URL + "/api/v1/";
const ACCOUNT_SERVICE_URL_V2 =
  process.env.REACT_APP_API_ACCOUNT_BASE_URL + "/api/v2/";
const ACCOUNT_SERVICE_KEY = process.env.REACT_APP_KPI_APP_TOKEN;
const QUICK_TICKET_ADMIN_BASE_URL = process.env.REACT_APP_API_QUICK_BASE_URL + "/api/admin";
const QUICK_SERVICE_URL = process.env.REACT_APP_API_QUICK_BASE_URL + "/api/";

const WsCode = {
  groups: "groups",
  group: "group",
  users: "users",
  usersPaging: "users/paging",
  deleteGroup: "users",
  getUserById: "users",
  permission: "permissions",
  roles: "roles",
  groupList: "groups/paging",
  departments: "users-of-departments",
  department: "department",
  listFilter: "users/list/module",
};

export {
  ACCOUNT_SERVICE_ADMIN_URL,
  ACCOUNT_SERVICE_ADMIN_URL_V2,
  ACCOUNT_SERVICE_URL,
  ACCOUNT_SERVICE_URL_V2,
  ACCOUNT_SERVICE_KEY,
  WsCode,
  QUICK_TICKET_ADMIN_BASE_URL,
  QUICK_SERVICE_URL
};
