const IconPerformanceMulti = ({ color }: { color: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.22091 0.802368C8.22091 0.388155 7.88512 0.0523682 7.47091 0.0523682C7.05669 0.0523682 6.72091 0.388155 6.72091 0.802368V2.20507C6.39789 2.21336 6.10332 2.22687 5.8338 2.24889C5.12042 2.30718 4.51679 2.42878 3.96562 2.70962C3.07185 3.16502 2.34519 3.89168 1.88979 4.78545C1.60895 5.33662 1.48734 5.94025 1.42906 6.65364C1.37206 7.35126 1.37206 8.21676 1.37207 9.3089V9.3419V9.7171V15.7977V15.8307C1.37206 16.9229 1.37206 17.7884 1.42906 18.486C1.48734 19.1994 1.60895 19.803 1.88979 20.3542C2.34519 21.2479 3.07185 21.9746 3.96562 22.43C4.51679 22.7108 5.12042 22.8324 5.8338 22.8907C6.53142 22.9477 7.39691 22.9477 8.48905 22.9477H8.52207H14.9779H15.0109C16.103 22.9477 16.9685 22.9477 17.6662 22.8907C18.3795 22.8324 18.9832 22.7108 19.5343 22.43C20.4281 21.9746 21.1548 21.2479 21.6102 20.3542C21.891 19.803 22.0126 19.1994 22.0709 18.486C22.1279 17.7884 22.1279 16.9229 22.1279 15.8307V15.7977V9.3419V9.30888C22.1279 8.21675 22.1279 7.35125 22.0709 6.65364C22.0126 5.94025 21.891 5.33662 21.6102 4.78545C21.1548 3.89168 20.4281 3.16502 19.5343 2.70962C18.9832 2.42878 18.3795 2.30718 17.6662 2.24889C17.3966 2.22687 17.1021 2.21336 16.779 2.20507V0.802368C16.779 0.388155 16.4433 0.0523682 16.029 0.0523682C15.6148 0.0523682 15.279 0.388155 15.279 0.802368V2.19193L15.0109 2.1919H15.0109H14.9779H8.52207H8.48907H8.48906L8.22091 2.19193V0.802368Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.88868 7C8.34905 7 7.04589 8.20396 7.04589 9.7556C7.04589 11.3072 8.34905 12.5112 9.88868 12.5112C11.4283 12.5112 12.7315 11.3072 12.7315 9.7556C12.7315 8.20396 11.4283 7 9.88868 7ZM12.5289 7.75C12.5289 7.47386 12.7527 7.25 13.0289 7.25C14.4405 7.25 15.6216 8.35195 15.6216 9.7556C15.6216 11.1592 14.4405 12.2612 13.0289 12.2612C12.7527 12.2612 12.5289 12.0373 12.5289 11.7612C12.5289 11.4851 12.7527 11.2612 13.0289 11.2612C13.9288 11.2612 14.6216 10.5673 14.6216 9.7556C14.6216 8.94392 13.9288 8.25 13.0289 8.25C12.7527 8.25 12.5289 8.02614 12.5289 7.75ZM14.0071 16.2797C14.0211 16.1904 14.0284 16.0989 14.0284 16.0056C14.0284 14.4837 12.7947 13.25 11.2728 13.25H9.0056C7.48372 13.25 6.25 14.4837 6.25 16.0056C6.25 16.9736 7.03475 17.7584 8.0028 17.7584H12.2756C12.8441 17.7584 13.3494 17.4878 13.6696 17.0683C13.7605 17.1937 13.9081 17.2752 14.0747 17.2752H16.2111C17.0411 17.2752 17.7139 16.6024 17.7139 15.7724C17.7139 14.3886 16.5921 13.2668 15.2083 13.2668H14.0747C13.7986 13.2668 13.5747 13.4906 13.5747 13.7668C13.5747 14.0429 13.7986 14.2668 14.0747 14.2668H15.2083C16.0399 14.2668 16.7139 14.9409 16.7139 15.7724C16.7139 16.0501 16.4888 16.2752 16.2111 16.2752H14.0747C14.0518 16.2752 14.0292 16.2767 14.0071 16.2797Z"
        fill="white"
      />
    </svg>
  );
};
export default IconPerformanceMulti;
