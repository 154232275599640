import { KpiDataManagementSlice } from "./interface";

export enum MODAL_PROGRESS_TYPE {
  Success = "success",
  Failed = "failed",
  Loading = "loading"
}

export enum RESOURCE_TYPE {
  Target = 1,
  Actual
}

export enum MODAL_TYPE {
  Upload = "upload",
  Progress = "progress",
  Add = "add"
}

export enum ACTION {
  Dowload = "download",
  Upload = "upload"
}

export enum TOAST_TYPE {
  Success = "success",
  Error = "error",
  Warning = "warning"
}
export const TABLE_HEADER = ["fieldName", "condition", "value"];
export const PER_PAGE = 20;
export const DEFAULT_PAGE = 1;
export const DEFAULT_PARAMS = { per_page: PER_PAGE, page: DEFAULT_PAGE };

export const TYPE_UPlOAD = [
  ".csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel"
];

export const SIZE_UPLOAD = 5242880;

export const initialState: KpiDataManagementSlice = {
  list: [],
  configsResource: [],
  fieldsTemplate: [
    {
      name: "template",
      value: [{}]
    }
  ],
  paginate: {
    total: 0,
    current_page: 0,
    last_page: 0
  },
  loading: {
    loadMore: false,
    list: false,
    upload: false,
    listParam: false,
    addResource: false
  },
  status: "idle",
  uploadStatus: null,
  formDataUpload: null,
  responseUpload: null,
  showModal: { upload: false, progress: false, add: false },
  filterParams: { ...DEFAULT_PARAMS },
  action: { id: null, action: null },
  resetFormFilter: false,
  listParam: [],
  paginateParam: {
    total: 0,
    current_page: 0,
    last_page: 0
  },
  keyWordSearchParam: ""
};

export const REGEX_CHECK_SPECIAL_CHARACTER = new RegExp(
  /[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/
);
