import { ReactNode } from "react";
import style from "./style.module.scss";
import { mergeStylesCSS } from "../../../../helpers/helper";

type TypeProps = {
  children: ReactNode;
  customStyle?: any;
  className?: string;
};

const BodyModal = ({ children, customStyle, className }: TypeProps) => {
  return (
    <div
      className={mergeStylesCSS([
        style["container-body-modal"],
        className && className,
      ])}
      style={customStyle}
    >
      {children}
    </div>
  );
};
export default BodyModal;
