import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { FileExcelOutlined } from "@ant-design/icons";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import {
  ROLE_PERMISSIONS_FLATTEN,
  ROUTE_PATH_NAME_BY_ID,
} from "../../../../../helpers/permissionConstant";
import { checkPermissionByRoleId } from "../../../../../helpers/permissionFactory";
import { useAppSelector } from "../../../../../store/store";
import { selectAuthUser } from "../../../../auth/redux/authSlice";
import IconList from "../../../common/icons/IconList";
const PerformanceHeader = ({ exportExcel }: { exportExcel: Function }) => {
  const { t } = useTranslation("gperformance", {
    keyPrefix: "header",
  });

  const { G_PERFORMANCE } = ROLE_PERMISSIONS_FLATTEN;
  const PERMISSION = useAppSelector(selectAuthUser)?.permissions;
  const permissionListStatus = checkPermissionByRoleId(
    G_PERFORMANCE.PERFORMANCE_MANAGEMENT_LIST_STATUS,
    PERMISSION
  );

  const navigate = useNavigate();

  const handleClickList = () => {
    navigate(
      ROUTE_PATH_NAME_BY_ID[G_PERFORMANCE.PERFORMANCE_MANAGEMENT_LIST_STATUS]
    );
  };

  return (
    <div className={`${styles.header} kpi-wrapper`}>
      <h1>{t("title")}</h1>

      <div style={{ display: "flex" }}>
        {permissionListStatus && (
          <Button
            type="default"
            icon={<IconList />}
            onClick={handleClickList}
            style={{ width: 220, marginRight: 10 }}
          >
            {t("listStatus")}
          </Button>
        )}

        <Button
          type="default"
          icon={<FileExcelOutlined />}
          onClick={() => exportExcel()}
        >
          {t("export")}
        </Button>
      </div>
    </div>
  );
};
export default PerformanceHeader;
