import { useAppDispatch, useAppSelector } from "../../../../store/store";
import {
  ApprovalRequestType,
  FilterPerformanceDataType,
  HistoryParameterType,
} from "../interfaces";
import {
  updateToggle,
  toggleMap,
  displayTarget,
  displayType,
  updateDisplayType,
  updateDisplayTarget,
  workspaces,
  employees,
  userInformation,
  goalCategory,
  mapEmployee,
  mapProportion,
  mapWorkspace,
  renderRowMap,
  openHistoryPerformance,
  historyPerformance,
  statusTable,
  updateStatusTable,
  updateFilterConfiguration,
  filterConfiguration,
  dataHistoryPerformance,
  updateSubjectHierarchy,
  followSubject,
  selectedSubject,
  addFollowSubject,
  expandedSubject,
  resetToggleTrace,
  setDefaultToggle,
  mapEmployeeManagement,
  roleType,
  updateRoleType,
  mapManagement,
  concurrentlyWorkspace,
  resetStatePerformance,
} from "./slice";
import {
  getListWorkspace,
  getListEmployee,
  getEmployeeDetail,
  getPerformanceByOneSubject,
  getPerformanceByManySubject,
  getGoalCategory,
  getHistoryPerformance,
  sentApproveRequest,
  fetchDataUserManagement,
  exportExcelMultipleSubject,
  exportExcelOneSubject,
  fetchAdditionSubject,
} from "./thunk";
const usePerformanceManagement = () => {
  const _toggleMap = useAppSelector(toggleMap);
  const dispatch = useAppDispatch();
  const _setDefaultToggle = (params: any) => {
    dispatch(setDefaultToggle(params));
  };
  const _updateSubjectHierarchy = (params: any) => {
    dispatch(updateSubjectHierarchy(params));
  };
  const _addFollowSubject = (params: number[]) => {
    dispatch(addFollowSubject(params));
  };
  const _updateStatusTable = (param: number) => {
    dispatch(updateStatusTable(param));
  };
  const _updateFilterConfiguration = (params: any) => {
    dispatch(updateFilterConfiguration(params));
  };

  const _updateToggleMap = (params: any) => {
    dispatch(updateToggle(params));
  };
  const _updateDisplayType = (params: any) => {
    dispatch(updateDisplayType(params));
  };
  const _updateDisplayTarget = (params: any) => {
    dispatch(updateDisplayTarget(params));
  };
  const _fetchListWorkspace = () => {
    dispatch(getListWorkspace({}));
  };
  const _fetchListEmployees = () => {
    dispatch(getListEmployee({}));
  };
  const _fetchPerformanceByManySubject = (
    params: FilterPerformanceDataType
  ) => {
    dispatch(getPerformanceByManySubject(params));
  };
  const _fetchPerformanceByOneSubject = (params: FilterPerformanceDataType) => {
    dispatch(getPerformanceByOneSubject(params));
  };
  const _resetToggleTrace = () => {
    dispatch(resetToggleTrace());
  };
  const _fetchUserDetail = (id: string) => {
    dispatch(getEmployeeDetail(id));
  };
  const _fetchGoalCategory = () => {
    dispatch(getGoalCategory());
  };
  const _fetchAdditionSubject = (params: any) => {
    dispatch(fetchAdditionSubject(params));
  };
  const _fetchEmployeeManagement = async (id: string) => {
    const data = await dispatch(fetchDataUserManagement({ ids: id }));
    let result: number[] = [];
    data.payload.data.forEach((user: any) => {
      const _ids: number[] = user.managements
        .map((workspace: any) => {
          if ("directly" in workspace && workspace.directly.length > 0) {
            return workspace.directly.map((item: any) => Number(item.id));
          }
          return null;
        })
        .filter((item: any) => item);
      result = [...result, ..._ids.flat()];
    });
    const followIds = [...new Set(result)].join(",");
    if (followIds.length > 0) {
      dispatch(fetchDataUserManagement({ ids: followIds }));
    }

    return data;
  };
  const _openHistoryPerformance = (params: HistoryParameterType | null) => {
    const data = dispatch(openHistoryPerformance(params));
    return data;
  };
  const _fetchHistoryPerformance = (params: HistoryParameterType) => {
    const data = dispatch(getHistoryPerformance(params));
    return data;
  };
  const _sentApproveRequest = (params: ApprovalRequestType) => {
    dispatch(sentApproveRequest(params));
  };
  const _exportExcelMultipleSubject = (params: any) => {
    dispatch(exportExcelMultipleSubject(params));
  };
  const _exportExcelOneSubject = (params: any) => {
    dispatch(exportExcelOneSubject(params));
  };

  const _updateRoleType = (param: any) => {
    dispatch(updateRoleType(param));
  };
  const _resetStatePerformance = () => {
    dispatch(resetStatePerformance());
  };
  const _displayTarget = useAppSelector(displayTarget);
  const _displayType = useAppSelector(displayType);
  const _workspaces = useAppSelector(workspaces);
  const _employees = useAppSelector(employees);
  const _userInformation = useAppSelector(userInformation);
  const _goalCategory = useAppSelector(goalCategory);
  const _mapEmployee = useAppSelector(mapEmployee);
  const _mapProportion = useAppSelector(mapProportion);
  const _mapWorkspace = useAppSelector(mapWorkspace);
  const _renderRowMap = useAppSelector(renderRowMap);
  const _historyPerformance = useAppSelector(historyPerformance);
  const _statusTable = useAppSelector(statusTable);
  const _filterConfiguration = useAppSelector(filterConfiguration);
  const _dataHistoryPerformance = useAppSelector(dataHistoryPerformance);
  const _selectedSubject = useAppSelector(selectedSubject);
  const _followSubject = useAppSelector(followSubject);
  const _expandedSubject = useAppSelector(expandedSubject);
  const _mapEmployeeManagement = useAppSelector(mapEmployeeManagement);
  const _roleType = useAppSelector(roleType);
  const _mapManagement = useAppSelector(mapManagement);
  const _concurrentlyWorkspace = useAppSelector(concurrentlyWorkspace);
  return {
    updateToggleMap: _updateToggleMap,
    updateSubjectHierarchy: _updateSubjectHierarchy,
    updateDisplayType: _updateDisplayType,
    updateDisplayTarget: _updateDisplayTarget,

    updateRoleType: _updateRoleType,
    openHistoryPerformance: _openHistoryPerformance,
    addFollowSubject: _addFollowSubject,
    updateStatusTable: _updateStatusTable,
    updateFilterConfiguration: _updateFilterConfiguration,
    setDefaultToggle: _setDefaultToggle,
    resetStatePerformance: _resetStatePerformance,

    sentApproveRequest: _sentApproveRequest,
    fetchUserDetail: _fetchUserDetail,
    fetchPerformanceByOneSubject: _fetchPerformanceByOneSubject,
    fetchPerformanceByManySubject: _fetchPerformanceByManySubject,
    fetchAdditionSubject: _fetchAdditionSubject,
    fetchListWorkspace: _fetchListWorkspace,
    fetchListEmployee: _fetchListEmployees,
    fetchHistoryPerformance: _fetchHistoryPerformance,
    fetchGoalCategory: _fetchGoalCategory,
    fetchEmployeeManagement: _fetchEmployeeManagement,
    exportExcelOneSubject: _exportExcelOneSubject,
    exportExcelMultipleSubject: _exportExcelMultipleSubject,
    resetToggleTrace: _resetToggleTrace,
    mapManagement: _mapManagement,
    toggleMap: _toggleMap,
    roleType: _roleType,
    employees: _employees,
    workspaces: _workspaces,
    displayType: _displayType,
    mapEmployee: _mapEmployee,
    statusTable: _statusTable,
    goalCategory: _goalCategory,
    mapWorkspace: _mapWorkspace,
    renderRowMap: _renderRowMap,
    displayTarget: _displayTarget,
    mapProportion: _mapProportion,
    followSubject: _followSubject,
    userInformation: _userInformation,
    selectedSubject: _selectedSubject,
    expandedSubject: _expandedSubject,
    historyPerformance: _historyPerformance,
    filterConfiguration: _filterConfiguration,
    dataHistoryPerformance: _dataHistoryPerformance,
    mapEmployeeManagement: _mapEmployeeManagement,
    concurrentlyWorkspace: _concurrentlyWorkspace,
  };
};
export default usePerformanceManagement;
