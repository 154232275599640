import { lazy } from 'react';
import GperformanceDashboardWorkspaceDetail from '../../features/g-performance/dashboard/workspace-detail';
import PerformanceManagement from '../../features/g-performance/performance-management';
import {
  ROLE_PERMISSIONS_FLATTEN,
  ROUTE_PATH_NAME_BY_ID
} from '../../helpers/permissionConstant';

const GoalGroup = lazy(() => import('../../features/g-performance/goal-group'));

const Goal = lazy(() => import('../../features/g-performance/goal'));

const ParamManagement = lazy(
  () => import('../../features/g-performance/param-management')
);

const DataManagement = lazy(
  () => import('../../features/g-performance/data-management')
);

const SyncHistory = lazy(
  () => import('../../features/g-performance/sync-history')
);

const ComputingManagement = lazy(
  () => import('../../features/g-performance/computing-management')
);

const ResultManagement = lazy(
  () => import('../../features/g-performance/result-management')
);

const APPROVE = lazy(() => import('../../features/g-performance/result-approve'));

const ListStatus = lazy(
  () => import('../../features/g-performance/list-status')
);

const Dashboard = lazy(() => import('../../features/g-performance/dashboard'));
const DashboardWorkspaceDetail = lazy(() => import('../../features/g-performance/dashboard/workspace-detail'));
const DashboardUserDetail = lazy(() => import('../../features/g-performance/dashboard/user-detail'));


const { G_PERFORMANCE } = ROLE_PERMISSIONS_FLATTEN;

export const routeGPerformance = [
  {
    path: ROUTE_PATH_NAME_BY_ID[G_PERFORMANCE.RESULT_PERFORMANCE_MANAGEMENT],
    component: ResultManagement
  },
  {
    path: ROUTE_PATH_NAME_BY_ID[G_PERFORMANCE.PERFORMANCE_MANAGEMENT],
    component: PerformanceManagement
  },
  {
    path: ROUTE_PATH_NAME_BY_ID[G_PERFORMANCE.COMPUTING_MANAGEMENT],
    component: ComputingManagement
  },
  {
    path: ROUTE_PATH_NAME_BY_ID[G_PERFORMANCE.GOAL_MANAGEMENT_GROUP],
    component: GoalGroup
  },
  {
    path: ROUTE_PATH_NAME_BY_ID[G_PERFORMANCE.GOAL_MANAGEMENT_GOAL],
    component: Goal
  },
  {
    path: ROUTE_PATH_NAME_BY_ID[G_PERFORMANCE.PARAM_MANAGEMENT],
    component: ParamManagement
  },
  {
    path: ROUTE_PATH_NAME_BY_ID[G_PERFORMANCE.RESOURCE_MANAGEMENT],
    component: DataManagement
  },
  {
    path: ROUTE_PATH_NAME_BY_ID[G_PERFORMANCE.HISTORY_SYNC_DATA],
    component: SyncHistory
  },
  {
    path: ROUTE_PATH_NAME_BY_ID[G_PERFORMANCE.APPROVE_PERFORMANCE],
    component: APPROVE
  },
  {
    path: ROUTE_PATH_NAME_BY_ID[G_PERFORMANCE.PERFORMANCE_MANAGEMENT_LIST_STATUS],
    component: ListStatus
  },
  {
    path: ROUTE_PATH_NAME_BY_ID[G_PERFORMANCE.DASHBOARD],
    component: Dashboard
  },
  {
    path: '/g-performance/dashboard/workspace-detail/:id',
    component: DashboardWorkspaceDetail
  },
  {
    path: '/g-performance/dashboard/user-detail/:id',
    component: DashboardUserDetail
  },
];
