import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '../../../../store/store';
import {
  downloadFile,
  getIds,
  initialState,
  mapDataDetail,
  mapDataTable,
  mapEmployeeData,
  mapTreeData,
  StatusOperatorEnum
} from '../utils';
import {
  add,
  deleteGoal,
  detail,
  downloadTemplate,
  exportExcel,
  getList,
  getListEmployee,
  getListFilterGoal,
  getListGoal,
  getAllGoals,
  getListMore,
  getListWorkspace,
  importGoal,
  update,
  upload
} from './thunk';

export const computingManagementSlice = createSlice({
  name: 'gPerformanceComputingManagement',
  initialState,
  reducers: {
    reset: (state) => ({...initialState,  listEmployee: state.listEmployee, listSubject: state.listSubject}),
    toggleAddModal: (state) => {
      state.showAddModal = !state.showAddModal;
    },
    toggleUploadModal: (state) => {
      state.showUploadModal = !state.showUploadModal;
    },
    toggleEditModal: (state) => {
      state.showEditModal = !state.showEditModal;
    },
    toggleExpandedSearch: (state) => {
      state.expandedSearch = !state.expandedSearch;
    },
    updateFilterParams: (state, action) => {
      state.filterParams = { ...action.payload };
    },
    clearSlice: (state) => {
      state.action = initialState.action;
      state.errorAddGoal = initialState.errorAddGoal;
      state.errorEditGoal = initialState.errorEditGoal;
    },
    updateAction: (state, action) => {
      state.action = action.payload;
    },
    clearFormFilter: (state, action) => {
      state.filterParams = initialState.filterParams;
      state.resetFormFilter = action.payload;
    },
    setErrorAddGoal: (state, action) => {
      state.errorAddGoal = action.payload;
    },
    setErrorEditGoal: (state, action) => {
      state.errorEditGoal = action.payload;
    },
    setFileIdUploadSuccess: (state, action) => {
      state.fileIdUploadSuccess = action.payload;
    },
    setFiles: (state, action) => {
      state.files = action.payload;
    },
    setHeightFilter: (state, action) => {
      state.heightFilter = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getList.pending, (state) => {
        state.loading.list = true;
      })
      .addCase(getList.fulfilled, (state, action) => {
        state.loading.list = false;
        state.list = mapDataTable([...action.payload.data]);
        state.paginate = { ...action.payload.paginate };
      })
      .addCase(getListFilterGoal.pending, (state, action) => {
        state.loading.searchGoal = true;
      })
      .addCase(getListFilterGoal.fulfilled, (state, action) => {
        state.loading.searchGoal = false;
        state.listGoal = action.payload?.goals.map((_item: any) => ({
          label: _item.name,
          value: _item.id
        }));
      })
      .addCase(getListWorkspace.fulfilled, (state, action) => {
        if (action.meta.arg?.status === StatusOperatorEnum.Active) {
          state.listSubjectActive = [mapTreeData(action.payload.data)?.[0]];
        } else {
          state.listSubject = [mapTreeData(action.payload.data)?.[0]];
        }
        state.listSubjectIds = getIds(action.payload.data, [])
      })
      .addCase(getListEmployee.pending, (state, action) => {
        if (action.meta.arg?.status === StatusOperatorEnum.Active) {
          state.loading.employeeActive = true;
        } else {
          state.loading.employee = true;
        }
      })
      .addCase(getListEmployee.fulfilled, (state, action) => {
        if (action.meta.arg?.status === StatusOperatorEnum.Active) {
          state.listEmployeeActive = mapEmployeeData(action.payload.data);
          state.loading.employeeActive = false;
        } else {
          state.listEmployee = mapEmployeeData(action.payload.data);
          state.loading.employee = false;
        }
      })
      .addCase(getListGoal.fulfilled, (state, action) => {
        state.listGoal = action.payload.data.map((_item: any) => ({
          label: _item.name,
          value: _item.id
        }));
      })
      .addCase(getAllGoals.fulfilled, (state, action) => {
        state.listAllGoals = action.payload.data.map((_item: any) => ({
          label: _item.name,
          value: _item.id
        }));
      })
      .addCase(add.pending, (state, action) => {
        state.loading.add = true;
      })
      .addCase(add.rejected, (state, action) => {
        state.loading.add = false;
      })
      .addCase(add.fulfilled, (state, action) => {
        state.showAddModal = false;
        state.loading.add = false;
      })
      .addCase(downloadTemplate.fulfilled, (state, action) => {
        downloadFile(action.payload);
      })
      .addCase(exportExcel.fulfilled, (state, action) => {
        downloadFile(action.payload);
      })
      .addCase(getListMore.pending, (state) => {
        state.loading.loadMore = true;
      })
      .addCase(getListMore.fulfilled, (state, action) => {
        state.paginate = { ...action.payload.paginate };
        const _currentList = [...state.list];
        const _listMore = mapDataTable([...action.payload.data]);
        const _list = [..._currentList, ..._listMore];
        state.list = _list.map((_item: any, index: number) => ({
          ..._item,
          index: index + 1
        }));
        state.loading.loadMore = false;
      })
      .addCase(detail.pending, (state, action) => {
        state.goalDetail = null;
        state.loading.details = true;
      })
      .addCase(detail.fulfilled, (state, action) => {
        state.goalDetail = mapDataDetail(action.payload?.data);
        state.loading.details = false;
      })
      .addCase(update.pending, (state, action) => {
        state.loading.update = true;
      })
      .addCase(update.rejected, (state, action) => {
        state.loading.update = false;
      })
      .addCase(upload.pending, (state, action) => {
        state.loading.upload = true;
      })
      .addCase(upload.rejected, (state, action) => {
        state.loading.upload = false;
      })
      .addCase(upload.fulfilled, (state, action) => {
        state.fileIdUploadSuccess = [
          ...state.fileIdUploadSuccess,
          action.payload?.data?.id
        ];
      })
      .addCase(importGoal.pending, (state, action) => {
        state.loading.import = true;
      })
      .addCase(importGoal.fulfilled, (state, action) => {
        state.showUploadModal = false;
        state.loading.import = false;
        state.loading.upload = false;
        state.fileIdUploadSuccess = [];
      })
      .addCase(update.fulfilled, (state, action) => {
        state.showEditModal = false;
        state.loading.update = false;
      })
      .addCase(deleteGoal.pending, (state, action) => {
        state.loading.delete = true;
      })
      .addCase(deleteGoal.rejected, (state, action) => {
        state.loading.delete = false;
      })
      .addCase(deleteGoal.fulfilled, (state, action) => {
        state.loading.delete = false;
      });
  }
});

export const {
  toggleAddModal,
  toggleUploadModal,
  updateFilterParams,
  clearSlice,
  updateAction,
  clearFormFilter,
  reset,
  toggleEditModal,
  setErrorAddGoal,
  setErrorEditGoal,
  toggleExpandedSearch,
  setFileIdUploadSuccess,
  setFiles,
  setHeightFilter
} = computingManagementSlice.actions;

export const gPerformanceComputingManagement = (state: RootState) =>
  state.gPerformanceComputingManagement;

export default computingManagementSlice.reducer;
