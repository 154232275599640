import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../store/store";
import { convertStatus, downloadFile, initialState, KpiParam } from "../utils";
import {
  deleteParam,
  downloadParams,
  genResourceCode,
  getEndpointSource,
  getFields,
  getList,
  getListMore,
  getParam,
  postParam,
  updateParam
} from "./thunk";

export const kpiParamSlice = createSlice({
  name: "kpiParam",
  initialState,
  reducers: {
    reset: () => initialState,
    toggleAddParam: (state: KpiParam) => {
      state.showAddParam = !state.showAddParam;
    },
    updateFilterParams: (state: KpiParam, action) => {
      state.filterParams = { ...action.payload };
    },
    clearSlice: (state: KpiParam) => {
      state.resourceCode = initialState.resourceCode;
      state.action = initialState.action;
      state.param = initialState.param;
      state.fields = [...initialState.fields];
      state.resources = { ...initialState.resources };
    },
    updateAction: (state: KpiParam, action) => {
      state.action = action.payload;
    },
    clearFormFilter: (state: KpiParam, action) => {
      state.filterParams = initialState.filterParams;
      state.resetFormFilter = action.payload;
    },
    clearFields: (state: KpiParam) => {
      state.fields = [...initialState.fields];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getList.pending, (state) => {
        state.status = "loading";
        state.loading.list = true;
        state.list = initialState.list;
      })
      .addCase(getList.fulfilled, (state, action) => {
        state.status = "idle";
        const _list = [
          ...action.payload.data.map((_param: any) => ({
            ..._param,
            statusShow: convertStatus(_param.status)
          }))
        ];
        state.list = _list;
        state.paginate = { ...action.payload.paginate };
        state.loading.list = false;
      })
      .addCase(getList.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getFields.pending, (state, action) => {
        state.status = "loading";
        state.loading.fields = true;
        state.fields = [...initialState.fields];
        state.resources = { ...initialState.resources };
      })
      .addCase(getFields.fulfilled, (state, action) => {
        state.status = "idle";
        const _data = action.payload.data;
        state.fields[0].value = _data.template?.data.map((_item: string) => ({
          field: _item
        }));
        state.resources = { ..._data };
        state.loading.fields = false;
      })
      .addCase(postParam.pending, (state, action) => {
        state.status = "loading";
        state.loading.addParam = true;
      })
      .addCase(postParam.fulfilled, (state, action) => {
        state.status = "idle";
        state.showAddParam = false;
        state.loading.addParam = false;
      })
      .addCase(getListMore.pending, (state) => {
        state.status = "loading";
        state.loading.loadMore = true;
      })
      .addCase(getListMore.fulfilled, (state, action) => {
        state.status = "idle";
        const _currentList = [...state.list];
        state.list = [
          ..._currentList,
          ...(action?.payload?.data.map((_param: any) => ({
            ..._param,
            statusShow: convertStatus(_param.status)
          })) || [])
        ];
        state.paginate = { ...action.payload.paginate };
        state.loading.loadMore = false;
      })
      .addCase(getEndpointSource.fulfilled, (state, action) => {
        state.status = "idle";
        state.endpointSource = action?.payload || [];
      })
      .addCase(genResourceCode.fulfilled, (state, action) => {
        state.status = "idle";
        state.resourceCode = action?.payload || "";
      })
      .addCase(getParam.fulfilled, (state, action) => {
        state.status = "idle";
        state.param = action?.payload || initialState.param;
      })
      .addCase(updateParam.pending, (state, action) => {
        state.status = "loading";
        state.loading.updateParam = true;
      })
      .addCase(updateParam.fulfilled, (state, action) => {
        state.status = "idle";
        state.showAddParam = false;
        state.loading.updateParam = false;
      })
      .addCase(deleteParam.pending, (state, action) => {
        state.status = "loading";
        state.loading.delete = true;
      })
      .addCase(deleteParam.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.delete = false;
      })
      .addCase(downloadParams.fulfilled, (state, action) => {
        state.status = "idle";
        downloadFile(action.payload);
      });
  }
});

export const {
  toggleAddParam,
  updateFilterParams,
  clearSlice,
  updateAction,
  clearFormFilter,
  clearFields,
  reset
} = kpiParamSlice.actions;

export const list = (state: RootState) => state.kpiParamManage.list;
export const showAddParam = (state: RootState) =>
  state.kpiParamManage.showAddParam;
export const filterParams = (state: RootState) =>
  state.kpiParamManage.filterParams;
export const endpointSource = (state: RootState) =>
  state.kpiParamManage.endpointSource;
export const paginate = (state: RootState) => state.kpiParamManage.paginate;
export const resourceCode = (state: RootState) =>
  state.kpiParamManage.resourceCode;
export const action = (state: RootState) => state.kpiParamManage.action;
export const param = (state: RootState) => state.kpiParamManage.param;
export const resetFormFilter = (state: RootState) =>
  state.kpiParamManage.resetFormFilter;
export const loading = (state: RootState) => state.kpiParamManage.loading;
export const fields = (state: RootState) => state.kpiParamManage.fields;
export const resources = (state: RootState) => state.kpiParamManage.resources;

export default kpiParamSlice.reducer;
