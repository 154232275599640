import { createSlice } from "@reduxjs/toolkit";
import { sub } from "date-fns";
import { RootState } from "../../../store/store";
import { NotificationState } from "../types/types";

const initialState: NotificationState = {
  data: [
    {
      id: "1",
      title: "Lorem Ipsum is not simply random text",
      date: sub(new Date(), { minutes: 10 }).toISOString(),
      unread: true,
    },
    {
      id: "2",
      title: "Lorem Ipsum is not simply random text",
      date: sub(new Date(), { minutes: 10 }).toISOString(),
      unread: true,
    },
  ],
};

const notificationSlice = createSlice({
  name: "notification",
  initialState: initialState,
  reducers: {},
});

export default notificationSlice.reducer;

export const selectUnreadNotification = (state: RootState) =>
  state.notification.data.filter((noti) => noti.unread);
