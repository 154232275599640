import { WsCode } from "../../api";
import {
  ApprovalRequestType,
  FilterPerformanceDataType,
  HistoryParameterType,
} from "../interfaces";
import { RequestApiAccountService, RequestApiPrivate } from "./../../api/axios";
const KpiGoalGroupApi = {
  workspaces: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.performanceManagement.workspaces,
        { params }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  employees: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.performanceManagement.users,
        { params }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchDataUserManagement: async (params: any) => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.performanceManagement.userManagement,
        { params }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  userDetail: async (id: string): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.performanceManagement.userDetail + "/" + id,
        {}
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  performanceByOneSubject: async (params: FilterPerformanceDataType) => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.performanceManagement.performanceOfOneSubject,
        { params }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  performanceByManySubject: async (params: FilterPerformanceDataType) => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.performanceManagement.performanceOfManySubject,
        { params }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getGoalCategory: async () => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.performanceManagement.goalCategory
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getHistoryPerformance: async (params: HistoryParameterType) => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.performanceManagement.history,
        { params }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  sentApproveRequest: async (params: ApprovalRequestType) => {
    try {
      const response = await RequestApiPrivate.post(
        WsCode.performanceManagement.sentRequestApproval,
        params
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  fetchDataUserJobs: async (params: any) => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.performanceManagement.userJobs,
        { params }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  exportExcelOneSubject: async (params: any) => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.performanceManagement.exportExcelOneSubject,
        { responseType: "blob", params }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  exportExcelMultipleSubject: async (params: any) => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.performanceManagement.exportExcelMultipleSubject,
        { responseType: "blob", params }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
};
export default KpiGoalGroupApi;
