
import { CheckEnum, GoalTypeEnum, StatusEnum, SubjectEnum } from './enum';
import { ResultManagementSlice, ConfigFilter, OptionSelect } from './interface';
import i18n from '../../../../i18n';
const formatStringMonth = (month: number)=> ('0'+ String(month)).slice(-2)


export const MONTHS: string[] = [
  ...Array.from({ length: 12 }, (_, i) => ('0' + (i + 1)).slice(-2))
];
const offsetYear = 5;
export const YEARS: number[] = [
  ...Array.from(
    { length: offsetYear * 2 + 1 },
    (_, i) => new Date().getFullYear() - offsetYear + i
  )
];

export const MONTH_YEAR_OPTIONS = MONTHS.map((month) => ({
  label: month + '/' + new Date().getFullYear(),
  value: month
}));

export const MONTH_OPTIONS = MONTHS.map((_year) => ({
  label: _year,
  value: _year
}));

export const YEAR_OPTIONS = YEARS.map((_year) => ({
  label: _year,
  value: _year
}));

export const PARAM_HEADER = ['field', 'condition', 'start', 'end', 'result'];

export enum PROCESS {
  NotFinalPoint = 0,
  FinalPoint = 1
}

export enum STATUS {
  Active = 1,
  Inactive
}

export enum SCREEN {
  RESULT = 2
}

export enum ACTION {
  Delete = 'delete',
  Edit = 'edit',
  View = 'view',
  Create = 'create',
  DownloadTemplate = 'download_template',
  ExportExcel = 'export_excel',
  Upload = 'upload'
}

export enum TOAST_TYPE {
  Success = 'success',
  Error = 'error',
  Warning = 'warning'
}

export const PER_PAGE = 20;
export const DEFAULT_PAGE = 1;

export const OPTION_ITEM_ALL = {
  label: i18n.t('gperformance:resultManagement.filter.all'),
  value: CheckEnum.All
};

const MONTH_NOW = new Date().getMonth() + 1;
const OFFSET_MONTH = 1
const MONTH_DEFAULT_SELECT = MONTH_NOW - OFFSET_MONTH <= 0 ? 12 - Math.abs(MONTH_NOW - OFFSET_MONTH) : MONTH_NOW - OFFSET_MONTH
const YEAR_NOW = new Date().getFullYear();
const YEAR_DEFAULT_SELECT = MONTH_NOW - OFFSET_MONTH <= 0 ? YEAR_NOW - 1 : YEAR_NOW
export const DEFAULT_FILTER: ConfigFilter = {
  subjectType: SubjectEnum.Individual,
  employeeId: [OPTION_ITEM_ALL],
  goalType: GoalTypeEnum.Month,
  goalId: [OPTION_ITEM_ALL],
  timeYear: YEAR_DEFAULT_SELECT,
  timeMonth: [formatStringMonth(MONTH_DEFAULT_SELECT)],
  isApproved:[OPTION_ITEM_ALL]
};
export const DEFAULT_PARAMS = {
  per_page: PER_PAGE,
  page: DEFAULT_PAGE,
  type_unit: DEFAULT_FILTER.subjectType,
  type: DEFAULT_FILTER.goalType,
  month: [formatStringMonth(MONTH_DEFAULT_SELECT) + '/' + YEAR_DEFAULT_SELECT],
  target: 'all',
  screen: SCREEN.RESULT,
};

export const initialState: ResultManagementSlice = {
  list: [],
  listSubjectIds: [],
  listSubject: [],
  listWorkspaceActive: [],
  listOriginWorkspace: [],
  listWorkspacePerformance: [],
  listEmployeePerformance: [],
  listEmployee: [],
  listEmployeeActive: [],
  listResult: [],
  listGoalForm: [],
  paginate: {
    total: 0,
    current_page: 0,
    last_page: 0
  },
  loading: {
    loadMore: false,
    list: false,
    add: false,
    delete: false,
    update: false,
    searchResult: false,
    employee: false,
    employeeActive: false,
    editResult: false,
    upload: false,
    import: false,
    details: false,
    performanceEmployee: false,
    performanceWorkspace: false
  },
  fileIdUploadSuccess: [],
  files: [],
  expandedSearch: false,
  status: 'idle',
  showAddModal: false,
  showEditModal: false,
  showUploadModal: false,
  filterParams: { ...DEFAULT_PARAMS },
  action: { id: null, action: ACTION.Create },
  resetFormFilter: false,
  errorAddResult: null,
  codeErrorAddResult: null,
  resultDetail: null,
  errorEditResult: null,
  heightFilter: 0
};


export const APPROVED_OPTIONS : OptionSelect[] = [
  {
    value: PROCESS.NotFinalPoint,
    label: i18n.t('gperformance:resultManagement.filter.notFinal')
  },
  {
    value: PROCESS.FinalPoint,
    label: i18n.t('gperformance:resultManagement.filter.final')
  }
]


export const OPTION_SUBJECT: OptionSelect[] = [
  {
    value: SubjectEnum.Individual,
    label: i18n.t('gperformance:resultManagement.filter.withEmployee')
  },
  {
    value: SubjectEnum.Group,
    label: i18n.t('gperformance:resultManagement.filter.withSubject')
  }
];

export const OPTION_GOAL_TYPE: OptionSelect[] = [
  {
    value: GoalTypeEnum.Month,
    label: i18n.t('gperformance:resultManagement.filter.month')
  },
  {
    value: GoalTypeEnum.Year,
    label: i18n.t('gperformance:resultManagement.filter.year')
  }
];

export const OPTION_STATUS: OptionSelect[] = [
  {
    value: StatusEnum.Created,
    label: i18n.t('gperformance:resultManagement.filter.created')
  },
  {
    value: StatusEnum.SentApprove,
    label: i18n.t('gperformance:resultManagement.filter.sentApprove')
  },
  {
    value: StatusEnum.Approved,
    label: i18n.t('gperformance:resultManagement.filter.approved')
  },
  {
    value: StatusEnum.Rejected,
    label: i18n.t('gperformance:resultManagement.filter.rejected')
  }
];

export const FILTER_FIELD_HAS_CHECK_ALL = [
  'status',
  'employeeId',
  'goalId',
  'timeMonth',
  'isApproved'
];

export const ADD_GOAL_HEADERS = [
  'objectSelect',
  'object',
  'goal',
  'goalType',
  'timeMonth',
  'timeYear',
  'beforeResult',
  'afterResult'
];

export const REGEX_DECIMAL_RESULT = new RegExp(/^((?=.*[1-9]+)(0*([1-9]{1})([0-9]{0,1})(\.[0-9]{1,10})?|0+\.[0-9]{1,10})|100(\.[0]{1,10})?)$/);

export const MAX_GOAL_FILE_UPLOAD = 10;
export const FILE_GOAL_TYPE_UPLOAD = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.template.macroEnabled.12',
  'application/vnd.ms-excel.addin.macroEnabled.12',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12'
];
export const MAX_GOAL_FILE_SIZE = 5;
export const NOW_MONTH = new Date().getMonth() + 1;

export const ERROR_CODE_DUPLICATE = 409;

export const PERFORMANCE_APPROVE = 3;