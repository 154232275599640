import { Modal, notification, Tooltip } from "antd";
import {
  ErrorWaring,
  IconErrorSnackbar,
  IconSuccessSnackbar,
} from "../../../../components/icons";
import { QuestionIcon, TOAST_TYPE } from ".";
import { LoadingRing } from "../../../../components/UI";
import { formatText } from "./helpers";
import i18n from "../../../../i18n"

import { IconRankDown, IconRankUp } from "./icons";

const { confirm } = Modal;

export const openNotification = ({
  message,
  type,
}: {
  message?: string;
  type: string;
}) => {
  const iconType = () => {
    if (type === TOAST_TYPE.Success) return <IconSuccessSnackbar />;
    if (type === TOAST_TYPE.Error) return <IconErrorSnackbar />;
    return <ErrorWaring />;
  };

  const icon = iconType();

  return notification.open({
    message,
    icon,
    placement: "bottomRight",
    className: "kpi-wrapper",
    bottom: 10,
    duration: 5,
    closeIcon: null,
  });
};

export const showConfirm = ({
  message,
  callBack,
  okText,
  cancelText,
}: {
  message: string;
  okText: string;
  cancelText: string;
  callBack: () => void;
}) => {
  return confirm({
    content: (
      <>
        <QuestionIcon />
        <p>{message}</p>
      </>
    ),
    className: "kpi-wrapper",
    centered: true,
    icon: null,
    cancelText,
    okText,
    width: 500,
    onOk() {
      callBack();
    },
  });
};

export const LoadingSSC = ({
  loading,
  message = i18n.t('gperformance:common.loading').toString()
}: {
  loading: boolean;
  message?: string;
})  =>
  loading ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        color: "#000000",
      }}
    >
      <LoadingRing size="md" />
      <h4>{message}</h4>
    </div>
  ) : (
    <></>
  );

export const ProcessBar = ({
  percent,
  color,
  backgroundColor = "#EDEDED",
}: {
  percent: number;
  color: string;
  backgroundColor?: string;
}) =>
  percent !== null ? (
    <div
      style={{
        display: "flex",
        gap: 10,
        fontSize: 16,
        alignItems: "center",
        flexWrap: "nowrap",
      }}
    >
      <div
        style={{
          width: "100%",
          minWidth: 165,
          maxWidth: 165,
          height: 6,
          background: backgroundColor,
          borderRadius: 3,
        }}
      >
        <div
          style={{
            width: percent + "%",
            height: "100%",
            background: color,
            borderRadius: 3,
            border: `1px solid ${color}`,
          }}
        ></div>
      </div>
      <span>{percent}%</span>
    </div>
  ) : (
    <></>
  );

export const renderRank = (_rank: number, _change: number) => {
  let Bottom = <></>;
  if (_change === 0) 
    Bottom = (
      <div
        style={{
          background: "black",
          width: 4,
          height: 4,
          borderRadius: 100,
        }}
      ></div>
    );

  if (_change < 0)
    Bottom = (
      <div style={{display: 'flex', alignItems: 'center'}}>
        <span style={{ color: "#D94D27", marginRight: 3, fontSize: 12 }}>
          {Math.abs(_change)}
        </span>      
        <IconRankDown />       
      </div>
    );

  if (_change > 0)
    Bottom = (
      <div style={{display: 'flex', alignItems: 'center'}}>      
         <span style={{ color: "#47C751", marginRight: 3, fontSize: 12 }}>
           {Math.abs(_change)}
         </span>
        <IconRankUp />  
      </div>
    );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span>{_rank ? _rank : '-'}</span>
      {Bottom}
    </div>
  );
};

export const renderTextWithTooltip = (
  _text: string,
  _maxLength: number = 20
) => {
  if (!_text) return "";
  return _text.length > _maxLength ? (
    <Tooltip title={_text}>
      <span>{formatText(_text, _maxLength)}</span>
    </Tooltip>
  ) : (
    <span>{_text}</span>
  );
}
