import {
  DASHBOARD_ROUTE,
  DEFAULT_PERMISSION_ID,
  DEFAULT_ROUTE_NOT_SETTING_PERMISSION,
  EXTRA_GPERFORMANCE_DASHBOARD_ROUTE,
  EXTRA_GPROCESS_TASK_ROUTE,
  EXTRA_GPROCESS_TICKET_ROUTE,
  ROLE_PERMISSIONS_FLATTEN,
  ROUTE_PATH_NAME_BY_ID,
} from "./permissionConstant";
import { matchRoutes } from "react-router-dom";

const { G_PMS, G_PROCESS, G_PERFORMANCE } = ROLE_PERMISSIONS_FLATTEN;

export function getDataPermissionIds(object: any) {
  if (!object) return [];
  return Object.keys(object)
    .map((key) => object[key])
    .flat()
    .map((v: any) => v.id);
}

//---------------HELPER FUNCTION START---------------------//
const getPermissionPathName = (permissionArr: any[] | undefined): string[] => {
  if (!permissionArr) return [];
  return permissionArr.map((el) => ROUTE_PATH_NAME_BY_ID[el]);
};
//get the authorized routes string array
const getRoutersByPermissionId = (
  permissionIdArr: number[],
  defaultRoutes: string[]
): string[] => {
  let pathNameArr =
    getPermissionPathName(permissionIdArr)?.concat(defaultRoutes);

  if (permissionIdArr.includes(G_PMS.MAIN_DASHBOARD)) {
    pathNameArr = pathNameArr?.concat(DASHBOARD_ROUTE);
  }
  if (permissionIdArr.includes(G_PROCESS.CREATE_TICKET)) {
    pathNameArr = pathNameArr?.concat(EXTRA_GPROCESS_TICKET_ROUTE);
  }
  if (permissionIdArr.includes(G_PROCESS.MY_TASK)) {
    pathNameArr = pathNameArr?.concat(EXTRA_GPROCESS_TASK_ROUTE);
  }
  if (permissionIdArr.includes(G_PERFORMANCE.DASHBOARD)) {
    pathNameArr = pathNameArr?.concat(EXTRA_GPERFORMANCE_DASHBOARD_ROUTE);
  }

  return pathNameArr || [];
};
//get the authorized routes array for checking
const routerProducer = (pathNameArr: any[] | undefined): any[] => {
  if (!pathNameArr) return [];
  return pathNameArr.map((el: any) => {
    return { path: el };
  });
};
//---------------HELPER FUNCTION END---------------------//

//---------------MAIN FUNCTION START---------------------//
//conditional show sidebar by its path name
export const renderSidebarHelper = (
  sidebarPathname: string | undefined,
  authPermission: any[] | undefined
): boolean => {
  let PERMISSION_IDS = [];
  if (authPermission) {
    PERMISSION_IDS = getDataPermissionIds(authPermission);
  }
  if (!PERMISSION_IDS?.length) return false;
  if (!sidebarPathname) return true;
  const pathNameArr = getRoutersByPermissionId(
    PERMISSION_IDS,
    DEFAULT_ROUTE_NOT_SETTING_PERMISSION
  );
  return pathNameArr?.includes(sidebarPathname);
};
//check permissions by comparing its with the login user's permission ids
export const checkPermissionByRoleId = (
  id: number | undefined,
  authPermission: any
) => {
  if (authPermission?.IS_ADMIN === 1) {
    return true;
  }
  const PERMISSION_IDS = getDataPermissionIds(authPermission);
  if (!PERMISSION_IDS || !PERMISSION_IDS.length) return false;
  return (
    authPermission &&
    (PERMISSION_IDS?.includes(id) || id === DEFAULT_PERMISSION_ID)
  );
};
//prevent the user from accessing unauthorized routes
export const checkPermissionByPathName = (
  pathName: string,
  authPermission: any
): boolean => {
  if (authPermission?.is_admin === 1) {
    return true;
  }
  if (!authPermission) return false;
  const PERMISSION_IDS = getDataPermissionIds(authPermission);
  if (!PERMISSION_IDS || !PERMISSION_IDS?.length) return false;
  const pathNameArr = getRoutersByPermissionId(
    PERMISSION_IDS,
    DEFAULT_ROUTE_NOT_SETTING_PERMISSION
  );
  const routes = routerProducer(pathNameArr);
  const authRoute = matchRoutes(routes, pathName);
  return Boolean(authRoute);
};
//---------------MAIN FUNCTION END---------------------//
