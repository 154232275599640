const IconCancel = ({ fill = "black" }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8625 1.1375C12.5125 0.7875 11.9875 0.7875 11.6375 1.1375L7 5.775L2.3625 1.1375C2.0125 0.7875 1.4875 0.7875 1.1375 1.1375C0.7875 1.4875 0.7875 2.0125 1.1375 2.3625L5.775 7L1.1375 11.6375C0.7875 11.9875 0.7875 12.5125 1.1375 12.8625C1.3125 13.0375 1.4875 13.125 1.75 13.125C2.0125 13.125 2.1875 13.0375 2.3625 12.8625L7 8.225L11.6375 12.8625C11.8125 13.0375 12.075 13.125 12.25 13.125C12.425 13.125 12.6875 13.0375 12.8625 12.8625C13.2125 12.5125 13.2125 11.9875 12.8625 11.6375L8.225 7L12.8625 2.3625C13.2125 2.0125 13.2125 1.4875 12.8625 1.1375Z"
        fill={fill}
      />
    </svg>
  );
};
export default IconCancel;
