import { Select } from "antd";
import { FilterSelectType } from "../../interfaces";
import { renderMaxTag, renderTag } from "../tree/renderTag";
import styles from "./style.module.scss";
import { MULTIPLE } from "../../constants";
function FilterSelect(props: FilterSelectType) {
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const setValueData = (value: any) => {
    props.onChange(value);
  };

  const handleClear = () => {
    props.onChange([]);
  };
  const { list, type, value, placeholder } = props;
  const onDeselectItem = () => {};
  return (
    <>
      {type === MULTIPLE ? (
        <Select
          allowClear
          tagRender={renderTag}
          showSearch
          mode="multiple"
          className={styles["select-type"]}
          onChange={setValueData}
          options={list}
          value={value}
          maxTagCount={"responsive"}
          filterOption={filterOption}
          maxTagPlaceholder={renderMaxTag}
          placeholder={placeholder}
          autoClearSearchValue={false}
          onDeselect={onDeselectItem}
          onClear={handleClear}
        ></Select>
      ) : (
        <Select
          showSearch
          disabled={props.disabled}
          className={styles["select-type"]}
          onChange={setValueData}
          autoClearSearchValue={false}
          options={list}
          value={value}
          filterOption={filterOption}
          maxTagPlaceholder={renderMaxTag}
        ></Select>
      )}
    </>
  );
}
export default FilterSelect;
