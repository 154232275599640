import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "../../icons/CalendarIcon";
import "./overwrite.scss";
import styles from "./style.module.scss";
type TypeProps = {
  selected?: any;
  showYearPicker?: any;
  value?:
    | any
    | {
        startTime: any;
        endTime: any;
      };
  onChange?: Function;
  name?: string;
  style?: any;
  dateFormat?: any;
  placeholder?: any;
  defaultValue?:
    | any
    | {
        startTime: any;
        endTime: any;
      };
  period?: Boolean;
  minDate?: any;
  disable?: boolean;
  maxDate?: any;
  error?: boolean;
  onBlur?: any;
};
const DateRange = ({
  value,
  onChange,
  name,
  style,
  dateFormat = "dd/MM/yyyy",
  placeholder,
  defaultValue,
  showYearPicker,
  period,
  disable,
  selected,
  error,
  ...other
}: TypeProps) => {
  const [date, setDate] = useState<any>();
  const inputRef = useRef<any>(null);
  const onChanges = (e: any) => {
    if (onChange) {
      onChange(e);
    }

    setDate(e);
  };

  useEffect(() => {
    if (value) {
      setDate(value);
    }
  }, [value]);

  useEffect(() => {
    if (defaultValue) {
      setDate(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div
      style={style}
      className={
        styles["datepicker-container"] +
        " " +
        (disable ? styles.disable : "") +
        " " +
        (error ? styles.error : "")
      }
    >
      <DatePicker
        clearButtonClassName={styles.clear}
        disabled={disable}
        dateFormat={dateFormat}
        showYearPicker={showYearPicker}
        name={name}
        onChange={onChanges}
        selected={date}
        placeholderText={placeholder ? placeholder : "-"}
        {...other}
        ref={inputRef}
      />
      <span
        onClick={() => {
          inputRef.current.input.focus();
        }}
        className={styles["icon-container"]}
      >
        <CalendarIcon />
      </span>
    </div>
  );
};

export default DateRange;
