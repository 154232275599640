import { RequestApiKpi, RequestApiKpiEndPoint, WsCode } from "../../api";

const KpiDataManagementApi = {
  listFile: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiKpiEndPoint.get(
        WsCode.dataManagement.list,
        { params }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getConfigsResource: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiKpiEndPoint.get(
        WsCode.dataManagement.configsResource
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getListParam: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiKpi.get(WsCode.param.list, {
        params,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  postResource: async (payload: any): Promise<any> => {
    try {
      const response = await RequestApiKpiEndPoint.post(
        WsCode.dataManagement.resources,
        payload
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  upload: async (formdata: any): Promise<any> => {
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    try {
      const response = await RequestApiKpiEndPoint.post(
        WsCode.dataManagement.upload,
        formdata,
        config
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  download: async ({
    id,
    params,
  }: {
    id: number;
    params: any;
  }): Promise<any> => {
    try {
      const response = await RequestApiKpiEndPoint.get(
        WsCode.dataManagement.download + `/${id}`,
        {
          params,
          responseType: "blob",
        }
      );
      return response;
    } catch (error: any) {
      throw error;
    }
  },
};

export default KpiDataManagementApi;
