import React, { Component, ErrorInfo, ReactNode } from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/img/logo_GGG.png";
import i18n from "../../i18n";
import styles from "./ErrorBoundary.module.scss";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    function goBack(this: any) {
      this.setSate({ hasError: false });
    }
    if (this.state.hasError) {
      return (
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <div className={styles.formContent}>
              <img src={logo} alt="logo" className={styles.logo} />
              <h1 className={styles.title}>
                {i18n.t("layout:errorBoundary.message") as any}
              </h1>
              <Link className={styles.link} onClick={goBack} to="/">
                {i18n.t("layout:errorBoundary.goBack") as any}
              </Link>
            </div>
          </div>
          <footer className={styles.footer}>
            GGG &#169; {new Date().getFullYear()}
          </footer>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
