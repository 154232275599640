const Dot = () => {
  return (
    <svg
      width="24"
      height="6"
      viewBox="0 0 24 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 5.25C4.24264 5.25 5.25 4.24264 5.25 3C5.25 1.75736 4.24264 0.75 3 0.75C1.75736 0.75 0.75 1.75736 0.75 3C0.75 4.24264 1.75736 5.25 3 5.25Z"
        fill="#434243"
      />
      <path
        d="M12 5.25C13.2426 5.25 14.25 4.24264 14.25 3C14.25 1.75736 13.2426 0.75 12 0.75C10.7574 0.75 9.75 1.75736 9.75 3C9.75 4.24264 10.7574 5.25 12 5.25Z"
        fill="#434243"
      />
      <path
        d="M21 5.25C22.2426 5.25 23.25 4.24264 23.25 3C23.25 1.75736 22.2426 0.75 21 0.75C19.7574 0.75 18.75 1.75736 18.75 3C18.75 4.24264 19.7574 5.25 21 5.25Z"
        fill="#434243"
      />
    </svg>
  );
};
export default Dot;
