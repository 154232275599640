import { createAsyncThunk } from "@reduxjs/toolkit";
import { pick } from 'lodash'
import { openNotification, TOAST_TYPE } from "../utils";
import { FilterEmployeeParam, FilterObjectParam } from '../utils/interface';

import ResultApproveApi from "./api";
import { resetColors, setSelectedEmployees, setSelectedSubjects, setInvalidResultMessage } from "./slice";
import { RootState } from "../../../../store/store";

export const getListObjectApprove = createAsyncThunk(
  "gperformanceResultApprove/getListObjectApprove",
  async (params: FilterObjectParam) => {
    try {
      const response = await ResultApproveApi.listObjectApprove(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListObjectApproveMore = createAsyncThunk(
  "gperformanceResultManagement/getListObjectApproveMore",
  async (params: FilterObjectParam) => {
    try {
      const response = await ResultApproveApi.listObjectApprove(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListEmployeeApprove = createAsyncThunk(
  "gperformanceResultApprove/getListEmployeeApprove",
  async (params: FilterEmployeeParam) => {
    try {
      const response = await ResultApproveApi.listEmployeeApprove(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListEmployeeApproveMore = createAsyncThunk(
  "gperformanceResultApprove/getListEmployeeApproveMore",
  async (params: FilterEmployeeParam) => {
    try {
      const response = await ResultApproveApi.listEmployeeApprove(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListWorkspace = createAsyncThunk(
  "gperformanceResultApprove/getListWorkspace",
  async (params?: any) => {
    try {
      const response = await ResultApproveApi.listWorkspace(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListEmployee = createAsyncThunk(
  "gperformanceResultApprove/getListEmployee",
  async (params: any, { dispatch, getState }) => {
    const { initFetching } = params;
    delete params?.initFetching;
    try {
      const response = await ResultApproveApi.listEmployee(params);
      if (initFetching) {
        const state = getState() as RootState;

        const user_ids = response?.data.map((userId: any) => userId?.id)?.join(",");

        const filterObjParams = state.gPerformanceResultApprove.filterObjectParams;
        const filterEmployeeParams = state.gPerformanceResultApprove.filterEmployeeParams;
        dispatch(getListObjectApprove({ ...filterObjParams, user_ids }));
        dispatch(getListEmployeeApprove({ ...filterEmployeeParams, user_ids }));
      }
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const exportExcel = createAsyncThunk(
  "gperformanceResultApprove/exportExcel",
  async (params: any) => {
    try {
      const response = await ResultApproveApi.exportExcel(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const upload = createAsyncThunk(
  "gperformanceResultApprove/upload",
  async (formData: any, { dispatch }) => {
    try {
      const response = await ResultApproveApi.upload(formData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const importResult = createAsyncThunk(
  "gperformanceResultManagement/importResult",
  async (fromData: any, { dispatch }) => {
    try {
      const response = await ResultApproveApi.importResult(fromData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteFile = createAsyncThunk(
  "gperformanceResultManagement/deleteFile",
  async (params: { id: number; message: any }, { dispatch, getState }) => {
    try {
      const response = await ResultApproveApi.deleteFile(params?.id);
      openNotification({
        type: TOAST_TYPE.Success,
        message: params?.message,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const scoreApprove = createAsyncThunk(
  "gperformanceResultManagement/scoreApprove",
  async (payload: any, { dispatch, getState }) => {
    try {
      const response = await ResultApproveApi.scoreApprove(payload.data);
      const state = getState() as RootState;
      openNotification({
        type: TOAST_TYPE.Success,
        message: payload.message
      });
      dispatch(getListObjectApprove(state.gPerformanceResultApprove.filterObjectParams))
      dispatch(getListEmployeeApprove(state.gPerformanceResultApprove.filterEmployeeParams))
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getDetailUser = createAsyncThunk(
  "gperformanceResultManagement/getDetailUser",
  async (id: number | string) => {
    try {
      const response = await ResultApproveApi.detailUser(id);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const updateResultApprove = createAsyncThunk(
  "gperformanceResultApprove/updateResultApprove",
  async (payload: any, { dispatch, getState }) => {
    try {
      const response = await ResultApproveApi.updateResultApprove(payload.id, payload.data);
      const state = getState() as RootState;
      openNotification({
        type: TOAST_TYPE.Success,
        message: payload.message
      });
      dispatch(setInvalidResultMessage(""))
      if (payload.isObjectApprove) {
        dispatch(getListObjectApprove(state.gPerformanceResultApprove.filterObjectParams))
        dispatch(setSelectedSubjects([]))
      } else {
        dispatch(getListEmployeeApprove(state.gPerformanceResultApprove.filterEmployeeParams))
        dispatch(setSelectedEmployees([]))
      }
      return response;
    } catch (error: any) {
      throw error;
    }
  }
)

export const getColors = createAsyncThunk(
  "gperformanceResultApprove/getColors",
  async (id: number | string, { dispatch }) => {
    dispatch(resetColors())
    try {
      const response = await ResultApproveApi.listColor(id);      
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const calculation = createAsyncThunk(
  'gperformanceResultApprove/calculation',
  async (params: {filter: FilterEmployeeParam, message: any }, { dispatch }) => {
    const { filter, message } = params;
    try {
      const paramsCalculation = pick(filter, ['type', 'month', 'year', 'user_ids', 'workspace_ids'])
      const response = await ResultApproveApi.calculation(paramsCalculation);
      openNotification({
        type: TOAST_TYPE.Success,
        message:  message.success.calculation,
      });
      return response;
    } catch (error) {
      openNotification({
        type: TOAST_TYPE.Error,
        message: message.error.calculation
      });
      throw error;
    }
  }
);