import { useTranslation } from "react-i18next";
import UnauthorizedIcon from "../../icons/UnauthorizedIcon";
import styles from "./Unauthorized.module.scss";

const Unauthorized = () => {
  const { t } = useTranslation("layout");
  return (
    <div className={styles.wrapper}>
      <UnauthorizedIcon />
      <p>{t("unauthorized")}</p>
    </div>
  );
};

export default Unauthorized;
