import { StatusEnum } from './enum';
import {round} from 'lodash'

type Tree<T> = {
  children?: T[];
  key: number;
  status?: StatusEnum;
  disabled?: boolean;
};

export const findKeyByLevel = <T extends Tree<T>>(
  obj: T,
  level: number,
  initKeys: number[] = [],
  initLevel: number = 1
) => {
  if(!obj) return initKeys
  if (initLevel === 1) {
    initKeys.push(obj.key);
  }
  if (!obj?.children?.length || level === initLevel) return initKeys;
  obj.children?.forEach((_child) => {
    initKeys.push(_child.key);
    findKeyByLevel(_child, level, initKeys, initLevel + 1);
  });
  return initKeys;
};

function findNestedObj<T extends Tree<T>>(
  entireObj: T,
  keyToFind: string,
  valToFind: number
) {
  let foundObj;
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind] === valToFind) {
      foundObj = nestedValue;
    }
    return nestedValue;
  });
  return foundObj;
}

export const finObjFormKey = <T extends Tree<T>>(
  obj: T,
  result: T | null,
  key: number
) => {
  if (obj.key === key) return obj;
  obj.children?.forEach((_child) => {
    if (_child.key === key) {
      result = { ..._child };
    }
    finObjFormKey(_child, result, key);
  });

  return result;
};

export const findAllKeyChildren = <T extends Tree<T>>(
  parent: T,
  keys: number[] = [],
  status: StatusEnum
) => {
  if (!parent.children) return [];
  parent.children.forEach((_child) => {
    if (status === _child.status) {
      keys.push(_child.key);
    }
    findAllKeyChildren(_child, keys, status);
  });
  return keys;
};

export const findAllKeyChildrenFormKey = <T extends Tree<T>>(
  parent: T,
  keys: number[] = [],
  status: StatusEnum,
  key: number
) => {
  const currentObj = findNestedObj(parent, 'key', key);
  return currentObj ? findAllKeyChildren(currentObj, keys, status) : [];
};

export const findAllChildren = <T extends Tree<T>>(
  parent: T,
  keys: any[] = [],
  status: StatusEnum,
) => {
  if (!parent.children) return [];
  parent.children.forEach((_child) => {
    if (status === _child.status && !_child?.disabled) {
      keys.push(_child);
    }
    findAllChildren(_child, keys, status);
  });
  return keys;
};

export const findAllChildrenFormKey = <T extends Tree<T>>(
  parent: T,
  keys: number[] = [],
  status: StatusEnum,
  key: number
) => {
  const currentObj = findNestedObj(parent, 'key', key);
  return currentObj ? findAllChildren(currentObj, keys, status) : [];
};


export const formatShowDecimalNumber = (_number: string | number) => round(Number(_number), 2)