import React from "react";

const IconSuccessSnackbar = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 0C12.8355 0 9.74207 0.938384 7.11088 2.69649C4.4797 4.45459 2.42894 6.95345 1.21793 9.87706C0.0069325 12.8007 -0.309921 16.0177 0.307443 19.1214C0.924806 22.2251 2.44866 25.0761 4.6863 27.3137C6.92394 29.5513 9.77487 31.0752 12.8786 31.6926C15.9823 32.3099 19.1993 31.9931 22.1229 30.7821C25.0466 29.5711 27.5454 27.5203 29.3035 24.8891C31.0616 22.2579 32 19.1645 32 16C31.9876 11.7603 30.2979 7.69789 27.3 4.69999C24.3021 1.70209 20.2397 0.0123994 16 0V0ZM13 22.414L7.58601 17L9.00001 15.586L13 19.586L23 9.586L24.414 11L13 22.414Z"
        fill="#47C751"
      />
    </svg>
  );
};

export default IconSuccessSnackbar;
