import type { MenuProps } from 'antd';
import { Dropdown, Modal, notification, Tooltip } from 'antd';
import { IconCheck, QuestionIcon, ResultApproveData, TOAST_TYPE } from '.';
import {
  ErrorWaring,
  IconErrorSnackbar,
  IconSuccessSnackbar,
} from '../../../../components/icons';
import { LoadingRing } from '../../../../components/UI';
import i18n from '../../../../i18n';
import { formatText } from './helpers';
import styles from '../components/list/style.module.scss';
import { CSSProperties } from 'react';

const { confirm } = Modal;

export const openNotification = ({
  message,
  type
}: {
  message?: string;
  type: string;
}) => {
  const iconType = () => {
    if (type === TOAST_TYPE.Success) return <IconSuccessSnackbar />;
    if (type === TOAST_TYPE.Error) return <IconErrorSnackbar />;
    return <ErrorWaring />;
  };

  const icon = iconType();

  return notification.open({
    message,
    icon,
    placement: 'bottomRight',
    className: 'kpi-wrapper',
    bottom: 10,
    duration: 5,
    closeIcon: null
  });
};

export const showConfirm = ({
  message,
  callBack,
  okText,
  cancelText
}: {
  message: string;
  okText: string;
  cancelText: string;
  callBack: () => void;
}) => {
  return confirm({
    content: (
      <>
        <QuestionIcon />
        <p>{message}</p>
      </>
    ),
    className: 'kpi-wrapper',
    centered: true,
    icon: null,
    cancelText,
    okText,
    width: 500,
    onOk() {
      callBack();
    }
  });
};

export const showSuccess = ({
  message,
  callBack,
  okText,
  cancelText
}: {
  message: string;
  okText: string;
  cancelText: string;
  callBack?: () => void;
}) => {
  return confirm({
    content: (
      <>
        <IconSuccessSnackbar />
        <p>{message}</p>
      </>
    ),
    cancelButtonProps: { style: { display: 'none' } },
    className: 'kpi-wrapper',
    centered: true,
    icon: null,
    cancelText,
    okText,
    width: 500,
    onOk() {
      callBack && callBack();
    }
  });
};

export const LoadingSSC = ({
  loading,
  message = i18n.t('gperformance:common.loading').toString()
}: {
  loading: boolean;
  message?: string;
}) =>
  loading ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        color: "#000000",
      }}
    >
      <LoadingRing size='md' />
      <h4>{message}</h4>
    </div>
  ) : (
    <></>
  );

export const renderTextWithTooltip = (_text: string, _maxLength: number = 20) => {
  if (!_text) return '';
  return _text.length > _maxLength ? (
    <Tooltip title={_text}>
      <span>{formatText(_text)}</span>
    </Tooltip>
  ) : (
    <span>{_text}</span>
  );
};

export const configNotify = (arg: any) => ({
  key: 'toastUpload',
  icon: <IconSuccessSnackbar />,
  placement: 'bottomRight',
  className: 'kpi-wrapper',
  bottom: 10,
  closeIcon: null,
  ...arg,
});

export const renderResultWithColor = (
  text: string,
  record: ResultApproveData
) => {
  if (!text) return '';
  const styleDotPerformance:CSSProperties = {
    width: '12px',
    height: '12px',
    borderRadius: '9999px',
    background: record.resultColor,
    margin: '0px 4px 0px 13px'
  }
  const styleCellWrapper:CSSProperties = {display: 'flex', justifyContent: 'center'}
  const styleCellInner:CSSProperties = 
  { width: 100, padding: '8px 4px 8px 8px', display: 'flex', alignItems: 'center', justifyContent: 'end' }

  return (
    <div style={styleCellWrapper}>
      <div style={styleCellInner}>
        <span>{Number(text)}%</span>
        <div style={styleDotPerformance}/>
      </div>
    </div>
  );
};

export const LoadingDropdown = ({
  loading
}: {
  loading: boolean
  message?: string
}) =>
  loading ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <LoadingRing size="xs" />
    </div>
  ) : (
    <></>
  )