import { Image } from "antd"
import icon from "../../assets/icon/mobile-detected-icon.svg"
import "./styles/styles.scss"

const MobileDetected = () => {

  return (
    <div className="mobile-detected-container">
      <Image src={icon} width={166} height={139}/>
      <p className="message"> Truy cập hệ thống trên laptop hoặc Desktop để có trải nghiệm tốt nhất</p>
    </div>
  );
}

export default MobileDetected;