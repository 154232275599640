import { createAsyncThunk } from '@reduxjs/toolkit';
import KpiManagementApi from './api';
import { clearFormFilter, clearSlice } from './slice';
import { FilterParam } from '../utils/interface';
import { DEFAULT_PARAMS, openNotification, TOAST_TYPE } from '../utils';

export const getList = createAsyncThunk(
  'kpiManagement/getList',
  async (params: FilterParam, { getState }) => {
    const state: any = getState();
    const _authUser: any = state?.auth?.authUser || '';

    let _params: any;
    _params = {
      user_id: 43,
      role: 'HOD',
      ...params,
      year: params.year || 2022
    };

    if (_params.staus) {
      delete _params.status;
    }

    params.status?.map((e: any, index: any) => {
      _params[`status[${index}]`] = e;
    });
    try {
      const response = await KpiManagementApi.list(_params || params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListMore = createAsyncThunk(
  'kpiManagement/getListMore',
  async (params: FilterParam, { getState }) => {
    const state: any = getState();
    const _authUser: any = state?.auth?.authUser || '';
    let _params;
    _params = {
      user_id: 43,
      role: 'HOD',
      ...params
    };
    try {
      const response = await KpiManagementApi.list(_params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const add = createAsyncThunk(
  'kpiManagement/add',
  async (payload: { body: any; message: any }, { dispatch, getState }) => {
    const { body, message } = payload;
    const state: any = getState();
    const _authUser: any = state?.auth?.authUser || 43;
    let _body;
    _body = {
      role: 'HOD',
      ...body,
      directManagements: body.direct_managements
    };
    try {
      const response = await KpiManagementApi.add(_body);
      dispatch(clearFormFilter(true));
      dispatch(getList({ ...DEFAULT_PARAMS }));
      openNotification({
        type: TOAST_TYPE.Success,
        message: message.success.create
      });
      return response;
    } catch (error) {
      openNotification({
        type: TOAST_TYPE.Error,
        message: message.error.add
      });
      throw error;
    }
  }
);

export const detail = createAsyncThunk(
  'kpiManagement/detail',
  async (params: any) => {
    try {
      const response = await KpiManagementApi.detail(params);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const update = createAsyncThunk(
  'kpiManagement/update',
  async (payload: { id: number; body: any; message: any }, { dispatch }) => {
    const { id, body, message } = payload;
    try {
      const response = await KpiManagementApi.update(id, body);
      dispatch(clearFormFilter(true));
      dispatch(getList({ ...DEFAULT_PARAMS }));
      openNotification({
        type: TOAST_TYPE.Success,
        message: message.success.edit
      });
      return response.data;
    } catch (error) {
      openNotification({
        type: TOAST_TYPE.Error,
        message: message.error.unique
      });
      throw error;
    }
  }
);

export const deleteKpi = createAsyncThunk(
  'kpiManagement/deleteKpi',
  async (params: { id: number; message: any }, { dispatch }) => {
    const { id, message } = params;
    dispatch(clearSlice());
    try {
      const response = await KpiManagementApi.delete(id);
      dispatch(clearFormFilter(true));
      dispatch(getList({ ...DEFAULT_PARAMS }));
      openNotification({
        type: TOAST_TYPE.Success,
        message: message.success.delete
      });
      return response;
    } catch (error) {
      openNotification({
        type: TOAST_TYPE.Error,
        message: message.error.delete
      });
      throw error;
    }
  }
);

export const getDepartments = createAsyncThunk(
  'kpiManagement/getDepartments',
  async () => {
    try {
      const response = await KpiManagementApi.departments();
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getUserDivisions = createAsyncThunk(
  'kpiManagement/getUserDivisions',
  async (_, { getState }) => {
    const state: any = getState();
    const _authUser: any = state?.auth?.authUser || '';
    const _params = {
      department_id: _authUser?.department?.id || ''
    };
    try {
      const response = await KpiManagementApi.divisions(_params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getDivisions = createAsyncThunk(
  'kpiManagement/getDivisions',
  async (params: any) => {
    try {
      const response = await KpiManagementApi.divisions(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getEmployees = createAsyncThunk(
  'kpiManagement/getEmployees',
  async (params: any) => {
    try {
      const response = await KpiManagementApi.employees(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getGoals = createAsyncThunk(
  'kpiManagement/getGoals',
  async (params: any) => {
    try {
      const response = await KpiManagementApi.goals(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getCreateKpiData = createAsyncThunk(
  'kpiManagement/getCreateKpiData',
  async (params: FilterParam, { getState }) => {
    const state: any = getState();
    const _authUser: any = state?.auth?.authUser || '';
    let _params;
    _params = {
      user_id: 43,
      role: 'HOD',
      ...params,
      year: 2022
    };
    try {
      const response = await KpiManagementApi.createKpiData(_params);
      return { list: response.data, role: _params.role };
    } catch (error) {
      throw error;
    }
  }
);
