import style from "./style.module.scss";

type TypeProps = {
  value: any;
  label?: any;
};

const FormRadio = ({ value, label }: TypeProps) => {
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className={`radio`}></div>
        {value && (
          <div style={label ? { display: "none" } : {}} className={style.value}>
            {value}
          </div>
        )}
        {label ? <div className={style.value}>{label}</div> : ""}
      </div>
    </>
  );
};

export default FormRadio;
