import { NUMBER_REGEX, STATUS } from './constant';
import { FilterParam } from './interface';

export const convertStatus = (_status: number): boolean => {
  if (_status === STATUS.Active) return true;
  return false;
};

export const removeCondition = (el: any) => {
  const _el = { ...el };
  if (_el.conditions) {
    delete _el.conditions;
  }
  return _el;
};

export const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const downloadFileParam = (params: FilterParam) => {
  const _params = { ...params } as {
    page?: number;
    name?: string;
    goal_group_id?: string;
    per_page?: number;
  };
  if (_params.per_page) {
    delete _params.per_page;
  }
  if (_params.page) {
    delete _params.page;
  }
  return _params;
};

export const downloadFile = (response: any): void => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const fileName = 'Danh sách chỉ tiêu.xlsx';
  const link = document.createElement('a');
  link.target = '_blank';
  link.download = fileName;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

const getFileNameInResponseHeader = (headers: any): any => {
  let fileName = '';
  if (!headers) return '';
  if ('content-disposition' in headers) {
    const data = headers['content-disposition']
      .split(';')
      .map((item: string) => item.trim());

    for (let item of data) {
      const disposition = item.split('=');
      if (disposition.length > 0) {
        if (disposition[0] === 'filename') return disposition[1];
      }
    }
  }
  return fileName;
};

export const _checkData = (_object: Object): boolean => {
  let _checkData = true;
  for (const [key, value] of Object.entries(_object)) {
    if (value === undefined || value === '' || !NUMBER_REGEX.test(value)) {
      _checkData = false;
    }
  }
  return _checkData;
}