enum ROLE {
  CHANGE_USER = "changeRoleUser",
  ROOM = "room",
  EMPLOYEE = "employee",
  CEO_FOCUS = "ceo",
}

enum TIME {
  WEEK = "Week",
  MONTH = "Month",
}
enum ENUM_DAYS_IN_WEEK {
  CN = 0,
  T2 = 1,
  T3 = 2,
  T4 = 3,
  T5 = 4,
  T6 = 5,
  T7 = 6,
}
export { ROLE, TIME, ENUM_DAYS_IN_WEEK };
