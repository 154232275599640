import { Button, Form, Select } from "antd";
import { Rule } from "antd/lib/form";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import DropdownEmployee from "../DropdownEmployee";
import { CloseOutlined } from "@ant-design/icons";

import IconArrow from "../../../../../components/icons/IconArrow";
import IconFilter from "../../../../../components/icons/IconFilter";
import {
  DATA_READY,
  DISPLAY_TARGET,
  DISPLAY_TYPES,
  FIELD_NAMES,
  FILTER_SETTINGS,
  MONTH,
  MULTIPLE,
  PERIOD_OPTIONS,
  PERIOD_VALUE,
  REGION_CODE,
  ROLE_TYPE,
  ROLE_TYPE_NAME,
  STATUS_FILTER,
  STATUS_PERFORMANCE,
  STATUS_TABLE,
  YEAR,
} from "../../constants";
import {
  convertValue,
  defaultTimeConfig,
  getChildDepartmentByListIds,
} from "../../helper";
import {
  FilterInitialData,
  FilterPerformanceDataType,
  FilterSetting,
  SelectedValueType,
  SelectOptionType,
  TreeNodeDataType,
} from "../../interfaces";
import usePerformanceManagement from "../../redux/hook";
import FilterSelect from "../select";
/* eslint-disable react-hooks/exhaustive-deps */
import TreeUnit from "../tree";
import { renderMaxTag, renderTag } from "../tree/renderTag";
import {
  findPathToTreeNode,
  getFilterConfigSetting,
  getUnitChildren,
  getWorkspaceWithConcurrently,
  isEmpty,
} from "./helper";
import styles from "./style.module.scss";
import { useSearchParams } from "react-router-dom";
import { arrTypeFile } from "../../../../../helpers/function";
const ALL_OPTION_VALUE = "all";
const FormFilter = ({
  workspaces,
  listEmployee,
  roleType,

  onSearchPerformance,
}: {
  workspaces: TreeNodeDataType[];
  listEmployee: SelectOptionType[];
  roleType: string;

  onSearchPerformance: Function;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    mapEmployee,
    mapWorkspace,
    userInformation,
    displayTarget,
    displayType,
    concurrentlyWorkspace,
    statusTable,
    employees,
  } = usePerformanceManagement();
  const [form] = Form.useForm();
  const [selectedEmployees, setSelectedEmployees] = useState<
    Array<string | number> | number | string
  >([]);
  const [status, setStatus] = useState<Array<string | number>>([]);
  const [treeDefaultExpand, setTreeDefaultExpand] = useState<string[]>([]);
  const [isAdvancedFilter, setIsAdvancedFilter] = useState(false);
  const [financialPeriod, setFinancialPeriod] = useState<number>(1);
  const [month, setMonth] = useState<Array<string> | string>([]);
  const [year, setYear] = useState<Array<string> | string>([]);
  const [selectedUnits, setSelectedUnits] = useState<Array<number> | number>(
    []
  );
  let formFilterDefaultValue: FilterInitialData | undefined = undefined;
  const statusFilter = useRef([STATUS_PERFORMANCE.ALL]);

  const [configFilter, setConfigFilter] = useState<FilterSetting>(
    FILTER_SETTINGS[0]
  );
  const [employeeReference, setEmployeeReference] = useState(listEmployee);
  const [workspaceList, setWorkspaceList] = useState(workspaces);
  const [dataReady, setDataReady] = useState<number>(0);

  /**
   * @constant getEmployeeInWorkspaces
   * @description get employee when workspaceList change
   * @argument workspaceList
   * @argument listEmployee
   */

  const getEmployeeInWorkspaces = (selectedUnits: number[] | number) => {
    const _units = Array.isArray(selectedUnits)
      ? selectedUnits
      : [selectedUnits];
    let mapUnit: { [key: number]: boolean } = {};
    _units.forEach((id: number) => {
      mapUnit[id] = true;
    });
    let result: SelectOptionType[] = [];
    employees.forEach((item: any) => {
      if (item?.workspace?.id && mapUnit[item.workspace.id]) {
        result.push({
          label: item.full_name,
          value: item.id,
          workspace_id: item.workspace.id,
          name: item.full_name,
          user_name: item.user_name,
        });
      }
    });
    return result.sort((a, b) =>
      String(a?.user_name).localeCompare(String(b?.user_name))
    );
  };
  const mapUnit = useRef({});
  const { t } = useTranslation("gperformance", {
    keyPrefix: "performance_filter",
  });
  const getFilterBasic = () => {
    //  verified
    const formValue = form.getFieldsValue();
    const status =
      formValue?.status?.length === 1 &&
      formValue?.status?.includes(STATUS_PERFORMANCE.ALL)
        ? STATUS_FILTER.map((item) => item.value)
        : formValue.status;
    return {
      type: form.getFieldValue(FIELD_NAMES.PERIOD),
      type_unit: displayTarget,
      status: status,
    };
  };
  /**
   * @function generateDefaultWorkspaceSelection
   * @returns workspace id list, which is show in the default filter
   */
  function generateDefaultWorkspaceSelection() {
    let _workspaceId = [...new Set([...concurrentlyWorkspace.owner])];
    if (userInformation?.workspace?.is_owner) {
      _workspaceId.push(Number(userInformation?.workspace?.id));
    }
    _workspaceId = _workspaceId.filter((item) => item && !Number.isNaN(item));
    const markWorkspace: { [key: number]: boolean } = {};
    _workspaceId.forEach((id) => {
      markWorkspace[id] = true;
    });
    let childList = {};
    workspaces.forEach((workspace) => {
      const result = getChildDepartmentByListIds(workspace, markWorkspace);
      childList = { ...childList, ...result };
    });

    const selectedWorkspace: number[] = [...concurrentlyWorkspace.staff];
    if (!userInformation?.workspace?.is_owner) {
      selectedWorkspace.push(Number(userInformation?.workspace?.id));
    }
    Object.entries(childList).forEach(([key, value]) => {
      if (value && !Number.isNaN(Number(key))) {
        selectedWorkspace.push(Number(key));
      }
    });
    return selectedWorkspace;
  }

  /**
   * @function generateFormDefaultFilterValue
   * @returns callback Function for  creating default filter value to set when reset filter
   *
   */
  const generateFormDefaultFilterValue = () => {
    const { month: _month, year: _year } = defaultTimeConfig();
    const currentYear = [new Date().getFullYear().toString()];
    const value: FilterInitialData = {
      period: PERIOD_VALUE.MONTH,
      year:
        displayType === DISPLAY_TYPES.MULTIPLE_SUBJECT
          ? _year[String(configFilter.data.year)]
          : currentYear,
      status: [STATUS_PERFORMANCE.ALL],
      month:
        displayType === DISPLAY_TYPES.MULTIPLE_SUBJECT
          ? _month[String(configFilter.data.month)]
          : currentYear,

      workspaces: [],
      employees: [],
    };

    if (configFilter.data.workspaces === MULTIPLE) {
      value.workspaces = [
        userInformation?.workspace?.id,
        ...new Set([
          ...concurrentlyWorkspace.owner,
          ...concurrentlyWorkspace.staff,
        ]),
      ];
    } else {
      value.workspaces = userInformation?.workspace?.id;
    }
    if (configFilter.data.employees === MULTIPLE) {
      value.employees = [ALL_OPTION_VALUE];
    } else {
      value.employees = userInformation?.id;
    }
    return value;
  };

  /**
   * @function getAssigneeId
   * @description get employeeId or workspace Id based on filter configuration
   * @returns string like array of ids
   */
  const getAssigneeId = () => {
    const formValue = form.getFieldsValue();
    let assignee_id =
      displayTarget === DISPLAY_TARGET.EMPLOYEE
        ? formValue.employees
        : formValue.workspaces;
    if (
      displayTarget === DISPLAY_TARGET.EMPLOYEE &&
      Array.isArray(assignee_id) &&
      assignee_id.includes(ALL_OPTION_VALUE)
    ) {
      const result = getEmployeeInWorkspaces(formValue.workspaces);
      assignee_id = result.map((item) => item.value);
    }
    if (Array.isArray(assignee_id)) {
      assignee_id = assignee_id
        .map((item) => (typeof item == "object" ? item.value : item))
        .join(",");
    }
    return assignee_id;
  };
  /**
   * @var formFilter default filter variable
   * @description default value for filter , can be assigned when filter type and display change
   */

  const handleTimeCOnvert = (value: string | string[]): string | string[] => {
    if (Array.isArray(value)) {
      let result: string[] = [];
      value = value.sort();
      value.forEach((year: string) => {
        for (let index = 1; index < 13; index++) {
          if (index < 10) {
            result.push(`0${index}/${year}`);
          } else {
            result.push(`${index}/${year}`);
          }
        }
      });
      return result;
    }
    return value;
  };
  /**
   * @function onFilter
   * @param value any
   * @returns null
   * @description create search parameter and execute onSearchPerformance function
   */
  const onFilter = (values: any) => {
    if (statusTable === STATUS_TABLE.LOADING) {
      return;
    }
    const result: FilterPerformanceDataType = {
      ...getFilterBasic(),
      assignee_id: getAssigneeId(),
      month: handleTimeCOnvert(
        financialPeriod === PERIOD_VALUE.MONTH
          ? form.getFieldValue(FIELD_NAMES.MONTH)
          : form.getFieldValue(FIELD_NAMES.YEAR)
      ),
    };
    onSearchPerformance(result);
    firstLoad.current = false;
  };
  /**
   * @function onFilterFailed
   * @description fail-safe in case on Filter action failed
   */
  const onFilterFailed = (values: any) => {};

  const setChangeStatus: any = (value: number[]) => {
    const finalValue = handleChangeStatusFilter(
      value,
      statusFilter.current ?? []
    );
    statusFilter.current = finalValue;

    form.setFieldValue(FIELD_NAMES.STATUS, finalValue);
  };

  const handleChangeStatusFilter: any = (
    _newValue: Array<string | number>,
    _preValue: Array<string | number>
  ) => {
    let finalValue: Array<string | number> = [];
    const newValue = _newValue || [];
    const preValue = _preValue || [];
    const newIncludeAll = newValue.includes(STATUS_PERFORMANCE.ALL);
    const oldIncludeAll = preValue.includes(STATUS_PERFORMANCE.ALL);
    switch (true) {
      case !oldIncludeAll && newIncludeAll:
        finalValue = [STATUS_PERFORMANCE.ALL];
        break;
      case oldIncludeAll && newIncludeAll:
        finalValue = newValue.filter(
          (item) => item !== STATUS_PERFORMANCE.ALL
        ) as number[];
        break;
      case !oldIncludeAll &&
        !newIncludeAll &&
        newValue.length === STATUS_FILTER.length - 1:
        finalValue = [STATUS_PERFORMANCE.ALL];
        break;
      default:
        finalValue = newValue;
        break;
    }
    return [...finalValue];
  };
  const handleChangeTarget = (value: number) => {
    setFinancialPeriod(value);
  };
  const handleChangeStatus = (value: Array<string | number>) => {
    let result = [];
    if (value.includes(STATUS_PERFORMANCE.ALL)) {
      result = [STATUS_PERFORMANCE.ALL];
    } else if (form.getFieldValue(FIELD_NAMES.STATUS)?.length === 0) {
      result = [STATUS_PERFORMANCE.ALL];
    } else if (
      form.getFieldValue(FIELD_NAMES.STATUS)?.length === STATUS_FILTER.length
    ) {
      result = [STATUS_PERFORMANCE.ALL];
    } else {
      result = value;
    }
    form.setFieldValue(FIELD_NAMES.STATUS, result);
    setStatus(result);
  };
  const setChangeSelectedEmployee = (
    value: SelectedValueType,
    options: any
  ) => {
    if (value.length === 0 || value.length === employeeReference.length) {
      setSelectedEmployees(["all"]);
      form.setFieldValue(FIELD_NAMES.EMPLOYEES, ["all"]);
    } else {
      setSelectedEmployees(convertValue(value));
      form.setFieldValue(FIELD_NAMES.EMPLOYEES, value);
    }
  };
  const onChangeUnit = (value: any) => {
    const data = [...new Set(value)] as number[];
    let selectedUnit: number[] = [];
    if (!Array.isArray(value)) {
      selectedUnit = value;
    } else if (data.length === 0) {
      selectedUnit = [
        userInformation?.workspace?.id,
        ...new Set([
          ...concurrentlyWorkspace.owner,
          ...concurrentlyWorkspace.staff,
        ]),
      ];
    } else {
      // #1.2 :choosing workspace change employee list
      selectedUnit = data;
    }

    const result = getEmployeeInWorkspaces(data);
    setEmployeeReference([...result]);
    setSelectedUnits(() => selectedUnit);
    form.setFieldValue(FIELD_NAMES.WORKSPACES, selectedUnit);
    if (displayType === DISPLAY_TYPES.MULTIPLE_SUBJECT) {
      setSelectedEmployees(() => [ALL_OPTION_VALUE]);
      form.setFieldValue(FIELD_NAMES.EMPLOYEES, [ALL_OPTION_VALUE]);
    } else {
      setSelectedEmployees(() => userInformation.id);
      form.setFieldValue(FIELD_NAMES.EMPLOYEES, userInformation.id);
    }
  };

  /**
   *
   * @param fieldName fieldName of field,
   * @returns css property for field
   */
  const getDisplayStatus = (fieldName: string) => {
    let display = null;
    if (fieldName === FIELD_NAMES.STATUS) {
      if (isAdvancedFilter === true || roleType === ROLE_TYPE_NAME.STAFF) {
        display = "";
      } else {
        display = "none";
      }
      return {
        display: display,
        minWidth: 250,
      };
    } else if (!configFilter.data.hasOwnProperty(fieldName)) {
      display = "";
    } else {
      display =
        (isAdvancedFilter && configFilter.data[fieldName]) ||
        (roleType === ROLE_TYPE_NAME.STAFF &&
          fieldName === FIELD_NAMES.EMPLOYEES)
          ? ""
          : "none";
    }

    return {
      display: display,
    };
  };

  /**
   *
   * @param key key of the field
   * @returns require and message on error for field
   */

  /**
   * @constant resetValueFilter
   * @description resetValueFilter to default Option
   */
  const resetValueFilter = () => {
    if (!dataReady) {
      return;
    }
    ///#4 reset after role, target and type change. happened after #3
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (userInformation.id) {
      formFilterDefaultValue = generateFormDefaultFilterValue();
      const _formFilterDefaultValue = { ...formFilterDefaultValue };
      setFinancialPeriod(() => PERIOD_VALUE.MONTH);
      setMonth(_formFilterDefaultValue.month);
      setYear(_formFilterDefaultValue.year);
      setSelectedUnits(_formFilterDefaultValue.workspaces);
      setSelectedEmployees(_formFilterDefaultValue.employees);
      form.setFieldsValue({
        [FIELD_NAMES.PERIOD]: _formFilterDefaultValue.period,
        [FIELD_NAMES.MONTH]: _formFilterDefaultValue.month,
        [FIELD_NAMES.YEAR]: _formFilterDefaultValue.year,
        [FIELD_NAMES.STATUS]: _formFilterDefaultValue.status,
        [FIELD_NAMES.WORKSPACES]: _formFilterDefaultValue.workspaces,
        [FIELD_NAMES.EMPLOYEES]:
          displayType === DISPLAY_TYPES.MULTIPLE_SUBJECT
            ? [ALL_OPTION_VALUE]
            : userInformation.id,
      });
    }
  };
  useEffect(() => {
    form.resetFields();
    firstLoad.current = true;
    formFilterDefaultValue = undefined;
    // reset value when init
    const data = getUnitChildren(workspaces);
    mapUnit.current = data.result;
    resetValueFilter();
  }, []);

  /**
   * UserEffect for reset value when change data
   */
  useEffect(() => {
    setIsAdvancedFilter(false);
    if (!dataReady) {
      return;
    }
    const config: FilterSetting | undefined = getFilterConfigSetting({
      target: displayTarget,
      type: displayType,
      group: roleType,
    });

    if (config && dataReady === DATA_READY.READY) {
      setConfigFilter({ ...config });
    }
  }, [displayTarget, displayType, dataReady]);

  const setInitValueFromParam = (formFilter: { [key: string]: any }) => {
    setFinancialPeriod(formFilter.period);
    setMonth(formFilter.month);
    setYear(formFilter.year);
    setSelectedUnits(formFilter.workspaces);
    setSelectedEmployees(formFilter.employees);
    form.setFieldsValue({
      [FIELD_NAMES.PERIOD]: formFilter.period,
      [FIELD_NAMES.MONTH]: formFilter.month,
      [FIELD_NAMES.YEAR]: formFilter.year,
      [FIELD_NAMES.STATUS]: formFilter.status,
      [FIELD_NAMES.WORKSPACES]: formFilter.workspaces,
      [FIELD_NAMES.EMPLOYEES]: formFilter.employees,
    });
  };
  const getWorkspaceIdsFromEmployees = (ids: number[]) => {
    return ids
      .map((id) => mapEmployee[id]?.workspace?.id || undefined)
      .filter((item) => item);
  };
  const readSearchParams = () => {
    const obj: { [key: string]: any } = {};
    searchParams.forEach((value, key) => {
      if (
        value.includes(",") ||
        key === "employees" ||
        key === "workspaces" ||
        key === "status"
      ) {
        obj[key] = value.split(",").map((item) => Number(item));
      } else if (key === "month") {
        obj[key] = value;
      } else {
        obj[key] = Number(value);
      }
    });
    const result: { [key: string]: any } = {
      period: obj.type,
      month: obj.month,
      year: obj.month,
      status: obj.status,
    };
    if (obj.type_unit === DISPLAY_TARGET.UNIT) {
      result.workspaces = obj.workspaces;
      result.employees = [];
    } else {
      result.employees = obj.employees;
      result.workspaces = getWorkspaceIdsFromEmployees(obj.employees);
    }
    return result;
  };

  const firstLoad = useRef(true);
  const initValueFilter = () => {
    const _searchParams = searchParams as any;
    if (firstLoad.current === true && _searchParams?.size > 0) {
      const defaultFilter = readSearchParams();
      setInitValueFromParam(defaultFilter);
    } else {
      resetValueFilter();
    }
    const result: FilterPerformanceDataType = {
      ...getFilterBasic(),
      assignee_id: getAssigneeId(),
      type_unit: displayTarget,
      month: handleTimeCOnvert(
        financialPeriod === PERIOD_VALUE.MONTH
          ? form.getFieldValue(FIELD_NAMES.MONTH)
          : form.getFieldValue(FIELD_NAMES.YEAR)
      ),
    };

    onSearchPerformance(result);
    firstLoad.current = false;
  };

  const findPath = (workspace: any, ids: any, parentId?: any) => {
    let _path: number[] = [];
    if (workspace.children) {
      workspace.children.forEach((item: any) => {
        _path = _path.concat(findPath(item, ids, workspace.id));
      });
    }
    if (ids[workspace.id] === true || _path.length > 0) {
      _path.push(parentId);
    }

    return _path;
  };
  const initDepartmentDefault = () => {
    if (roleType === "") {
      return;
    }
    // tim kiem nhung phong ban hien thi
    const workspaceIds = generateDefaultWorkspaceSelection();
    const showWorkspace = [
      ...concurrentlyWorkspace.owner,
      ...concurrentlyWorkspace.staff,
      userInformation?.workspace?.id,
    ];
    const mapWorkspaceMark: { [key: number]: boolean } = {};
    showWorkspace.forEach((item) => (mapWorkspaceMark[item] = true));
    let rootTree: number[] = [];
    let dependenciesTree: number[] = [];
    let pathNest: number[] = [];
    let newWorkspace = workspaces.map((item) => {
      pathNest = pathNest.concat(findPath(item, mapWorkspaceMark));
      const { rootTree: _rootTree, dependenciesTree: _dependenciesTree } =
        findPathToTreeNode(item, workspaceIds, false);
      rootTree = rootTree.concat(_rootTree);
      dependenciesTree = dependenciesTree.concat(_dependenciesTree);
      const result = getWorkspaceWithConcurrently(item, workspaceIds, [
        ...rootTree,
        ...dependenciesTree,
      ]);
      return result;
    });
    setTreeDefaultExpand(
      pathNest.filter((item) => item).map((item) => String(item))
    );

    newWorkspace = newWorkspace.filter((item) => item);
    setWorkspaceList(() => newWorkspace as TreeNodeDataType[]); // set workspace list

    if (
      displayType === DISPLAY_TYPES.SINGLE_SUBJECT &&
      displayTarget === DISPLAY_TARGET.UNIT
    ) {
      form.setFieldValue(
        FIELD_NAMES.WORKSPACES,
        userInformation?.workspace?.id
      );
    } else {
      form.setFieldValue(FIELD_NAMES.WORKSPACES, [
        ...new Set([
          ...concurrentlyWorkspace.owner,
          ...concurrentlyWorkspace.staff,
          userInformation?.workspace?.id,
        ]),
      ]);
    } // set form filter Value

    let _selectedUnits = workspaceIds.map((item) => Number(item));
    setSelectedUnits(_selectedUnits); // set ref
  };
  useEffect(() => {
    if (
      isEmpty(userInformation) ||
      isEmpty(mapEmployee) ||
      isEmpty(mapWorkspace)
    ) {
      setDataReady(DATA_READY.NOT_READY);
    } else {
      setDataReady(DATA_READY.READY);
    }
  }, [mapWorkspace, mapEmployee, userInformation]);
  useEffect(() => {
    if (dataReady === DATA_READY.READY && configFilter) {
      initValueFilter();
    }
  }, [configFilter]);

  useEffect(() => {
    if (userInformation?.id) {
      resetValueFilter();
    }
  }, [userInformation]);

  useEffect(() => {
    // #1.1 :choosing region change workspace tree
    initDepartmentDefault();
  }, [workspaces, roleType, userInformation, concurrentlyWorkspace]);

  useEffect(() => {
    const result = getEmployeeInWorkspaces(selectedUnits);
    setEmployeeReference([...result]);
  }, [selectedUnits]);

  const handleDeselectOption = () => {
    if (form.getFieldValue(FIELD_NAMES.STATUS).length === 0) {
      form.setFieldValue(FIELD_NAMES.STATUS, [STATUS_PERFORMANCE.ALL]);
    }
  };
  const displayTimeLabel = (type: string) => {
    if (displayType === DISPLAY_TYPES.SINGLE_SUBJECT) {
      return t("selectYearLabel");
    }
    return type === FIELD_NAMES.YEARS
      ? t("selectYearLabel")
      : t("selectMonthLabel");
  };

  const setChangeYear = (value: Array<string> | string) => {
    let _newValue = value;
    if (value.length === 0) {
      const defaultValue = generateFormDefaultFilterValue();
      _newValue = defaultValue.year ?? [];
    }
    form.setFieldValue(FIELD_NAMES.YEAR, _newValue);
    setMonth(convertValue(_newValue));
  };
  const setChangeMonth = (value: Array<string> | string) => {
    let _newValue = value;
    if (value.length === 0) {
      const defaultValue = generateFormDefaultFilterValue();
      _newValue = defaultValue?.month ?? [];
    }
    setMonth(convertValue(_newValue));
    form.setFieldValue(FIELD_NAMES.MONTH, _newValue);
  };
  const listTime = displayType === DISPLAY_TYPES.SINGLE_SUBJECT ? YEAR : MONTH;

  return (
    <Form
      form={form}
      initialValues={formFilterDefaultValue}
      className={`${styles["filter-group"]}`}
      layout="vertical"
      onFinish={onFilter}
      onFinishFailed={onFilterFailed}
    >
      {isAdvancedFilter && (
        <Button
          disabled={statusTable === STATUS_TABLE.LOADING}
          className={styles["button-close"]}
          onClick={() => setIsAdvancedFilter(!isAdvancedFilter)}
          icon={<CloseOutlined />}
        >
          {t("close")}
        </Button>
      )}
      <div className={`${styles["filter-section"]}`}>
        <Form.Item
          style={{ maxWidth: 250 }}
          label={t("selectPeriodLabel")}
          name={FIELD_NAMES.PERIOD}
          className={styles["filter-field"]}
        >
          <Select
            suffixIcon={<IconArrow />}
            className={styles["select-type"]}
            onChange={handleChangeTarget}
            options={PERIOD_OPTIONS}
            value={financialPeriod}
          />
        </Form.Item>
        {financialPeriod === PERIOD_VALUE.MONTH ? (
          <Form.Item
            style={{ maxWidth: 250 }}
            label={displayTimeLabel(FIELD_NAMES.MONTHS)}
            name={FIELD_NAMES.MONTH}
            className={styles["filter-field"]}
          >
            <FilterSelect
              onChange={setChangeMonth}
              list={listTime}
              type={configFilter.data.month}
              value={month}
            />
          </Form.Item>
        ) : (
          <Form.Item
            style={{ maxWidth: 250 }}
            label={displayTimeLabel(FIELD_NAMES.YEARS)}
            name={FIELD_NAMES.YEAR}
            className={styles["filter-field"]}
          >
            <FilterSelect
              onChange={setChangeYear}
              list={listTime}
              type={configFilter.data.year}
              value={year}
            />
          </Form.Item>
        )}
        {/* //UNIT */}
        <Form.Item
          style={getDisplayStatus(FIELD_NAMES.WORKSPACES)}
          className={`${styles["filter-field"]}`}
          label={t("selectUnitsLabel")}
          name={FIELD_NAMES.WORKSPACES}
        >
          <TreeUnit
            multiple={configFilter.data.unit === MULTIPLE}
            list={workspaceList}
            value={selectedUnits}
            onChange={onChangeUnit}
            treeDefaultExpand={treeDefaultExpand}
            type={configFilter.data.workspaces}
          />
        </Form.Item>
        {/* EMPLOYEE */}
        <Form.Item
          style={getDisplayStatus(FIELD_NAMES.EMPLOYEES)}
          label={t("selectEmployeesLabel")}
          name={FIELD_NAMES.EMPLOYEES}
          className={styles["filter-field"]}
        >
          <DropdownEmployee
            isSingle={configFilter.data.employees !== "multiple"}
            placeholder={t("select_employee_placeholder")}
            disabled={ROLE_TYPE[roleType] === ROLE_TYPE.STAFF}
            onChange={setChangeSelectedEmployee}
            value={selectedEmployees}
            mode={
              configFilter.data.employees == "multiple" ? "multiple" : undefined
            }
            showSearch
            options={employeeReference}
          />
        </Form.Item>
        {/* STATUS */}
        <div className={styles["filter-last"]}>
          <Form.Item
            style={getDisplayStatus(FIELD_NAMES.STATUS)}
            label={t("selectStatusLabel")}
            name={FIELD_NAMES.STATUS}
            className={styles["filter-field"]}
          >
            <DropdownEmployee
              options={STATUS_FILTER}
              mode={MULTIPLE}
              value={status}
              onChange={handleChangeStatus}
            />
          </Form.Item>

          {/* Search Button */}
          <Form.Item label="" className={`${styles["button-filter"]}`}>
            <Button className="button-filter" htmlType="submit">
              {t("search")}
            </Button>
          </Form.Item>
        </div>
        {!isAdvancedFilter && roleType !== ROLE_TYPE_NAME.STAFF && (
          <Form.Item label="" className={`${styles["button-filter"]}`}>
            <Button
              icon={<IconFilter />}
              onClick={() =>
                statusTable !== STATUS_TABLE.LOADING &&
                setIsAdvancedFilter(!isAdvancedFilter)
              }
            >
              {t("showAdvancedFilterOption")}
            </Button>
          </Form.Item>
        )}
      </div>
    </Form>
  );
};
export default FormFilter;
