import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../store/store';
import {
  createKpiDataMapping,
  initialState,
  KpiSlice,
  listMapping,
  updateKpiDataMapping
} from '../utils';
import {
  add,
  deleteKpi,
  detail,
  getCreateKpiData,
  getDepartments,
  getDivisions,
  getEmployees,
  getGoals,
  getList,
  getListMore,
  getUserDivisions,
  update
} from './thunk';

export const kpiManagementSlice = createSlice({
  name: 'kpiManagement',
  initialState,
  reducers: {
    reset: () => initialState,
    toggleAddModal: (state: KpiSlice, action) => {
      const field = action.payload as 'addKpi' | 'employeeList';
      state.showModal[field] = !state.showModal[field];
    },
    updateFilterParams: (state: KpiSlice, action) => {
      state.filterParams = { ...action.payload };
    },
    clearSlice: (state: KpiSlice) => {
      state.action = initialState.action;
      state.param = initialState.param;
      state.divisions = initialState.divisions;
      state.employees = initialState.employees;
      state.detailKpiData = initialState.detailKpiData;
    },
    updateAction: (state: KpiSlice, action) => {
      state.action = action.payload;
    },
    clearFormFilter: (state: KpiSlice, action) => {
      state.filterParams = initialState.filterParams;
      state.resetFormFilter = action.payload;
    },
    clearEmployeeList: (state: KpiSlice) => {
      state.employees = initialState.employees;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getList.pending, (state) => {
        state.status = 'loading';
        state.loading.list = true;
        state.list = initialState.list;
      })
      .addCase(getList.fulfilled, (state, action) => {
        state.status = 'idle';
        const _list = [...action.payload.data];
        state.list = listMapping(_list);
        state.paginate = { ...action.payload.paginate };
        state.loading.list = false;
      })
      .addCase(getList.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(add.fulfilled, (state, action) => {
        state.status = 'idle';
        state.showModal.addKpi = false;
      })
      .addCase(getListMore.pending, (state) => {
        state.status = 'loading';
        state.loading.loadMore = true;
      })
      .addCase(getListMore.fulfilled, (state, action) => {
        state.status = 'idle';
        const _currentList = [...state.list];
        const _list = [..._currentList, ...action.payload.data];
        state.list = listMapping(_list);
        state.paginate = { ...action.payload.paginate };
        state.loading.loadMore = false;
      })
      .addCase(detail.pending, (state, action) => {
        state.status = 'loading';
        state.loading.kpiDetailData = true;
      })
      .addCase(detail.fulfilled, (state, action) => {
        state.status = 'idle';
        state.param = action?.payload || initialState.param;
        state.detailKpiData = updateKpiDataMapping(action.payload);
        state.loading.kpiDetailData = false;
      })
      .addCase(update.fulfilled, (state, action) => {
        state.status = 'idle';
        state.showModal.addKpi = false;
      })
      .addCase(deleteKpi.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(getDepartments.fulfilled, (state, action) => {
        state.status = 'idle';
        const _departments = action.payload.data;
        state.departments = _departments;
      })
      .addCase(getDivisions.fulfilled, (state, action) => {
        state.status = 'idle';
        state.divisions = action.payload.data;
      })
      .addCase(getUserDivisions.fulfilled, (state, action) => {
        state.status = 'idle';
        state.userDivisions = action.payload.data;
      })
      .addCase(getEmployees.fulfilled, (state, action) => {
        state.status = 'idle';
        state.employees = action.payload.data;
      })
      .addCase(getGoals.fulfilled, (state, action) => {
        state.status = 'idle';
        state.goals = action.payload.data;
      })
      .addCase(getCreateKpiData.pending, (state) => {
        state.loading.kpiCreateData = true;
        state.createKpiData = initialState.createKpiData;
      })
      .addCase(getCreateKpiData.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loading.kpiCreateData = false;
        state.createKpiData = createKpiDataMapping(action.payload);
      });
  }
});

export const {
  toggleAddModal,
  updateFilterParams,
  clearSlice,
  updateAction,
  clearFormFilter,
  reset,
  clearEmployeeList
} = kpiManagementSlice.actions;

export const list = (state: RootState) => state.kpiManagement.list;
export const showModal = (state: RootState) => state.kpiManagement.showModal;
export const filterParams = (state: RootState) =>
  state.kpiManagement.filterParams;
export const paginate = (state: RootState) => state.kpiManagement.paginate;
export const action = (state: RootState) => state.kpiManagement.action;
export const param = (state: RootState) => state.kpiManagement.param;
export const resetFormFilter = (state: RootState) =>
  state.kpiManagement.resetFormFilter;
export const loading = (state: RootState) => state.kpiManagement.loading;
export const departments = (state: RootState) =>
  state.kpiManagement.departments;
export const divisions = (state: RootState) => state.kpiManagement.divisions;
export const userDivisions = (state: RootState) =>
  state.kpiManagement.userDivisions;
export const employees = (state: RootState) => state.kpiManagement.employees;
export const goals = (state: RootState) => state.kpiManagement.goals;
export const createKpiData = (state: RootState) =>
  state.kpiManagement.createKpiData;
export const detailKpiData = (state: RootState) =>
  state.kpiManagement.detailKpiData;

export default kpiManagementSlice.reducer;
