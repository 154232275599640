import { isBefore } from "date-fns";
import {
  CONDITION,
  GROUP_DEPARTMENT,
  GROUP_DEPARTMENT_AND_POSITION,
  GROUP_USER,
  LIST_USER,
  OBJECT_ACTION_TYPE_EXECUTOR,
  OBJECT_ACTION_TYPE_RECEIVE_INFORMATION,
  OPTIONAL_DATE,
  STATUS_NEXT_PERFORMER_TYPE,
  USER_ACTIVE_STATUS,
  WORKING_POSITION,
} from "../../../helpers/constant";
import { UserType } from "../../auth/types/types";
import {
  DepartmentType,
  GroupUsersType,
  JobTitleType,
  RoleType,
  StepTransferConditionType,
  StepTransferConditionsType,
  User,
} from "../../meta-info/redux/metaInfoSlice";
import { RequestApiPrivate, gprocessApi } from "../component/api/axios";
import {
  checkArray,
  checkValidateTimeNextStep,
} from "../component/helper/Helper";
import { KEY_STATUS_PROCESS } from "../component/helper/constant";
import isEqual from "lodash/isEqual";
import { PayloadEditorType } from "../mission-management/request/form-request/EditorComment/EditorComment";

export const generateGprocessToken = async (
  authUser: UserType,
  expiresAt: number
) => {
  try {
    let json = {
      authUser: authUser,
      expired_in: expiresAt,
    };
    const { data } = await gprocessApi.generateToken(json);
    RequestApiPrivate.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${data.access_token}`;
  } catch (error) {
    throw error;
  }
};

export function getStepType(stepItem: any, arr: any[]): number {
  if (stepItem.step_order === arr.length) return KEY_STATUS_PROCESS.DEFAULT;
  if (stepItem.child_process_id) return KEY_STATUS_PROCESS.ACTIVED;
  if (stepItem.step_order === KEY_STATUS_PROCESS.DRAFF)
    return KEY_STATUS_PROCESS.DRAFF;
  return KEY_STATUS_PROCESS.WAITING;
}

export function checkMockErrorEmpty({
  basicInfoDetail,
  nextPerformer,
  nextInformers,
  executionTime,
  setError,
  ms,
}: any) {
  let _error = {} as any;

  const isStepMissingName = basicInfoDetail.formName.trim().length === 0;
  const isStepMissingProcedure = basicInfoDetail?.procedure?.value.value;
  if (isStepMissingName) {
    _error = {
      ..._error,
      name: {
        message: ms("MISSING_NAME"),
        type: "name",
      },
    };
  }
  if (!isStepMissingProcedure) {
    _error = {
      ..._error,
      procedure: {
        message: ms("MISSING_PROCESS"),
        type: "procedure",
      },
    };
  }
  const checkMissingStartTime = (elementTime: any) =>
    elementTime.type_execution === OPTIONAL_DATE && !elementTime.start_time;

  if (executionTime.some(checkMissingStartTime)) {
    _error = {
      ..._error,
      start_time: {
        message: ms("MISSING_START_TIME"),
        type: "start_time",
      },
    };
  }
  if (checkValidateTimeNextStep(executionTime)) {
    _error = {
      ..._error,
      date_start_time: {
        message: "",
        type: "date_start_time",
      },
    };
  }
  /**
   * @customUser must required by
   * @id = 1, @position
   * @id = 3, @department
   * @id = 6, @allUser
   */

  const validateRoleAndDepartmentOrGroup = (
    nextPerformer: {
      transfer_condition_type: number;
      recipient: { groups: { id: number } }[];
    }[],
    CONDITION: { ALL_USER: number },
    STATUS_NEXT_PERFORMER_TYPE: { DEPARTMENT_AND_ROLES: number },
    customUser: { value: number }[][],
    index: number
  ) => {
    return (
      nextPerformer[index].transfer_condition_type === CONDITION.ALL_USER &&
      nextPerformer[index].recipient
        .map((item: { groups: { id: number } }) => item.groups.id)
        .includes(STATUS_NEXT_PERFORMER_TYPE.DEPARTMENT_AND_ROLES) &&
      !customUser[index][0]?.value
    );
  };

  const stepRequiredCustomUser = nextPerformer.map((el: any) => {
    return el?.transfer_condition_type === CONDITION.ALL_USER
      ? el.recipient.map((element: any) =>
          [
            STATUS_NEXT_PERFORMER_TYPE.POSITION,
            STATUS_NEXT_PERFORMER_TYPE.DEPARTMENT,
            STATUS_NEXT_PERFORMER_TYPE.ALL_USER,
          ].includes(element.groups.id)
        )
      : el.recipient.map((element: any) =>
          [
            STATUS_NEXT_PERFORMER_TYPE.POSITION,
            STATUS_NEXT_PERFORMER_TYPE.USER,
            STATUS_NEXT_PERFORMER_TYPE.ALL_USER,

            el.customDivision.flat() &&
              !el.customDivision.flat()[0] &&
              STATUS_NEXT_PERFORMER_TYPE.DEPARTMENT,
            STATUS_NEXT_PERFORMER_TYPE.DEPARTMENT_AND_ROLES,
          ].includes(element.groups.id)
        );
  });
  const customUser = nextPerformer.map((el: any) => {
    if (
      el.recipient.length === 1 &&
      el.recipient[0].groups.value === STATUS_NEXT_PERFORMER_TYPE.USER
    ) {
      return [
        {
          value: el.recipient[0].regency.value.value,
          label: el.recipient[0].regency.value.label,
        },
      ];
    } else {
      return el.customUser.map((element: any) => ({
        value: element.value,
        label: element.label,
      }));
    }
  });

  const stepRequiredCustomUserRecipient = nextInformers.map(
    (
      el: {
        recipient?: {
          groups: { id: number };
          regency: { value: { name: string; id: number }[] };
        }[];
      },
      index: number
    ) => {
      if (
        el.recipient &&
        nextInformers[index].recipient.length === 1 &&
        nextInformers[index].recipient[0].groups.value ===
          STATUS_NEXT_PERFORMER_TYPE.USER
      ) {
        return [];
      }
      if (el.recipient) {
        return el.recipient.map((element: any) =>
          [
            STATUS_NEXT_PERFORMER_TYPE.POSITION,
            STATUS_NEXT_PERFORMER_TYPE.DEPARTMENT,
            STATUS_NEXT_PERFORMER_TYPE.ALL_USER,
            STATUS_NEXT_PERFORMER_TYPE.DEPARTMENT_AND_ROLES,
            STATUS_NEXT_PERFORMER_TYPE.USER,
          ].includes(element.groups.id)
        );
      }
      return [];
    }
  );

  const customUserRecipient: boolean[][] = nextInformers.map(
    (el: { customUser?: { value: number; label: string }[] }) => {
      if (el.customUser) {
        return el.customUser.map(
          (element: { value: number; label: string }) => ({
            value: element.value,
            label: element.label,
          })
        );
      }
      return [];
    }
  );
  if (checkArray(nextPerformer) && nextPerformer[0].recipient) {
    for (
      let index = 0;
      index < stepRequiredCustomUserRecipient.length;
      index++
    ) {
      const listImplementationObjectOne = nextPerformer[index].recipient.filter(
        (item: { groups: { value: number } }) =>
          item.groups.value !== STATUS_NEXT_PERFORMER_TYPE.GROUP &&
          item.groups.value !== STATUS_NEXT_PERFORMER_TYPE.DEPARTMENT &&
          item.groups.value !== STATUS_NEXT_PERFORMER_TYPE.USER
      );

      const listImplementationObjectAll = nextPerformer[index].recipient.filter(
        (item: { groups: { value: number } }) =>
          item.groups.value !==
            STATUS_NEXT_PERFORMER_TYPE.DEPARTMENT_AND_ROLES &&
          item.groups.value !== STATUS_NEXT_PERFORMER_TYPE.GROUP
      );

      const active = nextPerformer[index].recipient
        .map((item: { regency: { value: { status: number }[] } }) =>
          item.regency.value.find(
            (item: { status: number }) =>
              item.status !== USER_ACTIVE_STATUS.INACTIVE
          )
        )
        .filter((item: any) => item);

      if (
        nextPerformer[index].transfer_condition_type === CONDITION.ONE_USER &&
        !checkArray(listImplementationObjectOne) &&
        !checkArray(active)
      ) {
        _error = {
          ..._error,
          error_active: {
            ..._error["error_active"],
          },
        };
        break;
      }

      if (
        nextPerformer[index].transfer_condition_type === CONDITION.ALL_USER &&
        !checkArray(listImplementationObjectAll) &&
        !checkArray(active)
      ) {
        _error = {
          ..._error,
          error_active: {
            ..._error["error_active"],
          },
        };
        break;
      }
      const findGroupOrUser = nextPerformer[index].recipient.filter(
        (item: { groups: { value: number } }) =>
          item.groups.value === STATUS_NEXT_PERFORMER_TYPE.GROUP ||
          item.groups.value === STATUS_NEXT_PERFORMER_TYPE.USER
      );

      const activeGroupOrUser = findGroupOrUser
        ?.map((item: { regency: { value: { status: number }[] } }) =>
          item.regency.value.find(
            (item: { status: number }) =>
              item.status !== USER_ACTIVE_STATUS.INACTIVE
          )
        )
        .filter((item: any) => item);

      const listDivisionActive = nextPerformer[index].customDivision
        .flat()
        .filter((item: any) => item.data?.status === USER_ACTIVE_STATUS.ACTIVE);
      if (
        checkArray(nextPerformer[index].customDivision.flat()) &&
        nextPerformer[index].customDivision.flat()[0]?.data &&
        !checkArray(listDivisionActive) &&
        (!checkArray(findGroupOrUser) || !checkArray(activeGroupOrUser))
      ) {
        _error = {
          ..._error,
          error_active: {
            ..._error["error_active"],
          },
        };
        break;
      }

      const listUserActive =
        checkArray(nextPerformer[index].customUser) &&
        nextPerformer[index].customUser.filter(
          (item: { status: number }) =>
            item.status !== USER_ACTIVE_STATUS.INACTIVE
        );

      if (
        checkArray(nextPerformer[index].customUser) &&
        !checkArray(listUserActive)
      ) {
        _error = {
          ..._error,
          error_active: {
            ..._error["error_active"],
          },
        };
        break;
      }
    }
  }

  stepRequiredCustomUserRecipient.forEach((step: boolean[], index: number) => {
    if (isRequiredValue(step) && customUserRecipient[index].length === 0) {
      _error = {
        ..._error,
        recipient: {
          ..._error.recipient,
          [index + 1]: {
            message: ms("ERR_REQUIRED"),
            type: "recipient",
          },
        },
      };
    }
  });
  stepRequiredCustomUser.forEach((step: any, index: number) => {
    if (isRequiredValue(step) && customUser[index].length === 0) {
      _error = {
        ..._error,
        "request-ticket": {
          ..._error["request-ticket"],
          [index + 1]: {
            message: ms("ERR_REQUIRED"),
            type: "request-ticket",
          },
        },
      };
    }
    if (
      nextPerformer[index].recipient
        .map((item: { groups: { id: number } }) => item.groups.id)
        .includes(STATUS_NEXT_PERFORMER_TYPE.GROUP) &&
      !customUser[index][0]?.value
    ) {
      _error = {
        ..._error,
        error_group: {
          ..._error["error_group"],
          [index + 1]: {
            message: ms("ERR_REQUIRED"),
            type: "error_group",
          },
        },
      };
    }
    if (
      nextPerformer[index].transfer_condition_type === CONDITION.ONE_USER &&
      nextPerformer[index].recipient[0].groups.id ===
        STATUS_NEXT_PERFORMER_TYPE.DEPARTMENT &&
      !customUser[index][0]?.value
    ) {
      _error = {
        ..._error,
        error_division: {
          ..._error["error_division"],
          [index + 1]: {
            message: ms("ERR_REQUIRED"),
            type: "error_division",
          },
        },
      };
    }

    if (
      validateRoleAndDepartmentOrGroup(
        nextPerformer,
        CONDITION,
        STATUS_NEXT_PERFORMER_TYPE,
        customUser,
        index
      )
    ) {
      _error = {
        ..._error,
        error_group_department_and_role: {
          ..._error["error_group_department_and_role"],
          [index + 1]: {
            message: ms("ERR_REQUIRED"),
            type: "error_group_department_and_role",
          },
        },
      };
    }
  });

  setError(_error);
  return {
    _error,
    isMockErrorRequired: Object.getOwnPropertyNames(_error).length !== 0,
  };
}

function isRequiredValue(step: boolean[]) {
  return step.some((required: boolean) => required);
}

export function renderLabelStep(target: string, chain: string) {
  return chain ? `${target} : ${chain}` : target;
}

export function getRegencyValue({
  target,
  users,
  groupUsers,
  departments,
  roles,
  isDisabledDefault,
  additionalProps,
}: {
  target: { id: number; object_type: number; object_id: number };
  users: User[];
  groupUsers: GroupUsersType[];
  departments: DepartmentType[];
  roles: RoleType[];
  isDisabledDefault: boolean;
  additionalProps?: any;
}) {
  if (target.object_type === STATUS_NEXT_PERFORMER_TYPE.DEPARTMENT_AND_ROLES) {
    const currentDepartment: { name: string; id: number } | undefined =
      departments.find(
        (department: { id: number; name: string }) =>
          department.id === target.object_id
      );
    return [
      {
        value: currentDepartment ? currentDepartment.id : null,
        label: currentDepartment ? currentDepartment.name : ``,
        default: isDisabledDefault,
        ...additionalProps,
      },
    ];
  }
  if (target.object_type === STATUS_NEXT_PERFORMER_TYPE.POSITION) {
    const currentRole: { name: string; id: number } | undefined = roles.find(
      (user: { id: number; name: string }) => user.id === target.object_id
    );
    return [
      {
        value: currentRole ? currentRole.id : null,
        label: currentRole ? currentRole.name : ``,
        default: isDisabledDefault,
        ...additionalProps,
      },
    ];
  }
  if (target.object_type === STATUS_NEXT_PERFORMER_TYPE.GROUP) {
    const currentGroup:
      | { name: string; id: number; status?: number }
      | undefined = groupUsers.find(
      (user: { id: number; name: string }) => user.id === target.object_id
    );
    return [
      {
        value: currentGroup ? currentGroup.id : null,
        label: currentGroup ? currentGroup.name : ``,
        status: currentGroup ? currentGroup.status : null,
        default: isDisabledDefault,
        ...additionalProps,
      },
    ];
  }
  if (target.object_type === STATUS_NEXT_PERFORMER_TYPE.DEPARTMENT) {
    const currentDepartment:
      | { name: string; id: number; status?: number }
      | undefined = departments.find(
      (department: { id: number; name: string }) =>
        department.id === target.object_id
    );
    return [
      {
        value: currentDepartment ? currentDepartment.id : null,
        label: currentDepartment ? currentDepartment.name : ``,
        status: currentDepartment ? currentDepartment.status : null,
        default: isDisabledDefault,
        ...additionalProps,
      },
    ];
  }
  if (
    target.object_type === STATUS_NEXT_PERFORMER_TYPE.ALL_USER ||
    target.object_type === STATUS_NEXT_PERFORMER_TYPE.USER
  ) {
    const currentUser = users.find(
      (user: { id: number; full_name: string }) => user.id === target.object_id
    );
    return [
      {
        value: currentUser ? currentUser.id : null,
        label: getUserName(currentUser),
        status: currentUser ? currentUser.status : null,
        default: isDisabledDefault,
        ...additionalProps,
      },
    ];
  }
  return [
    {
      value: null,
      label: ``,
      default: isDisabledDefault,
    },
  ];
}

function getUserName(user: any): string {
  if (!user) return "";
  return user.full_name ? user.full_name : user.user_name;
}

export const getOptionForSubject = (arg: {
  object_type: number;
  roles: RoleType[];
  departments: DepartmentType[];
  groupUsers: GroupUsersType[];
  users: User[];
}) => {
  const { object_type, roles, departments, groupUsers, users } = arg;
  let data: any = [];
  if (object_type === WORKING_POSITION) {
    data = setRoleByIndex(roles);
  } else if (object_type === GROUP_USER) {
    data = setGroupUserByIndex(groupUsers);
  } else if (
    object_type === GROUP_DEPARTMENT_AND_POSITION ||
    object_type === GROUP_DEPARTMENT
  ) {
    data = setDepartmentByIndex(departments);
  } else if (object_type === LIST_USER) {
    data = setListUserByIndex(users);
  }
  return data;
};

// SET INFO FOR RECIPIENT
export const setDepartmentByIndex = (departments: DepartmentType[]) => {
  if (!departments) return;
  const results = departments.map((item: DepartmentType) => ({
    id: item.id,
    value: item.id,
    label: item.name,
  }));
  return results;
};

export const setGroupUserByIndex = (groupUsers: GroupUsersType[]) => {
  if (!groupUsers) return;
  const results = groupUsers.map((item: GroupUsersType) => ({
    id: item.id,
    value: item.id,
    label: item.name,
  }));
  return results;
};

export const setRoleByIndex = (roles: RoleType[]) => {
  if (!roles) return;
  const results = roles.map((item: RoleType) => ({
    id: item.id,
    value: item.id,
    label: item.name,
  }));
  return results;
};

export const setListUserByIndex = (users: User[]) => {
  if (!users?.length) return [];
  const results = users.map((item: User) => ({
    id: item.id,
    value: item.id,
    label: item.email,
  }));
  return results;
};

export function isUser({ label, users }: { label: string; users: User[] }) {
  const result = users.some((user: User) => {
    if (user.full_name) return user.full_name === label;
    return user.user_name === label;
  });
  return result;
}

export function isObjectTypeUser(value: number) {
  return value === STATUS_NEXT_PERFORMER_TYPE.USER;
}

export function labelSelectAllUSer() {
  return `all-user`;
}

export function labelSelectedUser({
  users,
  id,
}: {
  id: number;
  users: User[];
}) {
  const target = users.find((user: User) => user.id === id);
  return target && target.full_name ? target.full_name : target?.user_name;
}

export function labelSelectedRole({
  roles,
  id,
}: {
  id: number;
  roles: RoleType[];
}) {
  const target = roles.find((role: RoleType) => role.id === id);
  return target ? target.name : ``;
}

export function labelSelectedGroupUser({
  groupUsers,
  id,
}: {
  id: number;
  groupUsers: GroupUsersType[];
}) {
  const target = groupUsers.find((group: GroupUsersType) => group.id === id);
  return target ? target.name : ``;
}

export function labelSelectedJobTitle({
  jobTitles,
  id,
}: {
  jobTitles: JobTitleType[];
  id: number;
}) {
  const uniqueJobTitleCluster = getJobTitleFromRedux(jobTitles);
  const target = uniqueJobTitleCluster.find(
    (jobTitle: { id: number }) => jobTitle.id === id
  );
  return target ? target.name : ``;
}

export function getJobTitleFromRedux(jobTitles: JobTitleType[]) {
  const jobTitleCluster = jobTitles;
  const uniqueJobTitleCluster = filterDuplicateIds(jobTitleCluster);
  return uniqueJobTitleCluster;
}

export function filterDuplicateIds(source: { id: number; name: string }[]) {
  const idMap = new Map();
  const uniqueItems = [];
  for (const item of source) {
    if (item && typeof item === "object" && "id" in item) {
      const id = item.id;
      if (!idMap.has(id)) {
        idMap.set(id, true);
        uniqueItems.push(item);
      }
    }
  }
  return uniqueItems;
}

export function labelSelectedDepartment({
  departments,
  id,
}: {
  id: number;
  departments: GroupUsersType[];
}) {
  const target = departments.find(
    (department: GroupUsersType) => department.id === id
  );
  return target ? target.name : ``;
}

export function mapLabelSelectedByIndex({
  type,
  id,
  departments,
  roles,
  groupUsers,
  users,
  objectPosition,
  jobTitles,
}: {
  type: number;
  id: number;
  departments: GroupUsersType[];
  roles: RoleType[];
  groupUsers: GroupUsersType[];
  users: User[];
  jobTitles: JobTitleType[];
  objectPosition: string;
}) {
  if (type === STATUS_NEXT_PERFORMER_TYPE.ALL_USER) {
    return labelSelectAllUSer();
  }
  if (type === STATUS_NEXT_PERFORMER_TYPE.USER) {
    return labelSelectedUser({
      id,
      users,
    });
  }
  if (type === STATUS_NEXT_PERFORMER_TYPE.DEPARTMENT) {
    return labelSelectedDepartment({
      id,
      departments,
    });
  }
  if (type === STATUS_NEXT_PERFORMER_TYPE.GROUP) {
    return labelSelectedGroupUser({
      id,
      groupUsers,
    });
  }
  if (type === STATUS_NEXT_PERFORMER_TYPE.POSITION) {
    return labelSelectedRole({
      id,
      roles,
    });
  }
  if (type === STATUS_NEXT_PERFORMER_TYPE.DEPARTMENT_AND_ROLES) {
    if (typeof objectPosition === "string" && objectPosition !== "") {
      return labelSelectedDepartment({
        id,
        departments: jobTitles,
      });
    }
    return labelSelectedDepartment({
      id,
      departments,
    });
  }
}

export function getDataElmProcessSteps(
  stepOrder: number,
  processStep: any[],
  childProcess = null as any
) {
  const processStepData = childProcess ?? processStep;
  if (!processStepData?.length || !stepOrder) return {};
  const {
    name,
    stepObjectPerform,
    stepObjectInfo,
    step_order,
    child_process_id,
    transfer_condition_type,
  } = processStepData?.find((item: any) => item.step_order === stepOrder);
  return {
    name,
    stepObjectPerform,
    stepObjectInfo,
    step_order,
    child_process_id,
    transfer_condition_type,
  };
}

function getSubLabel(dataStep: any, processStep: any[], t: any) {
  const isLastStep = processStep.length === dataStep.step_order;
  const subLabel = isLastStep
    ? t("stepEnd")
    : `${t("step")}${dataStep.step_order}`;
  return subLabel;
}

export function mappingNextStep(tree: any, processStep: any[], t: any) {
  let result = [];
  // duyet theo so dong cua phieu yeu cau
  for (let i = 0; i < tree.length; i++) {
    if (tree[i].length === 1) {
      const dataStep = getDataElmProcessSteps(
        tree[i][0].step_order + 1,
        processStep
      );
      const subLabel = getSubLabel(dataStep, processStep, t);
      const temp = {
        options: [
          {
            label: `${subLabel} : ${dataStep.name}`,
            value: 1,
            step_order: dataStep.step_order,
            group_condition_id: null,
          },
        ],
        value: 1,
        conditionType: tree[i][0].step_condition,
        group_condition_id: null,
      };
      result.push(temp);
    } else if (tree[i].length === 2) {
      const dataStep = getDataElmProcessSteps(
        tree[i][0].step_order + 1,
        processStep
      );
      const dataStep2 = getDataElmProcessSteps(
        tree[i][1].step_order + 1,
        processStep
      );
      const subLabel1 = getSubLabel(dataStep, processStep, t);
      const subLabel2 = getSubLabel(dataStep2, processStep, t);
      // truong hop OR
      if (tree[i][0].step_condition === 2) {
        const temp = {
          options: [
            {
              label: `${subLabel1} : ${dataStep.name}`,
              value: 1,
              step_order: dataStep.step_order,
              group_condition_id: tree[i][1].group_condition_id,
            },
            {
              label: `${subLabel2} : ${dataStep2.name}`,
              value: 2,
              step_order: dataStep2.step_order,
              group_condition_id: tree[i][1].group_condition_id,
            },
          ],
          value: 1,
          conditionType: tree[i][1].step_condition,
          group_condition_id: tree[i][1].group_condition_id,
        };
        result.push(temp);
      }
      // truong hop AND
      else {
        const label = cutLabelGroupStepString(t("step"), dataStep, dataStep2);
        const temp = {
          options: [
            {
              label: label,
              value: 1,
              step_order: [dataStep.step_order, dataStep2.step_order],
              group_condition_id: tree[i][1].group_condition_id,
            },
          ],
          value: 1,
          conditionType: tree[i][1].step_condition,
          group_condition_id: tree[i][1].group_condition_id,
        };
        result.push(temp);
      }
    }
  }
  // check dk tat ca la hoac
  if (result.find((item: any) => item.conditionType === 2)) {
    let temp = [
      {
        conditionType: 2,
        group_condition_id: null,
        value: 1,
        options: [] as any,
      },
    ];
    for (let i = 0; i < result.length; i++) {
      result[i].options[0].value = i + 1;
      temp[0].options.push(result[i].options[0]);
    }
    result = temp;
  }
  return result;
}

export function renderTreeStep(data: any[]) {
  if (!data?.length) return [];
  let treeData = [];
  const MAX_OPERATOR = 5;
  for (let i = 0; i < MAX_OPERATOR; i++) {
    if (data.filter((item: any) => Number(item.transfer_order) === i).length)
      treeData[i] = data.filter(
        (item: any) => Number(item.transfer_order) === i
      );
  }
  return treeData;
}

export function cutLabelGroupStepString(
  i18String: string,
  dataStepOne: any,
  dataStepTwo: any
) {
  let label: string = "";
  const subStringFirstStep = `${i18String}${dataStepOne.step_order} : ${dataStepOne.name}`;
  const subStringLastStep = `${i18String}${dataStepTwo.step_order} : ${dataStepTwo.name}`;
  if (subStringFirstStep.length > 122) {
    label = `${subStringFirstStep.substring(0, 62)}... & ${subStringLastStep}`;
  } else {
    label = `${subStringFirstStep} & ${subStringLastStep}`;
  }
  return label;
}
export function convertDataDepartment(dataDepartment: any) {
  const result = dataDepartment.map((item: any) => {
    return {
      ...item,
      name: item.label,
    };
  });
  return result;
}

export function convertDataJobTitle(dataDepartment: any) {
  const result = dataDepartment.map((item: any) => {
    return {
      ...item,
      value: item.id,
      id: item.id,
      code: item.code,
      label: item.name,
    };
  });
  return result;
}

export function getTaskObjectDetail(
  processStepId: number,
  dataMission: any,
  taskTransferCondition: any
) {
  const nextStepApprovedLog = dataMission?.next_step_approved_log?.find(
    (item: any) => item.next_step_id === processStepId
  );
  if (nextStepApprovedLog) {
    return {
      taskObjectPerform: nextStepApprovedLog.list_object,
      taskObjectInformer: nextStepApprovedLog.list_object_info,
    };
  }
  const taskObjectPerform = taskTransferCondition?.task_objects?.filter(
    (item: any) => item?.object_action_type === OBJECT_ACTION_TYPE_EXECUTOR
  );
  const taskObjectInformer = taskTransferCondition?.task_objects?.filter(
    (item: any) =>
      item?.object_action_type === OBJECT_ACTION_TYPE_RECEIVE_INFORMATION
  );
  return { taskObjectPerform, taskObjectInformer };
}

export function getMissionTaskObject(
  processStepId: number,
  dataMission: any,
  taskTransferCondition: any
) {
  const stepAssignObjectData = dataMission?.stepAssignObjectTemps
    ? dataMission?.stepAssignObjectTemps
    : dataMission?.step_assignee_object_temps;
  const stepAssignObjectTemp = stepAssignObjectData?.find(
    (item: any) => item.step_id === processStepId
  );
  if (stepAssignObjectTemp) {
    return {
      taskObjectPerform: stepAssignObjectTemp.task_assigns,
      taskObjectInformer: stepAssignObjectTemp.task_reviews,
    };
  }

  const taskObjectPerform = taskTransferCondition?.task_objects?.filter(
    (item: any) => item?.object_action_type === OBJECT_ACTION_TYPE_EXECUTOR
  );
  const taskObjectInformer = taskTransferCondition?.task_objects?.filter(
    (item: any) =>
      item?.object_action_type === OBJECT_ACTION_TYPE_RECEIVE_INFORMATION
  );

  return { taskObjectPerform, taskObjectInformer };
}

export function getListUserNextStep(
  taskObjects: any[],
  stepObjects: any,
  listUser: any
) {
  const taskObjectNotTypeUsers = taskObjects.find(
    (item: any) => item.object_type !== STATUS_NEXT_PERFORMER_TYPE.USER
  );

  const isSelected =
    taskObjectNotTypeUsers ||
    isEqual(
      stepObjects.map((item: any) => item.object_id).sort(),
      taskObjects.map((item: any) => item.object_id).sort()
    );

  return isSelected
    ? []
    : taskObjects.map((item: any) => ({
        ...item,

        value: item.object_id,
        label: labelSelectedUser({
          id: item.object_id,
          users: listUser,
        }),
        status: listUser.find((user: User) => user.id === item.object_id)
          .status,
      }));
}

export function isCommentEmpty(commentPayload: PayloadEditorType | undefined) {
  if (
    !commentPayload ||
    !commentPayload.content ||
    commentPayload.content === "<p><br></p>"
  )
    return true;
  return false;
}
export function renderStepComplexRollback(
  nextSteps: any,
  stepTranferConditions: StepTransferConditionsType,
  processStep: any[],
  t: any
) {
  let result: any = [];
  const dataStep = getDataElmProcessSteps(nextSteps[0], processStep);
  const dataStep2 = getDataElmProcessSteps(nextSteps[1], processStep);
  // truong hop render truc tiep tu process
  if (
    nextSteps.length === stepTranferConditions.length &&
    nextSteps.includes(2)
  ) {
    return mappingNextStep(
      renderTreeStep(stepTranferConditions),
      processStep,
      t
    );
  }
  // CASE: B1 -> B2 OR ( B3 & B4 ) -> ....
  if (
    nextSteps.length === 2 &&
    stepTranferConditions.length === 3 &&
    !stepTranferConditions[0].group_condition_id &&
    stepTranferConditions[0].step_condition === 2 // OR
  ) {
    const groupIDAnd = stepTranferConditions?.find(
      (item: StepTransferConditionType) =>
        item.group_condition_id &&
        nextSteps.includes(item.step_order) &&
        item.step_condition === 1 // group AND
    )?.group_condition_id;
    const label = cutLabelGroupStepString(t("step"), dataStep, dataStep2);
    const temp = {
      options: [
        {
          label: label,
          value: 1,
          step_order: [dataStep.step_order, dataStep2.step_order],
        },
      ],
      value: 1,
      conditionType: 1, // 1 la AND, 2 la OR
      group_condition_id: groupIDAnd,
    };
    result.push(temp);
    return result;
  }
  // CASE: B1 > B2 & (B3 or B4) -> ....
  if (
    nextSteps.length === 2 &&
    stepTranferConditions.length === 3 &&
    !stepTranferConditions[0].group_condition_id &&
    stepTranferConditions[0].step_condition === 1 // OR
  ) {
    const _nextSteps = nextSteps.sort();
    for (let i = 0; i < _nextSteps.length; i++) {
      const dataStep = getDataElmProcessSteps(_nextSteps[i], processStep);
      const temp = {
        options: [
          {
            label: `${t("step")} ${dataStep.step_order} : ${dataStep.name}`,
            value: 1,
            step_order: [dataStep.step_order],
          },
        ],
        value: 1,
        conditionType: 2, // 1 la AND, 2 la OR
        group_condition_id: null,
      };
      result.push(temp);
    }
    return result;
  }

  // tim toan tu AND giua cac buoc
  const groupIDAnd = stepTranferConditions?.find(
    (item: StepTransferConditionType) =>
      item.group_condition_id &&
      nextSteps.includes(item.step_order) &&
      item.step_condition === 2 // group AND
  )?.group_condition_id;

  // truong hop toan tu co group
  if (nextSteps.length === 2 && groupIDAnd) {
    const label = cutLabelGroupStepString(t("step"), dataStep, dataStep2);
    const temp = {
      options: [
        {
          label: label,
          value: 1,
          step_order: [dataStep.step_order, dataStep2.step_order],
        },
      ],
      value: 1,
      conditionType: 1, // 1 la AND, 2 la OR
      group_condition_id: groupIDAnd,
    };
    result.push(temp);
  }
  // truong hop toan tu va k group
  else {
    for (let i = 0; i < nextSteps.length; i++) {
      const dataStep = getDataElmProcessSteps(nextSteps[i], processStep);
      const temp = {
        options: [
          {
            label: `${t("step")}${dataStep.step_order} : ${dataStep.name}`,
            value: 1,
            step_order: [dataStep.step_order],
          },
        ],
        value: 1,
        conditionType: 1, // 1 la AND, 2 la OR
        group_condition_id: null,
      };
      result.push(temp);
    }
  }
  return result;
}
