import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { appApi } from "./../api/appApi";

import globalModalSlice from "../components/GlobalModal/globalModalSlice";
import authReducer from "../features/auth/redux/authSlice";
import workspaceReducer from "../features/system-management/workspace/redux/workspaceSlice";
import selectionDataReducer from "../features/auth/redux/selectionDataSlice";
import dashboardSlice from "../features/dashboard/redux/index";
import processManage from "../features/g-process/redux/processManage";
import gticketManage from "../features/gticket/redux/gticketManage";
import metaInfoReducer from "../features/meta-info/redux/metaInfoSlice";
import notificationReducer from "../features/notification/redux/notificationSlice";
import ceoFocusSlice from "../features/okr/redux/role-ceo/ceoFocusSlice";
import departmentSlice from "../features/okr/redux/role-ceo/departmentSlice";
import hodSlice from "../features/okr/redux/role-hod/hodSlice";
import staffSlice from "../features/okr/redux/role-staff/staffSlice";
import teamLeadSlice from "../features/okr/redux/role-team-lead/teamLeadSlice";
import formDataSlice from "../utils/slice/formData/createDepartmentForm";
import createDivisionSlice from "../utils/slice/formData/createDivisionForm";
import userV2Slice from "../utils/slice/user/index";
import gPerformanceGoalSlice from '../features/g-performance/goal/redux/slice';
import gPerformanceParamSlice from '../features/g-performance/param-management/redux/slice';
import gPerformanceGoalGroupSlice from '../features/g-performance/goal-group/redux/slice';
import kpiManagementSlice from '../features/g-performance/kpi-management/redux/slice';
import gPerformanceDataManagementSlice from '../features/g-performance/data-management/redux/slice';
import gPerformanceSyncHistorySlice from '../features/g-performance/sync-history/redux/slice';
import gPerformanceComputingManagementSlice from '../features/g-performance/computing-management/redux/slice';
import gPerformanceManagementSlice from '../features/g-performance/performance-management/redux/slice';
import gPerformanceListStatusSlice from '../features/g-performance/list-status/redux/slice';
import gPerformanceResultManagementSlice from '../features/g-performance/result-management/redux/slice';
import gPerformanceResultApproveSlice from "../features/g-performance/result-approve/redux/slice";
import gPerformanceDashboardSlice from '../features/g-performance/dashboard/redux/slice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    workspace: workspaceReducer,
    selectionData: selectionDataReducer,
    notification: notificationReducer,
    process: processManage,
    metaInfo: metaInfoReducer,
    ceoFocus: ceoFocusSlice,
    departmentCeo: departmentSlice,
    hodSlice: hodSlice,
    teamLead: teamLeadSlice,
    staff: staffSlice,
    gticket: gticketManage,
    createDepartment: formDataSlice,
    createDivision: createDivisionSlice,
    globalModal: globalModalSlice,
    userV2Slice: userV2Slice,
    [appApi.reducerPath]: appApi.reducer,
    dashboard: dashboardSlice,
    kpiGoalGroup: gPerformanceGoalGroupSlice,
    kpiParamManage: gPerformanceParamSlice,
    kpiGoal: gPerformanceGoalSlice,
    kpiManagement: kpiManagementSlice,
    kpiDataManagement: gPerformanceDataManagementSlice,
    kpiSyncHistory: gPerformanceSyncHistorySlice,
    gPerformanceComputingManagement: gPerformanceComputingManagementSlice,
    gPerformanceManagement: gPerformanceManagementSlice,
    gPerformanceResultManagement: gPerformanceResultManagementSlice,
    gPerformanceResultApprove: gPerformanceResultApproveSlice,
    gPerformanceListStatus: gPerformanceListStatusSlice,
    gPerformanceDashboard: gPerformanceDashboardSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([appApi.middleware]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
