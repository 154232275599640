import React from "react";

const IconAddT = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.67852 2.63379C4.43502 2.63379 3.40377 3.64029 3.37452 4.88154V13.0048C3.35277 14.24 4.33302 15.254 5.55927 15.275L5.66952 15.2743H11.6785C12.91 15.2608 13.9143 14.243 13.9128 13.0055V6.25629L10.4373 2.63379H5.68752H5.67852ZM5.54877 16.4C3.69402 16.3678 2.21802 14.84 2.24952 12.995V4.86804C2.29227 3.00729 3.82902 1.50879 5.67627 1.50879H5.68977H10.6765C10.8295 1.50879 10.9758 1.57104 11.0823 1.68129L14.8818 5.64054C14.9815 5.74479 15.0378 5.88504 15.0378 6.02979V13.0048C15.0408 14.8565 13.5363 16.3798 11.6845 16.3993L5.54877 16.4Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4717 6.73824H12.4062C11.033 6.73449 9.91699 5.61549 9.91699 4.24449V2.06274C9.91699 1.75224 10.169 1.50024 10.4795 1.50024C10.79 1.50024 11.042 1.75224 11.042 2.06274V4.24449C11.042 4.99749 11.6547 5.61099 12.4077 5.61324H14.4717C14.7822 5.61324 15.0342 5.86524 15.0342 6.17574C15.0342 6.48624 14.7822 6.73824 14.4717 6.73824Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3442 10.248H6.66992C6.35942 10.248 6.10742 9.99605 6.10742 9.68555C6.10742 9.37505 6.35942 9.12305 6.66992 9.12305H10.3442C10.6547 9.12305 10.9067 9.37505 10.9067 9.68555C10.9067 9.99605 10.6547 10.248 10.3442 10.248Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.50684 12.0856C8.19634 12.0856 7.94434 11.8336 7.94434 11.5231V7.84814C7.94434 7.53764 8.19634 7.28564 8.50684 7.28564C8.81734 7.28564 9.06934 7.53764 9.06934 7.84814V11.5231C9.06934 11.8336 8.81734 12.0856 8.50684 12.0856Z"
        fill="white"
      />
    </svg>
  );
};

export default IconAddT;
