import IconOneUser from "../../../components/icons/IconOneUser";
import IconTwoUsers from "../../../components/icons/IconTwoUsers";
import IconThreeUsers from "../../../components/icons/IconThreeUsers";
import { ENUM_TAB_OKR } from "../type/type";

const HEADER_BUTTONS = [
  {
    _id: "createOkr",
    title: "OKR của GGG",
    themeDot: "primary",
  },
  {
    _id: "createOkr1",
    title: "Sub OKR",
    themeDot: "secondary",
  },
  {
    _id: "createOkr2",
    title: "KR/ Milestone",
    themeDot: "tertiary",
  },
  {
    _id: "createOkr3",
    title: "Nhiệm vụ",
    themeDot: "quaternary",
  },
];

const BUTTON_RESULT_OKRS_TAB = [
  {
    name: ENUM_TAB_OKR.PERSONAL,
    icon: <IconOneUser width={24} height={24} />,
  },
  {
    name: ENUM_TAB_OKR.DIVISION,
    icon: <IconTwoUsers width={24} height={24} />,
  },
  {
    name: ENUM_TAB_OKR.DEPARTMENT,
    icon: <IconThreeUsers width={24} height={24} />,
  },
];

const KEY_DATA_BY_INDEX: {
  [key: number]:
    | ENUM_TAB_OKR.PERSONAL
    | ENUM_TAB_OKR.DIVISION
    | ENUM_TAB_OKR.DEPARTMENT;
} = {
  0: ENUM_TAB_OKR.PERSONAL,
  1: ENUM_TAB_OKR.DIVISION,
  2: ENUM_TAB_OKR.DEPARTMENT,
};

export { HEADER_BUTTONS, BUTTON_RESULT_OKRS_TAB, KEY_DATA_BY_INDEX };
