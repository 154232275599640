export enum StatusEnum {
  Created = 1,
  SentApprove,
  Approved,
  Rejected
}

export enum SubjectEnum {
  Individual = 1,
  Group
}

export enum GoalTypeEnum {
  Month = 1,
  Year
}

export enum CheckEnum {
  All = 'all'
}

export enum StatusOperatorEnum {
  Inactive,
  Active
}

export enum UploadTypeEnum {
  RunBack,
  Upload
}
