import React, { SVGProps } from 'react';

export default function IconArrowTree(props: SVGProps<SVGSVGElement>) {
  const { stroke = 'black' } = props;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      {...props}
    >
      <path
        d='M14.25 11.625L9 6.375L3.75 11.625'
        stroke={stroke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
