import axios from "axios";
import {
  SERVICE_URL_GTICKET,
  SERVICE_URL_G_TICKET_V2,
} from "../request/wsCode";
import { TOKEN_GTICKET } from "../constants/constant";

export const RequestApiGticket = axios.create({
  baseURL: SERVICE_URL_GTICKET,
  headers: {
    "Content-Type": "application/json",
    "api-key": String(process.env.REACT_APP_GTICKET_APP_TOKEN),
  },
});

export const RequestApiPrivateGticket = axios.create({
  baseURL: SERVICE_URL_GTICKET,
  headers: {
    "Content-Type": "application/json",
    "api-key": String(process.env.REACT_APP_GTICKET_APP_TOKEN),
    // Authorization: `Bearer ${
    //     sessionStorage.getItem(TOKEN) ? sessionStorage.getItem(TOKEN) : ''
    // }`,
  },
});

export const RequestApiPrivateGTicketV2 = axios.create({
  baseURL: SERVICE_URL_G_TICKET_V2,
  headers: {
    "Content-Type": "application/json",
    "api-key": String(process.env.REACT_APP_GTICKET_APP_TOKEN),
  },
});
