import { lazy } from "react";

// Gticket

const WorkgroupManagement = lazy(
  () =>
    import(
      "../workgroup-management/component/workgroup-list/WorkgroupManagement"
    )
);
const ContentWorkgroup = lazy(
  () =>
    import("../workgroup-management/component/workgroup-list/ContentWorkgroup")
);
const WorkgroupDetail = lazy(
  () =>
    import("../workgroup-management/component/workgroup-detail/WorkgroupDetail")
);
const WorkgroupDetailManagement = lazy(
  () =>
    import(
      "../workgroup-management/component/workgroup-detail/WorkgroupDetailManagement"
    )
);

const GTicketWorkReport = lazy(
  () =>
      import(
          "../work-report/WorkReportPage"
          )
);

const WorkList = lazy(() => import("../worklist/component/my-task/WorkList"));
const DetailTicket = lazy(() => import("../worklist/DetailTicket/index"));
const ContentWorklist = lazy(() => import("../worklist/ContentWorklist"));

let path = "/gticket";

const RouteGticket: any = [
  {
    path: `${path}/workgroup-management`,
    component: WorkgroupManagement,
    index: ContentWorkgroup,
  },
  {
    path: `${path}/work-list`,
    component: WorkList,
    index: ContentWorklist,
  },
  {
    path: "gticket/work-list/detail",
    component: DetailTicket,
    index: null,
  },
  {
    path: `${path}/workgroup-detail/:id`,
    component: WorkgroupDetailManagement,
    index: WorkgroupDetail,
  },
  {
    path: `${path}/work-report`,
    component: GTicketWorkReport,
    index: null,
  },
];

export default RouteGticket;
