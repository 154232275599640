const IconDelete = () => {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4937 6.60156C12.4937 6.60156 12.0864 11.6528 11.8502 13.7806C11.7377 14.7968 11.1099 15.3923 10.0817 15.4111C8.12492 15.4463 6.16592 15.4486 4.20992 15.4073C3.22067 15.3871 2.60342 14.7841 2.49317 13.7858C2.25542 11.6393 1.85042 6.60156 1.85042 6.60156"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5311 4.18066H0.812649"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0805 4.18023C10.4917 4.18023 9.9847 3.76398 9.8692 3.18723L9.68695 2.27523C9.57445 1.85448 9.19345 1.56348 8.7592 1.56348H5.58445C5.1502 1.56348 4.7692 1.85448 4.6567 2.27523L4.47445 3.18723C4.35895 3.76398 3.85195 4.18023 3.2632 4.18023"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default IconDelete;
