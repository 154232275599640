import { useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DATE_FORMAT } from "../../../helpers/constant";
import CalendarIcon from "../../icons/CalendarIcon";
import styles from "./CustomDatePicker.module.scss";
import "./overwrite.scss";

type propsType = {
  onChange?: any;
  name?: string;
  selected?: any;
  startDate?: any;
  endDate?: any;
  selectsRange?: boolean;
  dateFormat?: string;
  hasError?: boolean;
  className?: string;
  [key: string]: any;
};

const CustomDatePicker = ({
  onChange,
  name,
  dateFormat = DATE_FORMAT.FE,
  selectsRange,
  style,
  disabled,
  placeholder,
  hasError,
  className,
  icon = <CalendarIcon />,
  ...other
}: propsType) => {
  const inputRef = useRef<any>(null);
  let classNameArr = [styles["datepicker-container"]];
  if (className) classNameArr.push(className);
  if (disabled) classNameArr.push(styles["datepicker-disabled"]);
  if (hasError) classNameArr.push(styles["error"]);
  return (
    <div style={style} className={classNameArr.join(" ")}>
      <DatePicker
        ref={inputRef}
        disabled={disabled}
        dateFormat={dateFormat}
        closeOnScroll={() => inputRef.current?.setOpen(false)}
        name={name}
        onChange={(e: any) => {onChange(e,inputRef.current.state.inputValue)}}
        placeholderText={placeholder ? placeholder : null}
        selectsRange={selectsRange}
        showFullMonthYearPicker={true}
        autoComplete="off"
        {...other}
      />
      <span
        onClick={() => {
          inputRef.current.input.focus();
        }}
        className={styles["icon-container"]}
      >
        {icon}
      </span>
    </div>
  );
};

export default CustomDatePicker;
