import React from "react";

function IconCloseA(props) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3955 8.09473L7.60352 12.8867"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3976 12.8898L7.60156 8.09277"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {/* <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.335 1.25H5.66598C2.64498 1.25 0.750977 3.389 0.750977 6.416V14.584C0.750977 17.611 2.63598 19.75 5.66598 19.75H14.334C17.365 19.75 19.251 17.611 19.251 14.584V6.416C19.251 3.389 17.365 1.25 14.335 1.25Z"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      /> */}
    </svg>
  );
}

export default IconCloseA;
