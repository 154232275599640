import { createSlice, nanoid } from "@reduxjs/toolkit";
import { RootState } from "../../../../store/store";
import { formatShowDecimalNumber } from "../../common/utils/helper";
import {
  initialState,
  downloadFile,
} from "../utils";
import {
  exportNonCeo,
  getDetailPerformanceUser,
  getDetailPerformanceWorkspace,
  getListPerformance,
  getOverviewPerformance,
  getDataDashboardCEO,
  exportCEO,
} from "./thunk";

export const kpiDashboardCeoSlice = createSlice({
  name: "gPerformanceDashboard",
  initialState,
  reducers: {
    reset: () => initialState,
    toggleShowDetail: (state) => {
      state.showDetail = !state.showDetail;
    },
    updateFilterParams: (state, action) => {
      state.filterParams = { ...action.payload };
    },
    clearSlice: (state) => {
      state.action = initialState.action;
      state.param = initialState.param;
    },
    updateAction: (state, action) => {
      state.action = action.payload;
    },
    clearFormFilter: (state, action) => {
      state.filterParams = initialState.filterParams;
      state.resetFormFilter = action.payload;
    },
    setMonthView: (state, action) => {
      state.monthView = action.payload;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setCachingFetchList: (state, action) => {
      state.cachingFetchList = action.payload;
    },
    setTabControl: (state, action) => {
      state.tabControl = action.payload
    },
    setYearCached : (state, action) => {
      state.yearCached = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListPerformance.pending, (state) => {
        state.loading.list = true;
        state.list = initialState.list;
        state.listPerformanceUser = initialState.listPerformanceUser;
      })
      .addCase(getListPerformance.fulfilled, (state, action) => {   
        state.loading.list = false;
        const listPerformanceOrigin = action.payload?.data?.map((_item: any) => {
         return {rank: _item.rank,
          previous_month_rank: _item.previous_month_rank,
          change: _item.change,
          ..._item.workspace,}
        });

        state.list = listPerformanceOrigin.filter((_item: any) => _item.performances?.length || _item.children?.length )

        const listPerformanceUserOrigin = action.payload?.data?.map((_item: any) => {
          if(!_item.workspace) return {
            rank: _item.rank,
            previous_month_rank: _item.previous_month_rank,
            change: _item.change,
            id: 0,
            workspace: null,
            children: _item.user,
          }
          return {
            rank: _item.rank,
            previous_month_rank: _item.previous_month_rank,
            change: _item.change,
            ..._item.workspace,
            children: _item.user,
          };
        });
        state.listPerformanceUser = listPerformanceUserOrigin?.filter((_item: any) => _item.children?.length)
      })
      .addCase(getDetailPerformanceUser.pending, (state, action) => {
        state.loading.detail = true;
        state.loading.detail = true;
        if(!action.meta.arg?.params?.monthOnly) {
           state.dataChart.row = initialState.dataChart.row;
         }
        state.listGoal = initialState.listGoal;
        state.listPerformanceEmployee = initialState.listPerformanceEmployee
      })
      .addCase(getDetailPerformanceUser.fulfilled, (state, action) => {
        if (
          action.meta.arg?.params?.targets?.includes("subodinatePerformances")
        ) {
          state.listPerformanceEmployee =
            action?.payload?.data?.subodinate_performances?.map(
              (_item: any, _idx: number) => ({
                index: _idx + 1,
                ..._item,
              })
            );
        }
        if(!action.meta.arg?.params?.monthOnly) {
          state.dataChart.row = 
          { value: formatShowDecimalNumber(action.payload?.data?.yearly_performance?.performance_value), 
            color: action.payload?.data?.yearly_performance?.color || ''};
        }
        state.listGoal = action.payload?.data?.goal_performances_grouped.map(
          (_item: any, _idx: number) => ({
            index: _idx + 1,
            ..._item,
          })
        );
        state.loading.detail = false;
        state.monthView = state.filterParams.month;
        state.title = action.payload?.data?.user?.name || action.payload?.data?.user?.user_name
        const monthly_performance = action.payload?.data?.monthly_performance
        state.monthPerformance = {...monthly_performance, color: monthly_performance?.color ||  initialState.monthPerformance.color}
      })
      .addCase(getDetailPerformanceWorkspace.pending, (state, action) => {
        state.loading.detail = true;
        if(!action.meta.arg?.params?.monthOnly) {
          state.dataChart.row = initialState.dataChart.row;
        }
        state.listGoal = initialState.listGoal;
      })
      .addCase(getDetailPerformanceWorkspace.fulfilled, (state, action) => {
        if(!action.meta.arg?.params?.monthOnly) {
          state.dataChart.row = 
          { value: formatShowDecimalNumber(action.payload?.data?.yearly_performance?.performance_value), 
            color: action.payload?.data?.yearly_performance?.color || ''};
        }
        state.listGoal = action.payload?.data?.goal_performances_grouped.map(
          (_item: any, _idx: number) => ({
            index: _idx + 1,
            ..._item,
          })
        );
        state.loading.detail = false;
        state.monthView = state.filterParams.month;
        state.title = action.payload?.data?.workspace?.name;
        state.monthPerformance = action.payload?.data?.monthly_performance;
      })

      .addCase(getOverviewPerformance.pending, (state, action) => {
        state.dataChart.column = initialState.dataChart.column;
      })
      .addCase(getOverviewPerformance.fulfilled, (state, action) => {
        state.dataChart.column = action.payload?.data?.map((_item: any) => ({
          month: Number(_item.month),
          value:
            Number(_item.performance_value) > 100
              ? 100
              : Number(_item.performance_value),
          color: _item.color,
        }));
        state.yearCached = action.meta.arg?.year 
      })
      .addCase(exportNonCeo.fulfilled, (state, action) => {
        downloadFile(action.payload);
      })
      .addCase(exportCEO.fulfilled, (state, action) => {
        downloadFile(action.payload);
      })
      .addCase(getDataDashboardCEO.pending, (state) => {
        state.loading.list = true;
      })
      .addCase(getDataDashboardCEO.fulfilled, (state, action) => {
        state.loading.list = false;
        state.dashboardMain = action.payload.data;
      });
  },
});

export const {
  toggleShowDetail,
  updateFilterParams,
  clearSlice,
  updateAction,
  clearFormFilter,
  reset,
  setMonthView,
  setTitle,
  setCachingFetchList,
  setTabControl,
  setYearCached
} = kpiDashboardCeoSlice.actions;

export const gPerformanceDashboardState = (state: RootState) =>
  state.gPerformanceDashboard;

export default kpiDashboardCeoSlice.reducer;
