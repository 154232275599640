export const renderIcon = () => (
  <div className="ant-checkbox-wrapper" style={{ marginRight: 10 }}>
    <span className="ant-checkbox ant-checkbox-checked">
      <span className="ant-checkbox-inner"></span>
    </span>
    <span className="ant-checkbox">
      <span className="ant-checkbox-inner"></span>
    </span>
  </div>
);
