import { Empty, Modal, notification } from 'antd';
import {
  ErrorWaring,
  IconErrorSnackbar,
  IconSuccessSnackbar,
} from '../../../../components/icons';
import { IconEmpty, QuestionIcon, TOAST_TYPE } from '.';
import { LoadingRing } from '../../../../components/UI';

const { confirm } = Modal;

export const openNotification = ({
  message,
  type,
}: {
  message?: string;
  type: string;
}) => {
  const iconType = () => {
    if (type === TOAST_TYPE.Success) return <IconSuccessSnackbar />;
    if (type === TOAST_TYPE.Error) return <IconErrorSnackbar />;
    return <ErrorWaring />;
  };

  const icon = iconType();

  return notification.open({
    message,
    icon,
    placement: 'bottomRight',
    className: 'kpi-wrapper',
    bottom: 10,
    duration: 5,
    closeIcon: null,
  });
};

export const showConfirm = ({
  message,
  callBack,
  okText,
  cancelText,
}: {
  message: string;
  okText: string;
  cancelText: string;
  callBack: () => void;
}) => {
  return confirm({
    content: (
      <>
        <QuestionIcon />
        <p>{message}</p>
      </>
    ),
    className: 'kpi-wrapper',
    centered: true,
    icon: null,
    cancelText,
    okText,
    width: 500,
    onOk() {
      callBack();
    },
  });
};

export const LoadingSSC = ({ loading }: { loading: boolean }) =>
  loading ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <LoadingRing size='md' />
      <h4>Đang tải dữ liệu</h4>
    </div>
  ) : (
    <></>
  );

export const StatusBar = ({ status }: { status: any }) =>
  status ? (
    <div
      style={{
        width: '130px',
        height: ' 26px',
        background: '#D9EEDB',
        borderRadius: '17px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '13px',
        textAlign: 'center',
        color: '#41AC49',
        margin: '0 auto',
      }}
    >
      {status}
    </div>
  ) : (
    <></>
  );

export const CustomizeEmpty = ({ text }: { text: string }) => (
  <Empty
    image={<IconEmpty />}
    style={{ height: 140 }}
    imageStyle={{
      height: 35,
      marginTop: 70,
      marginBottom: 10,
    }}
    description={
      <span
        style={{
          fontFamily: 'GoogleSans',
          fontWeight: 400,
          fontSize: '16px',
          color: '#000000',
        }}
      >
        {text}
      </span>
    }
  ></Empty>
);
