import {
  axiosAccountGprocessPrivateV2,
  axiosAccountPrivate,
  axiosAccountPrivateDomainV2,
  axiosAccountPrivateV2,
} from "./axios";
import { RequestApiGticket } from "../features/gticket/api/axios";
import {
  RequestApiPrivateQuickTicket,
  RequestApiUsers,
} from "../features/system-management/api/axios";
import axios from "axios";

let source: any = null;

export const cancelToken = () => cancel(source);

export function cancel(source: any) {
  source && source.cancel && source.cancel("Unmount");
}
export const AccountApi = {
  getListDepartment: async (): Promise<any> => {
    try {
      const res = await axiosAccountPrivate.get("/department");
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  checkOwnerAndConcurrenlyOfWorkSpace: async (params: any): Promise<any> => {
    try {
      const res = await axiosAccountPrivateV2.get("/users/direct-managements", {
        params,
      });
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  getLoginUserQrCodes: async (): Promise<any> => {
    try {
      const res = await RequestApiPrivateQuickTicket.get("/users/qr-codes");
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  getUserWorkProcess: async (id: any): Promise<any> => {
    try {
      const res = await axiosAccountPrivateV2.get(
        `/users/${id}/working-process`
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  getWorkspaceOwnTicket: async (id: any): Promise<any> => {
    try {
      const res = await axiosAccountPrivateV2.get(
        `/users/info/${id}?relations[]=workspace`
      );
      return res?.data?.data;
    } catch (error) {
      throw error;
    }
  },
  getListSBU: async (): Promise<any> => {
    try {
      const res = await axiosAccountPrivateV2.get("/sbu-info");
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  getUserSameLevels: async (userID: number): Promise<any> => {
    try {
      const res = await axiosAccountPrivate.get(
        `users/list-user-same-level?user_id=${userID}`
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  createNewDepartment: async (department: any): Promise<any> => {
    try {
      const res = await axiosAccountPrivate.post(
        "/admin/departments",
        department || {}
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  getListTitles: async (): Promise<any> => {
    try {
      const res = await axiosAccountPrivate.get("/department?type=select");
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  updateDepartmentInfo: async (department: any, _id: number): Promise<any> => {
    try {
      const res = await axiosAccountPrivate.put(
        `/admin/departments/${_id}`,
        department || {}
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  getListGroups: async (): Promise<any> => {
    try {
      const params = {
        with: ["leader", "users"],
      };

      const res = await axiosAccountGprocessPrivateV2.get("/selection-teams", {
        params,
      });

      return res.data;
    } catch (error) {
      throw error;
    }
  },
  getListWorkspaces: async (): Promise<any> => {
    try {
      const res = await axiosAccountPrivateV2.get(
        "/workspaces?additional_job_title=true"
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  createNewDvision: async (_division: any = {}): Promise<any> => {
    try {
      const res = await axiosAccountPrivate.post("/admin/divisions", _division);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  updateDivisionInfoById: async (
    _division: any = {},
    _id: number
  ): Promise<any> => {
    try {
      const res = await axiosAccountPrivate.put(
        `/admin/divisions/${_id}`,
        _division
      );
    } catch (error) {
      throw error;
    }
  },
  getListRoles: async (): Promise<any> => {
    try {
      const res = await axiosAccountPrivateV2.get("/roles");
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  getAllUser: async function (params: any = {}): Promise<any> {
    try {
      const response = await axiosAccountPrivateV2.get("/users/module", params);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getAdminUsers: async function (): Promise<any> {
    try {
      const response = await axiosAccountPrivate.get("/admin/users");
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getUserInfo: async function (payload: any): Promise<any> {
    try {
      const response = await axiosAccountPrivateV2.post(
        "/users/info-by-username",
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getUserInfoV2: async function (payload: any): Promise<any> {
    try {
      const response = await axiosAccountPrivateV2.post(
        "/users/info-by-username",
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  generateToken: async function (email: string): Promise<any> {
    try {
      const response = await axiosAccountPrivate.post("/generate-token", {
        email: email,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getDepartmentDivisions: async function (departmentId: number): Promise<any> {
    try {
      const response = await axiosAccountPrivate.get(
        `/department/${departmentId}/divisions`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getListDepartmentByIds: async function (
    departmentIds: number[]
  ): Promise<any> {
    try {
      const response = await axiosAccountPrivate.get(`department/list`, {
        params: {
          ids: departmentIds,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  generateTokenGticket: async function (username: string): Promise<any> {
    try {
      const response = await RequestApiGticket.post(`/generate-token`, {
        user_name: username,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  generateTokenUsersManagement: async function (email: string): Promise<any> {
    try {
      const response = await RequestApiUsers.post(`/generate-token`, {
        email: email,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  updateLocale: async function (payload: {
    userId: number;
    locale: string;
  }): Promise<any> {
    const { userId, locale } = payload;
    try {
      const response = await axiosAccountPrivate.put(`/users/${userId}`, {
        language: locale,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  checkExistedDepartmentAndRegion: async function (
    departmentName: string,
    region: string
  ) {
    try {
      const response = await axiosAccountPrivate.get(
        `/admin/departments/find-by-filters?name=${departmentName}&region_code=${region}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  checkExistedDepartmentCode: async function (code: string) {
    const encodedCode = encodeURIComponent(code);
    try {
      const response = await axiosAccountPrivate.get(
        `/admin/departments/find-by-filters?code=${encodedCode}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  checkExistedDivisionCode: async function (code: string) {
    const encodedCode = encodeURIComponent(code);

    try {
      const response = await axiosAccountPrivate.get(
        `/admin/divisions/find-by-filters?code=${encodedCode}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  checkExistedDivisionName: async function (_name: string) {
    try {
      const response = await axiosAccountPrivate.get(
        `/admin/divisions/find-by-filters?name=${_name}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getDepartmentInfoById: async function (_id: any) {
    try {
      const response = await axiosAccountPrivate.get(
        `/admin/departments/${_id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getDivisionInfoById: async function (_id: any) {
    try {
      const response = await axiosAccountPrivate.get(`/admin/divisions/${_id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getUsersInDepartment: async function (params: {}, wsCode: any) {
    try {
      if (params) {
        axiosAccountPrivate.defaults.params = params;
        const res: any = await axiosAccountPrivate.get(wsCode);
        if (res) {
          if (res.data) {
            return res.data;
          }
        }
      }
    } catch (error: any) {
      throw error;
    }
  },
  getAppList: async function (config: any) {
    try {
      const res: any = await axiosAccountPrivate.get("/app/list", config);
      return res.data;
    } catch (error: any) {
      throw error;
    }
  },
  getAssigneeGroup: async function (params: any) {
    try {
      cancelToken();
      source = axios.CancelToken.source();
      const res: any = await axiosAccountPrivateV2.get("/teams", {
        params,
        cancelToken: source.token,
      });
      return res.data;
    } catch (error: any) {
      throw error;
    }
  },
  getAssigneeGroupById: async function (id: any) {
    try {
      const res: any = await axiosAccountPrivateV2.get(`/teams/${id}`);
      return res.data;
    } catch (error: any) {
      throw error;
    }
  },
  getAllUsersAndTeams: async function (id: any) {
    try {
      const res: any = await axiosAccountPrivate.get(`/user-teams`);
      return res.data;
    } catch (error: any) {
      throw error;
    }
  },
  getListUserSettings: async function (params: any) {
    try {
      const res: any = await axiosAccountPrivateV2.get(`/admin/users/paging`, {
        params,
      });
      return res.data;
    } catch (error: any) {
      throw error;
    }
  },
  updateUser: async function (id: any, payload: any) {
    try {
      const res: any = await axiosAccountPrivateV2.put(
        `/admin/users/${id}`,
        payload
      );
      return res.data;
    } catch (error: any) {
      throw error;
    }
  },

  exportListUserSettings: async function (params: any) {
    try {
      const res: any = await axiosAccountPrivateV2.get(`/admin/users/export`, {
        params: params,
        responseType: "blob",
      });
      return res;
    } catch (error: any) {
      throw error;
    }
  },

  downloadTemplate: async function () {
    try {
      const res: any = await axiosAccountPrivateV2.get(
        `/admin/users/download-template`
      );
      return res.data;
    } catch (error: any) {
      throw error;
    }
  },

  getListWorkSpaces: async function () {
    try {
      const res: any = await axiosAccountPrivateV2.get(`workspaces`);
      return res.data;
    } catch (error: any) {
      throw error;
    }
  },
  getListRolesV2: async function () {
    try {
      const res: any = await axiosAccountPrivateV2.get(`admin/roles`);
      return res.data;
    } catch (error: any) {
      throw error;
    }
  },

  getListUserV2: async function (params?: any) {
    try {
      const res: any = await axiosAccountPrivateV2.get(`users/module`, params);
      return res.data;
    } catch (error: any) {
      throw error;
    }
  },

  getUserInfoV2ById: async function (id: any) {
    try {
      const res: any = await axiosAccountPrivateV2.get(
        `users/info-full/${id}?has_mobile=1`
      );
      return res.data;
    } catch (error: any) {
      throw error;
    }
  },
  getUserInfoById: async function (id: any) {
    try {
      const res: any = await axiosAccountPrivateV2.get(`users/info/${id}`);
      return res.data;
    } catch (error: any) {
      throw error;
    }
  },

  getUserAdminInfoV2ById: async function (id: any) {
    try {
      const res: any = await axiosAccountPrivateV2.get(
        `/admin/users/info/${id}`
      );
      return res.data;
    } catch (error: any) {
      throw error;
    }
  },
  checkIsOwnerAndIsConcurrenlyManagers: async function (id: any) {
    try {
      let params: any = {
        relations: ["workspace.owner", "jobApplies.workspace"],
      };
      const res: any = await axiosAccountPrivateV2.get(`/users/info/${id}`, {
        params,
      });

      return res?.data?.data;
    } catch (error: any) {
      throw error;
    }
  },

  importListUserSettings: async function (formData: any) {
    try {
      const res: any = await axiosAccountPrivateV2.post(
        `/admin/users/import`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "blob",
        }
      );
      return res;
    } catch (error: any) {
      throw error;
    }
  },
  getAllUserV2: async function (params: any = {}): Promise<any> {
    try {
      const response = await axiosAccountPrivateDomainV2.get("/users/module", {
        params,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
