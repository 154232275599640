import moment from 'moment';
import { CheckEnum, FileTypeEnum, StatusEnum } from './enum';
import { KpiSyncHistorySlice } from './interface';
import i18n from '../../../../i18n';

export enum STATUS {
  Success = 1,
  Fail
}

export enum ACTION {
  Refresh = 'refresh',
  Filter = 'filter'
}

export enum TOAST_TYPE {
  Success = 'success',
  Error = 'error',
  Warning = 'warning'
}

const MONTH_NOW = new Date().getMonth() + 1;
const YEAR_NOW = new Date().getFullYear();

export const OPTION_ITEM_ALL = {
  label: i18n.t('gperformance:syncHistory.filter.all'),
  value: CheckEnum.All
};

export const DEFAULT_FILTER = {
  employeeId: [OPTION_ITEM_ALL],
  readTime: undefined,
  loadTime: [moment(`${MONTH_NOW}/01/${YEAR_NOW}`), moment()],
  status: [CheckEnum.All],
  type: [CheckEnum.All]
};

export const PER_PAGE = 20;
export const DEFAULT_PAGE = 1;
export const DEFAULT_PARAMS = {
  per_page: PER_PAGE,
  page: DEFAULT_PAGE,
  read_start_time: undefined,
  read_end_time: undefined,
  load_start_time: DEFAULT_FILTER.loadTime[0].format('YYYY/MM/DD'),
  load_end_time: DEFAULT_FILTER.loadTime[1].format('YYYY/MM/DD'),
  target: 'all'
};

export const initialState: KpiSyncHistorySlice = {
  list: [],
  listEmployee: [],
  paginate: {
    total: 0,
    current_page: 0,
    last_page: 0
  },
  loading: { loadMore: false, list: false, employee: false },
  status: 'idle',
  filterParams: { ...DEFAULT_PARAMS },
  action: { id: null, action: null },
  resetFormFilter: false,
  heightFilter: 0
};

export const MONTHS_OF_THE_YEAR = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const OPTION_STATUS = [
  {
    value: StatusEnum.UnRead,
    label: i18n.t('gperformance:syncHistory.filter.unRead')
  },
  {
    value: StatusEnum.Success,
    label: i18n.t('gperformance:syncHistory.filter.success')
  },
  {
    value: StatusEnum.Fail,
    label: i18n.t('gperformance:syncHistory.filter.fail')
  }
];

export const OPTION_FILE_TYPE = [
  {
    value: FileTypeEnum.Density,
    label: i18n.t('gperformance:syncHistory.filter.density')
  },
  {
    value: FileTypeEnum.Result,
    label: i18n.t('gperformance:syncHistory.filter.result')
  }
];
