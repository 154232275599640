export enum StatusEnum {
  UnRead = 1,
  Fail,
  Success
}

export enum CheckEnum {
  All = 'all'
}

export enum FileTypeEnum {
  Density = 1,
  Result,
  PerformanceApproval
}

export enum UploadTypeEnum {
  RunBack,
  Upload
}
