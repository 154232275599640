import moment from 'moment';
import { CheckEnum, GoalTypeEnum, StatusEnum, SubjectEnum } from './enum';
import { ComputingManagementSlice, TreeData } from './interface';
import { ConfigFilter, OptionSelect } from './interface';

export const MONTHS: string[] = [
  ...Array.from({ length: 12 }, (_, i) => ('0' + (i + 1)).slice(-2))
];
const offsetYear = 5;
export const YEARS: number[] = [
  ...Array.from(
    { length: offsetYear * 2 + 1 },
    (_, i) => new Date().getFullYear() - offsetYear + i
  )
];

export const MONTH_YEAR_OPTIONS = MONTHS.map((month) => ({
  label: month + '/' + new Date().getFullYear(),
  value: month
}));

export const MONTH_OPTIONS = MONTHS.map((_year) => ({
  label: _year,
  value: _year
}));

export const YEAR_OPTIONS = YEARS.map((_year) => ({
  label: _year,
  value: _year
}));

export const PARAM_HEADER = ['field', 'condition', 'start', 'end', 'result'];

export enum STATUS {
  Active = 1,
  Inactive
}

export enum ACTION {
  Delete = 'delete',
  Edit = 'edit',
  View = 'view',
  Create = 'create',
  DownloadTemplate = 'download_template',
  ExportExcel = 'export_excel',
  Upload = 'upload',
  Rejected = 'rejected',
  Approve = 'approve',
  Expand = 'expand',
  CheckExpand = 'check_expand'
}

export enum TOAST_TYPE {
  Success = 'success',
  Error = 'error',
  Warning = 'warning'
}

export const PER_PAGE = 20;
export const DEFAULT_PAGE = 1;

export const OPTION_ITEM_ALL = {
  label: 'Tất cả',
  value: CheckEnum.All
};

const MONTH_NOW = moment().format('MM');
const YEAR_NOW = new Date().getFullYear().toString();
export const DEFAULT_FILTER: ConfigFilter = {
  subjectType: SubjectEnum.Individual,
  employeeId: [],
  goalType: GoalTypeEnum.Month,
  goalId: [CheckEnum.All],
  status: [StatusEnum.SentApprove],
  timeYear: YEAR_NOW,
  timeMonth: MONTH_NOW
};
export const DEFAULT_PARAMS = {
  fields: 'owner,members'
};

export const initialState: ComputingManagementSlice = {
  list: [],
  listSubject: [],
  listWorkspace: [],
  listEmployee: [],
  listGoal: [],
  listPerformance: {},
  listPerformanceWorkspace: {},
  listPerformanceUser: {},
  listOriginWorkspace: [],
  listOriginTable: [],
  listOwnerOfWorkspaceFilter: [],
  initFetching: false,
  initFetchingEmployeeDone: false,
  detailUser: null,
  paginate: {
    total: 0,
    current_page: 0,
    last_page: 0
  },
  loading: {
    list: false,
    searchGoal: false,
    employee: false,
    approve: false,
    workspaceFilter: false,
    detailUser: false
  },
  idsFetchWorkspace: { ids: '', user_ids: '' },
  idsAllWorkspaceOfUser: [],
  idsAllWorkspaceFilter: [],
  idsSelectedWorkspaceFilter: [],
  idsWorkspaceCanBeCheckFilter: [],
  idsWorkspaceCanBeApprove: [],
  idsWorkspaceUserOwner: [],
  keysWorkspaceCanBeApprove: [],
  currentDepthPerformance: [],
  currentDepthWorkspace: [],
  parentIds: [],
  childrenIds: [],
  defaultSelectedKeysFilter: [],
  expandedRowKeys: [],
  defaultExpanded: [],
  selectedRow: [],
  workspaceSelectedIds: [],
  userSelectedIds: [],
  status: 'idle',
  showNoteModal: false,
  filterParams: { ...DEFAULT_PARAMS },
  action: { id: null, action: ACTION.Create },
  resetFormFilter: false,
  heightFilter: 0
};

export const OPTION_SUBJECT: OptionSelect[] = [
  { value: SubjectEnum.Individual, label: 'Theo nhân viên' },
  { value: SubjectEnum.Group, label: 'Theo đơn vị' }
];

export const OPTION_GOAL_TYPE: OptionSelect[] = [
  { value: GoalTypeEnum.Month, label: 'Tháng' },
  { value: GoalTypeEnum.Year, label: 'Năm' }
];

export const OPTION_STATUS: OptionSelect[] = [
  { value: StatusEnum.SentApprove, label: 'Gửi phê duyệt' },
  { value: StatusEnum.Approved, label: 'Phê duyệt' },
  { value: StatusEnum.Rejected, label: 'Từ chối' }
];

export const ADD_GOAL_HEADERS = [
  'objectSelect',
  'object',
  'goal',
  'goalType',
  'timeMonth',
  'timeYear',
  'density'
];

export const REGEX_DECIMAL_PERCENT = new RegExp(/^\d{1,20}(\.\d{1,2})?$/);

export const MAX_GOAL_FILE_UPLOAD = 10;
export const FILE_GOAL_TYPE_UPLOAD = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];
export const MAX_GOAL_FILE_SIZE = 5;

export const NOTE_MAX_LENGTH = 1000;
