import React from "react";

const IconTimeT = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9399 8.00049C14.9399 11.8322 11.8336 14.938 8.0012 14.938C4.16879 14.938 1.0625 11.8322 1.0625 8.00049C1.0625 4.16874 4.16879 1.06299 8.0012 1.06299C11.8336 1.06299 14.9399 4.16874 14.9399 8.00049Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5751 10.2073L7.74707 8.5205V4.88525"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconTimeT;
