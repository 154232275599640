import React, { CSSProperties, ReactNode, useEffect, useRef } from "react";
import style from "./style.module.scss";

type PropTypes = {
  children: ReactNode;
  classname?: any;
  defaultValue?: any;
  disabled?: Boolean;
  name?: any;
  onChange?: Function;
  style?: CSSProperties;
  gap?: Number;
  top?: Number;
  titleName?: any;
};

const FormRadioGroup = ({
  children,
  classname,
  defaultValue,
  disabled,
  name,
  onChange,
  style,
  gap,
  top,
  titleName,
}: PropTypes) => {
  const ref = useRef<any>();
  const refb = useRef<any>();

  useEffect(() => {
    if (ref.current && refb.current) {
      let checked: any = null;
      let options: any = [];

      if (defaultValue) {
        ref.current.childNodes.forEach((val: any) => {
          if (val.childNodes[1]?.innerText === defaultValue) {
            refb.current.value = defaultValue;
            val.childNodes[0].className = "radioc";
            options = options.concat([
              {
                checked: true,
                label: val.childNodes[2] ? val.childNodes[2].innerText : null,
                value: val.childNodes[1].innerText,
              },
            ]);
            checked = val.childNodes[1].innerText;
          } else {
            options = options.concat([
              {
                checked: false,
                label: val.childNodes[2] ? val.childNodes[2]?.innerText : null,
                value: val.childNodes[1]?.innerText,
              },
            ]);
          }
        });
      } else {
        ref.current.childNodes.forEach((val: any) => {
          if (val.childNodes[0].className === "radioc") {
            refb.current.value = val.childNodes[1]?.innerText;
            options = options.concat([
              {
                checked: true,
                label: val.childNodes[2] ? val.childNodes[2]?.innerText : null,
                value: val.childNodes[1].innerText,
              },
            ]);
            checked = val.childNodes[1].innerText;
          } else {
            options = options.concat([
              {
                checked: false,
                label: val.childNodes[2] ? val.childNodes[2]?.innerText : null,
                value: val.childNodes[1]?.innerText,
              },
            ]);
          }
        });
      }
      if (onChange) {
        onChange(checked, options);
      }
    }
  }, [ref.current, defaultValue]);

  useEffect(() => {
    if (ref.current) {
      //Khoảng cách giữa các phần tử trong Layout
      if (gap) {
        ref.current.style.display = `flex`;
        ref.current.style.gap = `${gap}px`;
      }
      //Khoảng cách của layout với phần tử trên nó
      if (top) {
        ref.current.style.marginTop = `${top}px`;
      }
    }
  }, [ref.current]);

  const onClicks = (e: any) => {
    if (!disabled) {
      let checked: any = null;
      let options: any = [];
      ref.current.childNodes.forEach((val: any) => {
        if (e.target.className === "radio" || e.target.className === "radioc") {
          if (val.childNodes[0] === e.target) {
            val.childNodes[0].className = "radioc";
            refb.current.value = val.childNodes[1]?.innerText;
            options = options.concat([
              {
                checked: true,
                label: val.childNodes[2] ? val.childNodes[2]?.innerText : null,
                value: val.childNodes[1]?.innerText,
              },
            ]);
            checked = val.childNodes[1].innerText;
          } else {
            val.childNodes[0].className = "radio";
            options = options.concat([
              {
                checked: false,
                label: val.childNodes[2] ? val.childNodes[2]?.innerText : null,
                value: val.childNodes[1]?.innerText,
              },
            ]);
          }
        }
      });

      if (onChange) {
        onChange(checked, options);
      }
    }
  };
  return (
    <>
      {titleName ? titleName : ""}
      <input name={name} ref={refb} type="text" style={{ display: "none" }} />
      <div style={style} onClick={onClicks} className={classname} ref={ref}>
        {children}
      </div>
    </>
  );
};

export default FormRadioGroup;
