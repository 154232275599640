import style from "./style.module.scss";
import { TypePropsStyle, TypeProps, MapType } from "./type";
import * as CONSTANT from "./constant";
import LoadingRing from "../LoadingRing/LoadingRing";

/*
* là giá trị mặc định
--------------------*ROLE*-------------------------
Dùng để phân loại nhóm button
default*: button mặc định 
icon: button chỉ hiện thị icon
--------------------*VARIANT*----------------------
Dùng để phân loại layout của button
outlined: không được sử dụng chung với các biến [theme]
--------------------*THEME*------------------------
Dùng để thay đổi màu nền
theme không được thêm vào nếu đã có biến variant: 'outlined'
--------------------*DENSE*------------------------
Dùng để điều chỉnh padding của button
--------------------*SIZE-BTN*---------------------
Dùng để điẻu chỉnh chiềù rộng cố định của button
--------------------*SIZE-ICON*--------------------
Dùng để điẻu chỉnh kích thước cố định của button chỉ có role="icon"
*/

const KEYS_STYLE: { [key: string]: MapType } = {
  role: CONSTANT.ROLE as MapType,
  dense: CONSTANT.DENSE as MapType,
  variant: CONSTANT.VARIANT as MapType,
  theme: CONSTANT.THEME as MapType,
  themeIcon: CONSTANT.THEME_ICON as MapType,
  sizeBtn: CONSTANT.SIZE_BTN as MapType,
  sizeIcon: CONSTANT.SIZE_ICON as MapType,
  height: CONSTANT.HEIGHT as MapType,
  visible: CONSTANT.VISIBLE,
};

const styles = (props: TypePropsStyle) => {
  const keyProps = { ...props } as MapType;
  let className = [style["btn"]];
  Object.keys(props).map((item: string) => {
    return (className = [
      ...className,
      style[KEYS_STYLE[item][keyProps[item]]],
    ]);
  });
  return className.join(" ");
};

const Button = (props: TypeProps) => {
  const {
    onClick,
    children,
    disabled = false,
    visible = true,
    id,
    name,
    className,
    type,
    buttonStyles,
    status = false,
    ...other
  } = props;
  return (
    <div>
      <button
        name={name}
        id={id}
        className={`${styles({ ...other, visible: visible })} ${className}`}
        disabled={disabled}
        onClick={onClick}
        type={type}
        style={buttonStyles}
      >
        {children}
        {status ? <LoadingRing size="sm" color="black" /> : ""}
      </button>
    </div>
  );
};

export default Button;
