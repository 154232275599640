import moment from 'moment';
import { CheckEnum, GoalTypeEnum, StatusEnum, SubjectEnum } from './enum';
import {
  ComputingManagementSlice,
  ConfigFilter,
  OptionSelect
} from './interface';
import i18n from '../../../../i18n';

export const MONTHS: string[] = [
  ...Array.from({ length: 12 }, (_, i) => ('0' + (i + 1)).slice(-2))
];
const offsetYear = 5;
export const YEARS: number[] = [
  ...Array.from(
    { length: offsetYear * 2 + 1 },
    (_, i) => new Date().getFullYear() - offsetYear + i
  )
];

export const MONTH_YEAR_OPTIONS = MONTHS.map((month) => ({
  label: month + '/' + new Date().getFullYear(),
  value: month
}));

export const MONTH_OPTIONS = MONTHS.map((_year) => ({
  label: _year,
  value: _year
}));

export const YEAR_OPTIONS = YEARS.map((_year) => ({
  label: _year,
  value: _year
}));

export const PARAM_HEADER = ['field', 'condition', 'start', 'end', 'result'];

export enum STATUS {
  Active = 1,
  Inactive
}

export enum ACTION {
  Delete = 'delete',
  Edit = 'edit',
  View = 'view',
  Create = 'create',
  DownloadTemplate = 'download_template',
  ExportExcel = 'export_excel',
  Upload = 'upload'
}

export enum TOAST_TYPE {
  Success = 'success',
  Error = 'error',
  Warning = 'warning'
}

export const PER_PAGE = 20;
export const DEFAULT_PAGE = 1;

export const OPTION_ITEM_ALL = {
  label: i18n.t('gperformance:computingManagement.filter.all'),
  value: CheckEnum.All
};

const MONTH_NOW = moment().format('MM');
const YEAR_NOW = new Date().getFullYear();
export const DEFAULT_FILTER: ConfigFilter = {
  subjectType: SubjectEnum.Individual,
  employeeId: [OPTION_ITEM_ALL],
  goalType: GoalTypeEnum.Month,
  goalId: [OPTION_ITEM_ALL],
  status: [CheckEnum.All],
  timeYear: YEAR_NOW,
  timeMonth: [MONTH_NOW]
};
export const DEFAULT_PARAMS = {
  per_page: PER_PAGE,
  page: DEFAULT_PAGE,
  type_unit: DEFAULT_FILTER.subjectType,
  type: DEFAULT_FILTER.goalType,
  month: [MONTH_NOW + '/' + YEAR_NOW],
  target: 'all'
};

export const initialState: ComputingManagementSlice = {
  list: [],
  listSubject: [],
  listSubjectIds: [],
  listSubjectActive: [],
  listEmployee: [],
  listEmployeeActive: [],
  listGoal: [],
  listAllGoals: [],
  paginate: {
    total: 0,
    current_page: 0,
    last_page: 0
  },
  loading: {
    loadMore: false,
    list: false,
    add: false,
    delete: false,
    update: false,
    searchGoal: false,
    employee: false,
    employeeActive: false,
    editGoal: false,
    upload: false,
    import: false,
    details: false
  },
  fileIdUploadSuccess: [],
  files: [],
  expandedSearch: false,
  status: 'idle',
  showAddModal: false,
  showEditModal: false,
  showUploadModal: false,
  filterParams: { ...DEFAULT_PARAMS },
  action: { id: null, action: ACTION.Create },
  resetFormFilter: false,
  errorAddGoal: null,
  goalDetail: null,
  errorEditGoal: null,
  heightFilter: 0,
};

export const OPTION_SUBJECT: OptionSelect[] = [
  {
    value: SubjectEnum.Individual,
    label: i18n.t('gperformance:computingManagement.filter.withEmployee')
  },
  {
    value: SubjectEnum.Group,
    label: i18n.t('gperformance:computingManagement.filter.withSubject')
  }
];

export const OPTION_GOAL_TYPE: OptionSelect[] = [
  { value: GoalTypeEnum.Month, label: 'Tháng' },
  { value: GoalTypeEnum.Year, label: 'Năm' }
];

export const OPTION_STATUS: OptionSelect[] = [
  {
    value: StatusEnum.Created,
    label: i18n.t('gperformance:computingManagement.filter.created')
  },
  {
    value: StatusEnum.SentApprove,
    label: i18n.t('gperformance:computingManagement.filter.sentApprove')
  },
  {
    value: StatusEnum.Approved,
    label: i18n.t('gperformance:computingManagement.filter.approved')
  },
  {
    value: StatusEnum.Rejected,
    label: i18n.t('gperformance:computingManagement.filter.rejected')
  }
];

export const FILTER_FIELD_HAS_CHECK_ALL = [
  'status',
  'employeeId',
  'goalId',
  'timeMonth'
];

export const ADD_GOAL_HEADERS = [
  'objectSelect',
  'object',
  'goal',
  'goalType',
  'timeMonth',
  'timeYear',
  'density'
];

export const REGEX_DECIMAL_PERCENT = new RegExp(/^\d{1,20}(\.\d{1,2})?$/);

export const REGEX_PERCENT_TEN_DECIMAL = new RegExp(/^\d{1,}(\.\d{1,10})?$/);

export const MAX_GOAL_FILE_UPLOAD = 10;
export const FILE_GOAL_TYPE_UPLOAD = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.template.macroEnabled.12',
  'application/vnd.ms-excel.addin.macroEnabled.12',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12'
];
export const MAX_GOAL_FILE_SIZE = 5;
