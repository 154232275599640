import { createAsyncThunk } from '@reduxjs/toolkit';
import KpiGoalGroupApi from './api';
import { clearFormFilter, clearSlice } from './slice';
import { FilterParam } from '../utils/interface';
import { DEFAULT_PARAMS, openNotification, TOAST_TYPE } from '../utils';

export const getList = createAsyncThunk(
  'kpiGoalGroup/getList',
  async (params: FilterParam) => {
    try {
      const response = await KpiGoalGroupApi.list(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListMore = createAsyncThunk(
  'kpiGoalGroup/getListMore',
  async (params: FilterParam) => {
    try {
      const response = await KpiGoalGroupApi.list(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const add = createAsyncThunk(
  'kpiGoalGroup/add',
  async (payload: { body: any; message: any }, { dispatch }) => {
    const { body, message } = payload;
    try {
      const response = await KpiGoalGroupApi.add(body);
      dispatch(clearFormFilter(true));
      dispatch(getList({ ...DEFAULT_PARAMS }));
      openNotification({
        type: TOAST_TYPE.Success,
        message: message.success.create
      });
      return response;
    } catch (error) {
      openNotification({
        type: TOAST_TYPE.Error,
        message: message.error.unique
      });
      throw error;
    }
  }
);

export const detail = createAsyncThunk(
  'kpiGoalGroup/detail',
  async (id: number) => {
    try {
      const response = await KpiGoalGroupApi.detail(id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const update = createAsyncThunk(
  'kpiGoalGroup/update',
  async (payload: { id: number; body: any; message: any }, { dispatch }) => {
    const { id, body, message } = payload;
    try {
      const response = await KpiGoalGroupApi.update(id, body);
      dispatch(clearFormFilter(true));
      dispatch(getList({ ...DEFAULT_PARAMS }));
      openNotification({
        type: TOAST_TYPE.Success,
        message: message.success.edit
      });
      return response.data;
    } catch (error) {
      openNotification({
        type: TOAST_TYPE.Error,
        message: message.error.unique
      });
      throw error;
    }
  }
);

export const deleteGoalGroup = createAsyncThunk(
  'kpiGoalGroup/deleteGoalGroup',
  async (params: { id: number; message: any }, { dispatch }) => {
    const { id, message } = params;
    dispatch(clearSlice());
    try {
      const response = await KpiGoalGroupApi.delete(id);
      dispatch(clearFormFilter(true));
      dispatch(getList({ ...DEFAULT_PARAMS }));
      openNotification({
        type: TOAST_TYPE.Success,
        message: message.success.delete
      });
      return response;
    } catch (error) {
      openNotification({
        type: TOAST_TYPE.Error,
        message: message.error.delete
      });
      throw error;
    }
  }
);
