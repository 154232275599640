import React, { CSSProperties, ReactNode, useRef } from "react";
import ClickOutsideContainer from "../ClickOutsideContainer";
const MenuContainer = ({
  children,
  className,
  style,
  onClickMenu,
  customStyle,
}: {
  children: ReactNode;
  className: String;
  style: { [key: string]: string };
  onClickMenu: Function;
  customStyle?: CSSProperties;
}) => {
  const ref = useRef(null);
  return (
    <div
      className={[style["dd-menu"], className].join(" ")}
      ref={ref}
      style={customStyle}
    >
      <ClickOutsideContainer refs={ref} callback={onClickMenu}>
        {children}
      </ClickOutsideContainer>
    </div>
  );
};

export default MenuContainer;
