import { Request } from "../../request/Request";
import { WsCode } from "../../request/wsCode";
import {
  axiosAccountPrivateV2,
  axiosReportPrivate,
  axiosWorkLogPrivate,
} from "../../../../api/axios";
import { RequestApiPrivateGTicketV2 } from "../../api/axios";

const WorkGroupManagementApi = {
  //get list workgroup
  getListWorkGroup: async (params: {}): Promise<any> => {
    try {
      const result = await Request.getApi(WsCode.workGroups, params);
      if (result) {
        return result.data;
      }
    } catch (error) {
      throw error;
    }
  },

  getListCategory: async (params: {}): Promise<any> => {
    try {
      const result = await Request.getApi(WsCode.workGroups, params);
      if (result) {
        return result.data;
      }
    } catch (error) {
      throw error;
    }
  },
  //add workgroup
  addWorkGroup: async (data: any): Promise<any> => {
    try {
      const result = await Request.postApi(WsCode.workGroups, data);
      if (result) {
        return result;
      }
    } catch (error) {
      return error;
    }
  },
  addWorkGroupV2: async (payload: any): Promise<any> => {
    try {
      const result = await RequestApiPrivateGTicketV2.post(
        "work-groups",
        payload
      );
      if (result) {
        return result;
      }
    } catch (error) {
      throw error;
    }
  },
  updateWorkGroupV2: async (id: any, payload: any): Promise<any> => {
    try {
      const result = await RequestApiPrivateGTicketV2.put(
        `work-groups/${id}`,
        payload
      );
      if (result) {
        return result;
      }
    } catch (error) {
      throw error;
    }
  },
  getWorkGroupByIdV2: async (id: any): Promise<any> => {
    try {
      const result = await RequestApiPrivateGTicketV2.get(
        `work-groups/get-list?work_group_id=${id}`
      );
      return result?.data?.data;
    } catch (error) {
      throw error;
    }
  },
  getWorkGroupsV2: async (params: any): Promise<any> => {
    try {
      const result = await RequestApiPrivateGTicketV2.get("work-groups", {
        params,
      });
      if (result) {
        return result;
      }
    } catch (error) {
      throw error;
    }
  },
  //add workgroup
  updateWorkGroup: async (workgroupId: any, data: any): Promise<any> => {
    try {
      const result = await Request.putApi(
        WsCode.workGroups + `/${workgroupId}`,
        data
      );
      if (result) {
        return result;
      }
    } catch (error) {
      throw error;
    }
  },

  getUserByDepartment: async (data: any) => {
    try {
      if (data) {
        const res: any = await axiosAccountPrivateV2.get(
          "/users/list/module?department_id=" + data
        );
        if (res) {
          if (res.data) {
            return res.data;
          }
        }
      }
    } catch (error: any) {
      if (error) {
        return error.code;
      }
    }
  },

  getUserByWorkSpace: async (data: any) => {
    try {
      if (data) {
        const res: any = await axiosAccountPrivateV2.get(
          "users/module?workspace_ids[]=" + data
        );
        return res?.data;
      }
    } catch (error: any) {
      throw error;
    }
  },

  getUserByDivision: async (data: any) => {
    try {
      if (data) {
        axiosAccountPrivateV2.defaults.params = data;
        const res: any = await axiosAccountPrivateV2.get("/users/list/module");
        if (res) {
          if (res.data) {
            return res.data;
          }
        }
      }
    } catch (error: any) {
      if (error) {
        return error.code;
      }
    }
  },
  getWorkgroupById: async (params: any) => {
    try {
      const res: any = await RequestApiPrivateGTicketV2.get(
        "work-groups/get-list",
        params
      );
      return res?.data?.data;
    } catch (error: any) {
      if (error) {
        return error.code;
      }
    }
  },
  getListWorkLog: async function (task_id: any, module: string): Promise<any> {
    try {
      const response = await axiosWorkLogPrivate.get(
        `/worklog/list-by-task?task_id=${task_id}&module=${module}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  checkTimesheetByTasks: async function (params: any): Promise<any> {
    try {
      const response = await axiosWorkLogPrivate.post(
        `/worklog/get-actual-time-by-task-ids`,
        params
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getReportFilter: async (date: string, userId: string) => {
    try {
      if (date && userId) {
        const res: any = await axiosReportPrivate.get(
          `/report/filters?date=${date}&user_id=${userId}`
        );
        if (res) {
          if (res.data) {
            return res.data;
          }
        }
      }
    } catch (error: any) {
      if (error) {
        return error.code;
      }
    }
  },
  getReportFilterByDays: async (
    from_date: string,
    to_date: string,
    userId: number | undefined
  ) => {
    try {
      if (from_date && to_date && userId) {
        const res: any = await axiosReportPrivate.get(
          `/tasks/filters?from_date=${from_date}&to_date=${to_date}&user_id=${userId}`
        );
        if (res) {
          if (res.data) {
            return res.data;
          }
        }
      }
    } catch (error: any) {
      if (error) {
        return error.code;
      }
    }
  },
};

export default WorkGroupManagementApi;
