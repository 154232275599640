import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";
import {
  ApprovalRequestType,
  EmployeeParameterType,
  FilterPerformanceDataType,
  HistoryParameterType,
  WorkspaceParameterType,
} from "../interfaces";
import { TOAST_TYPE } from "../constants";
import openNotification from "../components/notification";
import { t } from "i18next";
import i18n from "../../../../i18n";
export const getListWorkspace = createAsyncThunk(
  "gPerformanceManagement/getListWorkspace",
  async (params: WorkspaceParameterType) => {
    try {
      const response = await api.workspaces(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);
export const getListEmployee = createAsyncThunk(
  "gPerformanceManagement/getListEmployee",
  async (params: EmployeeParameterType) => {
    try {
      const response = await api.employees(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);
export const getEmployeeDetail = createAsyncThunk(
  "gPerformanceManagement/getEmployeeDetail",
  async (id: string) => {
    try {
      const response = await api.userDetail(id);
      return response;
    } catch (error) {
      throw error;
    }
  }
);
export const getPerformanceByOneSubject = createAsyncThunk(
  "gPerformanceManagement/getPerformanceByOneSubject",
  async (params: FilterPerformanceDataType) => {
    try {
      const response = await api.performanceByOneSubject(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);
export const getPerformanceByManySubject = createAsyncThunk(
  "gPerformanceManagement/getPerformanceByManySubject",
  async (params: FilterPerformanceDataType) => {
    try {
      const response = await api.performanceByManySubject(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);
export const getGoalCategory = createAsyncThunk(
  "gPerformanceManagement/getGoalCategory",
  async () => {
    try {
      const response = await api.getGoalCategory();
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getHistoryPerformance = createAsyncThunk(
  "gPerformanceManagement/getHistoryPerformance",
  async (params: HistoryParameterType) => {
    try {
      const response = await api.getHistoryPerformance(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);
export const sentApproveRequest = createAsyncThunk(
  "gPerformanceManagement/sentApproveRequest",
  async (params: ApprovalRequestType) => {
    try {
      const response = await api.sentApproveRequest(params);
      openNotification({
        type: TOAST_TYPE.Success,
        message: i18n.t("gperformance:header.sendApprovalSuccessfully"),
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
);
export const fetchDataUserManagement = createAsyncThunk(
  "gPerformanceManagement/fetchDataUserManagement",
  async (params: any) => {
    try {
      const response = await api.fetchDataUserManagement(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchDataUserJobs = createAsyncThunk(
  "gPerformanceManagement/fetchDataUserJobs",
  async (params: any) => {
    try {
      const response = await api.fetchDataUserJobs(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);
export const exportExcelOneSubject = createAsyncThunk(
  "gPerformanceManagement/exportExcelOneSubject",
  async (params: any) => {
    try {
      const response = await api.exportExcelOneSubject(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);
export const exportExcelMultipleSubject = createAsyncThunk(
  "gPerformanceManagement/exportExcelMultipleSubject",
  async (params: any) => {
    try {
      const response = await api.exportExcelMultipleSubject(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);
export const fetchAdditionSubject = createAsyncThunk(
  "gPerformanceManagement/fetchAdditionSubject",
  async (params: any) => {
    try {
      const response = await api.performanceByManySubject(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const performanceManagementThunk = () => {};
export default performanceManagementThunk;
