import { useMemo } from "react";
import IconAccept from "../../icons/IconAccept";
import IconAddT from "../../icons/IconAddT";
import IconArrowT from "../../icons/IconArrowT";
import IconCloseT from "../../icons/IconCloseT";
import IconDocsT from "../../icons/IconDocsT";
import IconEditT from "../../icons/IconEditT";
import IconSendT from "../../icons/IconSendT";
import IconTimeT from "../../icons/IconTimeT";
import IconXPerson from "../../icons/IconXPerson";
import style from "./style.module.scss";

const getColorStatus = (value: number) => {
  if (value >= 0) {
    if (value === 0) return style.yellow;
    if (value === 1) return style.green;
    if (value === 2) return style.tomato;
    if (value === 3) return style.tomato;
  }
  return "";
};

const Item = (props: any) => {
  const { val } = props;

  const menuOption: any = useMemo(() => {
    return {
      1: (
        <div className={`${style.icon} ${style.orange} center`}>
          <IconEditT />
        </div>
      ),
      2: (
        <div className={`${style.icon} ${style.primary} center`}>
          <IconSendT />
        </div>
      ),
      3: (
        <div className={`${style.icon} ${style.primary} center`}>
          <IconArrowT />
        </div>
      ),
      4: (
        <div className={`${style.icon} ${style.green} center`}>
          <IconAccept />
        </div>
      ),
      5: (
        <div className={`${style.icon} ${style.green} center`}>
          <IconAccept />
        </div>
      ),
      6: (
        <div className={`${style.icon} ${style.yellow} center`}>
          <IconDocsT />
        </div>
      ),
      7: (
        <div className={`${style.icon} ${style.yellow} center`}>
          <IconAddT />
        </div>
      ),
      8: (
        <div className={`${style.icon} ${style.tomato} center`}>
          <IconXPerson />
        </div>
      ),
      9: (
        <div className={`${style.icon} ${getColorStatus(val.status)} center`}>
          <IconEditT />
        </div>
      ),
      10: (
        <div className={`${style.icon} ${getColorStatus(val.status)} center`}>
          <IconTimeT />
        </div>
      ),
      11: (
        <div className={`${style.icon} ${getColorStatus(val.status)} center`}>
          <IconAccept />
        </div>
      ),
      12: (
        <div className={`${style.icon} ${getColorStatus(val.status)} center`}>
          <IconCloseT />
        </div>
      ),
      13: (
        <div className={`${style.icon} ${style.tomato} center`}>
          <IconCloseT />
        </div>
      ),
      14: (
        <div className={`${style.icon} ${getColorStatus(val.status)} center`}>
          <IconEditT fill="white" />
        </div>
      ),
    };
  }, []);

  return (
    <div className={style.content}>
      {menuOption[Number(val.state)]}
      <div className={style.item}>
        <div className={style.text}>{val.text}</div>
        {val.subText && (
          <div className={style.sub_text}>
            <span className="color-a5a5a5 f-xs">{val.subText}</span>
          </div>
        )}
      </div>
      {val.button && (
        <div className={style.button + " center"}>{val.button}</div>
      )}
    </div>
  );
};

export default Item;
