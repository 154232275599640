import { WsCode } from "../../api";
import { RequestApiPrivate } from "../../api/axios";

const KpiDashboardApi = {
  listPerformance: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.gPerformanceDashboardNonCeo.listPerformance,
        {
          params,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  detailPerformanceUser: async (id: number, params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.gPerformanceDashboardNonCeo.detailPerformanceUser + "/" + id,
        {
          params,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getDashBoardCEO: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.gPerformanceDashboard.dashboardCEO,
        {
          params,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  detailPerformanceCeo: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.gPerformanceDashboardNonCeo.detailPerformanceCeo,
        {
          params,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  detailPerformanceWorkspace: async (id: number, params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.gPerformanceDashboardNonCeo.detailPerformanceWorkspace +
          "/" +
          id,
        {
          params,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  overviewPerformance: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.gPerformanceDashboardNonCeo.overviewPerformance,
        {
          params,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  exportNonCeo: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.gPerformanceDashboardNonCeo.exportNonCeo,
        {
          params,
          responseType: "blob",
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  exportCEO: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.gPerformanceDashboardNonCeo.exportCEO,
        {
          params,
          responseType: "blob",
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export default KpiDashboardApi;
