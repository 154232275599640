import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  convertOKRsDepartment,
  convertOKRsDivision,
  convertOKRsPersonal,
  mergeSubItemWithPrevOKRs,
} from "../helpers/convert";
import {
  requestPersonalOKRs,
  requestDepartmentOKRs,
  requestDivisionOKRs,
  requestGetKRs,
  requestGetTasks,
  requestGetSubOKRs,
} from "../common/request";
import { RootState } from "../../../../store/store";

const initialState = {
  okr: [],
  loading: false,
  status: "idle",
};

export const getPersonalOKRs = createAsyncThunk(
  "role/hod/personal/getOKRs",
  requestPersonalOKRs
);

export const getDepartmentOKRs = createAsyncThunk(
  "role/hod/department/getOKRs",
  requestDepartmentOKRs
);

export const getDivisionOKRs = createAsyncThunk(
  "role/hod/division/getOKRs",
  requestDivisionOKRs
);

export const getSubOKRs = createAsyncThunk(
  "role/hod/personal/getSubOKRs",
  requestGetSubOKRs
);

export const getKRs = createAsyncThunk(
  "role/hod/personal/getKRs",
  requestGetKRs
);

export const getTasks = createAsyncThunk(
  "role/hod/personal/getTasks",
  requestGetTasks
);

const hodSlice = createSlice({
  name: "okr",
  initialState,
  reducers: {
    _onCleanUpOKRs(state) {
      state.okr = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPersonalOKRs.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getPersonalOKRs.fulfilled, (state, action) => {
        state.status = "success";
        state.okr = convertOKRsPersonal(action.payload);
        state.loading = false;
      })
      .addCase(getPersonalOKRs.rejected, (state) => {
        state.status = "failed";
        state.loading = false;
      })
      .addCase(getDepartmentOKRs.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getDepartmentOKRs.fulfilled, (state, action) => {
        state.status = "success";
        state.okr = convertOKRsDepartment(action.payload);
        state.loading = false;
      })
      .addCase(getDepartmentOKRs.rejected, (state) => {
        state.status = "failed";
        state.loading = false;
      })
      .addCase(getDivisionOKRs.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getDivisionOKRs.fulfilled, (state, action) => {
        state.status = "success";
        state.okr = convertOKRsDivision(action.payload);
        state.loading = false;
      })
      .addCase(getDivisionOKRs.rejected, (state) => {
        state.status = "failed";
        state.loading = false;
      })
      .addCase(getSubOKRs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSubOKRs.fulfilled, (state, action) => {
        state.status = "success";
        const payload: any = action.payload;
        state.okr = mergeSubItemWithPrevOKRs(
          {
            level: payload.level,
            parentId: payload.parentId,
            keyValue: payload.keyValue,
            response: payload.response.data,
            parentUUID: payload.parentUUID,
          },
          state.okr
        );
      })
      .addCase(getSubOKRs.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getKRs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getKRs.fulfilled, (state, action) => {
        state.status = "success";
        const payload: any = action.payload;
        state.okr = mergeSubItemWithPrevOKRs(
          {
            level: payload.level,
            parentId: payload.parentId,
            keyValue: payload.keyValue,
            keyID: payload.keyID,
            response: payload.response.data,
            parentUUID: payload.parentUUID,
          },
          state.okr
        );
      })
      .addCase(getKRs.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getTasks.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTasks.fulfilled, (state, action) => {
        state.status = "success";
        const payload: any = action.payload;
        state.okr = mergeSubItemWithPrevOKRs(
          {
            level: payload.level,
            parentId: payload.parentId,
            keyValue: payload.keyValue,
            response: payload.response.data,
            parentUUID: payload.parentUUID,
          },
          state.okr
        );
      })
      .addCase(getTasks.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export default hodSlice.reducer;
export const { _onCleanUpOKRs } = hodSlice.actions;
export const selectorOKRs = (state: RootState) => state.hodSlice.okr;
export const selectorLoadingOKRs = (state: RootState) => state.hodSlice.loading;
