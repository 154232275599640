import { RequestApiKpi, RequestApiKpiEndPoint, WsCode } from '../../api';

const KpiParamManagementApi = {
  getList: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiKpi.get(WsCode.param.list, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getFields: async (code: string): Promise<any> => {
    try {
      const response = await RequestApiKpiEndPoint.get(WsCode.param.fieldList + code);
      return response;
    } catch (error) {
      throw error;
    }
  },

  getEndpointSource: async (): Promise<any> => {
    try {
      const response = await RequestApiKpiEndPoint.get(WsCode.param.endpointSource);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  postParam: async (payload: any): Promise<any> => {
    try {
      const response = await RequestApiKpi.post(WsCode.param.list, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  genResourceCode: async (payload: { name: string }): Promise<any> => {
    try {
      const response = await RequestApiKpi.post(WsCode.param.genResourceCode, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getParam: async (id: number): Promise<any> => {
    try {
      const response = await RequestApiKpi.get(WsCode.param.list + `/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  updateParam: async (id: number, payload: any): Promise<any> => {
    try {
      const response = await RequestApiKpi.put(WsCode.param.list + `/${id}`, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  deleteParam: async (id: number): Promise<any> => {
    try {
      const response = await RequestApiKpi.delete(WsCode.param.list + `/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  downloadParams: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiKpi.get(
        WsCode.param.export,
        {
          params,
          responseType: "blob",
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export default KpiParamManagementApi;
