import { Input, TreeSelect, TreeSelectProps } from "antd";
import {
  ChangeEvent,
  JSXElementConstructor,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { IconSearch } from "../../../../../components/icons";
import { MULTIPLE } from "../../constants";
import { FilterSelectType } from "../../interfaces";
import { renderMaxTag, renderTag, switcherIcon } from "./renderTag";
import styles from "./style.module.scss";
import { cloneDeep } from "lodash";
import { findKeyByLevel } from "../../../common/utils/helper";
import { uniq } from "lodash";
let keysToExpand: any[] = [];

const { SHOW_ALL, SHOW_PARENT } = TreeSelect;
export default function TreeUnit(props: FilterSelectType) {
  const { t } = useTranslation("gperformance", {
    keyPrefix: "performance_filter",
  });
  const [searchTreeValue, setSearchTreeValue] = useState("");
  const { list, onChange, multiple, type, treeDefaultExpand } = props;
  useEffect(() => {
    const value = type === MULTIPLE;
    setModeMultiple(value);
    let result = setDefaultData() as string[];
    setData(() => result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const [treeExpandedKeys, setTreeExpandedKeys] = useState<string[]>([]);

  const handleChangeSearchSubject = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTreeValue(e.target.value);
  };
  const DropdownMenu = useCallback(
    (menu: ReactElement<any, string | JSXElementConstructor<any>>) => {
      return (
        <>
          <Input
            placeholder={t("searchUnitPlaceholder")}
            className="input-search-tree"
            value={searchTreeValue}
            onChange={(value: any) => handleChangeSearchSubject(value)}
            suffix={<IconSearch />}
          />
          {menu}
        </>
      );
    },
    [searchTreeValue, t]
  );
  const [data, setData] = useState<any>([]);
  const onChangeTree = (value: any) => {
    let _value = modeMultiple
      ? value.map((item: any) => Number(item.value))
      : value;
    onChange(_value);
    setData(_value);
  };
  const [modeMultiple, setModeMultiple] = useState(multiple);
  const handleShowTreeNode = (arr: any[] | undefined) => {
    let newArr = arr?.map((item) => String(item)) ?? [];
    newArr = [...new Set(newArr)];
    setTreeExpandedKeys(newArr);
  };
  const setDefaultData = () => {
    const value = Array.isArray(props.value)
      ? props.value.map((item) => String(item))
      : String(props.value);
    let result = value;
    if (modeMultiple) {
      result = Array.isArray(value) ? value : value[0];
    }
    return result;
  };
  const clearSearch = () => {
    setSearchTreeValue("");
  };
  useEffect(() => {
    const isMultiple = type === MULTIPLE;
    setModeMultiple(isMultiple);
    const _value = cloneDeep(props.value);
    let result = Array.isArray(_value)
      ? _value.map((item) => String(item))
      : String(_value);
    if (isMultiple) {
      result = Array.isArray(_value) ? _value?.map((item) => String(item)) : [];
    } else {
      result = String(_value);
    }
    setData(result);
  }, [props.value]);
  const [classTree, setClassTree] = useState("");
  useEffect(() => {
    const _classTree =
      typeof props?.value === "object" &&
      props?.value?.length &&
      props?.value?.length <= 2
        ? `tag-number-${props?.value?.length}`
        : `tag-number`;
    setClassTree(_classTree);
  }, [props.value]);
  const commonProps: TreeSelectProps = {
    virtual: true,
    multiple: modeMultiple,
    placeholder: t("searchUnitPlaceholder"),
    popupClassName: `kpi-wrapper dropdown-object-tree`,
    onChange: onChangeTree,
    searchValue: searchTreeValue,
    switcherIcon: switcherIcon,
    dropdownRender: DropdownMenu,
    value: data,
    autoClearSearchValue: false,
    treeNodeFilterProp: "title",
    treeData: list,
    showSearch: false,
    treeExpandedKeys,
    onTreeExpand: handleShowTreeNode,
    onDropdownVisibleChange: (status: boolean) =>
      status && setSearchTreeValue(""),
    onBlur: clearSearch,
  };
  const hasSearchTerm = (n: string, searchTerm: string) =>
    n.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
  function searchFilter(array: any[], name: string, explainKeys: any[]) {
    return array.reduce((r, { children = [], ...o }) => {
      if (hasSearchTerm(o.title, name)) {
        keysToExpand = uniq([...keysToExpand, ...explainKeys]);
        return r;
      }
      searchFilter(children, name, [...explainKeys, o.key]);
      return r;
    }, []);
  }

  useEffect(() => {
    handleShowTreeNode(treeDefaultExpand);
  }, [treeDefaultExpand]);

  useEffect(() => {
    if (searchTreeValue.trim()) {
      searchFilter(list, searchTreeValue.trim(), []);
      handleShowTreeNode([...keysToExpand]);
      keysToExpand = [];
    } else {
      handleShowTreeNode(treeDefaultExpand);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTreeValue]);

  return (
    <>
      {modeMultiple === true && (
        <TreeSelect
          treeExpandedKeys={treeExpandedKeys}
          {...commonProps}
          className={`${styles["tag"]}  ${styles[classTree]}`}
          treeIcon={false}
          treeCheckable={true}
          multiple={true}
          treeCheckStrictly={true}
          maxTagCount={"responsive"}
          maxTagPlaceholder={renderMaxTag}
          showCheckedStrategy={SHOW_ALL}
          allowClear={true}
          tagRender={renderTag}
        />
      )}
      {modeMultiple === false && (
        <TreeSelect
          {...commonProps}
          treeExpandedKeys={treeExpandedKeys}
          allowClear={false}
          treeIcon={true}
          multiple={false}
          treeCheckable={false}
          showCheckedStrategy={SHOW_PARENT}
        />
      )}
    </>
  );
}
