export const DATE_FORMAT = {
  FE: "dd/MM/yyyy",
  FE_HH_MM: "dd/MM/yyyy HH:mm",
  FE_DD_MM_YY_HH_MM_SS: "dd/MM/yy HH:mm:ss",
  BE: "yyyy-MM-dd",
};

export const DATE_FORMAT_MOMENT = {
  FE: "dd/MM/yyyy",
  BE: "YYYY-MM-DD",
};

export const NOT_COMPLETED_YET = 0;

export const INPUT_FILE_TYPE: { [key: string]: string } = {
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  csv: ".csv",
  image: "image/*",
  video: "video/*",
  audio: "audio/*",
  txt: "text/plain",
};

//G -Process
export const StatusGProcess = {
  draff: 1,
  activated: 3,
  watting: 2,
  reject: 4,
  expire: 5,
  default: 0,
};
export const field = {
  KRParent: "constants.KRParent",
  okrOwner: "constants.okrOwner",
  density: "constants.density",
  type: "constants.type",
  taskName: "constants.taskName",
  fromDate: "constants.fromDate",
  toDate: "constants.toDate",
  assignee: "constants.assignee",
  name: "constants.name",
  subOkrName: "constants.subOkrName",
  description: "constants.description",
  file: "constants.file",
  users: "constants.users",
  KR: "constants.KR",
  dueDate: "constants.dueDate",
  creatorId: "constants.creatorId",
  status: "constants.status",
  target: "constants.target",
};

export const GPROCESS_TOKEN = "gprocess_token";

export const CONDITION = {
  ALL_USER: 1,
  ONE_USER: 2,
};
export const STEP_CONDITION = {
  ONE_CONDITION: 1,
  TWO_CONDITION: 2,
};

export const LANGUAGES = {
  EN: "en",
  VN: "vn",
};

export const DEFAULT_LANGUAGE = LANGUAGES.VN;

export const ROLES_ID = {
  CEO: 1,
  DCEO: 2,
  CFO: 3,
  MDNS: 4,
  MDSS: 5,
  HOD: 6,
  SM: 7,
  TEAMLEAD: 8,
  STAFF: 9,
};

export const DEFAULT_CALL_API = {
  PAGE: 1,
  INDEX: 1,
  LIMIT: 20,
  START: 0,
  SUCCESS: 200,
  LENGTH: 10,
  LENGTH_OBJECT: 5,
  MAX_LENGTH_TEXTAREA: 250,
  MAX_LENGTH_INPUT: 50,
  TIME: 1000,
  UNIT: 2,
  TYPE: 3,
  CANCEL: 4,
  COMMENT: 4000,
};

export const TEXTAREA = {
  COMMENT_MAX_LENGTH: 4000,
};
export const TYPE_OBJECT = {
  GROUP_USER: 2,
  DEPARTMENT: 3,
  ROLE: 1,
  USER: 5,
  DEPARTMENT_ROLE: 4,
  ACTION: 1,
  INFO: 2,
  TITLE: 7,
  ALL_USER: 6,
};
export const STATUS_SSC = {
  SUCCESS: "Hoàn thành",
  WAITING: "Đang thực hiện",
  BLOCK: "Không duyệt",
};

export const A_OBJECT = {
  step_id: 1,
  object_action_type: "1",
  object_type: 5,
  object_position: "1",
  created_at: 1234567890,
  status: "1",
  process_id: 1,
};

export const MAX_LENGTH_FILE = 10;
export const MAX_CHECK_LIST = 10;
export const TODAY = Math.round(Date.now() / 1000);
export const G_PROCESS_FILE_FOLDER = "G-PROCESS-SERVICE";

export const APPROVAL_ID_DRAFF = 4;

export const APPROVAL_ID = 3;
export const USER_ID = 5;
export const GROUP_ID = 2;
export const APPROVAL_WAITING_ID = 1;

export const TARGET_APPLY_TYPE_USER = 1;

export const GROUP_RICK_ID = 1;
export const GROUP_RICK_NAME = "Quản trị rủi ro";
export const STEP_OBJECT_ACTIVE = 1;

export const STEP_OBJECT_INACTIVE = 0;

export const STEP_ID_DEFAULT = 1;
export const PROCESS_ID_DEFAULT = 1;

export const VERSION_DEFAULT_PROCESS = 1;
export const STEP_ODER_START = 1;
export const SLA_QUANTITY = 1;
export const SLA_UNIT = 2;

export const INTERNAL_SCOPE = 2;
export const GROUP_SCOPE = 1;

export const TRANSFER_CONDITION_DEFAULT = 2;

export const TYPE_ARRAY_OBJECT = [1, 2, 3, 4, 5];

export const TIME_SHEET_DEFAULT = 0;
export const TIME_SHEET_ACTIVE = 1;

export const MAX_PAGE_SIZE = 10000;
export const NUMBER_OF_DAY = 2;
export const ONE_HUNDRED = 1000;
export const RATING = {
  NEED_TO_IMPROVE: 70,
  MEDIUM: 90,
  SATISFIED: 100,
};
export const MONTH = {
  JAN: 1,
  FEB: 2,
  MAR: 3,
  APR: 4,
  MAY: 5,
  JUN: 6,
  JULY: 7,
  AUG: 8,
  SEP: 9,
  OCT: 10,
  NOV: 11,
  DEC: 12,
};
export const YEAR = {
  FOUR_YEAR: 4,
  ONE_HUNDRED_YEAR: 100,
  FOUR_HUNDRED_YEAR: 400,
};
export const DAY = {
  ONE_DAY: 1,
  SUPERFLOUOS: 31,
  ENOUGH: 30,
  SHORT: 29,
  SUPER_SHORT: 28,
};
export const DAY_ERROR = 0;
export const ZERO_PERCENT = 0;
export const ONE_HUNDRED_PERCENT = 100;
export const PAGE = {
  TWENTY: 20,
  TEN: 10,
  ONE: 1,
};
export const LAST_5_DAYS = 5;

export const OBJECT_RICK_MANAGEMENT = {
  step_id: null,
  object_action_type: APPROVAL_ID,
  object_type: GROUP_ID,
  object_id: GROUP_RICK_ID,
  object_name: GROUP_RICK_NAME,
  object_position: null,
  created_at: TODAY,
  status: STEP_OBJECT_ACTIVE,
  process_id: null,
};

export const OBJECT_ACTION_TYPE_PROCESS_OWNERSHIP = 0;
export const OBJECT_ACTION_TYPE_EXECUTOR = 1;
export const OBJECT_ACTION_TYPE_RECEIVE_INFORMATION = 2;
export const OBJECT_ACTION_TYPE_APPROVE = 3;
export const OBJECT_ACTION_TYPE_APPROVE_DRAFT = 4;

export const STATUS = {
  active: 1,
  inActive: 0,
};
export const ERROR_500 = "ERR_NETWORK";

export const STEP_ODER_DEFAULT = 2;

export const DEFAULT_STEP_CHILD = 1;

export enum ENUMS_KEY_STATUS_HISTORY {
  DRAFF = 0,
  INACTIVE = 1,
  ACTIVE = 2,
  COPY = 3,
  VERSION = 4,
  APPROVAL = 5,
  REDIRECT = 6,
  SEND_APPROVAL = 7,
  CANCEL = 8,
  COMPLETED = 9,
}

export const KEY_STATUS_HISTORY = {
  DRAFF: 0,
  INACTIVE: 1,
  ACTIVE: 2,
  COPY: 3,
  VERSION: 4,
  APPROVAL: 5,
  REDIRECT: 6,
  SEND_APPROVAL: 7,
  CANCEL: 8,
  COMPLETED: 9,
};

export const PAGE_SIZE = 20;
export const PAGE_DEFAULT = 0;

export const TYPE_ROLLBACK = {
  ADDITION: 1,
  SEQUENTIAL: 2,
};

export const PAGING = {
  PAGE_DEFAULT: 1,
  LIMIT: 20,
};

export const OPTION_ACTION_VOTES = [
  {
    label: "unAprrove",
    id: 4,
  },
  {
    label: "aprrove",
    id: 3,
  },
];

export const OPTION_MISSION_REQUEST = [
  {
    label: "createRequest",
    id: 3,
  },
];

export const OPTION_ACTION_TRANSFER = [
  {
    label: "unAprrove",
    id: 4,
  },
  {
    label: "aprrove",
    id: 3,
  },
  {
    label: "transfer",
    id: 2,
  },
];

export const OPTION_ACTION_REMOVE_VOTE = {
  label: "Hủy phiếu yêu cầu",
  value: 99,
  step_order: [],
};

export const OPTION_ACTION_INIT = [
  {
    label: "draff",
    id: 1,
  },
  {
    label: "createRequest",
    id: 2,
  },
];

export const ACTION_VOTES_DEFAULT = 2;

export const OPTION_ROLLBACK = [
  { id: 1, label: "additional" },
  { id: 2, label: "sequential_execution" },
];

export const OPTION_END_VOTES = [
  {
    label: "endProcess",
    id: 1,
  },
];

export const OPTION_FORWARD_STEP = [
  {
    label: "transfer",
    id: 2,
  },
];

export const VALUE_END_VOTES = 1;

export const ROLLBACK_DEFAULT = 1;

export const PRIORITY = [
  {
    id: 1,
    name: "low",
  },
  {
    id: 2,
    name: "medium",
  },
  {
    id: 3,
    name: "high",
  },
];

export const WORKING_POSITION = 1;
export const GROUP_USER = 2;
export const GROUP_DEPARTMENT = 3;
export const GROUP_DEPARTMENT_AND_POSITION = 4;
export const LIST_USER = 5;
export const ALL_USER = 6;

export const STATUS_NEXT_PERFORMER_TYPE = {
  POSITION: 1,
  GROUP: 2,
  DEPARTMENT: 3,
  DEPARTMENT_AND_ROLES: 4,
  USER: 5,
  ALL_USER: 6,
  DIVISION: 7,
};

export const OBJECT_ACTION_TYPE = {
  NEXT_PERFORMER: 1,
  NEXT_INFORMER: 2,
};

export const ID_ALL_USER = 999999999;

export const DATA_OBJECT_KEY = [
  {
    id: STATUS_NEXT_PERFORMER_TYPE.POSITION,
    name: "position",
  },
  {
    id: STATUS_NEXT_PERFORMER_TYPE.GROUP,
    name: "userGroup",
  },
  {
    id: STATUS_NEXT_PERFORMER_TYPE.DEPARTMENT,
    name: "department",
  },
  {
    id: STATUS_NEXT_PERFORMER_TYPE.DEPARTMENT_AND_ROLES,
    name: "titleAndDepartment",
  },
  {
    id: STATUS_NEXT_PERFORMER_TYPE.USER,
    name: "user",
  },
  {
    id: STATUS_NEXT_PERFORMER_TYPE.ALL_USER,
    name: "allUser",
  },
];

export const OBJECT_ELEMENT = {
  groups: {
    options: DATA_OBJECT_KEY,
    value: null,
  },
  subject: {
    options: [],
    value: [],
  },
  regency: {
    options: [],
    value: [],
  },
};

export const TICKET_ACTION = {
  DRAFF: 1,
  INPROGRESS: 2,
};

export const TICKET_STATUS = {
  DRAFF: 0,
  WAIT: 1,
  APPROVE: 2,
  REFUSE: 3,
};

export const TASK_APPROVAL_STATUS = {
  DRAFF: 0,
  IN_PROGRESS: 1,
  DONE: 2,
  CLOSE: 3,
  UN_APPROVED: 4,
};

export const COMBINE_TYPE = {
  AND: { label: "Và", value: 1 },
  OR: { label: "Hoặc", value: 2 },
};

export const STATUS_PROCESS_TYPE = {
  WAITING: 1,
  SUCCESS: 2,
  CANCEL: 3,
};

export const STATUS_PROCESS_APPROVE = 3;
export const STATUS_PROCESS_EXPIRED = 5;
export const DEPARTMENT_LEVER = 4;
export const DEPARTMENT_LEVER_MAX = 5;
export const TASK_ACTIONS = {
  APPROVE: 3,
  FORWARD: 2,
  UN_APPROVE: 4,
};

// loại hành động (1 đề xuất, 0: kết thúc, 2 chuyển tiếp, 3: phê duyệt)
export const ACTION_TYPE_TASK = {
  OFFER: 1,
  END: 0,
  FORWARD: 2,
  APPROVE: 3,
};

export const URL_REJECT_AFTER_LOGIN = "url_reject_after_login";

export const VALUE_END_STEP = 999;

export const ErrorCreateDivisionMessage = {
  no_info: "Không có dữ liệu employee",
  existed: "User already in another department or another division",
  dpm_user_existed: "Employees already in another department",
  sever_err: "Server Error",
  active_divisions: "Divisions still active in department",
  not_found_employee: "Not found employee",
  user_inactive: "User not active",
};

export const GGG_MAIL_REGEX =
  /^(?!.*[._]{2})[a-zA-Z0-9]+(?:[._][a-zA-Z0-9]+)*@ggg\.com\.vn$/;

export const ROUTE_PATH = {
  Q_TICKET_SETTINGS: "/system-management/qticket",
};

export const DATE_COMPLETION = 1;
export const OPTIONAL_DATE = 2;
export const CREATE_PROPOSALS = 2;
export const CORPORATE_LEVEL = 1;

export const USER_ACTIVE_STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
};

export const DEPARTMENT_ACTIVE_STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
};

export const WORKSPACE_ACTIVE_STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
};

export const GROUP_ACTIVE_STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
};

export const WORKSPACE_LEVEL = {
  REGION: 2,
  DEPARTMENT: 4,
};
