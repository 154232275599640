import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface CreateDvisionFormState {
  value: any;
  focusField: string;
}

const initialState: CreateDvisionFormState = {
  value: {},
  focusField: "",
};

const createDivisionSlice = createSlice({
  name: "createDivision",
  initialState,
  reducers: {
    updateForm(state, action: PayloadAction<any>) {
      state.value = action.payload;
    },
    clearForm(state) {
      state.value = initialState
    },
    updateFocusFied(state, action: PayloadAction<any>) {
      state.focusField = action.payload;
    },
  },
});

export const { updateForm, updateFocusFied , clearForm } = createDivisionSlice.actions;
export default createDivisionSlice.reducer;
