import i18n from "../../../i18n";
import { DASHBOARD_STATUS, DASHBOARD_TASK_ROUTE } from "./contant";

export const DASHBOARD = {
  allDashBoard: "/dashboard/overall-systems",
  processChart: "/dashboard/process-chart",
  departments: "departments",
  workspace: "/dashboard/filters",
  sla: "sla/report",
  process: "process",
  processAll: "processes",
  OKR_DASHBOARD: "/dashboard",
  G_TICKET_DASHBOARD: "/reports/result-task",
  G_TICKET_TASKS: "/reports/list-task",
  G_TICKET_FILTER: "/reports/filter",
};

export const STATISTICS_TASK = {
  EXPORT_DATA: "/reports/export",
};

export const POPUP_INFO_TYPE = {
  RESULT: i18n.t("dashboard:result"),
  QUANTITY: i18n.t("dashboard:quality"),
  PROGRESS: i18n.t("dashboard:progess"),
};

export const PROGRESS_PERCENT = {
  COMPLETION_ON_TIME: 100,
  LATE_COMPLETION: 50,
  UNFINISHED_OVERDUE: 0,
  UNFINISHED_NOT_DUE: "N/A",
};

export const DASHBOARD_TABLE_ROW_NAME = {
  GROUP_G: "GROUP_G",
  G_PROCESS: "G_PROCESS",
  G_TICKET: "G_TICKET",
  OKR: "OKR",
};

export const DASHBOARD_CONFIG_TABLE = {
  [DASHBOARD_TABLE_ROW_NAME.G_PROCESS]: {
    name: i18n.t("dashboard:g_process"),
    route: DASHBOARD_TASK_ROUTE.G_PROCESS,
  },
  [DASHBOARD_TABLE_ROW_NAME.G_TICKET]: {
    name: i18n.t("dashboard:g_ticket"),
    route: DASHBOARD_TASK_ROUTE.G_TICKET,
  },
  [DASHBOARD_TABLE_ROW_NAME.OKR]: {
    name: i18n.t("dashboard:okr_project"),
    route: DASHBOARD_TASK_ROUTE.OKR,
  },
};

export const TEXT_G_TICKET_STATUS = {
  NEW_OPEN: i18n.t("dashboard:task.new_open"),
  ON_GOING: i18n.t("dashboard:task.on_going"),
  COMPLETED: i18n.t("dashboard:task.accomplished"),
  CLOSED: i18n.t("dashboard:task.closed"),
  CANCELED: i18n.t("dashboard:task.canceled"),
  OPEN_AGAIN: i18n.t("dashboard:task.re_open"),
  DONE: "done",
  NOT_DONE: "not_done",
};

export const STATUS_TYPE_TASK: any = {
  open: {
    label: i18n.t("dashboard:status.open"),
    class: "just-open",
  },
  inprogress: {
    label: i18n.t("dashboard:status.inprogress"),
    class: "in-progress",
  },
  done: {
    label: i18n.t("dashboard:status.completed"),
    class: "done",
  },
  reopen: {
    label: i18n.t("dashboard:status.reopen"),
    class: "re-open",
  },
  cancelled: {
    label: i18n.t("dashboard:status.cancelled"),
    class: "canceled",
  },
  completed: {
    label: i18n.t("dashboard:status.completed"),
    class: "done",
  },
  closed: {
    label: i18n.t("dashboard:status.closed"),
    class: "closed",
  },
  not_done: {
    class: "in-progress",
    label: i18n.t("dashboard:status.not_done"),
  },
};

export const G_PROCESS_STATUS_LIST = [
  {
    value: DASHBOARD_STATUS.IN_PROGRESS,
    label: "task.on_going",
  },
  {
    value: DASHBOARD_STATUS.COMPLETED,
    label: "task.accomplished",
  },
];

export const G_TICKET_STATUS_LIST = [
  {
    value: 1,
    label: "task.not_done",
  },
  {
    value: 2,
    label: "task.done",
  },
];

export const SLA_REPORT = {
  ticket: "sla/ticket-request-statistics-report",
  process: "sla/task-statistics-report",
};

const TASK_PROGRESS_STATUS = {
  COMPLETED_ON_TIME: "completed_on_time",
  COMPLETED_OVERDUE: "completed_overdue",
  UNCOMPLETED_ON_TIME: "uncompleted_on_time",
  UNCOMPLETED_OVERDUE: "uncompleted_overdue",
};

export function mapTaskProgressStatus(status: string | null): string {
  switch (status) {
    case TASK_PROGRESS_STATUS.COMPLETED_ON_TIME:
      return i18n.t("dashboard:task.completed_on_time");
    case TASK_PROGRESS_STATUS.COMPLETED_OVERDUE:
    case null:
      return i18n.t("dashboard:task.late_completion");
    case TASK_PROGRESS_STATUS.UNCOMPLETED_ON_TIME:
      return i18n.t("dashboard:task.unfinished_not_due");
    case TASK_PROGRESS_STATUS.UNCOMPLETED_OVERDUE:
      return i18n.t("dashboard:task.unfinished_overdue");
    default:
      return "";
  }
}
