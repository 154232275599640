import { KpiSlice } from "./interface";

export const PARAM_HEADER = ["columnName", "show", "actions"];
export enum STATUS {
  Active = 1,
  Inactive
}

export enum PARAMETER_TYPE {
  Target = 1,
  Real
}

export enum ACTION {
  Delete = "delete",
  Edit = "edit",
  View = "view",
  Create = "create",
  Copy = "copy",
  Setup = "setup",
  AddRoom = "addRoom"
}

export enum TOAST_TYPE {
  Success = "success",
  Error = "error",
  Warning = "warning"
}

export enum DEPARMENT_STATUS {
  active = 1
}

export const PER_PAGE = 20;
export const DEFAULT_PAGE = 1;
export const DEFAULT_PARAMS = { per_page: PER_PAGE, page: DEFAULT_PAGE };

export const initialState: KpiSlice = {
  list: [],
  paginate: {
    total: 0,
    current_page: 0,
    last_page: 0
  },
  loading: {
    loadMore: false,
    list: false,
    copy: false,
    add: false,
    update: false,
    delete: false,
    searchParam: false,
    validateFomula: false,
    setting: false,
    previewSetting: false
  },
  status: "idle",
  showModal: {
    addGoal: false,
    previewGoal: false,
    setupGoal: false,
    addRoom: false,
    previewSetup: false
  },
  filterParams: { ...DEFAULT_PARAMS },
  action: { id: null, action: ACTION.Create },
  goal: { status: STATUS.Active },
  resetFormFilter: false,
  goalGroup: [],
  parameters: [],
  parameterByIds: [],
  formula: [],
  departments: [],
  viewSetup: []
};

export const DRAG_STYLE = {
  operator: {
    display: "flex",
    paddingLeft: -12
  },
  formula: {
    display: "flex",
    overflow: "auto",
    background: "#fafafb",
    borderRadius: "6px",
    padding: 20,
    paddingLeft: 8,
    minHeight: 147
  },
  dragItem: {
    background: "#FFFFFF",
    borderRadius: "6px",
    fontWeight: 400,
    fontSize: 16,
    height: 46,
    margin: "0 12px",
    minWidth: 46,
    lineHeight: "46px",
    textAlign: "center" as "center",
    userSelect: "none" as "none",
    whiteSpace: "nowrap" as "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "0 14px"
  }
};

export const NUMBER_REGEX = /^[0-9]\d*$/;

export const OPERATOR = [
  {
    id: "1",
    content: "+"
  },
  {
    id: "2",
    content: "-"
  },
  {
    id: "3",
    content: "*"
  },
  {
    id: "4",
    content: "/"
  },
  {
    id: "5",
    content: "("
  },
  {
    id: "6",
    content: ")"
  }
];
