import i18n from "../../../../i18n";
import { formatShowDecimalNumber } from "../../common/utils/helper";
import { STATUS } from "./constant";
import { CheckEnum, GoalTypeEnum, ResultApproveListName, SubjectEnum } from "./enum";
import {
  ConfigFilter,
  FilterObjectParam,
  FilterEmployeeParam,
  ResultApproveData,
  OptionSelect,
  TreeData,
  UserInfo,
  WorkspaceDetail,
} from "./interface";

export const downloadFile = (response: any): void => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const fileName = getFileNameInResponseHeader(response.headers);
  const link = document.createElement("a");
  link.target = "_blank";
  link.download = fileName;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

const getFileNameInResponseHeader = (headers: any): any => {
  let fileName = "";
  if (!headers) return "";
  if ("content-disposition" in headers) {
    const data = headers["content-disposition"]
      .split(";")
      .map((item: string) => item.trim());

    for (let item of data) {
      const disposition = item.split("=");
      if (disposition.length > 0) {
        if (disposition[0] === "filename") return disposition[1];
      }
    }
  }
  return fileName;
};

export const formatText = (_val: string): string => {
  if (_val.length > 20) {
    return _val.substring(0, 20) + "...";
  }
  return _val;
};

const renderIcon = () => (
  <div className="ant-checkbox-wrapper" style={{ marginRight: 10 }}>
    <span className="ant-checkbox ant-checkbox-checked">
      <span className="ant-checkbox-inner"></span>
    </span>
    <span className="ant-checkbox">
      <span className="ant-checkbox-inner"></span>
    </span>
  </div>
);

export const mapTreeData = (
  treeData: any,
  parentIds: number[],
  childrenIds: number[],
  currentIds: number[]
): any => {
  const ids = [...parentIds, ...childrenIds, ...currentIds];
  const item = treeData.filter((_item: any) => {
    return ids.includes(_item.id);
  });

  return item.map((_item: any) => {
    if (_item?.children?.length > 0) {
      return {
        title: _item.name,
        value: _item.id,
        key: _item.id,
        disabled: ![...childrenIds, ...currentIds].includes(_item.id),
        status: 0,
        children: mapTreeData(_item.children, parentIds, childrenIds, currentIds),
      };
    } else {
      return {
        title: _item.name,
        value: _item.id,
        key: _item.id,
        disabled: ![...childrenIds, ...currentIds].includes(_item.id),
        status: 0,
        children: [],
      };
    }
  });
};

export const mapEmployeeData = (data: UserInfo[]): OptionSelect[] => {
  return data.map((_user) => ({
    label: _user?.full_name || "-",
    value: _user?.id,
  }));
};

export const mappingObjDataTable = (data: any): ResultApproveData[] => {
  return data.map((item: any) => {
    const performance =
      item.performance &&
      Array.isArray(item.performance) &&
      item.performance.length &&
      item.performance[0];
    const result = performance?.original_value
      ? formatShowDecimalNumber(performance?.original_value)
      : "";
    const resultColor = performance?.original_color
      ? performance?.original_color.color
      : "";
    const resultAdjustment = performance?.accepted_value
      ? formatShowDecimalNumber(performance?.accepted_value)
      : "";
    const resultAdjustmentColor = performance?.accepted_color
      ? performance?.accepted_color.color
      : "";
    const resultAdjustmentColorID = performance?.accepted_color
      ? performance?.accepted_color.id
      : "";
    const month = performance?.month.toString() || "";
    const year = performance?.year.toString() || "";
    const time = month && year ? month + "/" + year : "";
    const note = performance?.note || "";
    const progress = performance?.progress || "";
    return {
      key: performance.id,
      code: item.code,
      name: item.name,
      originResult: performance?.original_value,
      originResultAdjustment: performance?.accepted_value,
      result,
      resultAdjustment,
      month,
      year,
      time,
      note,
      resultColor,
      resultAdjustmentColor,
      progress,
      colorID: resultAdjustmentColorID,
      isEditing: false,
      id: item.id,
      typingNote: false,
    };
  });
};

export const mappingEmployeeDataTable = (data: any): ResultApproveData[] => {
  return data.map((item: any) => {
    const performance =
      item.performances &&
      Array.isArray(item.performances) &&
      item.performances.length &&
      item.performances[0];
    const result = performance?.original_value
      ? formatShowDecimalNumber(performance?.original_value)
      : "";
    const resultColor = performance?.original_color
      ? performance?.original_color.color
      : "";
    const resultAdjustment = performance?.accepted_value
      ? formatShowDecimalNumber(performance?.accepted_value)
      : "";
    const resultAdjustmentColor = performance?.accepted_color
      ? performance?.accepted_color.color
      : "";
    const resultAdjustmentColorID = performance?.accepted_color
      ? performance?.accepted_color.id
      : "";
    const month = performance?.month.toString() || "";
    const year = performance?.year.toString() || "";
    const time = month && year ? month + "/" + year : "";
    const note = performance?.note || "";
    const progress = performance?.progress || "";
    return {
      key: performance.id,
      code: item.employee_code,
      name: item.name || item.user_name,
      originResult: performance?.original_value,
      originResultAdjustment: performance?.accepted_value,
      result,
      resultAdjustment,
      month,
      year,
      time,
      note,
      resultColor,
      resultAdjustmentColor,
      progress,
      colorID: resultAdjustmentColorID,
      isEditing: false,
      id: item.id,
      typingNote: false,
    };
  });
};

export const getParentIds = (target: any, children: any, ancestors = []): any => {
  for (let node of children) {
    if (node.id === target) {
      return ancestors;
    }
    const found = getParentIds(target, node.children, ancestors.concat(node.id));
    if (found) {
      return found;
    }
  }
  return undefined;
};

function getChildIdsFromNode(node: any, result: any[] = []): any {
  if (!node) return [];
  if (!Array.isArray(node.children)) return [];

  for (let entry of node.children) {
    result.push(entry.id);
    getChildIdsFromNode(entry, result);
  }
  return result;
}

function findNode(data: any, id: any): any {
  if (!Array.isArray(data)) return;

  for (let entry of data) {
    if (entry.id === id) {
      return entry;
    } else {
      const node = findNode(entry.children, id);
      if (node) {
        return node;
      }
    }
  }
}

export const getChildIds = (id: number, data: any[]): number[] =>
  getChildIdsFromNode(findNode(data, id));

export const mapDataFilter = (
  values: ConfigFilter,
  listEmployee: OptionSelect[],
  listName: string
): FilterObjectParam | FilterEmployeeParam => {
  const user_ids = values.employeeId?.includes(CheckEnum.All)
    ? listEmployee?.map((_employee) => _employee.value)?.join(",")
    : values.employeeId?.join(",");

  const workspace_ids =
    typeof values.subjectId?.[0] === "number"
      ? values.subjectId?.join(",")
      : values.subjectId?.map((item: any) => item.value)?.join(",");

  if (listName === ResultApproveListName.Object) {
    return {
      ids: workspace_ids,
      user_ids,
      month: values.timeMonth,
      year: values.timeYear,
      type: values.goalType,
    };
  }
  return {
    workspace_ids,
    user_ids,
    month: values.timeMonth,
    year: values.timeYear,
    type: values.goalType,
  };
};
