import { TRUE } from 'sass';
import { KpiSlice } from './interface';

export const PARAM_HEADER = ['index', 'name', 'proportion'];
export enum STATUS {
  Active = 1,
  Inactive,
}

export enum ACTION {
  Delete = 'delete',
  Edit = 'edit',
  View = 'view',
  Create = 'create',
}

export enum TOAST_TYPE {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
}

export enum KPI_STATUS {
  New = 1,
  Reviewed,
  HDApproved,
  PMSApproved,
  Caculated,
}

export enum KPI_ROLE {
  Admin = 'Admin',
  CEO = 'CEO',
  HOD = 'HOD',
  PMS = 'PMS',
  Teamlead = 'Teamlead',
  Staff = 'Staff',
  MD = 'MD',
  HD = 'HD',
}

export const PER_PAGE = 20;
export const DEFAULT_PAGE = 1;
export const DEFAULT_PARAMS = { per_page: PER_PAGE, page: DEFAULT_PAGE };
export const MONTH: string[] = [
  ...Array.from({ length: 12 }, (_, i) => ('0' + (i + 1)).slice(-2)),
];
const maxYear: number = new Date().getFullYear() - 1 + 30;
export const YEAR: number[] = [
  ...Array.from({ length: 31 }, (_, i) => maxYear - 30 + i),
];

export const initialState: KpiSlice = {
  list: [],
  paginate: {
    total: 0,
    current_page: 0,
    last_page: 0,
  },
  loading: {
    loadMore: false,
    list: false,
    kpiCreateData: false,
    kpiDetailData: false,
  },
  status: 'idle',
  showModal: { addKpi: false, employeeList: false },
  filterParams: { ...DEFAULT_PARAMS },
  endpointSource: [],
  resourceCode: '',
  action: { id: null, action: ACTION.Create },
  param: { status: STATUS.Active },
  resetFormFilter: false,
  departments: [],
  divisions: [],
  userDivisions: [],
  employees: [],
  goals: [],
  detailKpiData: { list: [], tracking: [] },
  createKpiData: { list: [], tracking: [] },
};

export const NUMBER_REGEX = /^[0-9]\d*$/;
export const NUMBER_DECIMAL_REGEX = /^(0|[1-9]\d*)(\.\d{1,2})?$/;
