import styles from './styles.module.scss';
import NoPermissionImg from '../../../../assets/img/no-permission.svg';
import { useTranslation } from 'react-i18next';
const NoPermission = () => {
  const { t } = useTranslation('gperformance', { keyPrefix: 'noPermission' });
  return (
    <>
      <div className={styles.content}>
        <img src={NoPermissionImg} />
        <p>{t('youDoNotHavePermissionToAccessThisFunction')}</p>
      </div>
    </>
  );
};

export default NoPermission;
