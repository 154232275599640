const dataValidateVotes = [
  {
    id: 1,
    name: "name",
    message: "",
    status: false,
  },
  {
    id: 2,
    name: "process_id",
    message: "",
    status: false,
  },
  {
    id: 3,
    name: "department_id",
    message: "",
    status: false,
  },
  {
    id: 4,
    name: "priority",
    message: "",
    status: false,
  },
  {
    id: 5,
    name: "objectPerfrom",
    message: "",
    status: false,
  },
  {
    id: 6,
    name: "objectInfo",
    message: "",
    status: false,
  },
];

const DEFAULT_BUTTON = {
  SUCCESS: "success",
  CANCEL: "notApproved",
  WAITING: "waiting",
  REFUSE: "refuse",
  SKIP: "skip",
  RUNNING: "running",
};

const TASK_ACTIONS = {
  END_PROCESS: 1,
  APPROVE: 3,
  FORWARD: 2,
  UN_APPROVE: 4,
};

const TAB_LAYOUT = {
  BOX: "box",
  ROW: "row",
};

export { dataValidateVotes, DEFAULT_BUTTON, TAB_LAYOUT, TASK_ACTIONS };
