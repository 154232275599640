import React from "react";

const IconDocsT = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13216_24178)">
        <path
          d="M6.375 15.125H3.875C2.77043 15.125 1.875 14.2296 1.875 13.125V3.625C1.875 2.52043 2.77043 1.625 3.875 1.625H11.125C12.2296 1.625 13.125 2.52043 13.125 3.625V5.375"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.875 8.375H11.375C10.2704 8.375 9.375 9.27043 9.375 10.375V16.125C9.375 17.2296 10.2704 18.125 11.375 18.125H14.875C15.9796 18.125 16.875 17.2296 16.875 16.125V10.375C16.875 9.27043 15.9796 8.375 14.875 8.375Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_13216_24178">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconDocsT;
