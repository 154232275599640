import { WsCode } from '../../api';
import { ImportPayload } from '../utils';
import { RequestApiPrivate } from './../../api/axios';

const ResultPerformanceApi = {
  list: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.post(
        WsCode.resultManagement.list,
        params
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  listFilterResult: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.resultManagement.list,
        {
          params
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  listEmployee: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.resultManagement.employees,
        {
          params
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  listWorkspace: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.resultManagement.workspaces,
        {
          params
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  listPerformances: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.resultManagement.performances,
        {
          params
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  listGoalAssignee: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.resultManagement.goalAssignee,
        {
          params
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  listResult: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.resultManagement.results,
        {
          params
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  add: async (payload: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.post(
        WsCode.resultManagement.addResult,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  detail: async (id: number): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.resultManagement.detail + `/${id}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  update: async (id: number, payload: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.put(
        WsCode.resultManagement.edit + `/${id}`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  delete: async (id: number): Promise<any> => {
    try {
      const response = await RequestApiPrivate.delete(
        WsCode.resultManagement.delete + `/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  downloadTemplate: async (): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.resultManagement.downloadTemplate,

        { responseType: 'blob' }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  exportExcel: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.post(
        WsCode.resultManagement.exportExcel,
        params,
        { responseType: 'blob' }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  upload: async (payload: FormData): Promise<any> => {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    };
    try {
      const response = await RequestApiPrivate.post(
        WsCode.resultManagement.upload,
        payload,
        config
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  importResult: async (payload: ImportPayload): Promise<any> => {
    try {
      const response = await RequestApiPrivate.post(
        WsCode.resultManagement.importResult,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  calculation: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.post(
        WsCode.resultManagement.calculation,
        params
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};

export default ResultPerformanceApi;
