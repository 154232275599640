// @ts-nocheck
import _ from "lodash";
export const handleCollapseChildren = (children: any[] = []): any[] => {
  return (
    children.map((it: any) => ({
      ...it,
      isAutoCollapse: true,
      children: handleCollapseChildren(it.children),
    })) || []
  );
};

export const handleClearAnotherChecked = (
  lastCheckedData: any,
  treeData: any,
  reRender: any
) => {
  if (lastCheckedData.treeData?.id === treeData?.id) return;
  if (lastCheckedData && lastCheckedData.treeData) {
    const updateUI = lastCheckedData.reRender;
    lastCheckedData.treeData.checked = false;
    updateUI?.({});
  }

  // Update last checked
  lastCheckedData.reRender = reRender;
  lastCheckedData.treeData = treeData;
};

export const handleUncheckParent = (
  listChecked: any[],
  fullWorkspaceList: any[] = []
) => {
  if (!Array.isArray(listChecked)) return [];
  const result = listChecked
    .map((it: any) => {
      const item = fullWorkspaceList.find((item: any) => it.id === item.id);
      return !checkHaveChildren(it) ||
        checkChildrenIsCheckFull(item.children, listChecked)
        ? it
        : undefined;
    })
    ?.filter((it: any) => !!it);

  return result || [];
};

export const getFullWorkspaceList = (data: any) => {
  let result = [{ ...data }];
  if (checkHaveChildren(data)) {
    let dataChildren: any[] = [];
    data.children?.forEach((it: any) => {
      dataChildren = dataChildren.concat(getFullWorkspaceList(it));
    });
    result = result.concat(dataChildren);
  }
  return result;
};

export const checkHaveChildren = (data: any) => {
  return !!data?.children && data?.children?.length > 0;
};

export const handleSearchTreeItems = (
  data: any,
  keyword: string | undefined,
  currentChecked: any[],
  searchField: string = "label"
) => {
  let searchResult;
  const indexChecked = currentChecked?.findIndex(
    (it: any) => it?.value === data?.value && it?.value !== undefined
  );
  if (
    !keyword ||
    data[searchField]?.toString()?.toLowerCase().includes(keyword.toLowerCase())
  ) {
    searchResult = { ...data, checked: indexChecked >= 0 };
    delete searchResult.children;
  }
  if (checkHaveChildren(data)) {
    const childrenDataSearch = data.children?.map((it: any) =>
      handleSearchTreeItems(it, keyword, currentChecked, searchField)
    );
    if (childrenDataSearch?.some((it: any) => !!it))
      searchResult = {
        ...data,
        checked: indexChecked >= 0,
        children: childrenDataSearch.filter((it: any) => !!it),
      };
  }
  return searchResult;
};

export const handleSearchTreeItemsShowChildren = (
  data: any,
  keyword: string | undefined,
  currentChecked: any[],
  searchField: string = "label",
  isBypassSearch: boolean = false
) => {
  let searchResult;
  const indexChecked = currentChecked?.findIndex(
    (it: any) => it?.value === data?.value && it?.value !== undefined
  );
  const searchCondition =
    !keyword ||
    data[searchField]
      ?.toString()
      ?.toLowerCase()
      .includes(keyword.toLowerCase());
  if (searchCondition || isBypassSearch) {
    searchResult = { ...data, checked: indexChecked >= 0 };
    delete searchResult.children;
  }
  if (checkHaveChildren(data)) {
    const childrenDataSearch = data.children?.map((it: any) =>
      handleSearchTreeItemsShowChildren(
        it,
        keyword,
        currentChecked,
        searchField,
        searchCondition
      )
    );
    if (childrenDataSearch?.some((it: any) => !!it))
      searchResult = {
        ...data,
        checked: indexChecked >= 0,
        children: childrenDataSearch.filter((it: any) => !!it),
      };
  }
  return searchResult;
};

export const handleSearchTreeItemsShowFullChildren = (
  data: any,
  keyword: string | undefined,
  currentChecked: any[],
  searchField: string = "label",
) => {
  let searchResult;
  const indexChecked = currentChecked?.findIndex(
    (it: any) => it?.value === data?.value && it?.value !== undefined
  );
  const searchCondition =
    !keyword ||
    data[searchField]
      ?.toString()
      ?.toLowerCase()
      .includes(keyword.toLowerCase());
  if (searchCondition) {
    return searchResult = { ...data, checked: indexChecked >= 0 };
  }

  if (checkHaveChildren(data)) {
    const childrenDataSearch = data.children?.map((it: any) =>
    handleSearchTreeItemsShowFullChildren(
        it,
        keyword,
        currentChecked,
        searchField,
      )
    );
    if (childrenDataSearch?.some((it: any) => !!it))
      searchResult = {
        ...data,
        checked: indexChecked >= 0,
        children: childrenDataSearch.filter((it: any) => !!it),
      };
  }
  return searchResult;
};

export const handleChangeMultiSelectTree = (
  data: any,
  value: any,
  currentValues: any[]
) => {
  const itemIndex = currentValues.findIndex((it) => it.value === data.value);
  if (value && itemIndex === -1) {
    currentValues.push({ ...data });
  }
  if (!value && itemIndex >= 0) {
    currentValues.splice(itemIndex, 1);
  }
};

export const checkChildrenIsCheckFull = (
  children: any[],
  listSelected: any[]
) => {
  if (!children) return true;
  return children.every((it: any) => {
    const condition =
      listSelected?.findIndex((itt: any) => itt.value === it.value) >= 0;
    return condition;
  });
};

export const handleCheckAllChildren = (
  data: any,
  value: boolean,
  multiCheckedValues: any[]
) => {
  // Update data for render UI
  data.checked = value;
  if (!!data.children && data.children?.length > 0) {
    const children = data.children?.map((it: any) => ({
      ...handleCheckAllChildren(it, value, multiCheckedValues),
    }));
    data.children = children;
  }

  // Update checked values
  handleChangeMultiSelectTree(data, value, multiCheckedValues);
  return data;
};

//active getActiveWorkspace flag
export const getChildrenData = (
  data: any[],
  combineSearchField: any = undefined,
  formatLabel: any = undefined,
  active?: boolean
) => {
  if (!data) return [];
  const blockInfo: any = data.map((it: any) => {
    if (active && it?.status !== 1) return undefined;
    let arr =
      getChildrenData(it.children, combineSearchField, formatLabel, active) ??
      [];

    return it.id !== undefined
      ? {
          ...it,
          value: it.id,
          label: formatLabel ? formatLabel?.(it) : it.name,
          searchField: combineSearchField?.(it),
          children: arr.filter((x: any) => x !== undefined),
        }
      : undefined;
  });

  return blockInfo.filter((x: any) => x !== undefined);
};

// Get workspace tree active to dropdown
export const getChildrenDataActive = (data: any[]) => {
  if (!data) return [];
  const blockInfo: any = data.map((it: any) => {
    if (it?.status !== 1) return undefined;
    let arr = getChildrenDataActive(it.children) ?? [];
    return it.id !== undefined
      ? {
          ...it,
          value: it.id,
          label: it.name,
          searchField: undefined,
          children: arr.filter((x: any) => x !== undefined),
        }
      : undefined;
  });
  return blockInfo.filter((x: any) => x !== undefined);
};

//active getActiveWorkspace flag
export const getChildrenDataCloneWithRegions = (
  data: any[],
  combineSearchField: any = undefined,
  formatLabel: any = undefined,
  active?: boolean,
  regions: any[] = []
) => {
  if (!data) return [];

  // 2-CORP 3-MB  4-MN
  const LV2_WORKSPACE = [2, 3, 4];
  if (!regions.length) {
    regions = LV2_WORKSPACE;
  }
  const blockInfo: any = data.map((it: any) => {
    if (active && it?.status !== 1) return undefined;
    if (regions.includes(it.id)) {
      let arr =
        getChildrenData(it.children, combineSearchField, formatLabel, active) ??
        [];

      return it.id !== undefined
        ? {
            ...it,
            value: it.id,
            label: formatLabel ? formatLabel?.(it) : it.name,
            searchField: combineSearchField?.(it),
            children: arr.filter((x: any) => x !== undefined),
          }
        : undefined;
    } else {
      return undefined;
    }
  });

  return blockInfo.filter((x: any) => x !== undefined);
};

export const transformWorkspaceData = (value: any): any[] => {
  const result: any = {
    label: <b>Tập đoàn GGG</b>,
    value: "ggg",
    children: [],
  };
  if (!value) return [];
  const regionKey = Object.keys(value) || [];
  regionKey.forEach((key) => {
    result.children.push({
      ...value[key],
      label: value[key]?.name,
      value: value[key]?.id,
      children: getChildrenData(value[key]?.data),
    });
  });
  return result;
};

export const findChildrenIds = (tree: any, targetId: any) => {
  const childrenIds: any = [];

  function findNode(node: any, targetId: any) {
    if (node.id === targetId) {
      childrenIds.push(node?.id);
      if (node.children) {
        node.children.forEach((child: any) => {
          childrenIds.push(child.id);
          findNode(child, child.id);
        });
      }
    } else if (node.children) {
      node.children.forEach((child: any) => {
        findNode(child, targetId);
      });
    }
  }

  findNode(tree, targetId);
  return childrenIds;
};

export const getChildrenDataCategories = (
  data: any[],
  dataToFindChild: any[],
  dataNotToFindChild: any[]
) => {
  if (!data) return [];

  const filterTree = (node: any, picked?: any): any => {
    if (!node || node.status !== 1) return undefined;
    const children = node?.children || [];
    if (!children.filter((x: any) => x !== undefined).length) {
      if (
        picked ||
        dataNotToFindChild.includes(node?.id) ||
        dataToFindChild.includes(node?.id)
      ) {
        return {
          ...node,
          value: node.id,
          label: node.name,
          searchField: undefined,
          children: [],
        };
      } else {
        return undefined;
      }
    } else {
      const filteredChildrenPicked = children
        .map((child: any) => filterTree(child, true))
        .filter((x: any) => x !== undefined);

      const filteredChildren = children
        .map((child: any) => filterTree(child))
        .filter((x: any) => x !== undefined);

      const currentNode = {
        ...node,
        value: node.id,
        label: node.name,
        searchField: undefined,
        children: filteredChildren,
        disabled: false,
      };

      if (
        currentNode.children &&
        !picked &&
        !dataToFindChild.includes(node.id) &&
        !dataNotToFindChild.includes(node.id)
      ) {
        currentNode.disabled = true;
      }

      if (dataToFindChild.includes(node.id)) {
        return {
          ...currentNode,
          children: filteredChildrenPicked,
        };
      }

      if (dataNotToFindChild.includes(node.id)) {
        return currentNode;
      }

      return currentNode.children.length || dataNotToFindChild.includes(node.id)
        ? currentNode
        : undefined;
    }
  };

  const filteredData = data
    .map((item: any) => filterTree(item))
    .filter((x: any) => x !== undefined);
  return filteredData;
};

export const findNodesAndChildren = (
  tree: any[],
  array1: any[],
  array2: any[],
  resultArray: any[]
): any[] => {
  if (!tree || tree.length === 0) return resultArray;

  for (const node of tree) {
    if (array1.includes(node.id) || array2.includes(node.id)) {
      resultArray.push(node);
      if (array2.includes(node.id) && node.children) {
        for (const child of node.children) {
          resultArray.push(child);
          findNodesAndChildren([child], array1, array2, resultArray);
        }
      }
    }

    if (node.children) {
      findNodesAndChildren(node.children, array1, array2, resultArray);
    }
  }

  return resultArray;
};

export function findNodeById(root, targetId) {
  try {
    if (!root) {
      return null;
    }

    if (root?.id === targetId) {
      return root;
    }
    if (root?.children) {
      for (let child of root?.children) {
        const foundNode = findNodeById(child, targetId);
        if (foundNode) {
          return foundNode;
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
  return null;
}

// export const transformWorkspaceDataV2 = (data: any): any[] => {
//   // return (
//   //   {...data, children: }
//   // );
// };
