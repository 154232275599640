import { Modal, notification } from "antd";
import {
  ErrorWaring,
  IconErrorSnackbar,
  IconNofiStep,
  IconSuccessSnackbar,
} from "../../../../components/icons";
import { TOAST_TYPE } from ".";
import { LoadingRing } from "../../../../components/UI";

const { confirm } = Modal;

export const openNotification = ({
  message,
  type,
}: {
  message?: string;
  type: string;
}) => {
  const iconType = () => {
    if (type === TOAST_TYPE.Success) return <IconSuccessSnackbar />;
    if (type === TOAST_TYPE.Error) return <IconErrorSnackbar />;
    return <ErrorWaring />;
  };

  const icon = iconType();

  return notification.open({
    message,
    icon,
    placement: "bottomRight",
    className: "kpi-wrapper",
    bottom: 10,
    duration: 5,
    closeIcon: null,
  });
};

export const showConfirm = ({
  message,
  callBack,
  okText,
  cancelText,
}: {
  message: string;
  okText: string;
  cancelText: string;
  callBack: () => void;
}) => {
  return confirm({
    content: (
      <>
        <IconNofiStep />
        <p>{message}</p>
      </>
    ),
    className: "kpi-wrapper",
    centered: true,
    icon: null,
    cancelText,
    okText,
    width: 500,
    onOk() {
      callBack();
    },
  });
};

export const LoadingSSC = ({ size = "large" }: { size?: "sm" | "large" }) =>
  size === "sm" ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <LoadingRing size="sm" />
      <span style={{ fontSize: 14 }}>Đang tải dữ liệu</span>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <LoadingRing size="md" />
      <h4>Đang tải dữ liệu</h4>
    </div>
  );
