import { RequestApiPrivate, WsCode } from '../../api';
import { FilterParam, RunBackPayload } from '../utils';

const KpiSyncHistoryApi = {
  list: async (params: FilterParam): Promise<any> => {
    try {
      const response = await RequestApiPrivate.post(WsCode.syncHistory.list, 
        params
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  export: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(WsCode.syncHistory.export, {
        params,
        responseType: 'blob'
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  listEmployee: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.syncHistory.employees,
        {
          params
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  runBack: async (payload: RunBackPayload): Promise<any> => {
    try {
      const response = await RequestApiPrivate.post(
        WsCode.syncHistory.runBack,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  downloadExcel: async (id: number): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.syncHistory.downloadExcel + `/${id}`,
        { responseType: 'blob' }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
};

export default KpiSyncHistoryApi;
