import { axiosMediaPrivate } from "./axios";
import { UploadPublicType } from "../types/types";

export const MediaApi = {
  download: async function (fileId: number): Promise<any> {
    try {
      const res = await axiosMediaPrivate.get(`/files/download/${fileId}`, {
        responseType: "blob",
      });
      return res;
    } catch (error) {
      throw error;
    }
  },

  upload: async function (file: any): Promise<any> {
    try {
      const res = await axiosMediaPrivate.post(`/files`, file, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res;
    } catch (error) {
      throw error;
    }
  },

  uploadPublic: async function (payload: UploadPublicType): Promise<any> {
    try {
      const res = await axiosMediaPrivate.post(`files/public`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res;
    } catch (error) {
      throw error;
    }
  },


  delete: async function (ids: any): Promise<any> {
    try {
      const res = await axiosMediaPrivate.delete(`/files`, { data: ids });
      return res;
    } catch (error) {
      throw error;
    }
  },

  downloadChecklistTemplate: async function (fileName: string): Promise<any> {
    try {
      const res = await axiosMediaPrivate.get(
        `files/download-template?filename=${fileName}`,
        {
          responseType: "blob",
        }
      );
      return res;
    } catch (error) {
      throw error;
    }
  },
  downloadTemplateFile: async function (fileName: string): Promise<any> {
    try {
      const res = await axiosMediaPrivate.get(
        `files/download-template?filename=${fileName}`,
        {
          responseType: "blob",
        }
      );
      return res;
    } catch (error) {
      throw error;
    }
  },
  downloadFileErrorImportOkr: async function (fileId: string): Promise<any> {
    try {
      const res = await axiosMediaPrivate.get(`files/download/${fileId}`, {
        responseType: "blob",
      });
      return res;
    } catch (error) {
      throw error;
    }
  },
};

export function downloadFile(response: any): void {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const fileName = getFileNameInResponseHeader(response.headers);
  const link = document.createElement("a");
  link.target = "_blank";
  link.download = fileName;
  link.href = url;
  document.body.appendChild(link);
  link.click();
}

export function getFileNameInResponseHeader(headers: any): string {
  let fileName = "";
  if (!headers) return "";
  if ("content-disposition" in headers) {
    const data = headers["content-disposition"]
      .split(";")
      .map((item: string) => item.trim());

    for (let item of data) {
      const disposition = item.split("=");
      if (disposition.length > 0) {
        if (disposition[0] === "filename") return disposition[1];
      }
    }
  }
  return fileName;
}
