import { ACTION_TYPE_TASK } from "../../../../helpers/constant";
import i18n from "../../../../i18n";

//mã code khi gọi api trả về respone là thành công
const SUCCESS = 200;
const NOT_FOUND = 404;

//Mã lỗi trùng tên viết tắt trong quy trình
const ERROR_SHORT_NAME_SAME = 400;
//mã lỗi đã tồn tại version
const ERROR_VERSION_SAME = 403;

const TARGETOBJECT = [
  {
    id: 1,
    name: i18n.t("gprocess:constants.allSystem"),
  },
  {
    id: 2,
    name: i18n.t("gprocess:constants.internal"),
  },
];

const SLA_UNIT = {
  DAY: 2,
  WORKING_DAY: 3,
};

//list trạng thái của quy trình
//list khi tạo
const STATUSPROCESS = [
  {
    id: 1,
    name: i18n.t("gprocess:constants.draff"),
  },
  {
    id: 2,
    name: i18n.t("gprocess:constants.waitingForApproval"),
  },
  {
    id: 3,
    name: i18n.t("gprocess:constants.activated"),
  },
  {
    id: 5,
    name: i18n.t("gprocess:constants.expire"),
  },
];

const STATUS_PROCESS_SELECT_COMPONENT = () => [
  {
    value: "",
    label: i18n.t("gprocess:constants.all"),
  },
  {
    value: 1,
    label: i18n.t("gprocess:constants.draff"),
  },
  {
    value: 2,
    label: i18n.t("gprocess:constants.waitingForApproval"),
  },
  {
    value: 3,
    label: i18n.t("gprocess:constants.activated"),
  },
  {
    value: 5,
    label: i18n.t("gprocess:constants.expire"),
  },
];

//list trạng của quy trình
const STATUSPROCESSSEARCH = [
  {
    id: "",
    name: i18n.t("gprocess:constants.all"),
  },
  {
    id: 2,
    name: i18n.t("gprocess:constants.waitingForApproval"),
  },
  {
    id: 3,
    name: i18n.t("gprocess:constants.activated"),
  },
  {
    id: 5,
    name: i18n.t("gprocess:constants.expire"),
  },
];

const STATUS_PROCESS_SEARCH_SELECT_COMPONENT = [
  {
    value: "",
    label: i18n.t("gprocess:constants.all"),
  },
  {
    value: 2,
    label: i18n.t("gprocess:constants.waitingForApproval"),
  },
  {
    value: 3,
    label: i18n.t("gprocess:constants.activated"),
  },
  {
    value: 5,
    label: i18n.t("gprocess:constants.expire"),
  },
];

//list trạng thái quy trình của  phiếu
const STATUS_PROCESS_VOTE = [
  {
    id: "",
    name: i18n.t("gprocess:constants.all"),
  },
  {
    id: 3,
    name: i18n.t("gprocess:constants.activated"),
  },
  {
    id: 5,
    name: i18n.t("gprocess:constants.expire"),
  },
];

const STATUS_PROCESS_VOTE_SELECT_COMPONENT = () => [
  {
    value: 0,
    label: i18n.t("gprocess:constants.all"),
  },
  {
    label: i18n.t("gprocess:constants.activated"),
    value: 3,
  },
  {
    label: i18n.t("gprocess:constants.expire"),
    value: 5,
  },
];

//Trạng thái của quy trình
const KEY_STATUS_PROCESS: { [key: string]: number } = {
  DRAFF: 1,
  WAITING: 2,
  ACTIVED: 3,
  EXSIT: 4,
  EXPRIE: 5,
  DEFAULT: 0,
};

const KEY_STATUS_TICKET: { [key: string]: number } = {
  DRAFF: 0,
  INPROCESS: 1,
  COMPLETED: 2,
  UN_APPROVAL: 3,
};

//giá trị của quy trình
//data mặc định khi tạo
const DATACREATEPROCESS = {
  code: Math.random(),
  name: null,
  short_name: null,
  owner_workspace_id: null,
  target_apply_id: undefined,
  regulation_document: null,
  regulation_start_date: null,
  regulation_end_date: null,
  description: null,
  file_attachment_ids: [],
  process_steps: null,
};

// giá trị của một bước quy trình
const ITEMSTEPS = {
  name: "",
  action_type: [ACTION_TYPE_TASK.APPROVE],
  step_type: 1,
  step_order: 1,
  child_process_id: null,
  sla_quantity: 1,
  sla_unit: SLA_UNIT.WORKING_DAY,
  transfer_condition_type: 2,
  timesheet: 1,
  object: [],
  objectRection: [],
};

//List các bước quy trình
const DATASTEPSLIST = [
  {
    name: "",
    action_type: [ACTION_TYPE_TASK.OFFER],
    step_type: 1,
    step_order: 1,
    sla_unit: SLA_UNIT.WORKING_DAY,
    timesheet: 0,
    transfer_condition_type: 2,
    object: [],
    objectRection: [],
  },
  {
    name: "",
    action_type: [ACTION_TYPE_TASK.APPROVE],
    step_type: 1,
    step_order: 2,
    sla_unit: SLA_UNIT.WORKING_DAY,
    timesheet: 1,
    transfer_condition_type: 2,
    object: [],
    objectRection: [],
  },
  {
    name: "",
    action_type: [ACTION_TYPE_TASK.END],
    step_type: 1,
    step_order: 3,
    sla_unit: SLA_UNIT.WORKING_DAY,
    timesheet: 1,
    transfer_condition_type: 2,
    object: [],
    objectRection: [],
  },
];

export const ACTION_TYPE = {
  TRANFER: 2,
  APPROVE: 3,
};

const TYPE_ACTION = () => [
  {
    id: ACTION_TYPE.TRANFER,
    name: i18n.t("gprocess:constants.transfer"),
  },
  {
    id: ACTION_TYPE.APPROVE,
    name: i18n.t("gprocess:constants.aprrove"),
  },
];

const DATAOBJECTKEY = [
  {
    id: 1,
    name: i18n.t("gprocess:constants.title"),
  },
  {
    id: 2,
    name: i18n.t("gprocess:constants.userGroup"),
  },
  {
    id: 3,
    name: i18n.t("gprocess:constants.department"),
  },
  {
    id: 4,
    name: i18n.t("gprocess:constants.titleAndDepartment"),
  },
  {
    id: 5,
    name: i18n.t("gprocess:constants.user"),
  },
];

//List trạng thái phiếu
const STATUS_TICKET = [
  {
    id: "",
    name: i18n.t("gprocess:constants.all"),
  },
  {
    id: 0,
    name: i18n.t("gprocess:constants.draff"),
  },
  {
    id: 1,
    name: i18n.t("gprocess:constants.inProgress"),
  },
  {
    id: 2,
    name: i18n.t("gprocess:constants.complete"),
  },
  {
    id: 3,
    name: i18n.t("gprocess:constants.unAprrove"),
  },
];

const STATUS_TICKET_SELECT_COMPONENT = () => [
  {
    value: "",
    label: i18n.t("gprocess:constants.all"),
  },
  {
    value: 0,
    label: i18n.t("gprocess:constants.draff"),
  },
  {
    value: 1,
    label: i18n.t("gprocess:constants.inProgress"),
  },
  {
    value: 2,
    label: i18n.t("gprocess:constants.complete"),
  },
  {
    value: 3,
    label: i18n.t("gprocess:constants.unAprrove"),
  },
];

const DataObjectApplyType = () => [
  {
    key: 1,
    val: i18n.t("gprocess:constants.entireSystem"),
  },
  {
    key: 2,
    val: i18n.t("gprocess:constants.internal"),
  },
];

const USER_OPTIONS_PER_PAGE = 20;
const APPROVE_ACTIVE = 1;

const initialState = {
  name_process_or_ticket: "",
  name_or_code_process: "",
  code_ticket: "",
  version: "",
  dateRange: {
    start: null,
    end: null,
  },
  approval_status_process: STATUS_PROCESS_VOTE_SELECT_COMPONENT()[0],
  approval_status_ticket: STATUS_TICKET_SELECT_COMPONENT()[0],
};

const TASK_STATUS_G_PROCESS = {
  DRAFT: 0,
  IN_PROGRESS: 1,
  DONE: 2,
  CLOSED: 3,
  CANCEL: 4,
};

const TASK_STATUS_SELECT = {
  ALL: 0,
  IN_PROGRESS: 1,
  DONE: 2,
  CANCEL: 4,
};
const SUBJECT_TYPES = {
  RSC: "rsc",
  OPS: "ops",
};

const STATUS_TASK_VOTE_SELECT_COMPONENT = () => [
  {
    value: TASK_STATUS_SELECT.ALL,
    label: i18n.t("gprocess:constants.all"),
  },
  {
    value: TASK_STATUS_SELECT.IN_PROGRESS,
    label: i18n.t("gprocess:constants.in_progress"),
  },
  {
    label: i18n.t("gprocess:constants.finish"),
    value: TASK_STATUS_SELECT.DONE,
  },
  {
    label: i18n.t("gprocess:constants.labelCancel"),
    value: TASK_STATUS_SELECT.CANCEL,
  },
];

export {
  initialState,
  SLA_UNIT,
  APPROVE_ACTIVE,
  TYPE_ACTION,
  TARGETOBJECT,
  DATACREATEPROCESS,
  SUCCESS,
  ITEMSTEPS,
  DATASTEPSLIST,
  STATUSPROCESS,
  KEY_STATUS_PROCESS,
  KEY_STATUS_TICKET,
  STATUSPROCESSSEARCH,
  DATAOBJECTKEY,
  USER_OPTIONS_PER_PAGE,
  STATUS_TICKET,
  STATUS_PROCESS_VOTE,
  DataObjectApplyType,
  ERROR_SHORT_NAME_SAME,
  ERROR_VERSION_SAME,
  STATUS_PROCESS_VOTE_SELECT_COMPONENT,
  STATUS_TASK_VOTE_SELECT_COMPONENT,
  STATUS_TICKET_SELECT_COMPONENT,
  STATUS_PROCESS_SELECT_COMPONENT,
  STATUS_PROCESS_SEARCH_SELECT_COMPONENT,
  TASK_STATUS_G_PROCESS,
  NOT_FOUND,
  SUBJECT_TYPES,
};
