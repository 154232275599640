import { createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { TypeSubtree } from "../../../../components/UI/TreeOKRs/type";
import i18n from "../../../../i18n";
import OkrApi from "../../api/okrApi";
import { rootObjectType } from "../../type/type";
const requestGetOKRs = async (params: {
  month: number;
  year: number;
  departments?: any;
  userEmail: string | number;
}) => {
  const response = await OkrApi.department.getOKRs(params);
  return response;
};

export const onGetOKRs = createAsyncThunk(
  "role/ceo/departments/getOKRs",
  async (params: {
    month: number;
    year: number;
    departments?: any;
    userEmail: string | number;
  }) => {
    try {
      const response = await requestGetOKRs(params);
      return response.data;
    } catch (error) {
      toast.error(i18n.t("layout:common.getUserError"));
      throw error;
    }
  }
);

export const onGetSubOKRs = createAsyncThunk(
  "role/ceo/department/getSubOKRs",
  async (params: {
    id: string | number;
    parentId: string | number;
    rootObject?: rootObjectType;
    level: number;
    subtree: TypeSubtree;
    auth_email: string;
    key_result_ids: number[];
    sub_project_ids: number[];
    task_ids: number[];
    time: {
      month: any;
      year: any;
    };
    uuid: string;
  }) => {
    try {
      const response = await OkrApi.getSubOKRs(params);
      return {
        response,
        level: params.level,
        parentId: params.parentId,
        parentUUID: params.uuid,
      };
    } catch (err) {
      return "close-loading";
    }
  }
);

export const onGetKRs = createAsyncThunk(
  "role/ceo/department/getKRs",
  async (params: {
    id: string | number;
    parentId: string | number;
    level: number;
    subtree: TypeSubtree;
    auth_email: string;
    key_result_ids: number[];
    rootObject?: rootObjectType;
    task_ids: number[];
    time: {
      month: any;
      year: any;
    };
    uuid: string;
  }) => {
    try {
      const response = await OkrApi.getKRs(params);
      return {
        response,
        level: params.level,
        parentId: params.parentId,
        parentUUID: params.uuid,
      };
    } catch (err) {
      return "close-loading";
    }
  }
);

export const onGetTasks = createAsyncThunk(
  "role/ceo/department/getTasks",
  async (params: {
    id: string | number;
    parentId: string | number;
    level: number;
    subtree: TypeSubtree;
    auth_email: string;
    task_ids: number[];
    time: {
      month: any;
      year: any;
    };
    uuid: string;
  }) => {
    try {
      const response = await OkrApi.getTasks(params);
      return {
        response,
        level: params.level,
        parentId: params.parentId,
        parentUUID: params.uuid,
      };
    } catch (err) {
      return "close-loading";
    }
  }
);
