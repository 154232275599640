import { useEffect } from "react";
import { matchRoutes } from "react-router-dom";
import { useTranslation } from "react-i18next";

const titles: any = {
  "/dashboard": "layout:sidebar.dashboard",
  "/okr/target-management": "layout:sidebar.myTarget",
  "/okr/my-task": "layout:sidebar.myTask",
  "/okr/main-user-evaluation": "layout:sidebar.mainUserEvaluation",
  "/g-process/dashboard": "layout:sidebar.dashboard",
  "/g-process/my-process": "layout:sidebar.myProcess",
  "/g-process/create-process": "gprocess:process-managements.createproceduce",
  "/g-process/details-process/:id":
    "gprocess:process-managements.processDetailt",
  "/g-process/copy-process/:id": "gprocess:process-managements.processCopy",
  "/g-process/version-process/:id":
    "gprocess:process-managements.createVersion",
  "/g-process/history-process/:id":
    "gprocess:process-managements.processHistory",
  "/g-process/process-sent-to-me": "layout:sidebar.processToMe",
  "/g-process/details-process-to-me":
    "gprocess:process-managements.processDetailt",
  "/g-process/process-list": "layout:sidebar.listProcess",
  "/g-process/create-votes": "gprocess:mission-management.create_coupons",
  "/g-process/detail-votes": "gprocess:mission-management.detail_vote",
  "/g-process/my-claim-form": "layout:sidebar.myRequestTicket",
  "/g-process/my-claim-form/history":
    "gprocess:process-managements.Tickethistory",
  "/g-process/my-mission": "layout:sidebar.myTask",
  "/g-process/my-mission/:id": "layout:sidebar.myTask",
  "/gticket/workgroup-management": "layout:sidebar.workgroupManagement",
  "/gticket/workgroup-detail/:id": "gticket:target.detailTaskGroup",
  "/gticket/work-list": "layout:sidebar.workList",
  "/gticket/work-list/detail": "gticket:target.detailTask",
  "/gticket/work-report": "layout:sidebar.taskReport",
  "/task-evaluation": "layout:sidebar.taskEvaluation",
  "/timesheet-management": "layout:sidebar.managementTimesheet",
  "/dashboard/tasks-g-ticket": "layout:pageTitle.taskList",
  "/dashboard/tasks-statistics/g-ticket": "layout:pageTitle.taskList",
  "/system-management/category-list": "layout:sidebar.categoryList",
  "/system-management/department-list": "layout:sidebar.departmentList",
  "/system-management/assignee": "layout:sidebar.assignee1",
  "/system-management/organization-structure/create":
    "layout:sidebar.addNewWorkspace",
  "/system-management/organization-structure/edit":
    "layout:sidebar.editWorkspace",
  "/system-management/organization-structure/detail":
    "layout:sidebar.viewWorkspace",
  "/system-management/organization-structure":
    "layout:sidebar.organizationalStructure",
  "/system-management/qticket": "layout:sidebar.qticket",
  "/system-management/qr-order": "layout:sidebar.qrOrderManagement",
  "/system-management/user-detail/:id/:mode?": "layout:sidebar.user-detail",
  // G-Performance
  "/g-performance/result-management":
    "layout:sidebar.gperformance.resultManagement",
  "/g-performance/performance-management":
    "layout:sidebar.gperformance.performanceManagement",
  "/g-performance/computing-management":
    "layout:sidebar.gperformance.computingManagement",
  "/g-performance/goal-group":
    "layout:sidebar.gperformance.goalGroupManagement",
  "/g-performance/goal": "layout:sidebar.gperformance.goalManagement",
  "/g-performance/param-management":
    "layout:sidebar.gperformance.paramManagement",
  "/g-performance/data-management":
    "layout:sidebar.gperformance.resourceManagement",
  "/g-performance/history-sync-data":
    "layout:sidebar.gperformance.historySyncData",
  "/g-performance/list-status": "layout:sidebar.gperformance.listStatus",
  "/g-performance/approve-performance":
    "layout:sidebar.gperformance.approveResultPerformance",
  "/g-performance/dashboard": "layout:sidebar.gperformance.dashboard",
  "/g-performance/dashboard/workspace-detail/:id":
    "layout:sidebar.gperformance.dashboard",
  "/g-performance/dashboard/user-detail/:id":
    "layout:sidebar.gperformance.dashboard",
  // End G-Performance
};

function usePageTitle({ pathname }: { pathname: string }) {
  const { t } = useTranslation();
  const routerProducer = (pathNames: any[] | undefined): any[] => {
    if (!pathNames) return [];
    return pathNames.map((el: any) => {
      return { path: el };
    });
  };

  const route = matchRoutes(
    routerProducer(Object.keys(titles)) as any,
    pathname,
  );

  let originalPathname = route?.[0]?.route?.path;
  const pageTitle =
    originalPathname && titles[originalPathname]
      ? titles[originalPathname]
      : "GGG";

  useEffect(() => {
    document.title = t(pageTitle);
  }, [t, pageTitle]);
}

export default usePageTitle;
