import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import App from "./App";
import store from "./store/store";
import "./index.scss";
import "./i18n";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </BrowserRouter>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

serviceWorkerRegistration.register({
    onUpdate: (registration) => {
        setTimeout(() => {
            console.log('force update');
            registration?.waiting?.postMessage({ type: 'SKIP_WAITING' });
            window?.location?.reload();
        }, 2000);
    },
});
