import cloneDeep from "lodash/cloneDeep";
import { FILTER_SETTINGS, ROLE_GROUP, ROLE_TYPE } from "../../constants";
import { TreeNodeDataType } from "../../interfaces";
import { renderIcon } from "../../helper/renderHelper";

/**
 *
 * @param object contains multiple variables that helps determinate filter configuration
 * @returns return config of filter that match with current display and role
 */
export const getFilterConfigSetting = ({
  target,
  type,
  group,
}: {
  target: number;
  type: number;
  group: string;
}) => {
  const filterConfigSetting = FILTER_SETTINGS.find(
    (config) =>
      config.display_target === target &&
      config.display_type === type &&
      config.role_group === ROLE_TYPE[group]
  );
  return filterConfigSetting;
};
/**
 *
 * @param id id of role
 * @returns role Name
 */
export const determinateRoleType = (id: number) => {
  let result: string = "";
  Object.entries(ROLE_GROUP).some(([key, value]) => {
    if (value.includes(id)) {
      result = key;
      return true;
    }
    return false;
  });
  return result;
};

/**
 *
 * @param workspace workspace tree
 * @param concurrentIds concurrently workspace ids
 * @param showIds workspace id need to already show
 * @param disabled disabled workspace or not
 */
export const getWorkspaceWithConcurrently = (
  workspace: TreeNodeDataType,
  concurrentIds: number[],
  showIds: number[],
  disabled: boolean | undefined = undefined
) => {
  if (!showIds.includes(workspace.id)) {
    return null;
  }
  const _workspaces = cloneDeep(workspace);
  let result: TreeNodeDataType = { ..._workspaces, children: [] };
  const isInclude = concurrentIds.includes(workspace.id);
  let _disabled: boolean | undefined = undefined,
    checkable: boolean = false;
  switch (true) {
    case isInclude || disabled === false:
      _disabled = false;
      checkable = true;
      break;

    case !isInclude:
      _disabled = true;
      break;

    default:
      break;
  }
  result = {
    ...result,

    icon: renderIcon(),

    disabled: _disabled,
    selectable: checkable,
  };

  if (_workspaces.children.length > 0) {
    _workspaces.children.forEach((item: TreeNodeDataType, index: number) => {
      const _data = getWorkspaceWithConcurrently(
        item,
        concurrentIds,
        showIds,
        _disabled
      );

      if (_data) {
        result.children.push(_data);
      }
    });
  }
  return result;
};

/**
 *
 * @param tree workspace tree
 * @param ids workspace need to create path
 * @param passed is parent workspace pass, if pass,
 */
export const findPathToTreeNode = (
  tree: any,
  ids: number[],
  passed = false
): { rootTree: number[]; dependenciesTree: number[] } => {
  let _passed = passed;
  let rootTree: number[] = [];
  let dependenciesTree: number[] = [];
  if (ids.includes(tree.id)) {
    _passed = true;
    rootTree.push(tree.id);
  }
  if (tree.children && tree.children.length > 0) {
    tree.children.forEach((item: any) => {
      const { rootTree: _rootTree, dependenciesTree: _dependenciesTree } =
        findPathToTreeNode(item, ids, _passed);
      rootTree = rootTree.concat(_rootTree);
      dependenciesTree = dependenciesTree.concat(_dependenciesTree);
    });
  }
  if (rootTree.length > 0) {
    rootTree.push(tree.id);
  }
  return {
    rootTree: [...new Set(rootTree)],
    dependenciesTree: [...new Set(dependenciesTree)],
  };
};

export const getUnitChildren = (workspaces: TreeNodeDataType[]) => {
  let result: { [key: number]: number[] } = {};
  let listChild: number[] = [];
  workspaces.forEach((workspace) => {
    result[workspace.id] = [];
    listChild.push(workspace.id);
    if (workspace.children && workspace.children.length > 0) {
      const data = getUnitChildren(workspace.children);
      listChild = listChild.concat(data.listChild);
      result[workspace.id] = data.listChild;
      result = { ...result, ...data.result };
    }
  });
  return { result, listChild };
};
export function isEmpty(obj: any) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}
