import { Form, Input, Modal, notification } from 'antd';
import {
  ErrorWaring,
  IconErrorSnackbar,
  IconSuccessSnackbar,
} from '../../../../components/icons';
import {
  EditableCellProps,
  KPI_STATUS,
  QuestionIcon,
  ThreeDot,
  TOAST_TYPE,
} from '.';
import { LoadingRing } from '../../../../components/UI';

const { confirm } = Modal;

export const openNotification = ({
  message,
  type,
}: {
  message?: string;
  type: string;
}) => {
  const iconType = () => {
    if (type === TOAST_TYPE.Success) return <IconSuccessSnackbar />;
    if (type === TOAST_TYPE.Error) return <IconErrorSnackbar />;
    return <ErrorWaring />;
  };

  const icon = iconType();

  return notification.open({
    message,
    icon,
    placement: 'bottomRight',
    className: 'kpi-wrapper',
    bottom: 10,
    duration: 5,
    closeIcon: null,
  });
};

export const showConfirm = ({
  message,
  callBack,
  okText,
  cancelText,
}: {
  message: string;
  okText: string;
  cancelText: string;
  callBack: () => void;
}) => {
  return confirm({
    content: (
      <>
        <QuestionIcon />
        <p>{message}</p>
      </>
    ),
    className: 'kpi-wrapper',
    centered: true,
    icon: null,
    cancelText,
    okText,
    width: 500,
    onOk() {
      callBack();
    },
  });
};

export const LoadingSSC = ({ loading }: { loading?: boolean }) =>
  loading ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        color: '#000000',
      }}
    >
      <LoadingRing size='md' />
      <h4>Đang tải dữ liệu</h4>
    </div>
  ) : (
    <></>
  );

export const ProcessBar = ({ percent }: { percent: any }) =>
  percent !== null ? (
    <div
      style={{ display: 'flex', gap: 10, fontSize: 16, alignItems: 'center' }}
    >
      <div
        style={{
          width: 165,
          height: 6,
          background: '#EDEDED',
          borderRadius: 3,
        }}
      >
        <div
          style={{
            width: percent <= 100 ? percent : 100 + '%',
            height: '100%',
            background: '#41AC49',
            borderRadius: 3,
            border: '1px solid #41AC49',
          }}
        ></div>
      </div>
      <span>{percent <= 100 ? percent : '>100'}%</span>
    </div>
  ) : (
    <></>
  );

export const Status = ({ status, text }: { status: KPI_STATUS; text: any }) =>
  status !== null && text !== '' ? (
    <div
      style={{
        padding: '3px 12px',
        width: 130,
        height: 26,
        borderRadius: 16,
        background: _backgroundColor(status).background,
        color: _backgroundColor(status).color,
        fontSize: 13,
      }}
    >
      {text}
    </div>
  ) : (
    <></>
  );

const _backgroundColor = (status: KPI_STATUS) => {
  if (status === KPI_STATUS.New)
    return { background: '#FCEFDF', color: '#E9912A' };
  return { background: '#D9EEDB', color: '#41AC49' };
};

export const EditableCell: React.FC<EditableCellProps> = ({
  children,
  record,
  editable,
  formName,
  ...restProps
}) => {
  if (!editable) return <td {...restProps}>{children}</td>;
  return (
    <td {...restProps}>
      {
        <Form.Item style={{ margin: 0 }} name={formName}>
          <Input maxLength={3} disabled={false} />
        </Form.Item>
      }
    </td>
  );
};
