import React from "react";

const IconSendT = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_117_2730"
        maskUnits="userSpaceOnUse"
        x="1"
        y="2"
        width="16"
        height="15"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.49902 2.25024H16.1233V16.8745H1.49902V2.25024Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_117_2730)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.10273 11.1134L10.8455 15.5631C10.9655 15.7581 11.153 15.7559 11.2287 15.7454C11.3045 15.7349 11.4867 15.6884 11.5527 15.4671L14.9825 3.88339C15.0425 3.67864 14.9322 3.53914 14.8827 3.48964C14.8347 3.44014 14.6975 3.33439 14.4987 3.39064L2.90673 6.78514C2.68698 6.84964 2.63898 7.03414 2.62848 7.10989C2.61798 7.18714 2.61498 7.37839 2.80923 7.50064L7.30998 10.3154L11.2865 6.29689C11.5047 6.07639 11.861 6.07414 12.0822 6.29239C12.3035 6.51064 12.305 6.86764 12.0867 7.08814L8.10273 11.1134ZM11.1702 16.8749C10.6482 16.8749 10.1697 16.6094 9.88773 16.1534L6.97998 11.4351L2.21298 8.45389C1.69923 8.13214 1.43073 7.55914 1.51398 6.95689C1.59648 6.35464 2.00973 5.87614 2.59023 5.70589L14.1822 2.31139C14.7155 2.15539 15.287 2.30314 15.68 2.69464C16.073 3.08989 16.2192 3.66739 16.0602 4.20289L12.6305 15.7859C12.4587 16.3686 11.9787 16.7804 11.378 16.8606C11.3075 16.8696 11.2392 16.8749 11.1702 16.8749Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default IconSendT;
