const IconTwoUsers = ({
  width,
  height,
}: {
  width?: number;
  height?: number;
}) => {
  const customWidth = width ? width : "41";
  const customHeight = height ? height : "40";
  return (
    <svg
      width={customWidth}
      height={customHeight}
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0822 24.7627C9.47586 24.7627 3.83331 25.7832 3.83331 29.8621C3.83331 33.9442 9.43998 34.9998 16.0822 34.9998C22.6885 34.9998 28.3311 33.9793 28.3311 29.9004C28.3311 25.8183 22.7244 24.7627 16.0822 24.7627Z"
        fill="#434243"
      />
      <path
        opacity="0.4"
        d="M16.0822 20.8747C20.5816 20.8747 24.1873 17.3436 24.1873 12.9374C24.1873 8.53108 20.5816 5 16.0822 5C11.5845 5 7.97717 8.53108 7.97717 12.9374C7.97717 17.3436 11.5845 20.8747 16.0822 20.8747Z"
        fill="#434243"
      />
      <path
        opacity="0.4"
        d="M27.4557 13.0812C27.4557 15.3251 26.7675 17.4188 25.5607 19.158C25.4351 19.3369 25.546 19.578 25.7645 19.6164C26.0679 19.6659 26.3794 19.6962 26.6974 19.7026C29.8611 19.784 32.7003 17.7893 33.4847 14.7853C34.6475 10.3279 31.2359 6.32568 26.8898 6.32568C26.4185 6.32568 25.9668 6.3736 25.5264 6.46143C25.4661 6.47421 25.4009 6.50296 25.3683 6.55406C25.3259 6.61954 25.3568 6.70419 25.3992 6.76008C26.7055 8.55357 27.4557 10.7367 27.4557 13.0812Z"
        fill="#434243"
      />
      <path
        d="M36.7985 25.2822C36.2196 24.0733 34.822 23.2444 32.6954 22.8372C31.6925 22.5976 28.9756 22.2574 26.4495 22.3053C26.412 22.3101 26.3908 22.3357 26.3875 22.3517C26.3826 22.3772 26.394 22.4155 26.443 22.4427C27.6106 23.008 32.123 25.4675 31.5555 30.6547C31.531 30.8815 31.7153 31.0732 31.9453 31.0412C33.0558 30.8863 35.913 30.2842 36.7985 28.4125C37.2894 27.4223 37.2894 26.2724 36.7985 25.2822Z"
        fill="#434243"
      />
    </svg>
  );
};

export default IconTwoUsers;
