import React from "react";

function Item(props) {
  const { style, val, vaz, idz, onClick } = props;

  const defaultE = () => {};

  return (
    <div
      onMouseEnter={() => {
        let a = document.getElementsByClassName(`table-${idz}`);
        for (let index = 0; index < a.length; index++) {
          a[index].style.backgroundColor = "rgba(128, 128, 128, 0.062)";
        }
      }}
      onMouseLeave={() => {
        let a = document.getElementsByClassName(`table-${idz}`);
        for (let index = 0; index < a.length; index++) {
          a[index].style.backgroundColor = "white";
        }
      }}
      key={idz}
      className={`${style.item} table-${idz} ${
        val.className ? val.className : ""
      } ${val.center ? style.center : ""} ${onClick ? style.cursor : ""}`}
      onClick={() => {
        onClick ? onClick(vaz, idz, vaz[val.key]) : defaultE();
      }}
      style={val.style ? val.style : style}
    >
      {
        // Nếu biến val có hàm render, return hàm render. Còn không thì sẽ trcuwj tiếp in dữ liệu
        val.render ? val.render(vaz[val.key], idz, vaz) : vaz[val.key]
      }
    </div>
  );
}

export default Item;
