import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../store/store";
import { initialState, KpiSlice } from "../utils";
import {
  add,
  deleteGoalGroup,
  detail,
  getList,
  getListMore,
  update
} from "./thunk";

export const kpiGoalGroupSlice = createSlice({
  name: "kpiGoalGroup",
  initialState,
  reducers: {
    reset: () => initialState,
    toggleAddModal: (state: KpiSlice) => {
      state.showAddModal = !state.showAddModal;
    },
    updateFilterParams: (state: KpiSlice, action) => {
      state.filterParams = { ...action.payload };
    },
    clearSlice: (state: KpiSlice) => {
      state.action = initialState.action;
      state.param = initialState.param;
    },
    updateAction: (state: KpiSlice, action) => {
      state.action = action.payload;
    },
    clearFormFilter: (state: KpiSlice, action) => {
      state.filterParams = initialState.filterParams;
      state.resetFormFilter = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getList.pending, (state) => {
        state.status = "loading";
        state.loading.list = true;
        state.list = initialState.list;
      })
      .addCase(getList.fulfilled, (state, action) => {
        state.status = "idle";
        const _list = [...action.payload.data];
        state.list = _list.map((_item: any, index: number) => ({
          ..._item,
          index: index + 1
        }));
        state.paginate = { ...action.payload.paginate };
        state.loading.list = false;
      })
      .addCase(getList.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(add.pending, (state, action) => {
        state.status = "loading";
        state.loading.add = true;
      })
      .addCase(add.fulfilled, (state, action) => {
        state.status = "idle";
        state.showAddModal = false;
        state.loading.add = false;
      })
      .addCase(getListMore.pending, (state) => {
        state.status = "loading";
        state.loading.loadMore = true;
      })
      .addCase(getListMore.fulfilled, (state, action) => {
        state.status = "idle";
        const _currentList = [...state.list];
        const _list = [..._currentList, ...action.payload.data];
        state.list = _list.map((_item: any, index: number) => ({
          ..._item,
          index: index + 1
        }));
        state.paginate = { ...action.payload.paginate };
        state.loading.loadMore = false;
      })
      .addCase(detail.fulfilled, (state, action) => {
        state.status = "idle";
        state.param = action?.payload || initialState.param;
      })
      .addCase(update.pending, (state, action) => {
        state.status = "loading";
        state.loading.update = true;
      })
      .addCase(update.fulfilled, (state, action) => {
        state.status = "idle";
        state.showAddModal = false;
        state.loading.update = false;
      })
      .addCase(deleteGoalGroup.pending, (state, action) => {
        state.status = "loading";
        state.loading.delete = true;
      })
      .addCase(deleteGoalGroup.rejected, (state, action) => {
        state.loading.delete = false;
      })
      .addCase(deleteGoalGroup.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.delete = false;
      });
  }
});

export const {
  toggleAddModal,
  updateFilterParams,
  clearSlice,
  updateAction,
  clearFormFilter,
  reset
} = kpiGoalGroupSlice.actions;

export const list = (state: RootState) => state.kpiGoalGroup.list;
export const showAddModal = (state: RootState) =>
  state.kpiGoalGroup.showAddModal;
export const filterParams = (state: RootState) =>
  state.kpiGoalGroup.filterParams;
export const paginate = (state: RootState) => state.kpiGoalGroup.paginate;
export const action = (state: RootState) => state.kpiGoalGroup.action;
export const param = (state: RootState) => state.kpiGoalGroup.param;
export const resetFormFilter = (state: RootState) =>
  state.kpiGoalGroup.resetFormFilter;
export const loading = (state: RootState) => state.kpiGoalGroup.loading;

export default kpiGoalGroupSlice.reducer;
