import toast from "react-hot-toast";
import OkrApi from "../../api/okrApi";
import { renderServerErrorMsg } from "../../../../helpers/helper";
import {
  ENUM_TAB_OKR,
  TypeKeyValueAsyncAPI,
  rootObjectType,
} from "../../type/type";
import { TypeSubtree } from "../../../../components/UI/TreeOKRs/type";

interface TypeParamGetOKRs {
  userEmail: string | number;
  month: number;
  year: number;
}

interface TypeRoleOKRs {
  roleOKRs:
    | ENUM_TAB_OKR.DEPARTMENT
    | ENUM_TAB_OKR.DIVISION
    | ENUM_TAB_OKR.PERSONAL;
}

interface TypeDefaultParamsSubitem {
  id: string | number;
  parentId: string | number;
  level: number;
  subtree: TypeSubtree;
  keyValue?: TypeKeyValueAsyncAPI;
  rootObject?: rootObjectType;
  auth_email: string;
  time: {
    month: any;
    year: any;
  };
  uuid: string;
}

interface TypeParamsRequestGetOKRs extends TypeDefaultParamsSubitem {
  key_result_ids: number[];
  sub_project_ids: number[];
  task_ids: number[];
}

interface TypeParamsRequestGetKRs extends TypeDefaultParamsSubitem {
  key_result_ids: number[];
  task_ids: number[];
}

interface TypeParamsRequestGetTasks extends TypeDefaultParamsSubitem {
  task_ids: number[];
}

const _onGetOKRs = async <T extends TypeRoleOKRs>(params: T): Promise<any> => {
  const response = await OkrApi.getOKRs(params as any);
  return response;
};

export const requestGetSubOKRs = async (params: TypeParamsRequestGetOKRs) => {
  try {
    const response = await OkrApi.getSubOKRs(params);
    // OTHER
    if (params.keyValue) {
      return {
        response,
        level: params.level,
        parentId: params.parentId,
        parentUUID: params.uuid,
        keyValue: params.keyValue,
      };
    }
    // CEO-FOCUS
    return {
      response,
      level: params.level,
      parentId: params.parentId,
    };
  } catch (err) {
    return "close-loading";
  }
};

export const requestGetKRs = async (params: TypeParamsRequestGetKRs) => {
  try {
    const response = await OkrApi.getKRs(params);
    if (params.keyValue) {
      return {
        response,
        level: params.level,
        parentId: params.parentId,
        parentUUID: params.uuid,
        keyValue: params.keyValue,
      };
    }
    return { response, level: params.level, parentId: params.parentId };
  } catch (err) {
    return "close-loading";
  }
};

export const requestGetTasks = async (params: TypeParamsRequestGetTasks) => {
  try {
    const response = await OkrApi.getTasks(params);
    if (params.keyValue) {
      return {
        response,
        level: params.level,
        parentId: params.parentId,
        parentUUID: params.uuid,
        keyValue: params.keyValue,
      };
    }
    return {
      response,
      level: params.level,
      parentId: params.parentId,
    };
  } catch (err) {
    return "close-loading";
  }
};

export const requestPersonalOKRs = async (params: TypeParamGetOKRs) => {
  try {
    const response = await _onGetOKRs({
      ...params,
      roleOKRs: ENUM_TAB_OKR.PERSONAL,
    });
    return response.data;
  } catch (error) {
    renderServerErrorMsg(error, toast);
    throw error;
  }
};

export const requestDivisionOKRs = async (params: TypeParamGetOKRs) => {
  try {
    const response = await _onGetOKRs({
      ...params,
      roleOKRs: ENUM_TAB_OKR.DIVISION,
    });
    return response.data;
  } catch (error) {
    renderServerErrorMsg(error, toast);
    throw error;
  }
};

export const requestDepartmentOKRs = async (params: TypeParamGetOKRs) => {
  try {
    const response = await _onGetOKRs({
      ...params,
      roleOKRs: ENUM_TAB_OKR.DEPARTMENT,
    });
    return response.data;
  } catch (error) {
    renderServerErrorMsg(error, toast);
    throw error;
  }
};
