const IconOneUser = ({
  width,
  height,
}: {
  width?: number;
  height?: number;
}) => {
  const customWidth = width ? width : "41";
  const customHeight = height ? height : "40";
  return (
    <svg
      width={customWidth}
      height={customHeight}
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.4947 25.291C13.3063 25.291 7.16632 26.4243 7.16632 30.9577C7.16632 35.4927 13.268 36.666 20.4947 36.666C27.683 36.666 33.823 35.5343 33.823 30.9993C33.823 26.4643 27.723 25.291 20.4947 25.291Z"
        fill="#434243"
      />
      <path
        opacity="0.4"
        d="M20.4947 20.973C25.3913 20.973 29.3147 17.048 29.3147 12.153C29.3147 7.25801 25.3913 3.33301 20.4947 3.33301C15.5997 3.33301 11.6747 7.25801 11.6747 12.153C11.6747 17.048 15.5997 20.973 20.4947 20.973Z"
        fill="#434243"
      />
    </svg>
  );
};

export default IconOneUser;
