import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../store/store";
import {
  convertOKRs,
  mergeSubItemWithPrevOKRsCeoFocus,
} from "../helpers/convert";
import {
  onGetKRs,
  onGetOKRs,
  onGetSubOKRs,
  onGetTasks,
} from "./ceoFocusRequest";

const initialState = {
  okr: {
    0: [],
  },
  result: {
    progress_actual: "-",
    progress_target: "-",
  },
  loading: false,
  status: "idle",
};

const ceoFocusSlice = createSlice({
  name: "role/ceo-focus",
  initialState,
  reducers: {
    _onCleanUpOKRs(state) {
      state.okr = {
        0: [],
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(onGetOKRs.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(onGetOKRs.fulfilled, (state, action) => {
        state.status = "success";
        state.okr = convertOKRs(action.payload.list_project);
        state.result = action.payload.result;
        state.loading = false;
      })
      .addCase(onGetOKRs.rejected, (state) => {
        state.status = "failed";
        state.loading = false;
      })
      .addCase(onGetSubOKRs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(onGetSubOKRs.fulfilled, (state, action) => {
        state.status = "success";
        const payload: any = action.payload;
        state.okr = mergeSubItemWithPrevOKRsCeoFocus(
          {
            level: payload.level,
            response: payload.response,
            parentUUID: payload.parentUUID,
          },
          state.okr
        );
      })
      .addCase(onGetSubOKRs.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(onGetKRs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(onGetKRs.fulfilled, (state, action) => {
        state.status = "success";
        const payload: any = action.payload;
        state.okr = mergeSubItemWithPrevOKRsCeoFocus(
          {
            level: payload.level,
            response: payload.response,
            parentUUID: payload.parentUUID,
          },
          state.okr
        );
      })
      .addCase(onGetKRs.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(onGetTasks.pending, (state) => {
        state.status = "loading";
      })
      .addCase(onGetTasks.fulfilled, (state, action) => {
        state.status = "success";
        const payload: any = action.payload;
        state.okr = mergeSubItemWithPrevOKRsCeoFocus(
          {
            level: payload.level,
            response: payload.response,
            parentUUID: payload.parentUUID,
          },
          state.okr
        );
      })
      .addCase(onGetTasks.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export default ceoFocusSlice.reducer;
export const { _onCleanUpOKRs } = ceoFocusSlice.actions;
export const selectorOKRs = (state: RootState) => state.ceoFocus.okr;
export const selectorResult = (state: RootState) => state.ceoFocus.result;
export const selectorLoadingOKRs = (state: RootState) => state.ceoFocus.loading;
