import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./Helper.module.scss";

type TypeProps = {
  main?: string;
  keys?: string;
  value: string;
};
const TranslateSSC = ({ keys, value, main = "gmessage" }: TypeProps) => {
  const { t } = useTranslation(main, { keyPrefix: keys });
  return <div className={styles.textStatus}>{t(value)}</div>;
};
export default TranslateSSC;
