import React from "react";

const UnauthorizedIcon = () => {
  return (
    <svg
      width="274"
      height="273"
      viewBox="0 0 274 273"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="137" cy="136.5" r="136.5" fill="#FAFAFB" />
      <mask
        id="mask0_23710_41309"
        // style={{ "mask-type": "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="274"
        height="273"
      >
        <circle cx="137" cy="136.5" r="136.5" fill="#FAFAFB" />
      </mask>
      <g mask="url(#mask0_23710_41309)">
        <rect x="0.5" y="198" width="261" height="98" fill="#EDEDED" />
      </g>
      <rect x="37.5" y="18" width="200" height="128" rx="6" fill="#EDEDED" />
      <rect x="46.5" y="23" width="182" height="117" rx="4" fill="white" />
      <rect x="116.5" y="146" width="40" height="48" fill="#C0C0C0" />
      <rect x="89.5" y="195" width="95" height="6" rx="3" fill="#C0C0C0" />
      <path d="M116.5 179L156.5 146H116.5V179Z" fill="#666666" />
      <rect x="89.5" y="110" width="96" height="10" rx="5" fill="#D9D9D9" />
      <circle cx="123" cy="115.5" r="2.5" fill="#666666" />
      <circle cx="137" cy="115.5" r="2.5" fill="#666666" />
      <circle cx="151" cy="115.5" r="2.5" fill="#666666" />
      <path
        d="M150 79H157.987C163.158 79 167.272 74.6639 167 69.5V69.5C166.728 64.3361 170.842 60 176.013 60H228.5M46.5 74.6552H93.5C95.433 74.6552 97 73.0882 97 71.1552V71.1552C97 69.2222 98.567 67.6552 100.5 67.6552H126"
        stroke="#D8D8D8"
      />
      <path
        d="M150.5 68H194.174C197.764 68 200.674 65.0899 200.674 61.5V61.5C200.674 57.9101 203.584 55 207.174 55H228.5"
        stroke="#D8D8D8"
      />
      <path
        d="M145.5 89V89C145.5 93.6944 149.306 97.5 154 97.5H184.75C187.097 97.5 189 95.5972 189 93.25V93.25C189 90.9028 190.903 89 193.25 89H228.5"
        stroke="#D8D8D8"
      />
      <path
        d="M46.5 68H75C78.0376 68 80.5 70.4624 80.5 73.5V73.5C80.5 76.5376 82.9624 79 86 79H130.5"
        stroke="#D8D8D8"
      />
      <path
        d="M46.5 97.5H117C122.523 97.5 127 93.0228 127 87.5V86"
        stroke="#D8D8D8"
      />
      <path
        d="M149.25 62.1V54C149.25 49.5817 145.668 46 141.25 46H134.25C129.832 46 126.25 49.5817 126.25 54V62.1"
        stroke="black"
        strokeWidth="3"
      />
      <rect
        x="120.5"
        y="59.7998"
        width="34.5"
        height="32.2"
        rx="8"
        fill="#F0B71C"
      />
      <circle cx="137.75" cy="73.5994" r="3.45" fill="#FAFAFB" />
      <rect
        x="136.6"
        y="75.8994"
        width="2.3"
        height="6.9"
        rx="1.15"
        fill="#FAFAFB"
      />
      <circle cx="58.5" cy="68" r="2" fill="#D9D9D9" />
      <circle cx="75.5" cy="97" r="2" fill="#D9D9D9" />
      <circle cx="106.5" cy="79" r="2" fill="#D9D9D9" />
      <circle cx="181.5" cy="68" r="2" fill="#D9D9D9" />
      <circle cx="213.5" cy="89" r="2" fill="#D9D9D9" />
      <circle cx="219.5" cy="55" r="2" fill="#D9D9D9" />
    </svg>
  );
};

export default UnauthorizedIcon;
