import { useEffect } from "react";
import { createPortal } from "react-dom";

import styles from "./globalModal.module.scss";

const GlobalModal = ({ isOpen, onClose, children }: any) => {
  
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return createPortal(
    <>
      <div className={styles.overlay} onClick={onClose}></div>
      <div className={styles.modalContent}>{children}</div>
    </>,
    document.getElementById("container-portal")!
  );
};

export default GlobalModal;
