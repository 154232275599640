import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store/store";
import { GticketState } from "./types";
import { _requestGetListWorkgroup } from "./request/requestListWorkgroup";
import toast from "react-hot-toast";
import { _getListTitlesForCategory } from "./request/requestListTitleForCategory";

const initialState: GticketState = {
  workGroupList: [],
  loadingWGList: false,
  statusWGList: "",
  totalsPageWG: 0,
  listTitles: [],
  listCategory: [],
};

export const actionGetListWorkgroup = createAsyncThunk(
  "workgroup/getListWorkgroup",
  _requestGetListWorkgroup
);

export const actionGetListTitleForCategory = createAsyncThunk(
  "workgroup/getListTitle",
  _getListTitlesForCategory
);

const gticketManage = createSlice({
  name: "gticket",
  initialState: initialState,
  reducers: {
    actionCleanDataWorkgroup: (state) => {
      state.workGroupList = [];
      state.loadingWGList = false;
      state.statusWGList = "";
      state.totalsPageWG = 0;
      state.listTitles = [];
    },
    actionSetDataListCategory: (state, action) => {
      state.listCategory = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(actionGetListWorkgroup.pending, (state) => {
        state.loadingWGList = true;
        state.statusWGList = "";
      })
      .addCase(actionGetListWorkgroup.fulfilled, (state, action) => {
        const payload: any = action.payload;

        state.loadingWGList = false;
        state.workGroupList = state.workGroupList.concat(payload.data?.data?.items ?? []);
        // state.totalsPageWG = payload.total_page;
        state.totalsPageWG = payload?.data?.data?.total_page || (state.workGroupList.concat(payload.data?.data?.items ?? [])?.length  / 10 ) || 0;
        state.statusWGList = "done";
      })
      .addCase(actionGetListWorkgroup.rejected, (state) => {
        state.loadingWGList = false;
        state.statusWGList = "done";
        toast.error("Có lỗi xảy ra khi lấy danh sách nhóm.");
      })
      .addCase(actionGetListTitleForCategory.pending, (state) => {})
      .addCase(actionGetListTitleForCategory.fulfilled, (state, action) => {
        const payload: any = action.payload;
        state.listTitles = payload ?? [];
      })
      .addCase(actionGetListTitleForCategory.rejected, (state) => {});
  },
});

export const { actionCleanDataWorkgroup, actionSetDataListCategory } =
  gticketManage.actions;

export default gticketManage.reducer;

export const selectWorkGroupList = (state: RootState) =>
  state.gticket.workGroupList;
export const selectLoadingWGList = (state: RootState) =>
  state.gticket.loadingWGList;
export const selectStatusWGList = (state: RootState) =>
  state.gticket.statusWGList;
export const selectTotalsPageWG = (state: RootState) =>
  state.gticket.totalsPageWG;
export const selectListTitles = (state: RootState) => state.gticket.listTitles;
export const selectListCategory = (state: RootState) =>
  state.gticket.listCategory;
