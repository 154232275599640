import React from "react";

type PropsType = {
  height?: string;
};

const IconPlus = ({ height }: PropsType) => {
  const customHeight = height ? height : "14";
  return (
    <svg
      width={customHeight}
      height={customHeight}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99996 0.142822C7.47335 0.142822 7.85711 0.526578 7.85711 0.999965V6.14282H13C13.4734 6.14282 13.8571 6.52658 13.8571 6.99996C13.8571 7.47335 13.4734 7.85711 13 7.85711H7.85711V13C7.85711 13.4734 7.47335 13.8571 6.99996 13.8571C6.52658 13.8571 6.14282 13.4734 6.14282 13V7.85711H0.999965C0.526578 7.85711 0.142822 7.47335 0.142822 6.99996C0.142822 6.52658 0.526578 6.14282 0.999965 6.14282H6.14282V0.999965C6.14282 0.526578 6.52658 0.142822 6.99996 0.142822Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconPlus;
