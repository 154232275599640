import _ from "lodash";

export const formatNameOfUser = (_user: any): string => {
  if (!_user) {
    return "";
  }

  let name = _user?.name ?? "";
  let user_name = _user?.user_name ?? "";
  let full_name = _user?.full_name ?? "";
  let department_name = _user?.department?.name ?? "";

  if (user_name && full_name && full_name?.includes(user_name)) {
    return full_name || name;
  }

  return `${user_name} ${
    full_name || department_name
      ? `(${full_name} ${
          department_name && full_name
            ? `| ${department_name}`
            : `${department_name}`
        })`
      : ``
  } `;
};

export const formatterYCHT = (value: any) => {
  return parseFloat(
    parseFloat(value.replace(/[^0-9.]/g, "")).toFixed(2)
  ).toString();
};

export const parserYCHT = (value: any) => {
  return parseFloat(
    parseFloat(value.replace(/[^0-9.]/g, "")).toFixed(2)
  ).toString();
};

export const formatterCurrency = (value: any): string => {
  if (!value) return "";
  const stringValue = value.toString();
  return stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const parserCurrency = (value: any) => {
  if (!value) return "";
  const numericValue = value.replace(/(,*)/g, "");
  return numericValue;
};

export const blockNaNInput = (e: any, mode?: any) => {
  // const key = e.key;
  // if (
  //   !/^\d$/.test(key) && // Allow digits (0-9)
  //   key !== '.' && // Allow decimal point
  //   key !== 'Backspace' && // Allow backspace key
  //   key !== 'ArrowLeft' && // Allow backspace key
  //   key !== 'ArrowRight' && // Allow backspace key
  //   key !== 'Delete'  // Allow delete key
  // ) {
  //   e.preventDefault(); // Prevents the default behavior of the key press
  // }
  // if (mode === 1 &&    key === '.') {
  //   e.preventDefault();
  // }
};

export const InputNumberFormatter = (e: any) => {
  // // Số > 0 , 2 số thập phân , chặn input ngoài số
  // blockNaNInput(e)
  // const {value} = e.target;
  // const key = e.key;
  // if ( value && value.includes(".")) {
  //   let arr = value.split(".");
  //   if (arr[1].length === 2 &&  key !== 'Backspace') {
  //     e.preventDefault();
  //   }
  // }
};

export const formatCostingToChargeObject = (array: any[], callback: any) => {
  const costing = array.reduce(
    (acc: any, cur: any) => {
      if (!acc["workplace"]) {
        acc["workplace"] = Number.isNaN(Number(cur["workplace"]))
          ? cur["workplace"]
          : Number(cur["workplace"]);
      }

      if (cur?.charge_object_id) {
        if (cur?.charge_object_name) {
          acc.charge_object_ids.push(cur?.charge_object_name);
          acc.outdatedIds.push(cur?.charge_object_id);
        } else {
          acc.charge_object_ids.push(cur?.charge_object_id);
        }

        if (cur?.charge_object_lv2_ids?.length) {
          cur?.charge_object_lv2_ids?.forEach((x: any) => {
            acc.charge_object_lv2_ids.push({
              id: x,
              parent_id: cur?.charge_object_id,
            });
          });
        }
      }
      return acc;
    },
    {
      workplace: null,
      charge_object_ids: [],
      charge_object_lv2_ids: [],
      outdatedIds: [],  
    }
  );
  callback({
    ...costing,
    charge_object_ids: _.uniq(costing?.charge_object_ids),
  });
};

export const sortedByFullName = (arr: any[]) => {
  if (!arr.length) return [];
  return arr.slice().sort((a, b) => {
    const nameA = a?.full_name.toLowerCase();
    const nameB = b?.full_name.toLowerCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};

export const sortedByName = (arr: any[]) => {
  return arr.sort(function (a: any, b: any) {
    if (a?.name < b?.name) {
      return -1;
    }
    if (a?.name > b?.name) {
      return 1;
    }
    return 0;
  });
};
