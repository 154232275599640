import moment from 'moment';
import { CheckEnum, GoalTypeEnum, ResultApproveTypeEnum, SubjectEnum } from './enum';
import { ResultApproveSlice, ConfigFilter, OptionSelect } from './interface';
import i18n from '../../../../i18n';

export const MONTHS: string[] = [
  ...Array.from({ length: 12 }, (_, i) => ('0' + (i + 1)).slice(-2))
];
const offsetYear = 5;
export const YEARS: number[] = [
  ...Array.from(
    { length: offsetYear * 2 + 1 },
    (_, i) => new Date().getFullYear() - offsetYear + i
  )
];

export const MONTH_OPTIONS = MONTHS.map((_year) => ({
  label: _year,
  value: Number(_year)
}));

export const YEAR_OPTIONS = YEARS.map((_year) => ({
  label: _year,
  value: _year
}));

export enum STATUS {
  Active = 1,
  Inactive
}

export enum SCREEN {
  RESULT = 2
}

export enum ACTION {
  Delete = 'delete',
  Edit = 'edit',
  View = 'view',
  Create = 'create',
  DownloadTemplate = 'download_template',
  ExportExcel = 'export_excel',
  Upload = 'upload'
}

export enum TOAST_TYPE {
  Success = 'success',
  Error = 'error',
  Warning = 'warning'
}

export const PER_PAGE = 20;
export const DEFAULT_PAGE = 1;

export const OPTION_ITEM_ALL = {
  label: i18n.t('gperformance:resultApprove.filter.all'),
  value: CheckEnum.All
};

const MONTH_NOW = new Date().getMonth() + 1
const OFFSET_MONTH = 1
const MONTH_DEFAULT_SELECT = MONTH_NOW - OFFSET_MONTH <= 0 ? 12 - Math.abs(MONTH_NOW - OFFSET_MONTH) : MONTH_NOW - OFFSET_MONTH
const YEAR_NOW = new Date().getFullYear();
const YEAR_DEFAULT_SELECT = MONTH_NOW - OFFSET_MONTH <= 0 ? YEAR_NOW - 1 : YEAR_NOW
export const DEFAULT_FILTER: ConfigFilter = {
  subjectId: [CheckEnum.All],
  employeeId: [],
  goalType: GoalTypeEnum.Month,
  timeYear: YEAR_DEFAULT_SELECT,
  timeMonth: MONTH_DEFAULT_SELECT
};
export const DEFAULT_OBJECT_PARAMS = {
  ids: CheckEnum.All,
  user_ids: CheckEnum.All,
  month: MONTH_DEFAULT_SELECT,
  year: YEAR_DEFAULT_SELECT,
  type: GoalTypeEnum.Month,
  per_page: 20,
  page: 1
};

export const DEFAULT_EMPLOYEE_PARAMS = {
  workspace_ids: CheckEnum.All,
  user_ids: CheckEnum.All,
  month: MONTH_DEFAULT_SELECT,
  year: YEAR_DEFAULT_SELECT,
  type: GoalTypeEnum.Month,
  per_page: 20,
  page: 1
};

export const initialState: ResultApproveSlice = {
  listObjResultApprove: [],
  listEmployeeResultApprove: [],
  listWorkspaceOfUser: [],
  listOriginWorkspace: [],
  listEmployee: [],
  listEmployeeActive: [],
  loading: {
    list: false,
    searchResult: false,
    employee: false,
    employeeActive: false,
    upload: false,
    details: false,
    import:false,
    objectLoadMore: false,
    employeeLoadMore: false,
    detailUser: false,
    workspace: false,
    update: false,
    scoreApprove: false
  },
  fileIdUploadSuccess: [],
  files: [],
  filterObjectParams: { ...DEFAULT_OBJECT_PARAMS },
  filterEmployeeParams: { ...DEFAULT_EMPLOYEE_PARAMS },
  selectedObjects: [],
  selectedEmployees: [],
  paginateObject: {
    total: 0,
    current_page: 0,
    last_page: 0
  },
  paginateEmployee: {
    total: 0,
    current_page: 0,
    last_page: 0
  },
  showUploadModal: false,
  selectAllRows: false,
  detailUser: null,
  idsAllWorkspaceOfUser: [],
  parentIds: [],
  defaultSelectedKeysFilter: [],
  colors: [],
  curKeyEditing: '',
  invalidResultMessage: '',
  keysWorkspaceCanBeApprove: [],
  idsWorkspaceUserOwner: [],
  listObjResultApproveCaching: [],
  listEmployeeResultApproveCaching: [],
  heightFilter: 0
};

export const OPTION_SUBJECT: OptionSelect[] = [
  {
    value: SubjectEnum.Individual,
    label: i18n.t('gperformance:resultApprove.filter.withEmployee')
  },
  {
    value: SubjectEnum.Group,
    label: i18n.t('gperformance:resultApprove.filter.withSubject')
  }
];

export const OPTION_GOAL_TYPE: OptionSelect[] = [
  {
    value: GoalTypeEnum.Month,
    label: i18n.t('gperformance:resultApprove.filter.month')
  },
  {
    value: GoalTypeEnum.Year,
    label: i18n.t('gperformance:resultApprove.filter.year')
  }
];

export const ADD_GOAL_HEADERS = [
  'objectSelect',
  'object',
  'goal',
  'goalType',
  'timeMonth',
  'timeYear',
  'beforeResult',
  'afterResult'
];

export const REGEX_DECIMAL_RESULT = new RegExp(
  /^[1-9].(?:\d{1,20}|(?=.{1,20}$)\d+\.\d{0,10})$/
);


export const MAX_GOAL_FILE_UPLOAD = 5;
export const FILE_GOAL_TYPE_UPLOAD = [
  'application/vnd.rar',
  'application/zip',
  'image/png',
  'image/jpeg',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/pdf',
  'text/csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.template.macroEnabled.12',
  'application/vnd.ms-excel.addin.macroEnabled.12',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12'
];

export const FILE_GOAL_NAME_UPLOAD = [
  'rar',
  'img',
  'zip',
  'doc',
  'docx',
  'pdf',
  'xlsx',
  'xls',
  'csv',
  'png',
  'jpg'
];

export const MAX_GOAL_FILE_SIZE = 5;
export const NOW_MONTH = new Date().getMonth() + 1;
export const NOTE_MAX_LENGTH = 1000;
export const RESULT_NOTE_MAX_LENGTH = 250;
export const NOTE_TEXT_COLOR = {
  EMPTY: '#A5A5A5',  
  HAS_DATA: '#000000d9'  
};

export const DEFAULT_TABLE_DATA = [
  {
    key: ResultApproveTypeEnum.Object,
    code: ResultApproveTypeEnum.Object,
    name: '',
    result: '',
    resultAdjustment: '',
    month: '',
    year: '',
    time: '',
    note: '',
    description: i18n.t('gperformance:resultApprove.list.object'),
  },
  {
    key: ResultApproveTypeEnum.Employee,
    code: ResultApproveTypeEnum.Employee,
    name: '',
    result: '',
    resultAdjustment: '',
    month: '',
    year: '',
    resultPerformance: '',
    time: '',
    note: '',
    description: i18n.t('gperformance:resultApprove.list.employee'),
  },
]

export const REGEX_INTEGER_RESULT = new RegExp(/^([1-9]{1}|[1-9][0-9]|100)$/);
export const REGEX_POSITIVE_INTEGER_UNDER_100 = new RegExp(/^([1-9][0-9]?|100)$/);
export const REGEX_DECIMAL_PERCENT = new RegExp(/^((?=.*[1-9]+)(0*([1-9]{1})([0-9]{0,1})(\.[0-9]{1,10})?)|100(\.[0]{1,10})?)$/);