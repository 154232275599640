import { Tooltip } from "antd";
import { useEffect, useState } from "react";
import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons";
import usePerformanceManagement from "../../redux/hook";
import styles from "./style.module.scss";
interface PropsType {
  name: string;
  id: number;
  hasChildren: boolean;
}
function Workspace(props: PropsType) {
  const { name, id } = props;
  const onToggleExpand: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    const data = Object.keys(mapWorkspace)
      .filter((key: string) => mapWorkspace[Number(key)].parent_id === id)
      .map((item) => Number(item));
    const usedWorkspace = [...followSubject];
    let mapUsedWorkspace: { [key: number]: boolean } = {};
    usedWorkspace.forEach((id: number) => {
      mapUsedWorkspace[id] = true;
    });
    let addIds = data.filter((id) => !mapUsedWorkspace[id]);
    addFollowSubject(addIds);
    updateToggleMap(id);
  };
  const [hasChildren, setHasChildren] = useState(false);
  const {
    toggleMap,
    updateToggleMap,
    mapWorkspace,
    addFollowSubject,
    selectedSubject,
    followSubject,
    userInformation,
    concurrentlyWorkspace,
  } = usePerformanceManagement();
  useEffect(() => {
    const workspaceActive = [
      ...concurrentlyWorkspace.owner,
      ...concurrentlyWorkspace.staff,
      userInformation?.workspace?.id,
    ];
    const ownerWorkspace = [...concurrentlyWorkspace.owner];
    if (userInformation?.workspace?.is_owner) {
      ownerWorkspace.push(userInformation?.workspace.id);
    }
    const workspace = mapWorkspace[id];
    let result = true;
    if (workspaceActive.includes(id)) {
      if (ownerWorkspace.includes(id)) {
        if (workspace.hasChildren) {
          result = true;
        } else {
          result = false;
        }
      } else {
        result = false;
      }
    } else if (workspace.hasChildren) {
      result = true;
    } else {
      result = false;
    }
    setHasChildren(result);
    return () => {};
  }, [id, mapWorkspace]);
  const [isDirectApproval, setIsDirectApproval] = useState(false);
  useEffect(() => {
    const isBold = [...selectedSubject, ...followSubject].includes(id);
    setIsDirectApproval(isBold);
    return () => {};
  }, [selectedSubject, followSubject]);

  return (
    <div className={styles["employee"]}>
      {/* <div className={styles["position"]}>{position}</div> */}
      <div className={styles["detail"]}>
        <div className={styles["context"]}>
          <Tooltip title={name}>
            <div
              className={`${styles["name"]} ${
                isDirectApproval ? styles["bold"] : ""
              }`}
            >
              {name}
            </div>
          </Tooltip>
        </div>
        {hasChildren && (
          <button className={styles["action"]} onClick={onToggleExpand}>
            {toggleMap && !!toggleMap[id] ? (
              <MinusSquareOutlined style={{ fontSize: "16px" }} />
            ) : (
              <PlusSquareOutlined style={{ fontSize: "16px" }} />
            )}
          </button>
        )}
      </div>
    </div>
  );
}
export default Workspace;
