import React from "react";
type TypeProps = {
  onClick?: any;
};
const IconAccept = ({ onClick }: TypeProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.74924 2.625C3.85174 2.625 2.62549 3.92475 2.62549 5.937V12.063C2.62549 14.0752 3.85174 15.375 5.74924 15.375H12.2502C14.1485 15.375 15.3755 14.0752 15.3755 12.063V5.937C15.3755 3.92475 14.1485 2.625 12.251 2.625H5.74924ZM12.2502 16.5H5.74924C3.20749 16.5 1.50049 14.7165 1.50049 12.063V5.937C1.50049 3.2835 3.20749 1.5 5.74924 1.5H12.251C14.7927 1.5 16.5005 3.2835 16.5005 5.937V12.063C16.5005 14.7165 14.7927 16.5 12.2502 16.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.1106 11.342C7.96735 11.342 7.8226 11.2873 7.7131 11.177L5.9326 9.39728C5.71285 9.17753 5.71285 8.82203 5.9326 8.60228C6.15235 8.38253 6.50785 8.38253 6.7276 8.60228L8.1106 9.98378L11.2726 6.82253C11.4924 6.60278 11.8479 6.60278 12.0676 6.82253C12.2874 7.04228 12.2874 7.39778 12.0676 7.61753L8.5081 11.177C8.3986 11.2873 8.2546 11.342 8.1106 11.342Z"
        fill="white"
      />
    </svg>
  );
};
export default IconAccept;
