import { KpiSlice } from "./interface";

export const PARAM_HEADER = ["field", "condition", "start", "end", "result"];
export enum STATUS {
  Active = 1,
  Inactive
}

export enum ACTION {
  Delete = "delete",
  Edit = "edit",
  View = "view",
  Create = "create"
}

export enum TOAST_TYPE {
  Success = "success",
  Error = "error",
  Warning = "warning"
}

export const PER_PAGE = 20;
export const DEFAULT_PAGE = 1;
export const DEFAULT_PARAMS = { per_page: PER_PAGE, page: DEFAULT_PAGE };

export const initialState: KpiSlice = {
  list: [],
  paginate: {
    total: 0,
    current_page: 0,
    last_page: 0
  },
  loading: {
    loadMore: false,
    list: false,
    add: false,
    delete: false,
    update: false
  },
  status: "idle",
  showAddModal: false,
  filterParams: { ...DEFAULT_PARAMS },
  endpointSource: [],
  resourceCode: "",
  action: { id: null, action: ACTION.Create },
  param: { status: STATUS.Active },
  resetFormFilter: false
};
