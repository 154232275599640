import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../store/store';
import {
  downloadFile,
  initialState,
  mapDataDetail,
  mapDataTable,
  mapEmployeeData,
  mapTreeData,
  StatusOperatorEnum,
  SubjectEnum,
  getIds
} from '../utils';
import {
  add,
  deleteResult,
  detail,
  downloadTemplate,
  exportExcel,
  getList,
  getListEmployee,
  getListFilterResult,
  getListGoalAssignee,
  getListMore,
  getListPerformance,
  getListResult,
  getListWorkspace,
  importResult,
  update,
  upload
} from './thunk';

export const resultManagementSlice = createSlice({
  name: 'gPerformanceResultManagement',
  initialState,
  reducers: {
    reset: (state) => ({...initialState,  listEmployeeActive: state.listEmployeeActive, listWorkspaceActive: state.listWorkspaceActive, listOriginWorkspace: state.listOriginWorkspace,  listSubjectIds: state.listSubjectIds}),
    toggleAddModal: (state) => {
      state.showAddModal = !state.showAddModal;
    },
    toggleUploadModal: (state) => {
      state.showUploadModal = !state.showUploadModal;
    },
    toggleEditModal: (state) => {
      state.showEditModal = !state.showEditModal;
    },
    toggleExpandedSearch: (state) => {
      state.expandedSearch = !state.expandedSearch;
    },
    updateFilterParams: (state, action) => {
      state.filterParams = { ...action.payload };
    },
    clearSlice: (state) => {
      state.action = initialState.action;
      state.errorAddResult = initialState.errorAddResult;
      state.errorEditResult = initialState.errorEditResult;
    },
    updateAction: (state, action) => {
      state.action = action.payload;
    },
    clearFormFilter: (state, action) => {
      state.filterParams = initialState.filterParams;
      state.resetFormFilter = action.payload;
    },
    setErrorAddResult: (state, action) => {
      state.errorAddResult = action.payload;
    },
    setErrorEditResult: (state, action) => {
      state.errorEditResult = action.payload;
    },
    setCodeErrorAddResult: (state, action) => {
      state.codeErrorAddResult = action.payload;
    },
    setFileIdUploadSuccess: (state, action) => {
      state.fileIdUploadSuccess = action.payload;
    },
    setFiles: (state, action) => {
      state.files = action.payload;
    },
    setListSubject: (state, action) => {
      state.listSubject = action.payload;
    },
    setHeightFilter: (state, action) => {
      state.heightFilter = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getList.pending, (state) => {
        state.loading.list = true;
      })
      .addCase(getList.fulfilled, (state, action) => {
        state.loading.list = false;
        state.list = mapDataTable([...action.payload.data]);
        state.paginate = { ...action.payload.paginate };
      })
      .addCase(getListFilterResult.pending, (state, action) => {
        state.loading.searchResult = true;
      })
      .addCase(getListFilterResult.fulfilled, (state, action) => {
        state.loading.searchResult = false;
        state.listResult = action.payload?.result.map((_item: any) => ({
          label: _item.name,
          value: _item.id
        }));
      })
      .addCase(getListWorkspace.fulfilled, (state, action) => {
        state.listWorkspaceActive = [mapTreeData(action.payload.data)?.[0]];
        state.listSubjectIds = getIds(action.payload.data, [])
        state.listOriginWorkspace = [action.payload.data?.[0]];
        // if (action.meta.arg?.status === StatusOperatorEnum.Active) {
        //   state.listWorkspaceActive = [mapTreeData(action.payload.data)?.[0]];
        //   state.listSubjectIds = getIds(action.payload.data, [])
        // } else {
        //   state.listOriginWorkspace = [action.payload.data?.[0]];
        // }
      })
      .addCase(getListPerformance.pending, (state, action) => {
        if (action.meta.arg.type_unit === SubjectEnum.Individual) {
          state.loading.performanceEmployee = true;
        } else {
          state.loading.performanceWorkspace = true;
        }
      })
      .addCase(getListPerformance.fulfilled, (state, action) => {
        if (action.meta.arg.type_unit === SubjectEnum.Individual) {
          state.listEmployee = mapEmployeeData(action.payload.data);
          state.loading.performanceEmployee = false;
        } else {
          state.listWorkspacePerformance = action.payload.data;
          state.loading.performanceWorkspace = false;
        }
      })
      .addCase(getListEmployee.pending, (state, action) => {
        state.loading.employee = true;
      })
      .addCase(getListEmployee.fulfilled, (state, action) => {
        state.listEmployeeActive = mapEmployeeData(action.payload.data);
        state.loading.employee = false;
      })
      .addCase(getListResult.fulfilled, (state, action) => {
        state.listResult = action.payload.data.map((_item: any) => ({
          label: _item.name,
          value: _item.id
        }));
      })
      .addCase(getListGoalAssignee.fulfilled, (state, action) => {
        const assignee_id = action.meta.arg.assignee_id;
        const _listGoal = action.payload?.data?.map((_item: any) => ({
          label: _item.name,
          value: _item.id
        }));
        if (assignee_id) {
          state.listGoalForm = [
            ...state.listGoalForm,
            { [assignee_id]: _listGoal }
          ];
        }
      })
      .addCase(add.pending, (state, action) => {
        state.loading.add = true;
      })
      .addCase(add.rejected, (state, action) => {
        state.loading.add = false;
      })
      .addCase(add.fulfilled, (state, action) => {
        state.showAddModal = false;
        state.loading.add = false;
      })
      .addCase(downloadTemplate.fulfilled, (state, action) => {
        downloadFile(action.payload);
      })
      .addCase(exportExcel.fulfilled, (state, action) => {
        downloadFile(action.payload);
      })
      .addCase(getListMore.pending, (state) => {
        state.loading.loadMore = true;
      })
      .addCase(getListMore.fulfilled, (state, action) => {
        state.paginate = { ...action.payload.paginate };
        const _currentList = [...state.list];
        const _listMore = mapDataTable([...action.payload.data]);
        const _list = [..._currentList, ..._listMore];
        state.list = _list.map((_item: any, index: number) => ({
          ..._item,
          index: index + 1
        }));
        state.loading.loadMore = false;
      })
      .addCase(detail.pending, (state, action) => {
        state.resultDetail = null;
        state.loading.details = true;
      })
      .addCase(detail.fulfilled, (state, action) => {
        state.resultDetail = mapDataDetail(action.payload?.data);
        state.loading.details = false;
      })
      .addCase(update.pending, (state, action) => {
        state.loading.update = true;
      })
      .addCase(update.rejected, (state, action) => {
        state.loading.update = false;
      })
      .addCase(upload.pending, (state, action) => {
        state.loading.upload = true;
      })
      .addCase(upload.rejected, (state, action) => {
        state.loading.upload = false;
      })
      .addCase(upload.fulfilled, (state, action) => {
        state.fileIdUploadSuccess = [
          ...state.fileIdUploadSuccess,
          action.payload?.data?.id
        ];
      })
      .addCase(importResult.pending, (state, action) => {
        state.loading.import = true;
      })
      .addCase(importResult.fulfilled, (state, action) => {
        state.showUploadModal = false;
        state.loading.import = false;
        state.loading.upload = false;
        state.fileIdUploadSuccess = [];
      })
      .addCase(update.fulfilled, (state, action) => {
        state.showEditModal = false;
        state.loading.update = false;
      })
      .addCase(deleteResult.pending, (state, action) => {
        state.loading.delete = true;
      })
      .addCase(deleteResult.rejected, (state, action) => {
        state.loading.delete = false;
      })
      .addCase(deleteResult.fulfilled, (state, action) => {
        state.loading.delete = false;
      });
  }
});

export const {
  toggleAddModal,
  toggleUploadModal,
  updateFilterParams,
  clearSlice,
  updateAction,
  clearFormFilter,
  reset,
  toggleEditModal,
  setErrorAddResult,
  setErrorEditResult,
  setCodeErrorAddResult,
  toggleExpandedSearch,
  setFileIdUploadSuccess,
  setFiles,
  setListSubject,
  setHeightFilter
} = resultManagementSlice.actions;

export const gPerformanceResultManagement = (state: RootState) =>
  state.gPerformanceResultManagement;

export default resultManagementSlice.reducer;
