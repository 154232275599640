import { useEffect } from "react";
import {
  fetchAllUsersAndGroups,
  selectFetchUsersAndGroupsStatus,
  selectUsers,
} from "../../features/meta-info/redux/metaInfoSlice";
import { useAppDispatch, useAppSelector } from "../../store/store";
import Layout from "../Layout/Layout";
import { LoadingRing } from "../UI";

type PropsType = {
  children: React.ReactNode;
  showLayout?: boolean;
};

function FetchAllUser({ children, showLayout = true }: PropsType) {
  const users = useAppSelector(selectUsers);
  const fetchUsersStatus = useAppSelector(selectFetchUsersAndGroupsStatus);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      Array.isArray(users) &&
      users.length === 0 &&
      fetchUsersStatus !== "succeeded"
    ) {
      dispatch(fetchAllUsersAndGroups());
    }
  }, []);

  if (fetchUsersStatus === "loading") {
    if (showLayout)
      return (
        <Layout>
          <div className="container-full-flex-center">
            <LoadingRing size="md" />
          </div>
        </Layout>
      );
    return (
      <div className="container-full-flex-center">
        <LoadingRing size="md" />
      </div>
    );
  }
  return <>{children}</>;
}

export default FetchAllUser;
