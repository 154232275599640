import { createSlice, nanoid } from "@reduxjs/toolkit";
import moment from "moment";
import { RootState } from "../../../../store/store";
import { convertStatus, downloadFile, initialState, KpiSlice } from "../utils";
import {
  add,
  deleteGoal,
  detail,
  downloadGoal,
  getDepartments,
  getGoalGroup,
  getList,
  getListMore,
  getParamByIds,
  getParameters,
  settingGoal,
  update,
  updateSettingGoal,
  validateFormula,
  viewDraft,
  copy
} from "./thunk";

export const kpiGoalSlice = createSlice({
  name: "kpiGoal",
  initialState,
  reducers: {
    reset: () => initialState,
    toggleAddModal: (state: KpiSlice, action) => {
      const field = action.payload as
        | "addGoal"
        | "previewGoal"
        | "setupGoal"
        | "addRoom"
        | "previewSetup";
      state.showModal[field] = !state.showModal[field];
    },
    updateFilterParams: (state: KpiSlice, action) => {
      state.filterParams = { ...action.payload };
    },
    clearSlice: (state: KpiSlice) => {
      state.action = initialState.action;
      state.goal = initialState.goal;
      state.formula = [];
      state.parameterByIds = initialState.parameterByIds;
    },
    updateAction: (state: KpiSlice, action) => {
      state.action = action.payload;
    },
    clearFormFilter: (state: KpiSlice, action) => {
      state.filterParams = initialState.filterParams;
      state.resetFormFilter = action.payload;
    },
    updateFormula: (state: KpiSlice, action) => {
      state.formula = [...action.payload];
    },
    updateGoal: (state: KpiSlice, action) => {
      state.goal = { ...action.payload };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getList.pending, (state) => {
        state.status = "loading";
        state.loading.list = true;
        state.list = initialState.list;
      })
      .addCase(getList.fulfilled, (state, action) => {
        state.status = "idle";
        const _list = [...action.payload.data];
        state.list = _list.map((_item: any) => ({
          ..._item,
          groupShow: _item.goal_category?.name, 
          goalNumberShow: _item.group?.goal_number || 0,
          statusShow: convertStatus(_item.status)
        }));
        state.paginate = { ...action.payload.paginate };
        state.loading.list = false;
      })
      .addCase(getList.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getListMore.pending, (state) => {
        state.status = "loading";
        state.loading.loadMore = true;
      })
      .addCase(getListMore.fulfilled, (state, action) => {
        state.status = "idle";
        const _currentList = [...state.list];
        const _list = [...action.payload.data].map((_item: any) => ({
          ..._item,
          groupShow: _item.goal_category?.name,
          goalNumberShow: _item.group?.goal_number || 0,
          statusShow: convertStatus(_item.status)
        }));
        state.list = [..._currentList, ..._list];
        state.paginate = { ...action.payload.paginate };
        state.loading.loadMore = false;
      })
      .addCase(copy.pending, (state, action) => {
        state.status = "loading";
        state.loading.copy = true;
      })
      .addCase(copy.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.copy = false;
      })
      .addCase(add.pending, (state, action) => {
        state.status = "loading";
        state.loading.add = true;
      })
      .addCase(add.fulfilled, (state, action) => {
        state.status = "idle";
        state.showModal.previewGoal = false;
        state.showModal.addGoal = false;
        state.loading.add = false;
      })
      .addCase(detail.fulfilled, (state, action) => {
        state.status = "idle";
        const _data = action?.payload;
        state.goal =
          {
            ..._data,
            date_applied: moment(_data.date_applied),
            goal_group_id: _data.group.id
          } || initialState.goal;
        state.formula = [..._data.kpi_formula.kpi_formula_operators].map(
          (_item: any) => ({
            ..._item,
            parameter_id: _item?.formula?.parameter_id || ""
          })
        );
      })
      .addCase(update.pending, (state, action) => {
        state.status = "loading";
        state.loading.update = true;
      })
      .addCase(update.fulfilled, (state, action) => {
        state.status = "idle";
        state.showModal.previewGoal = false;
        state.showModal.addGoal = false;
        state.loading.update = false;
      })
      .addCase(deleteGoal.pending, (state, action) => {
        state.status = "loading";
        state.loading.delete = true;
      })
      .addCase(deleteGoal.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.delete = false;
      })
      .addCase(getParameters.pending, (state, action) => {
        state.status = "loading";
        state.loading.searchParam = true;
      })
      .addCase(getParameters.fulfilled, (state, action) => {
        state.status = "idle";
        state.parameters = action.payload.data;
        state.loading.searchParam = false;
      })
      .addCase(getParamByIds.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getParamByIds.fulfilled, (state, action) => {
        state.status = "idle";
        state.parameterByIds = action.payload.data;
      })
      .addCase(getGoalGroup.fulfilled, (state, action) => {
        state.status = "idle";
        state.goalGroup = action.payload.data;
      })
      .addCase(validateFormula.pending, (state, action) => {
        state.status = "loading";
        state.loading.validateFomula = true;
      })
      .addCase(validateFormula.fulfilled, (state, action) => {
        state.status = "idle";
        state.showModal.previewGoal = true;
        state.loading.validateFomula = false;
      })
      .addCase(downloadGoal.fulfilled, (state, action) => {
        state.status = "idle";
        downloadFile(action.payload);
      })
      .addCase(getDepartments.fulfilled, (state, action) => {
        state.status = "idle";
        const _departments = action.payload.data;
        state.departments = _departments.map((_department: any) => ({
          ..._department,
          key: nanoid(),
          children: _department.divisions.map(({ id, name }: any) => ({
            department_id: _department.id,
            division_id: id,
            division: name,
            key: nanoid()
          }))
        }));
      })
      .addCase(settingGoal.pending, (state, action) => {
        state.status = "loading";
        state.loading.setting = true;
      })
      .addCase(settingGoal.fulfilled, (state, action) => {
        state.status = "idle";
        state.showModal.setupGoal = false;
        state.loading.setting = false;
      })
      .addCase(updateSettingGoal.pending, (state, action) => {
        state.status = "loading";
        state.loading.setting = true;
      })
      .addCase(updateSettingGoal.fulfilled, (state, action) => {
        state.status = "idle";
        state.showModal.setupGoal = false;
        state.loading.setting = false;
      })
      .addCase(viewDraft.pending, (state, action) => {
        state.status = "loading";
        state.loading.previewSetting = true
      })
      .addCase(viewDraft.fulfilled, (state, action) => {
        state.status = "idle";
        state.viewSetup = action.payload.data
          .filter((_item: any) => _item.is_display === 1)
          .map((_item: any) => ({
            ..._item,
            title: _item.display_name,
            dataIndex: _item.display_name
          }));
        state.showModal.previewSetup = true;
        state.loading.previewSetting = false
      });
  }
});

export const {
  toggleAddModal,
  updateFilterParams,
  clearSlice,
  updateAction,
  clearFormFilter,
  updateFormula,
  updateGoal,
  reset
} = kpiGoalSlice.actions;

export const list = (state: RootState) => state.kpiGoal.list;
export const showModal = (state: RootState) => state.kpiGoal.showModal;
export const filterParams = (state: RootState) => state.kpiGoal.filterParams;
export const paginate = (state: RootState) => state.kpiGoal.paginate;
export const action = (state: RootState) => state.kpiGoal.action;
export const goal = (state: RootState) => state.kpiGoal.goal;
export const resetFormFilter = (state: RootState) =>
  state.kpiGoal.resetFormFilter;
export const loading = (state: RootState) => state.kpiGoal.loading;
export const parameters = (state: RootState) => state.kpiGoal.parameters;
export const parameterByIds = (state: RootState) =>
  state.kpiGoal.parameterByIds;
export const goalGroup = (state: RootState) => state.kpiGoal.goalGroup;
export const formula = (state: RootState) => state.kpiGoal.formula;
export const departments = (state: RootState) => state.kpiGoal.departments;
export const viewSetup = (state: RootState) => state.kpiGoal.viewSetup;

export default kpiGoalSlice.reducer;
