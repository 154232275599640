import { RequestApiKpi, WsCode } from '../../api';

const KpiManagementApi = {
  list: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiKpi.get(WsCode.kpiManaggement.list, {
        params
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  add: async (payload: any): Promise<any> => {
    try {
      const response = await RequestApiKpi.post(
        WsCode.kpiManaggement.addKpi,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  detail: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiKpi.get(WsCode.kpiManaggement.detail, {
        params
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  update: async (id: number, payload: any): Promise<any> => {
    try {
      const response = await RequestApiKpi.put(
        WsCode.kpiManaggement.update,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  delete: async (id: number): Promise<any> => {
    try {
      const response = await RequestApiKpi.delete(
        WsCode.kpiManaggement.deleteAll + `/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  departments: async (): Promise<any> => {
    try {
      const response = await RequestApiKpi.get(
        WsCode.kpiManaggement.departments
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  divisions: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiKpi.get(
        WsCode.kpiManaggement.divisions,
        { params }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  employees: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiKpi.get(
        WsCode.kpiManaggement.employees,
        { params }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  goals: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiKpi.get(WsCode.kpiManaggement.goals, {
        params
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  createKpiData: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiKpi.get(
        WsCode.kpiManaggement.createKpiData,
        { params }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};

export default KpiManagementApi;
