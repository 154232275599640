import { CSSProperties, ReactNode, useEffect, useRef } from "react";
import style from "./style.module.scss";
type TypeProps = {
  children: ReactNode;
  id?: any;
  className?: any;
  classLabel?: any;
  label?: any;
  name: any | "button";
  require?: {
    Number?: Boolean;
    Empty: Boolean;
    TitleError?: Boolean;
  };
  styles?: CSSProperties;
  error?: String;
  classNameItem?: any;
  fakeLabel?: Boolean;
};
const Form = ({
  children,
  id,
  className,
  classLabel,
  label,
  name,
  require,
  error,
  classNameItem,
  styles,
  fakeLabel,
}: TypeProps) => {
  const ref = useRef<any>();
  const refItem = useRef<any>();
  useEffect(() => {
    if (ref.current && name && ref.current.childNodes[0]) {
      ref.current.childNodes[0].name = name;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, name]);
  const onChanges = (e: any) => {
    let a = ref.current.childNodes[0];
    if (a && require?.Empty && refItem.current) {
      const { value } = a;
      let labels = document.getElementById("form-item-label-" + name);
      if ((!value || (value && !value.trim())) && require?.Empty) {
        refItem.current.innerText = error
          ? error
          : `${
              require?.TitleError && labels
                ? labels.innerText + " không"
                : "Không được"
            } được bỏ trống!`;
      } else if (require?.Number && isNaN(Number(value))) {
        refItem.current.innerText = error
          ? error
          : `${
              require?.TitleError && labels
                ? labels.innerText + " phải"
                : "Ký tự nhập vào phải"
            } là số!`;
      } else {
        refItem.current.innerText = "";
        ref.current.className = `${style.input} border-error ${classNameItem}`;
      }
    } else {
      refItem.current.innerText = "";
      ref.current.className = `${style.input} ${classNameItem}`;
    }
  };

  const onClicks = () => {
    refItem.current.innerText = "";
    ref.current.className = `${style.input} ${classNameItem}`;
  };

  return (
    <div style={styles} id={id} className={`${className} ${style.item}`}>
      <div
        id={"form-item-label-" + name}
        className={`${classLabel} ${style.label} ${
          fakeLabel ? style.fake_label : ""
        }`}
      >
        {label}
      </div>
      <div
        id={"form-item-" + name}
        ref={ref}
        onChange={onChanges}
        className={`${style.input} ${classNameItem}`}
        onClick={onClicks}
      >
        {children}
      </div>
      <div
        ref={refItem}
        id={"form-item-error-" + name}
        className={`${style.error}`}
      ></div>
    </div>
  );
};

export default Form;
