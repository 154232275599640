import moment from 'moment';
import { STATUS } from './constant';
import { CheckEnum } from './enum';
import { FilterParam, ListFile, OptionSelect, UserInfo } from './interface';

export const convertStatus = (_status: number): boolean => {
  if (_status === STATUS.Success) return true;
  return false;
};

export const downloadFileParam = (params: FilterParam) => {
  const _params = { ...params } as {
    page?: number;
    status?: number;
    resource_code?: string;
    keyword?: string;
    per_page?: number;
  };
  if (_params.per_page) {
    delete _params.per_page;
  }
  if (_params.page) {
    delete _params.page;
  }
  return _params;
};

export const downloadFile = (response: any): void => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const fileName = getFileNameInResponseHeader(response.headers);
  const link = document.createElement('a');
  link.target = '_blank';
  link.download = fileName;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

const getFileNameInResponseHeader = (headers: any): any => {
  let fileName = '';
  if (!headers) return '';
  if ('content-disposition' in headers) {
    const data = headers['content-disposition']
      .split(';')
      .map((item: string) => item.trim());

    for (let item of data) {
      const disposition = item.split('=');
      if (disposition.length > 0) {
        if (disposition[0] === 'filename') return disposition[1];
      }
    }
  }
  return fileName;
};

export const formatText = (_val: string): string => {
  if (_val.length > 20) {
    return _val.substring(0, 20) + '...';
  }
  return _val;
};

export const mapEmployeeData = (data: UserInfo[]): OptionSelect[] => {
  return data.map((_user) => ({
    label: _user?.full_name || '-',
    value: _user?.id
  }));
};

export const mapDataTable = (data: ListFile[]) => {
  return data.map((_item, _idx) => ({
    index: _idx + 1,
    name: _item.file_name,
    loadTime: moment(_item.created_at).format('DD/MM/YYYY HH:mm'),
    readTime: _item.updated_at
      ? moment(_item.updated_at).format('DD/MM/YYYY HH:mm')
      : null,
    userName: `${_item.user?.user_name}${
      _item.user?.name ? ` (${_item.user?.name})` : ""
    }`,
    type: _item.type,
    status: _item.status,
    id: _item.id
  }));
};

export const mapDataFilter = (values: any): FilterParam => {
  return {
    status: values.status.includes(CheckEnum.All) ? undefined : values.status,
    user_id:
      values.employeeId?.includes(CheckEnum.All) ||
      values.employeeId?.[0]?.value === CheckEnum.All
        ? undefined
        : values.employeeId?.join(','),
    read_start_time: values.readTime
      ? moment(values.readTime?.[0]).format('YYYY/MM/DD')
      : undefined,
    read_end_time: values.readTime
      ? moment(values.readTime?.[1]).format('YYYY/MM/DD')
      : undefined,
    load_start_time: values.loadTime
      ? moment(values.loadTime?.[0]).format('YYYY/MM/DD')
      : undefined,
    load_end_time: values.loadTime
      ? moment(values.loadTime?.[1]).format('YYYY/MM/DD')
      : undefined,
    type: values.type.includes(CheckEnum.All) ? undefined : values.type
  };
};
