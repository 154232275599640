export const OBJECT_ACTION_TYPE = {
  WORK_STEP: 1,
  INFORMATION_STEP: 2,
  INFORMATION_USER: 3,
  INFORMATION_USER_GROUP: 4,
  USER_NEXT_STEP: 5,
};

export const GTICKET_TASK_TYPE = {
  ASSIGNED_TASK: 1,
  ROUTINE_TASK: 2,
  PLANNING_TASK: 3,
};

export const APPROVAL_STATUS = {
  DEFAULT: 0,
  DRAFF: 1,
  WAITING: 2,
  ACTIVE: 3,
  REJECT: 4,
  EXPIRED: 5,
};

export const APPROVAL_STATUS_FILTER = {
  DRAFF: 0,
  WAITING: 1,
  COMPLETED: 2,
  CLOSED: 3,
  REJECT: 4,
};

export const DASHBOARD_STATUS = {
  IN_PROGRESS: 1,
  COMPLETED: 2,
};

export const DASHBOARD_TASK_ROUTE = {
  G_PROCESS: "tasks/g-process",
  G_TICKET: "tasks-g-ticket",
  OKR: "../okr/target-management",
};

export const NO_ANSWER = "N/A";

export const G_TICKET_TIMELINE_STATUS_LIST = [
  {
    value: "late",
    label: "task.late",
  },
  {
    value: "upcoming",
    label: "task.upcoming",
  },
  {
    value: "all",
    label: "TotalTaskToBeDone",
  },
];

export enum MODULE_NAME {
  G_TICKET = "G-Ticket",
  G_PROCESS = "G-Process",
}

export const MONTHS = [
  {
    label: 1,
    value: 1,
  },
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 4,
    value: 4,
  },
  {
    label: 5,
    value: 5,
  },
  {
    label: 6,
    value: 6,
  },
  {
    label: 7,
    value: 7,
  },
  {
    label: 8,
    value: 8,
  },
  {
    label: 9,
    value: 9,
  },
  {
    label: 10,
    value: 10,
  },
  {
    label: 11,
    value: 11,
  },
  {
    label: 12,
    value: 12,
  },
];
