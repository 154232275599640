import { UserManager, User } from "oidc-client";

class AuthService {
  userManager: UserManager;
  constructor() {
    const settings = {
      authority: process.env.REACT_APP_SSO_ROOT_URL,
      client_id: process.env.REACT_APP_SSO_CLIENT_ID,
      AllowOfflineAccess: true,
      AllowAccessTokensViaBrowser: true,
      redirect_uri: `${process.env.REACT_APP_BASE_URL}/login/callback`,
      silent_redirect_uri: `${process.env.REACT_APP_BASE_URL}/logout`,
      post_logout_redirect_uri: `${process.env.REACT_APP_BASE_URL}/logout`,
      response_type: "id_token token",
      scope:
        "openid profile email skoruba_identity_admin_api IdentityServerApi",
    };
    this.userManager = new UserManager(settings);
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  public signinRedirectCallback(): Promise<User> {
    return this.userManager.signinRedirectCallback();
  }

  public renewToken(): Promise<User> {
    return this.userManager.signinSilent();
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }
}

export const AuthServiceInstance = new AuthService();
