import { Button } from "antd";
import { DISPLAY_TYPES, MAP_TEXT, STATUS_STYLE_NAME } from "../../constants";
import { getTime } from "../../helper";
import { HistoryParameterType } from "../../interfaces";
import usePerformanceManagement from "../../redux/hook";
import styles from "./style.module.scss";
function StatusChip({ value, data }: { value: string; data: any }) {
  const { openHistoryPerformance, filterConfiguration } =
    usePerformanceManagement();
  let text = "";
  if (MAP_TEXT.hasOwnProperty(value)) {
    text = MAP_TEXT[Number(value)];
  }
  const openModalHistory = () => {
    const filter = filterConfiguration;
    let params: HistoryParameterType = {
      type: filter.type,
      type_unit: filter.displayTarget,
      month: 1,
      year: 1,
      assignee_id: 1,
    };
    if (filter.displayType === DISPLAY_TYPES.MULTIPLE_SUBJECT) {
      params = {
        ...params,
        ...getTime({ month: filter.month }),
        assignee_id: Number(data.id),
      };
    } else if (filter.displayType === DISPLAY_TYPES.SINGLE_SUBJECT) {
      params = {
        ...params,
        ...getTime({ month: data.name }),
        assignee_id: Number(filter.assignee_id),
      };
    }
    openHistoryPerformance(params);
  };
  return (
    <div
      className={`${styles.status} ${styles[STATUS_STYLE_NAME[value]]}`}
      onClick={openModalHistory}
    >
      {text}
    </div>
  );
}
export default StatusChip;
