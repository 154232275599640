import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface CreateDepartmentFormState {
  value: any;
  focusField: string;
}

const initialState: CreateDepartmentFormState = {
  value: {},
  focusField: "",
};

const createDepartmentSlice = createSlice({
  name: "createDepartment",
  initialState,
  reducers: {
    updateForm(state, action: PayloadAction<any>) {
      state.value = action.payload;
    },
    updateFocusFied(state, action: PayloadAction<any>) {
      state.focusField = action.payload;
    },
  },
});

export const { updateForm, updateFocusFied } = createDepartmentSlice.actions;
export default createDepartmentSlice.reducer;
