import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { WorkspaceApi } from "../../system-management/workspace/api/WorkspaceApi";
import { RootState } from "../../../store/store";

const fetchRegionInfo = createAsyncThunk(
  "selectionData/regionInfo",
  async (payload: { callback?: any }) => {
    const { callback } = payload;
    try {
      const { data } = await WorkspaceApi.getRegionInfo();
      return [...data];
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      callback && callback(false);
      throw error;
    }
  }
);

const fetchObjectWorkspaceInfo = createAsyncThunk(
  "selectionData/objectWorkspaceInfo",
  async (payload: { callback?: any }) => {
    const { callback } = payload;
    try {
      const { data } = await WorkspaceApi.getObjectInfo();
      return [...data];
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      callback && callback(false);
      throw error;
    }
  }
);

const fetchRacingGroupInfo = createAsyncThunk(
  "selectionData/racingGroupInfo",
  async (payload: { callback?: any }) => {
    const { callback } = payload;
    try {
      const { data } = await WorkspaceApi.getRacingInfo();
      return [...data];
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      callback && callback(false);
      throw error;
    }
  }
);

const fetchTypeWorkspaceInfo = createAsyncThunk(
  "selectionData/typeWorkspaceInfo",
  async (payload: { callback?: any }) => {
    const { callback } = payload;
    try {
      const { data } = await WorkspaceApi.getTypeInfo();
      return [...data];
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      callback && callback(false);
      throw error;
    }
  }
);

const fetchRolesInfo = createAsyncThunk(
  "selectionData/fetchRolesInfo",
  async (payload: { callback?: any }) => {
    const { callback } = payload;
    try {
      const { data } = await WorkspaceApi.getListRole();
      return [...data];
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      callback && callback(false);
      throw error;
    }
  }
);

const fetchWorkspaceInfo = createAsyncThunk(
  "selectionData/fetchWorkspaceInfo",
  async (payload: { callback?: any }) => {
    const { callback } = payload;
    try {
      const { data } = await WorkspaceApi.getWorkspaceInfo();
      return { ...data[0] };
    } catch (error: any) {
      // toast.error(error?.response?.data?.message);
      callback && callback(false);
      return [];
    }
  }
);

export async function getSelectionData(dispatch: any, callback?: any) {
  dispatch(
    fetchRegionInfo({
      callback,
    })
  );
  dispatch(
    fetchObjectWorkspaceInfo({
      callback,
    })
  );
  dispatch(
    fetchRacingGroupInfo({
      callback,
    })
  );
  dispatch(
    fetchTypeWorkspaceInfo({
      callback,
    })
  );
  dispatch(
    fetchRolesInfo({
      callback,
    })
  );
  dispatch(
    fetchWorkspaceInfo({
      callback,
    })
  );
}

const initialState: any = {
  regionInfo: {
    data: [],
    status: "loading",
  },
  objectWorkspace: {
    data: [],
    status: "loading",
  },
  racingGroup: {
    data: [],
    status: "loading",
  },
  typeWorkspace: {
    data: [],
    status: "loading",
  },
  rolesInfo: {
    data: [],
    status: "loading",
  },
  workspaceInfo: {
    data: {},
    status: "loading",
  },
};

const selectionDataSlice = createSlice({
  name: "selectionData",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchRegionInfo.pending, (state) => {
        state.regionInfo.status = "loading";
      })
      .addCase(fetchRegionInfo.fulfilled, (state, action) => {
        state.regionInfo.status = "succeeded";
        state.regionInfo.data = action.payload;
      })
      .addCase(fetchRegionInfo.rejected, (state) => {
        state.regionInfo.status = "failed";
      })
      .addCase(fetchObjectWorkspaceInfo.pending, (state) => {
        state.objectWorkspace.status = "loading";
      })
      .addCase(fetchObjectWorkspaceInfo.fulfilled, (state, action) => {
        state.objectWorkspace.status = "succeeded";
        state.objectWorkspace.data = action.payload;
      })
      .addCase(fetchObjectWorkspaceInfo.rejected, (state) => {
        state.objectWorkspace.status = "failed";
      })
      .addCase(fetchRacingGroupInfo.pending, (state) => {
        state.racingGroup.status = "loading";
      })
      .addCase(fetchRacingGroupInfo.fulfilled, (state, action) => {
        state.racingGroup.status = "succeeded";
        state.racingGroup.data = action.payload;
      })
      .addCase(fetchRacingGroupInfo.rejected, (state) => {
        state.racingGroup.status = "failed";
      })
      .addCase(fetchTypeWorkspaceInfo.pending, (state) => {
        state.typeWorkspace.status = "loading";
      })
      .addCase(fetchTypeWorkspaceInfo.fulfilled, (state, action) => {
        state.typeWorkspace.status = "succeeded";
        state.typeWorkspace.data = action.payload;
      })
      .addCase(fetchTypeWorkspaceInfo.rejected, (state) => {
        state.typeWorkspace.status = "failed";
      })
      .addCase(fetchRolesInfo.pending, (state) => {
        state.rolesInfo.status = "loading";
      })
      .addCase(fetchRolesInfo.fulfilled, (state, action) => {
        state.rolesInfo.status = "succeeded";
        state.rolesInfo.data = action.payload;
      })
      .addCase(fetchRolesInfo.rejected, (state) => {
        state.rolesInfo.status = "failed";
      })
      .addCase(fetchWorkspaceInfo.pending, (state) => {
        state.workspaceInfo.status = "loading";
      })
      .addCase(fetchWorkspaceInfo.fulfilled, (state, action) => {
        state.workspaceInfo.status = "succeeded";
        state.workspaceInfo.data = action.payload;
      })
      .addCase(fetchWorkspaceInfo.rejected, (state) => {
        state.workspaceInfo.status = "failed";
      });
  },
});

export const selectSelectionData = (state: RootState) => state.selectionData;
export default selectionDataSlice.reducer;
