import React from "react";

const IconUpLoad = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7612_2896)">
        <path
          d="M11 10H1C0.734784 10 0.48043 10.1054 0.292893 10.2929C0.105357 10.4804 0 10.7348 0 11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H11C11.2652 12 11.5196 11.8946 11.7071 11.7071C11.8946 11.5196 12 11.2652 12 11C12 10.7348 11.8946 10.4804 11.7071 10.2929C11.5196 10.1054 11.2652 10 11 10Z"
          fill="black"
        />
        <path
          d="M5.50047 7.99992H6.50047C6.63308 7.99992 6.76025 7.94724 6.85402 7.85347C6.94779 7.7597 7.00047 7.63253 7.00047 7.49992V4.99992H9.50047C9.59645 4.99982 9.69038 4.9721 9.77104 4.92006C9.85169 4.86802 9.91566 4.79386 9.95531 4.70645C9.99496 4.61903 10.0086 4.52205 9.99463 4.42709C9.98065 4.33213 9.93963 4.2432 9.87647 4.17092L6.37647 0.170917C6.32822 0.119578 6.26996 0.078664 6.2053 0.0506965C6.14063 0.0227291 6.07092 0.00830078 6.00047 0.00830078C5.93001 0.00830078 5.86031 0.0227291 5.79564 0.0506965C5.73097 0.078664 5.67272 0.119578 5.62447 0.170917L2.12447 4.17092C2.06131 4.2432 2.02029 4.33213 2.00631 4.42709C1.99233 4.52205 2.00598 4.61903 2.04562 4.70645C2.08527 4.79386 2.14924 4.86802 2.2299 4.92006C2.31055 4.9721 2.40448 4.99982 2.50047 4.99992H5.00047V7.49992C5.00047 7.63253 5.05315 7.7597 5.14691 7.85347C5.24068 7.94724 5.36786 7.99992 5.50047 7.99992Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_7612_2896">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconUpLoad;
