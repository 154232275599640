import axios from 'axios';
import {
  KPI_RESOURCE_KEY,
  SERVICE_URL_KPI_API,
  SERVICE_URL_KPI_BASE,
  SERVICE_URL_KPI_RESOURCE_ENDPOINT,
  SERVICE_URL_ACCOUNT_BASE,
  ACCOUNT_SERVICE_KEY
} from './wsCode';

export const RequestApiKpi = axios.create({
  baseURL: SERVICE_URL_KPI_API,
  headers: {
    'Content-Type': 'application/json'
  },
  paramsSerializer: (params) => paramsSerializer(params)
});

export const RequestApiKpiBase = axios.create({
  baseURL: SERVICE_URL_KPI_API,
  headers: {
    'Content-Type': 'application/json'
  },
  paramsSerializer: (params) => paramsSerializer(params)
});

export const RequestApiPrivate = axios.create({
  baseURL: SERVICE_URL_KPI_API,
  headers: {
    'Content-Type': 'application/json',
  }
});

export const RequestApiKpiEndPoint = axios.create({
  baseURL: SERVICE_URL_KPI_RESOURCE_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    'X-Authorization': KPI_RESOURCE_KEY as string
  },
  paramsSerializer: (params) => paramsSerializer(params)
});

export const RequestApiAccountService = axios.create({
  baseURL: SERVICE_URL_ACCOUNT_BASE,
  headers: {
    'Content-Type': 'application/json',
    'api-key': ACCOUNT_SERVICE_KEY || ''
  },
  paramsSerializer: (params) => paramsSerializer(params)
});

const paramsSerializer = (params: { [key: string]: string }) => {
  let result = '';
  Object.keys(params).forEach((key) => {
    if (params[key] !== undefined) {
      result += `${key}=${encodeURIComponent(params[key])}&`;
    }
  });
  return result.substring(0, result.length - 1);
};
