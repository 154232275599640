import { Tag, Tooltip } from "antd";
import { AntTreeNodeProps } from "antd/lib/tree";
import { DisplayValueType, CustomTagProps } from "rc-select/lib/BaseSelect";
import IconArrowTree from "../../../common/icons/IconArrowTree";
import { CSSProperties } from "react";
export const renderMaxTag = (props: DisplayValueType[]) => {
  const title =
    props
      .map((item) => (typeof item.label == "string" ? item.label : item.title))
      .join(", ")
      .substring(0, 600) + "...";
  return (
    <Tooltip title={title}>
      <span>+{props.length}...</span>
    </Tooltip>
  );
};
export const switcherIcon = ({ expanded }: AntTreeNodeProps) => (
  <IconArrowTree
    className={`'icon-arrow-tree' ${!expanded ? "icon-expanded" : ""}`}
  />
);
export const renderTag = (props: CustomTagProps) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const tagStyle: CSSProperties = {
    background: "#fbeac0 ",
    color: "black",
    marginRight: 3,
    height: 30,
    fontSize: 16,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    borderRadius: 4,
    border: "none",
  };
  const texStyle: CSSProperties = {
    maxWidth: `calc(100% - 10px)`,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  };
  return (
    <Tooltip title={label} overlayClassName={"performance-popup"}>
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={tagStyle}
      >
        <div style={texStyle}>{label}</div>
      </Tag>
    </Tooltip>
  );
};
