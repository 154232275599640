import {
  NodeType,
  TypeId,
  TypeSubtree,
} from "../../../components/UI/TreeOKRs/type";

export type OkrType = {
  id: number;
  type: number;
  code: string;
  name: string;
  from_date: string;
  to_date: string;
  description: string;
  target?: string;
  frequency_type?: number;
  day_of_week?: number;
  date_in_month?: number;
  primary_users: any[];
  file: {
    id: number;
    file_id: number;
    original_filename: string;
    filename: string;
  };
  date_extends?: Array<{ user: number; date: string }>;
  creator_id: number;
  assignee_id: number;
  parent_id?: number;
  attachments: any[];
};

export type KrType = {
  project_id: any;
  code?: string;
  name: string;
  from_date: string;
  density: string | number;
  to_date: string;
  description: string;
  target?: string;
  primary_users: any[];
  departments: any;
  file: {
    id: number;
    original_filename: string;
    filename: string;
  } | null;
  creator_id?: number;
  assignee: {
    user_id: any;
  };
  date_extends: any[];
  attachments: any[];
};

export type ModalCRUDMode = "create" | "update" | "view" | "copied";

export interface OptionType {
  value: number;
  label: string | number;
}

export type SelectOptionValue = null | OptionType;

export type DateExtend = {
  uid: string;
  user: SelectOptionValue;
  date: Date | null;
  creator_id: null | number;
};
/**
 * params getSubitemAPI in role CEO-DEPARTMENT
 */
export type parentIDType = {
  parentId: string | number;
};
/**
 * params getSubitemAPI
 */
export type defaultParamsGetSubItemAPIType = {
  id: TypeId;
  uuid: string;
  level: number;
  subtree: TypeSubtree;
  auth_email: string;
  key_result_ids: number[];
  sub_project_ids: number[];
  task_ids: number[];
};
/**
 * some row need keys below to work
 */
export type optionalParamsGetSubItemAPIType = {
  keyValue?: TypeKeyValueAsyncAPI;
  rootObject?: rootObjectType;
};
export type paramsGetSubItemAPIType = defaultParamsGetSubItemAPIType &
  optionalParamsGetSubItemAPIType & { time: keyTimeSubItemAPIType };

export type keyTimeSubItemAPIType = {
  month: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  year: number;
};

export type paramsRequestGetOKRsType = {
  month: number;
  year: number;
  userEmail: number | string;
};

export type nodeOKRType = {
  department_id: number;
  department_name: string;
  list_project: { [key: number]: NodeType[] | [] };
  progress_actual: number;
  progress_target: number;
};
/**
 * root-object is object contain params using for specific page on API getSubitem
 */
export type rootObjectType = {
  label: labelIDType;
  value: number | string;
};
/**
 * labelIDType using specific page on API getSubitem
 */
export type labelIDType = ENUM_ID.USER | ENUM_ID.DIVISION | ENUM_ID.DEPARTMENT;
/**
 * TypeKeyValue using specific page on API getSubitem
 */
export type TypeKeyValueName =
  | ENUM_KEY_VALUE_NAME.USER
  | ENUM_KEY_VALUE_NAME.DIVISION
  | ENUM_KEY_VALUE_NAME.DEPARTMENT;
/**
 *
 */
export type TypeKeyValueAsyncAPI =
  | ENUM_KEY_VALUE_ASYNC_API.DEPARTMENT_ID
  | ENUM_KEY_VALUE_ASYNC_API.DIVISION_ID
  | ENUM_KEY_VALUE_ASYNC_API.PERSONAL_ID;

export type TypeRoleTabHOD =
  | ENUM_TAB_OKR.DEPARTMENT
  | ENUM_TAB_OKR.DIVISION
  | ENUM_TAB_OKR.PERSONAL;

export enum ENUM_KEY_VALUE_ASYNC_API {
  DEPARTMENT_ID = "department_id",
  DIVISION_ID = "division_id",
  PERSONAL_ID = "personal_id",
}

export enum ENUM_KEY_VALUE_NAME {
  USER = "user_name",
  DIVISION = "division_name",
  DEPARTMENT = "department_name",
}

export enum ENUM_ID {
  USER = "user_id",
  DIVISION = "division_id",
  DEPARTMENT = "department_id",
}

export enum ENUM_TAB {
  CEO_FOCUS = "CEO_FOCUS",
  DEPARTMENT = "DEPARTMENT",
}

export enum ENUM_TAB_OKR {
  DEPARTMENT = "department",
  DIVISION = "division",
  PERSONAL = "personal",
}

export enum ENUM_MODE_MODAL {
  CREATE = "create",
  MODIFY = "edit",
  VIEW = "view",
}
/**
 * redux action type
 */
export type actionOnCloseSubTreeType = {
  payload: {
    level: number;
    id: TypeId;
    parentId: TypeId;
    keyValue:
      | ENUM_KEY_VALUE_ASYNC_API.DEPARTMENT_ID
      | ENUM_KEY_VALUE_ASYNC_API.DIVISION_ID
      | ENUM_KEY_VALUE_ASYNC_API.PERSONAL_ID;
    uuid: string;
  };
};
