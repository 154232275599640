import { lazy, ReactNode } from "react";
import styles from "./Layout.module.scss";
// import { isMobile } from "react-device-detect";
import MobileDetected from "../../features/mobile-view";
import clsx from "clsx";

const Header = lazy(() => import("../Header/Header"));
const Sidebar = lazy(() => import("../Sidebar/Sidebar"));
function Layout({
  children,
  noDisplay,
  isScrollInside
}: {
  children: ReactNode;
  noDisplay?: Boolean;
  isScrollInside?: Boolean;
}) {
  const isMobile = false;
  return (
    <div className={`${styles.wrapper}`}>
      {isMobile ? (
        <MobileDetected />
      ) : (
        <>
          <div className={styles.sidebar}>
            <Sidebar />
          </div>
          <div
            style={{ display: `${noDisplay ? "block" : "flex"}` }}
            className={clsx(styles.content, isScrollInside && styles.overflowHidden)}
          >
            <header className={styles.header}>
              <Header />
            </header>
            <main id="body" className={styles.main}>
              {children}
            </main>
          </div>
        </>
      )}
    </div>
  );
}

export default Layout;
