const IconThreeUsers = ({
  width,
  height,
}: {
  width?: number;
  height?: number;
}) => {
  const customWidth = width ? width : "41";
  const customHeight = height ? height : "40";
  return (
    <svg
      width={customWidth}
      height={customHeight}
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.415 25.4878C14.6651 25.4878 9.81348 26.4945 9.81348 30.3797C9.81348 34.2666 14.6964 35.2379 20.415 35.2379C26.165 35.2379 31.0166 34.2312 31.0166 30.346C31.0166 26.4591 26.1337 25.4878 20.415 25.4878Z"
        fill="#434243"
      />
      <path
        opacity="0.4"
        d="M20.415 21.7862C24.3085 21.7862 27.4305 18.422 27.4305 14.2264C27.4305 10.0291 24.3085 6.6665 20.415 6.6665C16.5215 6.6665 13.3995 10.0291 13.3995 14.2264C13.3995 18.422 16.5215 21.7862 20.415 21.7862Z"
        fill="#434243"
      />
      <path
        opacity="0.4"
        d="M35.6465 15.9866C36.6538 11.7411 33.7005 7.92822 29.9398 7.92822C29.5309 7.92822 29.1399 7.97647 28.7579 8.05848C28.7071 8.07135 28.6504 8.09869 28.6206 8.14693C28.5862 8.20804 28.6116 8.29005 28.6489 8.34312C29.7786 10.051 30.4278 12.1303 30.4278 14.3624C30.4278 16.5012 29.8323 18.4953 28.7877 20.1501C28.6803 20.3205 28.7758 20.5505 28.9653 20.5859C29.2279 20.6357 29.4966 20.6614 29.7712 20.6695C32.5096 20.7467 34.9675 18.8475 35.6465 15.9866Z"
        fill="#434243"
      />
      <path
        d="M38.5154 25.9827C38.014 24.8312 36.8037 24.0417 34.9637 23.6541C34.0951 23.4257 31.7447 23.1041 29.5584 23.1475C29.5256 23.1524 29.5077 23.1765 29.5047 23.1926C29.5002 23.2151 29.5092 23.2537 29.5525 23.2778C30.5628 23.8165 34.4682 26.1596 33.9772 31.1014C33.9563 31.3153 34.116 31.5002 34.3145 31.468C35.2756 31.3201 37.7484 30.7476 38.5154 28.9642C38.9393 28.0218 38.9393 26.9267 38.5154 25.9827Z"
        fill="#434243"
      />
      <path
        opacity="0.4"
        d="M12.2414 8.05897C11.8608 7.97535 11.4684 7.92871 11.0595 7.92871C7.29876 7.92871 4.34543 11.7416 5.35425 15.9871C6.03177 18.848 8.48965 20.7472 11.2281 20.67C11.5027 20.6619 11.7728 20.6346 12.034 20.5864C12.2235 20.551 12.319 20.321 12.2115 20.1505C11.1669 18.4942 10.5715 16.5017 10.5715 14.3629C10.5715 12.1292 11.2221 10.0498 12.3518 8.34361C12.3876 8.29054 12.4145 8.20853 12.3787 8.14742C12.3488 8.09757 12.2936 8.07184 12.2414 8.05897Z"
        fill="#434243"
      />
      <path
        d="M6.03589 23.6533C4.19583 24.0408 2.98704 24.8304 2.48561 25.9818C2.0603 26.9258 2.0603 28.021 2.48561 28.9649C3.25267 30.7467 5.72548 31.3209 6.68654 31.4672C6.88503 31.4994 7.04321 31.316 7.02232 31.1005C6.53134 26.1603 10.4368 23.8173 11.4486 23.2786C11.4904 23.2528 11.4993 23.2158 11.4949 23.1917C11.4919 23.1756 11.4755 23.1515 11.4426 23.1483C9.25486 23.1033 6.90592 23.4249 6.03589 23.6533Z"
        fill="#434243"
      />
    </svg>
  );
};

export default IconThreeUsers;
