import { WsCode } from '../../api';
import { ImportPayload } from '../utils';
import { RequestApiPrivate } from './../../api/axios';

const GoalPerformanceApi = {
  list: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.post(
        WsCode.computingManagement.list,
        params
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  listFilterGoal: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.computingManagement.list,
        {
          params
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  listEmployee: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.computingManagement.employees,
        {
          params
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  listWorkspace: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.computingManagement.workspaces,
        {
          params
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  listGoal: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.computingManagement.goals,
        {
          params
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  add: async (payload: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.post(
        WsCode.computingManagement.addGoal,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  detail: async (id: number): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.computingManagement.detail + `/${id}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  update: async (id: number, payload: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.put(
        WsCode.computingManagement.edit + `/${id}`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  delete: async (id: number): Promise<any> => {
    try {
      const response = await RequestApiPrivate.delete(
        WsCode.computingManagement.delete + `/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  downloadTemplate: async (): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.computingManagement.downloadTemplate,

        { responseType: 'blob' }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  exportExcel: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.post(
        WsCode.computingManagement.exportExcel,
        params,
        {responseType: 'blob'}
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  upload: async (payload: FormData): Promise<any> => {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    };
    try {
      const response = await RequestApiPrivate.post(
        WsCode.computingManagement.upload,
        payload,
        config
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  importGoal: async (payload: ImportPayload): Promise<any> => {
    try {
      const response = await RequestApiPrivate.post(
        WsCode.computingManagement.importGoal,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};

export default GoalPerformanceApi;
