import { createSlice } from "@reduxjs/toolkit";
import { filter } from "lodash"
import { RootState } from "../../../../store/store";
import {
  downloadFile,
  initialState,
  mapEmployeeData,
  mappingObjDataTable,
  mappingEmployeeDataTable,
  StatusOperatorEnum,
  getChildIds,
} from "../utils";
import {
  exportExcel,
  getListObjectApprove,
  getListEmployeeApprove,
  getListEmployee,
  getListWorkspace,
  upload,
  deleteFile,
  importResult,
  scoreApprove,
  getListObjectApproveMore,
  getListEmployeeApproveMore,
  getDetailUser,
  updateResultApprove,
  getColors,
} from "./thunk";

export const ResultApproveSlice = createSlice({
  name: "gPerformanceResultApprove",
  initialState,
  reducers: {
    reset: () => initialState,
    updateFilterObjParams: (state, action) => {
      state.filterObjectParams = { ...action.payload };
    },
    updateFilterEmployeeParams: (state, action) => {
      state.filterEmployeeParams = { ...action.payload };
    },
    toggleUploadModal: (state) => {
      state.showUploadModal = !state.showUploadModal;
      state.files = [];
    },
    setFiles: (state, action) => {
      state.files = action.payload;
    },
    setDeleteFile: (state, action) => {
      state.files = action.payload;
    },
    setFileIdUploadSuccess: (state, action) => {
      state.fileIdUploadSuccess = action.payload;
    },
    setSelectedSubjects: (state, action) => {
      state.selectedObjects = action.payload;
    },
    setSelectedEmployees: (state, action) => {
      state.selectedEmployees = action.payload;
    },
    setSelectAllRows: (state, action) => {
      state.selectAllRows = action.payload;
    },
    setIdsAllWorkspaceOfUser: (state, action) => {
      state.idsAllWorkspaceOfUser = action.payload;
    },
    setParentIds: (state, action) => {
      state.parentIds = action.payload;
    },
    setDefaultSelectedKeysFilter: (state, action) => {
      state.defaultSelectedKeysFilter = action.payload;
    },
    setListWorkspaceOfUser: (state, action) => {
      state.listWorkspaceOfUser = action.payload;
    },
    resetColors: (state) => {
      state.colors = [];
    },
    setListObjResultApprove: (state, action) => {
      state.listObjResultApprove = action.payload;
    },
    setListEmployeeResultApprove: (state, action) => {
      state.listEmployeeResultApprove = action.payload;
    },
    setListEmployee: (state, action) => {
      state.listEmployee = action.payload;
    },
    setCurKeyEditing: (state, action) => {      
      state.curKeyEditing = action.payload;
    },
    setInvalidResultMessage: (state, action) => {      
      state.invalidResultMessage = action.payload;
    },
    setIdsWorkspaceUserOwner: (state, action) => {
      state.idsWorkspaceUserOwner = action.payload;
    },
    setHeightFilter: (state, action) => {
      state.heightFilter = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListObjectApprove.pending, (state) => {
        state.loading.list = true;
      })
      .addCase(getListObjectApprove.fulfilled, (state, action) => {
        const { paginate, data } = action.payload;
        state.paginateObject = { ...paginate };
        const mappedData = mappingObjDataTable(data)
        state.listObjResultApprove = mappedData;
        state.listObjResultApproveCaching = mappedData;

        let _keysWorkspaceCanBeApprove: number[] = [];
        if (state.idsWorkspaceUserOwner.length) {
          state.idsWorkspaceUserOwner.forEach((_id) => {
              const idsExcludeIt = getChildIds(_id, state.listOriginWorkspace)?.filter(
              (_item) => !state.idsWorkspaceUserOwner.includes(_item)
            );
            _keysWorkspaceCanBeApprove.push(...idsExcludeIt);
          })
        }
        state.keysWorkspaceCanBeApprove = _keysWorkspaceCanBeApprove

        state.loading.list = false;
      })
      .addCase(getListEmployeeApprove.pending, (state) => {
        state.loading.list = true;
      })

      .addCase(getListEmployeeApprove.fulfilled, (state, action) => {
        const { paginate, data } = action.payload;
        state.paginateEmployee = { ...paginate };
        const mappedData = mappingEmployeeDataTable(data)
        state.listEmployeeResultApprove = mappedData;
        state.listEmployeeResultApproveCaching = mappedData;
        state.loading.list = false;
      })
      .addCase(getListWorkspace.pending, (state) => {
        state.loading.list = true;
        state.loading.workspace = true;
      })
      .addCase(getListWorkspace.fulfilled, (state, action) => {
        state.listOriginWorkspace = [action.payload.data?.[0]];
        state.loading.workspace = false;
      })
      .addCase(getListEmployee.pending, (state, action) => {
        state.loading.employee = true;
        if (action.meta?.arg?.initFetching) {
          state.loading.list = true;
        }
      })
      .addCase(getListEmployee.fulfilled, (state, action) => {
        state.listEmployee = mapEmployeeData(action.payload.data);
        state.loading.employee = false;
      })
      .addCase(exportExcel.fulfilled, (state, action) => {
        downloadFile(action.payload);
      })
      .addCase(upload.pending, (state, action) => {
        state.loading.upload = true;
      })
      .addCase(upload.rejected, (state, action) => {
        state.loading.upload = false;
      })

      .addCase(deleteFile.pending, (state, action) => {
        state.loading.details = true;
      })
      .addCase(deleteFile.rejected, (state, action) => {
        state.loading.upload = false;
      })
      .addCase(deleteFile.fulfilled, (state, action) => {
        state.fileIdUploadSuccess = filter(state.fileIdUploadSuccess, (item)=> item.id !== action?.meta?.arg?.id);
        state.loading.upload = false;
      })
      .addCase(upload.fulfilled, (state, action) => {
        state.fileIdUploadSuccess = [
          ...state.fileIdUploadSuccess,
          action.payload?.data
        ];
        state.loading.upload = false;
      })
      .addCase(importResult.pending, (state, action) => {
        state.loading.import = true;
      })
      .addCase(importResult.fulfilled, (state, action) => {
        state.showUploadModal = false;
        state.loading.import = false;
        state.loading.upload = false;
        state.fileIdUploadSuccess = [];
      })
      .addCase(getListObjectApproveMore.pending, (state) => {
        state.loading.objectLoadMore = true;
      })
      .addCase(getListObjectApproveMore.fulfilled, (state, action) => {
        const { paginate, data } = action.payload;
        state.paginateObject = { ...paginate };
        const _currentList = [...state.listObjResultApprove];
        const _listMore = mappingObjDataTable([...data]);
        const _list = [..._currentList, ..._listMore];
        state.listObjResultApprove = _list.map((_item: any, index: number) => ({
          ..._item,
          index: index + 1
        }));
        let _keysWorkspaceCanBeApprove: number[] = [];
        if (state.idsWorkspaceUserOwner.length) {
          state.idsWorkspaceUserOwner.forEach((_id) => {
              const idsExcludeIt = getChildIds(_id, state.listOriginWorkspace)?.filter(
              (_item) => !state.idsWorkspaceUserOwner.includes(_item)
            );
            _keysWorkspaceCanBeApprove.push(...idsExcludeIt);
          })
        }
        state.keysWorkspaceCanBeApprove = [...state.keysWorkspaceCanBeApprove, ..._keysWorkspaceCanBeApprove]
        state.listObjResultApproveCaching = [..._list]
        state.loading.objectLoadMore = false;
      })
      .addCase(getListEmployeeApproveMore.pending, (state) => {
        state.loading.employeeLoadMore = true;
      })
      .addCase(getListEmployeeApproveMore.fulfilled, (state, action) => {
        const { paginate, data } = action.payload;
        state.paginateEmployee = { ...paginate };
        const _currentList = [...state.listEmployeeResultApprove];
        const _listMore = mappingEmployeeDataTable([...data]);
        const _list = [..._currentList, ..._listMore];
        state.listEmployeeResultApprove = _list.map((_item: any, index: number) => ({
          ..._item,
          index: index + 1
        }));
        state.listEmployeeResultApproveCaching = [..._list]
        state.loading.employeeLoadMore = false;
      })
      .addCase(getDetailUser.pending, (state, action) => {
        state.loading.detailUser = true;
        state.detailUser = action.payload;
      })
      .addCase(getDetailUser.fulfilled, (state, action) => {
        state.detailUser = action.payload.data;
        state.loading.detailUser = false;
      })
      .addCase(updateResultApprove.pending, (state, action) => {
        state.loading.list = true;
      })
      .addCase(updateResultApprove.fulfilled, (state, action) => {
        state.loading.list = false;
      })
      .addCase(getColors.fulfilled, (state, action) => {
        state.colors = action.payload.data;
      })
      .addCase(scoreApprove.pending, (state) => {
        state.loading.scoreApprove = true;
      })
      .addCase(scoreApprove.fulfilled, (state) => {
        state.loading.scoreApprove = false;
        state.showUploadModal = !state.showUploadModal;
        state.selectedObjects = [];
        state.selectedEmployees = [];

      })
  },
});

export const {
  updateFilterObjParams,
  updateFilterEmployeeParams,
  reset,
  setFileIdUploadSuccess,
  setSelectedSubjects,
  setSelectedEmployees,
  setFiles,
  setDeleteFile,
  toggleUploadModal,
  setSelectAllRows,
  setIdsAllWorkspaceOfUser,
  setParentIds,
  setDefaultSelectedKeysFilter,
  setListWorkspaceOfUser,
  resetColors,
  setListObjResultApprove,
  setListEmployeeResultApprove,
  setListEmployee,
  setCurKeyEditing,
  setInvalidResultMessage,
  setIdsWorkspaceUserOwner,
  setHeightFilter
} = ResultApproveSlice.actions;

export const gPerformanceResultApprove = (state: RootState) =>
  state.gPerformanceResultApprove;

export default ResultApproveSlice.reducer;
