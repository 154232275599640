import moment from "moment";
import { GperformanceDashboardSlice, TabsDashboard } from "./interface";
import i18n from '../../../../i18n';

export enum STATUS {
  Active = 1,
  Inactive,
}

export enum ACTION {
  DetailUser = "detailUser",
  DetailWorkspace = "detailWorkspace",
  DetailCeo = "detailCeo",
}

export enum TOAST_TYPE {
  Success = "success",
  Error = "error",
  Warning = "warning",
}

export enum TAB_DASHBOARD {
  Department = 1,
  Personal,
}

export enum COLOR_GRAPH_COLUMNN {
  Green = "#00A907",
  Yellow = "#F0B71C",
  Red = "#D94D27",
}

export enum PERCENT_LEVEL {
  High = 80,
  Mid = 40,
  Low = 20,
}

const MONTH_NOW = new Date().getMonth() + 1
const OFFSET_MONTH = 1
const MONTH_DEFAULT_SELECT = MONTH_NOW - OFFSET_MONTH <= 0 ? 12 - Math.abs(MONTH_NOW - OFFSET_MONTH) : MONTH_NOW - OFFSET_MONTH
const YEAR_NOW = new Date().getFullYear();
const YEAR_DEFAULT_SELECT = MONTH_NOW - OFFSET_MONTH <= 0 ? YEAR_NOW - 1 : YEAR_NOW

export const DEFAULT_PARAMS = {
  month: MONTH_DEFAULT_SELECT,
  year: YEAR_DEFAULT_SELECT,
  targets: [
    "monthlyPerformance",
    "yearlyPerformance",
    "goalPerformanceGrouped",
    "subodinatePerformances",
  ],
};
export const MONTHS: string[] = [
  ...Array.from({ length: 12 }, (_, i) => ("0" + (i + 1)).slice(-2)),
];
const offsetYear = 5;
export const YEARS: number[] = [
  ...Array.from(
    { length: offsetYear * 1 + 1 },
    (_, i) => new Date().getFullYear() - offsetYear + i
  ),
];

export const MONTH_YEAR_OPTIONS = MONTHS.map((month) => ({
  label: month + "/" + new Date().getFullYear(),
  value: month,
}));

export const MONTH_OPTIONS = MONTHS.map((_year) => ({
  label: _year,
  value: Number(_year),
}));

export const YEAR_OPTIONS = YEARS.map((_year) => ({
  label: _year,
  value: _year,
}));

const TAB_CONTROL: TabsDashboard[] = [
  {
    key: TAB_DASHBOARD.Department,
    label: i18n.t("gperformance:dashboardGperformance.tabControl.department"),
    active: true,
  },
  {
    key: TAB_DASHBOARD.Personal,
    label: i18n.t("gperformance:dashboardGperformance.tabControl.personal"),
    active: false,
  },
];

export const initialState: GperformanceDashboardSlice = {
  list: [],
  listPerformanceUser: [],
  listGoal: [],
  dataChart: { row: { value: 0, color: ''}, column: [] },
  title: "",
  listPerformanceEmployee: [],
  paginate: {
    total: 0,
    current_page: 0,
    last_page: 0,
  },
  loading: { loadMore: false, list: false, detail: false, overView: false },
  monthView: null,
  status: "idle",
  showDetail: false,
  filterParams: { ...DEFAULT_PARAMS },
  action: { id: null, name: null },
  param: { status: STATUS.Active },
  resetFormFilter: false,
  cachingFetchList: false,
  dashboardMain: [],
  monthPerformance: {
    color: '#000000',
    id: null,
    current_rank: null,
    performance_value: null
  },
  tabControl: TAB_CONTROL,
  yearCached: null,
  yearDetailCached: null,
};

export const NUMBER_REGEX = /^[0-9]\d*$/;
export const NUMBER_DECIMAL_REGEX = /^(0|[1-9]\d*)(\.\d{1,2})?$/;

export const DEFAULT_ROW_SHOW_TABLE = 5;
export const TARGET_LEVEL_REGION = 2;
export const TARGET_DESCENDANT_LEVEL = 4;
export const REGION_LEVEL = 2;
export const DEPARTMENT_LEVEL = 4;
export const GROUP = "group";
export const REGION = "region";
