import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  convertOKRsPersonal,
  mergeSubItemWithPrevOKRs,
} from "../helpers/convert";
import { RootState } from "../../../../store/store";
import {
  requestGetKRs,
  requestGetSubOKRs,
  requestGetTasks,
  requestPersonalOKRs,
} from "../common/request";
const initialState = {
  okr: [],
  loading: false,
  status: "idle",
};

export const getPersonalOKRs = createAsyncThunk(
  "role/staff/personal/getOKRs",
  requestPersonalOKRs
);

export const getSubOKRs = createAsyncThunk(
  "role/staff/personal/getSubOKRs",
  requestGetSubOKRs
);

export const getKRs = createAsyncThunk(
  "role/staff/personal/getKRs",
  requestGetKRs
);

export const getTasks = createAsyncThunk(
  "role/staff/personal/getTasks",
  requestGetTasks
);

const staffSlice = createSlice({
  name: "okr",
  initialState,
  reducers: {
    _onCleanUpOKRs(state) {
      state.okr = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPersonalOKRs.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getPersonalOKRs.fulfilled, (state, action) => {
        state.status = "success";
        const payload: any = action.payload;
        state.okr = convertOKRsPersonal(payload);
        state.loading = false;
      })
      .addCase(getPersonalOKRs.rejected, (state) => {
        state.status = "failed";
        state.loading = false;
      })
      .addCase(getSubOKRs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSubOKRs.fulfilled, (state, action) => {
        state.status = "success";
        const payload: any = action.payload;
        state.okr = mergeSubItemWithPrevOKRs(
          {
            level: payload.level,
            parentId: payload.parentId,
            keyValue: payload.keyValue,
            response: payload.response.data,
            parentUUID: payload.parentUUID,
          },
          state.okr
        );
      })
      .addCase(getSubOKRs.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getKRs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getKRs.fulfilled, (state, action) => {
        state.status = "success";
        const payload: any = action.payload;
        state.okr = mergeSubItemWithPrevOKRs(
          {
            level: payload.level,
            parentId: payload.parentId,
            keyValue: payload.keyValue,
            response: payload.response.data,
            parentUUID: payload.parentUUID,
          },
          state.okr
        );
      })
      .addCase(getKRs.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getTasks.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTasks.fulfilled, (state, action) => {
        state.status = "success";
        const payload: any = action.payload;
        state.okr = mergeSubItemWithPrevOKRs(
          {
            level: payload.level,
            parentId: payload.parentId,
            keyValue: payload.keyValue,
            response: payload.response.data,
            parentUUID: payload.parentUUID,
          },
          state.okr
        );
      })
      .addCase(getTasks.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export default staffSlice.reducer;
export const { _onCleanUpOKRs } = staffSlice.actions;
export const selectorOKRs = (state: RootState) => state.staff.okr;
export const selectorLoadingOKRs = (state: RootState) => state.staff.loading;
