import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store/store";
import { customListWorkspaces } from "../../g-process/component/helper/Helper";
import { getJobTitlesFromWorkspaceId } from "../../../components/GroupOrganizationTreeSelect/helper";
import { AccountApi } from "../../../api/AccountApi";

export type DepartmentType = {
  id: number;
  name: string;
  status?: number;
};

export type GroupUsersType = {
  id: number;
  name: string;
};

export type JobTitleType = {
  id: number;
  name: string;
};

export type JobTitleShortType = { id: number; name: string };

export type RoleType = {
  code: string;
  created_at: string;
  creator: string | null;
  id: number;
  level: number;
  modifier: string | number | null;
  name: string;
  status: number;
  updated_at: string | number;
};

export type User = {
  id: number;
  employee_id: number;
  user_name: string;
  full_name: string;
  email: string;
  name?: string;
  workspace: {
    id: number;
  };
  status: number;
};

type Role = {
  code: string;
  created_at: string;
  creator: string | null;
  id: number;
  level: number;
  modifier: string | number | null;
  name: string;
  status: number;
  updated_at: string | number;
};

// Member of group
export type Member = {
  id: number;
  user_name: string;
  name: string | null;
  full_name: string;
};

export type Group = {
  id: number;
  name: string;
  email: string | null;
  status: number;
  member: Member[];
  leader: Member[];
};

type Owner = {
  id: number;
  user_name: string;
  full_name: string;
};

export type Workspace = {
  id: number;
  level: number;
  name: string;
  title?: string;
  value?: string;
  key?: number;
  code?: string;
  children: Workspace[];
  status?: number;
  owner: Owner;
  type?: {
    code: string;
  };
};
export type StepTransferConditionType = {
  created_at: string;
  updated_at: string;
  group_condition_id: null | number;
  id: number;
  next_step_type: number;
  step_condition: number;
  step_id: number;
  step_order: number;
};
export type StepTransferConditionsType = StepTransferConditionType[];

type metaInfoState = {
  departments: DepartmentType[];
  users: User[];
  fetchUsersAndGroupsStatus: "idle" | "loading" | "succeeded" | "failed";
  okrDensityAllocate: any[];
  roles: Role[];
  titles: JobTitleType[];
  groups: Group[];
  status: "idle" | "loading" | "succeeded" | "failed";
  workspaces: Workspace[];
};

const initialState: metaInfoState = {
  departments: [],
  users: [],
  fetchUsersAndGroupsStatus: "idle",
  okrDensityAllocate: [],
  roles: [],
  titles: [],
  groups: [],
  status: "idle",
  workspaces: [],
};

export const fetchAllUsersAndGroups = createAsyncThunk(
  "meta-info/fetchAllUsersAndGroups",
  async (): Promise<[User[], Group[]]> => {
    try {
      const [usersRes, groupsRes] = await Promise.all([
        AccountApi.getAllUser(),
        AccountApi.getListGroups(),
      ]);
      return [usersRes.data, groupsRes.data];
    } catch (error) {
      // Handle error if needed
      throw error;
    }
  }
);

const metaInfoSlice = createSlice({
  name: "metaInfo",
  initialState,
  reducers: {
    setOkrDensityAllocate: (state, action) => {
      state.okrDensityAllocate = action.payload;
    },
    setMetaInfoAfterLogin: (state, action) => {
      const { roles, workspaces } = action.payload;
      state.roles = roles;
      state.workspaces = workspaces;
      state.departments = customListWorkspaces(workspaces);
      state.titles = getJobTitlesFromWorkspaceId(workspaces, 1);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllUsersAndGroups.fulfilled, (state, action) => {
        const [users, groups] = action.payload;
        state.users = users;
        state.groups = groups;
        state.fetchUsersAndGroupsStatus = "succeeded";
      })
      .addCase(fetchAllUsersAndGroups.pending, (state) => {
        state.fetchUsersAndGroupsStatus = "loading";
      })
      .addCase(fetchAllUsersAndGroups.rejected, (state) => {
        state.fetchUsersAndGroupsStatus = "failed";
      });
  },
});

export default metaInfoSlice.reducer;

export const { setOkrDensityAllocate, setMetaInfoAfterLogin } =
  metaInfoSlice.actions;

export const selectDepartments = (state: RootState) =>
  state.metaInfo.departments;
export const selectGroups = (state: RootState) => state.metaInfo.groups;
export const selectRoles = (state: RootState) => state.metaInfo.roles;
export const selectUsers = (state: RootState) => state.metaInfo.users;
export const selectFetchUsersAndGroupsStatus = (state: RootState) =>
  state.metaInfo.fetchUsersAndGroupsStatus;
export const selectTitles = (state: RootState) => state.metaInfo.titles;
export const selectWorkspaces = (state: RootState) => state.metaInfo.workspaces;

export const selectOkrDensityAllocate = (state: RootState) =>
  state.metaInfo.okrDensityAllocate;
export const selectMetaInfoStatus = (state: RootState) => state.metaInfo.status;
