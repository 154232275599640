/* eslint-disable array-callback-return */
import {
  KEY_STATUS_PROCESS,
  KEY_STATUS_TICKET,
} from "../features/g-process/component/helper/constant";
import {
  getTextProcessStatus,
  getTextTicketStatus,
} from "../features/g-process/component/helper/Helper";
import TranslateSSC from "../features/g-process/component/helper/Translate";
import ProcessManagementApi from "../features/g-process/process-managements/api/Process-management";
import {
  DEFAULT_CALL_API,
  ENUMS_KEY_STATUS_HISTORY,
  StatusGProcess,
  TYPE_OBJECT,
  URL_REJECT_AFTER_LOGIN,
} from "./constant";
import { checkArray } from "./helper";
import style from "./function.module.scss";
import { mapLabelSelectedByIndex } from "../features/g-process/helpers/helpers";
import {
  DepartmentType,
  GroupUsersType,
  JobTitleType,
  RoleType,
  User,
} from "../features/meta-info/redux/metaInfoSlice";

//status process
export function retunrStatus(key: any) {
  switch (key) {
    case KEY_STATUS_PROCESS.ACTIVED: {
      return (
        <div className="success status-container status-opacity center">
          {getTextProcessStatus(KEY_STATUS_PROCESS.ACTIVED)}
        </div>
      );
    }
    case KEY_STATUS_PROCESS.WAITING: {
      return (
        <div className="waiting status-container status-opacity center">
          {getTextProcessStatus(KEY_STATUS_PROCESS.WAITING)}
        </div>
      );
    }
    case KEY_STATUS_PROCESS.EXPRIE: {
      return (
        <div className="error status-container status-opacity center">
          {getTextProcessStatus(KEY_STATUS_PROCESS.EXPRIE)}
        </div>
      );
    }
    case KEY_STATUS_PROCESS.EXSIT: {
      return (
        <div className="success status-container status-opacity center">
          {getTextProcessStatus(KEY_STATUS_PROCESS.EXSIT)}
        </div>
      );
    }
    case KEY_STATUS_PROCESS.DRAFF: {
      return (
        <div className="waiting status-container status-opacity center">
          {getTextProcessStatus(KEY_STATUS_PROCESS.DRAFF)}
        </div>
      );
    }

    default:
      break;
  }
}
//status task
export function renderStatusTask(key: number, autoMission = false) {
  switch (key) {
    case 1: {
      return (
        <div
          className={`waiting status-container status-opacity center ${
            autoMission && "status-automission"
          }`}
        >
          <TranslateSSC
            value={"in_progress"}
            keys={"process-managements"}
            main="gprocess"
          />
        </div>
      );
    }
    case 2: {
      return (
        <div
          className={`success status-container status-opacity center ${
            autoMission && "status-automission"
          }`}
        >
          <TranslateSSC
            value={"finish"}
            keys={"process-managements"}
            main="gprocess"
          />
        </div>
      );
    }
    case 3: {
      return (
        <div
          className={`closed status-container status-opacity center ${
            autoMission && "status-automission"
          }`}
        >
          <TranslateSSC
            value={"cancelClose"}
            keys={"process-managements"}
            main="gprocess"
          />
        </div>
      );
    }
    case 4: {
      return (
        <div
          className={`cancel status-container status-opacity center ${
            autoMission && "status-automission"
          }`}
        >
          <TranslateSSC
            value={"labelCancel"}
            keys={"process-managements"}
            main="gprocess"
          />
        </div>
      );
    }

    default:
      break;
  }
}

export function returnStatusMission(key: any) {
  switch (key) {
    case KEY_STATUS_TICKET.DRAFF: {
      return (
        <div
          className={`waiting status-container status-opacity center ${style.button}`}
        >
          {getTextTicketStatus(key)}
        </div>
      );
    }
    case KEY_STATUS_TICKET.INPROCESS: {
      return (
        <div
          className={` waiting status-container status-opacity center ${style.button}`}
        >
          {getTextTicketStatus(key)}
        </div>
      );
    }
    case KEY_STATUS_TICKET.COMPLETED: {
      return (
        <div
          className={` success status-container status-opacity center ${style.button}`}
        >
          {getTextTicketStatus(key)}
        </div>
      );
    }
    case KEY_STATUS_TICKET.UN_APPROVAL: {
      return (
        <div
          className={` error status-container status-opacity center ${style.button}`}
        >
          {getTextTicketStatus(key)}
        </div>
      );
    }

    default:
      break;
  }
}

export function returnStatusProgress(value: any) {
  if (value === 2) {
    return (
      <div className="success status-container status-opacity center">
        Hoàn thành
      </div>
    );
  } else if (value === 0) {
    return (
      <div className="waiting status-container status-opacity center">
        Đang thực hiện
      </div>
    );
  } else if (value === 2) {
    return (
      <div className="error status-container status-opacity center">
        Không duyệt
      </div>
    );
  }
}
export function returnStatusProgressButton(value: any) {
  if (value === KEY_STATUS_PROCESS.DEFAULT) {
    return (
      <div className="waiting status-container status-opacity center">
        <TranslateSSC
          value={"waiting"}
          keys={"process_approval"}
          main="gprocess"
        />
      </div>
    );
  } else if (value === KEY_STATUS_PROCESS.DRAFF) {
    return (
      <div className=" success status-container status-opacity center">
        <TranslateSSC
          value={"finish"}
          keys={"process_approval"}
          main="gprocess"
        />
      </div>
    );
  } else if (value === KEY_STATUS_PROCESS.WAITING) {
    return (
      <div className="error status-container status-opacity center">
        <TranslateSSC
          value={"refuse"}
          keys={"process_approval"}
          main="gprocess"
        />
      </div>
    );
  }
}

export function returnStatusMyMission(value: any) {
  if (value === 1) {
    return (
      <div className="success status-container status-opacity center">
        Đã hoàn thành
      </div>
    );
  } else if (value === 2) {
    return (
      <div className="waiting status-container status-opacity center">
        Đang triển khai
      </div>
    );
  } else if (value === 3) {
    return (
      <div className="error status-container status-opacity center">
        Đã đóng
      </div>
    );
  }
}

export const randString = (size: number) => {
  let result = "";
  let key = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz01234";
  let length = key.length;
  for (let i = 0; i < size; i++) {
    result += key.charAt(Math.floor(Math.random() * length));
  }
  return result;
};

export const EXPORT_STRING = (number: number, key: any) => {
  let text = "";
  for (let index = 0; index < Number(number); index++) {
    text = text.concat(" " + key);
  }
  return text;
};

export const KEYPRESS_NUMBER = (evt: any) => {
  let theEvent = evt || window.event;
  let key;

  // Handle paste
  if (theEvent.type === "paste") {
    key = evt.clipboardData.getData("text/plain");
  } else {
    // Handle key press
    key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
  }
  let regex = /[0-9]/;
  if (!regex.test(key)) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
  }
};

export const preventTypingPlusMinus = (evt: any) => {
  if (evt.key === "-" || evt.key === "+") evt.preventDefault();
};

export const preventPastePlusMinus = (evt: any) => {
  const content = evt.clipboardData.getData("text/plain");
  if (content?.includes("-") || content?.includes("+")) evt.preventDefault();
};

export const transformNumberValue = (
  fieldName: string,
  value: string,
  executeFunction: any,
  regex: any = /^[0-9]*\.?[0-9]*$/
) => {
  if (regex.test(value)) executeFunction?.(fieldName, value);
};

export const getSLAName = (value: any, listSLA: any[]) => {
  if (!value || !listSLA || listSLA?.length === 0) return;
  const temp = listSLA?.find((it: any) => it.id === value);
  return temp?.name;
}

export const returnStatusHistory = ({
  key,
  name,
  style,
  task,
}: {
  key: GenerateValueUnion<typeof ENUMS_KEY_STATUS_HISTORY> | undefined;
  name: string;
  style: { readonly [key: string]: string };
  task?: boolean;
}) => {
  if (key === ENUMS_KEY_STATUS_HISTORY.DRAFF) {
    return (
      <>
        <span className={style.title}>
          <TranslateSSC
            main="gprocess"
            keys="process-managements"
            value="draff"
          />
        </span>{" "}
        <span className={style.note + " " + style.fonts}>
          {" "}
          <TranslateSSC main="gprocess" keys="process-managements" value="by" />
        </span>{" "}
        <span className={style.name}>{name}</span>
      </>
    );
  } else if (key === ENUMS_KEY_STATUS_HISTORY.SEND_APPROVAL) {
    return (
      <>
        <span className={style.title}>
          {task ? (
            <TranslateSSC
              main="gprocess"
              keys="process-managements"
              value="redirect"
            />
          ) : (
            <TranslateSSC
              main="gprocess"
              keys="process-managements"
              value="sendApproval"
            />
          )}
        </span>{" "}
        <span className={style.note + " " + style.fonts}>
          {" "}
          <TranslateSSC main="gprocess" keys="process-managements" value="by" />
        </span>{" "}
        <span className={style.name}>{name}</span>
      </>
    );
  } else if (key === ENUMS_KEY_STATUS_HISTORY.REDIRECT) {
    return (
      <>
        <span className={style.title}>
          <TranslateSSC
            main="gprocess"
            keys="process-managements"
            value="sendApproval"
          />
        </span>{" "}
        <span className={style.note + " " + style.fonts}>
          <TranslateSSC main="gprocess" keys="process-managements" value="by" />
        </span>
        <span className={style.name}>{name}</span>
      </>
    );
  } else if (key === ENUMS_KEY_STATUS_HISTORY.APPROVAL) {
    return (
      <>
        <span className={style.title}>
          <TranslateSSC
            main="gprocess"
            keys="process-managements"
            value="approves"
          />
        </span>{" "}
        <span className={style.note + " " + style.fonts}>
          <TranslateSSC main="gprocess" keys="process-managements" value="by" />
        </span>{" "}
        <span className={style.name}>{name}</span>
      </>
    );
  } else if (key === ENUMS_KEY_STATUS_HISTORY.ACTIVE) {
    return (
      <>
        <span className={style.title}>
          <TranslateSSC
            main="gprocess"
            keys="process-managements"
            value="activated"
          />
        </span>{" "}
        <span className={style.note + " " + style.fonts}>
          <TranslateSSC main="gprocess" keys="process-managements" value="by" />
        </span>{" "}
        <TranslateSSC
          value={"system"}
          keys={"process-managements"}
          main="gprocess"
        />
      </>
    );
  } else if (key === ENUMS_KEY_STATUS_HISTORY.COPY) {
    return (
      <>
        <span className={style.title}>Copy</span>{" "}
        <span className={style.note + " " + style.fonts}>
          <TranslateSSC main="gprocess" keys="process-managements" value="by" />
        </span>{" "}
        <span className={style.name}>{name}</span>
      </>
    );
  } else if (key === ENUMS_KEY_STATUS_HISTORY.VERSION) {
    return (
      <>
        <span className={style.title}>
          <TranslateSSC
            main="gprocess"
            keys="process-managements"
            value="createVersionNew"
          />
        </span>{" "}
        <span className={style.note + " " + style.fonts}>
          <TranslateSSC main="gprocess" keys="process-managements" value="by" />
        </span>
        <span className={style.name}>
          {name}
          {", "}
        </span>
        <span className={style.title}>
          <TranslateSSC
            main="gprocess"
            keys="process-managements"
            value="codeNewVersion"
          />
        </span>{" "}
        <span className={style.name}>{task}</span>
      </>
    );
  } else if (key === ENUMS_KEY_STATUS_HISTORY.INACTIVE) {
    return (
      <>
        <span className={style.title}>
          <TranslateSSC
            value={"cancelApprover"}
            keys={"process-managements"}
            main="gprocess"
          />
        </span>{" "}
        <span className={style.note + " " + style.fonts}>
          <TranslateSSC
            value={"by"}
            keys={"process-managements"}
            main="gprocess"
          />
        </span>{" "}
        <span className={`${style.title} ${style.name}`}>
          {key ? (
            name
          ) : (
            <TranslateSSC
              value={"system"}
              keys={"process-managements"}
              main="gprocess"
            />
          )}
        </span>
      </>
    );
  } else if (key === ENUMS_KEY_STATUS_HISTORY.CANCEL) {
    return (
      <>
        <span className={style.title}>
          <TranslateSSC
            value={"cancelActive"}
            keys={"process-managements"}
            main="gprocess"
          />
        </span>{" "}
        <span className={style.note + " " + style.fonts}>
          <TranslateSSC
            value={"by"}
            keys={"process-managements"}
            main="gprocess"
          />
        </span>
        <span className={style.title}>
          <TranslateSSC
            value={"system"}
            keys={"process-managements"}
            main="gprocess"
          />
        </span>{" "}
      </>
    );
  } else if (key === ENUMS_KEY_STATUS_HISTORY.COMPLETED) {
    return (
      <>
        <span className={style.title}>
          <TranslateSSC
            main="gprocess"
            keys="process-managements"
            value="success"
          />
        </span>{" "}
        <span className={style.note + " " + style.fonts}>
          {" "}
          <TranslateSSC main="gprocess" keys="process-managements" value="by" />
        </span>{" "}
        <span className={style.name}>{name}</span>
      </>
    );
  }
};
export const returnStatusHistoryState = (key: any) => {
  if (key === ENUMS_KEY_STATUS_HISTORY.DRAFF) {
    return 1;
  } else if (key === ENUMS_KEY_STATUS_HISTORY.SEND_APPROVAL) {
    return 2;
  } else if (key === ENUMS_KEY_STATUS_HISTORY.REDIRECT) {
    return 3;
  } else if (key === ENUMS_KEY_STATUS_HISTORY.APPROVAL) {
    return 4;
  } else if (key === ENUMS_KEY_STATUS_HISTORY.ACTIVE) {
    return 5;
  } else if (key === ENUMS_KEY_STATUS_HISTORY.COPY) {
    return 6;
  } else if (key === ENUMS_KEY_STATUS_HISTORY.VERSION) {
    return 7;
  } else if (key === ENUMS_KEY_STATUS_HISTORY.INACTIVE) {
    return 8;
  } else if (key === ENUMS_KEY_STATUS_HISTORY.CANCEL) {
    return 13;
  }
};

export const returnStatusProgressState = (key: any) => {
  if (key === 0) {
    return 14;
  } else if (key === 1) {
    return 10;
  } else if (key === 2) {
    return 11;
  }
};
export const returnStatusProgressState3 = (key: any) => {
  if (key === 0) {
    return 1;
  } else if (key === 1) {
    return 3;
  } else if (key === 2) {
    return 5;
  } else if (key === 3) {
    return 8;
  } else if (key === 4) {
    return 5;
  }
};
export const returnStatusProgressState2 = (key: any) => {
  if (key === 0) {
    return 0;
  } else if (key === 1) {
    return 7;
  } else if (key === 2) {
    return 5;
  } else if (key === 3) {
    return 1;
  } else if (key === 4) {
    return 9;
  }
};

export const returnStatusProgressStateS = (
  key: any,
  style: any,
  index: number
) => {
  const step = (
    <TranslateSSC value={"step"} keys={"process_approval"} main="gprocess" />
  );
  if (key === KEY_STATUS_PROCESS.DEFAULT) {
    return (
      <>
        <span className={style.title}>
          {step}
          {`${index} -`}
          <TranslateSSC
            value={"step_1"}
            keys={"process_approval"}
            main="gprocess"
          />
        </span>
      </>
    );
  } else if (key === KEY_STATUS_PROCESS.DRAFF) {
    return (
      <>
        <span className={style.title}>
          {step}
          {` ${index} -`}
          <TranslateSSC
            value={"step_2"}
            keys={"process_approval"}
            main="gprocess"
          />
        </span>
      </>
    );
  } else if (
    key === KEY_STATUS_PROCESS.WAITING ||
    key === KEY_STATUS_PROCESS.ACTIVED
  ) {
    return (
      <>
        <span className={style.title}>
          {step}
          {` ${index} -`}
          <TranslateSSC
            value={"step_3"}
            keys={"process_approval"}
            main="gprocess"
          />
        </span>
      </>
    );
  }
};

export const checkNoEmpty = (VALUE: any, RENDER: any) => {
  if (VALUE === 0 && VALUE !== undefined && VALUE !== null) {
    return VALUE;
  }
  if (VALUE && VALUE !== undefined && VALUE !== null) {
    return VALUE;
  } else {
    return RENDER;
  }
};

export const changeMapObject = ({
  source,
  users,
  departments,
  groupUsers,
  roles,
  jobTitles,
}: {
  source: any[];
  users: User[];
  departments: GroupUsersType[];
  groupUsers: GroupUsersType[];
  roles: RoleType[];
  jobTitles: JobTitleType[];
}) => {
  let arrObj: any[] = [];
  if (source && source.length) {
    source.forEach((val: any) => {
      let result = arrObj.find(
        (item: any) => item.display_order === val.display_order
      );
      if (result) {
        result.array.push({
          ...val,
          object_name: mapLabelSelectedByIndex({
            type: val.object_type,
            id: val.object_id,
            users,
            departments,
            groupUsers,
            roles,
            jobTitles,
            objectPosition: val.object_position,
          }),
        });
      } else {
        arrObj.push({
          type: val.object_type,
          array: [
            {
              ...val,
              object_name: mapLabelSelectedByIndex({
                type: val.object_type,
                id: val.object_id,
                users,
                departments,
                groupUsers,
                roles,
                jobTitles,
                objectPosition: val.object_position,
              }),
            },
          ],
          display_order: val.display_order,
        });
      }
    });
    return Object.values(arrObj).map((item: any) => ({
      ...item,
      id: randString(5),
    }));
  } else {
    return arrObj;
  }
};

export const changeMapObjectOfChildProcess = ({
  source,
  isSameChildProcessID,
  users,
  departments,
  groupUsers,
  roles,
  jobTitles,
}: {
  source: any;
  isSameChildProcessID: boolean;
  users: User[];
  departments: DepartmentType[];
  groupUsers: GroupUsersType[];
  roles: RoleType[];
  jobTitles: JobTitleType[];
}) => {
  let arrObj: any[] = [];
  if (source && source.length) {
    source.forEach((val: any) => {
      let result = arrObj.find(
        (item: any) => item.display_order === val.display_order
      );
      const _val = { ...val };
      if (!isSameChildProcessID) {
        _val.id = null;
      }
      if (result) {
        result.array.push({
          ..._val,
          object_name: mapLabelSelectedByIndex({
            type: val.object_type,
            id: val.object_id,
            users,
            departments,
            groupUsers,
            roles,
            jobTitles,
            objectPosition: val.object_position,
          }),
        });
      } else {
        arrObj.push({
          type: _val.object_type,
          array: [
            {
              ..._val,
              object_name: mapLabelSelectedByIndex({
                type: val.object_type,
                id: val.object_id,
                users,
                departments,
                groupUsers,
                roles,
                jobTitles,
                objectPosition: val.object_position,
              }),
            },
          ],
          display_order: _val.display_order,
        });
      }
    });
    return Object.values(arrObj).map((item: any) => ({
      ...item,
      id: randString(5),
    }));
  } else {
    return arrObj;
  }
};

export const closeMap = (ARRAY: any, ARRAY2: any) => {
  let newArray: any = [];
  ARRAY.forEach((val: any) => {
    if (ARRAY2.filter((vaz: any) => vaz.type === val.id).length) {
    } else {
      newArray = newArray.concat([{ ...val }]);
    }
  });
  return newArray;
};

export const stepOrder = (ARRAY: any, key: string = "processSteps") => {
  let newArray: any = ARRAY;
  newArray[key] = newArray[key]?.sort(function (a: any, b: any) {
    return Number(a.step_order) - Number(b.step_order);
  });
  return { ...newArray };
};

export const LEVEL = (ARRAY: any) => {
  let newArray: any = ARRAY;
  newArray.processSteps = newArray.processSteps.sort(function (a: any, b: any) {
    return Number(a.step_order) - Number(b.step_order);
  });
  return { ...newArray };
};

export const arrayFile = (ARRAY: any) => {
  let newArray: any = [];
  if (ARRAY && ARRAY.length) {
    ARRAY.forEach((val: any) => {
      if (val) {
        newArray = newArray.concat([val.id]);
      }
    });
  }

  return newArray;
};

export const CHECKTRANFERNOEMPTY = (ARRAY: any, length: any, index: any) => {
  let quire = false;
  let messenger = "";
  if (
    ARRAY &&
    ARRAY.filter(
      (val: any) => val.step_order === null && val.next_step_type !== 3
    ) &&
    ARRAY.filter(
      (val: any) => val.step_order === null && val.next_step_type !== 3
    ).length > 0 &&
    length - 1 !== index
  ) {
    messenger = "Các bước chuyển đến không được để trống.";
    quire = true;
  }
  if (
    ARRAY &&
    ARRAY.filter((val: any) => val.next_step_type === null) &&
    ARRAY.filter((val: any) => val.next_step_type === null).length > 0 &&
    length - 1 !== index
  ) {
    messenger = "Các bước chuyển đến không được để trống.";

    quire = true;
  }
  if (
    ARRAY &&
    ARRAY.filter(
      (val: any) => val.next_step_type === 2 && !val.group_step_order
    ) &&
    ARRAY.filter(
      (val: any) => val.next_step_type === 2 && !val.group_step_order
    ).length > 0 &&
    length - 1 !== index
  ) {
    messenger = "Các bước chuyển đến không được để trống.";

    quire = true;
  }
  if (
    ARRAY &&
    ARRAY.filter(
      (val: any) => val.next_step_type === 2 && !val.group_step_condition
    ) &&
    ARRAY.filter(
      (val: any) => val.next_step_type === 2 && !val.group_step_condition
    ).length > 0 &&
    length - 1 !== index
  ) {
    messenger = "Các bước chuyển đến không được để trống.";

    quire = true;
  }

  return {
    check: quire,
    mess: messenger,
  };
};

export const CHECKTRANFERSTEPS = (ARRAY: any) => {
  let tranfer: any = [];
  let mess: any = "";
  ARRAY.forEach((val: any) => {
    tranfer = tranfer.concat(
      val.stepTransferCondition.sort((a: any, b: any) => {
        return a.next_step_type > b.next_step_type;
      })
    );
  });

  if (tranfer && tranfer.length > 0) {
    let newArrray = tranfer.sort((a: any, b: any) => {
      return a.step_order - b.step_order;
    });
    newArrray.forEach((val: any, idx: any) => {
      if (
        val &&
        val.step_order &&
        newArrray[idx - 1] &&
        newArrray[idx - 1].step_order
      ) {
        if (
          Number(newArrray[idx - 1].step_order) + 1 !==
            Number(val.step_order) &&
          idx !== Number(newArrray[idx - 1].step_order) + 1
        ) {
          mess = ` Bước ${idx} là bước trước của bước ${
            Number(newArrray[idx - 1].step_order) + 1
          }, nhưng không là bước sau của bước khác.`;
          return;
        }
      }
    });
  }

  return {
    error: false,
    mess: mess,
  };
};

export const CONVERTDATETOBE = (date: any) => {
  if (date) {
    let a = date.split("/");
    return a[2] + "-" + a[1] + "-" + a[0];
  }

  return date;
};

export const getStepObjectRemove = (
  newData: any[],
  oldData: any[],
  isCreateNewVersion: boolean
) => {
  if (isCreateNewVersion) return [];
  let data: any = [];
  if (oldData.length) {
    for (let index = 0; index < oldData.length; index++) {
      if (oldData[index].id) {
        let check: any = true;
        for (let i = 0; i < newData.length; i++) {
          if (newData[i].id === oldData[index].id) {
            check = false;
          }
        }
        if (check) {
          data = data.concat([oldData[index].id]);
        }
      }
    }
  }
  return data;
};

export const convertStepObject = (PROCESS: any, KEY: any, LOCATION: any) => {
  let dataStep: any = [];
  if (PROCESS) {
    let step = PROCESS.processSteps;
    if (step && step.length) {
      let arrayMap: any = step
        .filter((ol: any) => ol.step_order === LOCATION)[0]
        .stepTransferCondition.sort(
          (currentStep: any, nextStep: any) =>
            currentStep.transfer_order - nextStep.transfer_order
        );
      for (let index = 0; index < arrayMap.length; index++) {
        const element = step.sort(
          (currentStep: any, nextStep: any) =>
            currentStep.step_order - nextStep.step_order
        )[arrayMap[index].step_order];
        let data: any = [];
        data = element[KEY];

        let arrObject: any = [];
        let forArray: any = [1, 2, 3, 4, 5];
        forArray.forEach((element: any, i: any) => {
          arrObject = arrObject.concat([
            {
              type: i + 1,
              data:
                data && data.length
                  ? data.filter((val: any) => val.object_type === i + 1)
                  : [],
            },
          ]);
        });
        dataStep.push({
          index: index,
          type: "Bước",
          name: arrayMap[index].step_order + 1,
          array: arrObject.filter(
            (value: any) => value.data && value.data.length
          ),
          transfer_order: arrayMap[index].transfer_order,
        });
      }
    }
  }
  return dataStep;
};

export const mergeTranferGroup = (
  data: Array<String>,
  key: string = "stepTransferCondition"
) => {
  let arr: any = [];
  if (data && data.length) {
    data.forEach((element: any) => {
      let newStep = element;
      if (element[key] && element[key].length) {
        let newTranfer: any = [];
        element[key].map((val: any) => {
          if (newTranfer.length && val.group_condition_id) {
            if (
              newTranfer.filter(
                (old: any) => old.group_condition_id === val.group_condition_id
              ).length
            ) {
              newTranfer = newTranfer.map((vaz: any) => {
                if (vaz.group_condition_id === val.group_condition_id) {
                  return {
                    ...vaz,
                    step_condition: val.step_condition,
                    group_step_order: val.step_order,
                    group_step_order_id: val.id,
                  };
                } else {
                  return vaz;
                }
              });
            } else {
              newTranfer = newTranfer.concat([
                {
                  group_condition_id: val.group_condition_id,
                  next_step_type: val.next_step_type,
                  group_step_condition: val.step_condition,
                  step_condition: val.step_condition,
                  step_id: val.step_id,
                  step_order: val.step_order,
                  id: val.id,
                  transfer_order: val.transfer_order,
                  group_step_order_id: val.id,
                },
              ]);
            }
          } else {
            if (val.group_condition_id) {
              newTranfer = newTranfer.concat([
                {
                  group_condition_id: val.group_condition_id,
                  next_step_type: val.next_step_type,
                  group_step_condition: val.step_condition,
                  step_condition: val.step_condition,
                  step_id: val.step_id,
                  step_order: val.step_order,
                  id: val.id,
                  transfer_order: val.transfer_order,
                  group_step_order_id: val.id,
                },
              ]);
            } else {
              newTranfer = newTranfer.concat([
                {
                  group_condition_id: val.group_condition_id,
                  next_step_type: val.next_step_type,
                  step_condition: val.step_condition,
                  step_id: val.step_id,
                  step_order: val.step_order,
                  id: val.id,
                  transfer_order: val.transfer_order,
                  group_step_order_id: val.id,
                },
              ]);
            }
          }
        });
        newStep = {
          ...newStep,
          [`${key}`]: newTranfer,
        };
      }

      arr = arr.concat(newStep);
    });
  }
  return arr;
};

export const mergeTranferGroup2 = (data: Array<String>) => {
  let newTranfer: any = [];
  if (data && data.length) {
    data.map((val: any) => {
      if (newTranfer.length && val.group_condition_id) {
        if (
          newTranfer.filter(
            (old: any) => old.group_condition_id === val.group_condition_id
          ).length
        ) {
          newTranfer = newTranfer.map((vaz: any) => {
            if (vaz.group_condition_id === val.group_condition_id) {
              return {
                ...vaz,
                step_condition: val.step_condition,
                group_step_order: val.step_order,
                group_step_order_id: val.id,
              };
            } else {
              return vaz;
            }
          });
        } else {
          newTranfer = newTranfer.concat([
            {
              group_condition_id: val.group_condition_id,
              next_step_type: val.next_step_type,
              group_step_condition: val.step_condition,
              step_condition: val.step_condition,
              step_id: val.step_id,
              step_order: val.step_order,
              id: val.id,
              transfer_order: val.transfer_order,
              group_step_order_id: val.id,
            },
          ]);
        }
      } else {
        if (val.group_condition_id) {
          newTranfer = newTranfer.concat([
            {
              group_condition_id: val.group_condition_id,
              next_step_type: val.next_step_type,
              group_step_condition: val.step_condition,
              step_condition: val.step_condition,
              step_id: val.step_id,
              step_order: val.step_order,
              id: val.id,
              transfer_order: val.transfer_order,
              group_step_order_id: val.id,
            },
          ]);
        } else {
          newTranfer = newTranfer.concat([
            {
              group_condition_id: val.group_condition_id,
              next_step_type: val.next_step_type,
              step_condition: val.step_condition,
              step_id: val.step_id,
              step_order: val.step_order,
              id: val.id,
              transfer_order: val.transfer_order,
              group_step_order_id: val.id,
            },
          ]);
        }
      }
    });
  }

  return newTranfer;
};

export const deleteMultiStepTransferCondition = (listStep: any[]) => {
  listStep.forEach((step: any) => {
    if (step.id) {
      ProcessManagementApi.deleteStepTransfer(step.id);
    }
  });
};

export const expectancyTransferGroup = (
  data: Array<String>,
  sid: any,
  task?: Boolean
) => {
  let arr: any = [];
  if (data && data.length) {
    data.forEach((element: any, idx: any) => {
      if (element.id && !task) {
        if (element.next_step_type && element.group_condition_id) {
          ProcessManagementApi.updateStepTransferV2(
            {
              group_condition_id: element.group_condition_id,
              next_step_type: element.next_step_type,
              step_condition: element.group_step_condition,
              step_id: sid,
              step_order: element.step_order,
              transfer_order: idx,
            },
            element.id
          );
          ProcessManagementApi.updateStepTransferV2(
            {
              group_condition_id: element.group_condition_id,
              next_step_type: element.next_step_type,
              step_condition: element.step_condition,
              step_id: sid,
              step_order: element.group_step_order,
              transfer_order: idx,
            },
            element.group_step_order_id
          );
        } else {
          ProcessManagementApi.updateStepTransferV2(
            {
              group_condition_id: element.group_condition_id,
              next_step_type: element.next_step_type,
              step_condition: element.step_condition,
              step_id: sid,
              step_order: element.step_order,
              transfer_order: idx,
            },
            element.id
          );
        }
      } else {
        if (element.next_step_type === 2 && !element.group_condition_id) {
          ProcessManagementApi.postGroupTranfer({
            group_first_step: sid,
            step_condition: element.group_step_condition,
            step_order: idx + 1,
          }).then((resul) => {
            if (resul && resul.length) {
              ProcessManagementApi.postStepTranfer([
                {
                  group_condition_id: resul[0].id,
                  next_step_type: element.next_step_type,
                  step_condition: element.group_step_condition,
                  step_id: sid,
                  step_order: element.step_order,
                  transfer_order: idx,
                },
                {
                  group_condition_id: resul[0].id,
                  next_step_type: element.next_step_type,
                  step_condition: element.step_condition,
                  step_id: sid,
                  step_order: element.group_step_order,
                  transfer_order: idx,
                },
              ]);
            }
          });
        } else {
          if (element.group_condition_id) {
            arr = arr.concat([
              {
                group_condition_id: element.group_condition_id,
                next_step_type: element.next_step_type,
                step_condition: element.group_step_condition,
                step_id: sid,
                step_order: element.step_order,
                transfer_order: idx,
              },
              {
                group_condition_id: element.group_condition_id,
                next_step_type: element.next_step_type,
                step_condition: element.step_condition,
                step_id: sid,
                step_order: element.group_step_order,
                transfer_order: idx,
              },
            ]);
          } else {
            arr = arr.concat([
              {
                group_condition_id: element.group_condition_id,
                next_step_type: element.next_step_type,
                step_condition: element.step_condition,
                step_id: sid,
                step_order: element.step_order,
                transfer_order: idx,
              },
            ]);
          }
        }
      }
    });
  }

  if (arr && arr.length) {
    return ProcessManagementApi.postStepTranfer(arr);
  }
  return "";
};

export const stepTranferVisible = (
  DATA: Array<Object>,
  DATACHECK: any,
  LOCATION: Number
) => {
  if (DATACHECK && DATACHECK.stepTranfer && DATACHECK.stepTranfer.length) {
    let resul = DATA.map((vaz: any, stt: any) => {
      if (stt > 0) {
        if (stt + 1 === DATA.length) {
          return {
            id: stt,
            name: "Bước kết thúc",
          };
        } else {
          return {
            id: stt,
            name: "Bước " + (stt + 1),
          };
        }
      }
    });

    return resul
      .filter((val: any) => {
        return (
          DATACHECK.stepTranfer.filter((vaz: any) => {
            if (val && vaz.step_order) {
              return vaz.step_order === val.id;
            }
          }).length === 0
        );
      })
      .filter((old: any, stt: any) => old && stt > LOCATION);
  } else {
    return DATA
      ? DATA.map((vaz: any, stt: any) => {
          if (stt > 0) {
            if (stt + 1 === DATA.length) {
              return {
                id: stt,
                name: "Bước kết thúc",
              };
            } else {
              return {
                id: stt,
                name: "Bước " + (stt + 1),
              };
            }
          }
        }).filter((old: any, stt: any) => old && stt > LOCATION)
      : [];
  }
};
export const pushArray = (ARRAY: any, ITEM: any, INDEX: any) => {
  if (ARRAY && ARRAY.length) {
    let resul: any = [];
    ARRAY.forEach((element: any, idx: any) => {
      if (INDEX === idx) {
        resul = resul.concat([element, ITEM]);
      } else {
        resul = resul.concat([element]);
      }
    });
    return resul;
  }
  return [];
};

export const arrTypeFile = (extension: any) => {
  switch (extension) {
    case ".gif": {
      return "image/gif";
    }
    case ".jpeg": {
      return "image/gif";
    }
    case ".jpg": {
      return "image/gif";
    }
    case ".png": {
      return "image/png";
    }
    case ".ppm": {
      return "image/x-portable-pixmap";
    }
    case ".xls": {
      return "application/vnd.ms-excel";
    }

    case ".xlsb": {
      return "application/vnd.ms-excel.sheet.binary.macroenabled.12";
    }
    case ".xlsm": {
      return "application/vnd.ms-excel.sheet.macroenabled.12";
    }
    case ".docx": {
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.template";
    }
    case ".pdf": {
      return "application/pdf";
    }
    case ".txt": {
      return "text/plain";
    }
    default:
      break;
  }
};

export const returnObjectStep = (arrObject: any) => {
  if (!checkArray(arrObject)) return [];
  return {
    usersId: arrObject
      .filter((val: any) => {
        if (val.object_type === TYPE_OBJECT.USER) {
          return val;
        }
      })
      .map((object: any) => object.object_id),
    groupsId: arrObject
      .filter((val: any) => {
        if (val.object_type === TYPE_OBJECT.GROUP_USER) {
          return val;
        }
      })
      .map((object: any) => object.object_id),
    departmentsId: arrObject
      .filter((val: any) => {
        if (val.object_type === TYPE_OBJECT.DEPARTMENT) {
          return val;
        }
      })
      .map((object: any) => object.object_id),
    RolesIdType: arrObject
      .filter((val: any) => {
        if (val.object_type === TYPE_OBJECT.DEPARTMENT_ROLE) {
          return val;
        }
      })
      .filter((object: any) => {
        if (!object.object_position) {
          return object;
        }
      })
      .map((object: any) => {
        return object.object_id;
      }),
    departmentsIdType: arrObject
      .filter((val: any) => {
        if (val.object_type === TYPE_OBJECT.DEPARTMENT_ROLE) {
          return val;
        }
      })
      .filter((object: any) => {
        if (object.object_position) {
          return object;
        }
      })
      .map((object: any) => {
        if (object.object_position) {
          return object.object_id;
        }
      }),
    roles: arrObject
      .filter((val: any) => {
        if (val.object_type === TYPE_OBJECT.ROLE) {
          return val;
        }
      })
      .map((object: any) => object.object_id),
  };
};

export const returnDefaultApproval = (step: any, array: Array<Object>) => {
  if (step && array && array.length) {
    if (step.step_order === StatusGProcess.draff) {
      return [StatusGProcess.draff];
    } else {
      if (step.step_order === array.length) {
        return [StatusGProcess.default];
      } else {
        if (step.action_type) {
          return step.action_type;
        } else {
          return [StatusGProcess.activated];
        }
      }
    }
  }
  return [StatusGProcess.activated];
};

export const dataObjectKey = [
  {
    id: 1,
    name: <TranslateSSC main="gprocess" keys="step-object" value="positions" />,
  },
  {
    id: 2,
    name: (
      <TranslateSSC main="gprocess" keys="step-object" value="group_users" />
    ),
  },
  {
    id: 3,
    name: (
      <TranslateSSC main="gprocess" keys="step-object" value="departments" />
    ),
  },
  {
    id: 4,
    name: (
      <TranslateSSC
        main="gprocess"
        keys="step-object"
        value="departments_roles"
      />
    ),
  },
  {
    id: 5,
    name: <TranslateSSC main="gprocess" keys="step-object" value="users" />,
  },
  {
    id: 6,
    name: <TranslateSSC main="gprocess" keys="step-object" value="all_user" />,
  },
];

export const convertArrayObject = (index?: number, lengthStep?: any) => {
  if (index === lengthStep - 1) {
    return true;
  }
  return false;
};

export const TARGETOBJECT = [
  {
    id: 1,
    name: (
      <TranslateSSC
        main="gprocess"
        keys="process-managements"
        value="system-part"
      />
    ),
  },
  {
    id: 2,
    name: (
      <TranslateSSC
        main="gprocess"
        keys="process-managements"
        value="internal"
      />
    ),
  },
];

export const mergeStepTranferGroup = (dataStep: any) => {
  if (dataStep) {
    let newStep = dataStep;
    if (
      dataStep.stepTransferConditions &&
      dataStep.stepTransferConditions.length
    ) {
      let newTranfer: any = [];
      dataStep.stepTransferConditions.map((val: any) => {
        if (newTranfer.length && val.group_condition_id) {
          if (
            newTranfer.filter(
              (old: any) => old.group_condition_id === val.group_condition_id
            ).length
          ) {
            newTranfer = newTranfer.map((vaz: any) => {
              if (vaz.group_condition_id === val.group_condition_id) {
                return {
                  ...vaz,
                  step_condition: val.step_condition,
                  group_step_order: val.step_order,
                  group_step_order_id: val.id,
                };
              } else {
                return vaz;
              }
            });
          } else {
            newTranfer = newTranfer.concat([
              {
                group_condition_id: val.group_condition_id,
                next_step_type: val.next_step_type,
                group_step_condition: val.step_condition,
                step_condition: val.step_condition,
                step_id: val.step_id,
                step_order: val.step_order,
                id: val.id,
                transfer_order: val.transfer_order,
                group_step_order_id: val.id,
              },
            ]);
          }
        } else {
          if (val.group_condition_id) {
            newTranfer = newTranfer.concat([
              {
                group_condition_id: val.group_condition_id,
                next_step_type: val.next_step_type,
                group_step_condition: val.step_condition,
                step_condition: val.step_condition,
                step_id: val.step_id,
                step_order: val.step_order,
                id: val.id,
                transfer_order: val.transfer_order,
                group_step_order_id: val.id,
              },
            ]);
          } else {
            newTranfer = newTranfer.concat([
              {
                group_condition_id: val.group_condition_id,
                next_step_type: val.next_step_type,
                step_condition: val.step_condition,
                step_id: val.step_id,
                step_order: val.step_order,
                id: val.id,
                transfer_order: val.transfer_order,
                group_step_order_id: val.id,
              },
            ]);
          }
        }
      });
      newStep = {
        ...newStep,
        stepTransferConditions: newTranfer,
      };
    }

    return newStep;
  }

  return dataStep;
};

export const removeTranferId = (currentData: any, nextData: any) => {
  let arrayRemove: any = [];
  if (nextData && nextData.length) {
    nextData.forEach((element: any) => {
      if (currentData && currentData.length) {
        if (
          currentData.filter(
            (itemTranfer: any) => itemTranfer.id === element.id
          ).length === DEFAULT_CALL_API.START
        ) {
          if (
            currentData.filter(
              (itemTranfer: any) =>
                itemTranfer.group_condition_id === element.group_condition_id
            ).length === DEFAULT_CALL_API.START
          ) {
            arrayRemove = arrayRemove.concat([element.id]);
          }
        }
      } else {
        arrayRemove = arrayRemove.concat([element.id]);
      }
    });
    return arrayRemove;
  }
  return [];
};

export const returnStepTranfer = (dataTranfer: any, currentTranfer: any) => {
  if (dataTranfer.group_condition_id) {
    let arrayTranferGroup = currentTranfer.filter(
      (element: any) =>
        element.group_condition_id === dataTranfer.group_condition_id
    );
    if (
      arrayTranferGroup &&
      arrayTranferGroup.length === DEFAULT_CALL_API.UNIT
    ) {
      return [
        {
          group_condition_id: dataTranfer.group_condition_id,
          next_step_type: dataTranfer.next_step_type,
          step_condition: dataTranfer.group_step_condition,
          step_id: dataTranfer.step_id,
          step_order: dataTranfer.step_order,
          id: dataTranfer.id,
        },
        {
          group_condition_id: dataTranfer.group_condition_id,
          next_step_type: dataTranfer.next_step_type,
          step_condition: dataTranfer.step_condition,
          step_id: dataTranfer.step_id,
          step_order: dataTranfer.group_step_order,
          id: dataTranfer.group_step_order_id,
        },
      ];
    }
    return [
      {
        group_condition_id: dataTranfer.group_condition_id,
        next_step_type: dataTranfer.next_step_type,
        step_condition: dataTranfer.step_condition,
        step_id: dataTranfer.step_id,
        step_order: dataTranfer.step_order,
        id: dataTranfer.id,
      },
    ];
  } else {
    return [
      {
        group_condition_id: dataTranfer.group_condition_id,
        next_step_type: dataTranfer.next_step_type,
        step_condition: dataTranfer.step_condition,
        step_id: dataTranfer.step_id,
        step_order: dataTranfer.step_order,
        id: dataTranfer.id,
      },
    ];
  }
};

export const returnItemTranfer = (dataStep: any, currentTranfer: any) => {
  let arrayTranfer: any = [];
  if (dataStep && dataStep.length && currentTranfer && currentTranfer.length) {
    dataStep.forEach((element: any) => {
      arrayTranfer = arrayTranfer.concat(
        returnStepTranfer(element, currentTranfer)
      );
    });
  }
  return arrayTranfer;
};

export const setUrlRejectAfterLogin = (url: string) => {
  if (url) {
    sessionStorage.setItem(URL_REJECT_AFTER_LOGIN, url);
  }
};

export const checkStepEnd = (processStep: any[]) => {
  return (step: { step: number }) => {
    if (processStep) {
      const maxLength = processStep.length;
      return step.step === maxLength;
    }
    return false;
  };
};

export const checkStepEndByStepOrder = (processStep: any[]) => {
  return (step: { step_order: number }) => {
    if (processStep) {
      const maxLength = processStep.length;
      return step.step_order === maxLength;
    }
    return false;
  };
};

export function generateRandomString() {
  const charset =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const stringLength = 9;
  let randomString = "";

  for (let i = 0; i < stringLength; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    randomString += charset.charAt(randomIndex);
  }

  return randomString;
}

export const findChildById = (node: any, id: any) => {
  if (node?.id === id) {
    return node?.name;
  }
  for (const child of node?.children) {
    const result: any = findChildById(child, id);
    if (result) {
      return result;
    }
  }
  return null;
};

export function recurseFindChildPath(
  rootNode: any,
  targetId: any,
  currentPath: any = []
) {
  try {
    if (currentPath.length) {
      currentPath += " > ";
    }
    currentPath += rootNode?.name;

    if (rootNode.id === targetId) {
      return currentPath;
    }

    for (const child of rootNode.children) {
      const path:any = recurseFindChildPath(child, targetId, currentPath);
      if (path) {
        return path;
      }
    }
    return null;
  } catch (error) {}
}



export function hasDuplicateStrings(arr: string[]) {
  const uniqueSet = new Set();
  for (const element of arr) {
    if (uniqueSet.has(element)) {
      return true; // Found a duplicate
    }
    uniqueSet.add(element);
  }
  return false; // No duplicates found
}


export type GenerateValueUnion<T> = NonNullable<T[keyof T]>;
