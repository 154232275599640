import { axiosOkrPrivate } from "../../../api/axios";
import { buildQueryUrl, formatSqlDate } from "../../../helpers/helper";
import { ROLES_ID } from "../../../helpers/constant";
import {
  ENUM_TAB_OKR,
  keyTimeSubItemAPIType,
  rootObjectType,
} from "../type/type";
import { TypeSubtree } from "../../../components/UI/TreeOKRs/type";
import { PayloadDensityTaskType } from "../target-management/components/ModalDensityAllocate/ModalDensityAllocate";

type densityPayload = Array<{
  id: string;
  density: number;
  approver_id: number | null;
}>;

export type ExportPayload = {
  roleId: number;
  authEmail: string;
  month: number;
  year: number;
  currentTabInExport?: "is_department" | "is_division" | "is_personal";
};

const OkrApi = {
  getOkr: async function (
    is_has_children: boolean,
    ork_id: number | null,
    key_ids?: { [key: string]: number[] },
    auth_email?: string,
    rootObject?: rootObjectType
  ): Promise<any> {
    try {
      const payload = {
        ...(key_ids && key_ids),
        auth_email,
        is_has_children,
        ...(rootObject && { [rootObject.label]: rootObject.value }),
      };
      const url = buildQueryUrl(`/projects/${ork_id}`, payload);
      const response = await axiosOkrPrivate.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  createOkr: async function (payload: any): Promise<any> {
    try {
      const response = await axiosOkrPrivate.post("/projects", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updateOkr: async function (payload: any, okr_id: number): Promise<any> {
    try {
      const response = await axiosOkrPrivate.post(
        `/projects/${okr_id}`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getListOkr: async function (): Promise<any> {
    try {
      const response = await axiosOkrPrivate.get(
        "https://jsonplaceholder.typicode.com/todos"
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  calculateOkrResult: async function (
    okrId: string,
    density: number
  ): Promise<any> {
    try {
      const response = await axiosOkrPrivate.get(
        "https://jsonplaceholder.typicode.com/todos"
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  densityAllocate: async function (payload: any): Promise<any> {
    try {
      const response = await axiosOkrPrivate.post(
        "/density-project/allocate",
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getListKR: async function (auth_email: string | undefined): Promise<any> {
    try {
      const response = await axiosOkrPrivate.get(
        `/key-result?auth_email=${auth_email}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getDetailKR: async function (
    _id: any,
    user_id: any,
    auth_email: any
  ): Promise<any> {
    try {
      const response = await axiosOkrPrivate.get(
        `/key-result/${_id}?user_id=${user_id}&auth_email=${auth_email}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getTask: async function (task_id: number): Promise<any> {
    try {
      const response = await axiosOkrPrivate.get(`/tasks/${task_id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getTaskByKrId: async function (
    kr_id: number,
    auth_email: string | undefined
  ): Promise<any> {
    try {
      const response = await axiosOkrPrivate.get(
        `/tasks/list-by-kr-id/${kr_id}?auth_email=${auth_email}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  createTask: async function (payload: any): Promise<any> {
    try {
      const response = await axiosOkrPrivate.post("/tasks", payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updateTask: async function (payload: any, task_id: number): Promise<any> {
    try {
      const response = await axiosOkrPrivate.put(`/tasks/${task_id}`, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getTimeSheet: async function (okrData: densityPayload): Promise<any> {
    try {
      const response = await axiosOkrPrivate.post(
        "https://jsonplaceholder.typicode.com/todos"
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updateKrDensity: async function (
    payload: PayloadDensityTaskType[]
  ): Promise<any> {
    try {
      const response = await axiosOkrPrivate.put(
        `/key-result/densities`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  okrDensityApprove: async function (payload: any): Promise<any> {
    try {
      const response = await axiosOkrPrivate.post(
        `/density-project/approval`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  createTaskSelfRating: async function (payload: any): Promise<any> {
    try {
      const response = await axiosOkrPrivate.post(`/self-ratings`, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updateTaskSelfRating: async function (payload: any, id: any): Promise<any> {
    try {
      const response = await axiosOkrPrivate.put(
        `/self-ratings/${id}`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getTaskSelfRating: async function (
    taskId: number | null,
    assigneeId: number | undefined
  ): Promise<any> {
    try {
      const response = await axiosOkrPrivate.get(
        `/self-ratings/info?task_id=${taskId}&assignee_id=${assigneeId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getTaskRating: async function (
    taskId: number | null,
    userId: number | undefined
  ): Promise<any> {
    try {
      const response = await axiosOkrPrivate.get(
        `/task-evaluations/info?task_id=${taskId}&evaluator_id=${userId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getCheckRatingState: async function (
    taskId: number | null,
    userId: number | undefined
  ): Promise<any> {
    try {
      const response = await axiosOkrPrivate.get(
        `/task-evaluations/check-state?task_id=${taskId}&evaluator_id=${userId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  createTaskRating: async function (payload: any): Promise<any> {
    try {
      const response = await axiosOkrPrivate.post(`/task-evaluations`, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updateTaskRating: async function (payload: any, id: any): Promise<any> {
    try {
      const response = await axiosOkrPrivate.put(
        `/task-evaluations/${id}`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getMainUserRating: async function (
    userId: string | number | null
  ): Promise<any> {
    try {
      const response = await axiosOkrPrivate.get(
        `primary-user-reviews/info?primary_user_id=${userId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  createUserRating: async function (payload: any): Promise<any> {
    try {
      const response = await axiosOkrPrivate.post(
        `/primary-user-reviews/multi-review`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updateUserRating: async function (payload: any): Promise<any> {
    try {
      const response = await axiosOkrPrivate.put(
        `/primary-user-reviews/multi-review`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getRatingPercent: async function (appliedTime: any): Promise<any> {
    try {
      const response = await axiosOkrPrivate.get(
        `/formulas/quality?applied_time=${appliedTime}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  //
  getFilterOkr: async function (param: {
    year: number;
    month: number;
    userEmail: number | string;
  }): Promise<any> {
    const { year, month, userEmail } = param;
    try {
      const response = await axiosOkrPrivate.get(
        `/projects/ceo-focus?year=${year}&month=${month}&auth_email=${userEmail}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getSubOKRs: async function (params: {
    id: number | string;
    level: number;
    subtree: TypeSubtree;
    auth_email: string;
    key_result_ids: number[];
    sub_project_ids: number[];
    task_ids: number[] | undefined;
    rootObject?: rootObjectType;
    time: {
      month: any;
      year: any;
    };
  }): Promise<any> {
    const {
      id,
      level,
      auth_email,
      key_result_ids,
      sub_project_ids,
      task_ids,
      rootObject,
      time,
    } = params;
    const url = generateURLGetSubitem(
      {
        key_result_ids,
        sub_project_ids,
        task_ids,
        rootObject,
        month: time.month,
        year: time.year,
      },
      `/sub-projects/${id}?level=${level}&auth_email=${auth_email}`
    );
    try {
      const response = await axiosOkrPrivate.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getKRs: async function (params: {
    id: number | string;
    level: number;
    subtree: TypeSubtree;
    auth_email: string;
    key_result_ids: number[];
    task_ids: number[];
    rootObject?: rootObjectType;
    time: keyTimeSubItemAPIType;
  }): Promise<any> {
    const {
      id,
      level,
      auth_email,
      key_result_ids,
      task_ids,
      rootObject,
      time,
    } = params;
    const url = generateURLGetSubitem(
      {
        key_result_ids,
        task_ids,
        rootObject,
        month: time.month,
        year: time.year,
      },
      `/key-result/list-by-project-id/${id}?level=${level}&auth_email=${auth_email}`
    );
    try {
      const response = await axiosOkrPrivate.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getTasks: async function (params: {
    id: number | string;
    level: number;
    subtree: TypeSubtree;
    auth_email: string;
    task_ids: number[];
    time: keyTimeSubItemAPIType;
  }): Promise<any> {
    const { id, level, auth_email, task_ids, time } = params;
    const url = generateURLGetSubitem(
      {
        task_ids,
        month: time.month,
        year: time.year,
      },
      `/tasks/list-by-kr-id/${id}?level=${level}&auth_email=${auth_email}`
    );
    try {
      const response = await axiosOkrPrivate.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  department: {
    getOKRs: async function (params: {
      year: number;
      month: number;
      departments?: any;
      userEmail: string | number;
    }): Promise<any> {
      const { year, month, departments = "departments=", userEmail } = params;
      try {
        const response = await axiosOkrPrivate.get(
          `/projects/departments?year=${year}&month=${month}&${departments}&auth_email=${userEmail}`
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  },
  getOKRs: async function (params: {
    year: number;
    month: number;
    userEmail: number | string;
    departments: string;
    divisions: string;
    roleOKRs:
      | ENUM_TAB_OKR.DEPARTMENT
      | ENUM_TAB_OKR.PERSONAL
      | ENUM_TAB_OKR.DIVISION;
  }): Promise<any> {
    /**
     * When user don't choose anything => @default
     * @default departments=
     * @departments = "departments=" || "departments=number[]"
     */
    /**
     * When user don't choose anything => @default
     * @default divisions=
     * @divisions = "divisions=" || "divisions=number[]"
     */
    const { year, month, userEmail, roleOKRs, departments, divisions } = params;
    try {
      const response = await axiosOkrPrivate.get(
        `/projects/hod-teamlead-staff?year=${year}&month=${month}&${departments}&${divisions}&${keyRoleOKRs[roleOKRs]}=true&auth_email=${userEmail}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getOkrDensityAllocated: async function (authEmail: string): Promise<any> {
    try {
      const response = await axiosOkrPrivate.get(
        `/projects/densities?auth_email=${authEmail}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getApproveOKRDetailList: async function (params: any): Promise<any> {
    try {
      const url = buildQueryUrl("/density-project/approve-detail", params);
      const response = axiosOkrPrivate.get(url);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getListDensityFirstChildSubOKR: async function (params: any): Promise<any> {
    const { okrId, authEmail, sub_project_ids, rootObject, is_has_children } =
      params;
    try {
      const payload = {
        auth_email: authEmail,
        parent_id: okrId,
        sub_project_ids: sub_project_ids,
        is_has_children,
      };
      const url = buildQueryUrl(`/projects/densities`, payload);
      const _url = generateURLGetSubitem(
        {
          rootObject,
          month: undefined,
          year: undefined,
        },
        url
      );
      const response = await axiosOkrPrivate.get(_url);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getListDensityFirstChildKR: async function ({
    parentId,
    user_id,
    key_result_ids,
    rootObject,
    auth_email,
    is_has_children,
  }: {
    parentId: number;
    user_id: number | null;
    key_result_ids?: number[];
    rootObject?: rootObjectType;
    auth_email?: string;
    is_has_children: boolean;
  }): Promise<any> {
    try {
      const payload = {
        project_id: parentId,
        creator_id: user_id,
        key_result_ids: key_result_ids,
        auth_email: auth_email,
        is_has_children: is_has_children,
        ...(rootObject && { [rootObject.label]: rootObject.value }),
      };
      const url = buildQueryUrl(`/key-result/densities`, payload);
      const response = await axiosOkrPrivate.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  exportOkrData: async function (payload: ExportPayload): Promise<any> {
    const { authEmail, roleId, month, year, currentTabInExport } = payload;
    let exportParams = "";
    if (currentTabInExport) {
      exportParams = `&${currentTabInExport}=true`;
    }
    try {
      let url = "";
      if (roleId === ROLES_ID.CEO) {
        url = `/projects/export?is_ceo=true&auth_email=${authEmail}&month=${month}&year=${year}`;
      } else {
        url = `/projects/export?auth_email=${authEmail}&month=${month}&year=${year}${exportParams}`;
      }

      const response = await axiosOkrPrivate.post(url, null, {
        responseType: "blob",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getApproveOKRList: async function (email: string | undefined): Promise<any> {
    try {
      const response = await axiosOkrPrivate.get(
        `/projects/ceo-hod-approval?auth_email=${email}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getListOkrOwner: async function (query: {
    search?: string;
    selected_id?: number;
    page?: number;
    hasKr?: any;
    user_id?: number | string;
  }): Promise<any> {
    try {
      const { search, selected_id, page, hasKr, user_id } = query;
      const url = buildQueryUrl("/projects/project-owners", {
        name: search,
        page: page,
        per_page: 200,
        user_id: user_id,
        selected_id: selected_id,
        "has-kr": hasKr,
      });
      const response = await axiosOkrPrivate.get(url);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  },
  getManagementTimesheet: async function (params: {
    user_id: number;
    from: any;
    to: any;
    search: string;
  }): Promise<any> {
    try {
      const { from, to, user_id, search = "" } = params;
      const payload = {
        from_date: formatSqlDate(from),
        to_date: formatSqlDate(to),
        name: search,
        user_id: user_id,
      };
      const url = buildQueryUrl("/timesheet-management", payload);
      const response = await axiosOkrPrivate.get(url);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  },
};

const keyRoleOKRs: { [key: string]: string } = {
  division: "is_division",
  department: "is_department",
  personal: "is_personal",
};

function generateURLGetSubitem(
  params: {
    key_result_ids?: [] | number[] | undefined;
    sub_project_ids?: [] | number[] | undefined;
    task_ids?: [] | number[] | undefined;
    rootObject?: rootObjectType;
    month: any;
    year: any;
  },
  url: string
) {
  for (const [key, values] of Object.entries(params)) {
    if (values) {
      switch (key) {
        case "rootObject":
          const { label, value } = values as rootObjectType;
          url += `&${label}=${value}`;
          break;
        default:
          if (typeof values === "string" || typeof values === "number") {
            url += `&${key}=${values}`;
          } else if (Array.isArray(values)) {
            url += `&${key}=${JSON.stringify(values)}`;
          }
          break;
      }
    }
  }
  return url;
}

export default OkrApi;
