export enum StatusEnum {
  Created = 1,
  SentApprove,
  Approved,
  Rejected
}

export enum SubjectEnum {
  Individual = 1,
  Group
}

export enum GoalTypeEnum {
  Month = 1,
  Year
}

export enum CheckEnum {
  All = 'all'
}

export enum StatusOperatorEnum {
  Inactive,
  Active
}

export enum UploadTypeEnum {
  RunBack,
  Upload
}

export enum GoalEnum {
  NotIsGoal,
  IsGoal
}

export enum FileTypeEnum {
  Final = 3,
  Density = 1,
  Result,
  PerformanceApproval
}

export enum ResultApproveTypeEnum {
  Object = 1,
  Employee = 2
}

export enum ResultApproveListName {
  Object = 'object',
  Employee = 'employee'
}