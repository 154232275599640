const PenEdit = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.31055 13.8321H14.75"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.585 1.34609C9.16674 0.65084 10.2125 0.548892 10.9222 1.1188C10.9614 1.14972 12.2221 2.12909 12.2221 2.12909C13.0017 2.60039 13.244 3.60233 12.762 4.36694C12.7365 4.40789 5.60896 13.3234 5.60896 13.3234C5.37183 13.6192 5.01187 13.7938 4.62718 13.798L1.89765 13.8323L1.28265 11.2292C1.1965 10.8632 1.28265 10.4788 1.51978 10.183L8.585 1.34609Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.26562 3.00073L11.3548 6.14108"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default PenEdit;
