import { t } from "i18next";
import { LoadingRing } from "../../../../../components/UI";
import { useTranslation } from "react-i18next";
export const LoadingSSC = ({ loading }: { loading: boolean }) => {
  const { t } = useTranslation("gperformance", {
    keyPrefix: "performance_table",
  });
  return loading ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        color: "black",
      }}
    >
      <LoadingRing size="md" />
      <h4>{t("loadingData")}</h4>
    </div>
  ) : (
    <></>
  );
};
