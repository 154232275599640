import React from "react";

const IconCloseT = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.20196 11.3526C7.05796 11.3526 6.91396 11.2979 6.80446 11.1876C6.58471 10.9679 6.58471 10.6124 6.80446 10.3926L10.3985 6.7986C10.6182 6.57885 10.9737 6.57885 11.1935 6.7986C11.4132 7.01835 11.4132 7.37385 11.1935 7.5936L7.59946 11.1876C7.48996 11.2979 7.34596 11.3526 7.20196 11.3526Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.797 11.3546C10.653 11.3546 10.509 11.2999 10.3995 11.1896L6.80251 7.59189C6.58276 7.37214 6.58276 7.01664 6.80251 6.79689C7.02301 6.57714 7.37851 6.57714 7.59751 6.79689L11.1945 10.3946C11.4143 10.6144 11.4143 10.9699 11.1945 11.1896C11.085 11.2999 10.9403 11.3546 10.797 11.3546Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.74875 2.625C3.85125 2.625 2.625 3.92475 2.625 5.937V12.063C2.625 14.0752 3.85125 15.375 5.74875 15.375H12.2497C14.148 15.375 15.375 14.0752 15.375 12.063V5.937C15.375 3.92475 14.148 2.625 12.2505 2.625H5.74875ZM12.2498 16.5H5.74875C3.207 16.5 1.5 14.7165 1.5 12.063V5.937C1.5 3.2835 3.207 1.5 5.74875 1.5H12.2505C14.7922 1.5 16.5 3.2835 16.5 5.937V12.063C16.5 14.7165 14.7923 16.5 12.2498 16.5Z"
        fill="white"
      />
    </svg>
  );
};

export default IconCloseT;
