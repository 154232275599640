import { useTranslation } from "react-i18next";
import styles from "./style.module.scss";
function EmptyDataTable() {
  const { t } = useTranslation("gperformance", {
    keyPrefix: "performance_table",
  });
  return (
    <div className={styles["empty-data"]}>
      <div className={styles["total"]}>{t("total")}</div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
      >
        <circle cx="60" cy="60" r="60" fill="#FAFAFB" />
        <mask
          id="mask0_731_5770"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="120"
          height="120"
        >
          <circle cx="60" cy="60" r="60" fill="#FAFAFB" />
        </mask>
        <g mask="url(#mask0_731_5770)">
          <rect y="87.0332" width="114.725" height="43.0769" fill="#EDEDED" />
        </g>
        <path
          d="M35.7144 44.2852L65.7144 49.823V95.7137L35.7144 89.0914V44.2852Z"
          fill="#E3E3E3"
        />
        <path
          d="M90 44.2852L65.7143 49.823V95.7137L90 89.0914V44.2852Z"
          fill="#D9D9D9"
        />
        <path
          d="M35.8594 43.8398L31.6406 56.0273L61.6406 61.8867L65.3906 50.4023L68.9062 61.8867L94.4531 56.0273L90 44.2863L63.75 40.793L35.8594 43.8398Z"
          fill="#F1F1F1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M86.7687 28.6226C87.53 28.4157 88.315 28.8652 88.5219 29.6266L90.6152 37.3297C90.8221 38.0911 90.3726 38.876 89.6113 39.0829C88.8499 39.2898 88.065 38.8403 87.8581 38.079L85.7647 30.3758C85.5578 29.6145 86.0073 28.8295 86.7687 28.6226ZM97.7164 29.8875C98.4375 30.2077 98.7626 31.0518 98.4424 31.7729L95.3459 38.7471C95.0257 39.4682 94.1816 39.7932 93.4605 39.4731C92.7394 39.1529 92.4144 38.3088 92.7346 37.5877L95.8311 30.6135C96.1512 29.8924 96.9953 29.5674 97.7164 29.8875ZM104.754 39.0818C104.974 39.8397 104.537 40.6319 103.78 40.8514L97.3804 42.7045C96.6225 42.924 95.8303 42.4875 95.6108 41.7297C95.3914 40.9718 95.8278 40.1796 96.5856 39.9601L102.985 38.107C103.743 37.8876 104.535 38.324 104.754 39.0818Z"
          fill="#C0C0C0"
        />
        <path
          d="M65 47.1426L40 44.2854L63.5714 42.1426L86.4286 44.9997L65 47.1426Z"
          fill="#D9D9D9"
        />
      </svg>
      {t("empty")}
    </div>
  );
}
export default EmptyDataTable;
