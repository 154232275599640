import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import layout_vn from "./locales/vn/layout.translation.json";
import layout_en from "./locales/en/layout.translation.json";

// okr
import okr_vn from "./locales/vn/okr.translation.json";
import okr_en from "./locales/en/okr.translation.json";

//gperformance
import kpi_vn from "./locales/vn/gperformance.translation.json";
import kpi_en from "./locales/en/gperformance.translation.json";

//g-process
import gprocess_vn from "./locales/vn/gprocess.translation.json";
import gprocess_en from "./locales/en/gprocess.translation.json";
import gprocess_vn_message from "./locales/vn/message.translation.json";
import gprocess_en_message from "./locales/en/message.translation.json";

//g-ticket
import gticket_en from "./locales/en/gticket.translation.json";
import gticket_vn from "./locales/vn/gticket.translation.json";

//system-management
import systemmanagement_vn from "./locales/vn/systemmanagement.translation.json";
import systemmanagement_en from "./locales/en/systemmanagement.translation.json";

import mission_vn from "./locales/vn/mission.translation.json";
import mission_en from "./locales/en/mission.translation.json";

import task_evaluate_en from "./locales/en/task-evaluation.translation.json";
import task_evaluate_vn from "./locales/vn/task-evaluation.translation.json";

import dashboard_vn from "./locales/vn/dashboard.translation.json";
import dashboard_en from "./locales/en/dashboard.translation.json";

import main_user_evaluate_en from "./locales/en/main-user-evaluation.translation.json";
import main_user_evaluate_vn from "./locales/vn/main-user-evaluation.translation.json";

import management_timesheet_en from "./locales/en/management-timesheet.translation.json";
import management_timesheet_vn from "./locales/vn/management-timesheet.translation.json";
import { LANGUAGES } from "./helpers/constant";

const resources = {
  en: {
    layout: layout_en,
    okr: okr_en,
    systemManagement: systemmanagement_en,
    gperformance: kpi_en,
    gprocess: gprocess_en,
    gmessage: gprocess_en_message,
    mission: mission_en,
    task_evaluate: task_evaluate_en,
    main_user_evaluate: main_user_evaluate_en,
    gticket: gticket_en,
    dashboard: dashboard_en,
    management_timesheet: management_timesheet_en,
  },
  vn: {
    layout: layout_vn,
    okr: okr_vn,
    systemManagement: systemmanagement_vn,
    gperformance: kpi_vn,
    gprocess: gprocess_vn,
    gmessage: gprocess_vn_message,
    mission: mission_vn,
    task_evaluate: task_evaluate_vn,
    main_user_evaluate: main_user_evaluate_vn,
    gticket: gticket_vn,
    dashboard: dashboard_vn,
    management_timesheet: management_timesheet_vn,
  },
};

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: LANGUAGES.VN,
    fallbackLng: LANGUAGES.VN,
    resources,
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
  });

export default i18n;
