import React, { useEffect, useRef, useState } from "react";
import styles from "./formProgress.module.scss";

type Typeprops = {
  total: Number;
  min?: Number;
  value: Number;
  title?: Boolean;
  name?: String;
  onClick?: any;
  className?: any;
};

const FormProgressBar = ({
  total,
  min,
  value,
  title,
  name,
  onClick,
  className,
}: Typeprops) => {
  const getColor = (value: number) => {
    if (value < 30) return "#C60E02";
    if (value >= 70) return "#41AC49";
    return "#F0B71C";
  };

  const [widths, setWidths] = useState<any>(0);
  useEffect(() => {
    let width = Math.round((100 / Number(total)) * Number(value));
    if (width > 99) {
      setWidths(100);
    } else if (width < 1) {
      setWidths(0);
    } else {
      setWidths(width);
    }
  }, [value]);

  return (
    <div
      style={title ? { paddingRight: "83px" } : {}}
      className={`${styles.content} ${className}`}
      onClick={onClick}
    >
      {name ? <p style={{ width: "200px" }}>{name}</p> : ""}
      <div className={styles.progress}>
        <div
          style={{ width: `${widths}%`, background: `${getColor(widths)}` }}
          className={styles.timeline}
        ></div>
      </div>
      {title && (
        <div className={styles.title}>
          {widths === 100 ? "100%" : widths === 0 ? "0%" : `${widths}%/100%`}
        </div>
      )}
    </div>
  );
};

export default FormProgressBar;
