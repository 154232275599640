import { ColumnType, ColumnsType } from "antd/lib/table";
import { FIELD_NAMES } from "../../../constants";
import { getStyleStatusChip } from "../../../helper";
import {
  DataType,
  EmployeeInfo,
  HeaderConfigType,
  UserType,
} from "../../../interfaces";
import Employee from "../../employee";
import StatusChip from "../../status";
import Workspace from "../../workspace";
import styles from "../style.module.scss";
import { Tooltip } from "antd";
export function createColumnRender(data: EmployeeInfo): ColumnType<DataType> {
  return {
    dataIndex: data.id,
    key: data.id,
    width: 170,
    align: "center",
    title: function () {
      let result;
      switch (data.type) {
        case "user":
          result = <Employee {...data} />;
          break;
        case "workspace":
          result = (
            <Workspace id={data.id} name={data.name} hasChildren={true} />
          );
          break;
        case "date":
          result = <div className={styles["month"]}>{data.name}</div>;
          break;
        default:
          result = <></>;
          break;
      }
      return result;
    },
    render: function (value: any, record: DataType) {
      let result: JSX.Element;
      switch (true) {
        case record.type === FIELD_NAMES.STATUS && !!value:
          result = <StatusChip value={value} data={data} />;
          break;
        case record.type === FIELD_NAMES.STATUS && !value:
          result = <></>;
          break;
        case !!value:
          result = <span style={getStyleStatusChip(value)}>{value}%</span>;
          break;
        default:
          result = <>-</>;
          break;
      }
      return result;
    },
  } as HeaderConfigType;
}
export const columns: ColumnsType<DataType> = [
  {
    dataIndex: "property",
    key: "property",
    render: (text) => {
      return (
        <Tooltip title={text}>
          <span
            style={{
              minWidth: "325px",
              maxWidth: "325px",
              marginLeft: 30,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              display: "inline-block",
              textAlign: "left",
              lineHeight: "48px",
              height: "48px",
              verticalAlign: "middle",
            }}
          >
            {text}
          </span>
        </Tooltip>
      );
    },
    title: () => {
      return (
        <div
          className="title-property"
          style={{ minWidth: "390px", maxWidth: "390px" }}
        ></div>
      );
    },
    fixed: "left",
    width: 390,
  },
  {
    dataIndex: "fill",
    key: "empty",
    title: "",
    width: "auto",
  },
];
export const createRenderHeaderTable = ({
  header,
  dataType,
  headerConfig,
}: {
  dataType: string;
  header: UserType[];
  headerConfig: ColumnsType<DataType>;
}) => {
  const list: ColumnType<DataType>[] = header.map((item: UserType) => {
    return createColumnRender({
      id: item.id,
      key: item.id,
      type: dataType,
      name: item.name,
      email: item.email,
      position: item.position,
      children: item.children,
      dataIndex: String(item.id),
    });
  });
  return [
    headerConfig[0],
    ...list,
    headerConfig[headerConfig.length - 1],
  ] as ColumnsType<DataType>;
};
