import { ReactNode } from "react";
import { createPortal } from "react-dom";
import style from "./style.module.scss";

type TypeProps = {
  children: ReactNode;
  width?: any;
  id?: string;
  customStyle?: any;
};
const Modal = ({ children, width, id, customStyle }: TypeProps) => {
  const modalRoot = document.getElementById("container-portal") as HTMLElement;
  return createPortal(
    <div id={style["container-modal"]}>
      <div
        className={`${style.container_content_modal} ${style.modal_mounting} `}
        style={{ width: width, ...customStyle }}
      >
        {children}
      </div>
    </div>,
    modalRoot
  );
};

export default Modal;
