import { createAsyncThunk } from '@reduxjs/toolkit';
import { openNotification, TOAST_TYPE } from '../utils';
import {
  AddGoalListSchema,
  FilterParam,
  UpdateGoalSchema
} from '../utils/interface';
import GoalPerformanceApi from './api';
import { clearSlice, setErrorAddGoal, setErrorEditGoal } from './slice';

export const getList = createAsyncThunk(
  'gperformanceComputingManagement/getList',
  async (params: FilterParam) => {
    try {
      const response = await GoalPerformanceApi.list(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListFilterGoal = createAsyncThunk(
  'gperformanceComputingManagement/getListFilterGoal',
  async (params: FilterParam) => {
    try {
      const response = await GoalPerformanceApi.list(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListWorkspace = createAsyncThunk(
  'gperformanceComputingManagement/getListWorkspace',
  async (params?: any) => {
    try {
      const response = await GoalPerformanceApi.listWorkspace(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListGoal = createAsyncThunk(
  'gperformanceComputingManagement/getListGoal',
  async (params?: any) => {
    try {
      const response = await GoalPerformanceApi.listGoal({...params, is_sort: 1});
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getAllGoals = createAsyncThunk(
  'gperformanceComputingManagement/getAllGoals',
  async (params?: any) => {
    try {
      const response = await GoalPerformanceApi.listGoal({
        ...params,
        status: [0,1],
        is_sort: 1
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListEmployee = createAsyncThunk(
  'gperformanceComputingManagement/getListEmployee',
  async (params?: { status: number }) => {
    try {
      const response = await GoalPerformanceApi.listEmployee(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListMore = createAsyncThunk(
  'gperformanceComputingManagement/getListMore',
  async (params: FilterParam) => {
    try {
      const response = await GoalPerformanceApi.list(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const add = createAsyncThunk(
  'gperformanceComputingManagement/add',
  async (
    payload: { body: AddGoalListSchema; message: any },
    { dispatch, getState }
  ) => {
    const { body, message } = payload;
    try {
      const response = await GoalPerformanceApi.add(body);
      const state: any = getState();
      dispatch(getList(state.gPerformanceComputingManagement.filterParams));
      openNotification({
        type: TOAST_TYPE.Success,
        message: message.success.create
      });
      return response;
    } catch (error: any) {
      if (error?.response?.data?.data) {
        dispatch(setErrorAddGoal(error?.response?.data?.data));
      } else if (error?.response?.data?.message) {
        openNotification({
          type: TOAST_TYPE.Error,
          message: error?.response?.data?.message
        });
      }
      throw error;
    }
  }
);

export const detail = createAsyncThunk(
  'gperformanceComputingManagement/detail',
  async (id: number) => {
    try {
      const response = await GoalPerformanceApi.detail(id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const update = createAsyncThunk(
  'gperformanceComputingManagement/update',
  async (
    payload: { id: number; body: UpdateGoalSchema; message: any },
    { dispatch, getState }
  ) => {
    const { id, body, message } = payload;
    try {
      const response = await GoalPerformanceApi.update(id, body);
      const state: any = getState();
      dispatch(
        getList({
          ...state.gPerformanceComputingManagement.filterParams,
          page: 1
        })
      );
      openNotification({
        type: TOAST_TYPE.Success,
        message: message
      });
      return response.data;
    } catch (error: any) {
      dispatch(setErrorEditGoal(error?.response?.data?.data));
      throw error;
    }
  }
);

export const deleteGoal = createAsyncThunk(
  'gperformanceComputingManagement/deleteGoalGroup',
  async (params: { id: number; message: any }, { dispatch, getState }) => {
    const { id, message } = params;
    dispatch(clearSlice());
    try {
      const response = await GoalPerformanceApi.delete(id);
      const state: any = getState();
      dispatch(
        getList({
          ...state.gPerformanceComputingManagement.filterParams,
          page: 1
        })
      );
      openNotification({
        type: TOAST_TYPE.Success,
        message: message.success.delete
      });
      return response;
    } catch (error) {
      openNotification({
        type: TOAST_TYPE.Error,
        message: message.error.delete
      });
      throw error;
    }
  }
);

export const downloadTemplate = createAsyncThunk(
  'gperformanceComputingManagement/downloadTemplate',
  async (_, { dispatch }) => {
    dispatch(clearSlice());
    try {
      const response = await GoalPerformanceApi.downloadTemplate();
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const exportExcel = createAsyncThunk(
  'gperformanceComputingManagement/exportExcel',
  async (params: FilterParam, { dispatch }) => {
    dispatch(clearSlice());
    try {
      const response = await GoalPerformanceApi.exportExcel(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const importGoal = createAsyncThunk(
  'gperformanceComputingManagement/importGoal',
  async (fromData: any, { dispatch }) => {
    try {
      const response = await GoalPerformanceApi.importGoal(fromData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const upload = createAsyncThunk(
  'gperformanceComputingManagement/upload',
  async (fromData: any, { dispatch }) => {
    try {
      const response = await GoalPerformanceApi.upload(fromData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);
