import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import CustomRoutes from "./components/CustomRoutes/CustomRoutes";
import LoadingFallback from "./components/UI/LoadingFallback/LoadingFallback";
import { AuthServiceInstance } from "./features/auth/AuthService";
import { loginUser } from "./features/auth/redux/authSlice";
import { checkValidToken } from "./helpers/helper";
import { useAppDispatch } from "./store/store";
import { ConfigProvider } from "antd";
import viVN from "antd/es/locale/vi_VN";
import { setUrlRejectAfterLogin } from "./helpers/function";
import usePageTitle from "./hooks/usePageTitle";
import { useSelector } from "react-redux";
import GlobalModal from "./components/GlobalModal";
import {
  onCloseModal,
  resetListModal,
} from "./components/GlobalModal/globalModalSlice";
import { isMobile } from "react-device-detect";
import { getWorkspaceInfo } from "./features/system-management/workspace/redux/workspaceSlice";
import { getSelectionData } from "./features/auth/redux/selectionDataSlice";

function App() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [checkingUser, setCheckingUser] = useState<boolean>(true);
  const { ListModals, isOpen } = useSelector((state: any) => state.globalModal);

  const redirect = useNavigate();

  // useEffect(() => {
  //   if (isMobile && location.pathname !== "/mobile-detected")
  //     redirect("/mobile-detected");
  // });

  useEffect(() => {
    // when SSO redirect to login/callback, don't check user authentication
    if (location.pathname === "/login/callback") {
      setCheckingUser(false);
      return;
    }

    // check user authentication
    AuthServiceInstance.getUser().then((user) => {
      if (user && checkValidToken(user.access_token, user.expires_at)) {
        const callback = (_: any, userInfo: any) => {
          if (checkingUser) setCheckingUser(false);
          if (userInfo?.is_admin) {
            // Get workspace info admin
            getWorkspaceInfo(dispatch);
          }
          // Get selection data
          getSelectionData(dispatch);
        };
        loginUser(dispatch, user, callback);
      } else {
        setUrlRejectAfterLogin(location?.pathname + location?.search);
        AuthServiceInstance.login();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  usePageTitle({ pathname: location.pathname });

  if (checkingUser) return <LoadingFallback />;

  return (
    <ConfigProvider locale={viVN}>
      <Toaster
        position="bottom-right"
        toastOptions={{
          duration: 3000,
          style: {
            background: "#fff",
            color: "#000 ",
            padding: "1.25rem 1.5rem",
          },
          success: {
            style: {
              border: "1px solid #95DBD2",
            },
          },
          error: {
            style: {
              border: "1px solid #DB9595",
            },
          },
        }}
      />
      <CustomRoutes />
      <GlobalModal
        isOpen={isOpen}
        onClose={() => {
          dispatch(onCloseModal());
          dispatch(resetListModal());
        }}
      >
        {ListModals[ListModals.length - 1]}
      </GlobalModal>
    </ConfigProvider>
  );
}

export default App;
