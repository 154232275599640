import { CSSProperties, ReactNode, useEffect, useState } from "react";
import { mergeStylesCSS } from "../../../helpers/helper";
import MenuContainer from "./components/MenuContainer";
import style from "./DropDown.module.scss";

const DropDown = ({
  children,
  menuClasses,
  icon,
  activeClass,
  setVisibleOut,
  customStyle,
  id,
}: PropTypes) => {
  const [isVisible, setVisible] = useState(false);

  const onClickOutside = () => {
    setVisible(false);
  };

  const onClickMenu = () => {
    if (isVisible) {
      return;
    }
    setVisible(!isVisible);
  };

  useEffect(() => {
    if (setVisibleOut !== undefined) {
      setVisible(false);
    }
  }, [setVisibleOut]);

  return (
    <div
      className={mergeStylesCSS([
        style["dropdown"],
        isVisible && style["active"],
        isVisible && activeClass,
      ])}
      data-toggle={isVisible}
      id={id}
      onClick={onClickMenu}
    >
      {typeof icon === "function" ? icon({ isVisible }) : icon}
      {isVisible && (
        <MenuContainer
          style={style}
          customStyle={customStyle}
          className={mergeStylesCSS([menuClasses])}
          onClickMenu={onClickOutside}
        >
          {children}
        </MenuContainer>
      )}
    </div>
  );
};

type PropTypes = {
  children: ReactNode;
  menuClasses?: string;
  icon?: ReactNode | Function;
  activeClass?: string;
  setVisibleOut?: boolean;
  customStyle?: CSSProperties;
  id?: string;
};

export default DropDown;
