import React from "react";
import styles from "./LoadingRing.module.scss";

type LoadingRingProps = {
  size?: "xs" | "sm" | "md" | "lg";
  color?: "black" | "white";
  classNames?: string;
};

const LoadingRing: React.FC<LoadingRingProps> = (props) => {
  const { size, color } = props;
  return (
    <div
      className={`${styles["lds-ring"]} ${size ? styles[size] : undefined} ${
        color ? styles[color] : ""
      } ${props.classNames || ""}`}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default LoadingRing;
