import { useEffect, useState } from "react";

import Layout from "../../../components/Layout/Layout";
import { ROLE_PERMISSIONS_FLATTEN } from "../../../helpers/permissionConstant";
import { checkPermissionByRoleId } from "../../../helpers/permissionFactory";
import { useAppSelector } from "../../../store/store";
import { selectAuthUser } from "../../auth/redux/authSlice";
import NoPermission from "../common/no-permission";
import Filter from "./components/filter";
import Header from "./components/header";
import ModalHistoryPerformance from "./components/history";
import PerformanceTable from "./components/table";
import {
  DISPLAY_TARGET,
  DISPLAY_TYPES,
  STATUS_PERFORMANCE,
  STATUS_TABLE,
  TABLE_TYPE,
} from "./constants";
import { createExportConfiguration, generateDateHeader } from "./helper";
import { chunk } from "lodash";
import {
  ApprovalRequestParameterType,
  FilterPerformanceDataType,
  HeaderTableDataType,
} from "./interfaces";
import usePerformanceManagement from "./redux/hook";
import styles from "./style.module.scss";

const PerformanceManagement = () => {
  const {
    fetchUserDetail,
    concurrentlyWorkspace,
    userInformation,
    setDefaultToggle,
    resetToggleTrace,
    fetchGoalCategory,
    updateStatusTable,
    fetchListEmployee,
    sentApproveRequest,
    fetchListWorkspace,
    fetchAdditionSubject,
    exportExcelOneSubject,
    resetStatePerformance,
    updateSubjectHierarchy,
    fetchEmployeeManagement,
    updateFilterConfiguration,
    exportExcelMultipleSubject,
    fetchPerformanceByOneSubject,
    fetchPerformanceByManySubject,
    mapEmployee,
    displayType,
    mapWorkspace,
    mapProportion,
    followSubject,
    displayTarget,
    selectedSubject,
    expandedSubject,
    filterConfiguration,
    mapEmployeeManagement,
  } = usePerformanceManagement();
  const { G_PERFORMANCE } = ROLE_PERMISSIONS_FLATTEN;
  const PERMISSION = useAppSelector(selectAuthUser)?.permissions;
  const [userStructure, setUserStructure] = useState<HeaderTableDataType[]>([]);
  const [type, setTableType] = useState(TABLE_TYPE.USER);
  const _getData = async (ids: string[]) => {
    let result: { id: number; parent: number | null }[] = [];
    const idsString = ids.join(",");
    const dataResponse = await fetchEmployeeManagement(idsString);

    dataResponse.payload.data.forEach((user: any) => {
      const _managements = user.managements;
      const rootEmployee = { id: Number(user.id), parent: null };
      result.push(rootEmployee);
      _managements.forEach((workspace: any) => {
        if ("directly" in workspace) {
          const data = workspace.directly.map((_employee: any) => ({
            id: Number(_employee.id),
            parent: Number(user.id),
          }));
          result = result.concat(data);
        }
      });
    });
    return result;
  };
  const getManagementUsers = async (assignee_id: string[]) => {
    let result: { id: number; parent: number | null }[] = [];
    const listId = [...new Set(assignee_id)];
    try {
      const chunkArr = chunk(listId, 100);

      const listRequest: Promise<any>[] = chunkArr.map((item) =>
        _getData(item)
      );
      const arrRes = await Promise.all(listRequest);
      arrRes.forEach((item) => {
        result = result.concat(item);
      });
    } catch (error) {
      throw error;
    }
    return result;
  };
  function createUserDataSet(employee: any, parent = null) {
    return {
      id: Number(employee.id),
      name: String(employee?.name || employee?.user_name),
      email: employee.email,
      position: employee?.roles?.name,
      level: employee?.roles?.level ?? Infinity,
      parent: parent,
    };
  }
  const createMultipleUserPerformanceConfig = async (
    params: FilterPerformanceDataType
  ) => {
    // prepare user data

    const listIds = params.assignee_id.split(",");
    let result = await getManagementUsers(listIds);
    let followSubject = result.filter((item: any) => item.parent !== null);
    let selectedSubject = result.filter((item: any) => item.parent === null);
    selectedSubject = selectedSubject.filter(
      (item) => !followSubject.includes(item)
    );
    updateSubjectHierarchy({
      followSubject: followSubject.map((item: any) => item.id),
      selectedSubject: selectedSubject.map((item: any) => item.id),
    });
    const mapToggleInitial: { [key: number]: boolean } = {};
    selectedSubject
      .map((item: any) => item.id)
      .forEach((item) => {
        mapToggleInitial[item] = true;
      });
    setDefaultToggle(mapToggleInitial);
    const dataHeader: any[] = result
      .map((employee: any) => {
        const information = mapEmployee[employee.id];
        if (information) {
          return {
            ...employee,
            ...createUserDataSet(information, employee.parent),
          };
        }
        return null;
      })
      .filter((item) => item);
    setUserStructure(() => dataHeader as HeaderTableDataType[]);
    let listIdJoined = dataHeader.map((item) => item.id);
    const _listIdJoined = [...new Set(listIdJoined)];
    const chunkPerformance = chunk(_listIdJoined, 100);
    chunkPerformance.forEach((ids) => {
      const listId = ids.join(",");
      fetchPerformanceByManySubject({ ...params, assignee_id: listId });
    });

    setTableType(TABLE_TYPE.USER);
  };
  const createWorkspacePerformanceConfig = async (
    params: FilterPerformanceDataType
  ) => {
    const workspaceId: string[] = params.assignee_id.split(",");
    const dataHeader: HeaderTableDataType[] = workspaceId.map(
      (item: string) => {
        return getData(mapWorkspace[Number(item)]) as HeaderTableDataType;
      }
    );
    const listIdJoined = dataHeader.map((item) => item.id).join(",") ?? [];
    fetchPerformanceByManySubject({ ...params, assignee_id: listIdJoined });
    setUserStructure(() => dataHeader);
    setTableType(TABLE_TYPE.WORKSPACE);
  };

  const onSearchSingleSubjectConfig = async (
    params: FilterPerformanceDataType
  ) => {
    fetchPerformanceByOneSubject(params);
    if (Array.isArray(params.month)) {
      let dataHeader: HeaderTableDataType[] = generateDateHeader(params.month);
      setUserStructure(() => dataHeader);
    }
    setTableType(TABLE_TYPE.DATE);
  };
  const getData = (workspace: any, isUseHeader = false) => {
    if (workspace)
      return {
        id: workspace.id,
        name: workspace.name,
        parent:
          isUseHeader && selectedSubject.includes(workspace.id)
            ? null
            : workspace.parent,
        level: workspace.level,
      };
  };
  const createListIdWorkspaceWillBeFetch = (
    params: FilterPerformanceDataType
  ) => {
    try {
      const assignee_id = params.assignee_id
        .split(",")
        .map((item) => Number(item));
      const mapArr: [string, boolean][] =
        assignee_id.map((item: number) => [String(item), true]) || [];
      let workspacesUsed: Map<string, boolean> = new Map(mapArr);
      const subWorkspace: number[] = Object.keys(mapWorkspace)
        .filter((key) =>
          workspacesUsed.get(String(mapWorkspace[Number(key)]?.parent_id))
        )
        .map((item) => Number(item))
        .map((item) => Number(item));
      return {
        selectedSubject: assignee_id,
        followSubject: subWorkspace,
      };
    } catch (error) {
      throw error;
    }
  };
  const createMultipleWorkspacePerformanceConfig = ({
    params,
  }: {
    params: FilterPerformanceDataType;
  }) => {
    let { selectedSubject, followSubject } =
      createListIdWorkspaceWillBeFetch(params);
    // console.log({ selectedSubject, followSubject });
    // selectedSubject = selectedSubject.filter(
    //   (item) => !followSubject.includes(item)
    // );
    updateSubjectHierarchy({
      followSubject: followSubject,
      selectedSubject: selectedSubject,
    });
    let hideExpandIcon = [...concurrentlyWorkspace.staff];
    if (!userInformation?.workspace?.is_owner)
      hideExpandIcon.push(userInformation?.workspace?.id);
    const showExpandIcon = [...concurrentlyWorkspace.owner];
    if (userInformation.workspace.is_owner)
      showExpandIcon.push(userInformation.workspace.id);
    hideExpandIcon = hideExpandIcon.filter(
      (item) => !showExpandIcon.includes(item)
    );
    const mapToggleInitial: { [key: number]: boolean } = {};
    selectedSubject.forEach((item) => {
      if (hideExpandIcon.includes(item)) {
        mapToggleInitial[item] = false;
      } else mapToggleInitial[item] = true;
    });
    setDefaultToggle(mapToggleInitial);

    createWorkspacePerformanceConfig({
      ...params,
      assignee_id: [...selectedSubject, ...followSubject].join(","),
    });
  };
  const onSearchPerformance = async ({
    params,
    displayType,
    displayTarget,
  }: {
    params: FilterPerformanceDataType;
    displayType: number;
    displayTarget: number;
  }) => {
    resetToggleTrace();
    const isMultipleView = displayType === DISPLAY_TYPES.MULTIPLE_SUBJECT;
    const isWorkspaceTarget = displayTarget === DISPLAY_TARGET.UNIT;
    switch (true) {
      case isMultipleView && isWorkspaceTarget:
        createMultipleWorkspacePerformanceConfig({ params });
        break;
      case isMultipleView && !isWorkspaceTarget:
        createMultipleUserPerformanceConfig(params);
        break;
      case !isMultipleView && isWorkspaceTarget:
        onSearchSingleSubjectConfig(params);
        break;
      case !isMultipleView && !isWorkspaceTarget:
        onSearchSingleSubjectConfig(params);
        break;
      default:
        break;
    }
    updateFilterConfiguration({ ...params, displayType, displayTarget });
  };

  const convertFilterApprovalRequestParams = (
    params: FilterPerformanceDataType
  ) => {
    let result: ApprovalRequestParameterType = {
      type: params.type,
      type_unit: params.type_unit,
      assignee_ids: Array.isArray(params.assignee_id)
        ? params.assignee_id.map((item) => Number(item))
        : [Number(params.assignee_id)],
      months: Array.isArray(params.month) ? params.month : [params.month],
      status: STATUS_PERFORMANCE.SENT,
    };
    return result;
  };
  const refreshData = (ids?: string) => {
    updateStatusTable(STATUS_TABLE.LOADING);
    const params = {
      type: filterConfiguration.type,
      type_unit: filterConfiguration.type_unit,
      month: filterConfiguration.month,
      status: filterConfiguration.status,
      assignee_id: filterConfiguration.assignee_id,
    };
    if (displayType === DISPLAY_TYPES.MULTIPLE_SUBJECT) {
      params.assignee_id = ids;
      fetchPerformanceByManySubject(params);
    } else if (displayType === DISPLAY_TYPES.SINGLE_SUBJECT) {
      fetchPerformanceByOneSubject(params);
    }
    updateStatusTable(STATUS_TABLE.LOADED);
  };
  const onSentApproveRequest = async () => {
    const params: ApprovalRequestParameterType =
      convertFilterApprovalRequestParams(filterConfiguration);
    if (displayType === DISPLAY_TYPES.SINGLE_SUBJECT) {
      const months: Map<number, string> = new Map(
        filterConfiguration.month.map((item: string) => {
          return [new Date(`01/${item}`).getTime(), item];
        })
      );
      let data: Array<string | undefined> = Object.keys(mapProportion)
        .filter((key) => mapProportion[key].status === STATUS_PERFORMANCE.NEW)
        .map((item) => months.get(Number(item)))
        .filter((item) => item !== undefined);
      sentApproveRequest({ ...params, months: data as string[] });
      setTimeout(() => {
        refreshData();
      }, 1000);
    } else if (displayType === DISPLAY_TYPES.MULTIPLE_SUBJECT) {
      const approvalIds = [...selectedSubject, ...followSubject].filter(
        (id: number) =>
          mapProportion[Number(id)] &&
          mapProportion[Number(id)]?.status === STATUS_PERFORMANCE.NEW
      );

      sentApproveRequest({
        ...params,
        assignee_ids: approvalIds,
      });
      setTimeout(() => {
        refreshData(approvalIds.join(","));
      }, 1000);
    }
  };
  const userId: any = useAppSelector(selectAuthUser)?.id;
  useEffect(() => {
    resetStatePerformance();
    updateStatusTable(STATUS_TABLE.LOADING);
    fetchUserDetail(userId);
    fetchGoalCategory();
    fetchListWorkspace();
    fetchListEmployee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataEmployee = (employee: any, isUseHeader = false) => {
    let result: {
      id: number;
      name: string;
      email: string;
      position: string | undefined;
      parent: number | null;
      level: number;
    } = {
      ...createUserDataSet(employee),
    };
    if (employee) {
      const _parent =
        isUseHeader && selectedSubject.includes(employee.id)
          ? null
          : mapEmployeeManagement[Number(employee.id)]?.find((_id: number) =>
              [
                ...selectedSubject,
                ...followSubject,
                ...expandedSubject,
              ].includes(_id)
            );

      result = {
        ...createUserDataSet(employee, _parent),
      };
    }
    return result;
  };
  const fetchAdditionEmployee = () => {
    if (
      filterConfiguration.displayType === DISPLAY_TYPES.SINGLE_SUBJECT ||
      (filterConfiguration.displayType === DISPLAY_TYPES.MULTIPLE_SUBJECT &&
        filterConfiguration.displayTarget === DISPLAY_TARGET.UNIT)
    ) {
      return;
    }
    updateStatusTable(STATUS_TABLE.LOADING);
    // update data mapProportion when click show more item in header
    const rootLevelData = selectedSubject.map((item: number) => {
      return getDataEmployee(mapEmployee[Number(item)], true);
    });
    const dependenciesData = [...followSubject, ...expandedSubject].map(
      (item: number) => {
        return getDataEmployee(mapEmployee[Number(item)]);
      }
    );

    setUserStructure([...rootLevelData, ...dependenciesData]);
    if (
      expandedSubject.length > 0 &&
      displayType === DISPLAY_TYPES.MULTIPLE_SUBJECT &&
      displayTarget === DISPLAY_TARGET.EMPLOYEE
    ) {
      fetchAdditionSubject({
        type: filterConfiguration.type,
        type_unit: filterConfiguration.type_unit,
        month: filterConfiguration.month,
        status: filterConfiguration.status,
        assignee_id: expandedSubject.join(","),
      });
    }
  };
  const fetchAdditionWorkspace = () => {
    if (filterConfiguration.displayType === DISPLAY_TYPES.SINGLE_SUBJECT) {
      return;
    }
    // update data mapProportion when click show more item in header
    const rootLevelData = selectedSubject.map((item: number) => {
      return getData(mapWorkspace[Number(item)], true) as HeaderTableDataType;
    });
    const dependenciesData = [...followSubject, ...expandedSubject].map(
      (item: number) => {
        return getData(mapWorkspace[Number(item)]) as HeaderTableDataType;
      }
    );
    setUserStructure([...rootLevelData, ...dependenciesData]);
    if (
      expandedSubject.length > 0 &&
      displayType === DISPLAY_TYPES.MULTIPLE_SUBJECT &&
      displayTarget === DISPLAY_TARGET.UNIT
    ) {
      fetchAdditionSubject({
        type: filterConfiguration.type,
        type_unit: filterConfiguration.type_unit,
        month: filterConfiguration.month,
        status: filterConfiguration.status,
        assignee_id: expandedSubject.join(","),
      });
    }
  };
  useEffect(() => {
    if (
      displayType === DISPLAY_TYPES.MULTIPLE_SUBJECT &&
      displayTarget === DISPLAY_TARGET.UNIT
    ) {
      fetchAdditionWorkspace();
    } else if (
      displayType === DISPLAY_TYPES.MULTIPLE_SUBJECT &&
      displayTarget === DISPLAY_TARGET.EMPLOYEE
    ) {
      fetchAdditionEmployee();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedSubject]);

  const exportExcel = () => {
    const params = createExportConfiguration(filterConfiguration);
    if (displayType === DISPLAY_TYPES.MULTIPLE_SUBJECT) {
      exportExcelMultipleSubject(params);
    } else if (displayType === DISPLAY_TYPES.SINGLE_SUBJECT) {
      exportExcelOneSubject(params);
    }
  };
  return (
    <Layout>
      {checkPermissionByRoleId(
        G_PERFORMANCE.PERFORMANCE_MANAGEMENT_VIEW_LIST,
        PERMISSION
      ) ? (
        <div className={`${styles.wrapper} `}>
          <Header exportExcel={exportExcel} />
          <Filter onSearchPerformance={onSearchPerformance} />
          <div className={styles.body}>
            <PerformanceTable
              userStructure={userStructure}
              type={type}
              onSentApproveRequest={onSentApproveRequest}
            />
          </div>
          <ModalHistoryPerformance />
        </div>
      ) : (
        <NoPermission />
      )}
    </Layout>
  );
};
export default PerformanceManagement;
