const SERVICE_URL_KPI_API = process.env.REACT_APP_API_KPI + "/api/";
const SERVICE_URL_KPI_BASE = process.env.REACT_APP_API_KPI + "/";
const SERVICE_URL_KPI_RESOURCE_ENDPOINT =
  process.env.REACT_APP_API_KPI_RESOURCE_ENDPOINT + "/api/";
const KPI_RESOURCE_KEY = process.env.REACT_APP_KPI_RESOURCE_KEY;
const SERVICE_URL_ACCOUNT_BASE =
  process.env.REACT_APP_API_ACCOUNT_BASE_URL + "/api/v2/";
const ACCOUNT_SERVICE_KEY = process.env.REACT_APP_KPI_APP_TOKEN;
const WsCode = {
  param: {
    list: "parameters",
    fieldList: "resources/code/",
    endpointSource: "resources",
    genResourceCode: "parameters/generate-code",
    export: "parameters/export",
  },
  goalGroup: {
    list: "goal-group",
  },
  goal: {
    list: 'goals',
    parameters: 'parameters/all?status=1',
    goalGroup: 'goal-categories',
    validate: 'goals/validate-math',
    departments: 'goal-departments/lists?status=1',
    addDepartments: 'goal-departments',
    export: 'goals/export',
    viewDraft: 'goal-settings/drafts',
    settingGoal: 'goal-settings',
    paramsByIds: 'goal-settings/get-by-parameter-ids'
  },
  kpiManaggement: {
    list: "kpi/list-kpi",
    detail: "kpi/detail-kpi",
    update: "kpi/update-kpi",
    departments: "kpi/get-data/departments",
    divisions: "kpi/get-data/divisions",
    employees: "kpi/get-data/employees",
    goals: "kpi/get-data/search-goal",
    addKpi: "kpi",
    createKpiData: "kpi/data-create",
    deleteAll: "kpi/deleteAll",
  },
  dataManagement: {
    list: "media",
    download: "media/download",
    upload: "media/upload",
    configsResource: "resources/configs",
    resources: "resources",
  },
  syncHistory: {
    list: "/upload-histories",
    export: "sync-history/export",
    employees: "users",
    downloadExcel: "files/download",
    runBack: "goal-performances/import",
  },
  computingManagement: {
    list: "/goal-performances/list",
    goals: "/goals",
    addGoal: "/goal-performances",
    downloadTemplate: "/goal-performances/template",
    exportExcel: "/goal-performances/export",
    workspaces: "workspaces",
    employees: "users",
    edit: "/goal-performances",
    detail: "/goal-performances",
    upload: "/files",
    importGoal: "goal-performances/import",
    delete: "/goal-performances",
  },
  resultManagement: {
    list: "/goal-performances/list",
    results: "/goals",
    addResult: "/goal-performances/result",
    downloadTemplate: "/goal-performances/result/template",
    exportExcel: "/goal-performances/export",
    workspaces: "workspaces",
    performances: "goal-performances/list-unit-goal",
    goalAssignee: "goal-performances/list-unit-goal",
    employees: "users",
    edit: "/goal-performances/result",
    detail: "/goal-performances",
    upload: "/files",
    importResult: "goal-performances/result/import",
    delete: "/goal-performances/result",
    calculation: "/goal-performances/result/calculation-performance",
  },
  listStatus: {
    list: "/goal-performances",
    goals: "/goals",
    addGoal: "/goal-performances",
    downloadTemplate: "/goal-performances/template",
    exportExcel: "/performances/export-list-status",
    workspaces: "workspaces",
    employees: "users",
    edit: "/goal-performances",
    detail: "/goal-performances",
    upload: "/files",
    importGoal: "goal-performances/import",
    delete: "/goal-performances",
    listPerformance: "/performances/performance-of-many-unit",
    sendApprove: "/performances/send-approval-request",
    users: "users/",
  },

  auth: {
    generateToken: "/generate-token",
  },
  performanceManagement: {
    workspaces: "workspaces",
    userDetail: "users",
    performanceOfOneSubject: "performances/performance-of-one-unit",
    performanceOfManySubject: "performances/performance-of-many-unit",
    history: "performances/list-performance-history",
    goalCategory: "goal-categories",
    sentRequestApproval: "performances/send-approval-request",
    userManagement: "users/managements",
    userJobs: "users/jobs",
    exportExcelOneSubject: "performances/export-performance-one-unit",
    exportExcelMultipleSubject: "performances/export-performance-of-many-unit",
    users: "users",
    management: (id: string) => `users/managements?ids=${id}`,
  },
  accountServiceV2: {},
  resultApprove: {
    listObjectApprove: "/performances/approve/list-workspace",
    listEmployeeApprove: "/performances/approve/list-user",
    exportExcel: "/performances/approve/export",
    workspaces: "workspaces",
    employees: "users",
    upload: "/files",
    importResult: "result-goal-performances/import",
    delete: "/result-goal-performances",
    deleteFile: "/files",
    scoreApprove: "/performances/approved-performance",
    update: "/performances/approve/update",
    colors: "color",
    calculation: "/performances/approve/calculation-performance",
  },
  gPerformanceDashboard: {
    list: "list",
    employee: "employee",
    dashboardCEO: "dashboard/ceo",
  },
  gPerformanceDashboardNonCeo: {
    listPerformance: "dashboard/non-ceo",
    detailPerformanceUser: "dashboard/user",
    detailPerformanceCeo: "dashboard/ceo/self",
    detailPerformanceWorkspace: "dashboard/workspace",
    overviewPerformance: "dashboard/overview",
    exportNonCeo: "dashboard/non-ceo/export",
    exportCEO:"dashboard/ceo/export-ceo"
  },
};

export {
  SERVICE_URL_KPI_API,
  SERVICE_URL_KPI_BASE,
  SERVICE_URL_KPI_RESOURCE_ENDPOINT,
  KPI_RESOURCE_KEY,
  WsCode,
  SERVICE_URL_ACCOUNT_BASE,
  ACCOUNT_SERVICE_KEY,
};
