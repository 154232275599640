import { createAsyncThunk } from '@reduxjs/toolkit';
import { pick } from 'lodash'
import { openNotification, TOAST_TYPE } from '../utils';
import {
  AddResultListSchema,
  FilterParam,
  UpdateResultSchema
} from '../utils/interface';
import ResultPerformanceApi from './api';
import { clearSlice, setErrorAddResult, setErrorEditResult, setCodeErrorAddResult } from './slice';

export const getList = createAsyncThunk(
  'gperformanceResultManagement/getList',
  async (params: FilterParam) => {
    try {
      const response = await ResultPerformanceApi.list(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListFilterResult = createAsyncThunk(
  'gperformanceResultManagement/getListFilterResult',
  async (params: FilterParam) => {
    try {
      const response = await ResultPerformanceApi.list(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListWorkspace = createAsyncThunk(
  'gperformanceResultManagement/getListWorkspace',
  async (params?: any) => {
    try {
      const response = await ResultPerformanceApi.listWorkspace(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListPerformance = createAsyncThunk(
  'gperformanceResultManagement/getListPerformance',
  async (params?: any) => {
    try {
      const response = await ResultPerformanceApi.listPerformances(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListGoalAssignee = createAsyncThunk(
  'gperformanceResultManagement/getListGoalAssignee',
  async (params?: any) => {
    try {
      const response = await ResultPerformanceApi.listGoalAssignee(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListResult = createAsyncThunk(
  'gperformanceResultManagement/getListResult',
  async (params?: any) => {
    try {
      const response = await ResultPerformanceApi.listResult({...params, is_sort: 1});
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListEmployee = createAsyncThunk(
  'gperformanceResultManagement/getListEmployee',
  async (params?: { status: number }) => {
    try {
      const response = await ResultPerformanceApi.listEmployee(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListMore = createAsyncThunk(
  'gperformanceResultManagement/getListMore',
  async (params: FilterParam) => {
    try {
      const response = await ResultPerformanceApi.list(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const add = createAsyncThunk(
  'gperformanceResultManagement/add',
  async (
    payload: { body: AddResultListSchema; message: any },
    { dispatch, getState }
  ) => {
    const { body, message } = payload;
    try {
      const response = await ResultPerformanceApi.add(body);
      const state: any = getState();
      dispatch(getList(state.gPerformanceResultManagement.filterParams));
      openNotification({
        type: TOAST_TYPE.Success,
        message: message.success.create
      });
      dispatch(setErrorAddResult(null));
      dispatch(setCodeErrorAddResult(null));
      return response;
    } catch (error: any) {
      dispatch(setErrorAddResult(error?.response?.data?.data));
      dispatch(setCodeErrorAddResult(error?.response?.data?.code));
      throw error;
    }
  }
);

export const detail = createAsyncThunk(
  'gperformanceResultManagement/detail',
  async (id: number) => {
    try {
      const response = await ResultPerformanceApi.detail(id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const update = createAsyncThunk(
  'gperformanceResultManagement/update',
  async (
    payload: { id: number; body: UpdateResultSchema; message: any },
    { dispatch, getState }
  ) => {
    const { id, body, message } = payload;
    try {
      const response = await ResultPerformanceApi.update(id, body);
      const state: any = await getState();
      dispatch(
        getList({
          ...state.gPerformanceResultManagement.filterParams,
          page: 1
        })
      );
      openNotification({
        type: TOAST_TYPE.Success,
        message: message
      });
      return response.data;
    } catch (error: any) {
      dispatch(setErrorEditResult(error?.response?.data));
      throw error;
    }
  }
);

export const deleteResult = createAsyncThunk(
  'gperformanceResultManagement/deleteResultGroup',
  async (params: { id: number; message: any }, { dispatch, getState }) => {
    const { id, message } = params;
    dispatch(clearSlice());
    try {
      const response = await ResultPerformanceApi.delete(id);
      const state: any = getState();
      dispatch(
        getList({
          ...state.gPerformanceResultManagement.filterParams,
          page: 1
        })
      );
      openNotification({
        type: TOAST_TYPE.Success,
        message: message.success.delete
      });
      return response;
    } catch (error) {
      openNotification({
        type: TOAST_TYPE.Error,
        message: message.error.delete
      });
      throw error;
    }
  }
);

export const downloadTemplate = createAsyncThunk(
  'gperformanceResultManagement/downloadTemplate',
  async (_, { dispatch }) => {
    dispatch(clearSlice());
    try {
      const response = await ResultPerformanceApi.downloadTemplate();
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const exportExcel = createAsyncThunk(
  'gperformanceResultManagement/exportExcel',
  async (params: FilterParam, { dispatch }) => {
    dispatch(clearSlice());
    try {
      const response = await ResultPerformanceApi.exportExcel(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const importResult = createAsyncThunk(
  'gperformanceResultManagement/importResult',
  async (fromData: any, { dispatch }) => {
    try {
      const response = await ResultPerformanceApi.importResult(fromData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const upload = createAsyncThunk(
  'gperformanceResultManagement/upload',
  async (fromData: any, { dispatch }) => {
    try {
      const response = await ResultPerformanceApi.upload(fromData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const calculation = createAsyncThunk(
  'gperformanceResultManagement/calculation',
  async (params: {filter: FilterParam, message: any }, { dispatch }) => {
    const { filter, message } = params;
    try {
      const paramsCalculation = pick(filter, ['type_unit', 'assignee_id', 'month', 'type'])
      const response = await ResultPerformanceApi.calculation(paramsCalculation);
      openNotification({
        type: TOAST_TYPE.Success,
        message:  message.success.calculation,
      });
      return response;
    } catch (error) {
      openNotification({
        type: TOAST_TYPE.Error,
        message: message.error.calculation
      });
      throw error;
    }
  }
);
