import { RenderExpandIcon, TriggerEventHandler } from "rc-table/lib/interface";
import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";
import { DataType } from "../../../../interfaces";
import styles from "./styles.module.scss";
const ExpandIcon: RenderExpandIcon<DataType> = ({
  expanded,
  record,
  onExpand,
}: {
  expanded: boolean;
  record: DataType;
  onExpand: TriggerEventHandler<DataType>;
}) => {
  if (!Array.isArray(record.children) || record.children.length === 0) {
    return null;
  }
  return expanded ? (
    <div className={styles["toggle-icon"]}>
      <CaretUpFilled
        size={10}
        color={"white"}
        style={{ fontSize: 10, color: "white" }}
        onClick={(e) => onExpand(record, e)}
      />
    </div>
  ) : (
    <div className={styles["toggle-icon"]}>
      <CaretDownFilled
        style={{ fontSize: 10, color: "white" }}
        size={10}
        color={"white"}
        onClick={(e) => onExpand(record, e)}
      />
    </div>
  );
};
export default ExpandIcon;
