import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../store/store";
import {
  DISPLAY_TARGET,
  DISPLAY_TYPES,
  STATUS_TABLE,
} from "../constants/index";
import {
  getListWorkspace,
  getListEmployee,
  getEmployeeDetail,
  getPerformanceByManySubject,
  getGoalCategory,
  getPerformanceByOneSubject,
  getHistoryPerformance,
  exportExcelOneSubject,
  exportExcelMultipleSubject,
  fetchAdditionSubject,
  fetchDataUserManagement,
  fetchDataUserJobs,
} from "./thunk";
import {
  convertGoalCategory,
  convertHistory,
  convertMapEmployee,
  getDirectManagement,
  mapManagementByResponse,
  mapResultPerformanceManySubject,
  mapResultPerformanceOneSubject,
  mapWorkSpaceIntoList,
  mapWorkSpaceIntoTree,
} from "../helper";
import {
  GoalCategoryType,
  HistoryParameterType,
  TreeNodeDataType,
  WorkspaceTypeSingle,
} from "../interfaces";
import { downloadFile } from "../../../../api/MediaApi";
interface StateType {
  toggleMap: {
    [key: string]: boolean;
  };
  displayType: number;
  displayTarget: number;
  roleType: string;
  workspaces: TreeNodeDataType[];
  employees: any;
  userInformation: any;
  goalCategory: GoalCategoryType[];
  mapProportion: { [key: number | string]: any };
  mapEmployee: { [key: number]: any };
  mapWorkspace: { [key: number]: WorkspaceTypeSingle };
  renderRowMap: { [key: string | number]: boolean };
  historyPerformance: HistoryParameterType | null;
  dataHistoryPerformance: any;
  statusTable: number;
  filterConfiguration: any;
  selectedSubject: number[];
  followSubject: number[];
  expandedSubject: number[];
  mapEmployeeManagement: { [key: number]: any };
  mapManagement: { [key: number]: any[] };
  concurrentlyWorkspace: { owner: number[]; staff: number[] };
}
const initialState: StateType = {
  roleType: "",
  toggleMap: {},
  displayType: DISPLAY_TYPES.MULTIPLE_SUBJECT,
  displayTarget: DISPLAY_TARGET.EMPLOYEE,
  workspaces: [],
  employees: [],
  userInformation: {},
  goalCategory: [],
  mapProportion: {},
  mapEmployee: {},
  mapWorkspace: {},
  renderRowMap: {},
  historyPerformance: null,
  statusTable: STATUS_TABLE.LOADING,
  filterConfiguration: {},
  dataHistoryPerformance: [],
  selectedSubject: [],
  followSubject: [],
  expandedSubject: [],
  mapEmployeeManagement: {},
  mapManagement: {},
  concurrentlyWorkspace: { owner: [], staff: [] },
};
const PerformanceManagementSlice = createSlice({
  name: "performanceManagement",
  initialState,
  reducers: {
    resetStatePerformance(state: StateType) {
      state = initialState;
    },
    resetToggleTrace(state: StateType) {
      state.selectedSubject = [];
      state.followSubject = [];
      state.expandedSubject = [];
      state.toggleMap = {};
      state.renderRowMap = {};
      state.mapProportion = {};
      state.mapEmployeeManagement = {};
      state.mapManagement = {};
      state.filterConfiguration = {};
    },
    setDefaultToggle(state: StateType, action) {
      state.toggleMap = action.payload;
    },
    updateSubjectHierarchy(state: StateType, action) {
      state.selectedSubject = action.payload.selectedSubject;
      state.followSubject = action.payload.followSubject;
    },
    updateStatusTable: (state: StateType, action) => {
      state.statusTable = action.payload;
    },
    updateToggle: (state: StateType, action) => {
      const id = action.payload as string;
      state.toggleMap[id] = !state.toggleMap[id];
    },
    updateDisplayTarget: (state: StateType, action) => {
      state.displayTarget = action.payload;
    },
    updateRoleType: (state: StateType, action) => {
      state.roleType = action.payload;
    },
    updateDisplayType: (state: StateType, action) => {
      state.displayType = action.payload;
    },
    openHistoryPerformance: (state: StateType, action) => {
      state.historyPerformance = action.payload;
    },
    updateFilterConfiguration: (state: StateType, action) => {
      state.filterConfiguration = action.payload;
    },
    addFollowSubject: (state: StateType, action) => {
      state.expandedSubject = [
        ...new Set([...state.expandedSubject, ...action.payload]),
      ];
    },
  },
  extraReducers(builder) {
    builder.addCase(getListWorkspace.fulfilled, (state, action) => {
      state.workspaces = mapWorkSpaceIntoTree(action.payload.data);
      state.mapWorkspace = mapWorkSpaceIntoList(action.payload.data);
    });
    builder.addCase(getListEmployee.fulfilled, (state, action) => {
      state.employees = action.payload.data;
      state.mapEmployee = convertMapEmployee(action.payload.data);
    });
    builder.addCase(getEmployeeDetail.fulfilled, (state, action) => {
      state.userInformation = action.payload.data;
      const owner: number[] = [];
      const staff: number[] = [];
      const callbackFunc = (item: any) => {
        if (item.is_owner) {
          owner.push(item?.workspace?.id);
        } else {
          staff.push(item?.workspace?.id);
        }
      };

      action.payload.data.concurrents.forEach(callbackFunc);
      action.payload.data.beneficiaries.forEach(callbackFunc);
      state.concurrentlyWorkspace = { owner, staff };
      // getting owner and staff workspace where employer has job/role
    });
    builder.addCase(getPerformanceByManySubject.fulfilled, (state, action) => {
      const data = mapResultPerformanceManySubject(action.payload.data);
      let keysInclude: { [key: string | number]: boolean } = {};
      Object.entries(data).forEach(([key, value]) => {
        Object.entries(value).forEach(([_key, value]) => {
          keysInclude[_key] = true;
        });
      });
      state.renderRowMap = { ...state.renderRowMap, ...keysInclude };
      state.mapProportion = { ...state.mapProportion, ...data };
      state.statusTable = STATUS_TABLE.LOADED;
    });
    builder.addCase(getPerformanceByOneSubject.pending, (state, action) => {
      state.statusTable = STATUS_TABLE.LOADING;
    });
    builder.addCase(getPerformanceByManySubject.pending, (state, action) => {
      state.statusTable = STATUS_TABLE.LOADING;
    });
    builder.addCase(getPerformanceByOneSubject.fulfilled, (state, action) => {
      const data = mapResultPerformanceOneSubject(action.payload.data);
      let keysInclude: { [key: string | number]: boolean } = {};
      Object.entries(data).forEach(([key, value]) => {
        Object.entries(value).forEach(([_key, value]) => {
          keysInclude[_key] = true;
        });
      });
      state.renderRowMap = { ...state.renderRowMap, ...keysInclude };
      state.mapProportion = { ...state.mapProportion, ...data };
      state.statusTable = STATUS_TABLE.LOADED;
    });
    builder.addCase(getGoalCategory.fulfilled, (state, action) => {
      const data = convertGoalCategory(action.payload.data);
      state.goalCategory = data;
    });

    builder.addCase(getHistoryPerformance.fulfilled, (state, action) => {
      state.dataHistoryPerformance = convertHistory(action.payload.data);
    });
    builder.addCase(exportExcelOneSubject.fulfilled, (state, action) => {
      downloadFile(action.payload);
    });
    builder.addCase(exportExcelMultipleSubject.fulfilled, (state, action) => {
      downloadFile(action.payload);
    });
    builder.addCase(fetchAdditionSubject.pending, (state, action) => {
      state.statusTable = STATUS_TABLE.LOADING;
    });
    builder.addCase(fetchAdditionSubject.fulfilled, (state, action) => {
      const data = mapResultPerformanceManySubject(action.payload.data);
      let keysInclude: { [key: string | number]: boolean } = {};
      Object.entries(data).forEach(([key, value]) => {
        Object.entries(value).forEach(([_key, value]) => {
          keysInclude[_key] = true;
        });
      });
      state.renderRowMap = { ...state.renderRowMap, ...keysInclude };
      state.mapProportion = { ...state.mapProportion, ...data };
      state.statusTable = STATUS_TABLE.LOADED;
    });
    builder.addCase(fetchDataUserManagement.pending, (state, action) => {
      state.statusTable = STATUS_TABLE.LOADING;
    });
    builder.addCase(fetchDataUserManagement.fulfilled, (state, action) => {
      const _mapManagement = mapManagementByResponse(action.payload.data);
      const resultManagement = getDirectManagement(_mapManagement);

      state.mapManagement = { ...state.mapManagement, ..._mapManagement };
      state.mapEmployeeManagement = {
        ...state.mapEmployeeManagement,
        ...resultManagement,
      };
    });
    builder.addCase(fetchDataUserJobs.fulfilled, (state, action) => {
      const user = action.payload.data.find((user: any) => {
        return (
          user && state.userInformation && user.id === state.userInformation?.id
        );
      });
      const jobs = user.jobs.map((item: any) => item?.workspace?.id);
      state.concurrentlyWorkspace = jobs;
    });
  },
});
export const {
  updateToggle,
  updateDisplayTarget,
  updateDisplayType,
  openHistoryPerformance,
  updateStatusTable,
  updateFilterConfiguration,
  updateSubjectHierarchy,
  addFollowSubject,
  resetToggleTrace,
  setDefaultToggle,
  updateRoleType,
  resetStatePerformance,
} = PerformanceManagementSlice.actions;
export default PerformanceManagementSlice.reducer;
export const toggleMap = (state: RootState) =>
  state.gPerformanceManagement.toggleMap;
export const roleType = (state: RootState) =>
  state.gPerformanceManagement.roleType;
export const displayType = (state: RootState) =>
  state.gPerformanceManagement.displayType;
export const displayTarget = (state: RootState) =>
  state.gPerformanceManagement.displayTarget;
export const workspaces = (state: RootState) =>
  state.gPerformanceManagement.workspaces;
export const employees = (state: RootState) =>
  state.gPerformanceManagement.employees;
export const userInformation = (state: RootState) =>
  state.gPerformanceManagement.userInformation;
export const goalCategory = (state: RootState) =>
  state.gPerformanceManagement.goalCategory;
export const mapEmployee = (state: RootState) =>
  state.gPerformanceManagement.mapEmployee;
export const mapProportion = (state: RootState) =>
  state.gPerformanceManagement.mapProportion;
export const mapWorkspace = (state: RootState) =>
  state.gPerformanceManagement.mapWorkspace;
export const renderRowMap = (state: RootState) =>
  state.gPerformanceManagement.renderRowMap;
export const historyPerformance = (state: RootState) =>
  state.gPerformanceManagement.historyPerformance;
export const statusTable = (state: RootState) =>
  state.gPerformanceManagement.statusTable;
export const filterConfiguration = (state: RootState) =>
  state.gPerformanceManagement.filterConfiguration;
export const dataHistoryPerformance = (state: RootState) =>
  state.gPerformanceManagement.dataHistoryPerformance;
export const selectedSubject = (state: RootState) =>
  state.gPerformanceManagement.selectedSubject;
export const followSubject = (state: RootState) =>
  state.gPerformanceManagement.followSubject;
export const expandedSubject = (state: RootState) =>
  state.gPerformanceManagement.expandedSubject;
export const mapEmployeeManagement = (state: RootState) =>
  state.gPerformanceManagement.mapEmployeeManagement;
export const mapManagement = (state: RootState) =>
  state.gPerformanceManagement.mapManagement;
export const concurrentlyWorkspace = (state: RootState) =>
  state.gPerformanceManagement.concurrentlyWorkspace;
