import { createContext, useContext, useState } from "react";
import { NodeType } from "../../../components/UI/TreeOKRs/type";
import { RATING_TYPE } from "../helpers/constant";
import { rootObjectType } from "../type/type";

const MyTargetContext = createContext<any>(null);

export function useMyTarget() {
  const contextValue = useContext(MyTargetContext);
  if (contextValue === null) throw Error("context has not been Provider");
  return contextValue;
}

type ModalOkrType = {
  show: boolean;
  okr_id?: any;
  currentLevel?: any;
  parent_id?: any;
  mode: "create" | "edit";
};

type ModalSubOkrType = {
  show: boolean;
  subOkr_id?: any;
  currentLevel?: any;
  parent_id?: any;
  mode: "create" | "edit";
  sub_project_ids?: number[];
  siblingItems?: NodeType[];
  rootObject?: rootObjectType;
};

type ModalKrType = {
  show: boolean;
  kr_id?: any;
  currentLevel?: any;
  parent_id?: any;
  mode: "create" | "edit";
  key_result_ids?: number[];
  rootObject?: rootObjectType;
};

type ModalTaskType = {
  show: boolean;
  task_id?: any;
  currentLevel?: any;
  parent_id?: any;
  _onRefreshModalKR?: any;
  mode: "create" | "edit";
};

type ModalRatingType = {
  show: boolean;
  task_id?: any;
  task_name: any;
  type: number;
};

export function MyTargetProvider({ children }: { children: React.ReactNode }) {
  const [showModalOkr, setShowModalOkr] = useState<ModalOkrType>({
    show: false,
    okr_id: null,
    currentLevel: null,
    parent_id: null,
    mode: "create",
  });

  const [showModalSubOkr, setShowModalSubOkr] = useState<ModalSubOkrType>({
    show: false,
    subOkr_id: null,
    currentLevel: null,
    parent_id: null,
    mode: "create",
  });

  const [showModalKr, setShowModalKr] = useState<ModalKrType>({
    show: false,
    kr_id: null,
    currentLevel: null,
    parent_id: null,
    mode: "create",
  });

  const [showModalTask, setShowModalTask] = useState<ModalTaskType>({
    show: false,
    task_id: null,
    currentLevel: null,
    parent_id: null,
    _onRefreshModalKR: null,
    mode: "create",
  });

  const [showModalRating, setShowModalRating] = useState<ModalRatingType>({
    show: false,
    task_id: null,
    task_name: null,
    type: RATING_TYPE.MANAGER_RATING,
  });

  // DENSITY
  const [showModalDensityApprove, setShowModalDensityApprove] =
    useState<boolean>(false);
  const [showModalDensityAllocate, setShowModalDensityAllocate] =
    useState<boolean>(false);

  const value: any = {
    showModalOkr,
    setShowModalOkr,
    showModalSubOkr,
    setShowModalSubOkr,
    showModalKr,
    setShowModalKr,
    showModalTask,
    setShowModalTask,
    showModalDensityApprove,
    setShowModalDensityApprove,
    showModalDensityAllocate,
    setShowModalDensityAllocate,
    showModalRating,
    setShowModalRating,
  };

  return (
    <MyTargetContext.Provider value={value}>
      {children}
    </MyTargetContext.Provider>
  );
}
