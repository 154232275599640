function IconFilter() {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.02664 9.67924H2.35059"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.6665 4.02518H11.3426"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.09098 3.99365C5.09098 3.23785 4.47361 2.625 3.71224 2.625C2.95086 2.625 2.3335 3.23785 2.3335 3.99365C2.3335 4.74945 2.95086 5.3623 3.71224 5.3623C4.47361 5.3623 5.09098 4.74945 5.09098 3.99365Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6686 9.65637C11.6686 8.90057 11.0517 8.28772 10.2903 8.28772C9.5285 8.28772 8.91113 8.90057 8.91113 9.65637C8.91113 10.4122 9.5285 11.025 10.2903 11.025C11.0517 11.025 11.6686 10.4122 11.6686 9.65637Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconFilter;
