/* eslint-disable no-useless-concat */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CSSProperties, useEffect, useRef, useState } from "react";
import IconArroew2 from "../../icons/IconArroew2";
import IconArrow from "../../icons/IconArrow";
import IconCancel from "../../icons/IconCancel";
import style from "./style.module.scss";
import { GROUP_SCOPE, INTERNAL_SCOPE } from "../../../helpers/constant";
import { useTranslation } from "react-i18next";
import { mergeStylesCSS } from "../../../helpers/helper";

type TypeProps = {
  keys?: any;
  data: any;
  location?: {
    edge: "left" | "right" | "top" | "bottom";
    stick: "left" | "right" | "top" | "bottom";
  };
  render?: Function;
  onChange?: Function;
  placeholder?: String;
  className?: String;
  defaultValue?: any;
  itemClass?: String;
  disabled?: Boolean;
  keyValue?: any;
  notClose?: Boolean;
  eventClose?: Function;
  name?: any;
  noIcon?: Boolean;
  noSelectBox?: Boolean;
  noDefaultChange?: Boolean;
  targetType?: Number;
  locationStep?: any;
  outerStyles?: CSSProperties;
  viewOuterStyles?: CSSProperties;
  limitWidth?: boolean;
  error?: any;
};

const ComboBox = ({
  data,
  keys,
  location,
  render,
  onChange,
  placeholder,
  className,
  defaultValue,
  itemClass,
  disabled,
  keyValue,
  notClose,
  eventClose,
  name,
  noIcon,
  noSelectBox,
  noDefaultChange,
  targetType,
  locationStep,
  error,
  outerStyles,
  viewOuterStyles,
  limitWidth = false,
}: TypeProps) => {
  const [open, setOpen] = useState<any>(false);
  const [value, setValue] = useState<any>("");
  const isMounted = useRef<any>(false);
  const { t: ms } = useTranslation("gmessage", { keyPrefix: "target" });
  const handleClick = (e: any) => {
    if (e.path) {
      if (
        isMounted.current &&
        !isMounted.current.contains(e.target) &&
        e.path &&
        e.path[1] &&
        !isMounted.current.contains(e.path[1])
      ) {
        if (open === true) {
          setOpen(false);
        }
      }
    } else {
      if (isMounted.current && !isMounted.current.contains(e.target)) {
        if (open === true) {
          setOpen(false);
        }
      }
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      document.addEventListener("click", handleClick);
      if (!open) {
        return () => {
          document.removeEventListener("click", handleClick);
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (defaultValue) {
      if (keyValue && data && Array.isArray(data)) {
        let item = data.filter((vaz: any) => vaz[keyValue] === defaultValue);
        if (item && item.length) {
          if (noDefaultChange) {
            if (!value) {
              setValue(item[0]);
            }
          } else {
            setValue(item[0]);
          }
        }
      } else {
        if (noDefaultChange) {
          if (!value) {
            setValue(defaultValue);
          }
        } else {
          setValue(defaultValue);
        }
      }
    } else {
      setValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, data]);
  const notEvent = () => {};

  const handleClearData = () => {
    if (eventClose && !notClose) {
      eventClose();
      setValue(null);
    }
  };

  return (
    <>
      <input
        style={{ display: "none" }}
        value={keyValue && value ? value[keyValue] || "" : value || ""}
        name={name ? name : null}
        onChange={() => {}}
      />
      <div
        ref={isMounted}
        style={{
          border:
            error && (!value || value?.length === 0)
              ? "1px solid red"
              : "1px solid #ededed",
          ...outerStyles,
        }}
        className={
          style.container +
          " " +
          className +
          " " +
          (disabled ? style.disabled : "")
        }
      >
        {/*  */}
        <div
          onClick={() => {
            disabled ? notEvent() : setOpen(!open);
          }}
          style={viewOuterStyles}
          className={mergeStylesCSS([
            style.view,
            limitWidth && style["limit-view"],
          ])}
        >
          {value ? (
            keys ? (
              value[keys]
            ) : (
              value
            )
          ) : (
            <span>{placeholder ? placeholder : ""}</span>
          )}
        </div>
        {/*  */}
        <div
          onClick={() => {
            if (open && !notClose) {
              // setValue(null);
              setOpen(!open);
              if (onChange) {
                onChange(null);
              }
            } else {
              if (!disabled) {
                setTimeout(() => {
                  setOpen((val: any) => !val);
                }, 0);
              }
            }
          }}
          className={`${
            open
              ? notClose || disabled
                ? style.arrow
                : style.notarrow
              : style.arrow
          } center`}
        >
          {noIcon ? (
            ""
          ) : (
            <>
              {open ? (
                notClose ? (
                  <IconArroew2 />
                ) : (
                  <div onClick={handleClearData}>
                    <IconCancel />
                  </div>
                )
              ) : (
                <IconArrow />
              )}
            </>
          )}
        </div>
        {/*  */}
        {open && !disabled && !noSelectBox ? (
          <div
            className={`${style.box}  ${
              location
                ? style[location.edge] + " " + style[location.stick]
                : style.top + " " + style.right
            }`}
            style={
              targetType !== GROUP_SCOPE && locationStep === 0
                ? {
                    minHeight: "135px",
                  }
                : data && data.length < 8
                ? data.length === 1
                  ? { minHeight: `${data.length * 45 + 5}px` }
                  : data.length === 0
                  ? { minHeight: "100px" }
                  : { minHeight: `${data.length * 45}px` }
                : { minHeight: "280px" }
            }
          >
            {data && data.length ? (
              data.map((val: any, idx: any) => {
                return (
                  <div
                    onClick={() => {
                      setOpen(false);
                      setValue(val);
                      if (onChange) {
                        if (keyValue) {
                          onChange(val[keyValue], idx, val);
                        } else {
                          onChange(val, idx, val);
                        }
                      }
                    }}
                    key={idx}
                    className={
                      style.item +
                      " " +
                      (val.isHidden ? style.hidden : "") +
                      " " +
                      ((value &&
                        keyValue &&
                        value[keyValue] === val[keyValue]) ||
                      value === val
                        ? style.checked
                        : "" + " " + (itemClass ? itemClass : ""))
                    }
                  >
                    {render ? render(val, idx) : keys ? val[keys] : val}
                  </div>
                );
              })
            ) : (
              <div className={style.nodata}>{ms("NOT_OPTION")}</div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default ComboBox;
