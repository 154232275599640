import { scoreApprove } from "./thunk";
import { WsCode } from "../../api";
import { RequestApiPrivate } from "./../../api/axios";
import { ImportPayload, UpdatePayload } from "../utils";

const ResultApproveApi = {
  listObjectApprove: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.post(
        WsCode.resultApprove.listObjectApprove,
        params
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  listEmployeeApprove: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.post(
        WsCode.resultApprove.listEmployeeApprove,
        params
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  listEmployee: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(WsCode.resultApprove.employees, {
        params
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  listWorkspace: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(WsCode.resultApprove.workspaces, {
        params
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  exportExcel: async (data: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.post(
        WsCode.resultApprove.exportExcel,
        data,
        { responseType: 'blob' }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  upload: async (payload: FormData): Promise<any> => {
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };
    try {
      const response = await RequestApiPrivate.post(
        WsCode.resultApprove.upload,
        payload,
        config
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  importResult: async (payload: ImportPayload): Promise<any> => {
    try {
      const response = await RequestApiPrivate.post(
        WsCode.resultManagement.importResult,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deleteFile: async (id: number): Promise<any> => {
    try {
      const response = await RequestApiPrivate.delete(WsCode.resultApprove.deleteFile, {
        data: { ids: [id] },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  scoreApprove: async (payload: FormData): Promise<any> => {
    try {
      const response = await RequestApiPrivate.post(
        WsCode.resultApprove.scoreApprove,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  detailUser: async (id: string | number): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        `${WsCode.resultApprove.employees}/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updateResultApprove: async (id: string | number, payload: UpdatePayload): Promise<any> => {
    try {
      const response = await RequestApiPrivate.put(
        `${WsCode.resultApprove.update}/${id}`, payload
      );
      return response.data;
    } catch (error: any) {
      throw error;
    }
  },
  listColor: async (id: string | number): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        `${WsCode.resultApprove.colors}/${id}`
      )
      return response;
    } catch (error: any) {
      throw error;
    }
  },
  calculation: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.post(
        WsCode.resultApprove.calculation,
        params
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};

export default ResultApproveApi;
