import { RequestApiKpi, WsCode } from '../../api';

const KpiGoalGroupApi = {
  list: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiKpi.get(WsCode.goalGroup.list, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  add: async (payload: any): Promise<any> => {
    try {
      const response = await RequestApiKpi.post(WsCode.goalGroup.list, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  detail: async (id: number): Promise<any> => {
    try {
      const response = await RequestApiKpi.get(WsCode.goalGroup.list + `/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  update: async (id: number, payload: any): Promise<any> => {
    try {
      const response = await RequestApiKpi.put(WsCode.goalGroup.list + `/${id}`, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  delete: async (id: number): Promise<any> => {
    try {
      const response = await RequestApiKpi.delete(WsCode.goalGroup.list + `/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default KpiGoalGroupApi;
