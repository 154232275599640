import { notification } from "antd";
import {
  ErrorWaring,
  IconErrorSnackbar,
  IconSuccessSnackbar,
} from "../../../../../components/icons";
import { TOAST_TYPE } from "../../constants";

const openNotification = ({
  message,
  type,
}: {
  message?: string;
  type: string;
}) => {
  const iconType = () => {
    if (type === TOAST_TYPE.Success) return <IconSuccessSnackbar />;
    if (type === TOAST_TYPE.Error) return <IconErrorSnackbar />;
    return <ErrorWaring />;
  };

  const icon = iconType();

  return notification.open({
    message,
    icon,
    placement: "bottomRight",
    className: "kpi-wrapper",
    bottom: 10,
    duration: 5,
    closeIcon: null,
  });
};
export default openNotification;
