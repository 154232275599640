import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../store/store';
import { DEFAULT_PARAMS, openNotification, TOAST_TYPE } from '../utils';
import { FilterParam } from '../utils/interface';
import { SubjectEnum } from './../../computing-management/utils/enum';
import listStatus from './api';
import { clearSlice, toggleNoteModal } from './slice';

export const getListFilterGoal = createAsyncThunk(
  'gperformanceListStatus/getListFilterGoal',
  async (params: FilterParam) => {
    try {
      const response = await listStatus.list(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListWorkspace = createAsyncThunk(
  'gperformanceListStatus/getListWorkspace',
  async (params?: any) => {
    try {
      const response = await listStatus.listWorkspace(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getDetailWorkspace = createAsyncThunk(
  'gperformanceListStatus/getDetailWorkspace',
  async (params?: any) => {
    try {
      const response = await listStatus.listWorkspace(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getDetailUser = createAsyncThunk(
  'gperformanceListStatus/detailUser',
  async (params?: any) => {
    try {
      const response = await listStatus.detailUser(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListPerformanceWorkspace = createAsyncThunk(
  'gperformanceListStatus/getListPerformanceWorkspace',
  async (params: FilterParam, { dispatch, getState }) => {
    try {
      const response = await listStatus.listPerformance(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getList = createAsyncThunk(
  'gperformanceListStatus/getListTable',
  async (params: FilterParam, { dispatch, getState }) => {
    const { workspace_ids, user_ids, type, monthYear, status } = params;

    const paramsGetPerformance = {
      type,
      type_unit: SubjectEnum.Individual,
      month: monthYear,
      status
    };
    try {
      if (user_ids) {
        const userIdsArrString = user_ids.split(',');
        const IDS_PER_REQUEST = 500;
        const sliceUserIds =
          Math.floor(userIdsArrString.length / IDS_PER_REQUEST) + 1;
        const loopsRequest = Array.from({ length: sliceUserIds }, (v, i) => i);
        const promiseRequestUserPerformances: any[] = [];
        const requestPerformance = async (i: any) => {
          const userIdsPerSlice = userIdsArrString
            .slice(i * IDS_PER_REQUEST, (i + 1) * IDS_PER_REQUEST)
            .join(',');
          await dispatch(
            getListPerformanceUser({
              ...paramsGetPerformance,
              type_unit: SubjectEnum.Individual,
              assignee_id: userIdsPerSlice,
              slice: i,
              totalSlice: sliceUserIds
            })
          );
        };
        loopsRequest.forEach((i) =>
          promiseRequestUserPerformances.push(requestPerformance(i))
        );
        await Promise.all(promiseRequestUserPerformances);
      }
      if (workspace_ids) {
        await dispatch(
          getListPerformanceWorkspace({
            ...paramsGetPerformance,
            type_unit: SubjectEnum.Group,
            assignee_id: workspace_ids
          })
        );
      }

      const state = getState() as RootState;
      const idsFetchWorkspace = state.gPerformanceListStatus.idsFetchWorkspace;

      if (!idsFetchWorkspace.user_ids && !idsFetchWorkspace.ids)
        return { data: [] };
      const response = await listStatus.listWorkspace({
        ...DEFAULT_PARAMS,
        user_ids: idsFetchWorkspace.user_ids,
        ids: idsFetchWorkspace.ids
      });

      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListPerformanceUser = createAsyncThunk(
  'gperformanceListStatus/getListPerformanceUser',
  async (
    params: FilterParam & { slice: number; totalSlice: number },
    { dispatch }
  ) => {
    try {
      const response = await listStatus.listPerformance(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const sendApprove = createAsyncThunk(
  'gperformanceListStatus/sendApprove',
  async (payload: any, { dispatch, getState }) => {
    const message = payload.message;
    delete payload.message;
    try {
      const response = await listStatus.sendApprove(payload);
      dispatch(toggleNoteModal());
      const state = getState() as RootState;
      const filterParams = state.gPerformanceListStatus.filterParams;
      openNotification({
        type: TOAST_TYPE.Success,
        message: message
      });
      dispatch(getList(filterParams));
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListGoal = createAsyncThunk(
  'gperformanceListStatus/getListGoal',
  async (params?: any) => {
    try {
      const response = await listStatus.listGoal(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListEmployee = createAsyncThunk(
  'gperformanceListStatus/getListEmployee',
  async (params: any, { dispatch, getState }) => {
    try {
      const response = await listStatus.listEmployee(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const exportExcel = createAsyncThunk(
  'gperformanceListStatus/exportExcel',
  async (params: any, { dispatch }) => {
    dispatch(clearSlice());
    try {
      const response = await listStatus.exportExcel(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);
