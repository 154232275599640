import { axiosAccountPrivateV2 } from "../../../../api/axios";

export const WorkspaceApi = {
  getAdminWorkspaceInfo: async (): Promise<any> => {
    try {
      const res = await axiosAccountPrivateV2.get("/admin/workspaces");
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  getWorkspaceInfo: async (): Promise<any> => {
    try {
      const res = await axiosAccountPrivateV2.get("/workspaces");
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  getUserWorkspaceInfo: async (id: number): Promise<any> => {
    try {
      const res = await axiosAccountPrivateV2.get(
        `/admin/users/check-owner/${id}`
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  getWorkspaceDetail: async (id: number): Promise<any> => {
    try {
      const res = await axiosAccountPrivateV2.get(
        `/admin/workspaces/${id}`
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  createWorkspaceInfo: async (body: any): Promise<any> => {
    try {
      const res = await axiosAccountPrivateV2.post(
        "/admin/workspaces",
        body
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  updateWorkspaceInfo: async (body: any): Promise<any> => {
    try {
      const res = await axiosAccountPrivateV2.put(
        `/admin/workspaces/${body.id}`,
        { ...body, id: undefined }
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  getRegionInfo: async (): Promise<any> => {
    try {
      const res = await axiosAccountPrivateV2.get(
        "/workspaces/region/list"
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  getObjectInfo: async (): Promise<any> => {
    try {
      const res = await axiosAccountPrivateV2.get(
        "/workspaces/objects/list"
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  getRacingInfo: async (): Promise<any> => {
    try {
      const res = await axiosAccountPrivateV2.get(
        "/workspaces/racing/list"
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  getTypeInfo: async (): Promise<any> => {
    try {
      const res = await axiosAccountPrivateV2.get(
        "/workspaces/type/list"
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  getListUser: async (params: any): Promise<any> => {
    try {
      const res = await axiosAccountPrivateV2.get("/admin/users/paging", {
        params,
      });
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  getListRole: async (): Promise<any> => {
    try {
      const res = await axiosAccountPrivateV2.get("/roles");
      return res.data;
    } catch (error) {
      throw error;
    }
  },

  exportWorkspace: async function (body: any = undefined) {
    try {
      const res: any = await axiosAccountPrivateV2.post(
        `/admin/workspaces/export`,
        body,
        {
          responseType: "blob",
        }
      );
      return res;
    } catch (error: any) {
      throw error;
    }
  },
};
