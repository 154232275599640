import { useState, useEffect } from "react";
import { selectAuthUser } from "../../auth/redux/authSlice";
import { useAppSelector } from "../../../store/store";

const useRoleUser = () => {
  const [roleUser, setRoleUser] = useState<string>("");
  const [userId, setUserId] = useState<any>();
  const [userEmail, setUserEmail] = useState<any>();
  const [roleID, setRoleID] = useState<any>();
  const authUser = useAppSelector(selectAuthUser);
  useEffect(() => {
    if (authUser && authUser.roles) {
      setUserId(authUser.id);
      setRoleUser(authUser.roles.name);
      setUserEmail(authUser.email);
      setRoleID(authUser.roles.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    roleUser: roleUser,
    userId: userId,
    userEmail: userEmail,
    roleID: roleID,
  };
};
export default useRoleUser;
