import { FilterParam } from './interface';
import { nanoid } from '@reduxjs/toolkit';
import _ from 'lodash';
import { ProcessBar } from './services';
import { PerformanceTypeEnum } from './enum';
import { formatShowDecimalNumber } from '../../common/utils/helper';

export const removeCondition = (el: any) => {
  const _el = { ...el };
  if (_el.conditions) {
    delete _el.conditions;
  }
  return _el;
};

export const downloadFileParam = (params: FilterParam) => {
  const _params = { ...params } as {
    page?: number;
    status?: number;
    resource_code?: string;
    keyword?: string;
    per_page?: number;
  };
  if (_params.per_page) {
    delete _params.per_page;
  }
  if (_params.page) {
    delete _params.page;
  }
  return _params;
};

export const downloadFile = (response: any): void => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const fileName = getFileNameInResponseHeader(response.headers);
  const link = document.createElement('a');
  link.target = '_blank';
  link.download = fileName;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

const getFileNameInResponseHeader = (headers: any): any => {
  let fileName = '';
  if (!headers) return '';
  if ('content-disposition' in headers) {
    const data = headers['content-disposition']
      .split(';')
      .map((item: string) => item.trim());

    for (let item of data) {
      const disposition = item.split('=');
      if (disposition.length > 0) {
        if (disposition[0] === 'filename') return disposition[1];
      }
    }
  }
  return fileName;
};

export const listMapping = (_list: any) => {
  let _tableList: any = [];
  _tableList = _list.map((_val: any, index: number) => {
    if (!_val?.childrens?.length) {
      return { ..._val, children: [] };
    }
    return {
      ..._val,
      children: [
        {
          index: '#',
          name: 'division',
          mtd: 'mtd',
          ytd: 'ytd',
          target_time: 'target_time',
          status: 'status',
          header: true,
          key: nanoid()
        },
        ..._addIndex(_val.childrens)
      ]
    };
  });
  return _addIndex(_tableList);
};

const _addIndex = (_list: any[]) => {
  return _list.map((_item: any, index: number) => ({
    ..._item,
    index: index + 1,
    key: nanoid()
  }));
};

export const formatText = (_val: string, _maxLength: number = 20): string => {
  if (_val.length > _maxLength) {
    return _val.substring(0, _maxLength) + '...';
  }
  return _val;
};

export const renderProgressBar = (
  _performance: string,
  type: PerformanceTypeEnum,
  backgroundColor?: string
) => {
  if (!_.isArray(_performance)) return '';
  const value = _performance.find((_item: any) => _item.type === type);

  if (value?.accepted_value) {
    const percent = Number(value?.accepted_value);
    return ProcessBar({
      percent: formatShowDecimalNumber(percent),
      color: value?.accepted_color?.color || '#959595',
      backgroundColor: backgroundColor
    });
  }

  return null;
};

export const getAllIdsArrayNested = (list: any, ids: any[] = []) => {
  list.forEach((_item: any) => {
    ids.push(_item.id);
    if (_item?.children?.length) {
      getAllIdsArrayNested(_item.children, ids);
    }
  });
  return ids;
};

export const flattenList = (list: any, ids: any[] = []) => {
  list.forEach((_item: any) => {
    ids.push({
      ..._item,
      id: _item.id + 'unique' + nanoid(),
      children: []
    });
    if (_item?.children?.length) {
      flattenList(_item.children, ids);
    }
  });
  return ids;
};

export const getItemInArrNested = (_list: any, ids: any[] = []) => {
  const items = _list.filter((_item: any) => {
    return ids.includes(_item.id);
  });
  return items.map((_item: any) => {
    if (!_item?.children?.length) {
      return { ..._item, children: [] };
    } else {
      return { ..._item, children: getItemInArrNested(_item.children, ids) };
    }
  });
};



export const formatPercent = (_number: number | string) =>  String(formatShowDecimalNumber(_number)) + '%'

export const renderPercent = (_performance: any, field: string) => (_performance?.[field] && _performance?.[field] !== 0) ? formatPercent(_performance[field]) : ''