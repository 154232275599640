import axios from "axios";
import { GPROCESS_SERVICE_URL } from "../request/wsCode";

export const RequestApiPrivate = axios.create({
  baseURL: GPROCESS_SERVICE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export function updateAxiosHeaderGprocessAcceptLanguage(locale: string): void {
  RequestApiPrivate.defaults.headers.common["Accept-Language"] = `${locale}`;
}

export const gprocessApi = {
  generateToken: async function (json: any): Promise<any> {
    let params = {
      id: json?.authUser?.id,
      expired_in: json?.expired_in,
    };
    try {
      const res = await RequestApiPrivate.post("generate-token", params);
      if (res) {
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  },
};
