import { RequestApiPrivateGTicketV2, RequestApiPrivateGticket } from "../../api/axios";

export const _getListTitlesForCategory = async () => {
  try {
    const response = await RequestApiPrivateGTicketV2.get("/job_titles/");

    if (response.status === 200 && response?.data) {
      return response?.data?.data ?? [];
    } else {
      return [];
    }
  } catch (error) {
    // return error;
    return [];
  }
};
