import { MouseEventHandler } from "react";

type TypeProps = {
  onClick?: MouseEventHandler<SVGElement>;
};
const IconCloseModal = ({ onClick }: TypeProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M14.3955 10.0947L9.60349 14.8867"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.397 14.8898L9.60098 10.0928"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3345 3.25H7.66549C4.64449 3.25 2.75049 5.389 2.75049 8.416V16.584C2.75049 19.611 4.63549 21.75 7.66549 21.75H16.3335C19.3645 21.75 21.2505 19.611 21.2505 16.584V8.416C21.2505 5.389 19.3645 3.25 16.3345 3.25Z"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default IconCloseModal;
