import { Fragment } from "react";
import RenderProps from "../../../features/okr/target-management/MyTarget/components/common/RenderProps/RenderProps";
import { mergeStylesCSS } from "../../../helpers/helper";
import Item from "./Item";
import style from "./style.module.scss";

type PropsType = {
  className?: string;
  data: {
    key: any;
    state: any;
    text: any;
    subText?: any;
    button?: any;
  }[];
  dataApprove?: any;
};

const TimeLine = ({ className, data }: PropsType) => {
  return (
    <div className={mergeStylesCSS([className && className])}>
      <RenderProps
        list={data}
        render={(val: any, idx: number) => (
          <Fragment key={idx}>
            <Item val={val} key={idx} />
            {idx < data.length - 1 && <div className={style.hr}></div>}
          </Fragment>
        )}
      />
    </div>
  );
};

export default TimeLine;
