import { WsCode } from '../../api';
import { RequestApiPrivate } from '../../api/axios';

const ListStatusApi = {
  list: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.listStatus.workspaces,
        {
          params
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  listFilterGoal: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.listStatus.workspaces,
        {
          params
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  listEmployee: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get('users', {
        params
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  listWorkspace: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.listStatus.workspaces,
        {
          params
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  listPerformance: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.listStatus.listPerformance,
        {
          params
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  detailWorkspace: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.listStatus.workspaces,
        {
          params
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  detailUser: async (id: string | number): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.listStatus.users + id
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  listGoal: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(WsCode.listStatus.goals, {
        params
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  sendApprove: async (payload: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.post(
        WsCode.listStatus.sendApprove,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  detail: async (id: number): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(
        WsCode.listStatus.detail + `/${id}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  update: async (id: number, payload: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.put(
        WsCode.listStatus.edit + `/${id}`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  delete: async (id: number): Promise<any> => {
    try {
      const response = await RequestApiPrivate.delete(
        WsCode.listStatus.delete + `/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  downloadTemplate: async (): Promise<any> => {
    try {
      const response = await RequestApiPrivate.post(
        WsCode.listStatus.downloadTemplate,
        undefined,
        { responseType: 'blob' }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  exportExcel: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.post(
        WsCode.listStatus.exportExcel,
        params,
        { responseType: 'blob'}
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
};

export default ListStatusApi;
