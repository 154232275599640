import React from "react";

const CalendarIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.834961 7.62084H17.1736"
        stroke="#7A7A7A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0717 11.2004H13.0802"
        stroke="#7A7A7A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.00434 11.2004H9.01283"
        stroke="#7A7A7A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.92817 11.2004H4.93666"
        stroke="#7A7A7A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0717 14.7624H13.0802"
        stroke="#7A7A7A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.00434 14.7624H9.01283"
        stroke="#7A7A7A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.92817 14.7624H4.93666"
        stroke="#7A7A7A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7068 0.834961V3.85099"
        stroke="#7A7A7A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.30155 0.834961V3.85099"
        stroke="#7A7A7A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8851 2.28223H5.12338C2.43142 2.28223 0.75 3.78157 0.75 6.5376V14.8317C0.75 17.631 2.43142 19.165 5.12338 19.165H12.8766C15.577 19.165 17.25 17.657 17.25 14.901V6.5376C17.2585 3.78157 15.5855 2.28223 12.8851 2.28223Z"
        stroke="#7A7A7A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalendarIcon;
