import { Select, SelectProps } from "antd";
import styles from "./style.module.scss";
import { useState } from "react";

import { without, map, filter, uniq, concat } from "lodash";
import IconArrow from "../../../../../components/icons/IconArrow";
import i18n from "../../../../../i18n";
import { renderMaxTag, renderTag } from "../tree/renderTag";

type Props = SelectProps & {
  isSingle?: boolean;
};

export default function SelectCustom(props: Props) {
  const option_all = {
    label: i18n.t("gperformance:common.all"),
    value: "all",
  };
  const optionAllSearch = {
    label: i18n.t("gperformance:common.selectAll"),
    value: "all_search",
  };
  const [filteredKey, setFilteredKey] = useState("");

  const filterOption = (input: any, option: any) =>
    String(option?.label ?? "")
      .toLowerCase()
      .includes(String(input).trim().toLowerCase()) ||
    option?.value === optionAllSearch.value;

  const filteredOptions = (props.options || []).filter((item) =>
    filterOption(filteredKey, item)
  );

  const handleSelect = (value: any, options: any) => {
    let result = uniq([...props.value, value]);
    if (value === optionAllSearch.value) {
      if (props.value.includes(option_all.value)) {
        result = [
          ...filter(
            map(filteredOptions || [], "value"),
            (item) => item !== optionAllSearch.value
          ),
        ];
      } else {
        result = uniq([
          ...props.value,
          ...filter(
            map(filteredOptions || [], "value"),
            (item) => item !== optionAllSearch.value
          ),
        ]);
      }
    } else if (value === option_all.value) {
      result = [option_all.value];
    } else if (props.value.includes(option_all.value)) {
      result = [value];
    }
    emitChange(result, []);
  };
  const handleDeselect = (value: any, options: any) => {
    let result = [];
    if (value === optionAllSearch.value) {
      result = without(
        props.value,
        ...filter(
          map(filteredOptions || [], "value"),
          (item) => item !== optionAllSearch.value
        )
      );
    } else if (value === option_all.value) {
      result = [option_all.value];
    } else {
      result = props.value.filter((_value: any) => _value !== value);
    }
    emitChange(result, []);
  };
  const handleClear = () => {
    emitChange([option_all.value], []);
  };
  const handleOnBlur = () => {
    if (props.value.length === 0) emitChange([option_all.value], []);
  };
  const emitChange = (value: any, option: any) => {
    if (props.onChange) {
      props.onChange(value, option);
    }
  };
  const options =
    !!filteredKey.trim() && filteredOptions.length
      ? [optionAllSearch, ...(props.options || [])]
      : [option_all, ...(props.options || [])];

  const selectProps: SelectProps = {
    maxTagCount: "responsive",
    suffixIcon: <IconArrow />,
    optionFilterProp: "label",
    showArrow: true,
    className: `${styles[`tag-number-${props.value?.length >= 2 ? 2 : 1}`]} ${
      styles.select
    }`,
    ...props,
  };

  if (props.isSingle) {
    return <Select {...selectProps} allowClear={false} />;
  }

  const { Option } = Select;
  const isSelectedAllResults =
    uniq(concat(map(filteredOptions || [], "value"), props.value))?.length ===
    props.value?.length;

  return (
    <Select
      {...selectProps}
      tagRender={renderTag}
      filterOption={filterOption}
      onDropdownVisibleChange={(status: boolean) =>
        !status && setFilteredKey("")
      }
      onSearch={(searchTerm: string) => setFilteredKey(searchTerm)}
      maxTagPlaceholder={renderMaxTag}
      showSearch
      allowClear
      onBlur={handleOnBlur}
      options={undefined}
      autoClearSearchValue={false}
      popupClassName="kpi-select-custom"
      onSelect={handleSelect}
      onDeselect={handleDeselect}
      onClear={handleClear}
    >
      {options?.map((item) => (
        <Option {...item} key={item.value} title={item.label}>
          <div
            className={`demo-option-label-item ${
              isSelectedAllResults &&
              filteredKey &&
              item.value === optionAllSearch.value
                ? "active"
                : ""
            }`}
          >
            <span>{item.label}</span>
          </div>
        </Option>
      ))}
    </Select>
  );
}
