import i18n from "../../../../i18n";
import { FilterSetting, RoleTypes } from "../interfaces";
export const DISPLAY_TYPES = {
  MULTIPLE_SUBJECT: 1,
  SINGLE_SUBJECT: 2,
};
export const DISPLAY_TARGET = {
  EMPLOYEE: 1,
  UNIT: 2,
};
export const REGION_CODE = {
  GGG: 1,
  CORP: 2,
  NORTH: 4,
  SOUTH: 3,
};
export const REGIONS = [
  {
    value: REGION_CODE.CORP,
    label: i18n.t("gperformance:performance_filter.regions.corp"),
  },
  {
    value: REGION_CODE.NORTH,
    label: i18n.t("gperformance:performance_filter.regions.north"),
  },
  {
    value: REGION_CODE.SOUTH,
    label: i18n.t("gperformance:performance_filter.regions.south"),
  },
];
export const STATUS_PERFORMANCE = {
  ALL: "all",
  NEW: 1,
  SENT: 2,

  APPROVED: 3,
  REJECTED: 4,
};
export const PERIOD_VALUE = {
  MONTH: 1,
  YEAR: 2,
};
export const PERIOD_OPTIONS = [
  {
    value: PERIOD_VALUE.MONTH,
    label: i18n.t("gperformance:performance_filter.optionSelectMonth"),
  },
  {
    value: PERIOD_VALUE.YEAR,
    label: i18n.t("gperformance:performance_filter.optionSelectYear"),
  },
];
export const STATUS_FILTER = [
  // {
  //   value: STATUS_PERFORMANCE.ALL,
  //   label: i18n.t("gperformance:performance_filter.status.all"),
  // },
  {
    value: STATUS_PERFORMANCE.NEW,
    label: i18n.t("gperformance:performance_filter.status.new"),
  },
  {
    value: STATUS_PERFORMANCE.SENT,
    label: i18n.t("gperformance:performance_filter.status.sent"),
  },
  {
    value: STATUS_PERFORMANCE.APPROVED,
    label: i18n.t("gperformance:performance_filter.status.approved"),
  },
  {
    value: STATUS_PERFORMANCE.REJECTED,
    label: i18n.t("gperformance:performance_filter.status.rejected"),
  },
];

export const ROLE_TYPE: RoleTypes = {
  STAFF: 1,
  DIRECTORS: 2,
  OTHER: 3,
};
export const ROLE_GROUP = {
  STAFF: [9],
  DIRECTORS: [1, 2, 4],
  OTHER: [3, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
};
export const ROLE_TYPE_NAME = {
  STAFF: "STAFF",
  DIRECTORS: "DIRECTORS",
  OTHER: "OTHER",
};
export const MULTIPLE = "multiple";
export const SINGLE = "single";
export const HIDE = null;
export const DISABLED = "disabled";
export const FILTER_SETTINGS: FilterSetting[] = [
  {
    id: 0,
    role_group: ROLE_TYPE.DIRECTORS,
    display_type: DISPLAY_TYPES.MULTIPLE_SUBJECT,
    display_target: DISPLAY_TARGET.EMPLOYEE,
    data: {
      month: SINGLE,
      year: SINGLE,
      workspaces: MULTIPLE,
      regions: HIDE,
      employees: MULTIPLE,
    },
  },
  {
    id: 1,
    role_group: ROLE_TYPE.DIRECTORS,
    display_type: DISPLAY_TYPES.SINGLE_SUBJECT,
    display_target: DISPLAY_TARGET.EMPLOYEE,
    data: {
      month: MULTIPLE,
      year: MULTIPLE,
      workspaces: MULTIPLE,
      regions: HIDE,
      employees: SINGLE,
    },
  },
  {
    id: 2,
    role_group: ROLE_TYPE.DIRECTORS,
    display_type: DISPLAY_TYPES.MULTIPLE_SUBJECT,
    display_target: DISPLAY_TARGET.UNIT,
    data: {
      month: SINGLE,
      year: SINGLE,
      workspaces: MULTIPLE,
      regions: HIDE,
      employees: HIDE,
    },
  },
  {
    id: 3,
    role_group: ROLE_TYPE.DIRECTORS,
    display_type: DISPLAY_TYPES.SINGLE_SUBJECT,
    display_target: DISPLAY_TARGET.UNIT,
    data: {
      month: MULTIPLE,
      year: MULTIPLE,
      workspaces: SINGLE,
      regions: HIDE,
      employees: HIDE,
    },
  },
  {
    id: 4,
    role_group: ROLE_TYPE.OTHER,
    display_type: DISPLAY_TYPES.MULTIPLE_SUBJECT,
    display_target: DISPLAY_TARGET.EMPLOYEE,
    data: {
      month: SINGLE,
      year: SINGLE,
      workspaces: MULTIPLE,
      regions: HIDE,
      employees: MULTIPLE,
    },
  },
  {
    id: 5,
    role_group: ROLE_TYPE.OTHER,
    display_type: DISPLAY_TYPES.SINGLE_SUBJECT,
    display_target: DISPLAY_TARGET.EMPLOYEE,
    data: {
      month: MULTIPLE,
      year: MULTIPLE,
      workspaces: MULTIPLE,
      regions: HIDE,
      employees: SINGLE,
    },
  },
  {
    id: 6,
    role_group: ROLE_TYPE.OTHER,
    display_type: DISPLAY_TYPES.MULTIPLE_SUBJECT,
    display_target: DISPLAY_TARGET.UNIT,
    data: {
      month: SINGLE,
      year: SINGLE,
      workspaces: MULTIPLE,
      regions: HIDE,
      employees: HIDE,
    },
  },
  {
    id: 7,
    role_group: ROLE_TYPE.OTHER,
    display_type: DISPLAY_TYPES.SINGLE_SUBJECT,
    display_target: DISPLAY_TARGET.UNIT,
    data: {
      month: MULTIPLE,
      year: MULTIPLE,
      workspaces: SINGLE,
      regions: HIDE,
      employees: HIDE,
    },
  },
  {
    id: 8,
    role_group: ROLE_TYPE.STAFF,
    display_type: DISPLAY_TYPES.SINGLE_SUBJECT,
    display_target: DISPLAY_TARGET.EMPLOYEE,
    data: {
      month: MULTIPLE,
      year: MULTIPLE,
      workspaces: HIDE,
      regions: HIDE,
      employees: DISABLED,
    },
  },
  {
    id: 9,
    role_group: ROLE_TYPE.STAFF,
    display_type: DISPLAY_TYPES.MULTIPLE_SUBJECT,
    display_target: DISPLAY_TARGET.EMPLOYEE,
    data: {
      month: HIDE,
      year: HIDE,
      workspaces: HIDE,
      regions: HIDE,
      employees: HIDE,
    },
  },
  {
    id: 10,
    role_group: ROLE_TYPE.STAFF,
    display_type: DISPLAY_TYPES.SINGLE_SUBJECT,
    display_target: DISPLAY_TARGET.UNIT,
    data: {
      month: HIDE,
      year: HIDE,
      workspaces: HIDE,
      regions: HIDE,
      employees: HIDE,
    },
  },
  {
    id: 10,
    role_group: ROLE_TYPE.STAFF,
    display_type: DISPLAY_TYPES.MULTIPLE_SUBJECT,
    display_target: DISPLAY_TARGET.UNIT,
    data: {
      month: HIDE,
      year: HIDE,
      workspaces: HIDE,
      regions: HIDE,
      employees: HIDE,
    },
  },
];
const currentYear = new Date().getFullYear();
const monthOfYear = (_m: number, year: number) =>
  ("0" + _m).slice(-2) + `/${year}`;
const createArrayInYear = (year: number) => {
  return [
    ...Array.from(new Array(12), (_, i) => ({
      value: monthOfYear(i + 1, year),
      label: monthOfYear(i + 1, year),
    })),
  ];
};
export const MONTH: Array<{ value: string; label: string }> = [
  ...Array.from(new Array(11), (_, i) =>
    createArrayInYear(currentYear - 5 + i)
  ).flat(),
];
export const YEAR: Array<{ value: string; label: string }> = [
  ...Array.from(new Array(11), (_, i) => ({
    value: String(currentYear - 5 + i),
    label: String(currentYear - 5 + i),
  })),
];
export const MAP_TEXT: { [key: number]: string } = {
  1: i18n.t("gperformance:performance_filter.status.new"),
  2: i18n.t("gperformance:performance_filter.status.sent"),
  3: i18n.t("gperformance:performance_filter.status.approved"),
  4: i18n.t("gperformance:performance_filter.status.rejected"),
};
export const STATUS_STYLE_NAME: { [key: string]: string } = {
  "1": "new",
  "2": "sent",
  "3": "approved",
  "4": "rejected",
};
export const CODE_LEVEL_WORKSPACE = {
  REGION: 2,
};
export const STATUS_TABLE = {
  LOADING: 1,
  LOADED: 2,
};
export const FIELD_NAMES = {
  PERIOD: "period",
  MONTH: "month",
  YEAR: "year",
  MONTHS: "months",
  YEARS: "years",
  WORKSPACES: "workspaces",
  REGION: "region",
  EMPLOYEES: "employees",
  STATUS: "status",
};

export enum TOAST_TYPE {
  Success = "success",
  Error = "error",
  Warning = "warning",
}
export const TABLE_TYPE = {
  USER: "user",
  WORKSPACE: "workspace",
  DATE: "date",
};
export const DATA_READY = {
  READY: 1,
  NOT_READY: 0,
};

export const FIXED_ROW_KEYS = [
  "className",
  "id",
  "key",
  "property",
  "type",
  "children",
];
export const FIXED_ROW_KEYS_2 = ["className", "id", "key", "property", "type"];

export const ROW_KEY = {
  TOTAL: "total",
  STATUS: "status",
  VALUE: "value",
};
