import { ReactNode, useEffect, useRef } from "react";
type TypeProps = {
  children: ReactNode;
  onSubmit?: Function;
  onChange?: Function;
  fielsValue?: {
    // FielsValue là một state
    key: String;
    value: String;
  }[];
  id?: any;
  className?: any;
  notEmpty?: Boolean;
  notEmptyTitle?: Boolean;
  setError?: {
    // SetError là một state
    key: String;
    error: any;
    open: Boolean;
  }[];
  enter?: Boolean;
};
const Form = ({
  children,
  onSubmit,
  onChange,
  fielsValue,
  id,
  className,
  notEmpty,
  notEmptyTitle,
  setError,
  enter,
}: TypeProps) => {
  const ref = useRef<any>();
  useEffect(() => {
    if (ref.current && fielsValue) {
      fielsValue.forEach((v: any) => {
        if (ref.current[v.key]) {
          ref.current[v.key].value = v.value;
        }
      });
    }
  }, [ref.current, fielsValue]);

  const submit = (e: any) => {
    e.preventDefault();
    if (onSubmit) {
      let a: any;
      let b: any;
      a = {};
      b = true;
      for (let index = 0; index < e.target.length; index++) {
        if (e.target[index].name && e.target[index].type !== "submit") {
          a[e.target[index].name] = e.target[index].value;
          if (notEmpty || notEmptyTitle) {
            let labels = document.getElementById(
              "form-item-label-" + e.target[index].name
            );
            let errors = document.getElementById(
              "form-item-error-" + e.target[index].name
            );

            let item = document.getElementById(
              "form-item-" + e.target[index].name
            );

            if (
              errors &&
              e.target[index].name !== "button" &&
              item &&
              !e.target[index].value &&
              !e.target[index].value.trim()
            ) {
              errors.innerText = `${
                notEmptyTitle && labels
                  ? labels.innerText + " không"
                  : "Không được"
              } được bỏ trống!`;
              b = false;
              item.className = item?.className + " border-error";
            }
          }
        }
      }

      if (b) {
        onSubmit(a, false);
      }
    }
  };

  const onChanges = (e: any) => {
    if (onChange) {
      const { value, name } = e.target;
      onChange(value, name);
    }
    const { value, name } = e.target;
    if (value && value.trim()) {
      let errors = document.getElementById("form-item-error-" + name);
      if (errors) {
        errors.innerText = "";
      }
    }
  };

  useEffect(() => {
    if (setError && setError.length) {
      setError.forEach((val) => {
        if (val.open) {
          let errors = document.getElementById("form-item-error-" + val.key);
          if (errors) {
            errors.innerText = val.error;
          }
        }
      });
    }
  }, [setError]);

  useEffect(() => {
    const listener = (event: any) => {
      const isKeyDownFromTextarea =
        event.target.tagName.toLowerCase() === "textarea";
      if (
        (event.code === "Enter" || event.code === "NumpadEnter") &&
        !isKeyDownFromTextarea &&
        !enter
      ) {
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [enter]);

  return (
    <form
      id={id}
      className={className}
      onSubmit={submit}
      onChange={onChanges}
      ref={ref}
    >
      {children}
    </form>
  );
};

export default Form;
