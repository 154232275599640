export const IconInfor = () => (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.16675 13.166H9.83341V8.16602H8.16675V13.166ZM9.00008 6.49935C9.23619 6.49935 9.43425 6.41935 9.59425 6.25935C9.75369 6.0999 9.83341 5.90213 9.83341 5.66602C9.83341 5.4299 9.75369 5.23185 9.59425 5.07185C9.43425 4.9124 9.23619 4.83268 9.00008 4.83268C8.76397 4.83268 8.56619 4.9124 8.40675 5.07185C8.24675 5.23185 8.16675 5.4299 8.16675 5.66602C8.16675 5.90213 8.24675 6.0999 8.40675 6.25935C8.56619 6.41935 8.76397 6.49935 9.00008 6.49935ZM9.00008 17.3327C7.8473 17.3327 6.76397 17.1138 5.75008 16.676C4.73619 16.2388 3.85425 15.6452 3.10425 14.8952C2.35425 14.1452 1.76064 13.2632 1.32341 12.2493C0.885637 11.2355 0.666748 10.1521 0.666748 8.99935C0.666748 7.84657 0.885637 6.76324 1.32341 5.74935C1.76064 4.73546 2.35425 3.85352 3.10425 3.10352C3.85425 2.35352 4.73619 1.75963 5.75008 1.32185C6.76397 0.884627 7.8473 0.666016 9.00008 0.666016C10.1529 0.666016 11.2362 0.884627 12.2501 1.32185C13.264 1.75963 14.1459 2.35352 14.8959 3.10352C15.6459 3.85352 16.2395 4.73546 16.6767 5.74935C17.1145 6.76324 17.3334 7.84657 17.3334 8.99935C17.3334 10.1521 17.1145 11.2355 16.6767 12.2493C16.2395 13.2632 15.6459 14.1452 14.8959 14.8952C14.1459 15.6452 13.264 16.2388 12.2501 16.676C11.2362 17.1138 10.1529 17.3327 9.00008 17.3327ZM9.00008 15.666C10.8612 15.666 12.4376 15.0202 13.7292 13.7285C15.0209 12.4368 15.6667 10.8605 15.6667 8.99935C15.6667 7.13824 15.0209 5.56185 13.7292 4.27018C12.4376 2.97852 10.8612 2.33268 9.00008 2.33268C7.13897 2.33268 5.56258 2.97852 4.27091 4.27018C2.97925 5.56185 2.33341 7.13824 2.33341 8.99935C2.33341 10.8605 2.97925 12.4368 4.27091 13.7285C5.56258 15.0202 7.13897 15.666 9.00008 15.666Z'
      fill='#1C1B1F'
    />
  </svg>
);

export const IconChart = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M2.16667 15.5C1.70833 15.5 1.31583 15.3369 0.989167 15.0108C0.663055 14.6842 0.5 14.2917 0.5 13.8333V2.16667C0.5 1.70833 0.663055 1.31583 0.989167 0.989167C1.31583 0.663055 1.70833 0.5 2.16667 0.5H9.66667V2.16667H2.16667V13.8333H13.8333V6.33333H15.5V13.8333C15.5 14.2917 15.3369 14.6842 15.0108 15.0108C14.6842 15.3369 14.2917 15.5 13.8333 15.5H2.16667ZM3.83333 12.1667H5.5V6.33333H3.83333V12.1667ZM7.16667 12.1667H8.83333V3.83333H7.16667V12.1667ZM10.5 12.1667H12.1667V8.83333H10.5V12.1667ZM12.1667 5.5V3.83333H10.5V2.16667H12.1667V0.5H13.8333V2.16667H15.5V3.83333H13.8333V5.5H12.1667Z'
      fill='black'
    />
  </svg>
);

export const IconEmpty = () => (
  <svg
    width='74'
    height='33'
    viewBox='0 0 74 33'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_11525_31932)'>
      <rect x='22.5' y='13' width='29' height='19' fill='#EDEDED' />
      <path
        d='M22 8.5H52'
        stroke='#C0C0C0'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M52 8.5L45 2.5H29L22 8.5V32.5H52V8.5Z'
        stroke='#C0C0C0'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M41 18.5H33V8.5L36 2.5H38L41 8.5V18.5Z'
        stroke='#C0C0C0'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <line x1='54.5' y1='31' x2='65.5' y2='31' stroke='black' strokeWidth='2' />
    <line x1='8.5' y1='32' x2='19.5' y2='32' stroke='black' strokeWidth='2' />
    <line x1='0.5' y1='32' x2='4.5' y2='32' stroke='black' strokeWidth='2' />
    <line x1='68.5' y1='31' x2='73.5' y2='31' stroke='black' strokeWidth='2' />
    <g clipPath='url(#clip1_11525_31932)'>
      <path
        d='M53.5 0C49.089 0 45.5 3.589 45.5 8C45.5 12.411 49.089 16 53.5 16C57.911 16 61.5 12.411 61.5 8C61.5 3.589 57.911 0 53.5 0ZM54.4645 3.5L54 9.5H53L52.5355 3.5H54.4645ZM53.5 12.5C52.9475 12.5 52.5 12.0525 52.5 11.5C52.5 10.9475 52.9475 10.5 53.5 10.5C54.0525 10.5 54.5 10.9475 54.5 11.5C54.5 12.0525 54.0525 12.5 53.5 12.5Z'
        fill='#F0B71C'
      />
    </g>
    <defs>
      <clipPath id='clip0_11525_31932'>
        <rect
          width='32'
          height='32'
          fill='white'
          transform='translate(20.5 1)'
        />
      </clipPath>
      <clipPath id='clip1_11525_31932'>
        <rect width='16' height='16' fill='white' transform='translate(45.5)' />
      </clipPath>
    </defs>
  </svg>
);

export const AddIconInfor = () => (
  <svg
    width='14'
    height='15'
    viewBox='0 0 14 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.00004 0.833984C5.6815 0.833984 4.39257 1.22498 3.29624 1.95752C2.19991 2.69006 1.34543 3.73125 0.840847 4.94943C0.336263 6.1676 0.20424 7.50805 0.461475 8.80125C0.71871 10.0945 1.35365 11.2823 2.286 12.2147C3.21835 13.147 4.40624 13.782 5.69944 14.0392C6.99265 14.2965 8.33309 14.1644 9.55127 13.6598C10.7694 13.1553 11.8106 12.3008 12.5432 11.2045C13.2757 10.1081 13.6667 8.81919 13.6667 7.50065C13.6667 6.62517 13.4943 5.75827 13.1592 4.94943C12.8242 4.14059 12.3331 3.40566 11.7141 2.78661C11.095 2.16755 10.3601 1.67649 9.55127 1.34145C8.74243 1.00642 7.87552 0.833984 7.00004 0.833984ZM7.00004 11.5007C6.86819 11.5007 6.7393 11.4616 6.62966 11.3883C6.52003 11.315 6.43458 11.2109 6.38412 11.0891C6.33367 10.9673 6.32046 10.8332 6.34619 10.7039C6.37191 10.5746 6.4354 10.4558 6.52864 10.3626C6.62187 10.2693 6.74066 10.2059 6.86998 10.1801C6.9993 10.1544 7.13335 10.1676 7.25517 10.2181C7.37698 10.2685 7.4811 10.354 7.55436 10.4636C7.62761 10.5732 7.66671 10.7021 7.66671 10.834C7.66671 11.0108 7.59647 11.1804 7.47145 11.3054C7.34642 11.4304 7.17686 11.5007 7.00004 11.5007ZM7.66671 8.16732C7.66671 8.34413 7.59647 8.5137 7.47145 8.63872C7.34642 8.76375 7.17686 8.83398 7.00004 8.83398C6.82323 8.83398 6.65366 8.76375 6.52864 8.63872C6.40362 8.5137 6.33338 8.34413 6.33338 8.16732V4.16732C6.33338 3.99051 6.40362 3.82094 6.52864 3.69591C6.65366 3.57089 6.82323 3.50065 7.00004 3.50065C7.17686 3.50065 7.34642 3.57089 7.47145 3.69591C7.59647 3.82094 7.66671 3.99051 7.66671 4.16732V8.16732Z'
      fill='#47C751'
    />
  </svg>
);

export const QuestionIcon = () => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20 0C16.0444 0 12.1776 1.17298 8.8886 3.37061C5.59962 5.56824 3.03617 8.69181 1.52242 12.3463C0.00866568 16.0009 -0.387401 20.0222 0.384303 23.9018C1.15601 27.7814 3.06082 31.3451 5.85787 34.1421C8.65492 36.9392 12.2186 38.844 16.0982 39.6157C19.9778 40.3874 23.9992 39.9913 27.6537 38.4776C31.3082 36.9638 34.4318 34.4004 36.6294 31.1114C38.827 27.8224 40 23.9556 40 20C40 17.3736 39.4827 14.7728 38.4776 12.3463C37.4725 9.91982 35.9993 7.71504 34.1421 5.85786C32.285 4.00069 30.0802 2.5275 27.6537 1.52241C25.2272 0.517315 22.6264 0 20 0ZM20 32C19.6044 32 19.2178 31.8827 18.8889 31.6629C18.56 31.4432 18.3036 31.1308 18.1523 30.7654C18.0009 30.3999 17.9613 29.9978 18.0384 29.6098C18.1156 29.2219 18.3061 28.8655 18.5858 28.5858C18.8655 28.3061 19.2219 28.1156 19.6098 28.0384C19.9978 27.9613 20.3999 28.0009 20.7654 28.1522C21.1308 28.3036 21.4432 28.56 21.6629 28.8889C21.8827 29.2178 22 29.6044 22 30C22 30.5304 21.7893 31.0391 21.4142 31.4142C21.0392 31.7893 20.5304 32 20 32ZM23.42 22.78C22 23.46 22 23.56 22 24C22 24.5304 21.7893 25.0391 21.4142 25.4142C21.0392 25.7893 20.5304 26 20 26C19.4696 26 18.9609 25.7893 18.5858 25.4142C18.2107 25.0391 18 24.5304 18 24C18.028 22.9092 18.3985 21.8547 19.0589 20.9861C19.7194 20.1175 20.6364 19.4786 21.68 19.16C23.22 18.42 24 18 24 16C23.9999 15.6529 23.9096 15.3118 23.7377 15.0102C23.5659 14.7087 23.3186 14.457 23.02 14.28C22.1067 13.7413 21.06 13.4709 20 13.5C18.62 13.5 16 14.02 16 16C16 16.5304 15.7893 17.0391 15.4142 17.4142C15.0391 17.7893 14.5304 18 14 18C13.4696 18 12.9609 17.7893 12.5858 17.4142C12.2107 17.0391 12 16.5304 12 16C12 12.24 15.36 9.5 20 9.5C24.64 9.5 28 12.24 28 16C28.0726 17.4734 27.6654 18.9307 26.8397 20.1531C26.0139 21.3755 24.814 22.2973 23.42 22.78Z'
      fill='#17345B'
    />
  </svg>
);