import React from "react";

const IconDownLoad = () => {
    return (
        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.00204 9.02733C6.72599 9.02733 6.50195 8.80333 6.50195 8.52733V0.5C6.50195 0.224 6.72599 0 7.00204 0C7.27809 0 7.50213 0.224 7.50213 0.5V8.52733C7.50213 8.80333 7.27809 9.02733 7.00204 9.02733" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.0026 9.02727C6.86991 9.02727 6.74189 8.97461 6.64854 8.87994L4.7042 6.92861C4.5095 6.73261 4.51017 6.41594 4.70554 6.22127C4.90157 6.02661 5.21763 6.02661 5.41233 6.22261L7.0026 7.81927L8.59288 6.22261C8.78758 6.02661 9.10364 6.02661 9.29967 6.22127C9.49504 6.41594 9.49571 6.73261 9.30101 6.92861L7.35667 8.87994C7.26332 8.97461 7.13529 9.02727 7.0026 9.02727" fill="black" />
            <mask id="mask0_7612_13476" maskUnits="userSpaceOnUse" x="0" y="3" width="14" height="10">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.333984 3.15503H13.6695V12.3177H0.333984V3.15503Z" fill="white" />
            </mask>
            <g mask="url(#mask0_7612_13476)">
                <path fillRule="evenodd" clipRule="evenodd" d="M10.7191 12.3177H3.29117C1.66088 12.3177 0.333984 10.9917 0.333984 9.36103V6.10436C0.333984 4.4777 1.65755 3.15503 3.28517 3.15503H3.91261C4.18866 3.15503 4.4127 3.37903 4.4127 3.65503C4.4127 3.93103 4.18866 4.15503 3.91261 4.15503H3.28517C2.20898 4.15503 1.33416 5.02903 1.33416 6.10436V9.36103C1.33416 10.4404 2.21165 11.3177 3.29117 11.3177H10.7191C11.794 11.3177 12.6695 10.4424 12.6695 9.3677V6.1117C12.6695 5.03236 11.7913 4.15503 10.7131 4.15503H10.0917C9.81564 4.15503 9.5916 3.93103 9.5916 3.65503C9.5916 3.37903 9.81564 3.15503 10.0917 3.15503H10.7131C12.3434 3.15503 13.6696 4.4817 13.6696 6.1117V9.3677C13.6696 10.9944 12.3454 12.3177 10.7191 12.3177" fill="black" />
            </g>
        </svg>


    );
};

export default IconDownLoad;