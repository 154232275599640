import moment from "moment";
import Button from "../../../../components/UI/Button";
import LoadingRing from "../../../../components/UI/LoadingRing/LoadingRing";
import {
  GPROCESS_TOKEN,
  OPTIONAL_DATE,
  StatusGProcess,
  WORKSPACE_LEVEL,
} from "../../../../helpers/constant";
import i18n from "../../../../i18n";
import {
  KEY_STATUS_PROCESS,
  KEY_STATUS_TICKET,
  USER_OPTIONS_PER_PAGE,
} from "./constant";
import styles from "./Helper.module.scss";
import { CVSelectSearch, department } from "./type";
import { isBefore } from "date-fns";
import { Workspace, User } from "../../../meta-info/redux/metaInfoSlice";

//convert status g-process
export const convertGProcess = (type: any) => {
  switch (type) {
    case StatusGProcess.draff: {
      return (
        <div className={styles.draff}>
          <Button className={styles.customButon} sizeBtn="sm">
            {getTextProcessStatus(StatusGProcess.draff)}
          </Button>
        </div>
      );
    }
    case StatusGProcess.watting: {
      return (
        <div className={styles.watting}>
          <Button className={styles.customButon} sizeBtn="sm">
            {getTextProcessStatus(StatusGProcess.watting)}
          </Button>
        </div>
      );
    }
    case StatusGProcess.activated: {
      return (
        <div className={styles.activated}>
          <Button className={styles.customButon} sizeBtn="sm">
            {getTextProcessStatus(StatusGProcess.activated)}
          </Button>
        </div>
      );
    }
    case KEY_STATUS_PROCESS.EXSIT: {
      return (
        <div className={styles.reject}>
          <Button className={styles.customButon} sizeBtn="sm">
            {getTextProcessStatus(StatusGProcess.reject)}
          </Button>
        </div>
      );
    }
    case KEY_STATUS_PROCESS.EXPRIE: {
      return (
        <div className={styles.expire}>
          <Button className={styles.customButon} sizeBtn="sm">
            {getTextProcessStatus(StatusGProcess.expire)}
          </Button>
        </div>
      );
    }

    default: {
      return (
        <div className={styles.expire}>
          <Button className={styles.customButon} sizeBtn="sm">
            Khác
          </Button>
        </div>
      );
    }
  }
};

//Chỉ nhập số
export const CONVERT_TO_NUMBER_NO_TEXT = (evt: any) => {
  let theEvent = evt || window.event;
  let key;

  // Handle paste
  if (theEvent.type === "paste") {
    key = evt.clipboardData.getData("text/plain");
  } else {
    // Handle key press
    key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
  }
  let regex = /[0-9]/;
  if (!regex.test(key)) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
  }
};

//
export const NOSpecial = (evt: any) => {
  let theEvent = evt || window.event;
  let key;

  // Handle paste
  if (theEvent.type === "paste") {
    key = evt.clipboardData.getData("text/plain");
  } else {
    // Handle key press
    key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
  }
  let regex = /^[!@#$%^&*()+\-=\[\]{};':"\\|,<>\/?]*$/;
  if (regex.test(key)) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
  }
};

export const maxLengthText = (value: any, max: any) => {
  if (!value) return false;
  return value.slice(0, max) + (value?.length < max ? "" : "...");
};

export const getTextloading = () => i18n.t("layout:common.loading");

export const LoadingSSC = (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      width: "100%",
    }}
  >
    <LoadingRing size="md" />
    <h4>{getTextloading()}</h4>
  </div>
);

export function checkStringInclude(str: string, word: string): boolean {
  if (!str) return false;
  if (!word) return true;
  return str.toLocaleLowerCase().includes(word.toLocaleLowerCase());
}

export function getFilterUsers(users: any[], search: string): any[] {
  if (!search) return users;
  return users.filter((user) => checkStringInclude(user.full_name, search));
}

export function getUserOptions(
  users: any[],
  page: number,
  keys: string,
  userPerPage?: number
): any[] {
  if (!users || users?.length === 0) return [];
  const maxUserPerPage = userPerPage ? userPerPage : USER_OPTIONS_PER_PAGE;
  const maxPageCount = Math.ceil(users.length / maxUserPerPage);
  const curPage = page < maxPageCount ? page : maxPageCount;
  let usersPagination = [];
  for (
    let i = (curPage - 1) * maxUserPerPage;
    i < Math.min(curPage * maxUserPerPage, users.length);
    i++
  ) {
    usersPagination.push(users[i]);
  }
  if (usersPagination?.length === 0) return [];
  return usersPagination?.map((user) => ({
    value: user?.id,
    label: user?.[keys] ? user?.[keys] : user?.user_name,
    data: user,
  }));
}

export const checkPermision = (id: any) => {
  if (!id) return false;
  return id;
};
export const checkArray = (arr: any[]) => {
  if (!arr) return false;
  if (!arr.length) return false;
  return arr;
};

export const CheckEmptyAny = (arr: any[]) => {
  if (!arr) return false;
  if (!arr.length) return false;
  let bolean = false;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i]) {
      bolean = true;
    } else {
      return false;
    }
  }
  return bolean;
};

export const checkToken = () => {
  if (!sessionStorage.getItem(GPROCESS_TOKEN)) return false;
  return true;
};

export const getToken = () => {
  if (!sessionStorage.getItem(GPROCESS_TOKEN)) return "";
  return sessionStorage.getItem(GPROCESS_TOKEN);
};

export const CheckError = (data: any[]) => {
  if (!data) return true;
  return false;
};

export const setError = (error: any, idx: any, msg: any) => {
  error[idx].message = msg;
  error[idx].status = true;
};

export const dataError = (status: any, msg: any, name: any) => {
  if (!status || !msg || !name) return false;
  return {
    key: name,
    error: "• " + msg,
    open: status,
  };
};

export const convertJson = (object: any, arrName: any[]) => {
  if (!object) return false;
  if (!arrName) return false;
  let dataObject: any = {};
  for (let keys in object) {
    if (JSON.stringify(arrName).includes(keys)) {
      dataObject[keys] = object[keys];
    }
  }
  return dataObject;
};

export const conVertDataSelectSearch = ({
  arr,
  keys,
  value,
}: CVSelectSearch) => {
  if (!arr) return [];
  if (!arr.length) return [];
  let arrConvert = arr.map((val: any) => {
    return {
      label: val[keys],
      value: val[value] ? val[value] : val.id,
      data: val,
    };
  });
  return arrConvert;
};

//format date dd/mm/yyyy hh:mm:ss
export const formatDateFull = (time: number) => {
  if (!time) return false;
  let date = new Date(time * 1000);
  let fulldate =
    forMatD(date.getDay()) +
    "/" +
    forMatD(date.getMonth()) +
    "/" +
    date.getFullYear() +
    " " +
    forMatD(date.getHours()) +
    ":" +
    forMatD(date.getMinutes()) +
    ":" +
    forMatD(date.getSeconds());
  return fulldate;
};
export const convertTimeStamp = (time: any) => {
  if (!time) return false;
  return Math.round(new Date(time).getTime() / 1000);
};

// format date dd/mm/yyyy
export const formatDateDMY = (UNIX_timestamp: number) => {
  var a = new Date(UNIX_timestamp * 1000);
  var year = a.getFullYear();
  var month = forMatD(a.getMonth());
  var date = forMatD(a.getDate());
  var time = `${date}/${Number(month) + 1}/${year}`;
  return time;
};

export const formatDateTime = (
  date: Date | string,
  format: any,
  inputFormat?: string
) => {
  if (!date) return "";
  if (inputFormat) {
    return moment(date, inputFormat).format(format);
  } else {
    return moment(date).format(format);
  }
};

//format date hh:mm:ss
export const formatDateHMS = (time: number | undefined) => {
  if (!time) return "";
  let date = new Date(time * 1000);
  let fulldate = forMatD(date.getHours()) + ":" + forMatD(date.getMinutes());
  return fulldate;
};

export const customFormatDateHMS = (time: number | undefined) => {
  if (!time) return "";
  let date = new Date(time);
  let fulldate = forMatD(date.getHours()) + ":" + forMatD(date.getMinutes());
  return fulldate;
};

export const forMatD = (time: number) => {
  if (!time) return "00";
  if (time < 10) {
    return "0" + time;
  } else {
    return time;
  }
};

//sắp sếp data
export const sortApproveStatus = (arr: []) => {
  if (!arr) return false;
  if (!arr.length) return false;
  return arr.sort((a: any, b: any) => a.approval_status - b.approval_status);
};

export function ticketValidateFocusHelper({
  error,
  sheetNameRef,
  processSelectRef,
}: any) {
  if (!error?.type) {
    return;
  }
  if (error?.type === "name" && sheetNameRef && sheetNameRef.current) {
    sheetNameRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
  if (
    error?.type === "procedure" &&
    processSelectRef &&
    processSelectRef.current
  ) {
    processSelectRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
}

export function stepFormValidateFocusHelper({
  validate,
  stepNameRef,
  actionTypeRef,
  slaQuantityRef,
  nextPerformerRef,
  stepObjectRef,
  processSelectRef,
  workDaysTypeRef,
}: any) {
  if (!validate) {
    return;
  }
  if (validate?.processSelect && processSelectRef && processSelectRef.current) {
    return processSelectRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
  if (validate?.name && stepNameRef && stepNameRef.current) {
    return stepNameRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
  if (validate?.action_type && actionTypeRef && actionTypeRef.current) {
    return actionTypeRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
  if (validate?.sla_quantity && slaQuantityRef && slaQuantityRef.current) {
    return slaQuantityRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
  if (validate?.workday && workDaysTypeRef && workDaysTypeRef.current) {
    return workDaysTypeRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
  if (validate?.object && nextPerformerRef && nextPerformerRef.current) {
    return nextPerformerRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
  if (validate?.tranfer && stepObjectRef && stepObjectRef.current) {
    return stepObjectRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
}

export function processValidateFocusHelper(
  error: any,
  nameProcessRef: any,
  shortNameRef: any,
  ownerDepartmentIdRef: any,
  targetApplyTypeRef: any,
  regulationStartDateRef: any
) {
  if (!error) {
    return;
  }
  if (error === "name" && nameProcessRef && nameProcessRef.current) {
    nameProcessRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }

  if (error === "shortName" && shortNameRef && shortNameRef.current) {
    shortNameRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }

  if (
    error === "ownerDepartmentId" &&
    ownerDepartmentIdRef &&
    ownerDepartmentIdRef.current
  ) {
    ownerDepartmentIdRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }

  if (
    error === "targetApplyType" &&
    targetApplyTypeRef &&
    targetApplyTypeRef.current
  ) {
    targetApplyTypeRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }

  if (
    error === "regulationStartDate" &&
    regulationStartDateRef &&
    regulationStartDateRef.current
  ) {
    regulationStartDateRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
}

export const flatten = function (arr: any, result: any = []) {
  for (let i = 0, length = arr.length; i < length; i++) {
    const value = arr[i];
    if (Array.isArray(value)) {
      flatten(value, result);
    } else {
      result.push(value);
    }
  }
  return result;
};

export const isCheckListChecked = (listChecklist: any, checkedList: any) => {
  if (
    (listChecklist && listChecklist[0]?.label === null) ||
    listChecklist?.filter((el: any) => {
      return el.checked !== true;
    }).length === 0
  )
    return true;
  if (
    checkedList?.filter((el: any) => {
      return el.checked !== true;
    }).length !== 0
  ) {
    return false;
  }
  return true;
};

export function getMondayOfCurrentWeek() {
  const today = new Date();
  const first = today.getDate() - today.getDay() + 1;
  const monday = new Date(today.setDate(first));
  return monday;
}

export const checklistValidateHelper = (
  recipientChecklist: { id: number; label: string; checked?: boolean }[]
) => {
  if (recipientChecklist.length === 1 && recipientChecklist[0]?.label === null)
    return false;
  const isAtLeastOneChecklistNotChecked = recipientChecklist.some(
    (el: { id: number; label: string; checked?: boolean }) =>
      !el.checked && el.label !== null
  );
  return isAtLeastOneChecklistNotChecked;
};

export const getTextProcessStatus = (statusId: number | string) => {
  if (statusId === KEY_STATUS_PROCESS.DRAFF) {
    return i18n.t("gprocess:constants.draff");
  } else if (statusId === KEY_STATUS_PROCESS.WAITING) {
    return i18n.t("gprocess:constants.waitingForApproval");
  } else if (statusId === KEY_STATUS_PROCESS.ACTIVED) {
    return i18n.t("gprocess:constants.activated");
  } else if (statusId === KEY_STATUS_PROCESS.EXPRIE) {
    return i18n.t("gprocess:constants.expire");
  }
};

export const getTextTicketStatus = (statusId: number | string) => {
  if (statusId === KEY_STATUS_TICKET.DRAFF) {
    return i18n.t("gprocess:constants.draff");
  } else if (statusId === KEY_STATUS_TICKET.INPROCESS) {
    return i18n.t("gprocess:constants.inProgress");
  } else if (statusId === KEY_STATUS_TICKET.COMPLETED) {
    return i18n.t("gprocess:constants.complete");
  } else if (statusId === KEY_STATUS_TICKET.UN_APPROVAL) {
    return i18n.t("gprocess:constants.unAprrove");
  }
};

export function getUserNameGprocess(id: number, users: any[]): string {
  const user = users.find((item) => item.id === Number(id));
  if (!user) return "";
  return user.user_name || user.full_name;
}

export const jobTitleFilterHelper = (
  jobTitles: any,
  departmentIds: number[]
) => {
  let result: any = [];
  for (const departmentId of departmentIds) {
    const filteredTitles = jobTitles.find(
      (department: any) => department.id === departmentId
    )?.job_titles;
    if (filteredTitles && filteredTitles.length) {
      result = result.concat(
        filteredTitles.map((job: any) => ({
          value: job.id,
          id: job.id,
          code: job.code,
          label: job.name,
        }))
      );
    }
  }
  return unique(result);
};

export function unique(source: any[]) {
  const uniqueIds = new Set();
  return source.filter((obj: any) => {
    if (uniqueIds.has(obj.id)) {
      return false;
    }
    uniqueIds.add(obj.id);
    return true;
  });
}

export const checkValidateTimeNextStep = (
  nextStepsStartTime: {
    step_order: number;
    start_time: string;
    type_execution: number;
  }[]
) => {
  const currentDate = new Date();
  const checkStartTimeProposal = (elementDate: any) =>
    isBefore(
      new Date(elementDate.start_time).setHours(0, 0, 0, 0),
      currentDate.setHours(0, 0, 0, 0)
    ) &&
    elementDate.start_time !== null &&
    elementDate.type_execution === OPTIONAL_DATE;
  if (nextStepsStartTime.some(checkStartTimeProposal)) {
    return true;
  }
  return false;
};

export const filterDepartment = (
  listWorkspaces: Workspace[],
  idWorkspaces: number,
  takeLevel = WORKSPACE_LEVEL.DEPARTMENT,
  user = true
): department[] => {
  let listDepartment: department[] = [];

  let regionName: string | undefined;
  const filterDepartment = (
    data: Workspace[],
    id: number,
    level = 0,
    department?: department
  ) => {
    if (level === WORKSPACE_LEVEL.REGION) {
      regionName = department?.name;
    }

    if (idWorkspaces === id && level === takeLevel) {
      listDepartment = department
        ? listDepartment.concat({
            ...department,
            name: regionName
              ? regionName + " - " + department?.name
              : department?.name,
          })
        : listDepartment;
    }
    for (let i: number = 0; i < data?.length; i++) {
      filterDepartment(
        data[i].children,
        idWorkspaces === data[i]?.id ? data[i]?.id : id,
        level + 1,
        {
          name: data[i]?.name,
          id: data[i]?.id,
          status: data[i]?.status,
          type: data[i]?.type,
        }
      );
    }
  };
  filterDepartment(listWorkspaces, 0);

  return listDepartment;
};

export const checkWorkSpace = (
  idWorkspace: number | undefined,
  listWorkspaces: Workspace[]
) => {
  let itemWorkspace: any;
  const filterDepartment = (
    data: Workspace[],
    id: number,
    level = 0,
    workspace?: {
      label: string;
      value: number;
    }
  ) => {
    if (idWorkspace === id) {
      itemWorkspace = workspace;
    }
    for (let i: number = 0; i < data?.length; i++) {
      filterDepartment(
        data[i].children,
        idWorkspace === data[i]?.id ? data[i]?.id : 0,
        level + 1,
        level < 4 ? { label: data[i]?.name, value: data[i]?.id } : workspace
      );
    }
  };
  filterDepartment(listWorkspaces, 0);
  return itemWorkspace;
};

export const getChildIds = (
  listWorkspaces: Workspace[],
  idWorkspaces: number[]
): number[] => {
  let listChildIds: number[] = [];
  const filterDepartment = (
    data: Workspace[],
    id?: number,
    departmentIds?: number
  ) => {
    if (id && idWorkspaces.includes(id) && departmentIds) {
      listChildIds = listChildIds.concat(departmentIds);
    }
    for (let i: number = 0; i < data?.length; i++) {
      filterDepartment(
        data[i].children,
        idWorkspaces.includes(data[i]?.id) ? data[i]?.id : id,
        data[i]?.id
      );
    }
  };
  filterDepartment(listWorkspaces);
  return listChildIds;
};

export const listUserWorkspace = (user: User[], listWorkspace: number[]) => {
  return user.reduce(
    (
      result: {
        value: number;
        label: string;
        workspace: any;
        status: number | undefined;
      }[],
      childNode: User
    ) => {
      if (
        listWorkspace.includes(
          (childNode?.workspace && childNode?.workspace.id) || 0
        )
      ) {
        result.push({
          value: childNode?.id,
          label: childNode?.full_name
            ? childNode?.full_name
            : childNode?.user_name,
          workspace: childNode?.workspace,
          status: childNode?.status,
        });
      }
      return result;
    },
    []
  );
};

export const filterUser = (
  listWorkspaces: Workspace[],
  idWorkspaces: number
): number[] => {
  let listDepartment: number[] = [];
  const filterDepartment = (
    data: Workspace[],
    id: number,
    level = 0,
    Department?: number
  ) => {
    if (idWorkspaces === id) {
      listDepartment = Department
        ? listDepartment.concat(Department)
        : listDepartment;
    }
    for (let i: number = 0; i < data?.length; i++) {
      filterDepartment(
        data[i].children,
        idWorkspaces === data[i]?.id ? data[i]?.id : id,
        level + 1,
        data[i]?.id
      );
    }
  };
  filterDepartment(listWorkspaces, 0);
  return listDepartment;
};

export const customListWorkspaces = (
  listWorkspaces: Workspace[]
): department[] => {
  let regionName: string | undefined;
  let listDepartment: department[] = [];
  const customDepartment = (
    data: Workspace[],
    level = 0,
    department?: department
  ) => {
    if (level === WORKSPACE_LEVEL.REGION) {
      regionName = department?.name;
    }

    if (department) {
      listDepartment = listDepartment.concat({
        ...department,
        name: regionName
          ? regionName + " - " + department?.name
          : department?.name,
      });
    }

    for (let i: number = 0; i < data?.length; i++) {
      customDepartment(data[i].children, level + 1, {
        name: data[i].name,
        id: data[i].id,
        status: data[i].status,
      });
    }
  };
  customDepartment(listWorkspaces);
  return listDepartment;
};
