import { LANGUAGES } from "./../../../helpers/constant";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import _ from "lodash";

import { AccountApi } from "../../../api/AccountApi";
import { RootState } from "../../../store/store";
import { UserType } from "../types/types";
import { setMetaInfoAfterLogin } from "../../meta-info/redux/metaInfoSlice";
import {
  axiosAccountPrivate,
  axiosAccountPrivateDomainV2,
  axiosAccountPrivateQticketModuleV2,
  axiosAccountPrivateV2,
  axiosReportPrivate,
} from "../../../api/axios";
import {
  RequestApiPrivateGticket,
  RequestApiPrivateGTicketV2,
} from "../../gticket/api/axios";
import { DEFAULT_LANGUAGE } from "../../../helpers/constant";
import {
  renderServerErrorMsg,
  updateI18nLocale,
} from "../../../helpers/helper";
import {
  RequestApiPrivateQuickTicket,
  RequestApiPrivateQuickTicketAdmin,
  RequestApiPrivateUsers,
  RequestApiPrivateUsersV2,
} from "../../system-management/api/axios";
import { generateGprocessToken } from "../../g-process/helpers/helpers";
import { updateLoginUser, updateQr, updateUsers } from "../../../utils/slice/user";
import { generateGPerformanceToken } from '../../g-performance/api/auth';

type AuthState = {
  authUser: UserType | null;
  accessToken: string | null;
  expiresAt: number | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  updateLocaleStatus: "idle" | "loading" | "succeeded" | "failed";
  kpiUser: { role_actions: any };
  tokenGTicket: string | null;
  tokenGPerformance: string | null;
  isUserInactive: boolean;
};

const fetchUserInfo = createAsyncThunk(
  "auth/userInfo",
  async (payload: {
    email: string;
    phone: string;
    employeeid: string;
    username: string;
    callback?: any;
    dispatch: any;
    expiresAt: number;
  }) => {
    const {
      email,
      phone,
      employeeid,
      username,
      callback,
      dispatch,
      expiresAt,
    } = payload;
    const payloadNew = {
      user_name: {
        email: email || undefined,
        code: employeeid || undefined,
        mobile: phone || undefined,
        username: username || undefined,
      },
    };

    try {
      const { data } = await AccountApi.getUserInfo(payloadNew);

      const token = data.token;
      axiosAccountPrivate.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
      axiosAccountPrivateV2.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
      axiosAccountPrivateQticketModuleV2.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
      RequestApiPrivateUsersV2.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
      axiosAccountPrivateDomainV2.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;

      const [roleRes, workspaces, allUserV2, loginUSer] = await Promise.all([
        AccountApi.getListRoles(),
        AccountApi.getListWorkspaces(),
        AccountApi.getAllUserV2({
          selector: true,
        }),
        AccountApi.getUserInfoV2ById(data?.id),
      ]);

      dispatch(
        setMetaInfoAfterLogin({
          roles: roleRes.data,
          workspaces: workspaces.data,
        })
      );

      //store users active V2 to redux
      dispatch(updateUsers(allUserV2));
      dispatch(updateLoginUser(loginUSer));
//      const resGenTokenGperformance = await generateGPerformanceToken(
//        { id: data?.id, groups: data?.groups },
//        expiresAt
//      );
//      dispatch(
//        setTokenGPerformance(resGenTokenGperformance?.data?.access_token)
//      );

      await generateGprocessToken(data, expiresAt);

      localStorage.setItem("accessToken", token);
      callback && callback(undefined, { ...data });
      return { ...data };
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      callback && callback(false);
      throw error;
    }
  }
);

export const getTokenGticket = createAsyncThunk(
  "auth/getTokenGticket",
  async (payload: { username: string }) => {
    const { username } = payload;
    try {
      const response = await AccountApi.generateTokenGticket(username);
      const token = response.data.token;
      RequestApiPrivateGticket.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
      RequestApiPrivateGTicketV2.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
      RequestApiPrivateQuickTicket.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
      RequestApiPrivateQuickTicketAdmin.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
      axiosReportPrivate.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
      axiosAccountPrivateV2.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
      axiosAccountPrivateDomainV2.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getTokenUsersManagement = createAsyncThunk(
  "auth/getTokenUsersManagement",
  async ({ email }: { email: string }) => {
    try {
      const response = await AccountApi.generateTokenUsersManagement(email);
      const token = response.data.token;
      RequestApiPrivateUsers.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
      RequestApiPrivateUsersV2.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateUserLocale = createAsyncThunk(
  "auth/updateUserLocale",
  async (payload: { userId: number; locale: string }) => {
    const { userId, locale } = payload;
    try {
      await AccountApi.updateLocale({ locale, userId });
      return locale;
    } catch (error) {
      renderServerErrorMsg(error, toast);
      throw error;
    }
  }
);

export async function loginUser(dispatch: any, user: any, callback?: any) {
  const payload = {
    accessToken: user.access_token,
    expiresAt: user.expires_at,
  };
  dispatch(loginSSOSuccess(payload));
  if (user.profile && user.profile.email) {
    const userName: string = user.profile.username;
    const userEmail: string = user.profile.email;
    dispatch(
      fetchUserInfo({
        email: userEmail,
        phone: user?.profile?.phone,
        employeeid: user?.profile?.employeeid,
        username: userName,
        callback,
        dispatch,
        expiresAt: user.expires_at,
      })
    );
    dispatch(getTokenGticket({ username: userName ?? userEmail }));
  }
}

export async function setTokenToApiPrivateUsers(token: string) {
  try {
    RequestApiPrivateUsers.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
  } catch (error) {
    throw error;
  }
}

const initialState: AuthState = {
  authUser: null,
  accessToken: null,
  expiresAt: null,
  status: "idle",
  updateLocaleStatus: "idle",
  kpiUser: { role_actions: {} },
  tokenGTicket: null,
    tokenGPerformance: null,
  isUserInactive: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    loginSSOSuccess: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.expiresAt = action.payload.expiresAt;
    },
    logoutSuccess: (state) => {
      state.authUser = null;
      state.accessToken = null;
      state.expiresAt = null;
    },
    setTokenGPerformance: (state, action) => {
      state.tokenGPerformance = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserInfo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        state.status = "succeeded";

        const userInfo = _.cloneDeep(action.payload);

        if (!userInfo.language) userInfo.language = DEFAULT_LANGUAGE;
        if (userInfo.language.id === 1) userInfo.language = LANGUAGES.VN;
        if (userInfo.language.id === 2) userInfo.language = LANGUAGES.EN;

        if (userInfo.is_admin === 1) {
          userInfo.permissions = { ...userInfo.permissions, IS_ADMIN: 1 };
        } else {
          userInfo.permissions = { ...userInfo.permissions, IS_ADMIN: 0 };
        }
        state.authUser = userInfo;
        updateI18nLocale(userInfo.language);
        setTokenToApiPrivateUsers(userInfo?.token ?? "");
        if (userInfo?.status === 0) {
          state.isUserInactive = true;
        }
      })
      .addCase(fetchUserInfo.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateUserLocale.pending, (state) => {
        state.updateLocaleStatus = "loading";
      })
      .addCase(updateUserLocale.fulfilled, (state, action) => {
        state.updateLocaleStatus = "succeeded";
        if (!state.authUser) return;
        let updateUser: UserType = { ...state.authUser };
        const updateLocale: any = action.payload;
        updateUser.language = updateLocale;
        state.authUser = updateUser;
        updateI18nLocale(updateLocale);
      })
      .addCase(updateUserLocale.rejected, (state) => {
        state.updateLocaleStatus = "failed";
      })
      .addCase(getTokenGticket.fulfilled, (state, action) => {
        state.tokenGTicket = action.payload.token;
      });
  },
});

export const { loginSSOSuccess, logoutSuccess, setTokenGPerformance } = authSlice.actions;

export default authSlice.reducer;

export const selectAuthUser = (state: RootState) => state.auth.authUser;
export const tokenGperformance = (state: RootState) =>
  state.auth.tokenGPerformance;
export const expiresAt = (state: RootState) => state.auth.expiresAt;
export const selectIsUserInActive = (state: RootState) =>
  state.auth.isUserInactive;
