import { createAsyncThunk } from '@reduxjs/toolkit';
import KpiGoalApi from './api';
import { clearFormFilter, clearSlice } from './slice';
import { FilterParam } from '../utils/interface';
import { DEFAULT_PARAMS, openNotification, TOAST_TYPE } from '../utils';

export const getList = createAsyncThunk(
  'kpiGoal/getList',
  async (params: FilterParam) => {
    try {
      const response = await KpiGoalApi.list(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListMore = createAsyncThunk(
  'kpiGoal/getListMore',
  async (params: FilterParam) => {
    try {
      const response = await KpiGoalApi.list(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const add = createAsyncThunk(
  'kpiGoal/add',
  async (
    payload: { body: any; message: any; copy?: boolean },
    { dispatch }
  ) => {
    const { body, message, copy } = payload;
    try {
      const response = await KpiGoalApi.add(body);
      dispatch(clearFormFilter(true));
      dispatch(getList({ ...DEFAULT_PARAMS }));
      openNotification({
        type: TOAST_TYPE.Success,
        message: message.success[copy ? 'copy' : 'create']
      });
      return response;
    } catch (error) {
      openNotification({
        type: TOAST_TYPE.Error,
        message: message.error[copy ? 'copy' : 'create']
      });
      throw error;
    }
  }
);

export const detail = createAsyncThunk(
  'kpiGoal/detail',
  async (id: number, { dispatch }) => {
    try {
      const response = await KpiGoalApi.detail(id);
      dispatch(getParamByIds({ ids: response.data.parameters }));
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const update = createAsyncThunk(
  'kpiGoal/update',
  async (payload: { id: number; body: any; message: any }, { dispatch }) => {
    const { id, body, message } = payload;
    try {
      const response = await KpiGoalApi.update(id, body);
      dispatch(clearFormFilter(true));
      dispatch(getList({ ...DEFAULT_PARAMS }));
      openNotification({
        type: TOAST_TYPE.Success,
        message: message.success.edit
      });
      return response.data;
    } catch (error) {
      openNotification({ type: TOAST_TYPE.Error, message: message.error.edit });
      throw error;
    }
  }
);

export const deleteGoal = createAsyncThunk(
  'kpiGoal/deleteGoal',
  async (params: { id: number; message: any }, { dispatch }) => {
    const { id, message } = params;
    dispatch(clearSlice());
    try {
      const response = await KpiGoalApi.delete(id);
      dispatch(clearFormFilter(true));
      dispatch(getList({ ...DEFAULT_PARAMS }));
      openNotification({
        type: TOAST_TYPE.Success,
        message: message.success.delete
      });
      return response;
    } catch (error) {
      openNotification({
        type: TOAST_TYPE.Error,
        message: message.error.delete
      });
      throw error;
    }
  }
);

export const copy = createAsyncThunk(
  'kpiGoal/copy',
  async (payload: { id: number; message: any }, { dispatch }) => {
    const { id, message } = payload;
    dispatch(clearSlice());
    try {
      const response = await KpiGoalApi.detail(id);
      const _data = response?.data;
      const body = {
        name: _data.name,
        date_applied: _data.date_applied,
        goal_group_id: _data.group.id,
        kpi_formula_name: _data.kpi_formula.name,
        status: _data.status,
        kpi_formula_operators: _data.kpi_formula.kpi_formula_operators.map(
          (_item: any) => ({
            operand: _item.operand,
            operator: _item.operator,
            sort: _item.sort,
            parameter_id: _item?.formula?.parameter_id || ''
          })
        )
      };
      dispatch(add({ body, message, copy: true }));
      return response.data;
    } catch (error) {
      openNotification({ type: TOAST_TYPE.Error, message: message.error.copy });
      throw error;
    }
  }
);

export const getParameters = createAsyncThunk(
  'kpiGoal/getParameters',
  async () => {
    try {
      const response = await KpiGoalApi.parameters();
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getGoalGroup = createAsyncThunk(
  'kpiGoal/getGoalGroup',
  async () => {
    try {
      const response = await KpiGoalApi.goalGroup();
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getDepartments = createAsyncThunk(
  'kpiGoal/getDepartments',
  async () => {
    try {
      const response = await KpiGoalApi.departments();
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const addDepartments = createAsyncThunk(
  'kpiGoal/addDepartments',
  async (payload: { body: any; message: any }, { dispatch }) => {
    const { body, message } = payload;
    try {
      const response = await KpiGoalApi.addDepartments(body);
      openNotification({
        type: TOAST_TYPE.Success,
        message: message.success.addRoom
      });
      return response;
    } catch (error) {
      openNotification({
        type: TOAST_TYPE.Error,
        message: message.error.addRoom
      });
      throw error;
    }
  }
);

export const validateFormula = createAsyncThunk(
  'kpiGoal/validateFormula',
  async (payload: { body: any; message: any }, { dispatch }) => {
    const { body, message } = payload;
    try {
      const response = await KpiGoalApi.validateFormula(body);
      return response;
    } catch (error) {
      openNotification({
        type: TOAST_TYPE.Error,
        message: message.error.validate
      });
      throw error;
    }
  }
);

export const downloadGoal = createAsyncThunk(
  'kpiGoal/downloadGoal',
  async (params: any) => {
    try {
      const response = await KpiGoalApi.downloadGoal(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const viewDraft = createAsyncThunk(
  'kpiGoal/viewDraft',
  async (payload: { body: any; message: any }) => {
    const { body, message } = payload;
    try {
      const response = await KpiGoalApi.viewDraft(body);
      return response;
    } catch (error) {
      openNotification({
        type: TOAST_TYPE.Error,
        message: message.error.previewSetting
      });
      throw error;
    }
  }
);

export const settingGoal = createAsyncThunk(
  'kpiGoal/settingGoal',
  async (payload: { body: any; message: any }, { dispatch }) => {
    const { body, message } = payload;
    try {
      const response = await KpiGoalApi.settingGoal(body);
      dispatch(clearFormFilter(true));
      dispatch(getList({ ...DEFAULT_PARAMS }));
      openNotification({
        type: TOAST_TYPE.Success,
        message: message.success.settingGoal
      });
      return response.data;
    } catch (error) {
      openNotification({
        type: TOAST_TYPE.Error,
        message: message.error.settingGoal
      });
      throw error;
    }
  }
);

export const updateSettingGoal = createAsyncThunk(
  'kpiGoal/updateSettingGoal',
  async (payload: { body: any; message: any }, { dispatch }) => {
    const { body, message } = payload;
    try {
      const response = await KpiGoalApi.updateSettingGoal(body);
      dispatch(clearFormFilter(true));
      dispatch(getList({ ...DEFAULT_PARAMS }));
      openNotification({
        type: TOAST_TYPE.Success,
        message: message.success.settingGoal
      });
      return response.data;
    } catch (error) {
      openNotification({
        type: TOAST_TYPE.Error,
        message: message.error.settingGoal
      });
      throw error;
    }
  }
);

export const getParamByIds = createAsyncThunk(
  'kpiGoal/getParamByIds',
  async (params: any, { dispatch }) => {
    try {
      const response = await KpiGoalApi.getParamByIds(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);
