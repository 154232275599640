import { ENUM_TYPE_SUBTREE } from "./../../../../../components/UI/TreeOKRs/enum";
import { current } from "@reduxjs/toolkit";
import { TypeSubtree } from "../../../../../components/UI/TreeOKRs/type";
import { uuid } from "../../../../../helpers/helper";

export const convertOKRs = (data: any) => {
  return {
    0: data.map((item: any) => ({ ...item, uuid: uuid() })),
  };
};

export const convertOKRsAsStructureOne = (
  params: { level: number; response: any },
  prevState: any
) => {
  const {
    level,
    response: { data },
  } = params;
  let result;
  const _preState = current(prevState);
  const isPrevStateHaveDataNextLevel = _preState[level + 1] === undefined;
  result = {
    ..._preState,
    [level + 1]: isPrevStateHaveDataNextLevel
      ? data
      : [..._preState[level + 1], ...data],
  };
  return result;
};

export const mergeSubItemWithPrevOKRsCeoFocus = (
  params: {
    level: number;
    response: {
      data:
        | {
            active: number;
            assignee_id: number;
            assignee_name: string;
            code: string;
            created_at: string;
            creator_id: number;
            density: number;
            department_id: number;
            department_name: string;
            description: string;
            division_id: number;
            division_name: string;
            from_date: string;
            to_date: string;
            full_name: string;
            id: number;
            key_result_ids: number[] | [];
            level: number;
            name: string;
            parent_id: number;
            progress_actual: number;
            progress_target: number;
            project_type: null | any;
            quality: number;
            sub_project_ids: number[] | [];
            subtree:
              | ENUM_TYPE_SUBTREE.KR
              | ENUM_TYPE_SUBTREE.SUB_OKR
              | ENUM_TYPE_SUBTREE.TASK;
            target: string;
            task_ids: number[] | [];
            type:
              | ENUM_TYPE_SUBTREE.KR
              | ENUM_TYPE_SUBTREE.SUB_OKR
              | ENUM_TYPE_SUBTREE.TASK;
            updated_at: string;
            user_id: 926;
            user_name: string;
          }[]
        | [];
    };
    parentUUID: string;
  },
  prevState: any
) => {
  const {
    level,
    response: { data },
    parentUUID,
  } = params;
  const _preState = current(prevState);
  const isPrevStateHasDataNextLevel = _preState[level + 1] !== undefined;
  let result;
  if (!isPrevStateHasDataNextLevel) {
    result = {
      ..._preState,
      [level + 1]: generateUUIDforResponse(data, "ceo-focus", parentUUID),
    };
    return result;
  }

  if (isPrevStateHasDataNextLevel) {
    const prevSubItemCurrentLevel: any[] = [];
    const OKRs = _preState[level + 1];

    const isElementExited = (
      id: number,
      parent_id: number,
      type: "subokr" | "kr" | "task"
    ) => {
      return data.some(
        (element: any) =>
          element.id === id &&
          element.parent_id === parent_id &&
          element.type === type
      );
    };
    OKRs.forEach((element: any) => {
      if (!isElementExited(element.id, element.parent_id, element.type)) {
        prevSubItemCurrentLevel.push(element);
      }
    });
    result = {
      ..._preState,
      [level + 1]: [
        ...generateUUIDforResponse(data, "ceo-focus", parentUUID),
        ...prevSubItemCurrentLevel,
      ],
    };

    return result;
  }
};

//* ROLE-HOD & STAFF & TEAM-LEAD *//

export const indexOfParentNodeInOKRs = (
  prevState: any,
  keyValue: string,
  value: string | number
) => {
  return prevState.findIndex((okr: any) => okr[keyValue] === value);
};

export const convertOKRsPersonal = (data: any) => {
  let result;
  if (data.length) {
    result = data.map((item: TypePersonal) => ({
      user_id: item.user_id,
      personal_id: item.user_id,
      user_name: item.user_name,
      list_project: {
        0: generateUUIDforResponse(item.list_project, "per"),
      },
      progress_actual: item.progress_actual,
      progress_target: item.progress_target,
    }));
    return result;
  }
  return [];
};

export const convertOKRsDepartment = (data: any) => {
  let result;
  if (data.length) {
    result = data.map((item: TypeDepartment) => ({
      department_id: item.department_id,
      department_name: `OKR - ${item.department_name}`,
      progress_actual: item.progress_actual,
      progress_target: item.progress_target,
      list_project: {
        0: generateUUIDforResponse(item.list_project, "dpm"),
      },
    }));
    return result;
  }
  return [];
};

export const convertOKRsDivision = (data: any) => {
  let result;
  result = data.map((item: TypeDivision) => ({
    division_id: item.division_id,
    division_name: `OKR - ${item.division_name}`,
    list_project: {
      0: generateUUIDforResponse(item.list_project, "dvd"),
    },
    progress_actual: item.progress_actual,
    progress_target: item.progress_target,
  }));
  return result;
};

export const mergeSubItemWithPrevOKRs = (
  params: {
    level: number;
    parentId: number | string;
    keyValue: string;
    response: any;
    keyID?: number | string;
    parentUUID?: string;
  },
  prevState: any
) => {
  const _preState = current(prevState);
  const { level, parentId, keyValue, parentUUID, response } = params;
  const index = indexOfParentNodeInOKRs(_preState, keyValue, parentId);
  if (index < 0) return _preState;
  const listProjectHasExited =
    _preState[index].list_project[level + 1] !== undefined;

  let result;

  if (!listProjectHasExited) {
    result = [
      ..._preState.slice(0, index),
      {
        ..._preState[index],
        list_project: {
          ..._preState[index].list_project,
          [level + 1]: generateUUIDforResponse(response, keyValue, parentUUID),
        },
      },
      ..._preState.slice(index + 1),
    ];
    return result;
  }

  if (listProjectHasExited) {
    const prevSubItemCurrentLevel: any[] = [];
    const OKRs = _preState[index].list_project[level + 1];
    const isElementExited = (
      id: number,
      parent_id: number,
      type: "subokr" | "kr" | "task"
    ) => {
      return response.some(
        (element: any) =>
          element.id === id &&
          element.parent_id === parent_id &&
          element.type === type
      );
    };
    OKRs.forEach((element: any) => {
      if (!isElementExited(element.id, element.parent_id, element.type)) {
        prevSubItemCurrentLevel.push(element);
      }
    });
    result = [
      ..._preState.slice(0, index),
      {
        ..._preState[index],
        list_project: {
          ..._preState[index].list_project,
          [level + 1]: [
            ...generateUUIDforResponse(response, keyValue, parentUUID),
            ...prevSubItemCurrentLevel,
          ],
        },
      },
      ..._preState.slice(index + 1),
    ];

    return result;
  }
};

function generateUUIDforResponse(
  response: any,
  keyValue: string,
  parentUUID?: string
) {
  if (!Array.isArray(response)) return [];
  return response.map((item: any) => ({
    ...item,
    uuid: `${keyValue}-${uuid()}`,
    parentUUID: parentUUID,
  }));
}

type TypeDepartment = {
  department_id: string | number;
  department_name: string;
  progress_actual: number;
  progress_target: number;
  list_project: TypeProjectDepartment[];
};

type TypeDivision = {
  division_id: string | number;
  division_name: string;
  progress_actual: number;
  progress_target: number;
  list_project: TypeProjectDepartment[];
};

type TypePersonal = {
  user_id: string | number;
  user_name: string;
  progress_actual: number;
  progress_target: number;
  list_project: TypeProjectDepartment[];
};

type TypeProjectDepartment = {
  assignee_name: string;
  creator_id: number | string;
  density: number;
  department_id: string | number;
  department_name: string;
  division_id: string | number;
  division_name: string;
  id: string | number;
  level: number;
  name: string;
  parent_id: string | number | null;
  progress_actual: number;
  progress_target: number;
  quality: number;
  subtree: TypeSubtree;
  to_date: Date;
  type: string;
  user_id: string | number;
};
