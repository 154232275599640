import { CustomSelectOption } from "../../../components/UI/CustomSelect/type";
import { ENUM_DAYS_IN_WEEK } from "../target-management/components/common/enum";

const TASK_STATUS = {
  JUST_OPENED: 1,
  REOPEN: 2,
  IN_PROGRESS: 3,
  DONE: 4,
  CLOSED: 5,
  CANCEL: 6,
};

const STATUS_TYPE: any = [
  {
    value: TASK_STATUS.JUST_OPENED,
    label: "okr:constants.justOpened",
    class: "in-progress",
  },
  {
    value: TASK_STATUS.IN_PROGRESS,
    label: "okr:constants.inProgress",
    class: "in-progress",
  },
  {
    value: TASK_STATUS.DONE,
    label: "okr:constants.done",
    class: "done",
  },
  {
    value: TASK_STATUS.CLOSED,
    label: "okr:constants.closed",
    class: "closed",
  },
  {
    value: TASK_STATUS.REOPEN,
    label: "okr:constants.reOpen",
    class: "re-open",
  },
  {
    value: TASK_STATUS.CANCEL,
    label: "okr:constants.cancel",
    class: "canceled",
  },
];

const STATUS_OPTIONS_CANCEL: any = [
  { value: TASK_STATUS.REOPEN, label: "okr:constants.reOpen" },
];

const STATUS_OPTIONS_CREATOR: any = [
  { value: TASK_STATUS.CANCEL, label: "okr:constants.cancel" },
];

const STATUS_OPTIONS: any = [
  { value: TASK_STATUS.IN_PROGRESS, label: "okr:constants.inProgress" },
  { value: TASK_STATUS.DONE, label: "okr:constants.done" },
  { value: TASK_STATUS.CANCEL, label: "okr:constants.cancel" },
];

const ACTIVE_REVIEW_SCORE = {
  UNRATING: 0,
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3,
  VERY_HIGH: 4,
};

const ACTIVE_REVIEW: { [key: number]: { fill: string } } = {
  [ACTIVE_REVIEW_SCORE.UNRATING]: { fill: "#d8d8d8" },

  [ACTIVE_REVIEW_SCORE.LOW]: {
    fill: "#E5781D",
  },
  [ACTIVE_REVIEW_SCORE.MEDIUM]: {
    fill: "#BEC114",
  },
  [ACTIVE_REVIEW_SCORE.HIGH]: {
    fill: "#88CD2F",
  },
  [ACTIVE_REVIEW_SCORE.VERY_HIGH]: {
    fill: "#00A907",
  },
};

const USER_OPTIONS_PER_PAGE = 20;

const MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item: any) => ({
  label: item,
  value: item,
}));

const RATING_TYPE = {
  SELF_RATING: 1,
  MANAGER_RATING: 2,
};

const MANAGER_RATING_TYPE = {
  APPROVED: 1,
  REOPEN: 2,
};

const RATING_OPTIONS = [
  {
    label: "aprrove",
    value: MANAGER_RATING_TYPE.APPROVED,
  },
  {
    label: "reOpen",
    value: MANAGER_RATING_TYPE.REOPEN,
  },
];

const getDaysInMonth = (day: number): CustomSelectOption[] => {
  let result = [];
  for (let i = 1; i <= day; i++) {
    result.push({ value: i, label: `${i}` });
  }
  return result;
};

const DAYS_IN_MONTH = getDaysInMonth(30);

const OKR_OWNER = [1, 2, 3, 4, 5, 6, 7, 8].map((item: any) => ({
  value: item,
  label: `SUB_OKR_${item}`,
}));

const OKR_TYPES_VALUES = {
  CEO: 1,
  DEPARTMENT: 2,
  PERSONAL: 3,
};

const OKR_TYPE_OPTIONS = {
  OPTIONS_1: [
    { value: OKR_TYPES_VALUES.CEO, label: "createOkr.type.ceo" },
    {
      value: OKR_TYPES_VALUES.DEPARTMENT,
      label: "createOkr.type.department",
    },
  ],
  OPTIONS_2: [{ value: OKR_TYPES_VALUES.CEO, label: "createOkr.type.ceo" }],
  OPTIONS_3: [
    {
      value: OKR_TYPES_VALUES.DEPARTMENT,
      label: "createOkr.type.department",
    },
    {
      value: OKR_TYPES_VALUES.PERSONAL,
      label: "createOkr.type.personal",
    },
  ],
};

const OKR_TYPES = [
  { value: OKR_TYPES_VALUES.CEO, label: "createOkr.type.ceo" },
  {
    value: OKR_TYPES_VALUES.DEPARTMENT,
    label: "createOkr.type.department",
  },
  {
    value: OKR_TYPES_VALUES.PERSONAL,
    label: "createOkr.type.personal",
  },
];

const FREQUENCY_TYPE = {
  WEEK: 1,
  MONTH: 2,
};

const FREQUENCY_RATING = {
  PERIODIC: 1,
  MILESTONE: 2,
};

const FREQUENCY_RATING_PERIODIC: any = [
  { value: FREQUENCY_TYPE.WEEK, label: "constants.weekly" },
  { value: FREQUENCY_TYPE.MONTH, label: "constants.monthly" },
];
const DAYS_IN_WEEK: CustomSelectOption[] = [
  { value: ENUM_DAYS_IN_WEEK.T2, label: "constants.Mon" },
  { value: ENUM_DAYS_IN_WEEK.T3, label: "constants.Tue" },
  { value: ENUM_DAYS_IN_WEEK.T4, label: "constants.Wed" },
  { value: ENUM_DAYS_IN_WEEK.T5, label: "constants.Thu" },
  { value: ENUM_DAYS_IN_WEEK.T6, label: "constants.Fri" },
  { value: ENUM_DAYS_IN_WEEK.T7, label: "constants.Sat" },
  { value: ENUM_DAYS_IN_WEEK.CN, label: "constants.Sun" },
];

const OKR_APPROVE_TYPE = { UNAPPROVE: 1, APPROVED: 2 };

const CHECKLIST_TEMPLATE = "check_list_okr.xlsx";

const AVAILABLE_TIMESHEET = 5;

export {
  AVAILABLE_TIMESHEET,
  CHECKLIST_TEMPLATE,
  RATING_OPTIONS,
  OKR_TYPES,
  OKR_APPROVE_TYPE,
  USER_OPTIONS_PER_PAGE,
  MONTHS,
  OKR_TYPE_OPTIONS,
  RATING_TYPE,
  TASK_STATUS,
  OKR_TYPES_VALUES,
  FREQUENCY_RATING_PERIODIC,
  DAYS_IN_WEEK,
  DAYS_IN_MONTH,
  FREQUENCY_TYPE,
  FREQUENCY_RATING,
  OKR_OWNER,
  STATUS_TYPE,
  ACTIVE_REVIEW,
  STATUS_OPTIONS,
  ACTIVE_REVIEW_SCORE,
  STATUS_OPTIONS_CANCEL,
  STATUS_OPTIONS_CREATOR,
};
