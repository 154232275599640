const IconShow = () => {
  return (
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3711 6.54045C10.3711 7.84995 9.30913 8.91119 7.99963 8.91119C6.69013 8.91119 5.62888 7.84995 5.62888 6.54045C5.62888 5.2302 6.69013 4.16895 7.99963 4.16895C9.30913 4.16895 10.3711 5.2302 10.3711 6.54045Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.9985 12.0165C10.8545 12.0165 13.4668 9.96298 14.9375 6.53998C13.4668 3.11698 10.8545 1.06348 7.9985 1.06348H8.0015C5.1455 1.06348 2.53325 3.11698 1.0625 6.53998C2.53325 9.96298 5.1455 12.0165 8.0015 12.0165H7.9985Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default IconShow;
