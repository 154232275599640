import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../store/store";
import {
  onGetKRs,
  onGetOKRs,
  onGetSubOKRs,
  onGetTasks,
} from "./departmentRequest";
import {
  convertOKRsDepartment,
  mergeSubItemWithPrevOKRs,
} from "../helpers/convert";

const initialState = {
  okr: [],
  loading: false,
  status: "idle",
};

const departmentSlice = createSlice({
  name: "role/ceo/departments",
  initialState,
  reducers: {
    _onCleanUpOKRs(state) {
      state.okr = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(onGetOKRs.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(onGetOKRs.fulfilled, (state, action) => {
        state.status = "success";
        state.okr = convertOKRsDepartment(action.payload);
        state.loading = false;
      })
      .addCase(onGetOKRs.rejected, (state) => {
        state.status = "failed";
        state.loading = false;
      })
      .addCase(onGetSubOKRs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(onGetSubOKRs.fulfilled, (state, action) => {
        state.status = "success";
        const payload: any = action.payload;
        state.okr = mergeSubItemWithPrevOKRs(
          {
            level: payload.level,
            parentId: payload.parentId,
            keyValue: "department_id",
            response: payload.response.data,
            parentUUID: payload.parentUUID,
          },
          state.okr
        );
      })
      .addCase(onGetSubOKRs.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(onGetKRs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(onGetKRs.fulfilled, (state, action) => {
        state.status = "success";
        const payload: any = action.payload;
        state.okr = mergeSubItemWithPrevOKRs(
          {
            level: payload.level,
            parentId: payload.parentId,
            keyValue: "department_id",
            response: payload.response.data,
            parentUUID: payload.parentUUID,
          },
          state.okr
        );
      })
      .addCase(onGetKRs.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(onGetTasks.pending, (state) => {
        state.status = "loading";
      })
      .addCase(onGetTasks.fulfilled, (state, action) => {
        state.status = "success";
        const payload: any = action.payload;
        state.okr = mergeSubItemWithPrevOKRs(
          {
            level: payload.level,
            parentId: payload.parentId,
            keyValue: "department_id",
            response: payload.response.data,
            parentUUID: payload.parentUUID,
          },
          state.okr
        );
      })
      .addCase(onGetTasks.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export default departmentSlice.reducer;
export const { _onCleanUpOKRs } = departmentSlice.actions;
export const selectorOKRs = (state: RootState) => state.departmentCeo.okr;
export const selectorLoadingOKRs = (state: RootState) =>
  state.departmentCeo.loading;
