import React, { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { selectAuthUser } from "../../features/auth/redux/authSlice";
import { UserType } from "../../features/auth/types/types";
import { checkPermissionByPathName } from "../../helpers/permissionFactory";
import { useAppSelector } from "../../store/store";

type PropsType = {
  children: ReactNode;
  allowedRoles?: number[];
};

function isUserCanAccessRoute(
  user: UserType | null,
  pathname: string,
  permissions: any
): boolean {
  if (!user) return false;
  if (user?.is_admin === 1) return true;
  if (!checkPermissionByPathName(pathname, permissions)) return false;
  return true;
}

function PrivateRoute({ children }: PropsType) {
  const location = useLocation();
  const authUser = useAppSelector(selectAuthUser);

  const renderContent = () => {
    if (!authUser)
      return (
        <Navigate to="/unauthenticated" state={{ from: location.pathname }} />
      );
    if (
      !isUserCanAccessRoute(authUser, location.pathname, authUser.permissions)
    ) {
      return <Navigate to="/unauthorized" />;
    }
    return <React.Fragment>{children}</React.Fragment>;
  };
  return <>{renderContent()}</>;
}

export default PrivateRoute;
