import { lazy } from "react";

/**
 * PROCESS MANAGEMENT
 */
const GprocessManagement = lazy(
  () => import("../../process-managements/process-management/ProcessManagement")
);
const GprocessMyPocess = lazy(
  () =>
    import("../../process-managements/process-management/my-process/MyProcess")
);

//Create Process
const GCreateProcess = lazy(
  () => import("../../process-managements/create-process/CreateProcess")
);
const GForm = lazy(
  () => import("../../process-managements/create-process/create/Form")
);

// Copy Process
const GprocessMyPocessCopy = lazy(
  () =>
    import(
      "../../process-managements/process-management-to-me/home/copy/ProcessCopy"
    )
);
const ContentGprocessCopy = lazy(
  () =>
    import(
      "../../process-managements/process-management-to-me/home/copy/content/ContentCopy"
    )
);

// Version process
const GprocessMyPocessVersion = lazy(
  () =>
    import(
      "../../process-managements/process-management-to-me/home/version/ProcessVersion"
    )
);
const ContentGprocessVersion = lazy(
  () =>
    import(
      "../../process-managements/process-management-to-me/home/version/content/ContentVersion"
    )
);

// Process detail
const GprocessMyPocessDetail = lazy(
  () =>
    import(
      "../../process-managements/process-management/my-process/process-detail/ProcessDetail"
    )
);
const ContentGprocessDetail = lazy(
  () =>
    import(
      "../../process-managements/process-management/my-process/process-detail/content/ContentDetail"
    )
);

// History
const GprocessMyPocessHistory = lazy(
  () =>
    import(
      "../../process-managements/process-management-to-me/home/history/ProcessHistory"
    )
);
const ContentGprocessHistory = lazy(
  () =>
    import(
      "../../process-managements/process-management-to-me/home/history/content/ContentHistory"
    )
);

//Process to Me
const GprocessManagementToMe = lazy(
  () =>
    import(
      "../../process-managements/process-management-to-me/PocessManagementToMe"
    )
);
const GprocessMyPocessToMe = lazy(
  () =>
    import("../../process-managements/process-management-to-me/home/MyPocess")
);

// Chi tiết
const GprocessMyPocessDetailTome = lazy(
  () =>
    import(
      "../../process-managements/process-management-to-me/home/process-detail/ProcessDetail"
    )
);
// Các component của chi tiết quy trình
const ContentGprocessDetailTome = lazy(
  () =>
    import(
      "../../process-managements/process-management-to-me/home/process-detail/content/ContentDetail"
    )
);
// Tiến độ
const GprocessMyPocessProgress = lazy(
  () =>
    import(
      "../../process-managements/process-management-to-me/home/progressbar/ProcessProgressBar"
    )
);
// Các component của chi tiết quy trình
const ContentGprocessProgress = lazy(
  () =>
    import(
      "../../process-managements/process-management-to-me/home/progressbar/content/ContentProgressbar"
    )
);

// list process
const ListProcessManagement = lazy(
  () => import("../../process-managements/process-list/ListProcess")
);
const GprocessListContent = lazy(
  () => import("../../process-managements/process-list/content")
);

/**
 * MISSION MANAGEMENT
 */
const GRequest = lazy(() => import("../../mission-management/request/Request"));
const GprocessMyTicket = lazy(
  () => import("../../mission-management/request/GprocessMyTicket")
);

const GCreateVotes = lazy(
  () =>
    import("../../mission-management/request/form-request/FormRequestCreate")
);

const GDetailVotes = lazy(
  () =>
    import("../../mission-management/request/form-request/FormRequestDetail")
);

const GMission = lazy(() => import("../../mission-management/mission/Mission"));

const GprocessMyMission = lazy(
  () => import("../../mission-management/mission/GprocessMyMission")
);

const GMissionDetail = lazy(
  () => import("../../mission-management/mission/my-mission/MyMissionDetail")
);

const MyWork = lazy(
  () => import("../../mission-management/mission/subContent/MyWork")
);

// Lịch sử nhiệm vụ
const GprocessMyMissionHistory = lazy(
  () => import("../../mission-management/my-ticket/history/MissionHistory")
);

const GprocessMyMissionHistoryContent = lazy(
  () =>
    import("../../mission-management/my-ticket/history/content/ContentHistory")
);
// Chi tiết phiếu tự động
const GprocessMissionAuto = lazy(
  () => import("../../mission-management/my-ticket/mission-auto/MissionAuto")
);
const GprocessMissionAutoDetail = lazy(
  () =>
    import("../../mission-management/my-ticket/mission-auto/content/Content")
);

let path = "/g-process/";
const routeProcess: any = [
  {
    path: `${path}my-process`,
    component: GprocessManagement,
    index: GprocessMyPocess,
  },
  {
    path: `${path}create-process`,
    component: GCreateProcess,
    index: GForm,
  },
  {
    path: `${path}copy-process/:id`,
    component: GprocessMyPocessCopy,
    index: ContentGprocessCopy,
  },
  {
    path: `${path}version-process/:id`,
    component: GprocessMyPocessVersion,
    index: ContentGprocessVersion,
  },
  {
    path: `${path}details-process/:id`,
    component: GprocessMyPocessDetail,
    index: ContentGprocessDetail,
  },
  {
    path: `${path}history-process/:id`,
    component: GprocessMyPocessHistory,
    index: ContentGprocessHistory,
  },
  {
    path: `${path}process-sent-to-me`,
    component: GprocessManagementToMe,
    index: GprocessMyPocessToMe,
  },
  {
    path: `${path}details-process-to-me/:id`,
    component: GprocessMyPocessDetailTome,
    index: ContentGprocessDetailTome,
  },
  {
    path: `${path}progress-process/:id`,
    component: GprocessMyPocessProgress,
    index: ContentGprocessProgress,
  },
  {
    path: `${path}process-list`,
    component: ListProcessManagement,
    index: GprocessListContent,
  },
  /**
   * Mission management
   */
  {
    path: `${path}my-claim-form`,
    component: GRequest,
    index: GprocessMyTicket,
  },
  {
    path: `${path}create-votes`,
    component: GCreateVotes,
    index: "",
  },
  {
    path: `${path}create-votes/:id`,
    component: GCreateVotes,
    index: "",
  },
  {
    path: `${path}detail-votes/:id`,
    component: GDetailVotes,
    index: "",
  },
  {
    path: `${path}my-mission/:id`,
    component: GMissionDetail,
    index: "",
  },
  {
    path: `${path}my-mission`,
    component: GMission,
    index: GprocessMyMission,
    submenu: [
      {
        path: "/my-work",
        component: MyWork,
      },
    ],
  },
  {
    path: `${path}my-claim-form/history/:id`,
    component: GprocessMyMissionHistory,
    index: GprocessMyMissionHistoryContent,
  },
  {
    path: `${path}my-claim-form/mission-auto/:id`,
    component: GprocessMissionAuto,
    index: GprocessMissionAutoDetail,
  },
];

export default routeProcess;
