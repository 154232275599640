import { formatNameOfUser } from "../../../../helpers/formatter";
import { CODE_LEVEL_WORKSPACE, FIELD_NAMES } from "../constants";
import { cloneDeep } from "lodash";
import {
  EmployeeInformationType,
  ExportParameterType,
  GoalCategoryResponseType,
  GoalCategoryType,
  HeaderTableDataType,
  SelectOptionType,
  TreeNodeDataType,
  UserType,
  WorkspaceType,
  WorkspaceTypeSingle,
} from "../interfaces";
import format from "date-fns/format";
import i18n from "../../../../i18n";
import { formatShowDecimalNumber } from "../../common/utils/helper";

/**
 *
 * @param trees workspace in response tree type
 * @description create treeNode workspace that AntDesign tree Select can use
 * @returns workspace in TreeNodeDataType
 */
export const mapWorkSpaceIntoTree = (trees: WorkspaceType[]) => {
  let result: TreeNodeDataType[] = [];
  trees.forEach((tree: WorkspaceType) => {
    const _result: TreeNodeDataType = {
      value: String(tree.id),
      id: tree.id,
      isRegion: tree.level === CODE_LEVEL_WORKSPACE.REGION,
      title: tree.name,
      key: String(tree.id),
      region: tree.region_id,
      children: [],
    };
    if (tree.children && tree.children.length > 0) {
      _result.children = mapWorkSpaceIntoTree(tree.children);
    }
    result.push(_result);
  });
  return result;
};

/**
 *
 * @param trees workspace in treeList
 * @description create Object with workspace id is key, contains data of workspaces
 * @returns workspace in object type
 */
export const mapWorkSpaceIntoList = (trees: WorkspaceType[]) => {
  let result: { [key: number]: WorkspaceTypeSingle } = {};
  trees.forEach((tree: WorkspaceType) => {
    let _result: WorkspaceTypeSingle = {} as WorkspaceTypeSingle;
    Object.entries(tree).forEach(([key, value]: [key: string, value: any]) => {
      if (key !== "children") {
        _result[key] = value;
      } else if (Array.isArray(value) && value?.length > 0) {
        _result["hasChildren"] = true;
      } else {
        _result["hasChildren"] = false;
      }
    });
    _result.parent = tree.parent_id;
    if (tree.children && tree.children.length > 0) {
      result = { ...result, ...mapWorkSpaceIntoList(tree.children) };
    }
    result[tree.id] = _result;
  });
  return result;
};
/**
 *
 * @param value value need to be converted
 * @description return value to Array type
 * @return value in Array type
 */
export const convertValue = (value: any) =>
  Array.isArray(value) ? value : [value];

/**
 *
 * @param data EmployeeInformationType
 * @returns listEmployee with label and workspace_id
 *
 */
export const calculateListEmployee = (data: EmployeeInformationType[]) => {
  let result: SelectOptionType[] = [];
  result = data.map((item: EmployeeInformationType) => ({
    ...item,
    value: item.id,
    label: formatNameOfUser(item),
    workspace_id: item?.workspace?.id,
  }));
  return result;
};
/**
 *
 * @param data history data of performance
 * @description convert data to render data that used in FE
 * @returns array of value with information that needed
 */
export function convertHistory(data: any) {
  return data.map((record: any) => {
    return {
      key: record.id,
      status: record.status,
      user: record.user.user_name,
      time: format(new Date(record.performed_at), "dd/MM/yyyy HH:mm:ss"),
      note: record.note ? record.note : "-",
    };
  });
}

/**
 *
 * @param data Goal category from backend
 * @returns performance goals for render in table
 * @description convert data from API to render data
 */
export function convertGoalCategory(data: GoalCategoryResponseType[]) {
  let results = data.map((category: GoalCategoryResponseType) => {
    const obj: GoalCategoryType = {
      key: category.code,
      id: category.id * -1,
      property: category.name,
      className: ` category ${category.code}`,
      type: "value",
      children: category?.goals
        ?.map((item: GoalCategoryResponseType) => {
          return {
            key: item.code,
            id: item.id,
            property: item.name,
            className: item.code,
          };
        })
        .sort((a, b) => String(a.property).localeCompare(b.property)),
    };
    return obj;
  });
  const status: GoalCategoryType = {
    id: -2,
    key: "STATUS_PERFORMANCE",
    className: "status item",
    property: i18n.t("gperformance:performance_table.status") as string,
    type: FIELD_NAMES.STATUS,
  };
  const summary: GoalCategoryType = {
    id: -2,
    key: "OVERALL",
    property: i18n.t("gperformance:performance_table.total") as string,
    className: "summary",
    type: "total",
  };
  return [summary, ...results, status];
}
/**
 *
 * @param data get data from API in list order
 * @description convert API to Object with subject id is key
 * @returns Object with id is key and value is proportion Data
 */
export function mapResultPerformanceManySubject(data: any) {
  let result: { [key: string]: any } = {};
  Object.entries(data).forEach(([key, value]: [key: string, value: any]) => {
    const objProportion: { [key: string]: any } = {};
    value.data.forEach(
      (item: any) =>
        (objProportion[item.goal_id] = formatShowDecimalNumber(item.proportion))
    );
    objProportion.status = value.latest_status;
    result[key] = objProportion;
  });
  return result;
}

/**
 *
 * @param data the proportion of months/year
 */
export const mapResultPerformanceOneSubject = (data: any) => {
  let result: { [key: string]: any } = {};
  Object.entries(data).forEach(([key, value]: [key: string, value: any]) => {
    const objProportion: { [key: string]: any } = {};
    value.data.forEach(
      (item: any) =>
        (objProportion[item.goal_id] = 
          formatShowDecimalNumber(item.proportion)
        )
    );
    objProportion.status = value.latest_status;
    if (key !== FIELD_NAMES.STATUS) {
      const timeKey = new Date(`01/${key}`).getTime();
      result[timeKey] = objProportion;
    } else {
      result[key] = objProportion;
    }
  });
  return result;
};

/**
 *
 * @param param0 dataSet that need to update with new mapProportion
 */
export const updateTableDataSource = ({
  dataSet,
  employeeId,
  mapProportion,
}: {
  dataSet: GoalCategoryType[];
  employeeId: number;
  mapProportion: any;
}) => {
  let summary = 0;
  try {
    dataSet.forEach((item) => {
      const key = item.id;
      // get data source here
      if (item?.children) {
        item.children = updateTableDataSource({
          dataSet: item?.children,
          employeeId: employeeId,
          mapProportion,
        });
      }
      let total = 0;
      switch (true) {
        case item.type === FIELD_NAMES.STATUS:
          item[employeeId] = mapProportion[employeeId]?.status;
          break;
        case Array.isArray(item.children) && item?.children?.length > 0:
          item?.children?.forEach((_item) => {
            const number = _item[employeeId] ? Number(_item[employeeId]) : 0;
            if (number > 0) {
              total = total + formatShowDecimalNumber(number);
            }
          });
          item[employeeId] = formatShowDecimalNumber(total);
          break;
        default:
          item[employeeId] =
            mapProportion[employeeId] && mapProportion[employeeId][key]
              ? Number(mapProportion[employeeId][key])
              : null;
          break;
      }
      if (item.type === "value") {
        summary = summary + item[employeeId];
      }
    });
  } catch (error) {
    throw error;
  }
  const index = dataSet.findIndex(
    (item: GoalCategoryType) => item.type === "total"
  );
  if (index > -1) {
    dataSet[index][employeeId] = formatShowDecimalNumber(summary);
  }
  return dataSet;
};
export const calculateTableColumnData = ({
  element,
  mapChildren,
  mapEmployee,
  toggleMap,
}: {
  element: UserType;
  mapChildren: Map<number, Array<number>>;
  mapEmployee: Map<number, UserType>;
  toggleMap: { [key: string]: boolean };
}) => {
  let result: UserType[] = [element];
  if (element) {
    if (toggleMap[element.id] === true) {
      const listChildrenId = mapChildren.get(element.id) ?? [];
      let data: UserType[] = [];
      let listEmployee = listChildrenId.map(
        (id) => mapEmployee.get(id) as UserType
      );

      listEmployee = listEmployee.sort((a, b) => {
        if (a.level - b.level === 0) {
          return a.name.localeCompare(b.name);
        } else return a.level - b.level;
      });
      listEmployee.forEach((_employee) => {
        if (_employee) {
          data = data.concat(
            calculateTableColumnData({
              element: _employee,
              mapChildren,
              mapEmployee,
              toggleMap,
            })
          );
        }
      });
      result = result.concat(data.filter((item) => item != null));
    }
  }
  return result;
};
export const convertMapEmployee = (employees: any) => {
  const result: any = {};
  employees.forEach((item: any) => {
    result[item.id] = item;
  });
  return result;
};
export const getWorkspaceIds = (workspaces: TreeNodeDataType[]) => {
  let listId: Array<number> = [];
  workspaces.forEach((workspace: TreeNodeDataType) => {
    if (!workspace.disabled) {
      listId.push(workspace.id);
    }
    if (workspace.children) {
      listId = listId.concat(getWorkspaceIds(workspace.children));
    }
  });
  return listId;
};
export const getTime = ({ month }: { month: string }) => {
  let [_month, _year] = typeof month == "string" ? month.split("/") : [month];
  return { month: Number(_month), year: Number(_year) };
};
export const getStyleStatusChip = (value: any) => {
  if (Number(value) > 100) {
    return {
      color: "red",
    };
  }
};
/**
 *
 * @param filter filter value that send to serve to get export file
 */
export const createExportConfiguration = (filter: ExportParameterType) => {
  const result = {
    type: filter.type,
    type_unit: filter.type_unit,
    status: filter.status,
    assignee_id: filter.assignee_id,
    month: filter.month,
  };
  return result;
};

/**
 *
 * @param param0 data set contains goals and map
 * @returns filtered goal that show in table
 */
export const getShow = ({
  goals,
  map,
}: {
  goals: GoalCategoryType[];
  map: any;
}) => {
  const result: GoalCategoryType[] = [];
  const newGoal = cloneDeep(goals);
  newGoal.forEach((row: GoalCategoryType) => {
    if (!("type" in row)) {
      if (map[Number(row.id)] === true) {
        result.push(row);
      }
    } else {
      if (Array.isArray(row.children) && row.children.length > 0) {
        row.children = getShow({ goals: row.children, map });
      }
      result.push(row);
    }
  });
  return result;
};

/**
 * create time config for current filter
 */
export const defaultTimeConfig = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const monthOfYear = (_m: number) =>
    ("0" + (_m + 1)).slice(-2) + `/${currentYear}`;
  const month: { [key: string]: string[] | string } = {
    single: monthOfYear(currentDate.getMonth()),
    multiple: [...Array.from({ length: 12 }, (i, index) => monthOfYear(index))],
  };
  const year: { [key: string]: string[] | string } = {
    single: monthOfYear(currentDate.getMonth()),
    multiple: [...Array.from({ length: 12 }, (i, index) => monthOfYear(index))],
  };
  return { month, year };
};

const generateHeaderSingle = (time: string) => {
  return {
    id: new Date("01/" + time).getTime(),
    name: time,
    parent: null,
  };
};

/**
 *
 * @param months array string of month
 * @returns array header of data Table
 */
export const generateDateHeader = (months: string[]) => {
  let dataHeader: HeaderTableDataType[] = [];
  if (Array.isArray(months)) {
    dataHeader = months.map((item: string) => {
      return generateHeaderSingle(item) as HeaderTableDataType;
    });
  }
  return dataHeader;
};

/**
 *
 * @param data management data that need to convert to map Data for easy get
 * @returns map of management data of user
 */
export const mapManagementByResponse = (data: any) => {
  let mapManagement: { [key: string]: any } = {};
  data.forEach((user: any) => {
    user?.managements?.forEach((workspace: any) => {
      if ("directly" in workspace) {
        if (!mapManagement[user.id]) {
          mapManagement[user.id] = [];
        }
        mapManagement[user.id] = mapManagement[user.id].concat(
          workspace.directly
        );
      }
    });
  });

  return mapManagement;
};

/**
 *
 * @param data data management
 * @returns object of data management with key is id and value is array of ids that subject manage
 */
export const getDirectManagement = (data: any) => {
  let result: { [key: number]: number[] } = {};
  Object.entries(data).forEach(([key, value]) => {
    Array.isArray(value) &&
      value.forEach((user: any) => {
        if (!result[user.id]) {
          result[user.id] = [];
        }
        result[user.id].push(Number(key));
      });
  });
  return result;
};

export function getChildDepartmentByListIds(
  tree: any,
  ids: { [key: number]: boolean },
  selected: boolean = false
) {
  let _selected = selected;
  let result: { [key: number]: boolean } = { ...ids };
  if (selected) {
    result[tree.id] = true;
  } else if (result[tree?.id] === true) {
    _selected = true;
  } else {
    _selected = false;
  }

  if (tree?.children) {
    tree?.children.forEach((item: any) => {
      const res = getChildDepartmentByListIds(item, result, _selected);
      result = { ...res, ...result };
    });
  }

  return result;
}
