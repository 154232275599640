import { ReactNode } from "react";
import Button from "../Button";
import style from "./style.module.scss";
type TypeProps = {
  onChange?: any;
  children?: ReactNode;
  upload?: any;
  disabled?: any;
  size?: string;
};
const UploadFile = (props: TypeProps) => {
  const { onChange, children, upload, disabled, size = "md" } = props;
  // const ref = useRef<any>();
  const onOpenFile = (e: any) => {
    let a: any = document.createElement("input");
    a.multiple = true;
    a.addEventListener("change", (e: any) => {
      return upload ? upload : onChange(e.target);
    });
    a.type = "file";
    a.accept =
      ".doc, .docx, .pdf, .xlsx, .xls, .csv, .png, .img, .zip, .rar, .JPG,";
    a.click();
    e.preventDefault();
  };

  return (
    <>
      <div className={style.ui_uploadfile}>
        <Button
          variant="outlined"
          sizeBtn={size}
          onClick={(e: any) => onOpenFile(e)}
          type="button"
          disabled={disabled}
        >
          {children}
        </Button>
      </div>
      {/* <div className={style.file}>
        <input
          multiple
          ref={ref}
          onChange={upload ? upload : (e: any) => onChange(e.target)}
          type="file"
          accept=".doc, .docx, .pdf, .xlsx, .xls, .csv, .png, .img, .zip, .rar, .JPG,"
        />
      </div> */}
    </>
  );
};

export default UploadFile;
