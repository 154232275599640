import { RESOURCE_TYPE } from "./constant";
import { FilterParam } from "./interface";

export const convertResourceType = (status: number): string => {
  if (status === RESOURCE_TYPE.Target) return "Target";
  return "Actual";
};

export const removeCondition = (el: any) => {
  const _el = { ...el };
  if (_el.conditions) {
    delete _el.conditions;
  }
  return _el;
};

export const downloadFileParam = (params: FilterParam) => {
  const _params = { ...params } as {
    page?: number;
    status?: number;
    resource_code?: string;
    keyword?: string;
    per_page?: number;
  };
  if (_params.per_page) {
    delete _params.per_page;
  }
  if (_params.page) {
    delete _params.page;
  }
  return _params;
};

export const downloadFile = (response: any): void => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const fileName = getFileNameInResponseHeader(response.headers);
  const link = document.createElement("a");
  link.target = "_blank";
  link.download = fileName;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

const getFileNameInResponseHeader = (headers: any): any => {
  let fileName = "";
  if (!headers) return "";
  if ("content-disposition" in headers) {
    const data = headers["content-disposition"]
      .split(";")
      .map((item: string) => item.trim());

    for (let item of data) {
      const disposition = item.split("=");
      if (disposition.length > 0) {
        if (disposition[0] === "filename") return disposition[1];
      }
    }
  }
  return fileName;
};

export const flattenObject = (ob: any) => {
  var toReturn: any = {};
  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] == "object" && ob[i] !== null) {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;
        toReturn[i + "[" + x + "]"] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};
