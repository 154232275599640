import { Tooltip } from "antd";
import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { EmployeeInfo } from "../../interfaces";
import usePerformanceManagement from "../../redux/hook";
import styles from "./style.module.scss";
import { useEffect, useState } from "react";
function Employee(props: Readonly<EmployeeInfo>) {
  const { position, name, email, id } = props;
  const {
    toggleMap,
    updateToggleMap,
    addFollowSubject,
    selectedSubject,
    followSubject,
    mapManagement,
    fetchEmployeeManagement,
  } = usePerformanceManagement();

  const onToggleExpand: React.MouseEventHandler<HTMLButtonElement> = async (
    e
  ) => {
    if (!selectedSubject.includes(id) && !toggleMap[id]) {
      const data = await fetchEmployeeManagement(String(id));

      let resultMapManagement: number[] = [];
      const _dataUserManagement = data.payload.data.find(
        (item: any) => item.id === id
      );
      _dataUserManagement.managements.forEach((workspace: any) => {
        workspace.directly?.forEach((employee: any) => {
          resultMapManagement.push(employee.id);
        });
      });

      const usedSubject = [...followSubject];
      let mapUsedUser: { [key: number]: boolean } = {};
      usedSubject.forEach((id: number) => {
        mapUsedUser[id] = true;
      });
      let addIds = resultMapManagement.filter((id) => !mapUsedUser[id]);
      addFollowSubject(addIds);
      updateToggleMap(id);
    } else {
      updateToggleMap(id);
    }
  };

  const [hasChildren, setHasChildren] = useState(false);
  const [isDirectApproval, setIsDirectApproval] = useState(false);
  useEffect(() => {
    const isBold = [...selectedSubject, ...followSubject].includes(id);
    setIsDirectApproval(isBold);
    return () => {};
  }, [selectedSubject, followSubject]);
  useEffect(() => {
    setHasChildren(mapManagement[id] && mapManagement[id].length > 0);
    return () => {};
  }, [mapManagement]);

  return (
    <div className={styles["employee"]}>
      <Tooltip title={position}>
        <div className={styles["position"]}>{position}</div>
      </Tooltip>
      {/* <div className={styles["position"]}>{position}</div> */}
      <div className={styles["detail"]}>
        <div className={styles["context"]}>
          <Tooltip title={name}>
            <div
              className={`${styles["name"]} ${
                isDirectApproval ? styles["bold"] : ""
              }`}
            >
              {name}
            </div>
          </Tooltip>
          <Tooltip title={email}>
            <div className={styles["email"]}>{email}</div>
          </Tooltip>
        </div>
        {hasChildren && (
          <button className={styles["action"]} onClick={onToggleExpand}>
            {toggleMap && !!toggleMap[id] ? (
              <MinusSquareOutlined style={{ fontSize: "16px" }} />
            ) : (
              <PlusSquareOutlined style={{ fontSize: "16px" }} />
            )}
          </button>
        )}
      </div>
    </div>
  );
}
export default Employee;
