import React from "react";

function IconEditT({ fill = "black" }) {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5628 14.9548H9.12305C8.81255 14.9548 8.56055 14.7028 8.56055 14.3923C8.56055 14.0818 8.81255 13.8298 9.12305 13.8298H14.5628C14.8733 13.8298 15.1253 14.0818 15.1253 14.3923C15.1253 14.7028 14.8733 14.9548 14.5628 14.9548Z"
        fill={fill}
      />
      <mask id="mask0_6283_595" x="0" y="0" width="14" height="15">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.5 0.750122H13.3854V14.9548H0.5V0.750122Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_6283_595)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.83271 2.2626L1.77146 11.0939C1.64321 11.2544 1.59596 11.4614 1.64321 11.6601L2.15396 13.8239L4.43321 13.7954C4.64996 13.7931 4.85021 13.6964 4.98296 13.5314C7.39571 10.5126 11.9955 4.7571 12.126 4.58835C12.249 4.38885 12.297 4.10685 12.2325 3.83535C12.1665 3.5571 11.9932 3.32085 11.7435 3.1701C11.6902 3.13335 10.4265 2.15235 10.3875 2.1216C9.91196 1.7406 9.21821 1.8066 8.83271 2.2626ZM1.70996 14.9549C1.44971 14.9549 1.22321 14.7764 1.16246 14.5221L0.548206 11.9189C0.421456 11.3796 0.547456 10.8231 0.893206 10.3911L7.95821 1.5546C7.96121 1.5516 7.96346 1.54785 7.96646 1.54485C8.74121 0.618604 10.1422 0.482104 11.0872 1.24035C11.1247 1.2696 12.3795 2.2446 12.3795 2.2446C12.8355 2.5161 13.1917 3.00135 13.3267 3.57585C13.461 4.14435 13.3635 4.73085 13.0507 5.2266C13.0275 5.26335 13.0072 5.29485 5.86121 14.2349C5.51696 14.6639 5.00096 14.9136 4.44671 14.9204L1.71746 14.9549H1.70996Z"
          fill={fill}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1671 7.26365C11.0471 7.26365 10.9271 7.2254 10.8243 7.1474L6.73533 4.0064C6.48933 3.8174 6.44283 3.4649 6.63183 3.2174C6.82158 2.9714 7.17408 2.92565 7.42083 3.11465L11.5106 6.2549C11.7566 6.4439 11.8031 6.79715 11.6133 7.0439C11.5031 7.1879 11.3358 7.26365 11.1671 7.26365Z"
        fill={fill}
      />
    </svg>
  );
}

export default IconEditT;
