import { Workspace } from "../../features/meta-info/redux/metaInfoSlice";
import { OrganizationTreeType } from "../../types/types";

export function mapTreeData(
  workspaces: Workspace[],
  depth: number = 1
): OrganizationTreeType[] {
  return workspaces.map((workspace) => {
    const nodeData = mapWorkspaceToNodeData(workspace, depth);
    if (Array.isArray(workspace.children) && workspace.children.length > 0) {
      return {
        ...nodeData,
        children: mapTreeData(workspace.children, depth + 1),
      };
    }
    return {
      ...nodeData,
      children: [],
    };
  });
}

export function mapTreeDataByLevel(workspaces: Workspace[], level: number) {
  const treeData = mapTreeData(workspaces);
  const filterTree = cutTreeByLevel(treeData, level);
  return filterTree;
}

function mapWorkspaceToNodeData(workspace: Workspace, depth: number = 1) {
  const title = renderNodeTitle(workspace);
  return {
    title,
    value: workspace.id,
    key: workspace.id,
    level: depth,
    name: workspace.name,
  };
}

// TODO: To find all workspaces where the level property is different from its depth in the tree
function findWorkspacesWithIncorrectLevel(
  workspaces: Workspace[],
  depth: number = 1
): Workspace[] {
  const result: Workspace[] = [];

  workspaces.forEach((workspace) => {
    if (workspace.level !== depth) {
      result.push(workspace);
    }

    if (Array.isArray(workspace.children) && workspace.children.length > 0) {
      result.push(
        ...findWorkspacesWithIncorrectLevel(workspace.children, depth + 1)
      );
    }
  });

  return result;
}

function renderNodeTitle(node: Workspace) {
  const level = node.level;
  const customStyle = getStyleTitle(level);

  return <span style={{ ...customStyle }}>{node.name}</span>;
}

function getStyleTitle(level: number) {
  const styleGlobalTitle = {
    fontSize: "16px",
  };
  if (level === 1) {
    return { fontFamily: "var(--font-family-bold)", ...styleGlobalTitle };
  }
  if (level === 2) {
    return { fontFamily: "var(--font-family-medium)", ...styleGlobalTitle };
  } else {
    return { fontFamily: "var(--font-family-regular)", ...styleGlobalTitle };
  }
}

export function filterTree(
  tree: OrganizationTreeType[],
  workSpaceIds: number[],
  level: number
) {
  if (workSpaceIds.length === 0) return tree;
  const branchTree = getTreeBranch(tree, workSpaceIds);

  return cutTreeByLevel(branchTree, level);
}

export function getTreeBranch(
  tree: OrganizationTreeType[],
  workSpaceIds: number[]
): OrganizationTreeType[] {
  function getNodes(result: any[], node: OrganizationTreeType) {
    if (workSpaceIds.includes(node.value)) {
      // BASE CASE
      result.push(node);
      return result;
    }
    if (Array.isArray(node.children)) {
      // RECURSIVE CASE
      const children = node.children.reduce(getNodes, []);

      if (children.length)
        result.push({
          ...node,
          children,
        });
    }
    return result;
  }

  return tree.reduce(getNodes, []);
}

function cutTreeByLevel(
  tree: OrganizationTreeType[],
  level: number
): OrganizationTreeType[] {
  const deepTree = getDepth(tree[0]);
  if (level >= deepTree) return tree;
  function getNodes(result: any[], node: OrganizationTreeType) {
    if (node.level === level) {
      const updatedNode = {
        ...node,
        children: [],
      };
      return [...result, updatedNode];
    }
    if (Array.isArray(node.children)) {
      const children = node.children.reduce(getNodes, []);
      if (children.length) result.push({ ...node, children });
    }
    return result;
  }
  return tree.reduce(getNodes, []);
}

function getPathFormRootNode(
  node: OrganizationTreeType,
  workSpaceId: any,
  path: number[] = []
): number[] {
  if (node.value === workSpaceId) return [...path, workSpaceId]; // BASE CASE
  if (node.children.length > 0) {
    for (let child of node.children) {
      // RECURSIVE CASE
      const pathUpdated = [...path, node.value];
      const childPath = getPathFormRootNode(child, workSpaceId, pathUpdated);
      if (childPath.includes(workSpaceId)) {
        return childPath;
      }
    }
  }
  return [];
}

export function getNodeById(
  node: Workspace,
  workSpaceId: number
): Workspace | null {
  // preorder depth-first search
  if (node.id === workSpaceId) return node; // BASE CASE
  if (node.children && node.children.length > 0) {
    // RECURSIVE CASE
    for (let child of node.children) {
      let returnValue = getNodeById(child, workSpaceId);
      if (returnValue !== null) return returnValue;
    }
  }
  return null; // BASE CASE;
}

export function getNodeByIdFromTreeData(
  node: OrganizationTreeType,
  workSpaceId: number
): OrganizationTreeType | null {
  // preorder depth-first search
  if (node?.value === workSpaceId) return node; // BASE CASE
  if (node?.children && node.children.length > 0) {
    // RECURSIVE CASE
    for (let child of node.children) {
      let returnValue = getNodeByIdFromTreeData(child, workSpaceId);
      if (returnValue !== null) return returnValue;
    }
  }
  return null; // BASE CASE;
}

function getDepth(node: OrganizationTreeType): number {
  if (!node) return 0;
  if (node.children.length === 0) return 1; // BASE CASE
  // RECURSIVE CASE
  let maxChildDepth = 0;
  for (let child of node.children) {
    // find the depth of each child node
    const childDepth = getDepth(child);
    if (childDepth > maxChildDepth) {
      // this child is deepest child node found so far
      maxChildDepth = childDepth;
    }
  }
  return 1 + maxChildDepth;
}

export function getJobTitlesFromWorkspaceId(
  workspaces: Workspace[],
  workspaceId: number
) {
  if (!workspaceId) return [];
  const node = getNodeById(workspaces[0], workspaceId);
  if (!node) return [];
  function getNodeJobTitles(node: any, result: any[] = []) {
    if (Array.isArray(node.job_titles) && node.job_titles.length > 0) {
      const titleIds = result.map((item) => item.id);
      const newJobTitles = node.job_titles.filter(
        (title: any) => !titleIds.includes(title.id)
      );
      const dataJobTitles = newJobTitles.map((item: any) => {
        return {
          ...item,
          code: node.code,
        };
      });
      result.push(...dataJobTitles);
    }
    if (Array.isArray(node.children) && node.children.length > 0) {
      for (let child of node.children) {
        getNodeJobTitles(child, result);
      }
    }
    return result;
  }
  return getNodeJobTitles(node);
}

export const filterTreeNode = (inputValue: string, treeNode: any) => {
  const nodeTitle = treeNode.title.props.children.toLowerCase();
  return nodeTitle.includes(inputValue.trim().toLowerCase());
};

export function getTreeDisabledToIds(
  node: OrganizationTreeType[],
  disabledNodeParent: number[],
  workSpaceIds: number[],
  id?: number
): OrganizationTreeType[] {
  return node.map((value: OrganizationTreeType) => {
    return {
      ...value,
      children: getTreeDisabledToIds(
        value.children,
        disabledNodeParent,
        workSpaceIds,
        disabledNodeParent.includes(value.value) ? value.value : id
      ),
      disabled:
        !disabledNodeParent.includes(id || value.value) &&
        !workSpaceIds.includes(id || value.value),
    };
  });
}

export const customIdWorkspaces = (
  listWorkspaces: OrganizationTreeType[]
): number[] => {
  let idWorkspaces: number[] = [];
  const customWorkspaces = (
    data: OrganizationTreeType[],
    level = 0,
    id?: number
  ) => {
    if (id) {
      idWorkspaces = idWorkspaces.concat(id);
    }

    for (let i: number = 0; i < data?.length; i++) {
      customWorkspaces(data[i].children, level + 1, data[i].value);
    }
  };
  customWorkspaces(listWorkspaces);
  return idWorkspaces;
};

export const customIdWorkspacesItem = (
  listWorkspaces: OrganizationTreeType[],
  idWorkSpace: number[]
): number[] => {
  let idWorkspaces: number[] = [];
  const customWorkspaces = (
    data: OrganizationTreeType[],
    level = 0,
    listIdWordSpace: number[],
    id?: number
  ) => {
    if (id && idWorkSpace.includes(id) && listIdWordSpace) {
      idWorkspaces = idWorkspaces.concat(listIdWordSpace);
    }

    for (let i: number = 0; i < data?.length; i++) {
      customWorkspaces(
        data[i].children,
        level + 1,
        idWorkSpace.includes(data[i].value)
          ? listIdWordSpace
          : [...listIdWordSpace, data[i].value],
        data[i].value
      );
    }
  };
  customWorkspaces(listWorkspaces, 0, []);
  return idWorkspaces;
};
