import React from "react";

function IconNofiStep(props) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.0001 0.666748C11.1084 0.666748 0.666748 11.1084 0.666748 24.0001C0.666748 36.8918 11.1084 47.3334 24.0001 47.3334C36.8918 47.3334 47.3334 36.8918 47.3334 24.0001C47.3334 11.1084 36.8918 0.666748 24.0001 0.666748ZM26.3334 35.6668H21.6667V21.6667H26.3334V35.6668ZM26.3334 17.0001H21.6667V12.3334H26.3334V17.0001Z"
        fill="#F0B71C"
      />
    </svg>
  );
}

export default IconNofiStep;
