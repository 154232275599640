import { Modal, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconCloseModal } from "../../../../../components/icons";
import i18n from "../../../../../i18n";
import {
  FIELD_NAMES,
  MAP_TEXT,
  STATUS_STYLE_NAME,
  STATUS_TABLE,
} from "../../constants";
import usePerformanceManagement from "../../redux/hook";
import { LoadingSSC } from "../table/spinning";
import styles from "./style.module.scss";
interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}
const columns: ColumnsType<DataType> = [
  {
    title: i18n.t("gperformance:performance_filter.history.status") as string,
    dataIndex: FIELD_NAMES.STATUS,
    key: FIELD_NAMES.STATUS,
    width: 175,
    render(value) {
      const text = MAP_TEXT[value];
      return (
        <div className={`${styles.status} ${styles[STATUS_STYLE_NAME[value]]}`}>
          {text}
        </div>
      );
    },
  },
  {
    width: 140,
    title: i18n.t("gperformance:performance_filter.history.user") as string,
    dataIndex: "user",
    key: "user",
  },
  {
    title: i18n.t("gperformance:performance_filter.history.time") as string,
    width: 180,
    dataIndex: "time",
    key: "time",
  },
  {
    title: i18n.t("gperformance:performance_filter.history.note") as string,
    key: "note",
    dataIndex: "note",
  },
];
function ModalHistoryPerformance() {
  const { t } = useTranslation("gperformance", {
    keyPrefix: "performance_filter.history",
  });
  const [openModal, setOpenModal] = useState(false);
  const {
    historyPerformance,
    openHistoryPerformance,
    fetchHistoryPerformance,
    dataHistoryPerformance,
  } = usePerformanceManagement();
  const [data, setData] = useState<DataType[]>([]);
  const [loading, setLoading] = useState(STATUS_TABLE.LOADED);
  useEffect(() => {
    setLoading(STATUS_TABLE.LOADING);
    if (historyPerformance !== null) {
      setOpenModal(true);
      fetchHistoryPerformance(historyPerformance);
    } else {
      setOpenModal(false);
      setData([]);
    }
    return () => {};
  }, [historyPerformance]);
  useEffect(() => {
    setData(dataHistoryPerformance);
    setLoading(STATUS_TABLE.LOADED);
  }, [dataHistoryPerformance]);
  const closeHistoryModal = () => {
    openHistoryPerformance(null);
  };
  return (
    <Modal
      centered
      open={openModal}
      title={t("title")}
      onCancel={closeHistoryModal}
      wrapClassName="kpi-wrapper"
      width={750}
      bodyStyle={{ overflowY: "auto", padding: 0 }}
      footer={null}
      closeIcon={<IconCloseModal />}
      maskClosable={false}
    >
      <Table
        className={styles[`performance-history`]}
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ y: window.innerHeight / 2 }}
        loading={{
          spinning: loading === STATUS_TABLE.LOADING,
          indicator: <LoadingSSC loading={true} />,
        }}
      />
    </Modal>
  );
}
export default ModalHistoryPerformance;
