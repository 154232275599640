export const DEFAULT_PERMISSION_ID = 0;

export const ROLE_PERMISSIONS_FLATTEN = {
  G_PMS: {
    MAIN_DASHBOARD: 1,
    TIMESHEET_MANAGEMENT: 2,
    TASK_EVALUATION: 3,
    TASK_REPORT: 4,
    SYSTEM_MANAGEMENT: 5,
    USER_MANAGEMENT: 6,
    USERGROUP_MANAGEMENT: 7,
    USER_ADMINISTRATION: 8,
    WORK_CATEGORY_LIST: 9,
    CATEGORY_LIST: 10,
    CRUD_CATEGORY: 11,
    IMPORT_DATA: 12,
    EXPORT_DATA: 13,
    // DEPARTMENT_CATEGORY: 14,
    DEPARTMENT_LIST: 15,
    EXPORT_DATA2: 16,
    HANDLER_GROUP: 110,
    HANDLER_GROUP_LIST: 112,
    HANDLER_GROUP_UPDATE: 113,
    HANDLER_GROUP_CREATE: 114,
    HANDLER_GROUP_SLA: 115,
    ORGANIZATIONAL_STRUCTURE: 15,
    ORGANIZATIONAL_STRUCTURE_CREATE: "15-CREATE",
    ORGANIZATIONAL_STRUCTURE_EDIT: "15-EDIT",
    ORGANIZATIONAL_STRUCTURE_DETAIL: "15-VIEW",
    QR_ORDER_MANAGEMENT: 129,
  },
  OKR: {
    ORK_ID: 17,
    MY_TARGET: 18,
    CREATE_OKR: 19,
    CREATE_SUBOKR: 20,
    CREATE_KR: 21,
    CREATE_TASK: 22,
    TASK_RATING: 23,
    DEPARTMENT_OKR_APPROVE: 24,
    OKR_DETAIL: 25,
    SUBOKR_DETAIL: 26,
    KR_DETAIL: 27,
    TASK_DETAIL: 28,
    OKR_DENSITY_ALLOCATE: 29,
    PERSONAL_OKR_APPROVE: 30,
    MY_TASK: 31,
    TASK_LIST: 32,
    SELF_RATING: 33,
    TASK_DETAIL_MY_TASK: 34,
    KEY_STAKEHOLDER_RATING: 35,
  },
  G_PROCESS: {
    DASHBOARD: 36,
    PROCESS_MANAGEMENT: 37,
    MY_PROCESS: 38,
    PROCESS_LIST: 39,
    PROCESS_DETAIL: 40,
    PROCESS_FORWARD: 41,
    PROCESS_COPY: 42,
    CREATE_PROCESS: 43,
    VIEW_PROCESS_HISTORY: 44,
    CREATE_NEW_PROCESS_VERSION: 45,
    DELETE_PROCESS: 46,
    PROCESS_SENT_TO_ME: 47,
    PROCESS_LIST_TO_ME: 48,
    COPY_PROCESS_TO_ME: 49,
    PROCESS_DETAIL_TO_ME: 50,
    VIEW_PROCESS_HISTORY_TO_ME: 51,
    PROCESS_LIST_DUPLICATE: 52,
    TASK_MANAGEMENT: 53,
    MY_REQUEST_TICKET: 54,
    PROCESS_LIST_DUPLICATE_2: 55,
    REQUEST_TICKET_LIST: 56,
    REQUEST_TICKET_HISTORY: 57,
    CREATE_TICKET: 58,
    REQUEST_TICKET_DETAIL: 59,
    DELETE_REQUEST_TICKET: 60,
    MY_TASK: 61,
    SLA: 62,
  },
  G_TICKET: {
    WORK_LIST: 63,
    MY_TASK: 64,
    CREATE_TASK: 65,
    MY_REQUEST: 66,
    TASK_DETAIL: 67,
    WORKING_GROUP_MANAGEMENT: 68,
    WORKING_GROUP_LIST: 69,
    CREATE_WORKING_GROUP: 70,
    WORK_GROUP_DETAIL: 71,
    UPDATE_GROUP_INFO: 72,
  },
  G_PERFORMANCE: {
    DASHBOARD: 73,
    RESULT_PERFORMANCE_MANAGEMENT: 74,
    RESULT_PERFORMANCE_MANAGEMENT_VIEW_LIST: 75,
    RESULT_PERFORMANCE_MANAGEMENT_EDIT: 76,
    RESULT_PERFORMANCE_MANAGEMENT_CALCULATING_SCORE: 77,
    RESULT_PERFORMANCE_MANAGEMENT_ADD: 78,
    RESULT_PERFORMANCE_MANAGEMENT_DELETE: 79,
    PERFORMANCE_MANAGEMENT: 80,
    PERFORMANCE_MANAGEMENT_VIEW_LIST: 81,
    PERFORMANCE_MANAGEMENT_SEND_APPROVE: 82,
    PERFORMANCE_MANAGEMENT_LIST_STATUS: 83,
    COMPUTING_MANAGEMENT: 84,
    COMPUTING_MANAGEMENT_VIEW_LIST: 85,
    COMPUTING_MANAGEMENT_EDIT: 86,
    COMPUTING_MANAGEMENT_ADD: 87,
    COMPUTING_MANAGEMENT_DELETE: 88,
    HISTORY_SYNC_DATA: 89,
    APPROVE_PERFORMANCE: 90,
    GOAL_MANAGEMENT: 91,
    GOAL_MANAGEMENT_GROUP: 92,
    GOAL_MANAGEMENT_GROUP_VIEW_LIST: 93,
    GOAL_MANAGEMENT_GROUP_ADD: 94,
    GOAL_MANAGEMENT_GROUP_DELETE: 95,
    GOAL_MANAGEMENT_GROUP_VIEW_DETAIL: 96,
    GOAL_MANAGEMENT_GROUP_EDIT: 97,
    GOAL_MANAGEMENT_GOAL: 98,
    GOAL_MANAGEMENT_GOAL_VIEW_LIST: 99,
    GOAL_MANAGEMENT_GOAL_ADD: 100,
    GOAL_MANAGEMENT_GOAL_EDIT: 101,
    GOAL_MANAGEMENT_GOAL_DELETE: 102,
    GOAL_MANAGEMENT_GOAL_VIEW_DETAIL: 103,
    GOAL_MANAGEMENT_GOAL_COPY: 104,
    GOAL_MANAGEMENT_GOAL_SETTING: 105,
    PARAM_MANAGEMENT: 106,
    PARAM_MANAGEMENT_VIEW_LIST: 107,
    PARAM_MANAGEMENT_ADD: 108,
    PARAM_MANAGEMENT_DELETE: 109,
    PARAM_MANAGEMENT_VIEW_DETAIL: 131,
    PARAM_MANAGEMENT_EDIT: 132,
    RESOURCE_MANAGEMENT: 133,
    RESOURCE_MANAGEMENT_ADD: 134,
    RESOURCE_MANAGEMENT_VIEW_LIST: 135,
    RESOURCE_MANAGEMENT_HISTORY_SYNC: 136,
    RESOURCE_MANAGEMENT_UPDATE: 137,
    RESOURCE_MANAGEMENT_VIEW_DETAIL: 138,
    CONFIG: 139,
    CONFIG_PROPORTION_GENE: 140,
    CONFIG_VIEW_PROPORTION_LIST: 141,
    CONFIG_EDIT_PROPORTION_LIST: 142,
    CONFIG_ADD_PROPORTION: 143,
    CONFIG_VIEW_DETAIL_PROPORTION: 144
  }
};

const { OKR, G_PMS, G_PROCESS, G_TICKET, G_PERFORMANCE } = ROLE_PERMISSIONS_FLATTEN;

export const ROUTE_PATH_NAME_BY_ID = {
  UNAUTHENTICATED: "/unauthenticated",
  UNAUTHORIZED: "/unauthorized",
  ACCOUNT_INACTIVE: "/account-inactive",
  [G_PMS.MAIN_DASHBOARD]: "/dashboard",
  [G_PMS.TIMESHEET_MANAGEMENT]: "/timesheet-management",
  [G_PMS.TASK_EVALUATION]: "/task-evaluation",
  [G_PMS.TASK_REPORT]: "/g-process/work-report/stafical",
  [G_PMS.TASK_REPORT]: "sla",
  [G_PMS.TASK_REPORT]: "/gticket/work-report",
  [G_PMS.SYSTEM_MANAGEMENT]: "",
  [G_PMS.USER_MANAGEMENT]: "/system-management/user",
  [G_PMS.USERGROUP_MANAGEMENT]: "/system-management/department",
  [G_PMS.USER_ADMINISTRATION]: "/system-management/part",
  [G_PMS.WORK_CATEGORY_LIST]: "/system-management/category-list",
  [G_PMS.CATEGORY_LIST]: "",
  [G_PMS.CRUD_CATEGORY]: "",
  [G_PMS.IMPORT_DATA]: "",
  [G_PMS.EXPORT_DATA]: "",
  // [G_PMS.DEPARTMENT_CATEGORY]: "/system-management/department-list",
  // [G_PMS.DEPARTMENT_LIST]: "",
  [G_PMS.EXPORT_DATA2]: "",
  GPMS_USER_MANAGEMENT: "/system-management/user",
  [G_PMS.HANDLER_GROUP]: "/system-management/assignee",
  [G_PMS.HANDLER_GROUP_LIST]: "/system-management/assignee",
  [G_PMS.ORGANIZATIONAL_STRUCTURE]: "/system-management/organization-structure",
  [G_PMS.ORGANIZATIONAL_STRUCTURE_DETAIL]: "/system-management/organization-structure/:type",
  // [G_PMS.ORGANIZATIONAL_STRUCTURE_EDIT]: "/system-management/organization-structure/edit",
  // [G_PMS.ORGANIZATIONAL_STRUCTURE_CREATE]: "/system-management/organization-structure/detail",
  [G_PMS.QR_ORDER_MANAGEMENT]: "/system-management/qr-order",
  //----------------------//
  [OKR.ORK_ID]: "",
  [OKR.MY_TARGET]: "/okr/target-management",
  [OKR.CREATE_OKR]: "",
  [OKR.CREATE_SUBOKR]: "",
  [OKR.CREATE_KR]: "",
  [OKR.CREATE_TASK]: "",
  [OKR.TASK_RATING]: "",
  [OKR.DEPARTMENT_OKR_APPROVE]: "",
  [OKR.OKR_DETAIL]: "",
  [OKR.SUBOKR_DETAIL]: "",
  [OKR.KR_DETAIL]: "",
  [OKR.TASK_DETAIL]: "",
  [OKR.OKR_DENSITY_ALLOCATE]: "",
  [OKR.PERSONAL_OKR_APPROVE]: "",
  [OKR.MY_TASK]: "/okr/my-task",
  [OKR.TASK_LIST]: "",
  [OKR.SELF_RATING]: "",
  [OKR.TASK_DETAIL_MY_TASK]: "",
  [OKR.KEY_STAKEHOLDER_RATING]: "/okr/main-user-evaluation",
  //------------------------//
  [G_PROCESS.DASHBOARD]: "/g-process/dashboard",
  [G_PROCESS.PROCESS_MANAGEMENT]: "",
  [G_PROCESS.MY_PROCESS]: "/g-process/my-process",
  [G_PROCESS.PROCESS_LIST]: "",
  [G_PROCESS.PROCESS_DETAIL]: "/g-process/details-process/:id",
  [G_PROCESS.PROCESS_FORWARD]: "",
  [G_PROCESS.PROCESS_COPY]: "/g-process/copy-process/:id",
  [G_PROCESS.CREATE_PROCESS]: "/g-process/create-process",
  [G_PROCESS.VIEW_PROCESS_HISTORY]: "/g-process/history-process/:id",
  [G_PROCESS.CREATE_NEW_PROCESS_VERSION]: "/g-process/version-process/:id",
  [G_PROCESS.DELETE_PROCESS]: "",
  [G_PROCESS.PROCESS_SENT_TO_ME]: "/g-process/process-sent-to-me",
  [G_PROCESS.PROCESS_LIST_TO_ME]: "",
  [G_PROCESS.COPY_PROCESS_TO_ME]: "",
  [G_PROCESS.PROCESS_DETAIL_TO_ME]: "/g-process/details-process-to-me/:id",
  [G_PROCESS.VIEW_PROCESS_HISTORY_TO_ME]: "",
  [G_PROCESS.PROCESS_LIST_DUPLICATE]: "/g-process/process-list",
  [G_PROCESS.TASK_MANAGEMENT]: "",
  [G_PROCESS.MY_REQUEST_TICKET]: "/g-process/my-claim-form",
  [G_PROCESS.PROCESS_LIST_DUPLICATE_2]: "/g-process/process-list",
  [G_PROCESS.REQUEST_TICKET_LIST]: "",
  [G_PROCESS.REQUEST_TICKET_HISTORY]: "/g-process/my-claim-form/history/:id",
  [G_PROCESS.CREATE_TICKET]: "/g-process/create-votes",
  [G_PROCESS.REQUEST_TICKET_DETAIL]: "/g-process/detail-votes/:id",
  [G_PROCESS.DELETE_REQUEST_TICKET]: "",
  [G_PROCESS.MY_TASK]: "/g-process/my-mission",
  [G_PROCESS.SLA]: "sla",
  G_PROCESS_CREATE_TICKET_BY_ID: "/g-process/create-votes/:id",
  G_PROCESS_MY_PROCESS_PROGRESS: "/g-process/progress-process/:id",
  G_PROCESS_DETAIL_MY_TASK: "/g-process/my-mission/:id",
  G_PROCESS_DETAIL_AUTO_TASK: "/g-process/my-claim-form/mission-auto/:id",
  [G_TICKET.WORK_LIST]: "/gticket/work-list",
  [G_TICKET.MY_TASK]: "",
  [G_TICKET.CREATE_TASK]: "",
  [G_TICKET.MY_REQUEST]: "",
  [G_TICKET.TASK_DETAIL]: "/gticket/work-list/detail",
  [G_TICKET.WORKING_GROUP_MANAGEMENT]: "/gticket/workgroup-management",
  [G_TICKET.WORKING_GROUP_LIST]: "/gticket/workgroup-management",
  [G_TICKET.CREATE_WORKING_GROUP]: "",
  [G_TICKET.WORK_GROUP_DETAIL]: "/gticket/workgroup-detail/:id",
  [G_TICKET.UPDATE_GROUP_INFO]: "",
  //------------------------//
  [G_PERFORMANCE.DASHBOARD]: '/g-performance/dashboard',
  [G_PERFORMANCE.RESULT_PERFORMANCE_MANAGEMENT]:
    '/g-performance/result-management',
  [G_PERFORMANCE.PERFORMANCE_MANAGEMENT]:
    '/g-performance/performance-management',
  [G_PERFORMANCE.COMPUTING_MANAGEMENT]: '/g-performance/computing-management',
  [G_PERFORMANCE.HISTORY_SYNC_DATA]: '/g-performance/history-sync-data',
  [G_PERFORMANCE.GOAL_MANAGEMENT_GROUP]: '/g-performance/goal-group',
  [G_PERFORMANCE.GOAL_MANAGEMENT_GOAL]: '/g-performance/goal',
  [G_PERFORMANCE.PARAM_MANAGEMENT]: '/g-performance/param-management',
  [G_PERFORMANCE.RESOURCE_MANAGEMENT]: '/g-performance/data-management',
  [G_PERFORMANCE.APPROVE_PERFORMANCE]: '/g-performance/result-approve',
  [G_PERFORMANCE.PERFORMANCE_MANAGEMENT_LIST_STATUS]: '/g-performance/list-status',
  [G_PERFORMANCE.APPROVE_PERFORMANCE]: '/g-performance/approve-performance',
  [G_PERFORMANCE.CONFIG]: '',
  GPROCESS_MY_PARAMS_LIST: "g-process/my-parameter-list",
};

export const EXTRA_GPROCESS_TASK_ROUTE = [
  ROUTE_PATH_NAME_BY_ID.G_PROCESS_DETAIL_MY_TASK,
  ROUTE_PATH_NAME_BY_ID.G_PROCESS_DETAIL_AUTO_TASK,
];

export const EXTRA_GPROCESS_TICKET_ROUTE = [
  ROUTE_PATH_NAME_BY_ID.G_PROCESS_CREATE_TICKET_BY_ID,
];

export const EXTRA_GPERFORMANCE_DASHBOARD_ROUTE = [
  '/g-performance/dashboard/workspace-detail/:id',
  '/g-performance/dashboard/user-detail/:id'
]

export const DEFAULT_ROUTE_NOT_SETTING_PERMISSION = [
  ROUTE_PATH_NAME_BY_ID.G_PROCESS_MY_PROCESS_PROGRESS,
  "/g-process/work-report/stafical",
  "/gticket/work-report",
  ROUTE_PATH_NAME_BY_ID.ACCOUNT_INACTIVE,
];

export const DASHBOARD_ROUTE = [
  "/",
  "/dashboard/tasks/g-process",
  "/dashboard/tasks-g-ticket",
  "/dashboard/tasks-statistics/g-ticket",
];

export const DEVELOPMENT_ROUTE_PATH = {
  COMPONENT_DEMO: "/development/demo"
}
