import IconCloseModal from "../../../icons/IconCloseModal";
import style from "./style.module.scss";
type TypeProps = {
  headerContent: String;
  handleClose: () => void;
  hiddenCloseIcon?: boolean;
};

const HeaderModal = ({ headerContent, handleClose, hiddenCloseIcon = false }: TypeProps) => {
  return (
    <div className={style["container-header-modal"]}>
      <p className="fw-bolder">{headerContent}</p>
      {!hiddenCloseIcon && (
        <div onClick={handleClose} className={style["container-close-icon"]}>
          <IconCloseModal />
        </div>
      )}
    </div>
  );
};
export default HeaderModal;
