import React, { lazy, Suspense, useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { MyTargetProvider } from "../../features/okr/contexts/MyTargetContext";
import useRoleUser from "../../features/okr/hooks/useRoleUser";
import Layout from "../Layout/Layout";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import LoadingFallback from "../UI/LoadingFallback/LoadingFallback";
import { ROLES_ID, ROUTE_PATH } from "../../helpers/constant";
import {
  DEVELOPMENT_ROUTE_PATH,
  ROLE_PERMISSIONS_FLATTEN,
  ROUTE_PATH_NAME_BY_ID,
} from "../../helpers/permissionConstant";
import Unauthorized from "../UI/Unauthorized/Unauthorized";
import { TypeRoute } from "../../features/g-process/component/helper/type";
import routeProcess from "../../features/g-process/component/routes/routeProcess";
import RouteGticket from "../../features/gticket/routes/routes";
import mobileDetectedRoutes from "../../features/mobile-view/routes";
import FetchAllUser from "../FetchAllUser/FetchAllUser";
import {
  DASHBOARD_CONFIG_TABLE,
  DASHBOARD_TABLE_ROW_NAME,
} from "../../features/dashboard/helper/Dashboard";
import { routeGPerformance } from "./KpiRoutes";

function CustomRoutes() {
  const LoginCallback = lazy(
    () => import("../../features/auth/components/LoginCallback/LoginCallback")
  );

  // OKR
  const MyTargetTeamLead = lazy(
    () =>
      import(
        "../../features/okr/target-management/MyTarget/role-team-lead/TargetTeamLead"
      )
  );

  const MyTargetStaff = lazy(
    () =>
      import(
        "../../features/okr/target-management/MyTarget/role-staff/TargetStaff"
      )
  );

  const MyTargetCeo = lazy(
    () =>
      import("../../features/okr/target-management/MyTarget/type-ceo/TargetCeo")
  );

  const MyTargetHOD = lazy(
    () =>
      import("../../features/okr/target-management/MyTarget/role-hod/TargetHOD")
  );

  const MyTargetSM = lazy(
    () =>
      import("../../features/okr/target-management/MyTarget/role-sm/TargetSM")
  );

  const MyTasks = lazy(
    () => import("../../features/okr/my-tasks/MyTasks/MyTasks")
  );

  const SystemCategorylist = lazy(
    () =>
      import(
        "../../features/system-management/category-list/CategoryListManagement"
      )
  );

  const SystemDepartmentlist = lazy(
    () =>
      import(
        "../../features/system-management/department-list/DepartmentListManagement"
      )
  );

  const AsigneeList = lazy(
    () =>
      import(
        "../../features/system-management/handler-group/component/handlergroup-list/HanlderGroupManagement"
      )
  );
  const Qticket = lazy(
    () => import("../../features/system-management/qticket")
  );
  const QROrderManagement = lazy(
    () => import("../../features/system-management/qr-code-management/index")
  );
  const UserDetailPage = lazy(
    () => import("../../features/system-management/user/components/user-detail")
  );

  const OrganizationStructure = lazy(
    () => import("../../features/system-management/workspace/index")
  );

  const OrganizationStructureDetail = lazy(
    () =>
      import(
        "../../features/system-management/workspace/components/WorkspaceDetail/index"
      )
  );

  const NoMatch = React.lazy(() => import("../../features/no-match/NoMatch"));

  // Task Evaluation
  const TaskEvaluation = React.lazy(
    () => import("../../features/task-evaluation/TaskEvaluation")
  );
  // Main user evaluation
  const MainUserEvaluation = React.lazy(
    () => import("../../features/okr/main-user-evaluation/MainUserEvaluation")
  );
  //
  const ManagementTimeSheet = React.lazy(
    () => import("../../features/management-timesheet/ManagementTimeSheet")
  );

  const SystemUser = lazy(
    () => import("../../features/system-management/user/User")
  );

  const AccountInactive = React.lazy(
    () => import("../../features/no-match/AccountInactive")
  );

  //  Gprocess Dashboard
  // const GprocessDashboard = lazy(
  //   () => import("../../features/g-process/dashboard/Dashboard")
  // );
  // const GprocessDashboardContent = lazy(
  //   () => import("../../features/g-process/dashboard/content/Content")
  // );

  // Main dashboard
  const DashboardLayout: any = lazy(
    () => import("../../features/dashboard/Dashboard")
  );
  const DashboardContent = lazy(
    () => import("../../features/dashboard/views/content/DashboardContent")
  );
  const DashboardTask = lazy(
    () => import("../../features/dashboard/views/list-task/TaskList")
  );
  const GTicketTasks = lazy(
    () => import("../../features/dashboard/views/g-ticket-tasks/GTicketTask")
  );
  const TaskStatistics = lazy(
    () =>
      import("../../features/dashboard/views/task-statistics/TaskStatistics")
  );

  // Mobile detected
  const MobileDetectedView = lazy(
    () => import("../../features/mobile-view/index")
  );

  // Development live demo
  const DevelopmentLiveDemo = lazy(
    () => import("../../features/development/index")
  );

  const RouterMyTarget = () => {
    const { roleID } = useRoleUser();
    const shouldVisbileRoleSM = roleID === ROLES_ID.SM;
    const shouldVisbileRoleHOD = roleID === ROLES_ID.HOD;
    const shouldVisbileRoleTeamLead = roleID === ROLES_ID.TEAMLEAD;
    const shouldVisbileRoleStaff = roleID === ROLES_ID.STAFF;
    const shouldVisbileRoleCEO = [
      ROLES_ID.CEO,
      ROLES_ID.DCEO,
      ROLES_ID.CFO,
      ROLES_ID.MDNS,
      ROLES_ID.MDSS,
    ].includes(roleID);
    // ROLES_ID
    if (!roleID) return <LoadingFallback />;

    if (shouldVisbileRoleSM) {
      return <MyTargetSM />;
    }
    if (shouldVisbileRoleHOD) {
      return <MyTargetHOD />;
    }

    if (shouldVisbileRoleTeamLead) {
      return <MyTargetTeamLead />;
    }

    if (shouldVisbileRoleStaff) {
      return <MyTargetStaff />;
    }

    if (shouldVisbileRoleCEO) {
      return <MyTargetCeo />;
    }
    return <Navigate to="/unauthenticated" />;
  };

  const { OKR, G_PMS } = ROLE_PERMISSIONS_FLATTEN;

  const RouteGPerformance = useMemo(
    () =>
      routeGPerformance.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <PrivateRoute>
              <route.component />
            </PrivateRoute>
          }
        >
          <Route path="*" element={<NoMatch />} />
        </Route>
      )),
    []
  );

  return (
    <Suspense fallback={<LoadingFallback />}>
      <Routes>
        <Route
          path={mobileDetectedRoutes[0].path}
          element={<MobileDetectedView />}
        />
        {/* authentication */}
        <Route
          path={ROUTE_PATH_NAME_BY_ID.UNAUTHENTICATED}
          element={
            <Layout>
              <h2>Unauthenticated</h2>
            </Layout>
          }
        />
        <Route
          path={ROUTE_PATH_NAME_BY_ID.UNAUTHORIZED}
          element={
            <Layout>
              <Unauthorized />
            </Layout>
          }
        />
        <Route path="/login/callback" element={<LoginCallback />} />
        {process.env.REACT_APP_SHOW_DEVELOPMENT && (
          <Route
            path={DEVELOPMENT_ROUTE_PATH.COMPONENT_DEMO}
            element={<DevelopmentLiveDemo />}
          />
        )}
        {/* Main dashboard */}
        <Route
          index
          element={
            <Navigate to={ROUTE_PATH_NAME_BY_ID[G_PMS.MAIN_DASHBOARD]} />
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <DashboardLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<DashboardContent />} />
          <Route
            path="/dashboard/tasks/:featureName"
            element={
              <FetchAllUser showLayout={false}>
                <DashboardTask />
              </FetchAllUser>
            }
          />
          <Route
            path={
              DASHBOARD_CONFIG_TABLE[DASHBOARD_TABLE_ROW_NAME.G_TICKET].route
            }
            element={<GTicketTasks />}
          />
          <Route
            path="/dashboard/tasks-statistics/:featureName"
            element={<TaskStatistics />}
          />
        </Route>

        {/* Timesheet management */}
        <Route
          path={ROUTE_PATH_NAME_BY_ID[G_PMS.TIMESHEET_MANAGEMENT]}
          element={
            <PrivateRoute>
              <ManagementTimeSheet />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTE_PATH_NAME_BY_ID[OKR.MY_TARGET]}
          element={
            <PrivateRoute>
              <MyTargetProvider>
                <RouterMyTarget />
              </MyTargetProvider>
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTE_PATH_NAME_BY_ID[OKR.MY_TASK]}
          element={
            <PrivateRoute>
              <MyTasks />
            </PrivateRoute>
          }
        />
        {/* Người dùng chính đánh giá nhiệm vụ */}
        <Route
          path={ROUTE_PATH_NAME_BY_ID[OKR.KEY_STAKEHOLDER_RATING]}
          element={
            <PrivateRoute>
              <MainUserEvaluation />
            </PrivateRoute>
          }
        />
        {/* Gticket */}
        {RouteGticket.map((val: TypeRoute, idx: number) => {
          return (
            <Route key={idx} path={val.path} element={<val.component />}>
              {val?.index ? <Route index element={<val.index />} /> : ""}
              {val?.submenu
                ? val.submenu.map((vaz: any, index: number) => (
                    <Route
                      key={index}
                      path={val.path}
                      element={<vaz.component />}
                    />
                  ))
                : ""}
              <Route path="*" element={<NoMatch />} />
            </Route>
          );
        })}
        {/* endGictket */}

        {/* Đánh giá nhiệm vụ */}
        <Route
          path={ROUTE_PATH_NAME_BY_ID[G_PMS.TASK_EVALUATION]}
          element={
            <PrivateRoute>
              <TaskEvaluation />
            </PrivateRoute>
          }
        />

        {/* Gprocess - MODULE */}
        {/* Dashboard Gprocess */}
        {/* <Route
          path={ROUTE_PATH_NAME_BY_ID[G_PROCESS.DASHBOARD]}
          element={
            <PrivateRoute>
              <GprocessDashboard />
            </PrivateRoute>
          }
        >
          <Route index element={<GprocessDashboardContent />} />
          <Route path="*" element={<NoMatch />} />
        </Route> */}
        {routeProcess.map((val: TypeRoute, idx: number) => {
          return (
            <Route
              key={idx}
              path={val.path}
              element={
                <PrivateRoute>
                  <FetchAllUser>
                    <val.component />
                  </FetchAllUser>
                </PrivateRoute>
              }
            >
              {val?.index ? <Route index element={<val.index />} /> : ""}
              {val?.submenu
                ? val.submenu.map((vaz: any, index: number) => (
                    <Route
                      key={index}
                      path={val.path}
                      element={
                        <PrivateRoute>
                          <FetchAllUser>
                            <vaz.component />
                          </FetchAllUser>
                        </PrivateRoute>
                      }
                    />
                  ))
                : ""}
            </Route>
          );
        })}
        {/* END Gprocess - MODULE */}

        {/* G-Performance */}
        {RouteGPerformance}

        {/* Quản trị hệ thống */}
        <Route
          path="system-management/user"
          element={
            <PrivateRoute>
              <SystemUser />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="system-management/category-list"
          element={<SystemCategorylist />}
        ></Route>
        <Route
          path="system-management/department-list"
          element={<SystemDepartmentlist />}
        ></Route>
        <Route
          path="system-management/assignee"
          element={<AsigneeList />}
        ></Route>
           <Route
          path="system-management/user-detail/:id/:mode?"
          element={
            <Layout>
              <UserDetailPage />
            </Layout>
          }
        ></Route>
        <Route path="system-management/qticket" element={<Qticket />}></Route>
        <Route
          path={ROUTE_PATH.Q_TICKET_SETTINGS}
          element={<Qticket />}
        ></Route>
        <Route
          path={ROUTE_PATH_NAME_BY_ID[G_PMS.QR_ORDER_MANAGEMENT]}
          element={<QROrderManagement />}
        ></Route>
        <Route
          path={ROUTE_PATH_NAME_BY_ID[G_PMS.ORGANIZATIONAL_STRUCTURE]}
          element={
            <PrivateRoute>
              <OrganizationStructure />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTE_PATH_NAME_BY_ID[G_PMS.ORGANIZATIONAL_STRUCTURE_DETAIL]}
          element={<OrganizationStructureDetail />}
        />
        <Route
          path={ROUTE_PATH_NAME_BY_ID.ACCOUNT_INACTIVE}
          element={
            <Layout>
              <AccountInactive />
            </Layout>
          }
        />

        <Route
          path="*"
          element={
            <PrivateRoute>
              <NoMatch />
            </PrivateRoute>
          }
        />
      </Routes>
    </Suspense>
  );
}

export default CustomRoutes;
