import { ReactNode } from "react";
import style from "./style.module.scss";
import { mergeStylesCSS } from "../../../../helpers/helper";

type TypeProps = {
  children: ReactNode;
  className?: string;
  styles?: object;
};

const BottomModal = ({ children, className, styles }: TypeProps) => {
  return (
    <div
      className={mergeStylesCSS([
        style["container-bottom-modal"],
        className && className,
      ])}
      style={{ ...styles }}
    >
      {children}
    </div>
  );
};
export default BottomModal;
