import { RequestApiPrivate, WsCode } from '../../api';
import qs from 'query-string';

const KpiGoalApi = {
  list: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(WsCode.goal.list, {
        params
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  add: async (payload: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.post(WsCode.goal.list, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  detail: async (id: number): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(WsCode.goal.list + `/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  update: async (id: number, payload: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.put(
        WsCode.goal.list + `/${id}`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  delete: async (id: number): Promise<any> => {
    try {
      const response = await RequestApiPrivate.delete(
        WsCode.goal.list + `/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  parameters: async (): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(WsCode.goal.parameters);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  goalGroup: async (): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(WsCode.goal.goalGroup);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  departments: async (): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(WsCode.goal.departments);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  addDepartments: async (payload: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.post(
        WsCode.goal.addDepartments,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  validateFormula: async (payload: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.post(
        WsCode.goal.validate,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  downloadGoal: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(WsCode.goal.export, {
        params,
        responseType: 'blob'
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  viewDraft: async (payload: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.post(
        WsCode.goal.viewDraft,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  settingGoal: async (payload: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.post(
        WsCode.goal.settingGoal,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  updateSettingGoal: async (payload: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.put(
        WsCode.goal.settingGoal + `/${payload.goal_id}`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getParamByIds: async (params: any): Promise<any> => {
    try {
      const response = await RequestApiPrivate.get(WsCode.goal.paramsByIds, {
        params: params,
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'index' });
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};

export default KpiGoalApi;
