import { convertResourceType } from "../utils/helpers";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../store/store";
import {
  initialState,
  KpiDataManagementSlice,
  MODAL_PROGRESS_TYPE,
} from "../utils";
import {
  uploadData,
  getList,
  getListMore,
  downloadFileData,
  getConfigsResource,
  postResource,
  getListParam,
  getListParamMore,
} from "./thunk";

export const kpiDataManagementSlice = createSlice({
  name: "kpiDataManagement",
  initialState,
  reducers: {
    reset: () => initialState,

    toggleShowModal: (state: KpiDataManagementSlice, action) => {
      const field = action.payload as "upload" | "progress" | "add";
      state.showModal[field] = !state.showModal[field];
    },

    updateFilterParams: (state: KpiDataManagementSlice, action) => {
      state.filterParams = { ...action.payload };
    },

    clearSlice: (state: KpiDataManagementSlice) => {
      state.action = initialState.action;
    },

    updateAction: (state: KpiDataManagementSlice, action) => {
      state.action = action.payload;
    },

    clearFormFilter: (state: KpiDataManagementSlice, action) => {
      state.filterParams = initialState.filterParams;
      state.resetFormFilter = action.payload;
    },

    updateFormDataUpload: (state: KpiDataManagementSlice, action) => {
      state.formDataUpload = action.payload;
    },

    updateKeyWordSearchParam: (state: KpiDataManagementSlice, action) => {
      state.keyWordSearchParam = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postResource.pending, (state) => {
        state.status = "loading";
        state.loading.addResource = true;
      })

      .addCase(postResource.fulfilled, (state) => {
        state.status = "idle";
        state.loading.addResource = false;
      })

      .addCase(postResource.rejected, (state) => {
        state.status = "failed";
      })

      .addCase(getList.pending, (state) => {
        state.status = "loading";
        state.loading.list = true;
        state.list = initialState.list;
      })

      .addCase(getList.fulfilled, (state, action) => {
        state.status = "idle";
        if (!action.payload.data) return;

        const _list = [...action.payload.data.items];
        const _extendList = _list.map((_el, _idx) => {
          const resourceType = convertResourceType(_el.resource.type);
          return {
            ..._el,
            uploaderShow: _el.uploader.user_name,
            resource_type: resourceType,
          };
        });
        state.list = _extendList;
        state.loading.list = false;
        const current_page = action.payload.data.current_page;
        const total = action.payload.data.total;
        const per_page = action.payload.data.per_page;
        const last_page = Math.trunc(total / per_page) + 1;
        state.paginate = { total, current_page, last_page };
      })

      .addCase(getList.rejected, (state) => {
        state.status = "failed";
      })

      .addCase(getListParamMore.pending, (state) => {
        state.status = "loading";
        state.loading.listParam = true;
      })
      .addCase(getListParamMore.fulfilled, (state, action) => {
        state.status = "idle";
        state.listParam = [...state.listParam, ...action.payload.data];
        state.paginateParam = { ...action.payload.paginate };
        state.loading.listParam = false;
      })

      .addCase(getListParamMore.rejected, (state, action) => {
        state.status = "failed";
        state.loading.listParam = false;
      })

      .addCase(getConfigsResource.fulfilled, (state, action) => {
        state.status = "idle";
        state.configsResource = action.payload.data;
      })

      .addCase(uploadData.pending, (state, action) => {
        state.status = "loading";
        state.loading.upload = true;
        state.uploadStatus = MODAL_PROGRESS_TYPE.Loading;
      })

      .addCase(uploadData.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.upload = false;
        state.uploadStatus = MODAL_PROGRESS_TYPE.Success;
        state.responseUpload = action.payload.data;
      })

      .addCase(uploadData.rejected, (state, action) => {
        state.status = "failed";
        state.loading.upload = false;
        state.uploadStatus = MODAL_PROGRESS_TYPE.Failed;
        state.responseUpload = null;
      })

      .addCase(downloadFileData.pending, (state, action) => {
        state.status = "loading";
      })

      .addCase(downloadFileData.fulfilled, (state, action) => {
        state.status = "idle";

        const downloadFile = (response: any): void => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const currentItem =
            state.list.find((_el) => _el.id === state.action.id) ||
            state.responseUpload;
          const fileName = currentItem.original_name;
          const link = document.createElement("a");
          link.target = "_blank";
          link.download = fileName;
          link.href = url;
          document.body.appendChild(link);
          link.click();
          link.remove();
        };
        downloadFile(action.payload);
      })

      .addCase(downloadFileData.rejected, (state, action) => {
        state.status = "failed";
      })

      .addCase(getListMore.pending, (state) => {
        state.status = "loading";
        state.loading.loadMore = true;
      })

      .addCase(getListMore.fulfilled, (state, action) => {
        state.status = "idle";
        if (!action.payload.data) return;

        const _currentList = [...state.list];
        const _getMoreList = [...action.payload.data.items];
        const _extendGetMoreList = _getMoreList.map((_el, _idx) => {
          const resourceType = convertResourceType(_el.resource.status);
          return {
            ..._el,
            uploaderShow: _el.uploader.email,
            resource_type: resourceType,
          };
        });
        state.list = [..._currentList, ..._extendGetMoreList];
        const current_page = action.payload.data.current_page;
        state.paginate = { ...state.paginate, current_page };
        state.loading.loadMore = false;
      })

      .addCase(getListMore.rejected, (state) => {
        state.status = "failed";
        state.loading.loadMore = false;
      })

      .addCase(getListParam.pending, (state) => {
        state.status = "loading";
        state.loading.listParam = true;
        state.listParam = initialState.listParam;
      })
      .addCase(getListParam.fulfilled, (state, action) => {
        state.status = "idle";
        state.listParam = [...action.payload.data];
        state.paginateParam = { ...action.payload.paginate };
        state.loading.listParam = false;
      })
      .addCase(getListParam.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {
  updateFilterParams,
  clearSlice,
  updateAction,
  clearFormFilter,
  reset,
  updateFormDataUpload,
  toggleShowModal,
  updateKeyWordSearchParam,
} = kpiDataManagementSlice.actions;

export const list = (state: RootState) => state.kpiDataManagement.list;
export const showModal = (state: RootState) =>
  state.kpiDataManagement.showModal;
export const filterParams = (state: RootState) =>
  state.kpiDataManagement.filterParams;
export const paginate = (state: RootState) => state.kpiDataManagement.paginate;
export const action = (state: RootState) => state.kpiDataManagement.action;
export const resetFormFilter = (state: RootState) =>
  state.kpiDataManagement.resetFormFilter;
export const loading = (state: RootState) => state.kpiDataManagement.loading;
export const uploadStatus = (state: RootState) =>
  state.kpiDataManagement.uploadStatus;
export const formDataUpload = (state: RootState) =>
  state.kpiDataManagement.formDataUpload;
export const responseUpload = (state: RootState) =>
  state.kpiDataManagement.responseUpload;
export const configsResource = (state: RootState) =>
  state.kpiDataManagement.configsResource;
export const listParam = (state: RootState) =>
  state.kpiDataManagement.listParam;
export const paginateParam = (state: RootState) =>
  state.kpiDataManagement.paginateParam;
export const keyWordSearchParam = (state: RootState) =>
  state.kpiDataManagement.keyWordSearchParam;

export default kpiDataManagementSlice.reducer;
