import { CSSProperties, ReactNode, useEffect, useRef } from "react";
import { EXPORT_STRING } from "../../../../helpers/function";
type TypeProps = {
  children: ReactNode;
  id?: any;
  className?: any;
  layout?: number;
  padding?: number;
  gap?: number;
  top?: number;
  width?: string;
  bottom?: number;
  styles?: CSSProperties;
  display?: "flex" | "grid";
};
const Form = ({
  children,
  layout,
  id,
  className,
  padding,
  gap,
  top,
  width,
  bottom,
  styles,
  display = "flex",
}: TypeProps) => {
  const ref = useRef<any>();
  useEffect(() => {
    if (ref.current) {
      if (layout) {
        ref.current.style.gridTemplateColumns = EXPORT_STRING(layout, "auto");
      } else {
        ref.current.style.gridTemplateColumns = "auto";
      }
      //Padding và bottom
      if (padding) {
        ref.current.style.padding = `${padding}px ${padding}px ${
          bottom ? bottom : padding
        }px ${padding}px`;
      }
      //Khoảng cách giữa các phần tử trong Layout
      if (gap) {
        ref.current.style.gap = `${gap}px`;
      }
      //Khoảng cách của layout với phần tử trên nó
      if (top) {
        ref.current.style.marginTop = `${top}px`;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);
  return (
    <div
      style={
        styles
          ? {
              ...styles,
              display: display,
              width: width,
            }
          : {
              display: display,
              width: width,
            }
      }
      id={id}
      className={className}
      ref={ref}
    >
      {children}
    </div>
  );
};

export default Form;
