import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '../../../../store/store';
import {
  downloadFile,
  initialState,
  mapEmployeeData,
  mapTreeDataTable,
  ROLE
} from '../utils';
import {
  findAllValueNestedByField,
  findKeyByLevel,
  getChildIds,
  getParentIds
} from './../utils/helpers';
import {
  exportExcel,
  getDetailUser,
  getDetailWorkspace,
  getList,
  getListEmployee,
  getListFilterGoal,
  getListGoal,
  getListPerformanceUser,
  getListPerformanceWorkspace,
  getListWorkspace,
  sendApprove
} from './thunk';

export const computingManagementSlice = createSlice({
  name: 'gperformanceListStatus',
  initialState,
  reducers: {
    reset: (state) => ({
      ...initialState,
      defaultSelectedKeysFilter: state.defaultSelectedKeysFilter,
      listSubject: state.listSubject,
      detailUser: state.detailUser
    }),

    toggleNoteModal: (state) => {
      state.showNoteModal = !state.showNoteModal;
    },
    updateFilterParams: (state, action) => {
      state.filterParams = { ...action.payload };
    },
    clearSlice: (state) => {
      state.action = initialState.action;
    },
    updateAction: (state, action) => {
      state.action = action.payload;
    },
    clearFormFilter: (state, action) => {
      state.filterParams = initialState.filterParams;
      state.resetFormFilter = action.payload;
    },
    setSelectedRow: (state, action) => {
      state.selectedRow = action.payload;
    },
    setExpandedRowKeys: (state, action) => {
      state.expandedRowKeys = action.payload;
    },
    setListWorkspace: (state, action) => {
      state.listWorkspace = action.payload;
    },
    setDefaultSelectedKeysFilter: (state, action) => {
      state.defaultSelectedKeysFilter = action.payload;
    },
    setParentIds: (state, action) => {
      state.parentIds = action.payload;
    },
    setIdsFetchWorkspace: (state, action) => {
      state.idsFetchWorkspace = action.payload;
    },
    setWorkspaceSelectedIds: (state, action) => {
      state.workspaceSelectedIds = action.payload;
    },
    setUserSelectedIds: (state, action) => {
      state.userSelectedIds = action.payload;
    },
    setIdsAllWorkspaceOfUser: (state, action) => {
      state.idsAllWorkspaceOfUser = action.payload;
    },
    setIdsAllWorkspaceFilter: (state, action) => {
      state.idsAllWorkspaceFilter = action.payload;
    },
    setIdsSelectedWorkspaceFilter: (state, action) => {
      state.idsSelectedWorkspaceFilter = action.payload;
    },
    setListTable: (state, action) => {
      state.list = action.payload;
    },
    setIdsWorkspaceCanBeCheckFilter: (state, action) => {
      state.idsWorkspaceCanBeCheckFilter = action.payload;
    },
    setChildrenIds: (state, action) => {
      state.childrenIds = action.payload;
    },
    setIdsWorkspaceCanBeApprove: (state, action) => {
      state.idsWorkspaceCanBeApprove = action.payload;
    },
    setIdsWorkspaceUserOwner: (state, action) => {
      state.idsWorkspaceUserOwner = action.payload;
    },
    setListEmployee: (state, action) => {
      state.listEmployee = action.payload;
    },
    setHeightFilter: (state, action) => {
      state.heightFilter = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getList.pending, (state) => {
        state.loading.list = true;
        state.selectedRow = [];
        state.listPerformanceUser = {};
        state.listPerformanceWorkspace = {};
      })
      .addCase(getList.fulfilled, (state, action) => {
        state.loading.list = false;
        const _listTree = action.payload?.data?.length
          ? [action.payload.data[0]]
          : [];
        const { month, year, workspace_ids, user_ids } = state.filterParams;
        const workspaceIds = workspace_ids ? workspace_ids.split(',') : [];
        const userIds = user_ids ? user_ids.split(',') : [];
        const idsSearch = { workspaceIds, userIds };

        const getAllLevelFilter = (
          data: any,
          idsSearch: any[],
          levels: any[] = []
        ): any[] => {
          if (!data?.children) return levels;
          data?.children.forEach((_child: any) => {
            if (idsSearch.includes(String(_child.id))) {
              levels.push(_child.level);
            }
            getAllLevelFilter(_child, idsSearch, levels);
          });
          return levels;
        };

        const allLevelSelectedFilter = getAllLevelFilter(
          _.cloneDeep(state.listSubject?.[0]),
          workspaceIds
        );
        const minLevelSelectedFilter = _.min(allLevelSelectedFilter);
        let idsSelectedWorkspaceIncludeParent: number[] = [];
        state.idsSelectedWorkspaceFilter.forEach((_id) => {
          idsSelectedWorkspaceIncludeParent.push(
            ...getParentIds(_id, state.listSubject)
          );
        });
        const idsIdsWorkspaceSelectedAndAllParentOfIt = _.uniq([
          ...idsSelectedWorkspaceIncludeParent,
          ...state.idsSelectedWorkspaceFilter
        ]);

        state.list = mapTreeDataTable(
          _.cloneDeep(_listTree),
          _.cloneDeep(state.listPerformance),
          { month, year },
          idsSearch,
          minLevelSelectedFilter - 1,
          state.detailUser?.roles.level,
          state.detailUser?.workspace?.id,
          idsIdsWorkspaceSelectedAndAllParentOfIt,
          state.detailUser
        );

        let _keysWorkspaceCanBeApprove: number[] = [];

        if (state.idsWorkspaceUserOwner.length) {
          state.idsWorkspaceUserOwner.forEach((_id) => {
            const idsExcludeIt = getChildIds(_id, state.list)?.filter(
              (_item) => !state.idsWorkspaceUserOwner.includes(_item)
            );
            _keysWorkspaceCanBeApprove.push(...idsExcludeIt);
          });
        }

        state.keysWorkspaceCanBeApprove = _.uniq(_keysWorkspaceCanBeApprove);

        state.listOriginTable = _.cloneDeep(state.list);
        if (state.detailUser?.roles.id === ROLE.CEO) {
          state.defaultExpanded = findKeyByLevel(state.list?.[0], 3);
          // 3: defaultExpanded tree table level vs role CEO
        } else {
          const allKeysUniqueListTreeTable = findAllValueNestedByField(
            state.list[0],
            'key'
          );
          let parentIds: any[] = [];
          const IdsShouldExpandTreeTable = [
            ...parentIds,
            ...workspaceIds,
            ...userIds
          ];
          const keysShouldExpandTreeTable = allKeysUniqueListTreeTable.filter(
            (_key) =>
              IdsShouldExpandTreeTable.includes(
                String(_key).split('unique')?.[0]
              )
          );

          state.defaultExpanded = allKeysUniqueListTreeTable;
        }
      })
      .addCase(getListPerformanceUser.fulfilled, (state, action) => {
        if (action.meta.arg?.slice === 0) {
          state.listPerformanceUser = { ...action.payload.data };
        } else {
          state.listPerformanceUser = {
            ...state.listPerformanceUser,
            ...action.payload.data
          };
        }
      })
      .addCase(getListPerformanceWorkspace.fulfilled, (state, action) => {
        state.listPerformanceWorkspace = { ...action.payload.data };
        state.listPerformance = {
          ...state.listPerformanceUser,
          ...state.listPerformanceWorkspace
        };
        state.idsFetchWorkspace = {
          ids: Object.keys(state.listPerformanceWorkspace)?.join(','),
          user_ids: Object.keys(state.listPerformanceUser)?.join(',')
        };
      })
      .addCase(getListFilterGoal.pending, (state, action) => {
        state.loading.searchGoal = true;
      })
      .addCase(getListFilterGoal.fulfilled, (state, action) => {
        state.loading.searchGoal = false;
        state.listGoal = action.payload?.goals.map((_item: any) => ({
          label: _item.name,
          value: _item.id
        }));
      })
      .addCase(getListWorkspace.pending, (state, action) => {
        state.loading.list = true;
        state.loading.workspaceFilter = true;
      })
      .addCase(getListWorkspace.fulfilled, (state, action) => {
        state.listSubject = action.payload.data;
        state.listOriginWorkspace = action.payload.data;
        state.loading.workspaceFilter = false;
      })
      .addCase(getDetailWorkspace.fulfilled, (state, action) => {})
      .addCase(getDetailUser.pending, (state, action) => {
        state.loading.detailUser = true;
        state.detailUser = action.payload;
      })
      .addCase(getDetailUser.fulfilled, (state, action) => {
        state.detailUser = action.payload.data;
        state.loading.detailUser = false;
      })
      .addCase(getListEmployee.pending, (state, action) => {
        state.loading.employee = true;
        if (action.meta?.arg?.initFetching) {
          state.loading.list = true;
        } else {
          state.initFetchingEmployeeDone = false;
        }
      })
      .addCase(getListEmployee.fulfilled, (state, action) => {
        state.loading.employee = false;
        const findAllOwner = (parent: any, keys: any[] = []) => {
          if (!parent?.children) return [];
          parent.children.forEach((_child: any) => {
            if (_child.owner) {
              keys.push({ ..._child.owner, workspaceId: _child.id });
            }
            findAllOwner(_child, keys);
          });
          return keys;
        };
        const allOwnerWorkspaceFilter = findAllOwner(
          _.cloneDeep(state.listOriginWorkspace?.[0])
        );

        const ownerWorkspaceFilter = allOwnerWorkspaceFilter.filter(
          (_owner) => {
            return state.idsSelectedWorkspaceFilter.includes(
              _owner?.workspaceId
            );
          }
        );

        const allUserOfWorkspace = [
          ...ownerWorkspaceFilter,
          ...action.payload.data
        ];
        const uniqueArray = _.uniqBy(allUserOfWorkspace, 'id');
        const sortedArray = _.sortBy(uniqueArray, [
          function (o) {
            return o?.full_name?.toLowerCase();
          }
        ]);
        state.listEmployee = mapEmployeeData(sortedArray);
        state.filterParams = {
          ...state.filterParams,
          user_ids: state.listEmployee.map((em) => em.value).join(',')
        };
        if (action.meta?.arg?.initFetching) {
          state.initFetching = true;
          state.initFetchingEmployeeDone = true;
        }
      })
      .addCase(sendApprove.pending, (state, action) => {
        state.loading.approve = true;
      })
      .addCase(sendApprove.fulfilled, (state, action) => {
        state.loading.approve = false;
      })
      .addCase(sendApprove.rejected, (state, action) => {
        state.loading.approve = false;
      })
      .addCase(getListGoal.fulfilled, (state, action) => {
        state.listGoal = action.payload.data.map((_item: any) => ({
          label: _item.name,
          value: _item.id
        }));
      })
      .addCase(exportExcel.fulfilled, (state, action) => {
        downloadFile(action.payload);
      });
  }
});

export const {
  updateFilterParams,
  clearSlice,
  updateAction,
  clearFormFilter,
  reset,
  toggleNoteModal,
  setSelectedRow,
  setExpandedRowKeys,
  setListWorkspace,
  setDefaultSelectedKeysFilter,
  setParentIds,
  setIdsFetchWorkspace,
  setUserSelectedIds,
  setWorkspaceSelectedIds,
  setIdsAllWorkspaceOfUser,
  setIdsAllWorkspaceFilter,
  setIdsSelectedWorkspaceFilter,
  setListTable,
  setIdsWorkspaceCanBeCheckFilter,
  setChildrenIds,
  setIdsWorkspaceCanBeApprove,
  setIdsWorkspaceUserOwner,
  setListEmployee,
  setHeightFilter,
} = computingManagementSlice.actions;

export const gPerformanceListStatus = (state: RootState) =>
  state.gPerformanceListStatus;

export default computingManagementSlice.reducer;
