import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store/store";

interface IInitialState {
  filterDate: {
    month: string | number;
    year: string | number;
  };
}

const initialState: IInitialState = {
  filterDate: {
    month: "",
    year: "",
  },
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setFilterDate(state, action) {
      if (action.payload.year) {
        state.filterDate.year = action.payload.year;
      }
      if (action.payload.month) {
        state.filterDate.month = action.payload.month;
      }
    },
  },
  extraReducers() {},
});

export default dashboardSlice.reducer;
export const { setFilterDate } = dashboardSlice.actions;
export const dashboardSelector = (state: RootState) => state.dashboard;
