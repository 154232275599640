import { createAsyncThunk } from '@reduxjs/toolkit';
import KpiParamManagementApi from './api';
import { clearFormFilter, clearSlice } from './slice';
import { FilterParam } from '../utils/interface';
import { DEFAULT_PARAMS, openNotification, TOAST_TYPE } from '../utils';

export const getList = createAsyncThunk(
  'kpiParam/getList',
  async (params: FilterParam) => {
    try {
      const response = await KpiParamManagementApi.getList(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListMore = createAsyncThunk(
  'kpiParam/getListMore',
  async (params: FilterParam) => {
    try {
      const response = await KpiParamManagementApi.getList(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getFields = createAsyncThunk(
  'kpiParam/getFields',
  async (code: string) => {
    try {
      const response = await KpiParamManagementApi.getFields(code);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getEndpointSource = createAsyncThunk(
  'kpiParam/getEndpointSource',
  async () => {
    try {
      const response = await KpiParamManagementApi.getEndpointSource();
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const postParam = createAsyncThunk(
  'kpiParam/postParam',
  async (payload: { body: any; message: string }, { dispatch }) => {
    try {
      const { body, message } = payload;
      const response = await KpiParamManagementApi.postParam(body);
      dispatch(clearFormFilter(true));
      dispatch(getList({ ...DEFAULT_PARAMS }));
      openNotification({ type: TOAST_TYPE.Success, message });
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const genResourceCode = createAsyncThunk(
  'kpiParam/genResourceCode',
  async (body: { name: string }) => {
    try {
      const response = await KpiParamManagementApi.genResourceCode(body);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getParam = createAsyncThunk(
  'kpiParam/getParam',
  async (id: number) => {
    try {
      const response = await KpiParamManagementApi.getParam(id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateParam = createAsyncThunk(
  'kpiParam/updateParam',
  async (payload: { id: number; body: any; message: string }, { dispatch }) => {
    const { id, body, message } = payload;
    try {
      const response = await KpiParamManagementApi.updateParam(id, body);
      dispatch(clearFormFilter(true));
      dispatch(getList({ ...DEFAULT_PARAMS }));
      openNotification({ type: TOAST_TYPE.Success, message });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteParam = createAsyncThunk(
  'kpiParam/deleteParam',
  async (params: { id: number; message: string }, { dispatch }) => {
    const { id, message } = params;
    try {
      const response = await KpiParamManagementApi.deleteParam(id);
      dispatch(clearSlice());
      dispatch(clearFormFilter(true));
      dispatch(getList({ ...DEFAULT_PARAMS }));
      openNotification({ type: TOAST_TYPE.Success, message });
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const downloadParams = createAsyncThunk(
  'kpiParam/downloadParams',
  async (params: any) => {
    try {
      const response = await KpiParamManagementApi.downloadParams(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);
