import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface userV2Props {
  users: any[];
  allUsers: any[];
  loginUser: any;
  assigneeCachedData: any;
  loginUserQr: any;
}

const initialState: userV2Props = {
  users: [], // activeUSer
  allUsers: [],
  loginUser: null,
  assigneeCachedData: {},
  loginUserQr: null,
};

const userV2Slice = createSlice({
  name: "userV2Slice",
  initialState,
  reducers: {
    updateUsers(state, action: PayloadAction<any>) {
      state.users = action?.payload?.data?.filter((x: any) => x?.status === 1);
      state.allUsers = action?.payload?.data;
    },
    updateLoginUser(state, action: PayloadAction<any>) {
      state.loginUser = action?.payload?.data;
    },
    updateQr(state, action: PayloadAction<any>) {
      state.loginUserQr = action?.payload?.data;
    },

    storeNewAsigneeCachedData(state, action: PayloadAction<any>) {
      const key = action?.payload?.data?.key;
      const newData = action?.payload?.data?.newData;
      state.assigneeCachedData = {
        ...state.assigneeCachedData,
        [key]: newData,
      };
    },
  },
});

export const getLoginUser = (state: any) => state?.userV2Slice?.loginUser;
export const getUsersListV2 = (state: any) => state?.userV2Slice?.users; //get user active
export const getAllUsersListV2 = (state: any) => state?.userV2Slice?.allUsers; // + user inactive
export const getAuthUserQrCode = (state: any) =>
  state?.userV2Slice?.loginUserQr; // login user Qr code

export const {
  updateUsers,
  updateLoginUser,
  storeNewAsigneeCachedData,
  updateQr,
} = userV2Slice.actions;
export default userV2Slice.reducer;
