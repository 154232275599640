const GPROCESS_SERVICE_URL = process.env.REACT_APP_API_G_PROCESS + "/api/v1/";
const WsCode = {
  process: "process",
  addProcess: "process",
  departments: "departments",
  file: "file-attachment",
  processChangeLog: "process-change-log",
  users: "users",
  processapprove: "approval-process",
  processStep: "process-step",
  stepObject: "step-object",
  steptranfer: "step-transfer-condition",
  postSteptranfer: "step-transfer-condition/create-multiple",
  postStepcheck: "step-check-list/create-multiple",
  stepcheck: "step-check-list",
  stepchild: "process-step-child",
  approveSteps: "process",
  generateToken: "generate-token",
  checkSort: "check-process-short-name",
  ticketHistory: "ticket-history",
  ticketRequest: "ticket-request",
  tasks: "tasks",
  position: "roles",
  groups: "groups",
  groupTranfer: "step-group-condition",
  fileDowload: "file-attachment-download",
  timeSheet: "tasks",
  taskTimeSheet: "task-timesheet",
  getCheckList: "step-check-list",
  results: "tasks/results",
  processAll: "process/all",
  job_title: "job-titles",
  create_multiple_steps: "process-step/create-multiple",
  delete_multiple_steps: "process-step-delete-multiple",
  usersOfDepartment: "users-of-departments",
  job_title_of_department: "job-titles-of-departments",
  weekendDays: "weekend-days",
  childTicketRequest: "child-ticket-requests",
  parentProcessStep: "parent-process-steps",
};

export { GPROCESS_SERVICE_URL, WsCode };
