import i18n from "../../../../i18n";
import { STATUS } from "./constant";
import { CheckEnum, GoalTypeEnum, SubjectEnum } from "./enum";
import {
  ConfigFilter,
  FilterParam,
  GoalPerformanceColumn,
  OptionSelect,
  TreeData,
  UserInfo,
  WorkspaceDetail,
} from "./interface";

import uniq from "lodash/uniq";
import map from "lodash/map"

export const convertStatus = (_status: number): boolean => {
  if (_status === STATUS.Active) return true;
  return false;
};

export const removeCondition = (el: any) => {
  const _el = { ...el };
  if (_el.conditions) {
    delete _el.conditions;
  }
  return _el;
};

export const downloadFileParam = (params: FilterParam) => {
  const _params = { ...params };
  if (_params.per_page) {
    delete _params.per_page;
  }
  if (_params.page) {
    delete _params.page;
  }
  return _params;
};

export const downloadFile = (response: any): void => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const fileName = getFileNameInResponseHeader(response.headers);
  const link = document.createElement("a");
  link.target = "_blank";
  link.download = fileName;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

const getFileNameInResponseHeader = (headers: any): any => {
  let fileName = "";
  if (!headers) return "";
  if ("content-disposition" in headers) {
    const data = headers["content-disposition"]
      .split(";")
      .map((item: string) => item.trim());

    for (let item of data) {
      const disposition = item.split("=");
      if (disposition.length > 0) {
        if (disposition[0] === "filename") return disposition[1];
      }
    }
  }
  return fileName;
};

export const formatText = (_val: string): string => {
  if (_val.length > 20) {
    return _val.substring(0, 20) + "...";
  }
  return _val;
};

export const mapTreeData = (data: WorkspaceDetail[]): TreeData[] => {
  if (!data.length) return [];
  const renderIcon = () => (
    <div className="ant-checkbox-wrapper" style={{ marginRight: 10 }}>
      <span className="ant-checkbox ant-checkbox-checked">
        <span className="ant-checkbox-inner"></span>
      </span>
      <span className="ant-checkbox">
        <span className="ant-checkbox-inner"></span>
      </span>
    </div>
  );
  return data.map((_item, _idx) => {
    if (!_item?.children)
      return {
        title: _item.name,
        value: _item.id,
        key: _item.id,
        icon: renderIcon(),
      };
    return {
      title: _item.name,
      value: _item.id,
      children: mapTreeData(_item.children),
      icon: renderIcon(),
      key: _item.id,
    };
  });
};

export const getIds = (
  data: WorkspaceDetail[],
  ids: number[] = []
): number[] => {
  if (!data.length) return uniq(ids);
  let results = [...ids];
  data.forEach(({ id, children = [] }) => {
    const otherIds = getIds(children, results);
    results = [...results, id, ...otherIds];
  });
  return uniq(results);
};

export const mapEmployeeData = (data: UserInfo[]): OptionSelect[] => {
  return data.map((_user) => ({
    label: _user?.full_name || "-",
    subLabel: _user?.name || _user?.full_name || "-",
    value: _user?.id,
  }));
};

export const mapDataTable = (data: any): GoalPerformanceColumn[] => {
  return data.map((_item: any, _idx: number) => ({
    name:
      _item.type_unit === SubjectEnum.Individual
        ? _item.user?.name || _item.user?.user_name
        : _item.workspace?.name,
    full_name:
      _item.type_unit === SubjectEnum.Individual
        ? `${_item.user?.user_name}${
            _item.user?.name ? ` (${_item.user?.name})` : ""
          }`
        : _item.workspace?.name,
    id: _item.id,
    status: _item.status,
    type: _item.type,
    density: _item.proportion,
    goal_code: _item.goal?.code,
    index: _idx + 1,
    code:
      _item.type_unit === SubjectEnum.Individual
        ? _item.user?.employee_code
        : _item.workspace?.code,
    goal: _item.goal_code,
    goal_name: _item.goal?.name,
    goal_type: _item.type,
    month:
      String(_item.month)?.length === 1
        ? `0${_item.month}`
        : String(_item.month),
    year: _item.year,
  }));
};
const formatBigDecimalNumber = (number: number) => {
  if(String(number).includes('e-')) {
    const decimal = String(number).slice(String(number).length - 1)
    return number.toFixed(Number(decimal))
  }
  return number
}

export const mapDataDetail = (data: any) => {
  if (!data) return null;
  return {
    subjectCode: data.user?.employee_code || data.workspace?.code,
    subject: data.user?.full_name || data.workspace?.name,
    name: data.user?.name || "",
    goalCode: data.goal?.code,
    goal: data.goal?.name,
    goalType:
      data.type === GoalTypeEnum.Month
        ? i18n.t("gperformance:computingManagement.filter.month")
        : i18n.t("gperformance:computingManagement.filter.year"),
    timeMonth:
      String(data.month)?.length === 1 ? `0${data.month}` : String(data.month),
    timeYear: data.year,
    density: formatBigDecimalNumber(data.proportion),
  };
};

export const mapDataFilter = (values: ConfigFilter): FilterParam => {
  let assigneeIds: Array<string | number> | undefined;

  if (values.subjectType === SubjectEnum.Individual) {
    assigneeIds =
      values.employeeId?.includes(CheckEnum.All) ||
      values.employeeId?.[0]?.value === CheckEnum.All
        ? undefined
        : values.employeeId;
  }

  if (values.subjectType === SubjectEnum.Group) {
    assigneeIds = values.subjectId?.includes(CheckEnum.All)
      ? undefined
      : values.subjectId;
  }

  const time = values.timeMonth?.includes(CheckEnum.All)
    ? [values.timeYear]
    : values.timeMonth?.map((_month) => _month + "/" + values.timeYear);

  const filterParams: FilterParam = {
    goal_id:
      values.goalId?.includes(CheckEnum.All) ||
      values.goalId?.[0]?.value === CheckEnum.All
        ? undefined
        : values.goalId,
    assignee_id: (assigneeIds || []).join(",") || undefined,
    type: values.goalType,
    month: time ? time : undefined,
    status: values.status.includes(CheckEnum.All) ? undefined : values.status,
    type_unit: values.subjectType,
    assignee_code: values.assigneeCode,
    target: "all",
  };

  return filterParams;
};
