import { createSlice } from "@reduxjs/toolkit";

interface modalSliceProps {
  isOpen: boolean;
  ListModals: any[];
}

const initialState: modalSliceProps = {
  isOpen: false,
  ListModals: [],
};

export const globalModalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    onOpenModal: (state) => {
      state.isOpen = true;
    },
    onCloseModal: (state) => {
      state.isOpen = false;
      
    },
    pushModal: (state, action) => {
      state.ListModals.push(action.payload);
    },
    popModal: (state) => {
      state.ListModals.pop();
      if (!state.ListModals.length) {
        state.isOpen = false;
      }
    },
    showFirstModal: (state) => {
      state.ListModals = state.ListModals.slice(0, 1);
    },
    resetListModal: (state) => {
      state.ListModals = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  onOpenModal,
  onCloseModal,
  pushModal,
  popModal,
  resetListModal,
  showFirstModal,
} = globalModalSlice.actions;

export default globalModalSlice.reducer;
