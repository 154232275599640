import React from "react";

const IconXPerson = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.625 10.625C6.507 10.625 4.62825 11.1035 3.39375 11.5197C2.4825 11.828 1.875 12.6867 1.875 13.649V16.625C1.875 16.625 9.3435 16.625 9.375 16.625"
        stroke="white"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.625 10.625C6.55425 10.625 4.875 8.19575 4.875 6.125V5.375C4.875 3.30425 6.55425 1.625 8.625 1.625C10.6957 1.625 12.375 3.30425 12.375 5.375V6.125C12.375 8.19575 10.6957 10.625 8.625 10.625Z"
        stroke="white"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.875 12.875L12.375 17.375"
        stroke="white"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.375 12.875L16.875 17.375"
        stroke="white"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default IconXPerson;
