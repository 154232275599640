import { createSlice } from "@reduxjs/toolkit";
import { sub } from "date-fns";
import { RootState } from "../../../store/store";
import { TAB_LAYOUT } from "../mission-management/helper/Contant";
import { ProcessState } from "../types/types";

const initialState: ProcessState = {
  open: true,
  myClaimForm: {
    layoutType: TAB_LAYOUT.BOX,
  },
};

const processManage = createSlice({
  name: "process",
  initialState: initialState,
  reducers: {
    setLayoutType(state, action) {
      state.myClaimForm.layoutType = action.payload;
    },
  },
});

export default processManage.reducer;

export const { setLayoutType } = processManage.actions;

export const slectProcessOpen = (state: RootState) => state.process.open;
export const myClaimFormSelector = (state: RootState) =>
  state.process.myClaimForm;
