import React, { CSSProperties, useEffect, useRef, useState } from "react";
import styles from "./formcheckbox.module.scss";
import { mergeStylesCSS } from "../../../../helpers/helper";

type TypeProps = {
  layout?: "grid" | "collum";
  data: {
    checked: Boolean;
    label: String | Number;
    value?: any;
    styleChecbox?: CSSProperties;
    styleLabel?: CSSProperties;
    styleItem?: CSSProperties;
    classNameItem?: any;
    classNameChecbox?: any;
    classNameLabel?: any;
    disable?: Boolean;
  }[];
  onChange?: Function;
  gap?: Number;
  padding?: Number;
  style?: any;
  className?: any;
  top?: Number;
  disable?: Boolean;
  styleLabel?: CSSProperties;
  classNameLabel?: string;
};

const FormCheckbox = ({
  layout,
  data,
  onChange,
  gap,
  padding,
  style,
  className,
  top,
  disable,
  styleLabel,
  classNameLabel,
}: TypeProps) => {
  const [classList, setClassList] = useState("");
  const [dataConfig, setDataConfig] = useState(data);

  let ref = useRef<any>();

  useEffect(() => {
    setDataConfig(data);
  }, [data]);

  useEffect(() => {
    let className: any = "";
    if (ref.current) {
      if (layout === "grid") {
        className = className + ` ${styles["displayGrid"]}`;
      } else {
        className = className + ` ${styles["displayFlex"]}`;
      }
      if (gap) {
        ref.current.style.gap = `${gap}px`;
      }
      if (padding) {
        ref.current.style.padding = `${padding}px`;
      }
      if (top) {
        ref.current.style.marginTop = `${top}px`;
      }
    }
    setClassList(className);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  const onClick = (value: any, idx: any, item: any) => {
    let arrayCheckbox = dataConfig;
    if (disable || (item && item.disable)) {
      return "";
    } else {
      arrayCheckbox[idx] = {
        ...arrayCheckbox[idx],
        checked: value,
      };
      setDataConfig([...arrayCheckbox]);
    }

    if (onChange) onChange(arrayCheckbox);
  };

  return (
    <div
      style={style}
      className={classList + " " + (className ? className : "")}
      ref={ref}
    >
      {dataConfig &&
        dataConfig.map((value: any, idx: any) => {
          if (value.label) {
            return (
              <div
                key={idx}
                onClick={() => onClick(!value.checked, idx, value)}
                className={
                  styles.item +
                  " " +
                  (value.classNameItem ? value.classNameItem : "")
                }
                style={value.styleItem}
              >
                <div
                  className={`${styles.checkbox} center ${
                    value.checked ? styles.checked : styles.nochecked
                  } ${value.classNameChecbox ? value.classNameChecbox : ""}`}
                  style={value.styleChecbox}
                >
                  {value.checked && (
                    <svg
                      width="12"
                      height="9"
                      viewBox="0 0 12 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.31414 0.793382C9.70467 0.402857 10.3378 0.402857 10.7284 0.793382C11.1189 1.18391 11.1189 1.81707 10.7284 2.2076L4.72836 8.20759C4.33783 8.59812 3.70467 8.59812 3.31414 8.20759L0.792893 5.68635C0.402369 5.29582 0.402369 4.66266 0.792893 4.27213C1.18342 3.88161 1.81658 3.88161 2.20711 4.27213L4.02125 6.08627L9.31414 0.793382Z"
                        fill="black"
                      />
                    </svg>
                  )}
                </div>
                <div
                  style={{ ...value.styleLabel, ...styleLabel }}
                  className={mergeStylesCSS([
                    styles.label,
                    value.classNameLabel && value.classNameLabel,
                    classNameLabel,
                  ])}
                >
                  {value.label}
                </div>
              </div>
            );
          }
        })}
    </div>
  );
};

export default FormCheckbox;
