import { Button, Radio, RadioChangeEvent } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import IconPerformanceMulti from "../../../../../components/icons/IconPerformanceMulti";
import IconPerformanceSingle from "../../../../../components/icons/IconPerformanceSingle";

import { DISPLAY_TARGET, DISPLAY_TYPES, ROLE_TYPE } from "../../constants";
import { calculateListEmployee } from "../../helper";
import { SelectOptionType } from "../../interfaces";
import usePerformanceManagement from "../../redux/hook";
import FormFilter from "./FormFilter";
import { determinateRoleType } from "./helper";
import styles from "./style.module.scss";
import { useSearchParams } from "react-router-dom";
const PerformanceManagementFilter = ({
  onSearchPerformance,
}: {
  onSearchPerformance: Function;
}) => {
  const { t } = useTranslation("gperformance", {
    keyPrefix: "performance_filter",
  });

  const [listEmployee, setListEmployee] = useState<SelectOptionType[]>([]);
  const {
    displayType,
    displayTarget,
    updateDisplayType,
    updateDisplayTarget,
    updateRoleType,
    roleType,
    userInformation,
    workspaces,
    employees,
  } = usePerformanceManagement();
  const onChange = (e: RadioChangeEvent) => {
    updateDisplayTarget(e.target.value);
  };

  const executeSearch = (params: any) => {
    onSearchPerformance({
      params,
      displayType,
      displayTarget,
    });
  };

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has("type_unit")) {
      const type_unit =
        searchParams.get("type_unit") || DISPLAY_TARGET.EMPLOYEE;
      if (Number(type_unit) == DISPLAY_TARGET.UNIT) {
        updateDisplayTarget(DISPLAY_TARGET.UNIT);
      } else updateDisplayTarget(DISPLAY_TARGET.EMPLOYEE);
    }
  }, []);
  useEffect(() => {
    return () => {};
  }, [displayTarget]);

  useEffect(() => {
    // #0 change role => update filter type => delete later
    // role base on user Auth
    if (userInformation?.roles) {
      updateRoleType(determinateRoleType(userInformation?.roles?.id));
    }
  }, [userInformation]);
  useEffect(() => {
    // #1 update Display when  role change
    updateDisplayType(DISPLAY_TYPES.SINGLE_SUBJECT);
    updateDisplayTarget(DISPLAY_TARGET.EMPLOYEE);
  }, []);

  useEffect(() => {
    // #1 update Display when  role change
    if (ROLE_TYPE[roleType] === ROLE_TYPE.STAFF) {
      updateDisplayType(DISPLAY_TYPES.SINGLE_SUBJECT);
    } else {
      updateDisplayType(DISPLAY_TYPES.MULTIPLE_SUBJECT);
    }
  }, [roleType]);

  useEffect(() => {
    //  calculate employee data when data came from api
    const data = calculateListEmployee(employees);
    setListEmployee(() => data);
    return () => {};
  }, [employees]);
  return (
    <div className={`${styles.filter} kpi-wrapper`}>
      {" "}
      {ROLE_TYPE[roleType] !== ROLE_TYPE.STAFF && (
        <div className={`${styles["filter-type"]}`}>
          <div className={`${styles["display-type"]}`}>
            <div className="label">{t("displayType")}:</div>

            <Button
              className={
                displayType === DISPLAY_TYPES.MULTIPLE_SUBJECT
                  ? "active"
                  : "de-active"
              }
              onClick={() => {
                updateDisplayType(DISPLAY_TYPES.MULTIPLE_SUBJECT);
              }}
              icon={
                <IconPerformanceMulti
                  color={
                    displayType === DISPLAY_TYPES.MULTIPLE_SUBJECT
                      ? "black"
                      : "#C0C0C0"
                  }
                />
              }
            >
              {t("displayMultipleTarget")}
            </Button>

            <Button
              className={
                displayType === DISPLAY_TYPES.SINGLE_SUBJECT
                  ? "active"
                  : "de-active"
              }
              onClick={() => {
                updateDisplayType(DISPLAY_TYPES.SINGLE_SUBJECT);
              }}
              icon={
                <IconPerformanceSingle
                  color={
                    displayType === DISPLAY_TYPES.SINGLE_SUBJECT
                      ? "black"
                      : "#C0C0C0"
                  }
                />
              }
            >
              {t("displaySingleTarget")}
            </Button>
          </div>
          <div className={`${styles["target-type"]}`}>
            <div className="label">{t("targetType")}:</div>
            <Radio.Group onChange={onChange} value={displayTarget}>
              <Radio value={DISPLAY_TARGET.EMPLOYEE}>
                {t("targetEmployee")}
              </Radio>
              <Radio value={DISPLAY_TARGET.UNIT}>{t("targetUnit")}</Radio>
            </Radio.Group>
          </div>
        </div>
      )}
      <FormFilter
        {...{
          roleType,
          workspaces,
          listEmployee,
        }}
        onSearchPerformance={executeSearch}
      />
    </div>
  );
};
export default PerformanceManagementFilter;
