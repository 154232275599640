export enum ENUM_TYPE_SUBTREE {
  OKR = "okr",
  SUB_OKR = "subokr",
  KR = "kr",
  TASK = "task",
}

export enum ENUM_TYPE_MODALS {
  OKR = "okr",
  SUB_OKR = "subokr",
  KR = "kr",
  TASK = "task",
  RATING = "rating",
}

export type TypeEditModals =
  | ENUM_TYPE_MODALS.OKR
  | ENUM_TYPE_MODALS.SUB_OKR
  | ENUM_TYPE_MODALS.KR
  | ENUM_TYPE_MODALS.TASK
  | ENUM_TYPE_MODALS.RATING;
