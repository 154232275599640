import React from "react";

function IconArrow(props) {
  const { stroke } = props;
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8333 1.08398L6.99996 6.91732L1.16663 1.08398"
        stroke={stroke ? stroke : "#7A7A7A"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconArrow;
