import axios from "axios";
import {
  ACCOUNT_SERVICE_URL,
  ACCOUNT_SERVICE_ADMIN_URL,
  ACCOUNT_SERVICE_ADMIN_URL_V2,
  ACCOUNT_SERVICE_KEY,
  QUICK_TICKET_ADMIN_BASE_URL,
  QUICK_SERVICE_URL,
  ACCOUNT_SERVICE_URL_V2,
} from "../request/wsCode";

export const RequestApiPrivateUsers = axios.create({
  baseURL: ACCOUNT_SERVICE_ADMIN_URL,
  headers: {
    "Content-Type": "application/json",
    "api-key": ACCOUNT_SERVICE_KEY ? ACCOUNT_SERVICE_KEY : "",
  },
});

export const RequestApiPrivateUsersV2 = axios.create({
  baseURL: ACCOUNT_SERVICE_ADMIN_URL_V2,
  headers: {
    "Content-Type": "application/json",
    "api-key": ACCOUNT_SERVICE_KEY ? ACCOUNT_SERVICE_KEY : "",
  },
});

export const RequestApiUsers = axios.create({
  baseURL: ACCOUNT_SERVICE_URL,
  headers: {
    "Content-Type": "application/json",
    "api-key": ACCOUNT_SERVICE_KEY ? ACCOUNT_SERVICE_KEY : "",
  },
});

export const RequestApiUsersV2 = axios.create({
  baseURL: ACCOUNT_SERVICE_URL_V2,
  headers: {
    "Content-Type": "application/json",
    "api-key": ACCOUNT_SERVICE_KEY ? ACCOUNT_SERVICE_KEY : "",
  },
});

export const RequestApiPrivateQuickTicket = axios.create({
  baseURL: QUICK_SERVICE_URL,
  headers: {
    "Content-Type": "application/json",
    "api-key": String(process.env.REACT_APP_QUICK_TICKET_APP_TOKEN),
  },
});

export const RequestApiPrivateQuickTicketAdmin = axios.create({
  baseURL: QUICK_TICKET_ADMIN_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "api-key": String(process.env.REACT_APP_QUICK_TICKET_APP_TOKEN),
  },
});

export const RequestApiUsersTeam = axios.create({
  baseURL: ACCOUNT_SERVICE_URL,
  headers: {
    "Content-Type": "application/json",
    "api-key": String(process.env.REACT_APP_QUICK_TICKET_APP_TOKEN),
  },
});
