import { createAsyncThunk } from '@reduxjs/toolkit';
import { openNotification, TOAST_TYPE } from '../utils';
import { FilterParam, RunBackPayload } from '../utils/interface';
import KpiSyncHistory from './api';
import { clearSlice } from './slice';
import i18n from '../../../../i18n';

export const getList = createAsyncThunk(
  'kpiSyncHistory/getList',
  async (params: FilterParam) => {
    try {
      const response = await KpiSyncHistory.list(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListUserHandle = createAsyncThunk(
  'kpiSyncHistory/getListHandle',
  async (params: any) => {
    try {
      const response = await KpiSyncHistory.list(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListMore = createAsyncThunk(
  'kpiSyncHistory/getListMore',
  async (params: FilterParam) => {
    try {
      const response = await KpiSyncHistory.list(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getListEmployee = createAsyncThunk(
  'kpiSyncHistory/getListEmployee',
  async (params: any) => {
    try {
      const response = await KpiSyncHistory.listEmployee(params);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const downloadExcel = createAsyncThunk(
  'kpiSyncHistory/downloadExcel',
  async (id: number, { dispatch }) => {
    dispatch(clearSlice());
    try {
      const response = await KpiSyncHistory.downloadExcel(id);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const runBack = createAsyncThunk(
  'kpiSyncHistory/runback',
  async (payload: RunBackPayload, { dispatch }) => {
    dispatch(clearSlice());
    try {
      const response = await KpiSyncHistory.runBack(payload);
      openNotification({
        type: TOAST_TYPE.Success,
        message: i18n.t('gperformance:syncHistory.list.sync')
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
);
