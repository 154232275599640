import { MapType } from "./type";

const ROLE: MapType = {
  default: "btn",
  icon: "btn-icon",
};

const DENSE: MapType = {
  sm: "dense-sm",
  md: "dense-md",
};

const SIZE_BTN: MapType = {
  sm: "btn-size-sm",
  "sm-1": "btn-size-sm-1",
  md: "btn-size-md",
  lg: "btn-size-lg",
  vlg: "btn-size-vlg",
  vmd: "btn-size-vmd",
};

const SIZE_ICON: MapType = {
  sm: "btn-icon-size-sm",
  sm_1: "btn-icon-size-sm-1",
  md: "btn-icon-size-md",
};

const VARIANT: MapType = {
  standard: "btn-standard",
  filled: "btn-filled",
  outlined: "btn-outlined",
};

const THEME: MapType = {
  neutral: "btn-neutral",
  primary: "btn-primary",
  secondary: "btn-secondary",
  tertiary: "btn-tertiary",
  clear: "btn-clear",
  green: "btn-green",
};

const THEME_ICON: MapType = {
  primary: "btn-icon-primary",
};

const VISIBLE: MapType = {
  true: "btn-visible",
  false: "btn-hidden",
};

const HEIGHT: MapType = {
  sm: "btn-height-sm",
  "sm-1": "btn-height-sm-1",
  md: "btn-height-md",
};
export {
  ROLE,
  DENSE,
  SIZE_BTN,
  SIZE_ICON,
  VARIANT,
  THEME,
  THEME_ICON,
  HEIGHT,
  VISIBLE,
};
