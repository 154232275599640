import axios from "axios";

export default axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL + "/api",
});

export const axiosProjectPrivate = axios.create({
  baseURL: process.env.REACT_APP_API_PROJECT_BASE_URL + "/api",
  headers: { "Content-Type": "application/json" },
});

export const axiosOkrPrivate = axios.create({
  baseURL: process.env.REACT_APP_API_OKR_BASE_URL + "/api",
  headers: { "Content-Type": "application/json" },
});

export const axiosUATPrivate = axios.create({
  baseURL: process.env.REACT_APP_API_UAT_BASE_URL + "/api",
  headers: { "Content-Type": "application/json" },
});

export const axiosWorkLogPrivate = axios.create({
  baseURL: process.env.REACT_APP_API_WORKLOG_BASE_URL + "/api",
  headers: { "Content-Type": "application/json" },
});

export const axiosMediaPrivate = axios.create({
  baseURL: process.env.REACT_APP_API_MEDIA_BASE_URL + "/api",
  headers: { "Content-Type": "application/json" },
});

export const axiosAccountPrivate = axios.create({
  baseURL: process.env.REACT_APP_API_ACCOUNT_BASE_URL + "/api/v1",
  headers: {
    "Content-Type": "application/json",
    "api-key": String(process.env.REACT_APP_OKR_APP_TOKEN),
  },
});
export const axiosAccountPrivateDomainV2 = axios.create({
  baseURL: process.env.REACT_APP_ACCOUNT_SERVICE_V2 + "/api/v2",
  headers: {
    "Content-Type": "application/json",
    "api-key": String(process.env.REACT_APP_QUICK_TICKET_APP_TOKEN),
  },
});
export const axiosAccountPrivateQticketModuleV2 = axios.create({
  baseURL: process.env.REACT_APP_API_ACCOUNT_BASE_URL + "/api/v2",
  headers: {
    "Content-Type": "application/json",
    "api-key": String(process.env.REACT_APP_QUICK_TICKET_APP_TOKEN),
  },
});
export const axiosAccountPrivateV2 = axios.create({
  baseURL: process.env.REACT_APP_API_ACCOUNT_BASE_URL + "/api/v2",
  headers: {
    "Content-Type": "application/json",
    "api-key": String(process.env.REACT_APP_OKR_APP_TOKEN),
  },
});

export const axiosAccountGprocessPrivateV2 = axios.create({
  baseURL: process.env.REACT_APP_API_ACCOUNT_BASE_URL + "/api/v2",
  headers: {
    "Content-Type": "application/json",
    "api-key": String(process.env.REACT_APP_GPROCESS_APP_TOKEN),
  },
});

export const axiosMediaGticketPrivate = axios.create({
  baseURL: process.env.REACT_APP_API_MEDIA_BASE_URL + "/api",
  headers: {
    "Content-Type": "multipart/form-data",
  },
});
export const axiosReportPrivate = axios.create({
  baseURL: process.env.REACT_APP_REPORT_BASE_URL + "/api",
  headers: {
    "Content-Type": "application/json",
    "X-Authorization": String(process.env.REACT_APP_REPORT_TOKEN),
  },
});
export const axiosAccountGticketPrivate = axios.create({
  baseURL: process.env.REACT_APP_API_ACCOUNT_BASE_URL + "/api/v1",
  headers: {
    "Content-Type": "application/json",
    "api-key": String(process.env.REACT_APP_GTICKET_APP_TOKEN),
  },
});

export const axiosAccountGticketPrivateQuickKey = axios.create({
  baseURL: process.env.REACT_APP_API_ACCOUNT_BASE_URL + "/api/v1",
  headers: {
    "Content-Type": "application/json",
    "api-key": String(process.env.REACT_APP_QUICK_TICKET_APP_TOKEN),
  },
});

export function updateAxiosHeaderAcceptLanguage(locale: string): void {
  axiosOkrPrivate.defaults.headers.common["Accept-Language"] = `${locale}`;
  axiosWorkLogPrivate.defaults.headers.common["Accept-Language"] = `${locale}`;
  axiosMediaPrivate.defaults.headers.common["Accept-Language"] = `${locale}`;
  axiosAccountPrivate.defaults.headers.common["Accept-Language"] = `${locale}`;
}
