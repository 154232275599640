import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../store/store';
import {
  initialState,
  KpiSyncHistorySlice,
  mapDataTable,
  mapEmployeeData
} from '../utils';
import { downloadFile } from './../../param-management/utils/helpers';
import {
  downloadExcel,
  getList,
  getListMore,
  getListUserHandle
} from './thunk';

export const kpiSyncHistory = createSlice({
  name: 'kpiSyncHistory',
  initialState,
  reducers: {
    reset: () => initialState,

    updateFilterParams: (state: KpiSyncHistorySlice, action) => {
      state.filterParams = { ...action.payload };
    },

    clearSlice: (state: KpiSyncHistorySlice) => {
      state.action = initialState.action;
    },

    updateAction: (state: KpiSyncHistorySlice, action) => {
      state.action = action.payload;
    },

    clearFormFilter: (state: KpiSyncHistorySlice, action) => {
      state.filterParams = initialState.filterParams;
      state.resetFormFilter = action.payload;
    },
    setHeightFilter: (state, action) => {
      state.heightFilter = action.payload
    }
  },
  extraReducers: (builder) => {
    builder

      .addCase(getList.pending, (state) => {
        state.status = 'loading';
        state.loading.list = true;
      })

      .addCase(getList.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = mapDataTable([...action.payload.data]);
        state.paginate = { ...action.payload.paginate };
        state.loading.list = false;
      })

      .addCase(getListUserHandle.pending, (state, action) => {
        state.loading.employee = true;
      })

      .addCase(getListUserHandle.fulfilled, (state, action) => {
        state.listEmployee = mapEmployeeData(action.payload.users);
        state.loading.employee = false;
      })

      .addCase(getList.rejected, (state) => {
        state.status = 'failed';
      })

      .addCase(downloadExcel.fulfilled, (state, action) => {
        downloadFile(action.payload);
      })

      .addCase(getListMore.pending, (state) => {
        state.status = 'loading';
        state.loading.loadMore = true;
      })

      .addCase(getListMore.fulfilled, (state, action) => {
        state.status = 'idle';
        const _listMore = mapDataTable([...action.payload.data]);
        state.list = [...state.list, ..._listMore].map((_item, _idx) => ({
          ..._item,
          index: _idx + 1
        }));
        state.paginate = { ...action.payload.paginate };
        state.loading.loadMore = false;
      });
  }
});

export const {
  updateFilterParams,
  clearSlice,
  updateAction,
  clearFormFilter,
  reset,
  setHeightFilter
} = kpiSyncHistory.actions;

export const syncHistory = (state: RootState) => state.kpiSyncHistory;

export default kpiSyncHistory.reducer;
