import toast from "react-hot-toast";
import { nanoid } from "@reduxjs/toolkit";
import {
  addDays,
  endOfMonth,
  format,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import i18n, { t } from "i18next";

import { DATE_FORMAT, ROLES_ID } from "./constant";
import { updateAxiosHeaderAcceptLanguage } from "../api/axios";
import { KEY_DATA_BY_INDEX } from "../features/okr/interface/constants";
import { ResultOKRsType } from "../features/okr/interface/type";
import { ENUM_TYPE_SUBTREE } from "../components/UI/TreeOKRs/enum";
import { updateAxiosHeaderGprocessAcceptLanguage } from "../features/g-process/component/api/axios";
import { MediaApi, downloadFile } from "../api/MediaApi";
import { UserType } from "../features/auth/types/types";
import { checkHaveChildren } from "./transformDataHelper";
import _ from "lodash";

export function formatDate(date: any): any {
  if (!date) return "";
  return format(new Date(date), DATE_FORMAT.FE);
}

export function formatDateWithHoursAndMinutes(date: any): any {
  if (!date) return "";
  return format(new Date(date), DATE_FORMAT.FE_HH_MM);
}

export function formatMilliSecondToDate(date: any): any {
  if (!date) return "";
  return format(new Date(Number(String(date).padEnd(13, "0"))), DATE_FORMAT.FE);
}

export function formatMilliSecondToDateYYMMDDHHMMSS(date: any): any {
  if (!date) return "";
  return format(
    new Date(Number(String(date).padEnd(13, "0"))),
    DATE_FORMAT.FE_DD_MM_YY_HH_MM_SS
  );
}

export function formatSqlDate(date: Date | null): string {
  if (!date) return "";
  return format(date, DATE_FORMAT.BE);
}

export function uuid(): string {
  return nanoid();
}

export function buildQueryUrl(
  baseUrl: string,
  queryObj: { [key: string]: any }
): string {
  if (Object.keys(queryObj).length === 0) return baseUrl;
  let url = baseUrl;
  let i = 0;
  for (let queryKey of Object.keys(queryObj)) {
    let urlString = "";
    if (
      (queryObj[queryKey] && !Array.isArray(queryObj[queryKey])) ||
      typeof queryObj[queryKey] === "number"
    ) {
      urlString = `${i === 0 ? "?" : "&"}${queryKey}=${encodeURIComponent(
        queryObj[queryKey]
      )}`;
      url += urlString;
      i++;
    } else if (
      queryObj[queryKey] &&
      Array.isArray(queryObj[queryKey]) &&
      queryObj[queryKey].length > 0
    ) {
      let j = 0;
      for (let item of queryObj[queryKey]) {
        urlString += `${i === 0 && j === 0 ? "?" : "&"}${queryKey}[]=${item}`;
        j++;
      }
      url += urlString;
      i++;
    }
  }
  return url;
}

export function renderServerErrorMsg(error: any, toast: any): void {
  if (error && error.response && error.response.status === 422) {
    const messages = error.response.data.message;
    let messageText = i18n.t("layout:common.somethingWrong");
    if (typeof messages === "string") {
      messageText = messages;
    } else if (Array.isArray(messages) && messages.length > 0) {
      messageText = Object.values(messages).join(" ");
    }
    toast.error(messageText);
    return;
  } else if (error && error.response && error.response.status === 403) {
    let messageText = i18n.t("layout:common.youAreNotPermisionToDoThis");
    toast.error(messageText);
    return;
  } else {
    toast.error(i18n.t("layout:common.somethingWrong"));
  }
}

export function checkValidToken(
  token: string,
  expiresAt: string | number
): boolean {
  if (!token || !expiresAt) return false;
  return new Date(Number(expiresAt) * 1000) > new Date();
}

export function checkStringInclude(str: string, word: string): boolean {
  if (!str) return false;
  if (!word) return true;
  return str.toLocaleLowerCase().includes(word.toLocaleLowerCase());
}

export function getNextDay(date: any, defaultDate?: any): any {
  if (!date) return defaultDate;
  const nextDay = new Date(date);

  nextDay.setDate(date.getDate() + 1);
  return nextDay;
}

export const convertDateToSecond = (date: any) =>
  Math.floor(new Date(date).getTime() / 1000);

export const checkArray = (arr: any[]) => {
  if (!arr) return false;
  if (!arr.length) return false;
  return arr;
};

export const isResultEqualNull = (result: any) => {
  return result === null;
};

export const migrateOptions = (
  options: any,
  keyFormValue: { [key: string]: string },
  setFieldValue: any,
  isSelectDefaultDivision = false
) => {
  let _options;
  let result;
  if (Array.isArray(options) && options.length > 0) {
    // input is ARRAY
    result = options.map(
      (option: { name: string; code: string; id: number }) => ({
        label: `${option.code}-${option.name}`,
        value: option.id,
      })
    );
    _options =
      result.length >= 2 ? [{ label: "Tât cả", value: 0 }, ...result] : result;
  } else {
    // input is OBJECT
    _options = [
      {
        label: `${options.code}-${options.name}`,
        value: options.id,
      },
    ];
  }
  setFieldValue(keyFormValue.options, _options);
  if (isSelectDefaultDivision || _options.length >= 2) {
    setFieldValue(keyFormValue.selected, _options[0]);
  }
  return;
};

export function isJsonString(str: string): boolean {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function isNodeOKR(level: number) {
  if (level === 0) return true;
  return false;
}

export function mergeStylesCSS(styles: (string | boolean | undefined)[]) {
  return styles.join(" ");
}

const { CEO, DCEO, CFO, MDNS, MDSS } = ROLES_ID;

export function filterUsersHelper(users: any[]) {
  return users.filter((user) => {
    return ![CEO, DCEO, CFO, MDNS, MDSS].includes(user.roles?.id);
  });
}

export function updateI18nLocale(locale: "en" | "vn"): void {
  i18n.changeLanguage(locale);
  updateAxiosHeaderAcceptLanguage(locale);
  updateAxiosHeaderGprocessAcceptLanguage(locale);
}

export function getMondayDayAndSunDayOfCurrentWeek() {
  const today = new Date();
  const currentIndex = today.getDay();
  if (currentIndex === SUNDAY_INDEX) {
    const first =
      today.getTime() - (TOTAL_DAYS_IN_WEEK - 1) * MILLISECONDS_IN_DAY; // as timestamp
    const monday = new Date(first);
    const sunday = today;
    return [monday, sunday];
  }
  const first = today.getTime() - (currentIndex - 1) * MILLISECONDS_IN_DAY; // as timestamp
  const last =
    today.getTime() + (TOTAL_DAYS_IN_WEEK - currentIndex) * MILLISECONDS_IN_DAY; // as timestamp
  const monday = new Date(first);
  const sunday = new Date(last);
  return [monday, sunday];
}

export function getStartAndNextDayOfWeek(addDay: number = ADD_DAY_DEFAULT) {
  const dayStartOfWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
  const nextDay = addDays(dayStartOfWeek, addDay);
  return {
    startOfWeek: dayStartOfWeek.getTime() / 1000,
    nextDay: nextDay.getTime() / 1000,
  };
}

export function getFirstAndLastDayOfMonth() {
  const firstDayOfMonth = startOfMonth(new Date());
  const lastDayOfMonth = endOfMonth(new Date());
  return {
    firstDay: firstDayOfMonth.getTime() / 1000,
    lastDay: lastDayOfMonth.getTime() / 1000,
  };
}

export function generateInitialState() {
  const [first, last] = getMondayDayAndSunDayOfCurrentWeek();
  return [
    {
      id: uuid(),
      isClone: true,
      timesheet: getDatesInRange(first, last).map((date: Date) => ({
        date: formatDate(date),
        ddMM: getDDMM(formatDate(date)),
        hour: 0,
      })),
      total: 0,
    },
  ];
}

export function getDDMM(date: string) {
  let counterSlat = 0;
  let output = "";
  [...date].forEach((item: string) => {
    if (item === "/") {
      counterSlat += 1;
    }
    if (counterSlat < 2) {
      output += item;
    }
  });
  return output;
}

export function getDatesInRange(fromDate: Date, toDate: Date) {
  const date = new Date(fromDate.getTime());

  const dates = [];

  while (date <= toDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
}

export async function delay(ms?: number) {
  return new Promise((resolve) => setTimeout(resolve, ms ? ms : 1000));
}

export function roundNumber(number: number, fixed: number = 2) {
  if (typeof number !== "number") return 0;
  if (number % 1 === 0) return number;
  return number.toFixed(fixed);
}

export function getNumerator(resultOKRs: ResultOKRsType, index: number) {
  const key = resultOKRs[KEY_DATA_BY_INDEX[index]];
  if (key && typeof key.progress_actual === "number")
    return Number(roundNumber(key.progress_actual, 1));
  return "-";
}

export function getDenominator(resultOKRs: ResultOKRsType, index: number) {
  const key = resultOKRs[KEY_DATA_BY_INDEX[index]];
  if (key && typeof key.progress_target === "number")
    return Number(roundNumber(key.progress_target, 1));
  return "-";
}

export function calculateCompletePercent(props: {
  numerator: number | null;
  denominator: number | null;
  progress: number;
  type:
    | ENUM_TYPE_SUBTREE.OKR
    | ENUM_TYPE_SUBTREE.TASK
    | ENUM_TYPE_SUBTREE.SUB_OKR
    | ENUM_TYPE_SUBTREE.KR;
}) {
  const { numerator, denominator, type, progress } = props;
  let percent = 0;
  if (
    numerator === null ||
    denominator === null ||
    (numerator === null && denominator === null)
  )
    return null;
  if (type === ENUM_TYPE_SUBTREE.TASK && progress !== undefined) {
    if (progress === 100) return 1;
    return progress;
  }

  if (type === ENUM_TYPE_SUBTREE.KR) {
    if (denominator === 0 && numerator === 0) {
      percent = 0;
      return percent;
    }
    if (denominator === 0) {
      percent = numerator;
      return percent / 100;
    }
    return numerator / denominator;
  }

  if (type === ENUM_TYPE_SUBTREE.OKR || ENUM_TYPE_SUBTREE.SUB_OKR) {
    if (denominator === 0 && numerator === 0) {
      percent = 0;
      return percent;
    }
    if (denominator === 0) {
      percent = numerator;
      return percent / 100;
    }
    if (denominator === 100 && numerator === 100) {
      return 1;
    }
    if (numerator <= 100 && numerator > 0 && denominator !== 0) {
      percent = numerator / denominator;
      return percent;
    }
  }
  return percent;
}

export function handleSortProcessStep(processStep: any): any {
  let i = 0;
  let j = 0;
  const newProcess = processStep;
  for (i = 0; i < newProcess.length - 1; i++)
    for (j = i + 1; j < newProcess.length; j++) {
      if (newProcess[i].step_order > newProcess[j].step_order) {
        let temp = newProcess[i];
        newProcess[i] = newProcess[j];
        newProcess[j] = temp;
      }
    }
  return newProcess;
}

export const downloadFileHandler = async (
  fileName: string,
  setDownloading: ((_: boolean) => void) | undefined = undefined
) => {
  try {
    setDownloading?.(true);
    const response = await MediaApi.downloadTemplateFile(fileName);
    downloadFile(response);
    setDownloading?.(false);
  } catch (err) {
    setDownloading?.(false);
    toast.error(t("somethingWrong"));
  }
};

export const showDeniedMessage = () => {
  return toast.error(t("okr:table-okr.permission-denied"));
};

export const convertArrayToObject = (array: any[], key: string) => {
  if (!array || !key) return {};
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};
export const isCEO = (user: UserType | null) => {
  if (!user) return false;
  return user.roles?.id === ROLES_ID?.CEO;
}
export const getTextWidth = (
  listInputText: string[] | string,
  fontSize: string,
  fontFamily: string = "GoogleSans Regular"
) => {
  const font = `${fontSize} ${fontFamily}`;
  const canvas = document.createElement("canvas");
  const context: any = canvas.getContext("2d") ?? {};
  context.font = font;

  if (typeof listInputText === "string") {
    const width = context.measureText(listInputText).width;
    const formattedWidth = Math.ceil(width);
    return formattedWidth;
  }
  if (Array.isArray(listInputText)) {
    return listInputText.map((text) => {
      const width = context.measureText(text).width;
      const formattedWidth = Math.ceil(width);
      return formattedWidth;
    });
  }
};

export function isQuillEmpty(value: string) {
  if (
    value.replace(/<(.|\n)*?>/g, "").trim().length === 0 &&
    !value.includes("<img")
  ) {
    return true;
  }
  return false;
}


export const transformListUser = (workspaceUsers: any[]) => {
  let result: any[] = [];
  if (!workspaceUsers || workspaceUsers.length === 0) return result;
  const allUsers: any = {};

  // Lấy full users kèm thông tin phòng ban
  workspaceUsers.forEach((dpm: any) => {
    // Lấy full users thuộc về 1 phòng ban
    const departmentUsers = [
      ...(dpm.members || []),
      ...(dpm.workspace_concurrently || []),

      // Sử dụng để lấy user được tính kết quả theo đơn vị này
      // ...(dpm.workspace_beneficiaries || []),
    ];

    // Add phòng ban vào các user ở list kết quả
    departmentUsers.forEach((user: any) => {
      if (allUsers[user.id]) {
        allUsers[user.id].workspaceIds = [
          ...(allUsers[user.id]?.workspaceIds || []),
          dpm.workspace?.id,
        ];
      } else {
        allUsers[user.id] = {
          ...user,
          workspaceIds: [dpm.workspace?.id],
        };
      }
    });
  });

  // Transform từ object sang array và chuẩn hóa data
  for (const key in allUsers) {
    result.push({
      ...allUsers[key],
      workspaceIds: allUsers[key]?.workspaceIds?.filter((it: any) => !!it),
    });
  }

  return result;
};

export const getListAssignee = (
  paramsSearch: any,
  filterData: any = {},
  allUsers: any[] = []
) => {
  const wspIdsSelected: number[] =
    paramsSearch.workspaces?.map((it: any) => it.id) || [];
  let users: any[] = wspIdsSelected.length
    ? allUsers.filter((it: any) => {
        const commonItem =
          it.workspaceIds?.filter((id: number) =>
            wspIdsSelected.includes(id)
          ) || [];
        return commonItem.length > 0;
      })
    : [...allUsers];

  // Nếu chứa đơn vị trực thuộc thì add thêm current user
  const wspAffiliated = [
    filterData.current_user?.workspace?.id,
    ...(filterData?.current_user?.workspace_concurrently?.map(
      (it: any) => it.workspace?.id
    ) || []),
  ];
  const wspSelectedAffiliated = wspIdsSelected.filter((it: any) =>
    wspAffiliated.includes(it)
  );
  if (wspSelectedAffiliated.length > 0 || wspIdsSelected.length === 0) {
    let userLogin = filterData.current_user;
    if (userLogin?.id)
      users.unshift({
        id: userLogin?.id,
        full_name: userLogin?.full_name,
        employee: userLogin?.employee,
        user_name: userLogin?.user_name,
        workspaceIds: [...wspAffiliated],
      });
    users = removeDuplicateItem(users, "id");
  }
  // return users;
  return _.sortBy(users, [(o) => o.full_name?.toLowerCase()]);
};

export const getActiveItem = (item: any = {}) => {
  if (!item.status) return;

  if (checkHaveChildren(item)) {
    let temp = item.children?.map((it: any) => getActiveItem(it));
    temp = temp.filter((it: any) => !!it);
    return {
      ...item,
      children: temp,
    };
  }

  return { ...item };
};

export const getChildRenActive = (children: any[]) => {
  const newData = children?.map((it: any) => getActiveItem(it)) || [];
  return newData.filter((it) => !!it);
};

export const getTreeData = (
  treeData: any = {},
  workspaceManagerId: number[] = [],
  workspaceStaffId: number[] = []
) => {
  if (!treeData?.status) return;
  let result;
  // Nếu là quản lý thì return toàn bộ đơn vị con
  if (workspaceManagerId.includes(treeData.id)) {
    return { ...treeData, children: getChildRenActive(treeData.children) };
  }
  // Nếu là staff return đơn vị
  const isStaff = workspaceStaffId.includes(treeData.id);
  if (isStaff) {
    result = { ...treeData, children: undefined };
  }

  // Tìm kiếm các cấp con thỏa mãn điều kiện
  if (checkHaveChildren(treeData)) {
    const childrenFilter = treeData.children
      .map((it: any) => getTreeData(it, workspaceManagerId, workspaceStaffId))
      ?.filter((it: any) => it?.status);

    if (childrenFilter && childrenFilter.length > 0) {
      result = {
        ...treeData,
        children: childrenFilter,
        disabled: isStaff ? false : true,
      };
    }
  }
  return result;
};

export const getAllWorkspace = (treeData: any): any[] => {
  let result = [];
  result.push({ ...treeData, children: undefined });
  if (checkHaveChildren(treeData)) {
    let children: any[] = treeData.children?.map((it: any) =>
      getAllWorkspace(it)
    );
    children = children?.flat(2);
    result = [...result, ...(children || [])];
  }
  return result;
};

export const getWorkspaceWithIds = (wspIds: number[] = [], treeData: any) => {
  const allWorkspace = getAllWorkspace(treeData);
  return allWorkspace
    ?.filter((it: any) => wspIds.includes(it.id))
    ?.map((it: any) => ({ ...it, value: it.id, label: it.name }));
};

export const removeDuplicateItem = (list: any[] = [], fieldName: string) => {
  const uniqueIds: any = [];

  const unique = list.filter((element) => {
    const isDuplicate = uniqueIds.includes(element[fieldName]);

    if (!isDuplicate) {
      uniqueIds.push(element[fieldName]);

      return true;
    }

    return false;
  });

  return unique;
};

export const getWspIds = (data: any = {}) => {
  let wsp: any[] = [];
  let wspManager: any[] = [];
  const currentWspOwnerId = data.workspace?.owner?.[0]?.id;

  if (currentWspOwnerId === data.id) wspManager.push(data.workspace);
  else wsp.push(data.workspace);

  wspManager = [...wspManager, ...(data.job_views || [])];

  data.job_scores?.forEach((it: any) => {
    if (it?.is_primary) wspManager = [...wspManager, ...(it?.workspace || [])];
    else wsp = [...wsp, ...(it?.workspace || [])];
  });

  data.jobs?.forEach((it: any) => {
    const ownerId = it.workspace?.owner?.[0]?.id;
    if ((ownerId && ownerId === data.id) || (!ownerId && it?.is_primary))
      wspManager.push(it?.workspace);
    else wsp.push(it?.workspace);
  });
  return [wsp, wspManager];
};


const SUNDAY_INDEX = 0;
const ADD_DAY_DEFAULT = 4;
const HOURS_IN_DAYS = 24;
const MINUTES_IN_HOUR = 60;
const SECONDS_IN_MINUTE = 60;
export const MILLISECONDS_IN_SECOND = 1000;
const MILLISECONDS_IN_DAY =
  HOURS_IN_DAYS * MINUTES_IN_HOUR * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND; // as timestamp
const TOTAL_DAYS_IN_WEEK = 7; // count from sunday-0-1-2-3-4-5-6
