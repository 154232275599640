import React from "react";

const ErrorWaring = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 0.666748C11.1083 0.666748 0.666626 11.1084 0.666626 24.0001C0.666626 36.8918 11.1083 47.3334 24 47.3334C36.8916 47.3334 47.3333 36.8918 47.3333 24.0001C47.3333 11.1084 36.8916 0.666748 24 0.666748ZM26.3333 35.6668H21.6666V21.6667H26.3333V35.6668ZM26.3333 17.0001H21.6666V12.3334H26.3333V17.0001Z"
        fill="#F0B71C"
      />
    </svg>
  );
};

export default ErrorWaring;
