import { OPTION_STATUS, STATUS } from './constant';
import { CheckEnum, GoalTypeEnum, StatusEnum, SubjectEnum } from './enum';
import _ from 'lodash';
import {
  ConfigFilter,
  FilterParam,
  ListPerformance,
  ListStatusColumn,
  OptionSelect,
  TreeData,
  UserInfo,
  WorkspaceDetail
} from './interface';
import { current, nanoid } from '@reduxjs/toolkit';
import i18n from '../../../../i18n';
import { t } from 'i18next';
import { formatShowDecimalNumber } from '../../common/utils/helper';

export const convertStatus = (_status: number): boolean => {
  if (_status === STATUS.Active) return true;
  return false;
};

export const removeCondition = (el: any) => {
  const _el = { ...el };
  if (_el.conditions) {
    delete _el.conditions;
  }
  return _el;
};

export const downloadFileParam = (params: FilterParam) => {
  const _params = { ...params };
  if (_params.per_page) {
    delete _params.per_page;
  }
  if (_params.page) {
    delete _params.page;
  }
  return _params;
};

export const downloadFile = (response: any): void => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const fileName = getFileNameInResponseHeader(response.headers);
  const link = document.createElement('a');
  link.target = '_blank';
  link.download = fileName;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

const getFileNameInResponseHeader = (headers: any): any => {
  let fileName = '';
  if (!headers) return '';
  if ('content-disposition' in headers) {
    const data = headers['content-disposition']
      .split(';')
      .map((item: string) => item.trim());

    for (let item of data) {
      const disposition = item.split('=');
      if (disposition.length > 0) {
        if (disposition[0] === 'filename') return disposition[1];
      }
    }
  }
  return fileName;
};

export const formatText = (_val: string): string => {
  if (_val.length > 20) {
    return _val.substring(0, 20) + '...';
  }
  return _val;
};

export const mapEmployeeData = (data: UserInfo[]): OptionSelect[] => {
  return data.map((_user) => ({
    label: _user?.full_name || '-',
    value: _user?.id
  }));
};

export const mapDataTable = (data: any): ListStatusColumn[] => {
  return data.map((_item: any, _idx: number) => ({
    name:
      _item.type_unit === SubjectEnum.Individual
        ? _item.user?.name
        : _item.workspace?.name,
    id: _item.id,
    key: _item.id,
    status: _item.status,
    type: _item.type,
    density: _item.proportion,
    goal_code: _item.goal_code,
    index: _idx + 1,
    code:
      _item.type_unit === SubjectEnum.Individual
        ? _item.user?.employee_code
        : _item.workspace?.code,
    goal: _item.goal_code,
    goal_name: _item.goal_name,
    goal_type: _item.type,
    month: _item.month?.split('/')?.[0],
    year: _item.month?.split('/')?.[1]
  }));
};

export const mapDataDetail = (data: any) => {
  if (!data) return null;
  return {
    subjectCode: data.user?.employee_code || data.workspace?.code,
    subject: data.user?.full_name || data.workspace?.name,
    goalCode: data.goal_code,
    goal: data.goal_name,
    goalType: data.type === GoalTypeEnum.Month ? 'Tháng' : 'Năm',
    timeMonth: data.month?.split('/')?.[0],
    timeYear: data.month?.split('/')?.[1],
    density: data.proportion
  };
};

export const addOwner = (
  data: WorkspaceDetail[],
  key = 0
): WorkspaceDetail[] => {
  const owner = {
    id: nanoid(),
    workspace_id: nanoid(),
    name: 'Trần Thu Thủy',
    employee_id: null,
    employee_code: '0197422',
    user_name: 'Thuy.tt1',
    full_name: 'Thuy.tt1 (Trần Thu Thủy | TruongTest)',
    email: 'account_fake_new_407@fake.com.vn',
    status: 1,
    roles: {
      id: 9,
      code: 'staff',
      name: 'Staff',
      level: 6,
      status: 1
    }
  };
  return data.map((_item, _idx) => {
    if (!_item?.children)
      return {
        ..._item,
        owner
      };
    return {
      ..._item,
      owner,
      children: addOwner(_item.children)
    };
  });
};

export const findAllValueNestedByField = (
  parent: any,
  field: string,
  keys: number[] = []
) => {
  if (!parent?.children) return [];
  keys.push(parent[field]);
  parent.children.forEach((_child: any) => {
    if (_child[field]) {
      keys.push(_child[field]);
    }
    findAllValueNestedByField(_child, field, keys);
  });
  return keys;
};

export const findAllKeyChildren = (
  parent: ListStatusColumn,
  keys: number[] = [],
  status: StatusEnum
) => {
  if (!parent.children) return [];
  parent.children.forEach((_child) => {
    if (status === _child.status) {
      keys.push(_child.key);
    }
    findAllKeyChildren(_child, keys, status);
  });
  return keys;
};

export const findAllKeyChildrenHasObject = (
  parent: any,
  keys: number[] = []
) => {
  if (!parent.children) return [];
  parent.children.forEach((_child: any) => {
    if (_child.object) {
      keys.push(_child.key);
    }
    findAllKeyChildrenHasObject(_child, keys);
  });
  return keys;
};

export const findAllKeyObjectChildrenFormKey = (
  parent: any,
  keys: number[] = [],
  key: number
) => {
  const currentObj = findNestedObj(parent, 'id', key);
  return currentObj ? findAllKeyChildrenHasObject(currentObj, keys) : [];
};

export const finObjFormKey = (
  obj: ListStatusColumn,
  result: ListStatusColumn | null,
  key: number
) => {
  if (obj.key === key) return obj;
  obj.children?.forEach((_child) => {
    if (_child.key === key) {
      result = { ..._child };
    }
    finObjFormKey(_child, result, key);
  });

  return result;
};

export function findNestedObj(
  entireObj: ListStatusColumn,
  keyToFind: string,
  valToFind: number
) {
  let foundObj;
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind] === valToFind) {
      foundObj = nestedValue;
    }
    return nestedValue;
  });
  return foundObj;
}

export const getChildIdsDepth = (
  node: any,
  result: any[] = [],
  depth = 1,
  fromDepth = node?.level
): any[] => {
  if (!node) return result;
  if (!Array.isArray(node.children)) return result;
  if (node?.level - fromDepth === 1) return result;

  for (let entry of node.children) {
    if (entry.status && entry.status === StatusEnum.SentApprove) {
      result.push(entry.key);
    }
    getChildIdsDepth(entry, result, depth++, fromDepth);
  }
  return result;
};

export const findAllKeyChildrenFormKey = (
  parent: ListStatusColumn,
  keys: number[] = [],
  status: StatusEnum,
  key: number
) => {
  const currentObj = findNestedObj(parent, 'key', key);
  return currentObj ? findAllKeyChildren(currentObj, keys, status) : [];
};

export const findKeyByLevel = (
  obj: ListStatusColumn,
  level: number,
  initKeys: number[] = [],
  initLevel: number = 1
) => {
  if (!obj) return initKeys;
  if (initLevel === 1) {
    initKeys.push(obj?.key);
  }
  if (!obj?.children?.length || level === initLevel) return initKeys;
  obj.children?.forEach((_child) => {
    initKeys.push(_child.key);
    findKeyByLevel(_child, level, initKeys, initLevel + 1);
  });
  return initKeys;
};

type PerformanceShow = {
  okr: number | null;
  kpi: number | null;
  gene: number | null;
  total: number | null;
  status: StatusEnum | null;
};

const getPerformance = (id: number, data: ListPerformance): PerformanceShow => {
  const items = data?.[id];
  if (!items?.data?.length)
    return {
      okr: null,
      kpi: null,
      gene: null,
      total: null,
      status: null
    };
  const itemsOKR = items.data.filter(
    (_item) => _item.goal.goal_category.code === 'OKR'
  );

  const proportionOKR = itemsOKR.reduce((_acc, _item) => {
    return _acc + Number(_item.proportion);
  }, 0);

  const itemsKPI = items.data.filter(
    (_item) => _item.goal.goal_category.code === 'KPI'
  );

  const proportionKPI = itemsKPI.reduce((_acc, _item) => {
    return _acc + Number(_item.proportion);
  }, 0);

  const itemsGene = items.data.filter(
    (_item) => _item.goal.goal_category.code === 'GENE'
  );

  const proportionGene = itemsGene.reduce((_acc, _item) => {
    return _acc + Number(_item.proportion);
  }, 0);

  return {
    okr: formatShowDecimalNumber(proportionOKR),
    kpi: formatShowDecimalNumber(proportionKPI),
    gene: formatShowDecimalNumber(proportionGene),
    total: formatShowDecimalNumber(proportionOKR + proportionKPI + proportionGene),
    status: items?.data[0]?.status
  };
};

export const mapTreeDataTable = (
  data: WorkspaceDetail[],
  dataPerformance: ListPerformance,
  time: { month?: number | string; year?: number | string },
  idsSearch: { workspaceIds: string[]; userIds: string[] },
  maxLevelUser: number,
  levelCurrentUser: number,
  workspaceId: number,
  idsAllWorkspaceFilter: number[],
  detailUser: any
): any => {
  const arrayIdsSearch = [...idsSearch.workspaceIds, ...idsSearch.userIds];
  const result: any[] = [];
  if (!data.length) return result;

  data.map((_item, _idx) => {
    if (!idsAllWorkspaceFilter.includes(Number(_item.id))) return result;
    const rowItem = {
      name: _item.name,
      id: _item.id,
      key: _item.id,
      level: _item.level,
      position: _item.object,
      isObject: _item.object,
      month: time.month,
      year: time.year,
      hasChildren: _item.has_children,
      ...getPerformance(_item.id, dataPerformance)
    };

    const hasMember =
      _item.members?.filter((_member) =>
        arrayIdsSearch.includes(String(_member.id))
      ).length > 0;

    let childrenMembers: any[] = [];

    if (hasMember) {
      childrenMembers =
        _item.members?.map((_member) => ({
          name: _member.name || _member.user_name,
          id: _member.id + 'unique' + nanoid(),
          key: _member.id + 'unique' + nanoid(),
          level: _item.level + 1,
          position: _member.roles?.name,
          month: time.month,
          year: time.year,
          hasChildren: false,
          children: [],
          ...getPerformance(_member.id, dataPerformance)
        })) || [];
    }

    if ( !_item?.owner) {
      const children = _item?.children?.length ? mapTreeDataTable(
        _item.children,
        dataPerformance,
        time,
        idsSearch,
        maxLevelUser,
        levelCurrentUser,
        workspaceId,
        idsAllWorkspaceFilter,
        detailUser
      ) : []
      const item = {
        ...rowItem,
        children: hasMember ? [...childrenMembers, ...children] : children
      };
      result.push(item);
    }

    // có owner trong _item
    if (_item.owner) {
      const children = _item.children?.length ?  mapTreeDataTable(
        _item.children,
        dataPerformance,
        time,
        idsSearch,
        maxLevelUser,
        levelCurrentUser,
        workspaceId,
        idsAllWorkspaceFilter,
        detailUser
      ) : [];
      const owner = {
        name: _item.owner.name || _item.owner.user_name,
        id: _item.owner.id + 'unique' + nanoid(),
        key: _item.owner.id + 'unique' + nanoid(),
        level: _item.level,
        position: _item.owner?.roles?.name,
        isObject: false,
        month: time.month,
        year: time.year,
        hasChildren: _item.has_children,
        ...getPerformance(_item.owner.id, dataPerformance),
        children: childrenMembers?.filter(
          (_member) => _member.id?.split('unique')[0] !== String(_item.owner.id)
        )
      };
      const itemHasChildOwner = {
        ...rowItem,
        children: [owner, ...children]
      };

      // Show owner nếu có status tạo mới, có children và có ít nhất 1 con có status khác tạo mới
      const idSearchIncludesOwner = idsSearch.userIds.includes(
        String(owner.id?.split('unique')[0])
      );
      const memberHasStatusAndHasSearchFilter = owner.children?.some(
        (_member) =>
          _member.status &&
          idsSearch.userIds.includes(_member.id?.split('unique')[0])
      );

      const ownerHasPerformance = dataPerformance[_item.owner.id];

      // có owner trong flter nhân viên và owner có performance
      if (idSearchIncludesOwner && ownerHasPerformance) {
        result.push(itemHasChildOwner);
      } else {
        result.push({
          ...rowItem,
          children: hasMember ? [...childrenMembers, ...children] : children
        });
      }
    }
  });
  return result;
};

export const getParentIds = (
  target: any,
  children: any,
  ancestors = []
): any => {
  for (let node of children) {
    if (node.id === target) {
      return ancestors;
    }
    const found = getParentIds(
      target,
      node.children,
      ancestors.concat(node.id)
    );
    if (found) {
      return found;
    }
  }
  return '';
};

function findNode(data: any, id: any): any {
  if (!Array.isArray(data)) return;

  for (let entry of data) {
    if (entry.id === id) {
      return entry;
    } else {
      const node = findNode(entry.children, id);
      if (node) {
        return node;
      }
    }
  }
}

function getChildIdsFromNode(node: any, result: any[] = []): any {
  if (!node) return [];
  if (!Array.isArray(node.children)) return [];

  for (let entry of node.children) {
    result.push(entry.id);
    getChildIdsFromNode(entry, result);
  }
  return result;
}

export const getChildIds = (id: number, data: any[]): number[] =>
  getChildIdsFromNode(findNode(data, id));

const newTreeIds = (ids: number[], data: any[]) => {
  let resultIds: number[] = [];
  ids.forEach((_id) => {
    let parentIds = getParentIds(_id, data);
    let childrenIds = getChildIds(_id, data);
    resultIds = [...childrenIds, ...parentIds];
  });
  return [...new Set(resultIds)];
};

export function getChildIdsIsObject(node: any, result: any[] = []): any {
  if (!node) return [];
  if (!Array.isArray(node.children)) return [];

  for (let entry of node.children) {
    if (entry.isObject) {
      result.push(entry.id);
    }
    getChildIdsIsObject(entry, result);
  }
  return result;
}

export const mapTreeData = (
  treeData: any,
  parentIds: number[],
  childrenIds: number[],
  currentIds: number[]
): any => {
  const ids = [...parentIds, ...childrenIds, ...currentIds];
  const item = treeData.filter((_item: any) => {
    return ids.includes(_item.id);
  });

  return item.map((_item: any) => {
    if (_item?.children?.length > 0) {
      return {
        title: _item.name,
        value: _item.id,
        key: _item.id,
        disabled: ![...childrenIds, ...currentIds].includes(_item.id),
        status: 0,
        children: mapTreeData(
          _item.children,
          parentIds,
          childrenIds,
          currentIds
        )
      };
    } else {
      return {
        title: _item.name,
        value: _item.id,
        key: _item.id,
        disabled: ![...childrenIds, ...currentIds].includes(_item.id),
        status: 0,
        children: []
      };
    }
  });
};

export const mapDataFilter = (
  values: any,
  listEmployee: OptionSelect[]
): FilterParam => {
  const employee_ids = values.employeeId?.includes(CheckEnum.All)
    ? listEmployee?.map((_employee) => _employee.value)?.join(',')
    : values.employeeId?.join(',');
  const target = values.employeeId?.includes(CheckEnum.All) ? i18n.t('gperformance:listStatus.filter.all'): undefined

  const workspace_ids =
    typeof values.subjectId?.[0] === 'number'
      ? values.subjectId?.join(',')
      : values.subjectId?.map((_item: any) => _item.value)?.join(',');

  const status = values.status.includes(CheckEnum.All)
    ? OPTION_STATUS.map((_status) => _status.value)
    : values.status;

  const filterParams: FilterParam = {
    type: values.goalType,
    monthYear: values.timeMonth + '/' + values.timeYear,
    month: values.timeMonth,
    year: values.timeYear,
    status,
    user_ids: employee_ids,
    workspace_ids: workspace_ids,
    target
  };
  return filterParams;
};

export const serialiseObject = (data: any) => {
  return Object.keys(data)
    .map((key) => `${key}=${encodeURIComponent(data[key])}`)
    .join('&');
};
