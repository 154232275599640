import { CSSProperties } from "react";
import { checkArray, mergeStylesCSS } from "../../../../helpers/helper";
import LoadingRing from "../../LoadingRing/LoadingRing";
import styles from "./fwTable.module.scss";

type TypeProps = {
  config: {
    key: any; //Tên của thuộc tính trong object
    title?: any; //Title muốn thể hiện, có thể không cần truyền
    center?: Boolean; //Title căn trái hoặc giữa (Mặc định là fale)
    centerItem?: Boolean; //các text trong item căn trái phải (Mặc định là false)
    width?: Number | string; //Width là độ rộng nhỏ nhất của width
    minWidth?: Number | string; //minWidth là độ rộng nhỏ nhất của minWidth
    render?: Function; //Tự custom dữ liệu được trả ra hiển thị trên màn hình
    classTitle?: String; //ClassName của một cột trong title
    classItem?: String; //ClassName của một cột trong item
    styleItem?: CSSProperties; //Style của Item (Không có style của title để tránh ảnh hưởng đến các thuộc tính mặc định)
    onClick?: Function; //Sự kiện khi click vào cột của item
    colSpan?: Number; //Title chiếm bao nhiêu cột
    rowSpan?: Number; //Titke chiếm bao nhiêu dòng
  }[];
  data: Array<Object>; //Dữ liệu truyền vào, bắt buộc là mảng
  hr?: Boolean; //HR ngăn cách title và item (Mặc định là false)
  hover?: Boolean; //Hover vào item (Mặc định là false)
  bacground?: Boolean; //Màu phân chia layout (Mặc định là false)
  className?: String; //ClassName của table
  classNameTitle?: String; //Class name của phần tử bao bọc toàn bộ title
  classnameItem?: String; //Class name của một item
  styleRowItem?: CSSProperties; //Style của một item
  styleRowTitle?: CSSProperties; //Style của một title
  style?: CSSProperties; //Style của table
  loadding?: Boolean;
  minHeight?: any;
  styleEmpty?: any;
  classNameThead?: any;
  textNameBold?: Boolean; // in đậm cột tên phiếu ở màn phiếu yêu cầu của tôi
  scroll?: (event: any) => void;
  styleContainer?: CSSProperties;
  loadingMore?: boolean;
  listTicketElementRef?: any;
};

const Table = ({
  config,
  data,
  hr,
  hover,
  bacground,
  className,
  classNameTitle,
  classnameItem,
  styleContainer,
  styleRowItem,
  styleRowTitle,
  style,
  loadding,
  minHeight,
  classNameThead,
  textNameBold,
  loadingMore,
  listTicketElementRef,
  scroll,
}: TypeProps) => {
  const handleRenderItemTicket = (value: any, val: any, index: number) => {
    if (!value || !val) return "";
    const elmItem = value?.render
      ? value?.render(val[value.key], index, val)
      : val[value.key];
    if (value.key === "request_completion_time") {
      return val.request_completion_time
        ? value?.render
          ? value?.render(val[value.key], index, val)
          : val[value.key]
        : null;
    }
    if (value?.property === "break-word") {
      return (
        <div className={mergeStylesCSS([styles["name-container"]])}>
          {elmItem}
        </div>
      );
    }
    if (value.key === "name") {
      if (val.parent !== null) {
        return (
          <>
            <div
              className={mergeStylesCSS([
                styles["name-container"],
                styles.textNameChilldren,
              ])}
            >
              {elmItem}
            </div>
            <div
              className={mergeStylesCSS([
                styles["name-container"],
                styles.textNameParent,
              ])}
            >
              {val.parent?.name}
            </div>
          </>
        );
      }
      if (textNameBold) {
        return (
          <div
            className={mergeStylesCSS([
              styles["name-container"],
              styles.textNameChilldren,
            ])}
          >
            {elmItem}
          </div>
        );
      }
    }

    if (value.key === "qualityPoint") {
      return val[value.key].text && value?.render
        ? value?.render(val[value.key], index, val)
        : val[value.key];
    }

    if (value.key === "is_view") {
      if (!val.is_view) {
        return null;
      }
    }
    return elmItem;
  };

  return (
    <>
      <div
        className={`${styles.container} ${minHeight}`}
        style={styleContainer}
        onScroll={scroll}
      >
        <table style={style} className={`${styles.table} ${className}`}>
          <thead className={classNameThead}>
            <tr
              style={styleRowTitle}
              className={`${styles.tr} ${classNameTitle ? classNameTitle : ""}`}
            >
              {config.map((val: any, index: any) => {
                return (
                  <th
                    className={`${styles.td} ${styles.tdtitle} ${
                      val?.center ? styles.center : styles.left
                    } ${hr ? styles.hr : ""} ${
                      val?.classTitle ? styles[val?.classTitle] : ""
                    }`}
                    key={index}
                    style={{
                      width: `${
                        val?.width
                          ? typeof val?.width === "string"
                            ? val.width
                            : val?.width + "px"
                          : "auto"
                      }`,
                      minWidth: `${
                        val?.minWidth
                          ? val?.minWidth
                          : val?.width
                          ? val?.width
                          : "auto"
                      }`,
                    }}
                    colSpan={val?.colSpan}
                    rowSpan={val?.rowSpan}
                  >
                    {val?.title ? val?.title : ""}
                  </th>
                );
              })}
            </tr>
          </thead>
          {checkArray(data) ? (
            <tbody onScroll={scroll}>
              {data.map((val: any, index: any) => {
                return (
                  <tr
                    className={`${styles.tr} ${
                      bacground ? styles.trLayout : ""
                    } ${hover ? styles.trHover : ""} ${
                      classnameItem ? classnameItem : ""
                    }`}
                    ref={listTicketElementRef}
                    key={index}
                    style={styleRowItem}
                  >
                    {checkArray(config) &&
                      config?.map((value: any, idx: any) => {
                        return (
                          <td
                            className={`${styles.td}  ${
                              value?.centerItem ? styles.center : styles.left
                            } ${value?.classItem ? value?.classItem : ""}`}
                            key={idx}
                            style={value?.styleItem}
                            onClick={(e: any) => {
                              if (value?.onClick) {
                                if (value.key === "is_view") {
                                  if (val.is_view) {
                                    return value.onClick(
                                      val[value?.key],
                                      index,
                                      val,
                                      e
                                    );
                                  } else {
                                    return null;
                                  }
                                } else {
                                  return value.onClick(
                                    val[value?.key],
                                    index,
                                    val,
                                    e
                                  );
                                }
                              }
                            }}
                          >
                            {handleRenderItemTicket(value, val, index)}
                          </td>
                        );
                      })}
                  </tr>
                );
              })}
              {loadingMore && (
                <tr key={"loadMore_key"}>
                  <td className={styles["loading-more-inner"]}>
                    <LoadingRing size="md" />
                    <span>Đang tải...</span>
                  </td>
                </tr>
              )}
            </tbody>
          ) : (
            ""
          )}
        </table>
        {loadding && (
          <div className={styles["loading-more"]}>
            <LoadingRing size="md" />
            <span>Đang tải dữ liệu</span>
          </div>
        )}
      </div>
    </>
  );
};

export default Table;
