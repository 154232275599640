const IconPerformanceSingle = ({ color }: { color: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.22091 0.802307C8.22091 0.388094 7.88512 0.0523071 7.47091 0.0523071C7.05669 0.0523071 6.72091 0.388094 6.72091 0.802307V2.20501C6.39789 2.2133 6.10332 2.22681 5.8338 2.24883C5.12042 2.30712 4.51679 2.42872 3.96562 2.70956C3.07185 3.16496 2.34519 3.89162 1.88979 4.78539C1.60895 5.33656 1.48734 5.94019 1.42906 6.65358C1.37206 7.3512 1.37206 8.2167 1.37207 9.30884V9.34184V9.71704V15.7977V15.8307C1.37206 16.9228 1.37206 17.7883 1.42906 18.4859C1.48734 19.1993 1.60895 19.8029 1.88979 20.3541C2.34519 21.2479 3.07185 21.9745 3.96562 22.4299C4.51679 22.7108 5.12042 22.8324 5.8338 22.8907C6.53142 22.9477 7.39691 22.9477 8.48905 22.9477H8.52207H14.9779H15.0109C16.103 22.9477 16.9685 22.9477 17.6662 22.8907C18.3795 22.8324 18.9832 22.7108 19.5343 22.4299C20.4281 21.9745 21.1548 21.2479 21.6102 20.3541C21.891 19.8029 22.0126 19.1993 22.0709 18.4859C22.1279 17.7883 22.1279 16.9228 22.1279 15.8307V15.7977V9.34184V9.30882C22.1279 8.21669 22.1279 7.35119 22.0709 6.65358C22.0126 5.94019 21.891 5.33656 21.6102 4.78539C21.1548 3.89162 20.4281 3.16496 19.5343 2.70956C18.9832 2.42872 18.3795 2.30712 17.6662 2.24883C17.3966 2.22681 17.1021 2.2133 16.779 2.20501V0.802307C16.779 0.388094 16.4433 0.0523071 16.029 0.0523071C15.6148 0.0523071 15.279 0.388094 15.279 0.802307V2.19187L15.0109 2.19184H15.0109H14.9779H8.52207H8.48907H8.48906L8.22091 2.19187V0.802307Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6387 7C10.0991 7 8.79589 8.20396 8.79589 9.7556C8.79589 11.3072 10.0991 12.5112 11.6387 12.5112C13.1783 12.5112 14.4815 11.3072 14.4815 9.7556C14.4815 8.20396 13.1783 7 11.6387 7ZM15.7784 16.0056C15.7784 16.9736 14.9936 17.7584 14.0256 17.7584H9.7528C8.78475 17.7584 8 16.9736 8 16.0056C8 14.4837 9.23372 13.25 10.7556 13.25H13.0228C14.5447 13.25 15.7784 14.4837 15.7784 16.0056Z"
        fill="white"
      />
    </svg>
  );
};
export default IconPerformanceSingle;
