import {
  RequestApiPrivateGTicketV2,
  RequestApiPrivateGticket,
} from "../api/axios";

export const Request = {
  async getApi(wsCode: any, params: {}) {
    try {
      if (params) {
        RequestApiPrivateGticket.defaults.params = params;
        const res: any = await RequestApiPrivateGticket.get(wsCode);
        if (res) {
          if (res.data) {
            return res.data;
          }
        }
      }
    } catch (error: any) {
      if (error) {
        return error.code;
      }
    }
  },
  async getApiV2(wsCode: any, params: {}) {
    try {
      if (params) {
        const res: any = await RequestApiPrivateGTicketV2.post(wsCode, {
          ...params,
        });
        if (res) {
          if (res.data) {
            return res.data;
          }
        }
      }
    } catch (error: any) {
      if (error) {
        return error.code;
      }
    }
  },
  async postApi(wsCode: any, data: {}) {
    try {
      if (data) {
        RequestApiPrivateGticket.defaults.params = [];
        const res: any = await RequestApiPrivateGticket.post(wsCode, data);
        if (res) {
          if (res.data) {
            return res.data;
          }
        }
      }
    } catch (error: any) {
      if (error?.response?.data) {
        return error?.response?.data;
      } else {
        return error;
      }
    }
  },
  async putApi(wsCode: any, data: {}) {
    try {
      if (data) {
        RequestApiPrivateGticket.defaults.params = [];
        const res: any = await RequestApiPrivateGticket.put(wsCode, data);
        if (res) {
          if (res.data) {
            return res.data;
          }
        }
      }
    } catch (error: any) {
      if (error) {
        return error.code;
      }
    }
  },
};
