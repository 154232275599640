import { Fragment, useEffect, useRef } from "react";

type TypeProps = {
  refs: any;
  callback: any;
  children: any;
  disabled?: boolean;
};

const ClickOutsideContainer = ({
  refs,
  callback,
  children,
  disabled = false,
}: TypeProps) => {
  const isMounted = useRef(false);
  const isDisabled = useRef(disabled);

  const handleClick = (e: any) => {
    if (!refs.current) return;
    if (!isMounted.current) return (isMounted.current = true);
    if (isDisabled.current) return;
    if (!refs.current.contains(e.target)) return callback();
  };

  useEffect(() => {
    isDisabled.current = disabled;
  }, [disabled]);

  useEffect(() => {
    if (refs.current) {
      document.addEventListener("click", handleClick);
      return () => {
        document.removeEventListener("click", handleClick);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Fragment>{children}</Fragment>;
};

export default ClickOutsideContainer;
